import { AxiosPromise } from "axios";
import apiService from "../../common/api/apiService";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { CreateFrontendErrorLog } from "./types";

const CREATE_FRONTEND_ERROR_LOG = ApiRequestAdapter.create("/events/frontend", "POST");

export const requests = {
  CREATE_FRONTEND_ERROR_LOG,
};

export default {
  createFrontendErrorLog: (request: CreateFrontendErrorLog): AxiosPromise => {
    return apiService.post<void>(CREATE_FRONTEND_ERROR_LOG.url, request);
  }
}
