import React, { CSSProperties } from "react";
import { PersonAccountBase } from "../../../modules/account/types";
import { formatLocaleDateTime, formatPersonAccountName } from "../../utils/formatUtils";
import t from "../../../app/i18n";

export interface Props {
  item: ItemProps;
  className?: string;
  style?: CSSProperties;
}

export interface ItemProps {
  createdAt: string;
  createdBy?: PersonAccountBase;
  updatedAt?: string;
}

const ItemCreatedUpdatedInfoView = ({ item, className, style }: Props) => (
  item ? (
    <div className={"sub-header-info " + (className ? className : "")} style={style}>
      <span>{t("common.createdAt")}:</span> {formatLocaleDateTime(item.createdAt)}
      {item.createdBy && ` - ${formatPersonAccountName(item.createdBy)}`}
      {item.updatedAt && <> | <span>{t("common.updatedAt")}:</span> {formatLocaleDateTime(item.updatedAt)}</>}
    </div>
  ) : null
);

export default ItemCreatedUpdatedInfoView;
