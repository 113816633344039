import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import api from "./api";
import {
  CalcDraft,
  CalcDraftAttachment,
  CalcDraftFilterPageRequest,
  CalcDraftFilterPageResult,
  CalcDraftList,
  CalcDraftReducerState,
  CreateUpdateCalcDraft
} from "./types";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  TwoLevelEntityIdRequest
} from "../../../common/types";
import { RealtyCalcDraft, RealtyCalcResultData } from "../calcs/realty/types";
import { CalcType } from "../enums";
import { setRealtyCalcResultsAction, setRealtySelectedCalcDraftAction } from "../calcs/realty/ducks";
import { sortAndGroupCalcResults } from "../calcs/utils";
import { openBlobFile, removeFromArray } from "../../../common/utils/utils";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import messageUtils from "../../../common/utils/messageUtils";
import t from "../../../app/i18n";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'calc-draft/FILTER',
  GET = 'calc-draft/GET',
  CREATE = 'calc-draft/CREATE',
  UPDATE = 'calc-draft/UPDATE',
  DELETE = 'calc-draft/DELETE',
  DELETE_STATE_LIST = 'calc-draft/DELETE_STATE_LIST',
  UPLOAD_ATTACHMENTS = 'calc-draft-attachment/UPLOAD',
  DOWNLOAD_ATTACHMENT = 'calc-draft-attachment/DOWNLOAD',
  DELETE_ATTACHMENT = 'calc-draft-attachment/DELETE'
}

/**
 * ACTIONS
 */
export const filterCalcDraftsActions = createApiActionCreators<CalcDraftFilterPageRequest, CalcDraftFilterPageResult>(actionType.FILTER);
export const getCalcDraftActions = createApiActionCreators<EntityIdRequest, CalcDraft>(actionType.GET);
export const createCalcDraftActions = createApiActionCreators<CreateUpdateCalcDraft, CalcDraft>(actionType.CREATE);
export const updateCalcDraftActions = createApiActionCreators<EntityObjectRequest<CreateUpdateCalcDraft>, CalcDraft>(actionType.UPDATE);
export const deleteCalcDraftActions = createApiActionCreators<EntityIdRequest, void>(actionType.DELETE);

export const deleteStateCalcDraftsPageAction = createActionCreator<void>(actionType.DELETE_STATE_LIST);

export const uploadCalcDraftAttachmentsActions = createApiActionCreators<EntityObjectRequest<FormData>, CalcDraftFilterPageResult>(actionType.UPLOAD_ATTACHMENTS);
export const downloadCalcDraftAttachmentActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.DOWNLOAD_ATTACHMENT);
export const deleteCalcDraftAttachmentActions = createApiActionCreators<TwoLevelEntityIdRequest, CalcDraftFilterPageResult>(actionType.DELETE_ATTACHMENT);

/**
 * REDUCERS
 */
const initialState: CalcDraftReducerState = {
  currentPage: {
    ...initSearchPageResult<CalcDraftList>(),
    calcType: null
  }
};

const currentPageReducer = createReducer<CalcDraftFilterPageResult>(initialState.currentPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentPage
  },
  [actionType.UPLOAD_ATTACHMENTS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_ATTACHMENT]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_LIST]: () => initialState.currentPage
});

export default combineReducers({ currentPage: currentPageReducer });

/**
 * SELECTORS
 */
const selectCalcDrafts = (state: RootState): CalcDraftReducerState => state.calculator.drafts;

export const selectCalcDraftsCurrentPage = (state: RootState): CalcDraftFilterPageResult => selectCalcDrafts(state).currentPage;

/**
 * SAGAS
 */
function* filterCalcDrafts({ payload }: ActionCreator<CalcDraftFilterPageRequest>) {
  try {
    const response: AxiosResponse<CalcDraftFilterPageResult> = yield call(api.filterCalcDrafts, payload);
    yield put(filterCalcDraftsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterCalcDraftsActions.failure(error));
  }
}

function* getCalcDraft({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<CalcDraft> = yield call(api.getCalcDraft, payload);
    yield put(getCalcDraftActions.success(response.data));

    if ( response.data.calcType === CalcType.REALTY ) {
      yield put(setRealtySelectedCalcDraftAction(response.data as RealtyCalcDraft));
      yield put(setRealtyCalcResultsAction(sortAndGroupCalcResults<RealtyCalcResultData>((response.data as RealtyCalcDraft).calcResponse)));
      yield put(push("/calc/realty"));
    }
  }
  catch ( error ) {
    yield put(getCalcDraftActions.failure(error));
  }
}

function* createCalcDraft({ payload }: ActionCreator<CreateUpdateCalcDraft>) {
  try {
    const response: AxiosResponse<CalcDraft> = yield call(api.createCalcDraft, payload);
    yield put(createCalcDraftActions.success(response.data));

    if ( response.data.calcType === CalcType.REALTY ) {
      yield put(setRealtySelectedCalcDraftAction(response.data as RealtyCalcDraft));
    }
    messageUtils.successNotification(t("common.operationSuccess"), t("calc.draft.helpers.draftSaved"));
  }
  catch ( error ) {
    yield put(createCalcDraftActions.failure(error));
  }
}

function* updateCalcDraft({ payload }: ActionCreator<EntityObjectRequest<CreateUpdateCalcDraft>>) {
  try {
    const response: AxiosResponse<CalcDraft> = yield call(api.updateCalcDraft, payload);
    yield put(updateCalcDraftActions.success(response.data));

    if ( response.data.calcType === CalcType.REALTY ) {
      yield put(setRealtySelectedCalcDraftAction(response.data as RealtyCalcDraft));
    }
    messageUtils.successNotification(t("common.operationSuccess"), t("calc.draft.helpers.draftSaved"));
  }
  catch ( error ) {
    yield put(updateCalcDraftActions.failure(error));
  }
}

function* deleteCalcDraft({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteCalcDraft, payload);
    yield put(deleteCalcDraftActions.success());

    const currentPage: CalcDraftFilterPageResult = yield select(selectCalcDraftsCurrentPage);
    yield put(filterCalcDraftsActions.request({
      keyword: currentPage.keyword,
      pageIndex: currentPage.pageElementsCount === 1 ? currentPage.isFirst ? 0 : currentPage.pageIndex - 1 : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      calcType: currentPage.calcType
    }));
  }
  catch ( error ) {
    yield put(deleteCalcDraftActions.failure(error));
  }
}

function* uploadCalcDraftAttachments({ payload }: ActionCreator<EntityObjectRequest<FormData>>) {
  try {
    const response: AxiosResponse<CalcDraftAttachment[]> = yield call(api.uploadCalcDraftAttachments, payload);
    const currentPage: CalcDraftFilterPageResult = cloneDeep(yield select(selectCalcDraftsCurrentPage));
    const draft = currentPage.pageData.find(draft => draft.id === payload.id);
    draft.attachments = response.data;
    yield put(uploadCalcDraftAttachmentsActions.success(currentPage));
  }
  catch ( error ) {
    yield put(uploadCalcDraftAttachmentsActions.failure(error));
  }
}

function* downloadCalcDraftAttachment({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    const response: AxiosResponse = yield call(api.downloadCalcDraftAttachment, payload);
    openBlobFile(response);
    yield put(downloadCalcDraftAttachmentActions.success());
  }
  catch ( error ) {
    yield put(downloadCalcDraftAttachmentActions.failure(error));
  }
}

function* deleteCalcDraftAttachment({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.deleteCalcDraftAttachment, payload);
    const currentPage: CalcDraftFilterPageResult = cloneDeep(yield select(selectCalcDraftsCurrentPage));
    const draft = currentPage.pageData.find(draft => draft.id === payload.id1);
    draft.attachments = removeFromArray(draft.attachments, item => item.id === payload.id2);
    yield put(deleteCalcDraftAttachmentActions.success(currentPage));
  }
  catch ( error ) {
    yield put(deleteCalcDraftAttachmentActions.failure(error));
  }
}

export function* calcDraftsSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterCalcDrafts);
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getCalcDraft);
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createCalcDraft);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateCalcDraft);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deleteCalcDraft);
  yield takeLatest(createActionType(actionType.UPLOAD_ATTACHMENTS, apiOperation.REQUEST), uploadCalcDraftAttachments);
  yield takeLatest(createActionType(actionType.DOWNLOAD_ATTACHMENT, apiOperation.REQUEST), downloadCalcDraftAttachment);
  yield takeLatest(createActionType(actionType.DELETE_ATTACHMENT, apiOperation.REQUEST), deleteCalcDraftAttachment);
}
