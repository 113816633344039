import React from "react";
import { Route, Switch } from "react-router";
import RouteWithPermission from "../../../common/security/authorization/RouteWithPermission";
import { Permission } from "../../../common/security/authorization/enums";

import ProductContainer from "./containers/ProductContainer";
import NotFound from "../../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission exact path="/admin/products"
                         component={ProductContainer}
                         permissions={[Permission.ADMIN_ENUMERATIONS]} />

    <Route component={NotFound} />
  </Switch>
);
