import React from "react";
import { Modal, Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import t from "../../../../app/i18n";

export interface Props {
  calcRequestInProgress: boolean;
  genRequestInProgress: boolean;
}

const CalcLoadingModal = ({ calcRequestInProgress, genRequestInProgress }: Props) => (
  <Modal
    visible={calcRequestInProgress || genRequestInProgress}
    title={calcRequestInProgress ? t("calc.helpers.calcRequestInProgress") : t("calc.helpers.genRequestInProgress")}
    destroyOnClose
    maskClosable={false}
    closable={false}
    footer={null}>

    <Result
      status={"info"}
      icon={<LoadingOutlined />}
      title={t("calc.helpers.requestInProgressInfo")} />

  </Modal>
);

export default CalcLoadingModal;
