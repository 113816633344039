import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { goBack, replace } from "connected-react-router";
import { Button, Result, Space } from "antd";
import { DoubleLeftOutlined, HomeOutlined } from "@ant-design/icons";
import { ActionProps } from "../../types";
import t from "../../../app/i18n";

interface ActionsMap {
  replaceNavigation: typeof replace;
  goBackNavigation: typeof goBack;
}

const NotFound = (props: ActionProps<ActionsMap>) => (
  <Result
    className="result-page"
    status="404"
    title={t("navigation.notFound.title")}
    subTitle={t("navigation.notFound.text")}
    extra={
      <Space className="margin-top-large">
        <Button onClick={props.actions.goBackNavigation}>
          <DoubleLeftOutlined />{t("navigation.notFound.backAction")}
        </Button>
        <Button onClick={() => props.actions.replaceNavigation("/")}>
          {t("navigation.notFound.homeAction")}<HomeOutlined />
        </Button>
      </Space>
    }
  />
);

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    replaceNavigation: replace,
    goBackNavigation: goBack
  }, dispatch)
});

export default connect<{}, ActionProps<ActionsMap>, {}>(null, mapDispatchToProps)(NotFound);
