import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Col, Modal, Row, Select } from "antd";
import { CloseOutlined, CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { CalcRecord } from "../../types";
import { isJson, saveTextFile } from "../../../../../common/utils/utils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import { formatLocaleDateTime, formatPersonAccountName } from "../../../../../common/utils/formatUtils";
import t from "../../../../../app/i18n";

export interface Props {
  visible: boolean;
  record: CalcRecord;
  onCancelClick(): void;
  onAfterClose(): void;
}

interface State {
  readonly selectedLogIndex: number;
}

class CalcRecordWebservicesLogsModal extends React.Component<Props, State> {
  readonly state: State = {
    selectedLogIndex: 0
  };

  handleSelectedLogChange = (value: number): void => {
    this.setState({ selectedLogIndex: value });
  };

  handleAfterCloseExecuted = (): void => {
    this.setState({ selectedLogIndex: 0 });
    this.props.onAfterClose();
  };

  handleLogFileSave = (content: string, type: "request" | "response"): void => {
    saveTextFile(content, this.createLogsTitle() + " _ " + t("common." + type));
  };

  handleCopyInfoMessageDisplay = (): void => {
    messageUtils.infoMessage(t("common.clipboardCopied"));
  };

  createLogsTitle = (): string => {
    return t("calc.records.titles.webserviceLogs") + ` - ${formatPersonAccountName(this.props.record.account)} / `
      + formatLocaleDateTime(this.props.record.createdAt);
  };

  render(): React.ReactNode {
    const { record } = this.props;

    if ( record && record.webservicesLogs && record.webservicesLogs.length > 0 ) {
      const { request, response } = record.webservicesLogs[this.state.selectedLogIndex];
      const requestString = isJson(request) ? JSON.stringify(JSON.parse(request), null, 2) : request;
      const responseString = isJson(response) ? JSON.stringify(JSON.parse(response), null, 2) : response;

      return (
        <Modal
          visible={this.props.visible}
          title={this.createLogsTitle()}
          width={1000}
          centered
          onCancel={this.props.onCancelClick}
          afterClose={this.handleAfterCloseExecuted}
          footer={<Button onClick={this.props.onCancelClick} icon={<CloseOutlined />}>{t("common.close")}</Button>}>

          <Row gutter={16} className="margin-bottom-medium">
            <Select
              {...selectStandardProps} style={{ width: "200px" }}
              value={this.state.selectedLogIndex}
              options={this.props.record.webservicesLogs.map((log, index) => ({
                value: index,
                label: log.institution + (log.coverage ? (" - " + log.coverage) : "")
              }))}
              onChange={this.handleSelectedLogChange} />
          </Row>

          <Row gutter={16}>

            <Col span={12}>
              <h3>{t("common.request")}</h3>

              <pre className="modal-code-block">
                {requestString}
              </pre>

              <div className="center-align margin-top-small">
                <Button icon={<DownloadOutlined />} className="blue-button margin-right-medium"
                        onClick={() => this.handleLogFileSave(requestString, "request")}>
                  {t("common.download")}
                </Button>

                <CopyToClipboard text={requestString} onCopy={this.handleCopyInfoMessageDisplay}>
                  <Button icon={<CopyOutlined />} className="blue-button">{t("common.copy")}</Button>
                </CopyToClipboard>
              </div>
            </Col>

            <Col span={12}>
              <h3>{t("common.response")}</h3>

              <pre className="modal-code-block">
                {responseString}
              </pre>

              <div className="center-align margin-top-small">
                <Button icon={<DownloadOutlined />} className="blue-button margin-right-medium"
                        onClick={() => this.handleLogFileSave(responseString, "response")}>
                  {t("common.download")}
                </Button>

                <CopyToClipboard text={responseString} onCopy={this.handleCopyInfoMessageDisplay}>
                  <Button icon={<CopyOutlined />} className="blue-button">{t("common.copy")}</Button>
                </CopyToClipboard>
              </div>
            </Col>

          </Row>

        </Modal>
      );
    }

    return null;
  }
}

export default CalcRecordWebservicesLogsModal;
