import React from "react";
import { AutoComplete, Col, Spin } from "antd";
import { SelectValue } from "antd/lib/select";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { Form } from "@ant-design/compatible";
import { GetFieldDecoratorOptions, WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { Client } from "../types";
import { FieldConstraintViolation } from "../../../common/types";
import { ClientFormStage, ClientFormType } from "../enums";
import { contains } from "../../../common/utils/utils";
import t from "../../../app/i18n";

import ClientActionsFormLabel from "./ClientActionsFormLabel_Deprecated";
import ClientTypeTagWithName from "./ClientTypeTagWithName";

export interface Props {
  form: WrappedFormUtils;
  formKey: string;
  formType: ClientFormType;
  formStage: ClientFormStage;
  formInputOptions: GetFieldDecoratorOptions;
  label: string;
  disabled?: boolean;
  processedClientFormType: ClientFormType;
  autocompleteInProgress: boolean;
  options: DataSourceItemObject[];
  client: Client;
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  clientsDuplicateErrors?: ClientFormType[];
  inputColSpan?: number;
  clientNameColSpan?: number;
  onClientActionCreateClick(formType: ClientFormType): void;
  onClientActionUpdateClick(formType: ClientFormType): void;
  onClientActionDeselectClick(formType: ClientFormType): void;
  onClientsAutocompleteFocus?(formType: ClientFormType): void;
  onClientsAutocompleteSearch(value: string, formType: ClientFormType): void;
  onClientsAutocompleteSelect(value: SelectValue, formType: ClientFormType): void;
  onClientsAutocompleteChange(value: SelectValue, formType: ClientFormType): void;
}

const DEFAULT_COL_SPAN = 8;

const ClientAutocompleteInput_Deprecated = (props: Props) => {

  let errorStatus = undefined;
  if ( props.clientsViolationErrors.has(props.formType) ) {
    errorStatus = { help: t("client.helpers.search.clientsDataError"), validateStatus: "error" };
  }
  else if ( props.clientsDuplicateErrors && contains(props.clientsDuplicateErrors, props.formType) ) {
    errorStatus = { help: t("client.helpers.search.clientsDuplicateError"), validateStatus: "error" };
  }
  else if ( props.formStage === ClientFormStage.NEW ) {
    errorStatus = { help: t("client.helpers.search.clientsDataMissingError"), validateStatus: "warning" };
  }

  return (
    <>
      <Col span={props.inputColSpan || DEFAULT_COL_SPAN}>
        <Spin spinning={props.autocompleteInProgress && props.processedClientFormType === props.formType}>
          <Form.Item
            {...errorStatus}
            required={false}
            label={
              <ClientActionsFormLabel
                label={props.label}
                stage={props.formStage}
                onCreateClick={() => props.onClientActionCreateClick(props.formType)}
                onUpdateClick={() => props.onClientActionUpdateClick(props.formType)}
                onDeselectClick={!props.disabled ? () => props.onClientActionDeselectClick(props.formType) : undefined} />
            }>
            {props.form.getFieldDecorator(props.formKey, props.formInputOptions)(
              <AutoComplete
                dropdownMatchSelectWidth={false}
                disabled={props.disabled || props.formStage === ClientFormStage.SELECTED}
                onFocus={() => {
                  if ( props.onClientsAutocompleteFocus ) {
                    props.onClientsAutocompleteFocus(props.formType);
                  }
                }}
                onSearch={value => props.onClientsAutocompleteSearch(value, props.formType)}
                onSelect={value => props.onClientsAutocompleteSelect(value, props.formType)}
                onChange={value => props.onClientsAutocompleteChange(value, props.formType)}
                options={props.options} />
            )}
          </Form.Item>
        </Spin>
      </Col>
      <Col span={props.clientNameColSpan || DEFAULT_COL_SPAN} className="form-item-without-label sub-header-info">
        <ClientTypeTagWithName client={props.client} />
      </Col>
    </>
  )
};

export default ClientAutocompleteInput_Deprecated;
