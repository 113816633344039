import React from "react";
import { Button } from "antd";
import {
  CalculatorOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  ReloadOutlined,
  RightOutlined,
} from "@ant-design/icons";
import t from "../../../../../../../app/i18n";

export interface Props {
  currentStep: number;
  lastReachedStep: number;
  hasErrors: boolean;
  hasResults: boolean;
  onFormSubmit(): void;
  onShowResultsClick(): void;
  onMoveToNextStepClick(): void;
  onMoveToPreviousStepClick(): void;
  onResetCalculatorClick(): void;
}

const RealtyCalcNavigation = (props: Props) => (
  <div className="bottom-fixed-navigation">

    <div className="navigation-center">
      <Button icon={<LeftOutlined />} onClick={props.onMoveToPreviousStepClick} disabled={props.currentStep === 0}>
        {t("common.back")}
      </Button>

      <Button icon={<CalculatorOutlined />} type="primary" className="margin-left-small margin-right-small"
              onClick={props.onFormSubmit} disabled={props.lastReachedStep < 3 || props.hasErrors}>
        {t("common.calculate")}
      </Button>

      <Button onClick={props.onMoveToNextStepClick} disabled={props.currentStep === 3}>
        {t("common.next")} <RightOutlined />
      </Button>
    </div>

    <div className="navigation-right">
      <Button className="blue-button" icon={<DoubleLeftOutlined />} onClick={props.onShowResultsClick}
              disabled={!props.hasResults}>
        {t("common.showResults")}
      </Button>
    </div>

    <div className="navigation-left">
      <Button icon={<ReloadOutlined />} onClick={props.onResetCalculatorClick}>{t("common.resetCalculator")}</Button>
    </div>

  </div>
);

export default RealtyCalcNavigation;
