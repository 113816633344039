export enum Permission {
  INSURANCE = 'INSURANCE',
  INSURANCE_CREATE = 'INSURANCE_CREATE',
  INSURANCE_UPDATE = 'INSURANCE_UPDATE',
  INSURANCE_DELETE = 'INSURANCE_DELETE',

  INSURANCE_VERIFY = 'INSURANCE_VERIFY',
  CHANGE_PERSONS_ON_VERIFIED_INSURANCE = 'CHANGE_PERSONS_ON_VERIFIED_INSURANCE',

  LOAN = 'LOAN',
  LOAN_CREATE = 'LOAN_CREATE',
  LOAN_UPDATE = 'LOAN_UPDATE',
  LOAN_DELETE = 'LOAN_DELETE',

  LOAN_VERIFY = 'LOAN_VERIFY',
  CHANGE_PERSONS_ON_VERIFIED_LOAN = 'CHANGE_PERSONS_ON_VERIFIED_LOAN',

  CLIENT = 'CLIENT',
  CLIENT_CREATE = 'CLIENT_CREATE',
  CLIENT_UPDATE = 'CLIENT_UPDATE',
  CLIENT_DELETE = 'CLIENT_DELETE',
  CLIENT_ACCOUNTS = 'CLIENT_ACCOUNTS',

  MTPL_CALCULATOR = 'MTPL_CALCULATOR',
  MTPL_CALCULATOR_GENERATE_CONTRACT = 'MTPL_CALCULATOR_GENERATE_CONTRACT',
  MTPL_CALCULATOR_ADMIN = 'MTPL_CALCULATOR_ADMIN',

  CRASH_CALCULATOR = 'CRASH_CALCULATOR',
  CRASH_CALCULATOR_GENERATE_CONTRACT = 'CRASH_CALCULATOR_GENERATE_CONTRACT',
  CRASH_CALCULATOR_ADMIN = 'CRASH_CALCULATOR_ADMIN',

  REALTY_CALCULATOR = 'REALTY_CALCULATOR',
  REALTY_CALCULATOR_GENERATE_CONTRACT = 'REALTY_CALCULATOR_GENERATE_CONTRACT',
  REALTY_CALCULATOR_ADMIN = 'REALTY_CALCULATOR_ADMIN',

  TRAVEL_CALCULATOR = 'TRAVEL_CALCULATOR',
  TRAVEL_CALCULATOR_GENERATE_CONTRACT = 'TRAVEL_CALCULATOR_GENERATE_CONTRACT',
  TRAVEL_CALCULATOR_ADMIN = 'TRAVEL_CALCULATOR_ADMIN',

  CARD_READER = 'CARD_READER',

  PERSON = 'PERSON',
  PERSON_CREATE = 'PERSON_CREATE',
  PERSON_UPDATE = 'PERSON_UPDATE',
  PERSON_DELETE = 'PERSON_DELETE',
  PERSON_ACCOUNTS = 'PERSON_ACCOUNTS',
  PERSON_COMMISSIONS = 'PERSON_COMMISSIONS',
  PERSON_COMMISSIONS_MANAGE = 'PERSON_COMMISSIONS_MANAGE',

  COMMISSIONS = 'COMMISSIONS',
  COMMISSIONS_MANAGE = 'COMMISSIONS_MANAGE',

  POSTPONED_COMMISSIONS = 'POSTPONED_COMMISSIONS',

  ADMIN = 'ADMIN',
  ADMIN_CONTRACT_UPDATES = 'ADMIN_CONTRACT_UPDATES',
  ADMIN_JOBS = 'ADMIN_JOBS',
  ADMIN_ENUMERATIONS = 'ADMIN_ENUMERATIONS'
}

export enum Role {
  PERSON = 'PERSON',
  CLIENT = 'CLIENT'
}

export const permissionPrerequisitesMap = new Map<Permission, Permission[]>([
  [Permission.INSURANCE, []],
  [Permission.INSURANCE_CREATE, [Permission.INSURANCE]],
  [Permission.INSURANCE_UPDATE, [Permission.INSURANCE]],
  [Permission.INSURANCE_DELETE, [Permission.INSURANCE]],

  [Permission.INSURANCE_VERIFY, [Permission.INSURANCE_UPDATE]],
  [Permission.CHANGE_PERSONS_ON_VERIFIED_INSURANCE, [Permission.INSURANCE_UPDATE]],

  [Permission.LOAN, []],
  [Permission.LOAN_CREATE, [Permission.LOAN]],
  [Permission.LOAN_UPDATE, [Permission.LOAN]],
  [Permission.LOAN_DELETE, [Permission.LOAN]],

  [Permission.LOAN_VERIFY, [Permission.LOAN_UPDATE]],
  [Permission.CHANGE_PERSONS_ON_VERIFIED_LOAN, [Permission.LOAN_UPDATE]],

  [Permission.CLIENT, []],
  [Permission.CLIENT_CREATE, [Permission.CLIENT]],
  [Permission.CLIENT_UPDATE, [Permission.CLIENT]],
  [Permission.CLIENT_DELETE, [Permission.CLIENT]],
  [Permission.CLIENT_ACCOUNTS, [Permission.CLIENT]],

  [Permission.MTPL_CALCULATOR, []],
  [Permission.MTPL_CALCULATOR_GENERATE_CONTRACT, [Permission.MTPL_CALCULATOR, Permission.INSURANCE, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.MTPL_CALCULATOR_ADMIN, [Permission.MTPL_CALCULATOR]],

  [Permission.CRASH_CALCULATOR, []],
  [Permission.CRASH_CALCULATOR_GENERATE_CONTRACT, [Permission.CRASH_CALCULATOR, Permission.INSURANCE, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.CRASH_CALCULATOR_ADMIN, [Permission.CRASH_CALCULATOR]],

  [Permission.REALTY_CALCULATOR, []],
  [Permission.REALTY_CALCULATOR_GENERATE_CONTRACT, [Permission.REALTY_CALCULATOR, Permission.INSURANCE, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.REALTY_CALCULATOR_ADMIN, [Permission.REALTY_CALCULATOR]],

  [Permission.TRAVEL_CALCULATOR, []],
  [Permission.TRAVEL_CALCULATOR_GENERATE_CONTRACT, [Permission.TRAVEL_CALCULATOR, Permission.INSURANCE, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.TRAVEL_CALCULATOR_ADMIN, [Permission.TRAVEL_CALCULATOR]],

  [Permission.CARD_READER, []],

  [Permission.PERSON, []],
  [Permission.PERSON_CREATE, [Permission.PERSON]],
  [Permission.PERSON_UPDATE, [Permission.PERSON]],
  [Permission.PERSON_DELETE, [Permission.PERSON]],
  [Permission.PERSON_ACCOUNTS, [Permission.PERSON]],
  [Permission.PERSON_COMMISSIONS, [Permission.PERSON]],
  [Permission.PERSON_COMMISSIONS_MANAGE, [Permission.PERSON_COMMISSIONS]],

  [Permission.COMMISSIONS, []],
  [Permission.COMMISSIONS_MANAGE, [Permission.COMMISSIONS]],

  [Permission.POSTPONED_COMMISSIONS, []],

  [Permission.ADMIN, []],
  [Permission.ADMIN_CONTRACT_UPDATES, [Permission.ADMIN]],
  [Permission.ADMIN_JOBS, [Permission.ADMIN]],
  [Permission.ADMIN_ENUMERATIONS, [Permission.ADMIN]]
]);

export const permissionStructureMap = new Map<Permission, Permission[]>([
  [Permission.INSURANCE, [
    Permission.INSURANCE_CREATE,
    Permission.INSURANCE_UPDATE,
    Permission.INSURANCE_DELETE,
    Permission.INSURANCE_VERIFY,
    Permission.CHANGE_PERSONS_ON_VERIFIED_INSURANCE
  ]],
  [Permission.LOAN, [
    Permission.LOAN_CREATE,
    Permission.LOAN_UPDATE,
    Permission.LOAN_DELETE,
    Permission.LOAN_VERIFY,
    Permission.CHANGE_PERSONS_ON_VERIFIED_LOAN
  ]],
  [Permission.CLIENT, [Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE, Permission.CLIENT_DELETE, Permission.CLIENT_ACCOUNTS]],
  [Permission.MTPL_CALCULATOR, [Permission.MTPL_CALCULATOR_GENERATE_CONTRACT, Permission.MTPL_CALCULATOR_ADMIN]],
  [Permission.CRASH_CALCULATOR, [Permission.CRASH_CALCULATOR_GENERATE_CONTRACT, Permission.CRASH_CALCULATOR_ADMIN]],
  [Permission.REALTY_CALCULATOR, [Permission.REALTY_CALCULATOR_GENERATE_CONTRACT, Permission.REALTY_CALCULATOR_ADMIN]],
  [Permission.TRAVEL_CALCULATOR, [Permission.TRAVEL_CALCULATOR_GENERATE_CONTRACT, Permission.TRAVEL_CALCULATOR_ADMIN]],
  [Permission.CARD_READER, []],
  [Permission.PERSON, [Permission.PERSON_CREATE, Permission.PERSON_UPDATE, Permission.PERSON_DELETE, Permission.PERSON_ACCOUNTS, Permission.PERSON_COMMISSIONS, Permission.PERSON_COMMISSIONS_MANAGE]],
  [Permission.COMMISSIONS, [Permission.COMMISSIONS_MANAGE]],
  [Permission.POSTPONED_COMMISSIONS, []],
  [Permission.ADMIN, [Permission.ADMIN_CONTRACT_UPDATES, Permission.ADMIN_JOBS, Permission.ADMIN_ENUMERATIONS]]
]);
