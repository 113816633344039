import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Path } from "history";
import { ActionProps, RootState } from "../../../common/types";
import { createContractActions } from "../ducks";
import { selectRouterLocationPathname } from "../../ducks";

import InsuranceContractForm from "../components/forms/insurance/InsuranceContractForm";
import LoanContractForm from "../components/forms/loan/LoanContractForm";

interface StateProps {
  locationPathname: Path;
}

interface ActionsMap {
  createContract: typeof createContractActions.request;
}

const ContractCreateContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  return props.locationPathname.endsWith("new-insurance")
    ? <InsuranceContractForm onCreateFormSubmit={props.actions.createContract} />
    : <LoanContractForm onCreateFormSubmit={props.actions.createContract} />
}

const mapStateToProps = (state: RootState): StateProps => ({
  locationPathname: selectRouterLocationPathname(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    createContract: createContractActions.request
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(ContractCreateContainer);
