import React, { FormEvent } from "react";
import { Location } from "history";
import { Button, Checkbox, Col, Divider, Input, List, Modal, Popconfirm, Row, Select, Space, } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DeleteOutlined, DoubleLeftOutlined, PlusOutlined, ReloadOutlined, SaveOutlined, } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import {
  UpdateVehicleMapping,
  UpdateVehicleMappingFormItem,
  VehicleMapping,
  VehicleMappingData,
  VehicleMappingFilterPageRequest,
  VehicleModelMappingFilterPageResult
} from "../../types";
import { RootArrayFormObject, UUID } from "../../../../../common/types";
import { ValidationErrorResponse } from "../../../../types";
import { VehicleMappingType } from "../../enums";
import {
  createVehicleModelMappingActions,
  filterVehicleModelMappingsActions,
  updateVehicleModelMappingsActions
} from "../../ducks";
import { deleteStateValidationErrorResponseAction } from "../../../../ducks";
import {
  parseVehicleMappingsFromIdentifiers,
  parseVehicleMappingsToUpdateFormItems,
  VEHICLE_IDENTIFIER_DELIMITER,
  VEHICLE_TYPES_PATH_MAP
} from "../../utils";
import validations, { validationConstants, validationFunctions } from "../../../../../common/utils/validationUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { contains, removeFromArray } from "../../../../../common/utils/utils";
import { selectStandardProps, setErrorsToForm_deprecated } from "../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import VehicleModelCreateMappingModal from "./VehicleModelCreateMappingModal";
import HiddenInputDeprecated from "../../../../../common/components/form/components/HiddenInput_Deprecated";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props extends FormComponentProps<RootArrayFormObject<UpdateVehicleMappingFormItem>> {
  selectedBrandId: UUID;
  selectedMappingTypes: VehicleMappingType[];
  mappingData: VehicleMappingData[];
  currentPage: VehicleModelMappingFilterPageResult;
  createMappingErrorResponse: ValidationErrorResponse;
  createMappingRequestInProgress: boolean;
  updateMappingsErrorResponse: ValidationErrorResponse;
  location: Location;
  onFilterMappings: typeof filterVehicleModelMappingsActions.request;
  onCreateMapping: typeof createVehicleModelMappingActions.request;
  onUpdateMappings: typeof updateVehicleModelMappingsActions.request;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
  onReturnToBrandsClick(): void;
}

interface State {
  readonly createMappingModalVisible: boolean;
  readonly updatedRowsIds: UUID[];
}

class VehicleModelMappingsListForm extends React.Component<Props, State> {
  private static readonly PAGE_SIZE = 15;

  readonly state: State = {
    createMappingModalVisible: false,
    updatedRowsIds: []
  };

  handleFormSubmit = (event: FormEvent): void => {
    event.preventDefault();

    const { updatedRowsIds } = this.state;
    if ( updatedRowsIds.length > 0 ) {
      this.props.form.validateFields((errors, values) => {
        if ( !errors ) {
          this.props.onUpdateMappings({
            id: this.props.selectedBrandId,
            object: {
              mappings: values.items
                .filter(mapping => contains(updatedRowsIds, mapping.id))
                .map<UpdateVehicleMapping>(mapping => parseVehicleMappingsFromIdentifiers(mapping) as UpdateVehicleMapping)
            }
          });
        }
      });
    }
    else {
      messageUtils.warnMessage(t("calc.vehicles.validations.noChanges"));
    }
  };

  handleSearchSubmit = (keyword: string): void => {
    if ( validationFunctions.validateSearchKeyword(keyword) ) {
      this.checkUnsavedChangesBeforePageChange({
        keyword,
        pageIndex: 0,
        pageSize: VehicleModelMappingsListForm.PAGE_SIZE,
        queriedMappings: this.props.selectedMappingTypes,
        excludeMapped: this.props.currentPage.excludeMapped
      });
    }
    else {
      messageUtils.errorMessage(t("validation.size", {
        min: validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
        max: validationConstants.SEARCH_KEYWORD_MAX_LENGTH
      }));
    }
  };

  handleExcludeMappedChange = (e: CheckboxChangeEvent): void => {
    this.checkUnsavedChangesBeforePageChange({
      keyword: this.props.currentPage.keyword,
      pageIndex: 0,
      pageSize: VehicleModelMappingsListForm.PAGE_SIZE,
      queriedMappings: this.props.selectedMappingTypes,
      excludeMapped: e.target.checked
    });
  };

  handleListPageChange = (pageNumber: number): void => {
    this.checkUnsavedChangesBeforePageChange({
      keyword: this.props.currentPage.keyword,
      pageIndex: pageNumber - 1,
      pageSize: VehicleModelMappingsListForm.PAGE_SIZE,
      queriedMappings: this.props.selectedMappingTypes,
      excludeMapped: this.props.currentPage.excludeMapped
    });
  };

  handleMappingRowChange = (id: UUID): void => {
    this.setState(previousState => ({
      updatedRowsIds: contains(previousState.updatedRowsIds, id) ? previousState.updatedRowsIds : [...previousState.updatedRowsIds, id]
    }));
  };

  handleCreateMappingModalShow = (): void => {
    this.setState({ createMappingModalVisible: true });
  };

  handleCreateMappingModalCancel = (): void => {
    this.setState({ createMappingModalVisible: false });
  };

  handleRowResetClick = (rowId: UUID, rowIndex: number): void => {
    const row = this.props.currentPage.pageData.find(row => row.id === rowId);
    if ( row ) {
      this.props.form.setFieldsValue({ [`items[${rowIndex}]`]: parseVehicleMappingsToUpdateFormItems(row)[0] });
      this.setState(previousState => ({
        updatedRowsIds: removeFromArray(previousState.updatedRowsIds, stateRowId => stateRowId === rowId)
      }));
    }
  };

  checkUnsavedChangesBeforePageChange = (request: VehicleMappingFilterPageRequest): void => {
    if ( this.state.updatedRowsIds.length > 0 ) {
      Modal.confirm({
        title: t("common.unsavedChanges"),
        okText: t("common.continue"),
        cancelText: t("common.back"),
        onOk: () => {
          this.props.onFilterMappings({ id: this.props.selectedBrandId, object: request });
        }
      });
    }
    else {
      this.props.onFilterMappings({ id: this.props.selectedBrandId, object: request });
    }
  };

  componentDidMount(): void {
    this.props.onFilterMappings({
      id: this.props.selectedBrandId,
      object: {
        keyword: this.props.currentPage.keyword,
        pageIndex: 0,
        pageSize: VehicleModelMappingsListForm.PAGE_SIZE,
        queriedMappings: this.props.selectedMappingTypes,
        excludeMapped: this.props.currentPage.excludeMapped
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( prevProps.currentPage.id !== this.props.currentPage.id ) {
      this.props.form.setFieldsValue({ items: parseVehicleMappingsToUpdateFormItems(...this.props.currentPage.pageData) });
      if ( this.state.updatedRowsIds.length > 0 ) {
        this.setState({ updatedRowsIds: [] });
      }
    }
    if ( prevProps.location.pathname !== this.props.location.pathname ) {
      this.props.onReturnToBrandsClick();
    }
    if ( this.props.updateMappingsErrorResponse ) {
      setErrorsToForm_deprecated(this.props.form, this.props.updateMappingsErrorResponse.violations, "calc.vehicles.attrs");
      this.props.onErrorResponseDelete();
    }
  }

  render(): React.ReactNode {
    const { updatedRowsIds } = this.state;
    const { form, selectedMappingTypes, mappingData, currentPage } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <Divider orientation="left">
          {currentPage.brand
            ? t("calc.vehicles.titles.modelsMappingForBrand", { brandName: currentPage.brand.name })
            : t("calc.vehicles.titles.modelsMapping")}
        </Divider>

        <Row gutter={rowGutter}>
          <Col span={16}>
            <Input.Search
              style={{ width: "350px" }}
              enterButton
              allowClear
              defaultValue={currentPage.keyword}
              placeholder={t("calc.vehicles.helpers.searchModelHint")}
              onSearch={this.handleSearchSubmit} />

            <span className="margin-left-small">
            <Checkbox
              style={{ marginTop: "4px" }}
              checked={currentPage.excludeMapped}
              onChange={this.handleExcludeMappedChange}>
              {t("calc.vehicles.actions.excludeMapped")}
            </Checkbox>
          </span>
          </Col>

          <Col span={8} className="right-align">
            <Space>
              <Button icon={<DoubleLeftOutlined />} onClick={this.props.onReturnToBrandsClick}>
                {t("calc.vehicles.actions.backToBrands")}
              </Button>

              <Button className="blue-button" icon={<PlusOutlined />} onClick={this.handleCreateMappingModalShow}>
                {t("calc.vehicles.actions.createModel")}
              </Button>
            </Space>
          </Col>
        </Row>

        <Form layout="vertical" onSubmit={this.handleFormSubmit}>
          <List<VehicleMapping>
            itemLayout="vertical"
            dataSource={currentPage.pageData}
            pagination={{
              current: currentPage.pageIndex + 1,
              pageSize: currentPage.pageSize,
              total: currentPage.totalElementsCount,
              hideOnSinglePage: true,
              onChange: this.handleListPageChange,
              showQuickJumper: true
            }}
            renderItem={(item, rowIndex) => (
              <List.Item key={rowIndex}>
                <HiddenInputDeprecated form={form} formKey={`items[${rowIndex}].id`} />
                <HiddenInputDeprecated form={form} formKey={`items[${rowIndex}].optimisticLockVersion`} />

                <Row
                  gutter={rowGutter}
                  className={"vehicle-mappings-row" + (contains(updatedRowsIds, item.id) ? " edited-row" : "")}>
                  <Col span={4}>
                    <Form.Item label={t("calc.vehicles.attrs.name")}>
                      {getFieldDecorator(`items[${rowIndex}].name`, { rules: [validations.notBlank] })(
                        <Input size="small" onChange={() => this.handleMappingRowChange(item.id)} />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={16}>
                    <Row gutter={rowGutter}>
                      {Object.values(VehicleMappingType).map((type, index) => {
                        const typePath = VEHICLE_TYPES_PATH_MAP.get(type);
                        const mapping = mappingData.find(mapping => mapping.type === type);
                        const brandMapping = mapping ? mapping.brands.find(brand => currentPage.brand && currentPage.brand[`${typePath}Id`] === brand.id) : null;

                        return (
                          <React.Fragment key={index}>
                            <HiddenInputDeprecated form={form} formKey={`items[${rowIndex}].${typePath}Id`} />
                            <HiddenInputDeprecated form={form} formKey={`items[${rowIndex}].${typePath}Name`} />

                            {contains(selectedMappingTypes, type) ? (
                              <Col span={6} key={index}>
                                <Form.Item label={t(`calc.vehicles.attrs.${typePath}Identifier`)}>
                                  {getFieldDecorator(`items[${rowIndex}].${typePath}Identifier`, { rules: [validations.none] })(
                                    <Select
                                      {...selectStandardProps} allowClear size="small"
                                      options={(brandMapping && brandMapping.models ? brandMapping.models : []).map(item => ({
                                        value: item.id + VEHICLE_IDENTIFIER_DELIMITER + item.name,
                                        label: `${item.name} (${item.id})`
                                      }))}
                                      onChange={() => this.handleMappingRowChange(item.id)} />
                                  )}
                                </Form.Item>
                              </Col>
                            ) : (
                              <HiddenInputDeprecated form={form} formKey={`items[${rowIndex}].${typePath}Identifier`} />
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Row>
                  </Col>

                  <Col span={4} className="right-align">
                    <Button size="small" icon={<ReloadOutlined />} className="margin-bottom-tiny"
                            style={{ width: "100px" }} onClick={() => this.handleRowResetClick(item.id, rowIndex)}>
                      {t("common.reset")}
                    </Button>
                    <br />

                    <Popconfirm
                      title={t("calc.vehicles.titles.modelDelete")}
                      icon={<PopconfirmDeleteIcon />}
                      okText={t("common.yes")}
                      cancelText={t("common.no")}
                      okType="danger"
                      disabled>
                      <Button size="small" icon={<DeleteOutlined />} disabled danger
                              className="margin-bottom-tiny" style={{ width: "100px" }}>
                        {t("common.delete")}
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </List.Item>
            )} />

          <Button className="margin-top-medium" type="primary" htmlType="submit" icon={<SaveOutlined />}>
            {t("common.save")}
          </Button>
        </Form>

        <VehicleModelCreateMappingModal
          visible={this.state.createMappingModalVisible}
          selectedBrand={currentPage.brand}
          selectedMappingTypes={selectedMappingTypes}
          mappingData={mappingData}
          errorResponse={this.props.createMappingErrorResponse}
          inProgress={this.props.createMappingRequestInProgress}
          locationKey={this.props.location.key}
          onFormSubmit={this.props.onCreateMapping}
          onErrorResponseDelete={this.props.onErrorResponseDelete}
          onFormCancel={this.handleCreateMappingModalCancel} />
      </>
    );
  }
}

export default Form.create<Props>()(VehicleModelMappingsListForm);
