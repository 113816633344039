import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { push, replace } from "connected-react-router";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CommissionsFilterPageRequest, CommissionsFilterPageResult, ImportedCommission } from "../types";
import { ActionProps, RootState } from "../../../../common/types";
import { InstitutionWithProducts } from "../../../enumerations/types";
import { PostponementReason } from "../enums";
import {
  createCommissionActions,
  deleteCommissionActions,
  deleteStateCommissionsPageAction,
  filterCommissionsActions,
  postponeCommissionActions,
  selectCommissionsPage,
  tryToIncludeCommissionActions,
  updateCommissionActions
} from "../ducks";
import { selectRouterLocationSearch } from "../../../ducks";
import { selectProductsClassificationEnumerations } from "../../../enumerations/ducks";
import { appendSearchParamsToCurrentPathname } from "../../../../common/utils/utils";
import { serializeParams } from "../../../../common/utils/apiUtils";
import { rowGutter } from "../../../../common/constants";
import t from "../../../../app/i18n";

import ImportedCommissionFilterHeaderView from "../components/views/ImportedCommissionFilterHeaderView";
import ImportedCommissionsTableView from "../components/views/ImportedCommissionTableView";
import ImportedCommissionForm from "../components/forms/ImportedCommissionForm";
import ImportedCommissionPostponeForm from "../components/forms/ImportedCommissionPostponeForm";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";

interface StateProps {
  commissionsPage: CommissionsFilterPageResult;
  institutionEnums: InstitutionWithProducts[];
  initialUrlSearchQuery: string;
}

interface ActionsMap {
  filterCommissions: typeof filterCommissionsActions.request;
  createCommission: typeof createCommissionActions.request;
  updateCommission: typeof updateCommissionActions.request;
  postponeCommission: typeof postponeCommissionActions.request;
  tryToIncludeCommission: typeof tryToIncludeCommissionActions.request;
  deleteCommission: typeof deleteCommissionActions.request;
  deleteStateCommissionsPage: typeof deleteStateCommissionsPageAction;
  pushNavigation: typeof push;
  replaceNavigation: typeof replace;
}

const PAGE_SIZE = 50;

const CommissionsBatchManualImportsContainer = (props: StateProps & ActionProps<ActionsMap> & RouteComponentProps) => {

  const [commissionFormVisible, setCommissionFormVisible] = useState<boolean>(false);
  const [commissionToUpdate, setCommissionToUpdate] = useState<ImportedCommission>();
  const [commissionToPostpone, setCommissionToPostpone] = useState<ImportedCommission>();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.initialUrlSearchQuery);
    props.actions.filterCommissions({
      id: props.match.params["id1"],
      object: {
        pageIndex: 0,
        pageSize: PAGE_SIZE,
        keyword: urlParams.get("keyword"),
        onlyManual: true,
        onlyPostponed: urlParams.get("onlyPostponed") === "true",
        postponementReasons: urlParams.getAll("postponementReasons").map(reason => PostponementReason[reason]),
        institutionIds: [props.match.params["id2"]]
      }
    });
    return () => props.actions.deleteStateCommissionsPage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSubmit = (filter: CommissionsFilterPageRequest): void => {
    props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(
      serializeParams({ ...filter, keyword: filter.keyword || null })));
    props.actions.filterCommissions({
      id: props.match.params["id1"],
      object: {
        ...filter,
        pageIndex: 0,
        pageSize: PAGE_SIZE,
        onlyManual: true,
        institutionIds: [props.match.params["id2"]]
      }
    });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, onlyManual, onlyPostponed, postponementReasons, institutionIds } = props.commissionsPage;
    props.actions.filterCommissions({
      id: props.match.params["id1"],
      object: {
        pageIndex: pageNumber - 1,
        pageSize,
        keyword,
        onlyManual,
        onlyPostponed,
        postponementReasons,
        institutionIds
      }
    });
  };

  const handleBackToBatchClick = (): void => {
    props.actions.pushNavigation(`/commissions/batches/${props.match.params["id1"]}`);
  };

  const handleCommissionCreateClick = (): void => {
    setCommissionFormVisible(true);
  };

  const handleCommissionUpdateClick = (commission: ImportedCommission): void => {
    setCommissionFormVisible(true);
    setCommissionToUpdate(commission);
  };

  const handleCommissionFormCancel = (): void => {
    setCommissionFormVisible(false);
    setCommissionToUpdate(null);
  };

  const handleCommissionPostponeClick = (commission: ImportedCommission): void => {
    setCommissionToPostpone(commission);
  };

  const handleCommissionPostponeFormCancel = (): void => {
    setCommissionToPostpone(null);
  };

  return (
    <DisplayWrapper itemLoaded={!!props.commissionsPage.include}>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <h2>
            {t("commissions.batch.titles.manageManualImports", {
              institutionName: props.institutionEnums.find(i => i.id === props.match.params["id2"])?.name || ""
            })}
          </h2>
        </Col>
        <Col span={12} className="right-align">
          <Button icon={<PlusOutlined />} type="primary" onClick={handleCommissionCreateClick}>
            {t("commissions.batch.actions.createImportedCommission")}
          </Button>
        </Col>
      </Row>

      <ImportedCommissionFilterHeaderView
        filter={props.commissionsPage}
        onFilterSubmit={handleFilterSubmit}
        onBackToBatchClick={handleBackToBatchClick} />

      <ImportedCommissionsTableView
        commissionsPage={props.commissionsPage}
        batchId={props.match.params["id1"]}
        onPageChange={handleTablePageChange}
        onUpdateClick={handleCommissionUpdateClick}
        onPostponeClick={handleCommissionPostponeClick}
        onTryToInclude={props.actions.tryToIncludeCommission}
        onDelete={props.actions.deleteCommission} />

      <ImportedCommissionForm
        commission={commissionToUpdate}
        batchId={props.match.params["id1"]}
        institutionId={props.match.params["id2"]}
        visible={commissionFormVisible}
        onCreate={props.actions.createCommission}
        onUpdate={props.actions.updateCommission}
        onFormCancel={handleCommissionFormCancel} />

      <ImportedCommissionPostponeForm
        commission={commissionToPostpone}
        batchId={props.match.params["id1"]}
        visible={!!commissionToPostpone}
        onPostpone={props.actions.postponeCommission}
        onFormCancel={handleCommissionPostponeFormCancel} />
    </DisplayWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  commissionsPage: selectCommissionsPage(state),
  institutionEnums: selectProductsClassificationEnumerations(state),
  initialUrlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterCommissions: filterCommissionsActions.request,
    createCommission: createCommissionActions.request,
    updateCommission: updateCommissionActions.request,
    postponeCommission: postponeCommissionActions.request,
    tryToIncludeCommission: tryToIncludeCommissionActions.request,
    deleteCommission: deleteCommissionActions.request,
    deleteStateCommissionsPage: deleteStateCommissionsPageAction,
    pushNavigation: push,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(CommissionsBatchManualImportsContainer);
