import React, { ReactText } from "react";
import { Col, Divider, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { RealtyCalcHouseOrRecreationalBuildingData } from "../../../types";
import { BuildingType, ConstructionMaterial, RoofType } from "../../../enums";
import validations from "../../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import RealtyCalcFloorAreasSection from "./sections/RealtyCalcFloorAreasSection";
import InputNumberWithAddon from "../../../../../../../common/components/form/components/InputNumberWithAddon";

export interface Props {
  form: WrappedFormUtils;
  initialData: RealtyCalcHouseOrRecreationalBuildingData;
  buildingType: BuildingType;
}

interface State {
  readonly roofType: RoofType;
  readonly buildArea: number;
}

class RealtyCalcHouseStep extends React.Component<Props, State> {
  readonly state: State = {
    roofType: this.props.initialData ? this.props.initialData.roofType : undefined,
    buildArea: this.props.initialData ? this.props.initialData.buildArea : undefined,
  };

  handleRoofTypeChange = (roofType: RoofType): void => {
    this.setState({ roofType });
  };

  handleBuildAreaChange = (buildArea: ReactText): void => {
    this.setState({ buildArea: buildArea as number });
  };

  render(): React.ReactNode {
    const { initialData } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { roofType, buildArea } = this.state;
    const colSpan = 8;

    return (
      <>
        <Row gutter={rowGutter}>
          <Divider>
            {t("calc.realty.sections." + (this.props.buildingType === BuildingType.HOUSE ? "house" : "recreationalBuilding"))}
          </Divider>

          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.enums.constructionMaterial._label")}>
              {getFieldDecorator("houseOrRecreationalBuildingData.constructionMaterial", { rules: [validations.notNull] })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(ConstructionMaterial).map(material => ({
                    value: material,
                    label: t("calc.realty.enums.constructionMaterial." + material)
                  }))} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.enums.roofType._label")}>
              {getFieldDecorator("houseOrRecreationalBuildingData.roofType", { rules: [validations.notNull] })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(RoofType).map(type => ({
                    value: type,
                    label: t("calc.realty.enums.roofType." + type)
                  }))}
                  onChange={this.handleRoofTypeChange} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea")}>
              {getFieldDecorator("houseOrRecreationalBuildingData.buildArea", {
                rules: [validations.notNull], initialValue: buildArea
              })(
                <InputNumberWithAddon
                  addonType="area"
                  formatStyle="integer"
                  min={1}
                  onChange={this.handleBuildAreaChange} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Divider className="divider-subheader">{t("calc.realty.sections.areas")}</Divider>

          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.houseOrRecreationalBuildingData.cellarArea")}>
              {getFieldDecorator("houseOrRecreationalBuildingData.cellarArea", {
                rules: [validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))]
              })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={0} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.houseOrRecreationalBuildingData.residentialCellarArea")}>
              {getFieldDecorator("houseOrRecreationalBuildingData.residentialCellarArea", {
                rules: [validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))],
              })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={0} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.houseOrRecreationalBuildingData.atticArea")}>
              {getFieldDecorator("houseOrRecreationalBuildingData.atticArea", {
                rules: [validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))]
              })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={0} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            {roofType === RoofType.PITCHED_WITH_ATTIC && (
              <Form.Item label={t("calc.realty.attrs.houseOrRecreationalBuildingData.residentialAtticArea")}>
                {getFieldDecorator("houseOrRecreationalBuildingData.residentialAtticArea", {
                  rules: [validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))],
                })(
                  <InputNumberWithAddon addonType="area" formatStyle="integer" min={0} />
                )}
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.houseOrRecreationalBuildingData.garageArea")}>
              {getFieldDecorator("houseOrRecreationalBuildingData.garageArea", { rules: [validations.none] })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={0} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Divider className="divider-subheader">{t("calc.realty.sections.overgroundAreas")}</Divider>
        <RealtyCalcFloorAreasSection
          form={this.props.form}
          initialFloorAreasSize={initialData && initialData.overgroundFloorAreas ? initialData.overgroundFloorAreas.length : null}
          buildArea={buildArea} />
      </>
    );
  }
}

export default RealtyCalcHouseStep;
