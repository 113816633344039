import React, { useEffect } from "react";
import { Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import { CheckboxOptionType } from "antd/lib/checkbox";
import { Store } from "rc-field-form/lib/interface";
import { CreateProduct } from "../../types";
import { UUID } from "../../../../../common/types";
import { ProductType } from "../../enums";
import { InstitutionType } from "../../../institution/enums";
import { InsuranceType } from "../../../../contract/enums";
import { createProductActions } from "../../ducks";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import InstitutionsEnumFormItemSelect from "../../../../enumerations/components/form/InstitutionsEnumFormItemSelect";

export interface Props {
  productGroupId: UUID;
  visible: boolean;
  onFormSubmit: typeof createProductActions.request;
  onFormCancel(): void;
}

const ProductCreateForm = ({ productGroupId, visible, onFormSubmit, onFormCancel }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "admin.product.attrs", requests.CREATE_PRODUCT);

  const inProgress = useRequestFinishedCallback(requests.CREATE_PRODUCT, onFormCancel);

  useEffect(() => {
    if ( visible ) {
      form.resetFields();
    }
  }, [visible, form]);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: CreateProduct | Store) => {
        onFormSubmit({ id: productGroupId, object: values as CreateProduct });
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 8;

  return (
    <Modal
      width={700}
      visible={visible}
      title={t("admin.product.titles.newProduct")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}>

      <Form form={form} layout="vertical" name="productCreateForm">

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("admin.product.attrs.productName")}
              rules={[validations.notBlank, validations.size(1, 1024)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="type"
              label={t("admin.product.enums.productType._label")}
              rules={[validations.notNull]}
              initialValue={ProductType.INSURANCE_PRODUCT}>
              <Radio.Group
                options={Object.keys(ProductType).map<CheckboxOptionType>(type => ({
                  value: type,
                  label: t("admin.product.enums.productType." + type)
                }))} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
              {({ getFieldValue }) => {
                const productType = getFieldValue(["type"]);
                return !productType || productType === ProductType.INSURANCE_PRODUCT ? (
                  <Form.Item
                    name="insuranceType"
                    label={t("contract.enums.insuranceType._label")}
                    rules={[validations.notNull]}>
                    <Select
                      {...selectStandardProps}
                      options={Object.keys(InsuranceType).map(type => ({
                        value: type,
                        label: t("contract.enums.insuranceType." + type)
                      }))} />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan * 3}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
              {({ getFieldValue }) => {
                const productType = getFieldValue(["type"]);
                return !productType || productType === ProductType.INSURANCE_PRODUCT ? (
                  <InstitutionsEnumFormItemSelect
                    formItemProps={{
                      name: "insuranceInstitutionIds",
                      label: t("admin.product.attrs.insuranceInstitutionIds"),
                      rules: [validations.notNull]
                    }}
                    selectProps={{ mode: "multiple" }}
                    optionsProps={{ filterType: InstitutionType.INSURANCE_COMPANY }} />
                ) : (
                  <InstitutionsEnumFormItemSelect
                    formItemProps={{
                      name: "bankInstitutionIds",
                      label: t("admin.product.attrs.bankInstitutionIds"),
                      rules: [validations.notNull]
                    }}
                    selectProps={{ mode: "multiple" }}
                    optionsProps={{ filterType: InstitutionType.BANK }} />
                );
              }}
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </Modal>
  );
}

export default React.memo(ProductCreateForm);

