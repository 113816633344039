import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Divider, Form, Input, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { CreateUpdateInsuranceContract, CreateUpdateVehicleInsurance, InsuranceContract } from "../../../../types";
import { Client } from "../../../../../client/types";
import { FieldConstraintViolation, RootState, UUID } from "../../../../../../common/types";
import { InstitutionWithProducts } from "../../../../../enumerations/types";
import { AuthorizedClientCompanyFunction } from "../../../../enums";
import { ClientFormStage, ClientSearchActionType, ClientType } from "../../../../../client/enums";
import { InstitutionType } from "../../../../../admin/institution/enums";
import { selectProductsClassificationEnumerations } from "../../../../../enumerations/ducks";
import { VEHICLE_INSURANCE_TYPES } from "../../../../utils";
import { useClientSearch } from "../../../../../client/utils";
import { contains, isDefinedValue } from "../../../../../../common/utils/utils";
import { resolveFormValidationError, selectStandardProps } from "../../../../../../common/utils/formUtils";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { validationFunctions, validations } from "../../../../../../common/utils/validationUtils";
import { tInterval } from "../../../../../../common/utils/translationUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import ClientDrawerCreateForm from "../../../../../client/components/drawers/ClientDrawerCreateForm";
import ClientDrawerUpdateForm from "../../../../../client/components/drawers/ClientDrawerUpdateForm";
import ClientSearchInput from "../../../../../client/components/ClientSearchInput";
import InstitutionsEnumFormItemSelect from "../../../../../enumerations/components/form/InstitutionsEnumFormItemSelect";
import ProductGroupsEnumFormItemSelect
  from "../../../../../enumerations/components/form/ProductGroupsEnumFormItemSelect";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import AddDeleteButton from "../../../../../../common/components/buttons/AddDeleteButton";
import DeleteIcon from "../../../../../../common/components/icons/DeleteIcon";

export interface Props {
  initialContract?: InsuranceContract;
  form: FormInstance;
  clients: Client[];
  clientsViolationErrors: Map<number, FieldConstraintViolation[]>;
  onClientChange(client: Client, index: number): void;
  onClientDelete(index: number): void;
  onClientViolationErrorsChange(errorsMap: Map<number, FieldConstraintViolation[]>);
}

const InsuranceContractFormHeaderSection = ({ initialContract, form, clients, ...props }: Props) => {

  const clientSearch = useClientSearch();
  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnumerations);

  const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);
  const [updateFormVisible, setUpdateFormVisible] = useState<boolean>(false);

  const [clientsCount, setClientsCount] = useState<number>(initialContract?.clients.length || 1);
  const [authorizedClientsCount, setAuthorizedClientsCount] = useState<number>(
    isDefinedValue(initialContract?.authorizedClient1Index) && isDefinedValue(initialContract?.authorizedClient2Index) ? 2 : 1);

  const [processedClientIndex, setProcessedClientIndex] = useState<number>();
  const [clientStages, setClientStages] = useState<ClientFormStage[]>(initialContract?.clients.map(() => ClientFormStage.SELECTED) || []);

  useEffect(() => {
    if ( isDefinedValue(processedClientIndex)
      && clientSearch.result.keyword === form.getFieldValue(["clientIdentifiers", processedClientIndex]) ) {
      if ( clientSearch.result.data ) {
        setClientStage(ClientFormStage.EXISTING, processedClientIndex);
        props.onClientChange(clientSearch.result.data, processedClientIndex);
      }
      else {
        setClientStage(ClientFormStage.NEW, processedClientIndex);
      }
    }
  }, [clientSearch.result]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleInsuranceInstitutionIdChange = (institutionId: UUID): void => {
    const institution = productsClassification.find(institution => institution.id === institutionId);
    const contract = form.getFieldsValue([["productGroupId"], ["insurances"]]) as CreateUpdateInsuranceContract;

    const productGroup = contract.productGroupId
      ? institution.productGroups.find(group => group.id === contract.productGroupId) : null

    form.setFieldsValue({
      productGroupId: productGroup ? contract.productGroupId : null,
      insurances: (contract.insurances || []).map(insurance => {
        const updatedInsurance = {
          ...insurance,
          productId: productGroup
            ? productGroup.products.find(product => product.id === insurance.productId) ? insurance.productId : null
            : null
        }

        if ( contains(VEHICLE_INSURANCE_TYPES, insurance.type) ) {
          const updatedVehicleInsurance = updatedInsurance as CreateUpdateVehicleInsurance;
          if ( updatedVehicleInsurance.insuranceData ) {
            delete updatedVehicleInsurance.insuranceData["healthCoverageLimit"];
            delete updatedVehicleInsurance.insuranceData["propertyCoverageLimit"];
            delete updatedVehicleInsurance.insuranceData["coverageLimits"];
            delete updatedVehicleInsurance.insuranceData["complicity"];

            if ( updatedVehicleInsurance.insuranceData["mtpl"] ) {
              delete updatedVehicleInsurance.insuranceData["mtpl"]["healthCoverageLimit"];
              delete updatedVehicleInsurance.insuranceData["mtpl"]["propertyCoverageLimit"];
              delete updatedVehicleInsurance.insuranceData["mtpl"]["coverageLimits"];
            }
          }
        }

        return updatedInsurance;
      })
    });
  }

  const handleProductGroupChange = (): void => {
    const { insurances } = form.getFieldsValue([["insurances"]]) as CreateUpdateInsuranceContract;
    form.setFieldsValue({
      insurances: (insurances || []).map(insurance => ({ ...insurance, productId: null }))
    });
  }

  const handleClientSearchActionClick = (index: number, actionType: ClientSearchActionType): void => {
    switch ( actionType ) {
      case ClientSearchActionType.CREATE:
        setProcessedClientIndex(index);
        setCreateFormVisible(true);
        break;
      case ClientSearchActionType.UPDATE:
        setProcessedClientIndex(index);
        setUpdateFormVisible(true);
        break;
      case ClientSearchActionType.DELETE:
        if ( index === 0 ) {
          const updatedIdentifiers = [...((form.getFieldValue("clientIdentifiers") as string[]) || [])];
          deselectAuthorizedClientAndClientOnInsurances(updatedIdentifiers[index]);
          updatedIdentifiers[index] = null;
          form.setFieldsValue({ clientIdentifiers: updatedIdentifiers });

          const updatedClientStages = [...clientStages];
          updatedClientStages[index] = null;
          setClientStages(updatedClientStages);

          deleteClientViolationErrors(index);
          setProcessedClientIndex(null);
          props.onClientChange(null, index);
        }
        else {
          const updatedIdentifiers = [...((form.getFieldValue("clientIdentifiers") as string[]) || [])];
          const deletedIdentifiers = updatedIdentifiers.splice(index, 1);
          deselectAuthorizedClientAndClientOnInsurances(deletedIdentifiers[0]);
          form.setFieldsValue({ clientIdentifiers: updatedIdentifiers });

          const updatedClientStages = [...clientStages];
          updatedClientStages.splice(index, 1);
          setClientStages(updatedClientStages);

          const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>();
          props.clientsViolationErrors.forEach((value, key) => {
            if ( key < index ) {
              updatedViolationsErrors.set(key, value);
            }
            else if ( key > index ) {
              updatedViolationsErrors.set(key - 1, value);
            }
          });
          props.onClientViolationErrorsChange(updatedViolationsErrors);

          setProcessedClientIndex(null);
          setClientsCount(clientsCount - 1);
          props.onClientDelete(index);
        }
        break;
    }
  };

  const handleClientSearchSubmit = (value: string, index: number): void => {
    form.validateFields([["clientIdentifiers", index]])
      .then(() => clientSearch.onSearch({ keyword: value }))
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, index: number): void => {
    if ( clientStages[index] ) {
      setClientStage(index === 0 ? null : ClientFormStage.EMPTY, index);
    }
    if ( clients[index] ) {
      deselectAuthorizedClientAndClientOnInsurances(clients[index].identifier);
      props.onClientChange(null, index);
    }
    if ( props.clientsViolationErrors.has(index) ) {
      deleteClientViolationErrors(index);
    }

    handleClientSearchSubmit(value, index);
  };

  const handleClientAdd = (): void => {
    setClientStage(ClientFormStage.EMPTY, clientsCount);
    setClientsCount(clientsCount + 1);
  };

  const handleAuthorizedClientAdd = (): void => {
    setAuthorizedClientsCount(authorizedClientsCount + 1);
  }

  const handleAuthorizedClientDelete = (index: number) => {
    if ( index === 0 ) {
      const contract = form.getFieldsValue([["authorizedClient2Identifier"], ["authorizedClient2Function"]]) as CreateUpdateInsuranceContract;
      form.setFieldsValue({
        authorizedClient1Identifier: contract.authorizedClient2Identifier,
        authorizedClient1Function: contract.authorizedClient2Function,
        authorizedClient2Identifier: null,
        authorizedClient2Function: null
      });
    }
    else {
      form.setFieldsValue({ authorizedClient2Identifier: null, authorizedClient2Function: null })
    }
    setAuthorizedClientsCount(authorizedClientsCount - 1);
  }

  const handleCreateClientFormSubmit = (client: Client, index: number): void => {
    setCreateFormVisible(false);
    setProcessedClientIndex(null);
    setClientStage(ClientFormStage.SELECTED, index);
    props.onClientChange(client, index);
  };

  const handleUpdateClientFormSubmit = (client: Client, index: number): void => {
    setUpdateFormVisible(false);
    setProcessedClientIndex(null);
    setClientStage(ClientFormStage.SELECTED, index);
    deleteClientViolationErrors(index);
    props.onClientChange(client, index);
  }

  const getClientFormIdentifier = (index: number): string => {
    return ((form.getFieldValue("clientIdentifiers") as string[]) || [])[index];
  }

  const setClientStage = (stage: ClientFormStage, index: number): void => {
    const updatedClientStages = [...clientStages];
    updatedClientStages[index] = stage;
    setClientStages(updatedClientStages);
  }

  const deselectAuthorizedClientAndClientOnInsurances = (identifier: string): void => {
    if ( identifier && validationFunctions.validatePinOrCrn(identifier) ) {
      const contract = form.getFieldsValue() as CreateUpdateInsuranceContract;

      if ( contract.authorizedClient1Identifier === identifier ) {
        form.setFieldsValue({ authorizedClient1Identifier: null, authorizedClient1Function: null });
      }
      if ( contract.authorizedClient2Identifier === identifier ) {
        form.setFieldsValue({ authorizedClient2Identifier: null, authorizedClient2Function: null });
      }

      form.setFieldsValue({
        insurances: (contract.insurances || []).map(formInsurance => {
          const insurance = { ...formInsurance };
          insurance["insuredClientIdentifier"] = insurance["insuredClientIdentifier"] === identifier ? null : insurance["insuredClientIdentifier"];
          insurance["vehicleHolderIdentifier"] = insurance["vehicleHolderIdentifier"] === identifier ? null : insurance["vehicleHolderIdentifier"];
          insurance["vehicleOwnerIdentifier"] = insurance["vehicleOwnerIdentifier"] === identifier ? null : insurance["vehicleOwnerIdentifier"];
          insurance["vinculationClientIdentifier"] = insurance["vinculationClientIdentifier"] === identifier ? null : insurance["vinculationClientIdentifier"];
          return insurance;
        })
      });
    }
  };

  const deleteClientViolationErrors = (index: number): void => {
    const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>([...props.clientsViolationErrors]);
    updatedViolationsErrors.delete(index);
    props.onClientViolationErrorsChange(updatedViolationsErrors);
  };

  const colSpan = 4;
  const clientInputColSpan = 5;
  const clientNameColSpan = 6;

  const clientSearchProps = {
    processedType: processedClientIndex,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    inputColSpan: clientInputColSpan,
    clientNameColSpan,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientIndex,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  }

  return (
    <>
      <Card size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.mainData")}>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("contract.attrs.contractNumber")}
              dependencies={["proposalNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull(form, "proposalNumber", t("contract.attrs.proposalNumber"))
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="proposalNumber"
              label={t("contract.attrs.proposalNumber")}
              dependencies={["contractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull(form, "contractNumber", t("contract.attrs.contractNumber"))
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionsEnumFormItemSelect
              formItemProps={{
                name: "insuranceInstitutionId",
                label: t("contract.attrs.insuranceInstitutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                filterType: InstitutionType.INSURANCE_COMPANY,
                selected: initialContract ? [initialContract.insuranceInstitution] : undefined
              }}
              selectProps={{ onChange: handleInsuranceInstitutionIdChange }} />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
              {({ getFieldValue }) => {
                const institutionId = getFieldValue("insuranceInstitutionId");
                return <ProductGroupsEnumFormItemSelect
                  formItemProps={{
                    name: "productGroupId",
                    label: t("contract.attrs.productGroupId"),
                    rules: [validations.notNull]
                  }}
                  optionsProps={{ institutionId: institutionId, hideAll: !!!institutionId }}
                  selectProps={{
                    placeholder: !!!institutionId ? t("contract.helpers.productGroupPlaceholder") : undefined,
                    onChange: handleProductGroupChange
                  }} />
              }}
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("contract.sections.clients")}</Divider>

        <Row gutter={rowGutter}>
          {[...Array(clientsCount)].map((_, index) => (
            <React.Fragment key={index}>
              <ClientSearchInput<number>
                {...clientSearchProps}
                formItemProps={{
                  name: ["clientIdentifiers", index],
                  label: tInterval("contract.attrs.insuranceClientIdentifiers_interval", index),
                  rules: [validations.notBlank, validations.pinOrCrn, validations.noRepeatedClient(form, "clientIdentifiers")]
                }}
                formStage={clientStages[index]}
                formType={index}
                client={clients[index]} />

              <Col span={index === 0 ? 13 : 1} />
            </React.Fragment>
          ))}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <AddDeleteButton type="add" label={t("contract.actions.addClient")} onClick={handleClientAdd} />
          </Col>
        </Row>

        {clients[0]?.type === ClientType.LEGAL && (
          <>
            <Divider orientation="left">{t("contract.sections.authorizedClients")}</Divider>

            <Row gutter={rowGutter}>
              <Col span={clientInputColSpan}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) => prev.authorizedClient2Identifier !== next.authorizedClient2Identifier}>
                  {({ getFieldValue }) => {
                    const authorized2Identifier = getFieldValue(["authorizedClient2Identifier"]);
                    return (
                      <Form.Item
                        name="authorizedClient1Identifier"
                        label={<LabelWithTooltip label={t("contract.attrs.authorizedClient1Identifier")}
                                                 tooltip={t("contract.helpers.authorizedClient")} />}
                        rules={[validations.notBlank, validations.pin]}>
                        <Select
                          {...selectStandardProps}
                          options={clients
                            .filter(c => c?.type === ClientType.NATURAL && c?.identifier !== authorized2Identifier)
                            .map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              </Col>

              <Col span={clientInputColSpan}>
                <Form.Item
                  name="authorizedClient1Function"
                  label={t("contract.enums.authorizedClientCompanyFunction._label")}
                  rules={[validations.notNull]}>
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(AuthorizedClientCompanyFunction).map(companyFunction => ({
                      value: companyFunction,
                      label: t("contract.enums.authorizedClientCompanyFunction." + companyFunction)
                    }))} />
                </Form.Item>
              </Col>

              {authorizedClientsCount === 2 && (
                <>
                  <Col span={1}>
                    <DeleteIcon index={0} onClick={handleAuthorizedClientDelete} />
                  </Col>

                  <Col offset={1} span={clientInputColSpan}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) => prev.authorizedClient1Identifier !== next.authorizedClient1Identifier}>
                      {({ getFieldValue }) => {
                        const authorized1Identifier = getFieldValue(["authorizedClient1Identifier"]);
                        return (
                          <Form.Item
                            name="authorizedClient2Identifier"
                            label={<LabelWithTooltip label={t("contract.attrs.authorizedClient2Identifier")}
                                                     tooltip={t("contract.helpers.authorizedClient")} />}
                            rules={[validations.notBlank, validations.pin]}>
                            <Select
                              {...selectStandardProps}
                              options={clients
                                .filter(c => c?.type === ClientType.NATURAL && c?.identifier !== authorized1Identifier)
                                .map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  </Col>

                  <Col span={clientInputColSpan}>
                    <Form.Item
                      name="authorizedClient2Function"
                      label={t("contract.enums.authorizedClientCompanyFunction._label")}
                      rules={[validations.notNull]}>
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(AuthorizedClientCompanyFunction).map(companyFunction => ({
                          value: companyFunction,
                          label: t("contract.enums.authorizedClientCompanyFunction." + companyFunction)
                        }))} />
                    </Form.Item>
                  </Col>

                  <Col span={1}>
                    <DeleteIcon index={1} onClick={handleAuthorizedClientDelete} />
                  </Col>
                </>
              )}
            </Row>

            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <AddDeleteButton
                  type="add" label={t("contract.actions.addAuthorizedClient")} disabled={authorizedClientsCount === 2}
                  onClick={handleAuthorizedClientAdd} />
              </Col>
            </Row>
          </>
        )}
      </Card>

      <ClientDrawerCreateForm<number>
        visible={createFormVisible}
        initialIdentifier={getClientFormIdentifier(processedClientIndex)}
        formType={processedClientIndex}
        placement="contract"
        onFormSubmit={handleCreateClientFormSubmit} />

      <ClientDrawerUpdateForm<number>
        visible={updateFormVisible}
        client={clients[processedClientIndex]}
        formType={processedClientIndex}
        violationErrors={props.clientsViolationErrors}
        placement="contract"
        onFormSubmit={handleUpdateClientFormSubmit} />
    </>
  )
}

export default InsuranceContractFormHeaderSection;
