import moment, { Moment } from "moment";
import Big from "big.js";
import { Vehicle } from "./types";
import {
  ContractStatus,
  InsuranceContractInsurancePeriod,
  InsuranceContractPaymentFrequency,
  InsuranceContractPeriodType,
  InsuranceType,
  LoanRateType
} from "./enums";
import { getClosestPreviousWorkDay, isDefinedValue } from "../../common/utils/utils";

const INSURANCE_CONTRACT_CANCELLING_TERM_DAYS = 42;
const LOAN_CONTRACT_FIXATION_TERM_MONTHS = 3;

export const VEHICLE_INSURANCE_TYPES = [InsuranceType.MTPL, InsuranceType.CRASH, InsuranceType.GAP, InsuranceType.PAS];

export const parseVehicleModelName = (vehicle: Vehicle): string => {
  return (vehicle.customBrand || vehicle.model.brand.name) + " " + (vehicle.customModel || vehicle.model.name)
}

export const calculateInsuranceContractStatus = (effectiveBeginningDate: Moment,
                                                 effectiveEndDate: Moment,
                                                 cancellationDate: Moment,
                                                 transferredToOtherBrokerDate: Moment): ContractStatus => {
  const now = moment();
  if ( transferredToOtherBrokerDate?.isBefore(now, "day") ) {
    return ContractStatus.TRANSFERRED_TO_BROKER;
  }
  if ( cancellationDate?.isBefore(now, "day") ) {
    return ContractStatus.CANCELED;
  }
  if ( effectiveEndDate?.isBefore(now, "day") ) {
    return ContractStatus.FINISHED;
  }
  return effectiveBeginningDate
    ? effectiveBeginningDate.isSameOrBefore(now, "day") ? ContractStatus.ACTIVE : ContractStatus.UNSTARTED
    : null;
}

export const calculatePartialPremium = (annualPremium: number, paymentFrequency: InsuranceContractPaymentFrequency): number => {
  if ( !isDefinedValue(annualPremium) || !paymentFrequency ) {
    return null;
  }

  switch ( paymentFrequency ) {
    case InsuranceContractPaymentFrequency.ONCE:
    case InsuranceContractPaymentFrequency.ANNUALLY:
      return annualPremium;
    case InsuranceContractPaymentFrequency.SEMI_ANNUALLY:
      return parseFloat(new Big(annualPremium).div(2).round(2, 1).valueOf());
    case InsuranceContractPaymentFrequency.QUARTERLY:
      return parseFloat(new Big(annualPremium).div(4).round(2, 1).valueOf());
    case InsuranceContractPaymentFrequency.MONTHLY:
      return parseFloat(new Big(annualPremium).div(12).round(2, 1).valueOf());
    default:
      return null;
  }
}

/**
 * Vypocet konca poistneho obdobia (KPO)
 */
export const calculateInsurancePeriodEndDate = (effectiveBeginningDate: Moment,
                                                effectiveEndDate: Moment,
                                                cancellationDate: Moment,
                                                periodType: InsuranceContractPeriodType,
                                                insurancePeriod: InsuranceContractInsurancePeriod): Moment => {
  if ( cancellationDate ) {
    return moment(cancellationDate);
  }

  switch ( periodType ) {
    case InsuranceContractPeriodType.DEFINITE:
      if ( effectiveEndDate ) {
        return moment(effectiveEndDate);
      }
      break;
    case InsuranceContractPeriodType.INDEFINITE:
      if ( effectiveBeginningDate ) {
        switch ( insurancePeriod ) {
          case InsuranceContractInsurancePeriod.CALENDAR_YEAR:
            const d = effectiveBeginningDate.isAfter(moment(), "day") ? moment(effectiveBeginningDate) : moment();
            return d.endOf("year");
          case InsuranceContractInsurancePeriod.TECHNICAL_YEAR:
            if ( effectiveBeginningDate.get("year") >= moment().get("year") ) {
              return moment(effectiveBeginningDate).add(1, "year").subtract(1, "day");
            }
            else {
              const z = moment(effectiveBeginningDate).year(moment().get("year")).subtract(1, "day");
              return z.isSameOrBefore(moment(), "day") ? z.add(1, "year") : z;
            }
        }
      }
      break;
  }

  return null;
}

/**
 * Vypocet posledneho datumu na vypoved zmluvy (PDnVZ)
 */
export const calculateLastContractCancellationDate = (status: ContractStatus,
                                                      effectiveBeginningDate: Moment,
                                                      cancellationDate: Moment,
                                                      insurancePeriodEndDate: Moment,
                                                      periodType: InsuranceContractPeriodType): Moment => {
  switch ( status ) {
    case ContractStatus.FINISHED:
    case ContractStatus.CANCELED:
    case ContractStatus.TRANSFERRED_TO_BROKER:
      return null;
    case ContractStatus.UNSTARTED:
    case ContractStatus.ACTIVE:
      if ( cancellationDate ) {
        return null;
      }
      else if ( effectiveBeginningDate && insurancePeriodEndDate ) {
        const d = effectiveBeginningDate.isAfter(moment(), "day") ? moment(effectiveBeginningDate) : moment();
        const k = getClosestPreviousWorkDay(moment(insurancePeriodEndDate).subtract(INSURANCE_CONTRACT_CANCELLING_TERM_DAYS, "day"));

        if ( k.isAfter(d, "day") ) {
          return k;
        }
        else {
          switch ( periodType ) {
            case InsuranceContractPeriodType.DEFINITE:
              return null;
            case InsuranceContractPeriodType.INDEFINITE:
              return getClosestPreviousWorkDay(
                moment(insurancePeriodEndDate).add(1, "year").subtract(INSURANCE_CONTRACT_CANCELLING_TERM_DAYS, "day"));
          }
        }
      }
      break;
  }
  return null;
}

export const calculateLoanContractStatus = (signDate: Moment,
                                            effectiveEndDate: Moment,
                                            cancellationDate: Moment): ContractStatus => {
  const now = moment();
  if ( cancellationDate?.isBefore(now, "day") ) {
    return ContractStatus.CANCELED;
  }
  if ( effectiveEndDate?.isBefore(now, "day") ) {
    return ContractStatus.FINISHED;
  }
  return signDate
    ? signDate.isSameOrBefore(now, "day") ? ContractStatus.ACTIVE : ContractStatus.UNSTARTED
    : null;
}

export const calculateFixationAnniversaryDate = (signDate: Moment, rateType: LoanRateType): Moment => {
  if ( signDate && rateType ) {
    switch ( rateType ) {
      case LoanRateType.FIXED_ONE_YEAR:
        return moment(signDate).add(1, "year");
      case LoanRateType.FIXED_TWO_YEARS:
        return moment(signDate).add(2, "year");
      case LoanRateType.FIXED_THREE_YEARS:
        return moment(signDate).add(3, "year");
      case LoanRateType.FIXED_FOUR_YEARS:
        return moment(signDate).add(4, "year");
      case LoanRateType.FIXED_FIVE_YEARS:
        return moment(signDate).add(5, "year");
      case LoanRateType.FIXED_SIX_YEARS:
        return moment(signDate).add(6, "year");
      case LoanRateType.FIXED_SEVEN_YEARS:
        return moment(signDate).add(7, "year");
      case LoanRateType.FIXED_EIGHT_YEARS:
        return moment(signDate).add(8, "year");
      case LoanRateType.FIXED_NINE_YEARS:
        return moment(signDate).add(9, "year");
      case LoanRateType.FIXED_TEN_YEARS:
        return moment(signDate).add(10, "year");
      case LoanRateType.VARIABLE:
        return null;
    }
  }
  return null;
}

export const calculateContactClientDate = (fixationAnniversaryDate: Moment): Moment => {
  return fixationAnniversaryDate
    ? getClosestPreviousWorkDay(moment(fixationAnniversaryDate).subtract(LOAN_CONTRACT_FIXATION_TERM_MONTHS, "month"))
    : null;
}
