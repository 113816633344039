import React from "react";
import { Link } from "react-router-dom";
import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ImportedCommission } from "../../types";
import { PageResult, UUID } from "../../../../../common/types";
import { PostponementReason } from "../../enums";
import { deleteCommissionActions, tryToIncludeCommissionActions } from "../../ducks";
import { paginationStandardProps } from "../../../../../common/utils/formUtils";
import { formatInstitutionName, formatLocaleCurrency, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import t from "../../../../../app/i18n";

import ImportedCommissionExtendedTableView from "./ImportedCommissionExtendedTableView";
import CommissionTypeTag from "../CommissionTypeTag";
import PostponementReasonTag from "../PostponementReasonTag";
import InstitutionTypeTag from "../../../../admin/institution/components/InstitutionTypeTag";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  commissionsPage: PageResult<ImportedCommission>;
  batchId: UUID;
  showInitialBatchColumn?: boolean;
  onTryToInclude: typeof tryToIncludeCommissionActions.request;
  onDelete: typeof deleteCommissionActions.request;
  onPageChange(pageNumber: number): void;
  onUpdateClick(commission: ImportedCommission): void;
  onPostponeClick(commission: ImportedCommission): void;
}

const ImportedCommissionsTableView = (props: Props) => {

  const columns: ColumnsType<ImportedCommission> = [
    {
      key: "institution",
      title: t("commissions.batch.attrs.commission.institution"),
      render: (value, record) => <>
        <InstitutionTypeTag type={record.institution.type} /> {formatInstitutionName(record.institution)}
      </>
    },
    {
      key: "contract",
      title: t("commissions.batch.attrs.commission.contract"),
      render: (value, record) => record.contract ? (
        <>
          {record.contractNumber
          && <><Link to={`/contracts/${record.contract.id}`} target="_blank">{record.contractNumber}</Link><br /></>}
          {record.proposalOrLoanNumber
          && <Link to={`/contracts/${record.contract.id}`} target="_blank">{record.proposalOrLoanNumber}</Link>}
        </>
      ) : (
        <>{record.contractNumber}<br />{record.proposalOrLoanNumber}</>
      )
    },
    {
      key: "clientName",
      title: t("commissions.batch.attrs.commission.clientName"),
      dataIndex: "clientName"
    },
    {
      key: "startDate",
      title: t("commissions.batch.attrs.commission.startDate"),
      render: (value, record) => formatLocaleDate(record.startDate)
    },
    {
      key: "endDate",
      title: t("commissions.batch.attrs.commission.endDate"),
      render: (value, record) => formatLocaleDate(record.endDate)
    },
    {
      key: "commissionType",
      title: t("commissions.batch.enums.commissionType._label"),
      render: (value, record) => <CommissionTypeTag type={record.commissionType} />
    },
    {
      key: "commissionBaseAmount",
      title: t("commissions.batch.attrs.commission.commissionBaseAmount"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrency(record.commissionBaseAmount)
    },
    {
      key: "commissionAmount",
      title: t("commissions.batch.attrs.commission.commissionAmount"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrency(record.commissionAmount)
    },
    {
      key: "postponed",
      title: t("commissions.batch.enums.postponementReason._label"),
      render: (value, record) =>
        <PostponementReasonTag
          reason={record.postponementReason}
          manualPostponementReason={record.manualPostponementReason} />
    }
  ];

  if ( props.showInitialBatchColumn ) {
    columns.push({
      key: "initialBatch",
      title: t("commissions.batch.attrs.commission.initialBatch"),
      render: (value, record) => record.initialBatch.name
    });
  }

  columns.push({
    key: "actions",
    className: "table-column-right-align",
    render: ((value, record) => (
      <>
        {!record.postponed ? (
          <ActionTextIcon type="branch" color="orange" text={t("common.postpone")}
                          onClick={() => props.onPostponeClick(record)} />
        ) : (
          <ActionTextIcon type="pull-request" color="green" text={t("common.include")}
                          disabled={record.postponementReason === PostponementReason.IMPORT_ERROR}
                          onClick={() => props.onTryToInclude({
                            id1: props.batchId,
                            id2: record.id,
                            object: { optimisticLockVersion: record.optimisticLockVersion }
                          })} />
        )}

        <Divider type="vertical" />

        <ActionTextIcon type="edit" color="blue" text={t("common.edit")}
                        onClick={() => props.onUpdateClick(record)} />

        <Divider type="vertical" />

        <Popconfirm
          title={t("commissions.batch.helpers.deleteCommissionConfirm")}
          icon={<PopconfirmDeleteIcon />}
          okText={t("common.yes")}
          cancelText={t("common.no")}
          okType="danger"
          onConfirm={() => props.onDelete({ id1: props.batchId, id2: record.id })}>
          <span><ActionTextIcon type="delete" color="red" text={t("common.delete")} /></span>
        </Popconfirm>
      </>
    ))
  });

  return (
    <Table<ImportedCommission>
      size="small"
      className="table-row-stripped"
      rowKey="id"
      columns={columns}
      dataSource={props.commissionsPage.pageData}
      expandable={{
        rowExpandable: record => !!record.errors.length,
        expandedRowRender: record => <ImportedCommissionExtendedTableView commission={record} />
      }}
      pagination={{
        ...paginationStandardProps,
        current: props.commissionsPage.pageIndex + 1,
        pageSize: props.commissionsPage.pageSize,
        total: props.commissionsPage.totalElementsCount,
        onChange: props.onPageChange,
      }} />
  )
};

export default ImportedCommissionsTableView;
