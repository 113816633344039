import React from "react";
import moment, { Moment } from "moment";
import { Card, Checkbox, Col, DatePicker, Divider, Input, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { VehicleCalc, VehicleCalcType } from "../../../types";
import { Client } from "../../../../../../client/types";
import {
  GapDuration,
  GlassReinsuranceAmount,
  ReplacementVehicleCategory,
  WustenrotContractsCount
} from "../../../enums";
import { CalcType } from "../../../../../enums";
import { InsuranceContractPaymentFrequency, VehicleCategory } from "../../../../../../contract/enums";
import { ClientType } from "../../../../../../client/enums";
import validations from "../../../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerPast,
  selectStandardProps
} from "../../../../../../../common/utils/formUtils";
import { REINSURANCES_MAP } from "../../../utils";
import { contains } from "../../../../../../../common/utils/utils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

export interface Props {
  form: WrappedFormUtils;
  initialData: VehicleCalc;
  calcType: VehicleCalcType;
  firstRegistrationDate: Moment;
  category: VehicleCategory;
  glass: boolean;
  gap: boolean;
  replacementVehicle: boolean;
  holder: Client;
  onGlassChange(event: CheckboxChangeEvent): void;
  onGapChange(event: CheckboxChangeEvent): void;
  onReplacementVehicleChange(event: CheckboxChangeEvent): void;
}

interface State {
  readonly axaContractEnabled: boolean;
  readonly wustenrotContractEnabled: boolean;
}

class VehicleCalcGeneralAndReinsurancesDataSection extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const stateObject = {
      axaContractEnabled: false,
      wustenrotContractEnabled: false
    };

    if ( props.initialData ) {
      const { generalData } = props.initialData;

      stateObject.axaContractEnabled = !!generalData.crossSelling.axaContractNumber;
      stateObject.wustenrotContractEnabled = !!generalData.crossSelling.wustenrotContractsCount;
    }

    this.state = stateObject;
  }

  handleAxaContractEnabledChange = (event: CheckboxChangeEvent): void => {
    this.setState({ axaContractEnabled: event.target.checked });
  };

  handleWustenrotContractEnabledChange = (event: CheckboxChangeEvent): void => {
    this.setState({ wustenrotContractEnabled: event.target.checked });
  };

  render(): React.ReactNode {
    const { axaContractEnabled, wustenrotContractEnabled } = this.state;
    const { form, calcType, firstRegistrationDate, category, glass, gap, replacementVehicle, holder } = this.props;
    const { getFieldDecorator } = form;

    const colSpan = 4;
    const shrinkedColSpan = 8;
    const twoColumnColSpan = 12;

    return (
      <Card size="small" type="inner" className="card-box" title={t("calc.vehicle.sections.generalData")}>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.generalData.effectiveBeginningDate")}>
              {getFieldDecorator("generalData.effectiveBeginningDate", {
                rules: [validations.notNull,
                  validations.dateInInterval(
                    firstRegistrationDate && firstRegistrationDate.isAfter(moment(), "day") ? firstRegistrationDate : moment(),
                    moment().add(1, "year")
                  )
                ]
              })(
                <DatePicker {...datePickerStandardProps} disabledDate={disableDatePickerPast} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("contract.enums.paymentFrequency._label")}>
              {getFieldDecorator("generalData.paymentFrequency", { rules: [validations.notNull] })(
                <Select
                  {...selectStandardProps}
                  options={[InsuranceContractPaymentFrequency.ANNUALLY, InsuranceContractPaymentFrequency.SEMI_ANNUALLY,
                    InsuranceContractPaymentFrequency.QUARTERLY].map(frequency => ({
                    value: frequency,
                    label: t("contract.enums.paymentFrequency." + frequency)
                  }))} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={twoColumnColSpan}>
            <Divider className="divider-subheader">{t("calc.vehicle.sections.reinsurancesData")}</Divider>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan * 2}>
                <Form.Item>
                  {getFieldDecorator("reinsurancesData.extendedAssistance", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox disabled={!contains(REINSURANCES_MAP.get("extendedAssistance"), category)}>
                      {t("calc.vehicle.attrs.reinsurancesData.extendedAssistance")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item className={glass ? "form-item-without-label" : null}>
                  {getFieldDecorator("reinsurancesData.glass", {
                    rules: [validations.none], valuePropName: "checked", initialValue: glass
                  })(
                    <Checkbox disabled={!contains(REINSURANCES_MAP.get("glass"), category)}
                              onChange={this.props.onGlassChange}>
                      {t("calc.vehicle.attrs.reinsurancesData.glass")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              {glass && (
                <Col span={shrinkedColSpan}>
                  <Form.Item label={t("calc.vehicle.enums.glassReinsuranceAmount._label")}>
                    {getFieldDecorator("reinsurancesData.glassAmount", { rules: [validations.notNull] })(
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(GlassReinsuranceAmount).map(amount => ({
                          value: amount,
                          label: t("calc.vehicle.enums.glassReinsuranceAmount." + amount)
                        }))} />
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan * 2}>
                <Form.Item>
                  {getFieldDecorator("reinsurancesData.animal", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox
                      disabled={calcType === CalcType.CRASH || !contains(REINSURANCES_MAP.get("animal"), category)}>
                      {t("calc.vehicle.attrs.reinsurancesData.animal")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan * 2}>
                <Form.Item>
                  {getFieldDecorator("reinsurancesData.element", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox
                      disabled={calcType === CalcType.CRASH || !contains(REINSURANCES_MAP.get("element"), category)}>
                      {t("calc.vehicle.attrs.reinsurancesData.element")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan * 2}>
                <Form.Item>
                  {getFieldDecorator("reinsurancesData.theftAndVandalism", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox
                      disabled={calcType === CalcType.CRASH || !contains(REINSURANCES_MAP.get("theftAndVandalism"), category)}>
                      {t("calc.vehicle.attrs.reinsurancesData.theftAndVandalism")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan * 2}>
                <Form.Item>
                  {getFieldDecorator("reinsurancesData.injury", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox
                      disabled={calcType === CalcType.CRASH || !contains(REINSURANCES_MAP.get("injury"), category)}>
                      {t("calc.vehicle.attrs.reinsurancesData.injury")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item className={gap ? "form-item-without-label" : undefined}>
                  {getFieldDecorator("reinsurancesData.gap", {
                    rules: [validations.none], valuePropName: "checked", initialValue: gap
                  })(
                    <Checkbox
                      disabled={calcType === CalcType.MTPL || !contains(REINSURANCES_MAP.get("gap"), category)}
                      onChange={this.props.onGapChange}>
                      {t("calc.vehicle.attrs.reinsurancesData.gap")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              {gap && (
                <>
                  <Col span={shrinkedColSpan}>
                    <Form.Item className="form-item-without-label">
                      {getFieldDecorator("reinsurancesData.gapComplicityReinsurance", {
                        rules: [validations.none], valuePropName: "checked", initialValue: false
                      })(
                        <Checkbox>
                          {t("calc.vehicle.attrs.reinsurancesData.gapComplicityReinsurance")}
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={shrinkedColSpan}>
                    <Form.Item label={t("calc.vehicle.enums.gapDuration._label")}>
                      {getFieldDecorator("reinsurancesData.gapDuration", { rules: [validations.notNull] })(
                        <Select
                          {...selectStandardProps}
                          options={[GapDuration.MONTHS_36, GapDuration.MONTHS_48, GapDuration.MONTHS_60].map(duration => ({
                            value: duration,
                            label: t("calc.vehicle.enums.gapDuration." + duration)
                          }))} />
                      )}
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item className={replacementVehicle ? "form-item-without-label" : undefined}>
                  {getFieldDecorator("reinsurancesData.replacementVehicle", {
                    rules: [validations.none], valuePropName: "checked", initialValue: replacementVehicle
                  })(
                    <Checkbox
                      disabled={calcType === CalcType.MTPL || !contains(REINSURANCES_MAP.get("replacementVehicle"), category)}
                      onChange={this.props.onReplacementVehicleChange}>
                      {t("calc.vehicle.attrs.reinsurancesData.replacementVehicle")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              {replacementVehicle && (
                <Col span={shrinkedColSpan}>
                  <Form.Item label={t("calc.vehicle.enums.replacementVehicleCategory._label")}>
                    {getFieldDecorator("reinsurancesData.replacementVehicleCategory", { rules: [validations.notNull] })(
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(ReplacementVehicleCategory).map(category => ({
                          value: category,
                          label: t("calc.vehicle.enums.replacementVehicleCategory." + category)
                        }))} />
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan * 2}>
                <Form.Item>
                  {getFieldDecorator("reinsurancesData.generaliAbroadVehicleRepair", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox
                      disabled={calcType === CalcType.MTPL || !contains(REINSURANCES_MAP.get("generaliAbroadVehicleRepair"), category)}>
                      {t("calc.vehicle.attrs.reinsurancesData.generaliAbroadVehicleRepair")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={twoColumnColSpan}>
            <Divider className="divider-subheader">{t("calc.vehicle.sections.crossSelling")}</Divider>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item className={axaContractEnabled ? "form-item-without-label" : undefined}>
                  <Checkbox defaultChecked={axaContractEnabled} onChange={this.handleAxaContractEnabledChange}>
                    {t("calc.vehicle.attrs.generalData.crossSelling.axaContract")}
                  </Checkbox>
                </Form.Item>
              </Col>

              {axaContractEnabled && (
                <Col span={shrinkedColSpan}>
                  <Form.Item label={t("calc.vehicle.attrs.generalData.crossSelling.axaContractNumber")}>
                    {getFieldDecorator("generalData.crossSelling.axaContractNumber", {
                      rules: [validations.notNull, validations.size(4, 15)]
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item>
                  {getFieldDecorator("generalData.crossSelling.csobContract", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox>{t("calc.vehicle.attrs.generalData.crossSelling.csobContract")}</Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item>
                  {getFieldDecorator("generalData.crossSelling.groupamaContract", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox>{t("calc.vehicle.attrs.generalData.crossSelling.groupamaContract")}</Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item>
                  {getFieldDecorator("generalData.crossSelling.unionHealthContract", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox disabled={holder && holder.type !== ClientType.NATURAL}>
                      {t("calc.vehicle.attrs.generalData.crossSelling.unionHealthContract")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item>
                  {getFieldDecorator("generalData.crossSelling.uniqaRealtyContract", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox disabled={calcType === CalcType.CRASH || (holder && holder.type !== ClientType.NATURAL)}>
                      {t("calc.vehicle.attrs.generalData.crossSelling.uniqaRealtyContract")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={shrinkedColSpan}>
                <Form.Item className={wustenrotContractEnabled ? "form-item-without-label" : undefined}>
                  <Checkbox
                    defaultChecked={wustenrotContractEnabled}
                    onChange={this.handleWustenrotContractEnabledChange}>
                    {t("calc.vehicle.attrs.generalData.crossSelling.wustenrotContract")}
                  </Checkbox>
                </Form.Item>
              </Col>

              {wustenrotContractEnabled && (
                <Col span={shrinkedColSpan}>
                  <Form.Item label={t("calc.vehicle.enums.wustenrotContractsCount._label")}>
                    {getFieldDecorator("generalData.crossSelling.wustenrotContractsCount", {
                      rules: [validations.notNull]
                    })(
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(WustenrotContractsCount).map(count => ({
                          value: count,
                          label: t("calc.vehicle.enums.wustenrotContractsCount." + count)
                        }))} />
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>

          </Col>
        </Row>
      </Card>
    );
  }
}

export default VehicleCalcGeneralAndReinsurancesDataSection;
