import React from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SearchPageResult, UUID } from "../../../../../common/types";
import { PersonList } from "../../../types";
import { paginationStandardProps } from "../../../../../common/utils/formUtils";
import t from "../../../../../app/i18n";

import PersonTypeTag from "../../PersonTypeTag";
import AddressView from "../../../../../common/modules/address/AddressView";

export interface Props {
  personsPage: SearchPageResult<PersonList>;
  onRowDoubleClick(personId: UUID): void;
  onPageChange(pageNumber: number): void;
}

const PersonListTableView = (props: Props) => {

  const columns: ColumnsType<PersonList> = [
    {
      key: "aggregatedName",
      title: t("person.attrs.aggregatedName"),
      render: (text, record) => <Link to={`/persons/${record.id}`}>{record.aggregatedName}</Link>
    },
    {
      key: "type",
      title: t("person.enums.type._label"),
      render: (text, record) => <PersonTypeTag type={record.type} />
    },
    {
      key: "personalIdentificationNumber",
      title: t("person.attrs.personalIdentificationNumber"),
      dataIndex: "personalIdentificationNumber"
    },
    {
      key: "companyRegistrationNumber",
      title: t("person.attrs.companyRegistrationNumber"),
      dataIndex: "companyRegistrationNumber"
    },
    {
      key: "address",
      title: t("person.attrs.address"),
      render: (text, record) => <AddressView address={record.address} />
    }
  ];

  return (
    <Table<PersonList>
      size="small"
      className="table-row-stripped"
      rowClassName={record => record.deactivated ? "deactivated" : undefined}
      rowKey="id"
      columns={columns}
      dataSource={props.personsPage.pageData}
      pagination={{
        ...paginationStandardProps,
        current: props.personsPage.pageIndex + 1,
        pageSize: props.personsPage.pageSize,
        total: props.personsPage.totalElementsCount,
        onChange: props.onPageChange
      }}
      onRow={record => ({
        onDoubleClick: () => props.onRowDoubleClick(record.id)
      })}
    />
  )
};

export default PersonListTableView;
