import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { CertificateVehicleColor } from "../../../contract/types";
import { RootState } from "../../../../common/types";
import { selectVehicleColorsEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const VehicleColorEnumFormItemSelect = ({ formItemProps, selectProps }: Props) => {

  const vehicleColorsEnumerations = useSelector<RootState, CertificateVehicleColor[]>(selectVehicleColorsEnumerations);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={vehicleColorsEnumerations.map(color => ({ value: color.id, label: color.value }))} />
    </Form.Item>
  );
}

export default VehicleColorEnumFormItemSelect;
