import createSagaMiddleware from "redux-saga";
import localForage from "localforage";
import { persistReducer, persistStore } from "redux-persist";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router"
import { isLocalhostDevMode } from "../../common/utils/utils";

import history from "./rootHistory";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

export default function configureStore() {
  const persistedReducer = persistReducer({
      key: "root",
      storage: localForage,
      whitelist: ["auth"],
      debug: isLocalhostDevMode()
    },
    rootReducer);

  // @ts-ignore
  const composeEnhancers = isLocalhostDevMode() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));
  const storePersistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, storePersistor };
}
