import { Address, Contact, LabelledHistoryItem } from "../modules/types";

const labelledHistoryItem: LabelledHistoryItem = { id: null, label: null };
const labelledHistoryItemKeys = Object.keys(labelledHistoryItem);

const contact: Contact = { description: null, type: null, value: null, useForNotifications: null };
const contactKeys = Object.keys(contact);

const address: Address = {
  street: null,
  descriptiveNumber: null,
  orientationNumber: null,
  city: null,
  zipCode: null,
  country: null
};
const addressKeys = Object.keys(address);

export const isLabelledHistoryItem = (input: any): input is LabelledHistoryItem => {
  return input && typeof input === "object" && Object.keys(input).length === labelledHistoryItemKeys.length && labelledHistoryItemKeys.every(key => key in input);
};

export const isArrayOfContacts = (input: any): input is Contact[] => {
  return Array.isArray(input) && Object.keys(input).length > 0 && input.every(item => contactKeys.every(key => key in item));
};

export const isAddress = (input: any): input is Address => {
  return input && typeof input === "object" && addressKeys.every(key => key in input);
};
