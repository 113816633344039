import React from "react";
import { Card } from "antd";
import { LoanContract } from "../../../../../types";
import {
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocalePercentageNumber,
  formatPersonName
} from "../../../../../../../common/utils/formatUtils";
import t from "../../../../../../../app/i18n";

import HtmlView from "../../../../../../../common/components/views/HtmlView";

export interface Props {
  contract: LoanContract;
}

const LoanContractDataSection = ({ contract }: Props) => (
  <Card size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}>
    <table className="data-table-view">
      <tbody>
      <tr>
        <td>{t("contract.attrs.signer")}:</td>
        <td>{formatPersonName(contract.signer)}</td>
        <td>
          {t("contract.attrs.gainer1")}:
          {contract.gainer2 ? <><br />{t("contract.attrs.gainer2")}:</> : null}
        </td>
        <td>
          {formatPersonName(contract.gainer1)}
          {contract.gainer2 ? <><br />{formatPersonName(contract.gainer2)}</> : null}
        </td>
        <td>{t("contract.attrs.manager")}:</td>
        <td>{formatPersonName(contract.manager)}</td>
      </tr>
      <tr>
        <td colSpan={6} />
      </tr>
      <tr>
        <td>{t("contract.attrs.signDate")}:</td>
        <td>{formatLocaleDate(contract.signDate)}</td>
        <td>{t("contract.attrs.approvedAmount")}:</td>
        <td className="right-align">{formatLocaleCurrency(contract.approvedAmount)}</td>
        <td>{t("contract.enums.loanRateType._label")}:</td>
        <td>{t("contract.enums.loanRateType." + contract.rateType)}</td>
      </tr>
      <tr>
        <td>{t("contract.attrs.loanMaturityDate")}:</td>
        <td>{formatLocaleDate(contract.loanMaturityDate)}</td>
        <td>{t("contract.attrs.monthlyPaymentAmount")}:</td>
        <td className="right-align">{formatLocaleCurrency(contract.monthlyPaymentAmount)}</td>
        <td>{t("contract.attrs.fixationAnniversaryDate")}:</td>
        <td>{formatLocaleDate(contract.fixationAnniversaryDate)}</td>
      </tr>
      <tr>
        <td>{t("contract.attrs.effectiveEndDate")}:</td>
        <td>{formatLocaleDate(contract.effectiveEndDate)}</td>
        <td>{t("contract.attrs.ltvRatio")}:</td>
        <td className="right-align">{formatLocalePercentageNumber(contract.ltvRatio)}</td>
        <td>{t("contract.attrs.contactClientDate")}:</td>
        <td>{formatLocaleDate(contract.contactClientDate)}</td>
      </tr>
      <tr>
        <td>{t("contract.attrs.cancellationDate")}:</td>
        <td>{formatLocaleDate(contract.cancellationDate)}</td>
        <td>{t("contract.attrs.interestRate")}:</td>
        <td className="right-align">{formatLocalePercentageNumber(contract.interestRate)}</td>
        <td>{t("contract.enums.reactionStatus._label")}:</td>
        <td>{t("contract.enums.reactionStatus." + contract.reactionStatus)}</td>
      </tr>
      <tr>
        <td colSpan={6} />
      </tr>
      <tr>
        <td colSpan={6}>{t("contract.attrs.note")}:</td>
      </tr>
      <tr>
        <td colSpan={6}><HtmlView value={contract.note} /></td>
      </tr>
      </tbody>
    </table>
  </Card>
);

export default React.memo(LoanContractDataSection);
