import React, { useEffect } from "react";
import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { CreateImportedCommission, ImportedCommission, UpdateImportedCommission } from "../../types";
import { UUID } from "../../../../../common/types";
import { CommissionType, commissionTypeTMap } from "../../enums";
import { createCommissionActions, updateCommissionActions } from "../../ducks";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  resolveFormValidationError,
  selectTagsStandardProps,
  toMoment,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import InputNumberWithAddon from "../../../../../common/components/form/components/InputNumberWithAddon";
import CommissionTypeTag from "../CommissionTypeTag";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";

export interface Props {
  commission?: ImportedCommission;
  batchId: UUID;
  institutionId?: UUID;
  visible: boolean;
  onCreate?: typeof createCommissionActions.request;
  onUpdate?: typeof updateCommissionActions.request;
  onFormCancel(): void;
}

const ImportedCommissionForm = ({ commission, batchId, institutionId, visible, onCreate, onUpdate, onFormCancel }: Props) => {

  const [form] = Form.useForm<CreateImportedCommission | UpdateImportedCommission>();
  useFormErrorHandler(form, "commissions.batch.attrs.commission", requests.CREATE_COMMISSION);
  useFormErrorHandler(form, "commissions.batch.attrs.commission", requests.UPDATE_COMMISSION);

  const colSpan = 8;

  useEffect(() => {
    if ( visible && commission ) {
      form.setFieldsValue({
        ...commission,
        startDate: toMoment(commission.startDate),
        endDate: toMoment(commission.endDate)
      });
      form.validateFields().catch(resolveFormValidationError);
    }
  }, [visible, commission, form]);

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
  };

  const createInProgress = useRequestFinishedCallback(requests.CREATE_COMMISSION, handleFormCancel);
  const updateInProgress = useRequestFinishedCallback(requests.UPDATE_COMMISSION, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: CreateImportedCommission | UpdateImportedCommission | Store) => {
        if ( commission ) {
          onUpdate?.({ id1: batchId, id2: commission.id, object: values as UpdateImportedCommission });
        }
        else {
          onCreate?.({ id: batchId, object: { ...values as CreateImportedCommission, institutionId } });
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={800}
      visible={visible}
      title={commission ? t("commissions.batch.titles.updateCommission") : t("commissions.batch.titles.createCommission")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={createInProgress || updateInProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="importedCommissionUpdateForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("commissions.batch.attrs.commission.contractNumber")}
              dependencies={["proposalOrLoanNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull(form, "proposalOrLoanNumber", t("commissions.batch.attrs.commission.proposalOrLoanNumber"))
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="proposalOrLoanNumber"
              label={t("commissions.batch.attrs.commission.proposalOrLoanNumber")}
              dependencies={["contractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull(form, "contractNumber", t("commissions.batch.attrs.commission.contractNumber"))
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="clientName"
              label={t("commissions.batch.attrs.commission.clientName")}
              rules={[validations.notBlank, validations.size(1, 255)]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="startDate"
              label={t("commissions.batch.attrs.commission.startDate")}
              rules={[validations.notNull]}>
              <DatePicker {...datePickerStandardProps} showToday={false} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="endDate"
              label={t("commissions.batch.attrs.commission.endDate")}
              rules={[validations.none]}>
              <DatePicker {...datePickerClearableProps} showToday={false} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="commissionBaseAmount"
              label={t("commissions.batch.attrs.commission.commissionBaseAmount")}
              rules={[validations.none]}>
              <InputNumberWithAddon addonType="euro" formatStyle="decimal" />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="commissionAmount"
              label={t("commissions.batch.attrs.commission.commissionAmount")}
              rules={[validations.notNull]}>
              <InputNumberWithAddon addonType="euro" formatStyle="decimal" />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="commissionType"
              label={t("commissions.batch.enums.commissionType._label")}
              rules={[validations.none]}>
              <Select
                {...selectTagsStandardProps(commissionTypeTMap)} allowClear
                options={Object.keys(CommissionType).map(type => ({
                  value: type,
                  label: <CommissionTypeTag type={CommissionType[type]} />
                }))} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
};

export default ImportedCommissionForm;
