import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import { Pathname } from "history";
import isEqual from "lodash/isEqual";
import { TablePaginationConfig } from "antd/lib/table";
import { Key, SorterResult } from "antd/lib/table/interface";
import { ActionProps, RootState, UUID } from "../../../common/types";
import { ContractFilterPageRequest, ContractFilterPageResult, ContractList } from "../types";
import { Enumerations } from "../../enumerations/types";
import { OrderDirection } from "../../../common/enums";
import { ContractOrderByColumn, ContractStatus, ContractView } from "../enums";
import { deleteStateContractPageAction, filterContractsActions, selectCurrentContractsPage } from "../ducks";
import { selectEnumerations } from "../../enumerations/ducks";
import { selectRouterLocationPathname, selectRouterLocationSearch } from "../../ducks";
import { sortOrderToOrderDirection } from "../../../common/utils/formUtils";
import { serializeParams } from "../../../common/utils/apiUtils";
import { appendSearchParamsToCurrentPathname } from "../../../common/utils/utils";

import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import ContractListTableView from "../components/views/list/ContractListTableView";
import ContractListFilterView from "../components/views/list/ContractListFilterView";

interface StateProps {
  currentContractsPage: ContractFilterPageResult<ContractList>;
  enumerations: Enumerations;
  initialUrlSearchQuery: string;
  locationPathname: Pathname;
}

interface ActionsMap {
  filterContracts: typeof filterContractsActions.request;
  deleteStateContractPage: typeof deleteStateContractPageAction;
  pushNavigation: typeof push;
  replaceNavigation: typeof replace;
}

class ContractListContainer extends React.Component<StateProps & ActionProps<ActionsMap>> {
  private static readonly DEFAULT_PAGE_SIZE = 50;

  getDefaultOrderBy = (report: ContractView): ContractOrderByColumn[] => {
    return report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT
      ? [ContractOrderByColumn.LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE]
      : [ContractOrderByColumn.CREATED_AT];
  };

  getDefaultOrderDirections = (report: ContractView): OrderDirection[] => {
    return report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT ? [OrderDirection.ASC] : [OrderDirection.DESC];
  };

  mapFilterResultToFilterRequest = (result: ContractFilterPageResult<ContractList>): ContractFilterPageRequest => {
    const newResult = { ...result };

    delete newResult.pageData;
    delete newResult.totalElementsCount;
    delete newResult.pageElementsCount;
    delete newResult.pagesCount;
    delete newResult.isFirst;
    delete newResult.isLast;

    return newResult;
  };

  appendSearchParamsToLink = (filter: ContractFilterPageRequest): void => {
    const shouldSerializeOrderIntoUrl = !isEqual(filter.orderBy, this.getDefaultOrderBy(filter.report))
      || !isEqual(filter.orderDirections, this.getDefaultOrderDirections(filter.report));

    const urlParams = serializeParams({
      ...filter,
      pageIndex: null,
      pageSize: filter.pageSize !== ContractListContainer.DEFAULT_PAGE_SIZE ? filter.pageSize : null,
      report: null,
      orderBy: shouldSerializeOrderIntoUrl ? filter.orderBy : null,
      orderDirections: shouldSerializeOrderIntoUrl ? filter.orderDirections : null
    });

    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(urlParams));
  };

  handleTableRowDoubleClick = (contractId: UUID): void => {
    this.props.actions.pushNavigation("/contracts/" + contractId);
  };

  handleTableChange = (pagination: TablePaginationConfig,
                       filters: Record<string, Key[] | null>,
                       sorter: SorterResult<ContractList> | SorterResult<ContractList>[]): void => {
    const orderBy = [];
    const orderDirections = [];
    if ( Array.isArray(sorter) ) {
      sorter.filter(item => item.order).forEach(item => {
        const order = ContractOrderByColumn[item.field.toString()];
        if ( orderBy.indexOf(order) === -1 ) {
          orderBy.push(order);
          orderDirections.push(sortOrderToOrderDirection(item.order));
        }
      });
    }
    else if ( sorter.order ) {
      orderBy.push(ContractOrderByColumn[sorter.field.toString()]);
      orderDirections.push(sortOrderToOrderDirection(sorter.order));
    }

    const filter = {
      ...this.mapFilterResultToFilterRequest(this.props.currentContractsPage),
      pageIndex: pagination.current - 1,
      pageSize: pagination.pageSize,
      orderBy,
      orderDirections
    };

    this.appendSearchParamsToLink(filter);

    this.props.actions.filterContracts(filter);
  };

  handleFilterSubmit = (filter: ContractFilterPageRequest): void => {
    const filterRequest = {
      ...this.mapFilterResultToFilterRequest(this.props.currentContractsPage),
      ...filter,
      keyword: filter.keyword ? filter.keyword : null,
      pageIndex: 0
    };

    this.appendSearchParamsToLink(filterRequest);

    this.props.actions.filterContracts(filterRequest);
  };

  componentDidMount(): void {
    const { productGroups, productsClassification, persons } = this.props.enumerations;
    const products = productGroups.map(productGroup => productGroup.products).flat();

    const locationPathnameParts = this.props.locationPathname.split("/");
    const lastPathnamePart = locationPathnameParts[locationPathnameParts.length - 1];
    let report: ContractView;
    switch ( lastPathnamePart ) {
      case "anniversary-date":
        report = ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT;
        break
      case "unpaid":
        report = ContractView.INTERNAL_UNPAID_REPORT;
        break
      default:
        report = ContractView.INTERNAL_GENERIC_REPORT;
        break
    }

    const urlParams = new URLSearchParams(this.props.initialUrlSearchQuery);
    const initialFilter: ContractFilterPageRequest = {
      report,
      orderBy: urlParams.getAll("orderBy").length > 0
        ? urlParams.getAll("orderBy").map(column => ContractOrderByColumn[column])
        : this.getDefaultOrderBy(report),
      orderDirections: urlParams.getAll("orderDirections").length > 0
        ? urlParams.getAll("orderDirections").map(direction => OrderDirection[direction])
        : this.getDefaultOrderDirections(report),
      keyword: urlParams.get("keyword"),
      pageIndex: 0,
      pageSize: urlParams.get("pageSize") ? parseInt(urlParams.get("pageSize")) : ContractListContainer.DEFAULT_PAGE_SIZE,
      institutionIds: urlParams.getAll("institutionIds").filter(institutionId => productsClassification.some(institution => institution.id === institutionId)),
      productIds: urlParams.getAll("productIds").filter(productId => products.some(product => product.id === productId)),
      personIds: urlParams.getAll("personIds").filter(personId => persons.some(person => person.id === personId && !person.deactivated)),
      statuses: urlParams.getAll("statuses").map(status => ContractStatus[status]),
      createdAtMin: urlParams.get("createdAtMin"),
      createdAtMax: urlParams.get("createdAtMax"),
      effectiveBeginningDateOrSignDateMin: urlParams.get("effectiveBeginningDateOrSignDateMin"),
      effectiveBeginningDateOrSignDateMax: urlParams.get("effectiveBeginningDateOrSignDateMax"),
      effectiveEndDateMin: urlParams.get("effectiveEndDateMin"),
      effectiveEndDateMax: urlParams.get("effectiveEndDateMax"),
      cancellationDateMin: urlParams.get("cancellationDateMin"),
      cancellationDateMax: urlParams.get("cancellationDateMax"),
      transferredToOtherBrokerDateMin: urlParams.get("transferredToOtherBrokerDateMin"),
      transferredToOtherBrokerDateMax: urlParams.get("transferredToOtherBrokerDateMax"),
      annualPremiumOrApprovedAmountMin: urlParams.get("annualPremiumOrApprovedAmountMin") && parseFloat(urlParams.get("annualPremiumOrApprovedAmountMin")),
      annualPremiumOrApprovedAmountMax: urlParams.get("annualPremiumOrApprovedAmountMax") && parseFloat(urlParams.get("annualPremiumOrApprovedAmountMax")),
      vehicleFirstRegistrationYearMin: urlParams.get("vehicleFirstRegistrationYearMin") && parseInt(urlParams.get("vehicleFirstRegistrationYearMin")),
      vehicleFirstRegistrationYearMax: urlParams.get("vehicleFirstRegistrationYearMax") && parseInt(urlParams.get("vehicleFirstRegistrationYearMax"))
    };

    this.props.actions.filterContracts(initialFilter);
  }

  componentWillUnmount(): void {
    this.props.actions.deleteStateContractPage();
  }

  render(): React.ReactNode {
    return (
      <DisplayWrapper itemLoaded={!!this.props.currentContractsPage.report}>
        <ContractListFilterView
          productGroups={this.props.enumerations.productGroups}
          contractsPage={this.props.currentContractsPage}
          onFilterSubmit={this.handleFilterSubmit} />

        <ContractListTableView
          contractsPage={this.props.currentContractsPage}
          onRowDoubleClick={this.handleTableRowDoubleClick}
          onTableChange={this.handleTableChange} />
      </DisplayWrapper>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentContractsPage: selectCurrentContractsPage(state),
  enumerations: selectEnumerations(state),
  initialUrlSearchQuery: selectRouterLocationSearch(state),
  locationPathname: selectRouterLocationPathname(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterContracts: filterContractsActions.request,
    deleteStateContractPage: deleteStateContractPageAction,
    pushNavigation: push,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(ContractListContainer);
