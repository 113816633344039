import React from "react";
import { Route, Switch } from "react-router-dom";
import RouteWithPermission from "../../common/security/authorization/RouteWithPermission";
import { regexPatterns } from "../../common/utils/validationUtils";
import { Permission } from "../../common/security/authorization/enums";

import ClientListContainer from "./containers/ClientListContainer";
import ClientCreateContainer from "./containers/ClientCreateContainer";
import ClientDetailContainer from "./containers/ClientDetailContainer";
import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission exact path="/clients"
                         component={ClientListContainer}
                         permissions={[Permission.CLIENT]} />

    <RouteWithPermission exact path="/clients/new"
                         component={ClientCreateContainer}
                         permissions={[Permission.CLIENT_CREATE]} />

    <RouteWithPermission exact path={`/clients/:id(${regexPatterns.uuidRegex.source})`}
                         component={ClientDetailContainer}
                         permissions={[Permission.CLIENT]} />

    <Route component={NotFound} />
  </Switch>
);
