import React from "react";
import {
  BranchesOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  CloudOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  ImportOutlined,
  LaptopOutlined,
  PullRequestOutlined,
  SelectOutlined,
  SyncOutlined,
  UserAddOutlined
} from "@ant-design/icons";

export interface Props {
  type: "edit" | "delete" | "close" | "download" | "import" | "check" | "eye" | "laptop" | "cloud" | "exclamation"
    | "select" | "user-add" | "time" | "sync" | "branch" | "pull-request";
  text?: string;
  color: "blue" | "green" | "red" | "orange";
  size?: "small" | "default" | "large" | "huge";
  disabled?: boolean;
  disableDelayEffect?: boolean;
  onClick?(): void;
}

const ActionTextIcon = (props: Props) => {
  const classNames = ["action-icon"];
  classNames.push(props.disabled ? "disabled" : props.color);
  classNames.push(props.size ? props.size : "default");
  if ( !props.disableDelayEffect ) {
    classNames.push("delay-effect");
  }

  let icon;
  switch ( props.type ) {
    case "edit":
      icon = <EditOutlined />;
      break;
    case "delete":
      icon = <DeleteOutlined />;
      break;
    case "close":
      icon = <CloseOutlined />;
      break;
    case "download":
      icon = <DownloadOutlined />;
      break;
    case "import":
      icon = <ImportOutlined />;
      break;
    case "check":
      icon = <CheckCircleOutlined />;
      break;
    case "eye":
      icon = <EyeOutlined />;
      break;
    case "laptop":
      icon = <LaptopOutlined />;
      break;
    case "cloud":
      icon = <CloudOutlined />;
      break;
    case "exclamation":
      icon = <ExclamationCircleOutlined />;
      break;
    case "select":
      icon = <SelectOutlined />;
      break;
    case "user-add":
      icon = <UserAddOutlined />;
      break;
    case "time":
      icon = <FieldTimeOutlined />;
      break;
    case "sync":
      icon = <SyncOutlined />;
      break;
    case "branch":
      icon = <BranchesOutlined />;
      break;
    case "pull-request":
      icon = <PullRequestOutlined />;
      break;
  }

  return (
    <span onClick={props.disabled ? undefined : props.onClick} className={classNames.join(" ")}>
      {icon}
      {props.text && <span className="margin-left-tiny">{props.text}</span>}
    </span>
  );
};

export default ActionTextIcon;
