export enum OperationStage {
  CALCULATE = 'CALCULATE',
  GENERATE = 'GENERATE'
}

export enum BuildingType {
  HOUSE = 'HOUSE',
  APARTMENT_IN_APARTMENT_BUILDING = 'APARTMENT_IN_APARTMENT_BUILDING',
  APARTMENT_IN_FAMILY_HOUSE = 'APARTMENT_IN_FAMILY_HOUSE',
  RECREATIONAL_BUILDING = 'RECREATIONAL_BUILDING'
}

export enum BuildingState {
  FINISHED = 'FINISHED',
  UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION'
}

export enum ConstructionMaterial {
  BRICK_PANEL_CONCRETE = 'BRICK_PANEL_CONCRETE',
  WOOD_OTHER = 'WOOD_OTHER'
}

export enum RoofType {
  FLAT = 'FLAT',
  PITCHED_WITHOUT_ATTIC = 'PITCHED_WITHOUT_ATTIC',
  PITCHED_WITH_ATTIC = 'PITCHED_WITH_ATTIC'
}

export enum NearbyBuildingType {
  ARBOUR = 'ARBOUR',
  WASTE_WATER_TREATMENT_PLANT = 'WASTE_WATER_TREATMENT_PLANT',
  WORKSHOP = 'WORKSHOP',
  DETACHED_GARAGE = 'DETACHED_GARAGE',
  FARM_BUILDING = 'FARM_BUILDING',
  COWSHED = 'COWSHED',
  SINK = 'SINK',
  BOILER_ROOM = 'BOILER_ROOM',
  SHED = 'SHED',
  FENCING = 'FENCING',
  PERGOLA = 'PERGOLA',
  SEPTIC = 'SEPTIC',
  STABLE = 'STABLE',
  BARN = 'BARN',
  WELL = 'WELL',
  WELL_WITH_PUMP = 'WELL_WITH_PUMP',
  HEAT_PUMP = 'HEAT_PUMP',
  BUILT_SWIMMING_POOL = 'BUILT_SWIMMING_POOL',
  BUILT_SWIMMING_POOL_WITH_ROOF = 'BUILT_SWIMMING_POOL_WITH_ROOF',
  OTHER = 'OTHER'
}

export enum HouseholdEquipment {
  PURPOSE_BUILT = 'PURPOSE_BUILT',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM'
}

export enum StructuralComponentsReinsuranceAmount {
  TEN_THOUSAND = 'TEN_THOUSAND',
  TWENTY_THOUSAND = 'TWENTY_THOUSAND',
  THIRTY_THOUSAND = 'THIRTY_THOUSAND',
  FORTY_THOUSAND = 'FORTY_THOUSAND',
  FIFTY_THOUSAND = 'FIFTY_THOUSAND'
}

export enum Liability {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM'
}

export enum CivilLiabilityReinsurance {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM'
}
