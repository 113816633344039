import React from "react";
import { Tag, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Status } from "../../types";
import t from "../../../app/i18n";

export interface Props {
  status: Status;
  tooltip?: string;
  onClick?(status: Status): void;
}

const StatusTag = ({ status, tooltip, onClick }: Props) => {
  let icon;

  switch ( status ) {
    case "success":
      icon = <CheckCircleOutlined />;
      break;
    case "processing":
      icon = <SyncOutlined spin />;
      break;
    case "warning":
      icon = <ExclamationCircleOutlined />;
      break;
    case "error":
      icon = <CloseCircleOutlined />;
      break;
  }

  const tag = <Tag color={status} icon={icon} className={onClick ? "cursor-pointer" : null}
                   onClick={() => onClick?.(status)}>
    {t("common.status." + status)}
  </Tag>;

  return tooltip ? <Tooltip title={tooltip}>{tag}</Tooltip> : tag;
};

export default StatusTag;
