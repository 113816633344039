import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import { ActionProps, RootState, SearchPageResult, UUID } from "../../../common/types";
import { PersonList } from "../types";
import { deleteStatePersonsPageAction, filterPersonsActions, selectPersonsCurrentPage } from "../ducks";
import { selectRouterLocationSearchParam } from "../../ducks";
import { appendSearchParamsToCurrentPathname } from "../../../common/utils/utils";
import { serializeParams } from "../../../common/utils/apiUtils";

import PersonListSearchHeaderView from "../components/views/list/PersonListSearchHeaderView";
import PersonListTableView from "../components/views/list/PersonListTableView";

interface StateProps {
  personsCurrentPage: SearchPageResult<PersonList>;
  initialUrlSearchKeyword: string;
}

interface ActionsMap {
  filterPersons: typeof filterPersonsActions.request;
  deleteStatePersonsPage: typeof deleteStatePersonsPageAction;
  pushNavigation: typeof push;
  replaceNavigation: typeof replace;
}

class PersonListContainer extends React.Component<StateProps & ActionProps<ActionsMap>> {
  private static readonly PAGE_SIZE = 50;

  handleTableRowDoubleClick = (personId: UUID): void => {
    this.props.actions.pushNavigation("/persons/" + personId);
  };

  handleTablePageChange = (pageNumber: number): void => {
    this.props.actions.filterPersons({
      keyword: this.props.personsCurrentPage.keyword,
      pageIndex: pageNumber - 1,
      pageSize: PersonListContainer.PAGE_SIZE
    });
  };

  handleSearchSubmit = (keyword: string): void => {
    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(
      serializeParams({ keyword: keyword !== "" ? keyword : null })));

    this.props.actions.filterPersons({
      keyword,
      pageIndex: 0,
      pageSize: PersonListContainer.PAGE_SIZE
    });
  };

  componentWillUnmount(): void {
    this.props.actions.deleteStatePersonsPage();
  }

  componentDidMount(): void {
    this.props.actions.filterPersons({
      keyword: this.props.initialUrlSearchKeyword,
      pageIndex: 0,
      pageSize: PersonListContainer.PAGE_SIZE
    });
  }

  render(): React.ReactNode {
    return (
      <>
        <PersonListSearchHeaderView
          initialSearchKeyword={this.props.initialUrlSearchKeyword}
          onSearchSubmit={this.handleSearchSubmit} />

        <PersonListTableView
          personsPage={this.props.personsCurrentPage}
          onRowDoubleClick={this.handleTableRowDoubleClick}
          onPageChange={this.handleTablePageChange} />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  personsCurrentPage: selectPersonsCurrentPage(state),
  initialUrlSearchKeyword: selectRouterLocationSearchParam(state, "keyword")
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterPersons: filterPersonsActions.request,
    deleteStatePersonsPage: deleteStatePersonsPageAction,
    pushNavigation: push,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(PersonListContainer);
