import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { loginActions } from "../ducks";
import { validations } from "../../../common/utils/validationUtils";
import { useFormErrorHandler } from "../../../common/utils/formUtils";
import requests from "../apiRequests";
import t from "../../../app/i18n";

export interface Props {
  onFormSubmit: typeof loginActions.request | any;
}

const LoginForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "login.attrs", requests.LOGIN);

  return (
    <>
      <h2 className="center-align margin-bottom-large">{t("login.title")}</h2>

      <Row justify="center">
        <Col span={24} style={{ maxWidth: "400px" }}>

          <Form form={form} layout="vertical" name="loginForm" onFinish={props.onFormSubmit}>

            <Form.Item
              name="username"
              label={t("login.attrs.username")}
              rules={[validations.notBlank, validations.size(1, 70)]}>
              <Input prefix={<UserOutlined className="icon-black-opacity" />} />
            </Form.Item>

            <Form.Item
              name="password"
              label={t("login.attrs.password")}
              rules={[validations.notBlank]}>
              <Input.Password prefix={<LockOutlined className="icon-black-opacity" />} />
            </Form.Item>

            <Form.Item>
              <Button className="margin-top-medium" type="primary" htmlType="submit" block>
                {t("login.submit")}
              </Button>

              <div className="center-align margin-top-medium">
                <Link to="/accounts/request-password-reset">{t("login.forgottenPassword")}</Link>
              </div>
            </Form.Item>

          </Form>

        </Col>
      </Row>
    </>
  );
}

export default LoginForm;
