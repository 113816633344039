import { ApiRequest, RequestMethod } from "../types";
import { regexPatterns } from "../utils/validationUtils";

export class ApiRequestAdapter implements ApiRequest {

  private static readonly IDENTIFIER_DELIMITER = "-->";

  readonly url: string;
  readonly method: RequestMethod;
  readonly identifier: string;
  readonly identifierRegExp: RegExp;

  constructor(url: string, method: RequestMethod) {
    this.url = url;
    this.method = method;
    this.identifier = ApiRequestAdapter.createIdentifier(url, method);
    this.identifierRegExp = regexPatterns.urlPathParamRegex.test(url)
      ? new RegExp(this.identifier.replace(regexPatterns.urlPathParamRegex, regexPatterns.uuidRegex.source))
      : null;
  }

  public static create = (url: string, method: RequestMethod): ApiRequest => {
    return new ApiRequestAdapter(url, method);
  };

  public static createIdentifier = (url: string, method: RequestMethod): string => {
    return method.toUpperCase() + ApiRequestAdapter.IDENTIFIER_DELIMITER + url;
  };

  getParametrizedUrl(...params: string[]): string {
    if ( params && params.length ) {
      let index = 0;
      let url = this.url;
      params.forEach(param => url = url.replace(`{${index++}}`, param));

      return url;
    }

    return this.url;
  }

  compareIdentifier(comparedIdentifier: string): boolean {
    return this.hasParamsInUrl() ? this.identifierRegExp.test(comparedIdentifier) : this.identifier === comparedIdentifier;
  }

  hasParamsInUrl(): boolean {
    return !!this.identifierRegExp;
  }

}
