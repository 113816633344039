import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Divider, Form, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { LoanContract } from "../../../../types";
import { Client } from "../../../../../client/types";
import { FieldConstraintViolation, RootState, UUID } from "../../../../../../common/types";
import { InstitutionWithProducts } from "../../../../../enumerations/types";
import { ClientFormStage, ClientSearchActionType } from "../../../../../client/enums";
import { InstitutionType } from "../../../../../admin/institution/enums";
import { selectProductsClassificationEnumerations } from "../../../../../enumerations/ducks";
import { useClientSearch } from "../../../../../client/utils";
import { isDefinedValue } from "../../../../../../common/utils/utils";
import { resolveFormValidationError } from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { tInterval } from "../../../../../../common/utils/translationUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import ClientDrawerCreateForm from "../../../../../client/components/drawers/ClientDrawerCreateForm";
import ClientDrawerUpdateForm from "../../../../../client/components/drawers/ClientDrawerUpdateForm";
import ClientSearchInput from "../../../../../client/components/ClientSearchInput";
import InstitutionsEnumFormItemSelect from "../../../../../enumerations/components/form/InstitutionsEnumFormItemSelect";
import ProductsEnumFormItemSelect from "../../../../../enumerations/components/form/ProductsEnumFormItemSelect";

export interface Props {
  initialContract?: LoanContract;
  form: FormInstance;
  clients: Client[];
  clientsViolationErrors: Map<number, FieldConstraintViolation[]>;
  onClientChange(client: Client, index: number): void;
  onClientViolationErrorsChange(errorsMap: Map<number, FieldConstraintViolation[]>);
}

const LoanContractFormHeaderSection = ({ initialContract, form, clients, ...props }: Props) => {

  const clientSearch = useClientSearch();
  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnumerations);

  const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);
  const [updateFormVisible, setUpdateFormVisible] = useState<boolean>(false);

  const [processedClientIndex, setProcessedClientIndex] = useState<number>();
  const [clientStages, setClientStages] = useState<ClientFormStage[]>(initialContract?.clients.map(() => ClientFormStage.SELECTED) || []);

  useEffect(() => {
    if ( isDefinedValue(processedClientIndex)
      && clientSearch.result.keyword === form.getFieldValue(["clientIdentifiers", processedClientIndex]) ) {
      if ( clientSearch.result.data ) {
        setClientStage(ClientFormStage.EXISTING, processedClientIndex);
        props.onClientChange(clientSearch.result.data, processedClientIndex);
      }
      else {
        setClientStage(ClientFormStage.NEW, processedClientIndex);
      }
    }
  }, [clientSearch.result]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleInsuranceInstitutionIdChange = (institutionId: UUID): void => {
    const institution = productsClassification.find(institution => institution.id === institutionId);
    const productId = form.getFieldValue("productId") as UUID;

    if ( productId && !institution?.productGroups.flatMap(group => group.products).some(product => product.id === productId) ) {
      form.setFieldsValue({ productId: null });
    }
  };

  const handleClientSearchActionClick = (index: number, actionType: ClientSearchActionType): void => {
    switch ( actionType ) {
      case ClientSearchActionType.CREATE:
        setProcessedClientIndex(index);
        setCreateFormVisible(true);
        break;
      case ClientSearchActionType.UPDATE:
        setProcessedClientIndex(index);
        setUpdateFormVisible(true);
        break;
      case ClientSearchActionType.DELETE:
        const updatedIdentifiers = [...((form.getFieldValue("clientIdentifiers") as string[]) || [])];
        updatedIdentifiers[index] = null;
        form.setFieldsValue({ clientIdentifiers: updatedIdentifiers });

        const updatedClientStages = [...clientStages];
        updatedClientStages[index] = null;
        setClientStages(updatedClientStages);

        deleteClientViolationErrors(index);
        setProcessedClientIndex(null);
        props.onClientChange(null, index);
        break;
    }
  };

  const handleClientSearchSubmit = (value: string, index: number): void => {
    form.validateFields([["clientIdentifiers", index]])
      .then(() => {
        if ( value ) {
          clientSearch.onSearch({ keyword: value });
        }
      })
      .catch(resolveFormValidationError);
  };

  const handleClientSearchChange = (value: string, index: number): void => {
    if ( clientStages[index] ) {
      setClientStage(null, index);
    }
    if ( clients[index] ) {
      props.onClientChange(null, index);
    }
    if ( props.clientsViolationErrors.has(index) ) {
      deleteClientViolationErrors(index);
    }

    handleClientSearchSubmit(value, index);
  };

  const handleCreateClientFormSubmit = (client: Client, index: number): void => {
    setCreateFormVisible(false);
    setProcessedClientIndex(null);
    setClientStage(ClientFormStage.SELECTED, index);
    props.onClientChange(client, index);
  };

  const handleUpdateClientFormSubmit = (client: Client, index: number): void => {
    setUpdateFormVisible(false);
    setProcessedClientIndex(null);
    setClientStage(ClientFormStage.SELECTED, index);
    deleteClientViolationErrors(index);
    props.onClientChange(client, index);
  };

  const getClientFormIdentifier = (index: number): string => {
    return ((form.getFieldValue("clientIdentifiers") as string[]) || [])[index];
  };

  const setClientStage = (stage: ClientFormStage, index: number): void => {
    const updatedClientStages = [...clientStages];
    updatedClientStages[index] = stage;
    setClientStages(updatedClientStages);
  };

  const deleteClientViolationErrors = (index: number): void => {
    const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>([...props.clientsViolationErrors]);
    updatedViolationsErrors.delete(index);
    props.onClientViolationErrorsChange(updatedViolationsErrors);
  };

  const colSpan = 4;
  const clientInputColSpan = 5;
  const clientNameColSpan = 6;

  const clientSearchProps = {
    processedType: processedClientIndex,
    violationErrors: props.clientsViolationErrors,
    inProgress: clientSearch.inProgress,
    inputColSpan: clientInputColSpan,
    clientNameColSpan,
    onActionClick: handleClientSearchActionClick,
    onFocus: setProcessedClientIndex,
    onSearch: handleClientSearchSubmit,
    onChange: handleClientSearchChange
  }

  return (
    <>
      <Card size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.mainData")}>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="contractNumber"
              label={t("contract.attrs.contractNumber")}
              dependencies={["loanNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull(form, "loanNumber", t("contract.attrs.loanNumber"))
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="loanNumber"
              label={t("contract.attrs.loanNumber")}
              dependencies={["contractNumber"]}
              rules={[
                validations.size(1, 64),
                validations.notNullIfOtherNull(form, "contractNumber", t("contract.attrs.contractNumber"))
              ]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <InstitutionsEnumFormItemSelect
              formItemProps={{
                name: "bankInstitutionId",
                label: t("contract.attrs.bankInstitutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                filterType: InstitutionType.BANK,
                selected: initialContract ? [initialContract.bankInstitution] : undefined
              }}
              selectProps={{ onChange: handleInsuranceInstitutionIdChange }} />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.bankInstitutionId !== next.bankInstitutionId}>
              {({ getFieldValue }) => {
                const institutionId = getFieldValue("bankInstitutionId");
                return (
                  <ProductsEnumFormItemSelect
                    formItemProps={{
                      name: "productId",
                      label: t("contract.attrs.productId"),
                      rules: [validations.notNull]
                    }}
                    optionsProps={{
                      institutionId: institutionId,
                      selected: initialContract?.product,
                      hideAll: !!!institutionId
                    }}
                    selectProps={{ placeholder: !!!institutionId ? t("contract.helpers.loanProductPlaceholder") : undefined }} />
                )
              }}
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("contract.sections.clients")}</Divider>

        <Row gutter={rowGutter}>
          {[...Array(2)].map((_, index) => (
            <ClientSearchInput<number>
              {...clientSearchProps}
              key={index}
              formItemProps={{
                name: ["clientIdentifiers", index],
                label: tInterval("contract.attrs.loanClientIdentifiers_interval", index),
                rules: [
                  index === 0 ? validations.notBlank : validations.none,
                  validations.pinOrCrn,
                  validations.noRepeatedClient(form, "clientIdentifiers")
                ]
              }}
              optional={index > 0}
              formStage={clientStages[index]}
              formType={index}
              client={clients[index]} />
          ))}
        </Row>
      </Card>

      <ClientDrawerCreateForm<number>
        visible={createFormVisible}
        initialIdentifier={getClientFormIdentifier(processedClientIndex)}
        formType={processedClientIndex}
        placement="contract"
        onFormSubmit={handleCreateClientFormSubmit} />

      <ClientDrawerUpdateForm<number>
        visible={updateFormVisible}
        client={clients[processedClientIndex]}
        formType={processedClientIndex}
        violationErrors={props.clientsViolationErrors}
        placement="contract"
        onFormSubmit={handleUpdateClientFormSubmit} />
    </>
  )
}

export default LoanContractFormHeaderSection;
