import React, { ReactText } from "react";
import debounce from "lodash/debounce";
import { Card, Checkbox, Col, InputNumber, Row, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { VehicleCalcClientsData, VehicleCalcForm, VehicleCalcType } from "../../../types";
import { Client, ClientsAutocompleteProps } from "../../../../../../client/types";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import { TimeWithoutAccident, VehicleInsurerRelation, VehicleOwnerRelation } from "../../../enums";
import { ClientFormStage, ClientFormType, ClientType } from "../../../../../../client/enums";
import { CalcType } from "../../../../../enums";
import { YEARS_TO_ACCIDENT_TIMES_MAP } from "../../../utils";
import { contains, isDefinedValue } from "../../../../../../../common/utils/utils";
import validations, { validationFunctions } from "../../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import ClientDrawerCreateForm from "../../../../../../client/components/drawers/ClientDrawerCreateForm";
import ClientDrawerUpdateForm from "../../../../../../client/components/drawers/ClientDrawerUpdateForm";
import ClientAutocompleteInput from "../../../../../../client/components/ClientAutocompleteInput_Deprecated";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  form: WrappedFormUtils;
  initialClientsData: VehicleCalcClientsData;
  calcType: VehicleCalcType;
  holder: Client;
  clientsAutocomplete: ClientsAutocompleteProps;
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  onHolderChange(client: Client): void;
  onHolderViolationErrorsDelete(): void;
}

interface State {
  readonly holderFormStage: ClientFormStage;
  readonly clientCreateFormVisible: boolean;
  readonly clientUpdateFormVisible: boolean;
  readonly leasing: boolean;
  readonly holderTimeWithoutAccident: TimeWithoutAccident;
  readonly holderAccidentsIn2Years: number;
  readonly holderAccidentsIn3Years: number;
  readonly holderAccidentsIn4Years: number;
  readonly holderAccidentsIn5Years: number;
}

class VehicleCalcClientsDataSection extends React.Component<Props, State> {

  private static readonly CLIENTS_AUTOCOMPLETE_MAX_SIZE = 7;

  constructor(props: Props) {
    super(props);

    const stateObject = {
      holderFormStage: null,
      clientCreateFormVisible: false,
      clientUpdateFormVisible: false,
      leasing: false,
      holderTimeWithoutAccident: null,
      holderAccidentsIn2Years: null,
      holderAccidentsIn3Years: null,
      holderAccidentsIn4Years: null,
      holderAccidentsIn5Years: null
    };

    if ( props.initialClientsData ) {
      if ( props.holder ) {
        stateObject.holderFormStage = ClientFormStage.SELECTED;
      }
      stateObject.leasing = !!(props.initialClientsData.leasing);
      stateObject.holderTimeWithoutAccident = props.initialClientsData.holderTimeWithoutAccident;
      stateObject.holderAccidentsIn2Years = props.initialClientsData.holderAccidentsIn2Years;
      stateObject.holderAccidentsIn3Years = props.initialClientsData.holderAccidentsIn3Years;
      stateObject.holderAccidentsIn4Years = props.initialClientsData.holderAccidentsIn4Years;
      stateObject.holderAccidentsIn5Years = props.initialClientsData.holderAccidentsIn5Years;
    }

    this.state = stateObject;
  }

  handleLeasingChange = (event: CheckboxChangeEvent): void => {
    this.setState({ leasing: event.target.checked });

    const { holder } = this.props;
    if ( event.target.checked ) {
      this.props.form.setFieldsValue({
        "clientsData.insurerRelation": VehicleInsurerRelation.SAME_AS_HOLDER,
        "clientsData.ownerRelation": VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER
      });
    }
    else if ( holder && holder.type !== ClientType.NATURAL ) {
      this.props.form.setFieldsValue({
        "clientsData.insurerRelation": VehicleInsurerRelation.SAME_AS_HOLDER,
        "clientsData.ownerRelation": VehicleOwnerRelation.SAME_AS_HOLDER
      });
    }
  };

  handleHolderTimeWithoutAccidentChange = (holderTimeWithoutAccident: TimeWithoutAccident): void => {
    this.setState(previousState => ({
      holderTimeWithoutAccident,
      holderAccidentsIn2Years: contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(2), holderTimeWithoutAccident) ? previousState.holderAccidentsIn2Years : null,
      holderAccidentsIn3Years: contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(3), holderTimeWithoutAccident) ? previousState.holderAccidentsIn3Years : null,
      holderAccidentsIn4Years: contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(4), holderTimeWithoutAccident) ? previousState.holderAccidentsIn4Years : null,
      holderAccidentsIn5Years: contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(5), holderTimeWithoutAccident) ? previousState.holderAccidentsIn5Years : null
    }));
  };

  handleHolderAccidentsIn2YearsChange = (holderAccidentsIn2Years: ReactText): void => {
    this.setState({ holderAccidentsIn2Years: holderAccidentsIn2Years as number });
    this.processHolderAccidentsChange(holderAccidentsIn2Years as number, 3, 4, 5, 8);
  };

  handleHolderAccidentsIn3YearsChange = (holderAccidentsIn3Years: ReactText): void => {
    this.setState({ holderAccidentsIn3Years: holderAccidentsIn3Years as number });
    this.processHolderAccidentsChange(holderAccidentsIn3Years as number, 4, 5, 8);
  };

  handleHolderAccidentsIn4YearsChange = (holderAccidentsIn4Years: ReactText): void => {
    this.setState({ holderAccidentsIn4Years: holderAccidentsIn4Years as number });
    this.processHolderAccidentsChange(holderAccidentsIn4Years as number, 5, 8);
  };

  handleHolderAccidentsIn5YearsChange = (holderAccidentsIn5Years: ReactText): void => {
    this.setState({ holderAccidentsIn5Years: holderAccidentsIn5Years as number });
    this.processHolderAccidentsChange(holderAccidentsIn5Years as number, 8);
  };

  processHolderAccidentsChange = (accidents: number, ...yearsToCheck: number[]): void => {
    const { clientsData } = this.props.form.getFieldsValue([`clientsData`]) as VehicleCalcForm;
    yearsToCheck
      .filter(year => !clientsData[`holderAccidentsIn${year}Years`])
      .forEach(year => {
        this.props.form.setFieldsValue({ [`clientsData.holderAccidentsIn${year}Years`]: accidents });
        switch ( year ) {
          case 3:
            this.setState({ holderAccidentsIn3Years: accidents });
            break;
          case 4:
            this.setState({ holderAccidentsIn4Years: accidents });
            break;
          case 5:
            this.setState({ holderAccidentsIn5Years: accidents });
            break;
        }
      });
  };

  handleClientActionCreateClick = (): void => {
    this.setState({ clientCreateFormVisible: true });
  };

  handleClientActionUpdateClick = (): void => {
    this.setState({ clientUpdateFormVisible: true });
  };

  handleClientActionDeselectClick = (): void => {
    this.setHolderFormIdentifier(null);
    this.setHolderFormStage(null);
    this.props.onHolderViolationErrorsDelete();
    this.props.onHolderChange(null);
  };

  handleClientsAutocompleteResultDelete = (): void => {
    if ( this.props.clientsAutocomplete.result.data.length > 0 ) {
      this.props.clientsAutocomplete.onResultDelete();
    }
  };

  handleClientsAutocompleteSearch = debounce(
    (value: string): void => {
      if ( value && validationFunctions.validateSearchKeyword(value) ) {
        this.props.clientsAutocomplete.onSearch({
          keyword: value,
          maxResultSize: VehicleCalcClientsDataSection.CLIENTS_AUTOCOMPLETE_MAX_SIZE
        });
      }
      else {
        this.handleClientsAutocompleteResultDelete();
      }
    }, 500
  );

  handleClientsAutocompleteSelect = (value: SelectValue): void => {
    this.props.onHolderChange(this.props.clientsAutocomplete.result.data.find(client => client.identifier === value));
    this.setHolderFormStage(ClientFormStage.EXISTING);
    this.handleClientsAutocompleteResultDelete();
  };

  handleClientsAutocompleteChange = (): void => {
    if ( this.state.holderFormStage ) {
      this.setHolderFormStage(null);
    }
    if ( this.props.holder ) {
      this.props.onHolderChange(null);
    }
    if ( this.props.clientsViolationErrors.has(ClientFormType.HOLDER) ) {
      this.props.onHolderViolationErrorsDelete();
    }
  };

  handleClientCreateFormSubmit = (client: Client): void => {
    this.setState({ clientCreateFormVisible: false });
    this.setHolderFormStage(ClientFormStage.SELECTED);
    this.props.onHolderChange(client);
  };

  handleClientUpdateFormSubmit = (client: Client): void => {
    this.setState({ clientUpdateFormVisible: false });
    this.setHolderFormStage(ClientFormStage.SELECTED);
    this.props.onHolderViolationErrorsDelete();
    this.props.onHolderChange(client);
  };

  setHolderFormStage = (holderFormStage: ClientFormStage): void => {
    this.setState({ holderFormStage });
  };

  setHolderFormIdentifier = (identifier: string): void => {
    this.props.form.setFieldsValue({ "clientsData.holderIdentifier": identifier });
  };

  getHolderFormIdentifier = (): string => {
    return this.props.form.getFieldValue("clientsData.holderIdentifier");
  };

  resolveClientsAutocompleteOptions = (): DataSourceItemObject[] => {
    return this.props.clientsAutocomplete.result.data.map<DataSourceItemObject>(client => ({
      value: client.identifier,
      text: client.identifier
    }))
  };

  componentWillUnmount(): void {
    this.handleClientsAutocompleteResultDelete();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { result } = this.props.clientsAutocomplete;
    if ( result.id && result.data.length <= 1 && result.id !== prevProps.clientsAutocomplete.result.id ) {
      const formIdentifier = this.getHolderFormIdentifier();

      if ( formIdentifier && validationFunctions.validatePinOrCrn(formIdentifier) ) {
        if ( result.data.length === 0 ) {
          this.setHolderFormStage(ClientFormStage.NEW);
        }
        else if ( result.data.length === 1 && formIdentifier === result.data[0].identifier
          && (!this.props.holder || this.props.holder.identifier !== formIdentifier) ) {
          this.props.onHolderChange(result.data[0]);
          this.setHolderFormStage(ClientFormStage.EXISTING);
        }
      }
      else if ( this.state.holderFormStage ) {
        this.setHolderFormStage(null);
      }
    }
  }

  render(): React.ReactNode {
    const {
      leasing, holderTimeWithoutAccident,
      holderAccidentsIn2Years, holderAccidentsIn3Years, holderAccidentsIn4Years, holderAccidentsIn5Years
    } = this.state;
    const { form, calcType, holder, clientsAutocomplete } = this.props;
    const { getFieldDecorator } = form;

    const relationSelectsDisabled = (holder && holder.type !== ClientType.NATURAL) || leasing;

    const colSpan = 4;
    const eightColumnColSpan = 3;

    return (
      <>
        <Card size="small" type="inner" className="card-box" title={t("calc.vehicle.sections.vehicleHolder")}>
          <Row gutter={rowGutter}>

            <ClientAutocompleteInput
              form={form}
              formKey="clientsData.holderIdentifier"
              formType={ClientFormType.HOLDER}
              formStage={this.state.holderFormStage}
              formInputOptions={{ rules: [validations.notBlank, validations.pinOrCrn] }}
              label={t("calc.vehicle.attrs.clientsData.holderIdentifier")}
              processedClientFormType={ClientFormType.HOLDER}
              autocompleteInProgress={clientsAutocomplete.inProgress}
              options={this.resolveClientsAutocompleteOptions()}
              client={holder}
              clientsViolationErrors={this.props.clientsViolationErrors}
              inputColSpan={colSpan + 1}
              clientNameColSpan={colSpan + 3}
              onClientActionCreateClick={this.handleClientActionCreateClick}
              onClientActionUpdateClick={this.handleClientActionUpdateClick}
              onClientActionDeselectClick={this.handleClientActionDeselectClick}
              onClientsAutocompleteSearch={this.handleClientsAutocompleteSearch}
              onClientsAutocompleteSelect={this.handleClientsAutocompleteSelect}
              onClientsAutocompleteChange={this.handleClientsAutocompleteChange} />

            <Col span={colSpan}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("clientsData.holderChildrenUnder15Years", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox disabled={holder && holder.type !== ClientType.NATURAL}>
                    {t("calc.vehicle.attrs.clientsData.holderChildrenUnder15Years")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("clientsData.holderIsDisabledPerson", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox disabled={holder && holder.type !== ClientType.NATURAL}>
                    {t("calc.vehicle.attrs.clientsData.holderIsDisabledPerson")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item className="two-line-form-item-without-label">
                {getFieldDecorator("clientsData.holderYoungRiderComplicityDiscount", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox disabled={calcType === CalcType.MTPL}>
                    <LabelWithTooltip label={t("calc.vehicle.attrs.clientsData.holderYoungRiderComplicityDiscount")}
                                      tooltip={t("calc.vehicle.helpers.holderYoungRiderComplicityDiscountDesc")} />
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.enums.vehicleInsurerRelation._label")}>
                {getFieldDecorator("clientsData.insurerRelation", { rules: [validations.notNull] })(
                  <Select
                    {...selectStandardProps}
                    disabled={relationSelectsDisabled}
                    options={Object.keys(VehicleInsurerRelation).map(relation => ({
                      value: relation,
                      label: t("calc.vehicle.enums.vehicleInsurerRelation." + relation)
                    }))} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.enums.vehicleOwnerRelation._label")}>
                {getFieldDecorator("clientsData.ownerRelation", { rules: [validations.notNull] })(
                  <Select
                    {...selectStandardProps}
                    disabled={relationSelectsDisabled}
                    options={Object.keys(VehicleOwnerRelation).map(relation => ({
                      value: relation,
                      label: t("calc.vehicle.enums.vehicleOwnerRelation." + relation)
                    }))} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("clientsData.leasing", {
                  rules: [validations.none], valuePropName: "checked", initialValue: leasing
                })(
                  <Checkbox onChange={this.handleLeasingChange}>
                    {t("calc.vehicle.attrs.clientsData.leasing")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item className="two-line-form-item-without-label">
                {getFieldDecorator("clientsData.holderContractTerminatedDueToNonPayment", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox>{t("calc.vehicle.attrs.clientsData.holderContractTerminatedDueToNonPayment")}</Checkbox>
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("clientsData.holderHasNeverBeenMtplInsured", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox>{t("calc.vehicle.attrs.clientsData.holderHasNeverBeenMtplInsured")}</Checkbox>
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("clientsData.holderHasNeverBeenCrashInsured", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox disabled={calcType === CalcType.MTPL}>
                    {t("calc.vehicle.attrs.clientsData.holderHasNeverBeenCrashInsured")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.enums.timeWithoutAccident._label")}>
                {getFieldDecorator("clientsData.holderTimeWithoutAccident", {
                  rules: [validations.notNull], initialValue: holderTimeWithoutAccident
                })(
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(TimeWithoutAccident).map(time => ({
                      value: time,
                      label: t("calc.vehicle.enums.timeWithoutAccident." + time)
                    }))}
                    onChange={this.handleHolderTimeWithoutAccidentChange} />
                )}
              </Form.Item>
            </Col>

            {contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(2), holderTimeWithoutAccident) && (
              <>
                <Col span={colSpan + 1}>
                  <Form.Item className="form-item-without-label">
                    {getFieldDecorator("clientsData.holderHasOpenInsuranceEvent", {
                      rules: [validations.none], valuePropName: "checked", initialValue: false
                    })(
                      <Checkbox>{t("calc.vehicle.attrs.clientsData.holderHasOpenInsuranceEvent")}</Checkbox>
                    )}
                  </Form.Item>
                </Col>

                <Col span={eightColumnColSpan}>
                  <Form.Item label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn2Years")}>
                    {getFieldDecorator("clientsData.holderAccidentsIn2Years", {
                      rules: [validations.notNull, validations.minNumber(0)], initialValue: holderAccidentsIn2Years
                    })(
                      <InputNumber min={0} onChange={this.handleHolderAccidentsIn2YearsChange} />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}

            {contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(3), holderTimeWithoutAccident) && (
              <Col span={eightColumnColSpan}>
                <Form.Item label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn3Years")}>
                  {getFieldDecorator("clientsData.holderAccidentsIn3Years", {
                    rules: [
                      validations.notNull,
                      validations.minNumber(holderAccidentsIn2Years || 0,
                        isDefinedValue(holderAccidentsIn2Years) ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn2Years") : undefined)
                    ],
                    initialValue: holderAccidentsIn3Years
                  })(
                    <InputNumber
                      min={holderAccidentsIn2Years || 0}
                      onChange={this.handleHolderAccidentsIn3YearsChange} />
                  )}
                </Form.Item>
              </Col>
            )}

            {contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(4), holderTimeWithoutAccident) && (
              <Col span={eightColumnColSpan}>
                <Form.Item label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn4Years")}>
                  {getFieldDecorator("clientsData.holderAccidentsIn4Years", {
                    rules: [
                      validations.notNull,
                      validations.minNumber(holderAccidentsIn3Years || 0,
                        isDefinedValue(holderAccidentsIn3Years) ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn3Years") : undefined)
                    ],
                    initialValue: holderAccidentsIn4Years
                  })(
                    <InputNumber
                      min={holderAccidentsIn3Years || 0}
                      onChange={this.handleHolderAccidentsIn4YearsChange} />
                  )}
                </Form.Item>
              </Col>
            )}

            {contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(5), holderTimeWithoutAccident) && (
              <Col span={eightColumnColSpan}>
                <Form.Item label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn5Years")}>
                  {getFieldDecorator("clientsData.holderAccidentsIn5Years", {
                    rules: [
                      validations.notNull,
                      validations.minNumber(holderAccidentsIn4Years || 0,
                        isDefinedValue(holderAccidentsIn4Years) ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn4Years") : undefined)
                    ],
                    initialValue: holderAccidentsIn5Years
                  })(
                    <InputNumber
                      min={holderAccidentsIn4Years || 0}
                      onChange={this.handleHolderAccidentsIn5YearsChange} />
                  )}
                </Form.Item>
              </Col>
            )}

            {contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(8), holderTimeWithoutAccident) && (
              <Col span={eightColumnColSpan}>
                <Form.Item label={t("calc.vehicle.attrs.clientsData.holderAccidentsIn8Years")}>
                  {getFieldDecorator("clientsData.holderAccidentsIn8Years", {
                    rules: [
                      validations.notNull,
                      validations.minNumber(holderAccidentsIn5Years || 0,
                        isDefinedValue(holderAccidentsIn5Years) ? t("calc.vehicle.attrs.clientsData.holderAccidentsIn5Years") : undefined)
                    ]
                  })(
                    <InputNumber min={holderAccidentsIn5Years || 0} />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        </Card>

        <ClientDrawerCreateForm
          visible={this.state.clientCreateFormVisible}
          initialIdentifier={this.getHolderFormIdentifier()}
          formType={ClientFormType.HOLDER}
          onFormSubmit={this.handleClientCreateFormSubmit} />

        <ClientDrawerUpdateForm
          visible={this.state.clientUpdateFormVisible}
          client={holder}
          formType={ClientFormType.HOLDER}
          violationErrors={this.props.clientsViolationErrors}
          onFormSubmit={this.handleClientUpdateFormSubmit} />
      </>
    );
  }
}

export default VehicleCalcClientsDataSection;
