import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { ActionProps, RootState } from "../../../common/types";
import { PersonWithSubordinates } from "../types";
import { deleteStatePersonsTreeAction, getPersonsTreeActions, selectPersonsTree } from "../ducks";
import { selectRouterLocationSearchParam } from "../../ducks";

import PersonTreeView from "../components/views/tree/PersonTreeView";

interface StateProps {
  personsTree: PersonWithSubordinates[];
  initialUrlIncludeDeactivated: boolean;
}

interface ActionsMap {
  getPersonsTree: typeof getPersonsTreeActions.request;
  deleteStatePersonsTree: typeof deleteStatePersonsTreeAction;
  replaceNavigation: typeof replace;
}

class PersonTreeContainer extends React.Component<StateProps & ActionProps<ActionsMap>> {

  componentDidMount(): void {
    this.props.actions.getPersonsTree({ includeDeactivated: true });
  }

  componentWillUnmount(): void {
    this.props.actions.deleteStatePersonsTree();
  }

  render(): React.ReactNode {
    return <PersonTreeView personsTree={this.props.personsTree}
                           initialIncludeDeactivated={this.props.initialUrlIncludeDeactivated}
                           replaceNavigation={this.props.actions.replaceNavigation} />
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  personsTree: selectPersonsTree(state),
  initialUrlIncludeDeactivated: selectRouterLocationSearchParam(state, "includeDeactivated") === "true"
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getPersonsTree: getPersonsTreeActions.request,
    deleteStatePersonsTree: deleteStatePersonsTreeAction,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(PersonTreeContainer);
