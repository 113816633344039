import React from "react";
import { SelfEmployedPerson } from "../../../types";
import { formatIban, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { createLinkHref } from "../../../../../common/utils/utils";
import t from "../../../../../app/i18n";

import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";

export interface Props {
  person: SelfEmployedPerson;
}

const SelfEmployedPersonDataView = ({ person }: Props) => (
  <>
    <tr>
      <td>{t("person.attrs.companyNameFOP")}:</td>
      <td colSpan={3}>{person.aggregatedName}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.aggregatedNaturalName")}:</td>
      <td colSpan={3}>{person.aggregatedNaturalName}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("person.attrs.personalIdentificationNumberLong")}:</td>
      <td><CopyToClipboardView content={person.personalIdentificationNumber} /></td>
      <td>{t("person.attrs.birthDate")}:</td>
      <td>{formatLocaleDate(person.birthDate)}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.identityCardNumber")}:</td>
      <td colSpan={3}><CopyToClipboardView content={person.identityCardNumber} /></td>
    </tr>
    <tr>
      <td>{t("person.attrs.bankAccountNumber")}:</td>
      <td><CopyToClipboardView content={formatIban(person.bankAccountNumber)} /></td>
      <td>{t("person.attrs.webPage")}:</td>
      <td>{person.webPage
        ? <a href={createLinkHref(person.webPage)} target="_blank" rel="noopener noreferrer">{person.webPage}</a>
        : null}
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("person.attrs.companyRegistrationNumber")}:</td>
      <td><CopyToClipboardView content={person.companyRegistrationNumber} /></td>
      <td>{t("person.attrs.districtOffice")}:</td>
      <td>{person.districtOffice}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.taxId")}:</td>
      <td><CopyToClipboardView content={person.taxId} /></td>
      <td>{t("person.attrs.tradeRegisterNumber")}:</td>
      <td>{person.tradeRegisterNumber}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.vatId")}:</td>
      <td><CopyToClipboardView content={person.vatId} /></td>
      <td colSpan={2} />
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("person.attrs.note")}:</td>
      <td colSpan={3}>{person.note}</td>
    </tr>
  </>
);

export default SelfEmployedPersonDataView;
