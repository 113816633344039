import { call, put, takeLatest } from "redux-saga/effects";
import { CreateFrontendErrorLog } from "./types";
import { ActionCreator } from "../../common/types";
import { apiOperation, createActionType, createApiActionCreators } from "../../common/utils/reduxUtils";
import api from "./api";

/**
 * ACTION TYPES
 */
export enum actionType {
  CREATE_ERROR_LOG = 'event/CREATE_ERROR_LOG'
}

/**
 * ACTIONS
 */
export const createFrontendErrorLogActions = createApiActionCreators<CreateFrontendErrorLog, void>(actionType.CREATE_ERROR_LOG);

/**
 * SAGAS
 */
function* createFrontendErrorLog({ payload }: ActionCreator<CreateFrontendErrorLog>) {
  try {
    yield call(api.createFrontendErrorLog, payload);
    yield put(createFrontendErrorLogActions.success());
  }
  catch ( error ) {
    yield put(createFrontendErrorLogActions.failure(error));
  }
}

export function* eventSaga() {
  yield takeLatest(createActionType(actionType.CREATE_ERROR_LOG, apiOperation.REQUEST), createFrontendErrorLog);
}
