import i18next from "i18next";
import t from "../../app/i18n";

const fieldPathRegex = new RegExp("{[a-zA-Z\\[\\]0-9.]+}");
const fieldPathArrayRegex = new RegExp("\\[\\d{1,2}]");
const fieldPathEnumRegex = new RegExp("<\\|[a-zA-Z\\[\\]0-9_.]+\\|>");

const FIELD_LABEL_PATH = "_label";
const FIELD_VALUE_QUOTES = "'";

const FIELD_PATH_START = "{";
const FIELD_PATH_END = "}";
const FIELD_PATH_ARRAY_START = "[";
const FIELD_PATH_ARRAY_END = "]";
const FIELD_PATH_ENUM_START = "<|";
const FIELD_PATH_ENUM_END = "|>";

/**
 * Checks whether translation for key exists.
 * @param key - key to be checked
 * @param options - TOptions object
 * @return <i>true</i> if translation exists, <i>false</i> otherwise
 */
export const tExists = (key: string, options?: object): boolean => i18next.exists(key, options);

/**
 * Translates boolean value
 * @param value - boolean value to be translated
 * @param options - TOptions object
 * @return translated text
 */
export const tBoolean = (value: boolean, options?: object): string => value ? i18next.t("common.yes", options) : i18next.t("common.no", options);

/**
 * Translates key with interval post processing.
 * @param key - key to be translated
 * @param count - count used for translation interval
 * @param options - TOptions object
 * @return translated text if translation exists, <i>key</i> otherwise
 */
export const tInterval = (key: string, count: number, options?: object): string => i18next.t(key, {
  postProcess: "interval",
  count,
  ...options
});

/**
 * Translates input path as enum.
 *
 * <b>Example inputs:</b>
 *
 * <i>1. rootPath: "client.attrs"; fieldPath: "type"</i>
 * Path "client.attrs.type" exists, translation returns "<|client.enums.type|>",
 * so path of given enum is parsed and its label is translated by using "client.enums.type._label"
 *
 * <i>2. rootPath: "client.attrs"; fieldPath: "type.NATURAL"</i>
 * Path "client.attrs.type.NATURAL" DOES NOT exist, so it is divided into 2 parts: "client.attrs.type" and "NATURAL".
 * The translation of part "client.attrs.type" returns "<|client.enums.type|>",
 * so path of given enum is parsed and its value is translated by using "client.enums.type.NATURAL"
 *
 * @param rootPath - root path of input path
 * @param fieldPath - field path of input path
 * @return translated text
 */
export const tEnum = (rootPath: string, fieldPath: string): string => {
  if ( tExists(rootPath + "." + fieldPath) ) {
    const translation = t(rootPath + "." + fieldPath);
    return fieldPathEnumRegex.test(translation) ? translateEnumValue(translation) : translation;
  }
  else {
    const fieldPart = fieldPath.substring(0, fieldPath.lastIndexOf("."));
    const enumPart = fieldPath.substring(fieldPath.lastIndexOf(".") + 1, fieldPath.length);
    return translateEnumValue(t(rootPath + "." + fieldPart), enumPart);
  }
};

/**
 * Translates input path as field name.
 *
 * <b>Example inputs:</b>
 *
 * <i>1. rootPath: "calc.realty.attrs"; fieldPath: "generalBuildingData.constructionYear"</i>
 * Path "calc.realty.attrs.generalBuildingData.constructionYear" exists, so translation of this path is returned.
 *
 * <i>2. rootPath: "calc.realty.attrs"; fieldPath: "generalBuildingData"</i>
 * Path "calc.realty.attrs.generalBuildingData" exists but it is an object, so translation of path "calc.realty.attrs.generalBuildingData._label" is returned.
 *
 * <i>3. rootPath: "calc.realty.attrs"; fieldPath: "houseOrRecreationalBuildingData.overgroundFloorAreas[0]"</i>
 * Path "calc.realty.attrs.houseOrRecreationalBuildingData.overgroundFloorAreas" exists and path ends with array,
 * so translation of this path with interval postProcessing is returned (index + 1 is used as count).
 *
 * <i>4. rootPath: "calc.realty.attrs"; fieldPath: "realtyInsuranceData.nearbyBuildingReinsurances[0]"</i>
 * Path "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances" exists and it is an object,
 * so translation of "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances._label" is returned (see point 2).
 *
 * <i>5. rootPath: "calc.realty.attrs"; fieldPath: "realtyInsuranceData.nearbyBuildingReinsurances[0].insuranceAmount"</i>
 * Path "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.insuranceAmount" exists, so translation of this path is returned.
 *
 * <i>6. rootPath: "calc.realty.attrs"; fieldPath: "realtyInsuranceData.nearbyBuildingReinsurances[0].type"</i>
 * Path "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.type" exists, but translation is enum link - see note below.
 *
 * <i>7. rootPath: "calc.realty.attrs"; fieldPath: "clientsData.insuredClientType"</i>
 * Path "calc.realty.attrs.clientsData.insuredClientType" exists, but translation is enum link - see note below.
 *
 * <i>8. rootPath: "calc.realty.attrs"; fieldPath: "clientsData.insuredClientType.NATURAL"</i>
 * Path "calc.realty.attrs.clientsData.insuredClientType.NATURAL" does not exist, but it is processed as enum - see note below.
 *
 * <b>NOTE:</b> Translations in cases 6, 7, 8 are processed as described in tEnum() function.
 *
 * @param rootPath - root path of input path
 * @param fieldPath - field path of input path
 * @return translated text
 */
export const tFieldName = (rootPath: string, fieldPath: string): string => {
  if ( fieldPathArrayRegex.test(fieldPath) ) {
    const startIndex = fieldPath.indexOf(FIELD_PATH_ARRAY_START);
    const endIndex = fieldPath.indexOf(FIELD_PATH_ARRAY_END);
    const fieldIndexValue = parseInt(fieldPath.substring(startIndex + 1, endIndex), 10);
    const resolvedFieldPath = fieldPath.replace(fieldPathArrayRegex, "");

    if ( endIndex === fieldPath.length - 1 && tExists(rootPath + "." + resolvedFieldPath + "_interval") ) {
      return tInterval(rootPath + "." + resolvedFieldPath + "_interval", fieldIndexValue + 1);
    }
    else {
      let translation = t(rootPath + "." + resolvedFieldPath, { returnObjects: true });

      if ( typeof translation === "object" ) {
        return translation[FIELD_LABEL_PATH];
      }
      else {
        if ( fieldPathEnumRegex.test(translation) ) {
          translation = translateEnumValue(translation);
        }

        const parentFieldLabelPath = rootPath + "." + fieldPath.substring(0, startIndex) + "." + FIELD_LABEL_PATH;
        if ( tExists(parentFieldLabelPath) ) {
          translation = `${t(parentFieldLabelPath)} (${fieldIndexValue + 1}) - ${translation}`;
        }

        return translation;
      }
    }
  }
  else {
    const translation = t(rootPath + "." + fieldPath, { returnObjects: true });
    return typeof translation === "object"
      ? translation[FIELD_LABEL_PATH]
      : fieldPathEnumRegex.test(translation) ? translateEnumValue(translation) : translation;
  }
};

/**
 * Translates validation message.
 * @param rootPath - root path for translations
 * @param message - message to be translated
 * @return translated message
 */
export const tValidationMessage = (rootPath: string, message: string): string => {
  let resolvedMessage = message;

  while ( fieldPathRegex.test(resolvedMessage) ) {
    const fieldPath = resolvedMessage.substring(resolvedMessage.indexOf(FIELD_PATH_START) + 1, resolvedMessage.indexOf(FIELD_PATH_END));
    resolvedMessage = resolvedMessage.replace(
      FIELD_PATH_START + fieldPath + FIELD_PATH_END,
      FIELD_VALUE_QUOTES + tFieldName(rootPath, fieldPath) + FIELD_VALUE_QUOTES);
  }

  while ( fieldPathEnumRegex.test(resolvedMessage) ) {
    const fieldPath = resolvedMessage.substring(resolvedMessage.indexOf(FIELD_PATH_ENUM_START) + 2, resolvedMessage.indexOf(FIELD_PATH_ENUM_END));
    resolvedMessage = resolvedMessage.replace(
      FIELD_PATH_ENUM_START + fieldPath + FIELD_PATH_ENUM_END,
      FIELD_VALUE_QUOTES + tEnum(rootPath, fieldPath) + FIELD_VALUE_QUOTES);
  }

  return resolvedMessage;
};

/**
 * Translates enum label.
 * @param enumField - enum field to be translated (e.g. "<|client.enums.type|>")
 * @param enumValue - enum value to be translated (e.g. "NATURAL")
 * @return if enumValue is present, translation of enum value is returned (e.g. "client.enums.type.NATURAL"),
 * otherwise translation of enum label is returned (e.g. "client.enums.type._label")
 */
const translateEnumValue = (enumField: string, enumValue?: string): string => {
  return t(enumField.replace(FIELD_PATH_ENUM_START, "").replace(FIELD_PATH_ENUM_END, "") + "."
    + (enumValue && enumValue.length > 0 ? enumValue : FIELD_LABEL_PATH));
};
