import React from "react";
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export interface Props {
  label: string;
  popoverTitle?: string;
  popoverContent: React.ReactNode;
}

const LabelWithPopover = ({ label, popoverTitle, popoverContent }: Props) => (
  <span>
    {label}&nbsp;
    <Popover title={popoverTitle ? <b>{popoverTitle}</b> : undefined} content={popoverContent}>
      <QuestionCircleOutlined className="cursor-help" />
    </Popover>
  </span>
);

export default LabelWithPopover;
