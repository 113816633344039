import { combineReducers } from "redux";
import {
  apiOperation,
  createActionCreator,
  createApiActionCreators,
  createReducer
} from "../../../../common/utils/reduxUtils";
import { CalcResult, GenResponse } from "../types";
import {
  RealtyCalc,
  RealtyCalcDraft,
  RealtyCalcReducerState,
  RealtyCalcResultData,
  RealtyGen,
  RealtyOffer
} from "./types";
import { RootState } from "../../../../common/types";

/**
 * ACTION TYPES
 */
export enum actionType {
  CALCULATE = 'calc-realty/CALCULATE',
  GENERATE = 'calc-realty/GENERATE',
  GENERATE_OFFER = 'calc-realty/GENERATE_OFFER',
  SET_CALC_RESULTS = 'calc-realty/SET_CALC_RESULTS',
  SET_SELECTED_DRAFT = 'calc-realty/SET_SELECTED_DRAFT',
  SET_INITIAL_CALC_DATA = 'calc-realty/SET_INITIAL_CALC_DATA',
  SET_INITIAL_GEN_DATA = 'calc-realty/SET_INITIAL_GEN_DATA',
  DELETE_STATE_CALC_RESULTS = 'calc-realty/DELETE_STATE_CALC_RESULTS',
  DELETE_STATE_GEN_RESULT = 'calc-realty/DELETE_STATE_GEN_RESULT',
  DELETE_STATE_SELECTED_DRAFT = 'calc-realty/DELETE_STATE_SELECTED_DRAFT',
  DELETE_STATE_INITIAL_CALC_GEN_DATA = 'calc-realty/DELETE_STATE_INITIAL_CALC_GEN_DATA'
}

/**
 * ACTIONS
 */
export const calculateRealtyActions = createApiActionCreators<RealtyCalc, CalcResult<RealtyCalcResultData>[][]>(actionType.CALCULATE);
export const generateRealtyActions = createApiActionCreators<RealtyGen, GenResponse>(actionType.GENERATE);
export const generateRealtyOfferActions = createApiActionCreators<RealtyOffer, void>(actionType.GENERATE_OFFER);

export const setRealtyCalcResultsAction = createActionCreator<CalcResult<RealtyCalcResultData>[][]>(actionType.SET_CALC_RESULTS);
export const setRealtySelectedCalcDraftAction = createActionCreator<RealtyCalcDraft>(actionType.SET_SELECTED_DRAFT);
export const setRealtyInitialCalcDataAction = createActionCreator<RealtyCalc>(actionType.SET_INITIAL_CALC_DATA);
export const setRealtyInitialGenDataAction = createActionCreator<RealtyGen>(actionType.SET_INITIAL_GEN_DATA);

export const deleteStateRealtyCalcResultsAction = createActionCreator<void>(actionType.DELETE_STATE_CALC_RESULTS);
export const deleteStateRealtyGenResultAction = createActionCreator<void>(actionType.DELETE_STATE_GEN_RESULT);
export const deleteStateRealtySelectedDraftAction = createActionCreator<void>(actionType.DELETE_STATE_SELECTED_DRAFT);
export const deleteStateRealtyInitialCalcGenDataAction = createActionCreator<void>(actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA);

/**
 * REDUCERS
 */
const initialState: RealtyCalcReducerState = {
  calcResults: [],
  genResult: null,
  selectedDraft: null,
  initialCalcData: null,
  initialGenData: null
};

const calcResultsReducer = createReducer<CalcResult<RealtyCalcResultData>[][]>(initialState.calcResults, {
  [actionType.CALCULATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.calcResults
  },
  [actionType.SET_CALC_RESULTS]: (state, payload) => payload,
  [actionType.DELETE_STATE_CALC_RESULTS]: () => initialState.calcResults
});

const genResultReducer = createReducer<GenResponse>(initialState.genResult, {
  [actionType.GENERATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.genResult
  },
  [actionType.DELETE_STATE_GEN_RESULT]: () => initialState.genResult
});

const selectedDraftReducer = createReducer<RealtyCalcDraft>(initialState.selectedDraft, {
  [actionType.SET_SELECTED_DRAFT]: (state, payload) => payload,
  [actionType.DELETE_STATE_SELECTED_DRAFT]: () => initialState.selectedDraft
});

const initialCalcDataReducer = createReducer<RealtyCalc>(initialState.initialCalcData, {
  [actionType.SET_INITIAL_CALC_DATA]: (state, payload) => payload,
  [actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA]: () => initialState.initialCalcData
});

const initialGenDataReducer = createReducer<RealtyGen>(initialState.initialGenData, {
  [actionType.SET_INITIAL_GEN_DATA]: (state, payload) => payload,
  [actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA]: () => initialState.initialGenData
});

export default combineReducers({
  calcResults: calcResultsReducer,
  genResult: genResultReducer,
  selectedDraft: selectedDraftReducer,
  initialCalcData: initialCalcDataReducer,
  initialGenData: initialGenDataReducer
});

/**
 * SELECTORS
 */
const selectRealtyCalc = (state: RootState): RealtyCalcReducerState => state.calculator.calcs.realty;

export const selectRealtyCalcResults = (state: RootState): CalcResult<RealtyCalcResultData>[][] => selectRealtyCalc(state).calcResults;
export const selectRealtyGenResult = (state: RootState): GenResponse => selectRealtyCalc(state).genResult;
export const selectRealtySelectedDraft = (state: RootState): RealtyCalcDraft => selectRealtyCalc(state).selectedDraft;
export const selectRealtyInitialCalcData = (state: RootState): RealtyCalc => selectRealtyCalc(state).initialCalcData;
export const selectRealtyInitialGenData = (state: RootState): RealtyGen => selectRealtyCalc(state).initialGenData;
