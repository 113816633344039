import React from "react";
import { Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import { TravelRiskGroup, TravelTerritorialValidity } from "../../../../../calculator/calcs/travel/enums";
import {
  datePickerStandardProps,
  disableDatePickerPresentAndFuture,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import AddDeleteButton from "../../../../../../common/components/buttons/AddDeleteButton";
import DeleteIcon from "../../../../../../common/components/icons/DeleteIcon";

export interface Props {
  index: number;
}

const TravelInsuranceFormPart = ({ index }: Props) => {

  const colSpan = 4;
  const formNameDataPrefix = ["insurances", index, "insuranceData"];

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "territorialValidity"]}
            label={t("calc.travel.enums.territorialValidity._label")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(TravelTerritorialValidity).map(validity => ({
                value: validity,
                label: t("calc.travel.enums.territorialValidity." + validity)
              }))} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "riskGroup"]}
            label={t("calc.travel.enums.riskGroup._label")}
            rules={[validations.none]}>
            <Select
              {...selectStandardProps} allowClear
              options={Object.keys(TravelRiskGroup).map(group => ({
                value: group,
                label: t("calc.travel.enums.riskGroup." + group)
              }))} />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">{t("contract.attrs.insurances.insuranceData.insuredClients._label")}</Divider>

      <Form.List name={[...formNameDataPrefix, "insuredClients"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Row gutter={rowGutter} key={field.key}>
                <Col span={colSpan}>
                  <Form.Item
                    {...field}
                    name={[field.name, "firstName"]}
                    label={t("contract.attrs.insurances.insuranceData.insuredClients.firstName")}
                    rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    {...field}
                    name={[field.name, "lastName"]}
                    label={t("contract.attrs.insurances.insuranceData.insuredClients.lastName")}
                    rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    {...field}
                    name={[field.name, "birthDate"]}
                    label={t("contract.attrs.insurances.insuranceData.insuredClients.birthDate")}
                    rules={[validations.notNull, validations.notPresentAndFuture]}>
                    <DatePicker {...datePickerStandardProps} showToday={false}
                                disabledDate={disableDatePickerPresentAndFuture} />
                  </Form.Item>
                </Col>

                <Col span={1}>
                  {fields.length > 1 && <DeleteIcon onClick={() => remove(field.name)} />}
                </Col>
              </Row>
            ))}

            <AddDeleteButton type="add" label={t("contract.actions.addInsuredClient")} onClick={add} />
          </>
        )}
      </Form.List>
    </>
  );
}

export default TravelInsuranceFormPart;
