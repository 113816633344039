import React, { useEffect, useState } from "react";
import get from "lodash/get";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { Moment } from "moment";
import { AutoComplete, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { CreateUpdateVehicleInsurance, Vehicle } from "../../../../types";
import { Client } from "../../../../../client/types";
import { RootState } from "../../../../../../common/types";
import { InstitutionBase } from "../../../../../admin/institution/types";
import { Bodywork, FuelType, InsuranceType, Transmission, VehicleCategory } from "../../../../enums";
import { GapDuration, VehiclePurpose } from "../../../../../calculator/calcs/vehicle/enums";
import { ClientType } from "../../../../../client/enums";
import { AutocompleteVehiclesBy } from "../../../../../calculator/vehicles/enums";
import { InstitutionEnum } from "../../../../../admin/institution/enums";
import {
  selectProductsClassificationEnumerations,
  selectVehicleOtherBrandsIdsEnumerations,
  selectVehicleOtherModelsIdsEnumerations
} from "../../../../../enumerations/ducks";
import { SPECS_MAP } from "../../../../../calculator/calcs/vehicle/utils";
import { contains, removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import { useVehicleAutocomplete } from "../../../../../calculator/vehicles/utils";
import { regexPatterns, validationFunctions, validations } from "../../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  licensePlateNormalizeFunction,
  selectStandardProps,
  toMoment,
  upperCaseStringNormalizeFunction
} from "../../../../../../common/utils/formUtils";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import InputNumberWithAddon from "../../../../../../common/components/form/components/InputNumberWithAddon";
import VehicleBrandEnumFormItemSelect from "../../../../../enumerations/components/form/VehicleBrandEnumFormItemSelect";
import VehicleModelEnumFormItemSelect from "../../../../../enumerations/components/form/VehicleModelEnumFormItemSelect";
import VehicleColorEnumFormItemSelect from "../../../../../enumerations/components/form/VehicleColorEnumFormItemSelect";
import CoverageLimitFormItemSelect from "../../../../../enumerations/components/form/CoverageLimitFormItemSelect";
import ComplicityFormItemSelect from "../../../../../enumerations/components/form/ComplicityFormItemSelect";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  index: number;
  clients: Client[];
  form: FormInstance;
  type: InsuranceType;
}

const autocompleteMaxSize = 7;

const VehicleInsuranceFormPart = ({ index, clients, form, type }: Props) => {

  const institutions = useSelector<RootState, InstitutionBase[]>(selectProductsClassificationEnumerations);
  const otherBrandsIds = useSelector<RootState, string[]>(selectVehicleOtherBrandsIdsEnumerations);
  const otherModelIds = useSelector<RootState, string[]>(selectVehicleOtherModelsIdsEnumerations);

  const autocomplete = useVehicleAutocomplete();
  const [autocompleteFocusItem, setAutocompleteFocusItem] = useState<AutocompleteVehiclesBy>();

  useEffect(() => {
    if ( autocomplete.result.data.length === 1
      && (autocomplete.result.keyword === (autocomplete.result.by === AutocompleteVehiclesBy.VIN
        ? autocomplete.result.data[0]?.vin : autocomplete.result.data[0]?.licensePlate)) ) {
      setVehicleData(autocomplete.result.data[0], autocomplete.result.by);
    }
  }, [autocomplete.result, form]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleAutocompleteSearch = debounce(
    (value: string, focusedItem: AutocompleteVehiclesBy): void => {
      const keyword = removeStringWhiteSpaces(value);
      if ( keyword?.length >= 5 && validationFunctions.validateSearchKeyword(keyword) ) {
        autocomplete.onSearch({ keyword: keyword.toUpperCase(), maxResultSize: autocompleteMaxSize, by: focusedItem });
      }
      else if ( autocomplete.result.id ) {
        autocomplete.onResultDelete();
      }
    }, 500
  );

  const handleAutocompleteSelect = (value: string, focusedItem: AutocompleteVehiclesBy): void => {
    setVehicleData(
      autocomplete.result.data.find(vehicle =>
        focusedItem === AutocompleteVehiclesBy.VIN ? vehicle.vin === value : vehicle.licensePlate === removeStringWhiteSpaces(value)),
      focusedItem);
  };

  const handleBrandIdChange = (): void => {
    const insurances = getInsurances();
    insurances[index] = {
      ...insurances[index],
      vehicle: { ...insurances[index]?.vehicle, modelId: null }
    }
    form.setFieldsValue({ insurances });
  };

  const handleCategoryChange = (category: VehicleCategory): void => {
    const insurances = getInsurances();
    const vehicle = insurances[index]?.vehicle;
    insurances[index] = {
      ...insurances[index],
      vehicle: {
        ...vehicle,
        fuelType: contains(SPECS_MAP.get("fuelType"), category) ? vehicle?.fuelType : null,
        engineDisplacement: contains(SPECS_MAP.get("engineDisplacement"), category) ? vehicle?.engineDisplacement : null,
        enginePower: contains(SPECS_MAP.get("enginePower"), category) ? vehicle?.enginePower : null,
        transmission: contains(SPECS_MAP.get("transmission"), category) ? vehicle?.transmission : null,
        bodywork: contains(SPECS_MAP.get("bodywork"), category) ? vehicle?.bodywork : null,
        seatsNumber: contains(SPECS_MAP.get("seatsNumber"), category) ? vehicle?.seatsNumber : null,
        doorsNumber: contains(SPECS_MAP.get("doorsNumber"), category) ? vehicle?.doorsNumber : null,
      }
    }
    form.setFieldsValue({ insurances });
  };

  const handleFuelTypeChange = (fuelType: FuelType): void => {
    const insurances = getInsurances();
    const vehicle = insurances[index]?.vehicle;
    insurances[index] = {
      ...insurances[index],
      vehicle: {
        ...vehicle,
        engineDisplacement: fuelType === FuelType.ELECTRICITY ? null : vehicle?.engineDisplacement
      }
    }
    form.setFieldsValue({ insurances });
  };

  const handleLeasingChange = (): void => {
    const insurances = getInsurances();
    insurances[index] = { ...insurances[index], vehicleOwnerIdentifier: null };
    form.setFieldsValue({ insurances });
  };

  const resolveAutocompleteOptions = (focusedItem: AutocompleteVehiclesBy): DataSourceItemObject[] => {
    return focusedItem === autocomplete.result.by
      ? autocomplete.result.data.map<DataSourceItemObject>(vehicle => ({
        value: focusedItem === AutocompleteVehiclesBy.VIN ? vehicle.vin : licensePlateNormalizeFunction(vehicle.licensePlate),
        text: focusedItem === AutocompleteVehiclesBy.VIN ? vehicle.vin : licensePlateNormalizeFunction(vehicle.licensePlate)
      }))
      : [];
  };

  const setVehicleData = (vehicleData: Vehicle, focusedItem: AutocompleteVehiclesBy): void => {
    const vehicle = { ...vehicleData };
    const brandId = vehicle.model?.brand?.id;
    const modelId = vehicle.model?.id;
    const colorId = vehicle.color?.id;

    delete vehicle.model;
    delete vehicle.color;
    delete vehicle.certificateCategory;

    const insurances = getInsurances();
    const licensePlate = insurances[index].licensePlate;
    const registrationCertificateNumber = insurances[index].insuranceData.registrationCertificateNumber;

    insurances[index] = {
      ...insurances[index],
      licensePlate: focusedItem === AutocompleteVehiclesBy.VIN && !!licensePlate ? licensePlate : licensePlateNormalizeFunction(vehicle.licensePlate),
      insuranceData: {
        ...insurances[index]?.insuranceData,
        registrationCertificateNumber: !!registrationCertificateNumber ? registrationCertificateNumber : vehicle.registrationCertificateNumber
      },
      vehicle: {
        ...vehicle,
        firstRegistrationDate: toMoment(vehicle.firstRegistrationDate),
        brandId,
        modelId,
        colorId
      }
    }

    form.setFieldsValue({ insurances });
    autocomplete.onResultDelete();
  };

  const getInsurances = (): CreateUpdateVehicleInsurance[] => {
    return [...(form.getFieldValue(["insurances"]) || [])];
  };

  const colSpan = 4;
  const colSpanBig = 6;
  const formNamePrefix = ["insurances", index];
  const formNameDataPrefix = [...formNamePrefix, "insuranceData"];
  const formNameCoveragesPrefix = [...formNameDataPrefix, "coverages"];
  const formNameMtplPrefix = [...formNameDataPrefix, "mtpl"];
  const formNameVehiclePrefix = [...formNamePrefix, "vehicle"];

  const standardInsuranceDataForm = <>
    <Col span={colSpan}>
      <Form.Item
        name={[...formNameDataPrefix, "purpose"]}
        label={t("calc.vehicle.enums.vehiclePurpose._label")}
        rules={[validations.notNull]}>
        <Select
          {...selectStandardProps}
          options={Object.keys(VehiclePurpose).map(purpose => ({
            value: purpose,
            label: t("calc.vehicle.enums.vehiclePurpose." + purpose)
          }))} />
      </Form.Item>
    </Col>

    <Col span={colSpan}>
      <Form.Item
        name={[...formNameDataPrefix, "leasing"]}
        valuePropName="checked"
        rules={[validations.none]}
        initialValue={false}
        className="form-item-without-label">
        <Checkbox onChange={handleLeasingChange}>{t("contract.attrs.insurances.insuranceData.leasing")}</Checkbox>
      </Form.Item>
    </Col>
  </>;

  let insuranceDataForm = null;
  switch ( type ) {
    case InsuranceType.MTPL:
      insuranceDataForm = <>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
              {({ getFieldValue }) => (
                <CoverageLimitFormItemSelect
                  formItemProps={{
                    name: [...formNameDataPrefix, "coverageLimits"],
                    label: t("contract.attrs.insurances.insuranceData.coverageLimits"),
                    rules: [validations.notNull]
                  }}
                  institutionId={getFieldValue(["insuranceInstitutionId"])} />
              )}
            </Form.Item>
          </Col>

          {standardInsuranceDataForm}

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "extendedAssistance"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
              className="form-item-without-label">
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.extendedAssistance")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "glass"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
              className="form-item-without-label">
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.glass")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => get(prev, formNameCoveragesPrefix)?.glass !== get(next, formNameCoveragesPrefix)?.glass}>
              {({ getFieldValue }) => getFieldValue([...formNameCoveragesPrefix, "glass"]) && (
                <Form.Item
                  name={[...formNameCoveragesPrefix, "glassAmount"]}
                  label={t("contract.attrs.insurances.insuranceData.coverages.glassAmount")}
                  rules={[validations.notNull, validations.minNumber(1)]}>
                  <InputNumberWithAddon addonType="euro" formatStyle="integer" min={1} />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "animal"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.animal")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "element"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.element")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "injury"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.injury")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "theftAndVandalism"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.theftAndVandalism")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </>
      break;
    case InsuranceType.CRASH:
      insuranceDataForm = <>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={[...formNameDataPrefix, "price"]}
              label={t("contract.attrs.insurances.insuranceData.price")}
              rules={[validations.minNumber(1)]}>
              <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={1} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
              {({ getFieldValue }) => (
                <ComplicityFormItemSelect
                  formItemProps={{
                    name: [...formNameDataPrefix, "complicity"],
                    label: t("contract.attrs.insurances.insuranceData.complicity"),
                    rules: [validations.notNull]
                  }}
                  institutionId={getFieldValue(["insuranceInstitutionId"])} />
              )}
            </Form.Item>
          </Col>

          {standardInsuranceDataForm}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "extendedAssistance"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.extendedAssistance")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "glass"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.glass")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "replacementVehicle"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.replacementVehicle")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <Form.Item
              name={[...formNameCoveragesPrefix, "abroadVehicleRepair"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.abroadVehicleRepair")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => get(prev, formNameCoveragesPrefix)?.gap !== get(next, formNameCoveragesPrefix)?.gap}>
            {({ getFieldValue }) => {
              const gap = getFieldValue([...formNameCoveragesPrefix, "gap"]) as boolean;
              return (
                <>
                  <Col span={colSpan}>
                    <Form.Item
                      name={[...formNameCoveragesPrefix, "gap"]}
                      valuePropName="checked"
                      rules={[validations.none]}
                      initialValue={false}
                      className={gap ? "form-item-without-label" : undefined}>
                      <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.gap")}</Checkbox>
                    </Form.Item>
                  </Col>

                  {gap && (
                    <>
                      <Col span={colSpan}>
                        <Form.Item
                          name={[...formNameCoveragesPrefix, "gapDuration"]}
                          label={t("calc.vehicle.enums.gapDuration._label")}
                          rules={[validations.none]}>
                          <Select
                            {...selectStandardProps}
                            allowClear
                            options={Object.keys(GapDuration).map(duration => ({
                              value: duration,
                              label: t("calc.vehicle.enums.gapDuration." + duration)
                            }))} />
                        </Form.Item>
                      </Col>

                      <Col span={colSpan}>
                        <Form.Item
                          name={[...formNameCoveragesPrefix, "gapComplicityReinsurance"]}
                          valuePropName="checked"
                          rules={[validations.none]}
                          initialValue={false}
                          className="form-item-without-label">
                          <Checkbox>{t("contract.attrs.insurances.insuranceData.coverages.gapComplicityReinsurance")}</Checkbox>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </>
              )
            }}
          </Form.Item>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
          {({ getFieldValue }) => {
            const institutionId = getFieldValue(["insuranceInstitutionId"]);
            return institutions.find(institution => institution.id === institutionId)?.institutionEnum === InstitutionEnum.ALLIANZ ? (
              <>
                <Row gutter={rowGutter}>
                  <Col span={24}><b>{t("contract.attrs.insurances.insuranceData.mtpl._label")}</b></Col>
                </Row>

                <Row gutter={rowGutter}>
                  <Col span={colSpan}>
                    <CoverageLimitFormItemSelect
                      formItemProps={{
                        name: [...formNameMtplPrefix, "coverageLimits"],
                        label: t("contract.attrs.insurances.insuranceData.mtpl.coverageLimits"),
                        rules: [validations.notNull]
                      }}
                      institutionId={institutionId} />
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) => prev.effectiveBeginningDate !== next.effectiveBeginningDate}>
                      {({ getFieldValue }) => {
                        const beginningDate = getFieldValue(["effectiveBeginningDate"]) as Moment;
                        const rules: Rule[] = [validations.notNull];
                        let beginningDateMax;
                        if ( beginningDate ) {
                          beginningDateMax = toMoment(beginningDate).add(1, "years").subtract(1, "days");
                          rules.push(validations.dateInInterval(beginningDate, beginningDateMax));
                        }
                        return (
                          <Form.Item
                            name={[...formNameMtplPrefix, "effectiveBeginningDate"]}
                            label={t("contract.attrs.insurances.insuranceData.mtpl.effectiveBeginningDate")}
                            rules={rules}>
                            <DatePicker
                              {...datePickerStandardProps}
                              disabledDate={current => beginningDate ? disableDatePickerOutOfInterval(current, beginningDate, beginningDateMax) : false} />
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null;
          }}
        </Form.Item>
      </>
      break;
    case InsuranceType.GAP:
      insuranceDataForm = <>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={[...formNameDataPrefix, "duration"]}
              label={t("calc.vehicle.enums.gapDuration._label")}
              rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(GapDuration).map(duration => ({
                  value: duration,
                  label: t("calc.vehicle.enums.gapDuration." + duration)
                }))} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameDataPrefix, "buyingPrice"]}
              label={t("contract.attrs.insurances.insuranceData.buyingPrice")}
              rules={[validations.minNumber(1)]}>
              <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={1} />
            </Form.Item>
          </Col>

          {standardInsuranceDataForm}

          <Col span={colSpan}>
            <Form.Item
              name={[...formNameDataPrefix, "complicityReinsurance"]}
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
              className="form-item-without-label">
              <Checkbox>{t("contract.attrs.insurances.insuranceData.complicityReinsurance")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </>
      break;
    case InsuranceType.PAS:
      insuranceDataForm = <Row gutter={rowGutter}>{standardInsuranceDataForm}</Row>;
      break;
  }

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={24}><b>{t("contract.attrs.insurances.vehicle._label")}</b></Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Spin spinning={autocomplete.inProgress && autocompleteFocusItem === AutocompleteVehiclesBy.LICENSE_PLATE}>
            <Form.Item
              name={[...formNamePrefix, "licensePlate"]}
              label={t("contract.attrs.insurances.licensePlate")}
              rules={[validations.notBlank, validations.licensePlate]}
              normalize={licensePlateNormalizeFunction}>
              <AutoComplete
                dropdownMatchSelectWidth={false}
                onFocus={() => setAutocompleteFocusItem(AutocompleteVehiclesBy.LICENSE_PLATE)}
                onSearch={value => handleAutocompleteSearch(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                onSelect={value => handleAutocompleteSelect(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                options={resolveAutocompleteOptions(AutocompleteVehiclesBy.LICENSE_PLATE)} />
            </Form.Item>
          </Spin>
        </Col>

        <Col span={colSpan}>
          <Spin spinning={autocomplete.inProgress && autocompleteFocusItem === AutocompleteVehiclesBy.VIN}>
            <Form.Item
              name={[...formNameVehiclePrefix, "vin"]}
              label={t("contract.attrs.insurances.vehicle.vin")}
              rules={[validations.notBlank, validations.size(1, 17)]}
              normalize={upperCaseStringNormalizeFunction}>
              <AutoComplete
                dropdownMatchSelectWidth={false}
                onFocus={() => setAutocompleteFocusItem(AutocompleteVehiclesBy.VIN)}
                onSearch={value => handleAutocompleteSearch(value, AutocompleteVehiclesBy.VIN)}
                onSelect={value => handleAutocompleteSelect(value, AutocompleteVehiclesBy.VIN)}
                options={resolveAutocompleteOptions(AutocompleteVehiclesBy.VIN)} />
            </Form.Item>
          </Spin>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameVehiclePrefix, "firstRegistrationDate"]}
            label={t("contract.attrs.insurances.vehicle.firstRegistrationDate")}
            rules={[validations.notNull]}>
            <DatePicker {...datePickerStandardProps} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "registrationCertificateNumber"]}
            label={t("contract.attrs.insurances.insuranceData.registrationCertificateNumber")}
            rules={[validations.pattern(regexPatterns.registrationCertificateNumberRegex), validations.length(8)]}
            normalize={upperCaseStringNormalizeFunction}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <VehicleBrandEnumFormItemSelect
            formItemProps={{
              name: [...formNameVehiclePrefix, "brandId"],
              label: t("contract.attrs.insurances.vehicle.brandId"),
              rules: [validations.notNull]
            }}
            selectProps={{ onChange: handleBrandIdChange }} />
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => get(prev, formNameVehiclePrefix)?.brandId !== get(next, formNameVehiclePrefix)?.brandId}>
            {({ getFieldValue }) => {
              const brandId = getFieldValue([...formNameVehiclePrefix, "brandId"]);
              return (
                <VehicleModelEnumFormItemSelect
                  formItemProps={{
                    name: [...formNameVehiclePrefix, "modelId"],
                    label: t("contract.attrs.insurances.vehicle.modelId"),
                    rules: [validations.notNull]
                  }}
                  brandId={brandId}
                  selectProps={{ placeholder: !!!brandId ? t("contract.helpers.vehicleModelPlaceholder") : undefined }} />
              )
            }}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => get(prev, formNameVehiclePrefix)?.brandId !== get(next, formNameVehiclePrefix)?.brandId
          || get(prev, formNameVehiclePrefix)?.modelId !== get(next, formNameVehiclePrefix)?.modelId}>
        {({ getFieldValue }) => {
          const hasOtherBrand = contains(otherBrandsIds, getFieldValue([...formNameVehiclePrefix, "brandId"]));
          const hasOtherModel = contains(otherModelIds, getFieldValue([...formNameVehiclePrefix, "modelId"]));
          return hasOtherBrand || hasOtherModel ? (
            <Row gutter={rowGutter}>
              {hasOtherBrand && (
                <Col span={colSpan} offset={colSpan * 4}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "customBrand"]}
                    label={t("contract.attrs.insurances.vehicle.customBrand")}
                    rules={[validations.notBlank, validations.size(1, 255)]}>
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {hasOtherModel && (
                <Col span={colSpan} offset={hasOtherBrand ? 0 : colSpan * 5}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "customModel"]}
                    label={t("contract.attrs.insurances.vehicle.customModel")}
                    rules={[validations.notBlank, validations.size(1, 255)]}>
                    <Input />
                  </Form.Item>
                </Col>
              )}
            </Row>
          ) : null
        }}
      </Form.Item>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameVehiclePrefix, "category"]}
            label={t("contract.enums.vehicleCategory._label")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(VehicleCategory)
                .map(category => ({ value: category, label: t("contract.enums.vehicleCategory." + category) }))}
              onChange={handleCategoryChange} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, formNameVehiclePrefix)?.category !== get(next, formNameVehiclePrefix)?.category
            || get(prev, formNameVehiclePrefix)?.fuelType !== get(next, formNameVehiclePrefix)?.fuelType}>
          {({ getFieldValue }) => {
            const category = getFieldValue([...formNameVehiclePrefix, "category"]) as VehicleCategory;
            const fuelType = getFieldValue([...formNameVehiclePrefix, "fuelType"]) as FuelType;
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "engineDisplacement"]}
                    label={t("contract.attrs.insurances.vehicle.engineDisplacement")}
                    rules={[
                      validations.minNumber(1),
                      !contains(SPECS_MAP.get("engineDisplacement"), category) || fuelType === FuelType.ELECTRICITY
                        ? validations.none : validations.notNull
                    ]}>
                    <InputNumberWithAddon
                      addonType="engineDisplacement" formatStyle="integer" min={1}
                      disabled={!contains(SPECS_MAP.get("engineDisplacement"), category) || fuelType === FuelType.ELECTRICITY} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "enginePower"]}
                    label={t("contract.attrs.insurances.vehicle.enginePower")}
                    rules={[
                      validations.minNumber(1),
                      !contains(SPECS_MAP.get("enginePower"), category) ? validations.none : validations.notNull
                    ]}>
                    <InputNumberWithAddon
                      addonType="enginePower" formatStyle="integer" min={1}
                      disabled={!contains(SPECS_MAP.get("enginePower"), category)} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "fuelType"]}
                    label={t("contract.enums.fuelType._label")}
                    rules={[!contains(SPECS_MAP.get("fuelType"), category) ? validations.none : validations.notNull]}>
                    <Select
                      {...selectStandardProps}
                      disabled={!contains(SPECS_MAP.get("fuelType"), category)}
                      options={Object.keys(FuelType).map(type => ({
                        value: type,
                        label: t("contract.enums.fuelType." + type)
                      }))}
                      onChange={handleFuelTypeChange} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "transmission"]}
                    label={t("contract.enums.transmission._label")}
                    rules={[validations.none]}>
                    <Select
                      {...selectStandardProps} allowClear
                      disabled={!contains(SPECS_MAP.get("transmission"), category)}
                      options={Object.keys(Transmission).map(transmission => ({
                        value: transmission,
                        label: t("contract.enums.transmission." + transmission)
                      }))} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "bodywork"]}
                    label={t("contract.enums.bodywork._label")}
                    rules={[validations.none]}>
                    <Select
                      {...selectStandardProps} allowClear
                      disabled={!contains(SPECS_MAP.get("bodywork"), category)}
                      options={Object.keys(Bodywork).map(bodywork => ({
                        value: bodywork,
                        label: t("contract.enums.bodywork." + bodywork)
                      }))} />
                  </Form.Item>
                </Col>
              </>
            )
          }}
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameVehiclePrefix, "totalWeight"]}
            label={t("contract.attrs.insurances.vehicle.totalWeight")}
            rules={[validations.notNull, validations.minNumber(1)]}>
            <InputNumberWithAddon addonType="weight" formatStyle="integer" min={1} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, formNameVehiclePrefix)?.category !== get(next, formNameVehiclePrefix)?.category}>
          {({ getFieldValue }) => {
            const category = getFieldValue([...formNameVehiclePrefix, "category"]) as VehicleCategory;
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "seatsNumber"]}
                    label={t("contract.attrs.insurances.vehicle.seatsNumber")}
                    rules={[
                      validations.minNumber(0),
                      !contains(SPECS_MAP.get("seatsNumber"), category) ? validations.none : validations.notNull
                    ]}>
                    <InputNumber min={0} disabled={!contains(SPECS_MAP.get("seatsNumber"), category)} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={[...formNameVehiclePrefix, "doorsNumber"]}
                    label={t("contract.attrs.insurances.vehicle.doorsNumber")}
                    rules={[validations.minNumber(0)]}>
                    <InputNumber min={0} disabled={!contains(SPECS_MAP.get("doorsNumber"), category)} />
                  </Form.Item>
                </Col>
              </>
            )
          }}
        </Form.Item>

        <Col span={colSpan}>
          <VehicleColorEnumFormItemSelect formItemProps={{
            name: [...formNameVehiclePrefix, "colorId"],
            label: t("contract.attrs.insurances.vehicle.colorId"),
            rules: [validations.notNull]
          }} />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}><b>{t("contract.sections.vehicleInsuranceData")}</b></Col>
      </Row>

      {insuranceDataForm}

      <Row gutter={rowGutter}>
        <Col span={colSpanBig}>
          <Form.Item
            name={[...formNamePrefix, "vehicleHolderIdentifier"]}
            label={t("contract.attrs.insurances.vehicleHolderIdentifier")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={clients.filter(c => !!c).map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
          </Form.Item>
        </Col>

        <Col span={colSpanBig}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.leasing !== get(next, formNameDataPrefix)?.leasing}>
            {({ getFieldValue }) => {
              const leasing = getFieldValue([...formNameDataPrefix, "leasing"]) as boolean;
              return (
                <Form.Item
                  name={[...formNamePrefix, "vehicleOwnerIdentifier"]}
                  label={leasing
                    ? <LabelWithTooltip label={t("contract.attrs.insurances.vehicleOwnerIdentifier")}
                                        tooltip={t("contract.helpers.vehicleOwnerClient")} />
                    : t("contract.attrs.insurances.vehicleOwnerIdentifier")}
                  rules={[validations.notNull]}>
                  <Select
                    {...selectStandardProps}
                    options={clients
                      .filter((c, i) => leasing ? (c?.type === ClientType.LEGAL && i > 0) : !!c)
                      .map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default VehicleInsuranceFormPart;
