import { RealtyCalc, RealtyFormClients, RealtyGen, RealtyGenFormData } from "./types";
import { CalcType } from "../../enums";
import { RoofType } from "./enums";
import { ClientType } from "../../../client/enums";
import { toMoment } from "../../../../common/utils/formUtils";
import { createUpdateContractClientToClient } from "../../../client/utils";

export const createRealtyFormClientsObject = (genData: RealtyGen): RealtyFormClients => ({
  insured: createUpdateContractClientToClient(genData.clientsData.clients[genData.clientsData.insuredClientIndex]),
  insurer: genData.clientsData.insurerIndex !== genData.clientsData.insuredClientIndex
    ? createUpdateContractClientToClient(genData.clientsData.clients[genData.clientsData.insurerIndex])
    : null,
  authorized1: createUpdateContractClientToClient(genData.clientsData.clients[genData.clientsData.authorizedClient1Index]),
  authorized2: createUpdateContractClientToClient(genData.clientsData.clients[genData.clientsData.authorizedClient2Index]),
  vinculation: createUpdateContractClientToClient(genData.clientsData.clients[genData.clientsData.vinculationClientIndex])
});

export const createRealtyCalcObjectFromCalcData = (calcData: RealtyCalc): RealtyCalc => ({
  ...calcData,
  generalInsuranceData: {
    ...calcData.generalInsuranceData,
    effectiveBeginningDate: toMoment(calcData.generalInsuranceData.effectiveBeginningDate)
  }
});

export const createRealtyCalcObjectFromGenData = (genData: RealtyGen): RealtyCalc => ({
  type: CalcType.REALTY,
  clientsData: {
    insuredClientType: genData.clientsData.insuredClientType,
    insuredClientPin: genData.clientsData.insuredClientPin,
    insuredClientCrn: genData.clientsData.insuredClientCrn
  },
  generalBuildingData: Object.assign({}, genData.generalBuildingData, { buildingPermitNumber: undefined }),
  houseOrRecreationalBuildingData: genData.houseOrRecreationalBuildingData ? {
    ...genData.houseOrRecreationalBuildingData,
    residentialAtticArea: genData.houseOrRecreationalBuildingData.roofType === RoofType.PITCHED_WITH_ATTIC ? genData.houseOrRecreationalBuildingData.residentialAtticArea : undefined
  } : null,
  apartmentData: genData.apartmentData,
  realtyInsuranceData: genData.realtyInsuranceData,
  householdInsuranceData: genData.householdInsuranceData,
  liabilityInsuranceData: genData.liabilityInsuranceData,
  generalInsuranceData: Object.assign({}, genData.generalInsuranceData, {
    effectiveBeginningDate: toMoment(genData.generalInsuranceData.effectiveBeginningDate),
    signCity: undefined,
    anotherInsuranceCompanyName: undefined
  })
});

export const createRealtyGenFormDataObject = (genData: RealtyGen, clients: RealtyFormClients): RealtyGenFormData => ({
  formData: {
    clientsData: {
      insuredClientIdentifier: clients.insured
        ? clients.insured.identifier
        : genData.clientsData.insuredClientType === ClientType.NATURAL ? genData.clientsData.insuredClientPin : genData.clientsData.insuredClientCrn,
      insurerIdentifier: clients.insurer ? clients.insurer.identifier : undefined,
      insurerEmail: genData.clientsData.insurerEmail,
      insurerPhone: genData.clientsData.insurerPhone,
      authorizedClient1Identifier: clients.authorized1 ? clients.authorized1.identifier : undefined,
      authorizedClient1Function: genData.clientsData.authorizedClient1Function,
      authorizedClient2Identifier: clients.authorized2 ? clients.authorized2.identifier : undefined,
      authorizedClient2Function: genData.clientsData.authorizedClient2Function,
      vinculationClientIdentifier: clients.vinculation ? clients.vinculation.identifier : undefined
    },
    generalBuildingData: { buildingPermitNumber: genData.generalBuildingData.buildingPermitNumber || undefined },
    generalInsuranceData: {
      signCity: genData.generalInsuranceData.signCity,
      anotherInsuranceCompanyName: genData.generalInsuranceData.anotherInsuranceCompanyName
    },
    informationObligationData: genData.informationObligationData
  },
  clientsData: {
    clientsDuplicateErrors: [],
    insurerInputEnabled: !!clients.insurer,
    authorizedClientsCount: clients.authorized1 && clients.authorized2 ? 2 : 1
  }
});
