import React, { useState } from "react";
import { Button, Col, Collapse, Divider, Row, Switch, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { JobSettings } from "../../types";
import {
  abortJobActions,
  cancelJobActions,
  filterJobRecordsActions,
  getAllJobsActions,
  scheduleJobActions,
  updateJobActions
} from "../../ducks";
import { formatCron } from "../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import JobRecordsTableView from "./JobRecordsTableView";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import ScheduleJobForm from "../forms/ScheduleJobForm";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import UpdateJobForm from "../forms/UpdateJobForm";

export interface Props {
  jobs: JobSettings[];
  onAllJobsGet: typeof getAllJobsActions.request;
  onUpdate: typeof updateJobActions.request;
  onSchedule: typeof scheduleJobActions.request;
  onFilter: typeof filterJobRecordsActions.request;
  onCancel: typeof cancelJobActions.request;
  onAbort: typeof abortJobActions.request;
}

const JobSettingsView = (props: Props) => {

  const [jobToSchedule, setJobToSchedule] = useState<JobSettings>();
  const [jobToUpdate, setJobToUpdate] = useState<JobSettings>();

  const handleJobEnabledChange = (enabled: boolean, job: JobSettings): void => {
    props.onUpdate({
      id: job.id,
      object: {
        optimisticLockVersion: job.optimisticLockVersion,
        name: job.name,
        cron: job.cron,
        preferences: job.preferences,
        enabled
      }
    });
  };

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <h2 className="margin-bottom-medium">{t("admin.job.titles.page")}</h2>
        </Col>
        <Col span={12} className="right-align" style={{ marginTop: "20px" }}>
          <Button size="small" className="blue-button" icon={<ReloadOutlined />} onClick={() => props.onAllJobsGet()}>
            {t("admin.job.actions.refresh")}
          </Button>
        </Col>
      </Row>

      <Collapse accordion>
        {props.jobs.map(job => (
          <Collapse.Panel
            key={job.id}
            header={<><b>{job.name}</b> {job.cron && <Tooltip title={job.cronDesc}>({job.cron})</Tooltip>}</>}
            extra={
              <span onClick={event => event.stopPropagation()}>
                <Switch
                  className="margin-right-medium"
                  defaultChecked={job.enabled}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={checked => handleJobEnabledChange(checked, job)} />

                  <ActionTextIcon type="edit" color="blue" text={t("common.edit")}
                                  onClick={() => setJobToUpdate(job)} />

                  <Divider type="vertical" />

                  <ActionTextIcon type="time" color="orange" text={t("admin.job.actions.schedule")}
                                  onClick={() => setJobToSchedule(job)} />
              </span>
            }>
            <ItemCreatedUpdatedInfoView item={job} />

            {job.cron && (
              <div className="margin-top-small">
                <b>{t("admin.job.sections.nextJobRuns")}:</b> {formatCron(job.cron, 3)}
              </div>
            )}

            <JobRecordsTableView job={job} onFilter={props.onFilter} onCancel={props.onCancel}
                                 onAbort={props.onAbort} />
          </Collapse.Panel>
        ))}
      </Collapse>

      <UpdateJobForm
        job={jobToUpdate}
        visible={!!jobToUpdate}
        onUpdate={props.onUpdate}
        onFormCancel={() => setJobToUpdate(null)} />

      <ScheduleJobForm
        job={jobToSchedule}
        visible={!!jobToSchedule}
        onSchedule={props.onSchedule}
        onFormCancel={() => setJobToSchedule(null)} />
    </>
  )
}

export default JobSettingsView;
