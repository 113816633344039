import React from "react";
import { Link } from "react-router-dom";
import { Divider, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthAccount } from "../../modules/auth/types";
import t from "../i18n";

export interface Props {
  account: AuthAccount;
  userAuthenticated: boolean;
}

const UserSection = ({ account, userAuthenticated }: Props) => (
  userAuthenticated && account ? (
    <div className="header-user-section">
      <Divider type="vertical" />

      <span className="header-info">
        <UserOutlined />&nbsp;
        <Tooltip mouseEnterDelay={1} placement="bottom" title={t("navigation.helpers.accountDetail")}>
          <Link to="/me">{account.person.aggregatedName}</Link>
        </Tooltip>
        <br />
        <span className="header-sub-info">
          {t("navigation.helpers.username")}: {account.name ? account.name : account.username}
        </span>
      </span>
    </div>
  ) : null
);

export default UserSection;
