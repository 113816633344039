import React from "react";
import moment from "moment";
import { Card, Checkbox, Col, DatePicker, Divider, Input, InputNumber, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { CrashCalcResultData, VehicleCalc, VehicleCalcResultData, VehicleGenFormVehicleData } from "../../../types";
import { CalcResult } from "../../../../types";
import { VehicleEquipmentType, VehiclePriceSource, VehicleSecurityFeature } from "../../../enums";
import { CalcType } from "../../../../../enums";
import { InstitutionEnum } from "../../../../../../admin/institution/enums";
import { M1_N1, SELF_MOVING, SPECS_MAP } from "../../../utils";
import { contains, removeFromArray } from "../../../../../../../common/utils/utils";
import validations, { regexPatterns } from "../../../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  licensePlateNormalizeFunction,
  selectStandardProps,
  upperCaseStringNormalizeFunction
} from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import VehicleBrandEnumName from "../../../../../../enumerations/components/view/VehicleBrandEnumName";
import VehicleModelEnumName from "../../../../../../enumerations/components/view/VehicleModelEnumName";
import VehicleColorEnumerationsSelect
  from "../../../../../../enumerations/components/deprecated/VehicleColorEnumerationsSelect_Deprecated";
import HiddenInputDeprecated from "../../../../../../../common/components/form/components/HiddenInput_Deprecated";
import HiddenCheckbox from "../../../../../../../common/components/form/components/HiddenCheckbox_Deprecated";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import InputNumberWithAddon from "../../../../../../../common/components/form/components/InputNumberWithAddon";

export interface Props {
  form: WrappedFormUtils;
  initialVehicleData: VehicleGenFormVehicleData;
  calcData: VehicleCalc;
  selectedResult: CalcResult<VehicleCalcResultData>;
}

interface State {
  readonly damaged: boolean;
  readonly standardWheels: boolean;
  readonly selectedEquipments: VehicleEquipmentType[];
}

class VehicleGenVehicleDataSection extends React.Component<Props, State> {
  readonly state: State = {
    damaged: this.props.initialVehicleData ? !!this.props.initialVehicleData.damaged : false,
    standardWheels: this.props.initialVehicleData ? !!this.props.initialVehicleData.standardWheels : false,
    selectedEquipments: this.props.initialVehicleData && this.props.initialVehicleData.equipment
      ? this.props.initialVehicleData.equipment.filter(e => e.selected).map(e => e.type)
      : []
  };

  handleDamagedChange = (e: CheckboxChangeEvent): void => {
    this.setState({ damaged: e.target.checked });
  }

  handleStandardWheelsChange = (e: CheckboxChangeEvent): void => {
    this.setState({ standardWheels: e.target.checked });
  }

  handleSelectedEquipmentsChange = (event: CheckboxChangeEvent, equipment: VehicleEquipmentType): void => {
    if ( event.target.checked ) {
      this.setState(previousState => ({
        selectedEquipments: contains(previousState.selectedEquipments, equipment)
          ? previousState.selectedEquipments
          : [equipment, ...previousState.selectedEquipments]
      }));
    }
    else {
      this.setState(previousState => ({ selectedEquipments: removeFromArray(previousState.selectedEquipments, e => e === equipment) }));
    }
  };

  render(): React.ReactNode {
    const { damaged, standardWheels, selectedEquipments } = this.state;
    const { form, calcData, selectedResult } = this.props;
    const { vehicleData } = calcData;
    const { calcType } = selectedResult;
    const { institutionEnum, } = selectedResult.insuranceInstitution;
    const { getFieldDecorator } = form;
    const colSpan = 4;

    return (
      <Card size="small" type="inner" className="card-box" title={t("calc.vehicle.sections.additionalVehicleData")}>

        <div className="sub-header-info normal-font-size margin-bottom-small">
          <span>{t("calc.vehicle.sections.vehicleData")}: </span>
          <VehicleBrandEnumName brandId={vehicleData.brandId} otherBrandName={vehicleData.customBrand} />&nbsp;
          <VehicleModelEnumName brandId={vehicleData.brandId} modelId={vehicleData.modelId}
                                otherModelName={vehicleData.customModel} />,&nbsp;
          {t("calc.vehicle.attrs.vehicleData.licensePlate")} - {licensePlateNormalizeFunction(vehicleData.licensePlate)},&nbsp;
          {t("calc.vehicle.attrs.vehicleData.vin")} - {vehicleData.vin}
        </div>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label={<LabelWithTooltip label={t("calc.vehicle.attrs.vehicleData.registrationCertificateNumber")}
                                       tooltip={t("calc.vehicle.helpers.registrationCertificateNumberDesc")} />}>
              {getFieldDecorator("vehicleData.registrationCertificateNumber", {
                rules: [validations.pattern(regexPatterns.registrationCertificateNumberRegex), validations.length(8)],
                normalize: upperCaseStringNormalizeFunction
              })(
                <Input />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.colorId")}>
              {getFieldDecorator("vehicleData.colorId", { rules: [validations.notNull] })(
                <VehicleColorEnumerationsSelect />
              )}
            </Form.Item>
          </Col>

          {institutionEnum === InstitutionEnum.UNIQA && calcType === CalcType.CRASH && (
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.enums.vehiclePriceSource._label")}>
                {getFieldDecorator("vehicleData.priceSource", {
                  rules: [validations.notNull],
                  initialValue: (selectedResult.data as CrashCalcResultData).vehiclePriceCalculatedByUniqa
                    ? VehiclePriceSource.INSURANCE_COMPANY_PRICE_LIST : undefined
                })(
                  <Select
                    {...selectStandardProps}
                    disabled={(selectedResult.data as CrashCalcResultData).vehiclePriceCalculatedByUniqa}
                    options={
                      ((selectedResult.data as CrashCalcResultData).vehiclePriceCalculatedByUniqa
                        ? [VehiclePriceSource.INSURANCE_COMPANY_PRICE_LIST]
                        : [VehiclePriceSource.INVOICE, VehiclePriceSource.INSURANCE_COMPANY_EXPERT]).map(source => ({
                        value: source,
                        label: t("calc.vehicle.enums.vehiclePriceSource." + source)
                      }))} />
                )}
              </Form.Item>
            </Col>
          )}

          <Col span={colSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("vehicleData.damaged", {
                rules: [validations.none], valuePropName: "checked", initialValue: damaged
              })(
                <Checkbox onChange={this.handleDamagedChange}>
                  {t("calc.vehicle.attrs.vehicleData.damaged")}
                </Checkbox>
              )}
            </Form.Item>
          </Col>

          {damaged && (
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.attrs.vehicleData.damageDescription")}>
                {getFieldDecorator("vehicleData.damageDescription", {
                  rules: [validations.notBlank, validations.size(1, 255)]
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
          )}

          {contains(SELF_MOVING, vehicleData.category)
          && (institutionEnum === InstitutionEnum.ALLIANZ
            || (institutionEnum === InstitutionEnum.GENERALI && calcType === CalcType.CRASH)) && (
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.attrs.vehicleData.key.numberOfPieces")}>
                {getFieldDecorator("vehicleData.key.numberOfPieces", {
                  rules: [validations.notNull, validations.minNumber(1), validations.maxNumber(100)]
                })(
                  <InputNumber min={1} max={100} />
                )}
              </Form.Item>
            </Col>
          )}

          {contains(SELF_MOVING, vehicleData.category)
          && institutionEnum === InstitutionEnum.GENERALI && calcType === CalcType.CRASH && (
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.attrs.vehicleData.key.numberOfCopies")}>
                {getFieldDecorator("vehicleData.key.numberOfCopies", {
                  rules: [validations.notNull, validations.minNumber(0), validations.maxNumber(100)]
                })(
                  <InputNumber min={0} max={100} />
                )}
              </Form.Item>
            </Col>
          )}

          {(institutionEnum === InstitutionEnum.ALLIANZ || institutionEnum === InstitutionEnum.AXA_NON_LIFE)
          && calcData.type === CalcType.MTPL
          && contains(SPECS_MAP.get("odometer"), vehicleData.category)
          && (
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.attrs.vehicleData.odometer")}>
                {getFieldDecorator("vehicleData.odometer", {
                  rules: [validations.notNull, validations.minNumber(0)]
                })(
                  <InputNumberWithAddon addonType="distance" formatStyle="integer" min={0} />
                )}
              </Form.Item>
            </Col>
          )}

          {institutionEnum === InstitutionEnum.COLONNADE && calcType === CalcType.GAP && (
            <Col span={colSpan}>
              <Form.Item label={<LabelWithTooltip label={t("calc.vehicle.attrs.vehicleData.purchaseDate")}
                                                  tooltip={t("calc.vehicle.helpers.purchaseDateDesc")} />}>
                {getFieldDecorator("vehicleData.purchaseDate", {
                  rules: [validations.notNull, validations.dateInInterval(moment().subtract(6, 'month'), moment())]
                })(
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current => disableDatePickerOutOfInterval(current, moment().subtract(6, 'month'), moment())} />
                )}
              </Form.Item>
            </Col>
          )}
        </Row>

        {institutionEnum === InstitutionEnum.UNIQA && calcType === CalcType.CRASH && (
          <>
            <Row gutter={rowGutter}>
              {contains(SELF_MOVING, vehicleData.category) && (
                <Col span={colSpan}>
                  <Form.Item label={t("calc.vehicle.attrs.vehicleData.key.numberOfPieces")}>
                    {getFieldDecorator("vehicleData.key.numberOfPieces", {
                      rules: [validations.notNull, validations.minNumber(1), validations.maxNumber(100)]
                    })(
                      <InputNumber min={1} max={100} />
                    )}
                  </Form.Item>
                </Col>
              )}

              <Col span={colSpan}>
                <Form.Item className="three-line-form-item-without-label">
                  {getFieldDecorator("vehicleData.standardKeys", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox>{t("calc.vehicle.attrs.vehicleData.standardKeys")}</Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={colSpan + 1}>
                <Form.Item className="three-line-form-item-without-label">
                  {getFieldDecorator("vehicleData.standardWheels", {
                    rules: [validations.none], valuePropName: "checked", initialValue: standardWheels
                  })(
                    <Checkbox onChange={this.handleStandardWheelsChange}>
                      {t("calc.vehicle.attrs.vehicleData.standardWheels")}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              {!standardWheels && (
                <Col span={colSpan + 1}>
                  <Form.Item label={<LabelWithTooltip label={t("calc.vehicle.attrs.vehicleData.nonStandardWheelsDesc")}
                                                      tooltip={t("calc.vehicle.helpers.nonStandardWheelsDescInfo")} />}>
                    {getFieldDecorator("vehicleData.nonStandardWheelsDesc", {
                      rules: [validations.notBlank, validations.size(1, 255)]
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>
          </>
        )}

        {institutionEnum === InstitutionEnum.UNION && calcType === CalcType.CRASH && contains(M1_N1, calcData.vehicleData.category) && (
          <>
            <Row gutter={rowGutter}>
              <Col span={colSpan * 3}>
                <Divider>{t("calc.vehicle.sections.vehicleEquipment")}</Divider>
              </Col>
            </Row>

            {Object.values(VehicleEquipmentType).map((type, index) => (
              <Row gutter={rowGutter} key={index}>
                <Col span={colSpan}>
                  <HiddenInputDeprecated form={form} formKey={`vehicleData.equipment[${index}].type`}
                                         initialValue={type} />
                  <Form.Item
                    className={type === VehicleEquipmentType.OTHER && contains(selectedEquipments, VehicleEquipmentType.OTHER)
                      ? "form-item-without-label" : undefined}>
                    {getFieldDecorator(`vehicleData.equipment[${index}].selected`, {
                      rules: [validations.none], valuePropName: "checked", initialValue: false
                    })(
                      <Checkbox onChange={e => this.handleSelectedEquipmentsChange(e, type)}>
                        {t("calc.vehicle.enums.vehicleEquipmentType." + type)}
                      </Checkbox>
                    )}
                  </Form.Item>
                </Col>

                {type === VehicleEquipmentType.OTHER && contains(selectedEquipments, VehicleEquipmentType.OTHER) && (
                  <Col span={colSpan}>
                    <Form.Item label={t("calc.vehicle.attrs.vehicleData.equipment.description")}>
                      {getFieldDecorator(`vehicleData.equipment[${index}].description`, {
                        rules: [validations.notBlank, validations.size(1, 255)]
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            ))}
          </>
        )}

        {institutionEnum === InstitutionEnum.UNIQA && calcType === CalcType.CRASH
        && vehicleData.security.filter(security => security.selected)
          .find(s => s.feature === VehicleSecurityFeature.MECHANICAL || s.feature === VehicleSecurityFeature.ALARM) && (
          <>
            <Row gutter={rowGutter}>
              <Col span={colSpan * 3}>
                <Divider>{t("calc.vehicle.sections.vehicleSecurity")}</Divider>
              </Col>
            </Row>

            {vehicleData.security.filter(security => security.selected).map((security, index) => (
              <Row gutter={rowGutter} key={index}>
                <HiddenCheckbox form={form} formKey={`vehicleData.security[${index}].selected`} initialValue />
                <HiddenInputDeprecated form={form} formKey={`vehicleData.security[${index}].feature`}
                                       initialValue={security.feature} />

                {security.feature === VehicleSecurityFeature.ALARM || security.feature === VehicleSecurityFeature.MECHANICAL ? (
                  <>
                    <Col span={colSpan} className="form-item-without-label">
                      {t("calc.vehicle.enums.vehicleSecurityFeature." + security.feature)}:
                    </Col>

                    <Col span={colSpan + 1}>
                      <Form.Item label={t("calc.vehicle.attrs.vehicleData.security.numberOfKeysOrControllers")}>
                        {getFieldDecorator(`vehicleData.security[${index}].numberOfKeysOrControllers`, {
                          rules: [validations.notNull, validations.minNumber(0), validations.maxNumber(100)]
                        })(
                          <InputNumber min={0} max={100} />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
              </Row>
            ))}
          </>
        )}

      </Card>
    )
  }
}

export default VehicleGenVehicleDataSection;
