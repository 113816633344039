import React from "react";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { Contact } from "../types";
import { ContactType } from "../enums";
import { contains } from "../../utils/utils";
import t from "../../../app/i18n";

import AddDeleteButton from "../../components/buttons/AddDeleteButton";
import ContactRowFormItemDeprecated from "./ContactRowFormItem_Deprecated";

export interface Props {
  form: WrappedFormUtils;
  initialContacts?: Contact[];
  useBrokerContactTypes?: boolean;
}

interface State {
  readonly selectedContactTypes: ContactType[];
}

class ContactsForm_Deprecated extends React.Component<Props, State> {
  readonly state: State = {
    selectedContactTypes: this.props.initialContacts ? this.props.initialContacts.map(contact => contact.type) : []
  };

  handleContactTypeChange = (index: number, contactType: ContactType): void => {
    this.setState(previousState => {
      const selectedContactTypes = [...previousState.selectedContactTypes];
      selectedContactTypes[index] = contactType;
      return { selectedContactTypes };
    }, () => this.props.form.validateFields([`contacts[${index}].value`], { force: true }));
  };

  handleContactDelete = (index: number): void => {
    const contacts: Contact[] = this.props.form.getFieldValue("contacts");
    contacts.splice(index, 1);

    this.props.form.setFieldsValue({ contacts });
    this.setState({ selectedContactTypes: contacts.map(contact => contact.type) });
  };

  handleContactAdd = (): void => {
    this.setState(previousState => ({ selectedContactTypes: [...previousState.selectedContactTypes, undefined] }));
  };

  render(): React.ReactNode {
    const { selectedContactTypes } = this.state;
    const { useBrokerContactTypes } = this.props;
    const contactTypeOptions: ContactType[] = useBrokerContactTypes
      ? Object.values(ContactType)
      : Object.values(ContactType).filter(type => type !== ContactType.BROKER_EMAIL && type !== ContactType.BROKER_PHONE_NUMBER);

    const disabledContactTypeOptions: ContactType[] = [];
    if ( contains(selectedContactTypes, ContactType.BROKER_EMAIL) ) {
      disabledContactTypeOptions.push(ContactType.BROKER_EMAIL);
    }
    if ( contains(selectedContactTypes, ContactType.BROKER_PHONE_NUMBER) ) {
      disabledContactTypeOptions.push(ContactType.BROKER_PHONE_NUMBER);
    }

    const contactFormItems = [];
    for ( let index = 0; index < selectedContactTypes.length; index++ ) {
      contactFormItems.push(
        <ContactRowFormItemDeprecated
          key={index} form={this.props.form} index={index}
          selectedContactType={selectedContactTypes[index]}
          contactTypeOptions={contactTypeOptions}
          disabledContactTypeOptions={disabledContactTypeOptions}
          showUseForNotificationsItem={useBrokerContactTypes}
          onContactTypeChange={this.handleContactTypeChange}
          onDelete={this.handleContactDelete} />
      );
    }

    return (
      <>
        {contactFormItems}
        <AddDeleteButton type="add" label={t("contact.actions.add")} onClick={this.handleContactAdd} />
      </>
    )
  }
}

export default ContactsForm_Deprecated;
