import React from "react";
import { Alert } from "antd";
import { CalcResult } from "../../../types";
import { TravelCalcResultData } from "../../types";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { HttpStatus } from "../../../../../../common/constants";
import { formatLocaleCurrency } from "../../../../../../common/utils/formatUtils";
import t from "../../../../../../app/i18n";

import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";

export interface Props {
  result: CalcResult<TravelCalcResultData>;
  disabled: boolean;
  onGenerateContractClick(result: CalcResult<TravelCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<TravelCalcResultData>): void;
}

const TravelCalcResultItem = ({ result, disabled, ...props }: Props) => {

  const resultAnnualPremiumTable = result.data ? (
    <table className="prices-table-view">
      <tbody>
      <tr>
        <td>{t("calc.travel.results.annualPremium")}</td>
        <td>{formatLocaleCurrency(result.data.annualPremium)}</td>
      </tr>
      </tbody>
    </table>
  ) : null;

  let resultAlert = null;
  if ( result.data && result.data.warnings && result.data.warnings.length > 0 ) {
    resultAlert =
      <Alert type="warning" showIcon message={<MultiLineText showDivider tokens={result.data.warnings} />} />
  }
  else if ( result.error ) {
    if ( result.error.status === HttpStatus.UNPROCESSABLE_ENTITY ) {
      resultAlert = <Alert type="error" showIcon message={result.error.message}
                           description={<FieldViolationsView violations={result.error.violations}
                                                             rootPath="calc.travel.attrs" />} />
    }
    else {
      resultAlert = <Alert type="error" showIcon message={result.error.message}
                           description={<MultiLineText tokens={result.error.errors} />} />
    }
  }

  let resultAction = null;
  if ( result.data ) {
    resultAction = <ComponentWithPermission permissions={[Permission.TRAVEL_CALCULATOR_GENERATE_CONTRACT]}>
      <ActionTextIcon type="check" color="green" size="large" disabled={disabled}
                      text={t("calc.travel.actions.createContract")}
                      onClick={() => props.onGenerateContractClick(result)} />
    </ComponentWithPermission>
  }
  else if ( props.onShowErrorsClick && result.error && result.error.status === HttpStatus.UNPROCESSABLE_ENTITY ) {
    resultAction = <ActionTextIcon type="eye" color="red" size="large" text={t("calc.travel.actions.showErrors")}
                                   onClick={() => props.onShowErrorsClick(result)} />
  }

  return (
    <>
      {result.coverage && <h4>{result.coverage}</h4>}
      {resultAnnualPremiumTable}
      {resultAlert}
      {resultAction && <div className="margin-top-tiny">{resultAction}</div>}
    </>
  )
};

export default TravelCalcResultItem;
