import skCalc from "./sk/calc/calc-translations.json";
import skCalcVehicle from "./sk/calc/calc-vehicle-translations.json";
import skCalcRealty from "./sk/calc/calc-realty-translations.json";
import skCalcTravel from "./sk/calc/calc-travel-translations.json";
import skCommissionsBatch from "./sk/commissions/commissions-batch-translations.json";
import skCommon from "./sk/common-translations.json";
import skMyIdentity from "./sk/my-identity-translations.json";
import skContract from "./sk/contract-translations.json";
import skClient from "./sk/client-translations.json";
import skPerson from "./sk/person-translations.json";
import skAccount from "./sk/account-translations.json";
import skAdmin from "./sk/admin-translations.json";

import en from "./en/translations.json";

export default {
  sk: {
    calc: {
      ...skCalc,
      ...skCalcVehicle,
      ...skCalcRealty,
      ...skCalcTravel
    },
    commissions: {
      ...skCommissionsBatch
    },
    ...skCommon,
    ...skMyIdentity,
    ...skContract,
    ...skClient,
    ...skPerson,
    ...skAccount,
    ...skAdmin
  }, en
};
