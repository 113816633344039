import React, { ReactText } from "react";
import moment, { Moment } from "moment";
import { Checkbox, Col, DatePicker, Divider, InputNumber, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { TravelCalc } from "../../types";
import {
  CancellationInsuranceCoverage,
  LuggageReinsuranceCoverage,
  TravelClientDiscountType,
  TravelInsuranceType,
  TravelRiskGroup,
  TravelTerritorialValidity
} from "../../enums";
import { resolveUnionFamilyDiscountPossible } from "../../utils";
import validations from "../../../../../../common/utils/validationUtils";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import InputNumberWithAddon from "../../../../../../common/components/form/components/InputNumberWithAddon";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  form: WrappedFormUtils;
  initialData: TravelCalc;
  insuranceType: TravelInsuranceType;
  onInsuranceTypeChange(insuranceType: TravelInsuranceType): void;
}

interface State {
  readonly effectiveBeginningDate: Moment;
  readonly insuredClientsCount: number;
  readonly unionFamilyDiscountPossible: boolean;
  readonly cancellationReinsuranceEnabled: boolean;
  readonly luggageReinsuranceEnabled: boolean;
}

const INSURED_CLIENTS_MAX_COUNT = 31;

class TravelCalcForm extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const stateObject = {
      effectiveBeginningDate: null,
      insuredClientsCount: null,
      unionFamilyDiscountPossible: false,
      cancellationReinsuranceEnabled: false,
      luggageReinsuranceEnabled: false
    };

    if ( props.initialData ) {
      stateObject.effectiveBeginningDate = props.initialData.generalData.effectiveBeginningDate;
      stateObject.insuredClientsCount = props.initialData.clientsData.insuredClientsCount;
      stateObject.unionFamilyDiscountPossible = resolveUnionFamilyDiscountPossible(props.initialData.clientsData.insuredClients);
      stateObject.cancellationReinsuranceEnabled = !!(props.initialData.reinsurancesData && props.initialData.reinsurancesData.cancellationReinsurance);
      stateObject.luggageReinsuranceEnabled = !!(props.initialData.reinsurancesData && props.initialData.reinsurancesData.luggageReinsuranceCoverage);
    }

    this.state = stateObject;
  }

  handleInsuranceTypeChange = (insuranceType: TravelInsuranceType): void => {
    this.props.onInsuranceTypeChange(insuranceType);
    this.setState({
      unionFamilyDiscountPossible: insuranceType === TravelInsuranceType.SHORT_TERM
        ? resolveUnionFamilyDiscountPossible((this.props.form.getFieldsValue(["clientsData"]) as TravelCalc).clientsData.insuredClients)
        : false
    });
  };

  handleEffectiveBeginningDateChange = (effectiveBeginningDate: Moment): void => {
    this.setState({ effectiveBeginningDate }, () => {
      if ( moment.isMoment(this.props.form.getFieldValue("generalData.effectiveEndDate")) ) {
        this.props.form.validateFields(["generalData.effectiveEndDate"], { force: true });
      }
    });
  };

  handleInsuredClientsCountChange = (count: ReactText): void => {
    this.setState({
      insuredClientsCount: count as number > INSURED_CLIENTS_MAX_COUNT ? INSURED_CLIENTS_MAX_COUNT : count as number
    });
  };

  handleInsuredClientAgeChange = (age: ReactText, index: number): void => {
    const clients = [...(this.props.form.getFieldsValue(["clientsData"]) as TravelCalc).clientsData.insuredClients];
    clients[index] = { ...clients[index], age: age as number };

    const unionFamilyDiscountPossible = resolveUnionFamilyDiscountPossible(clients);
    this.setState({ unionFamilyDiscountPossible });

    if ( !unionFamilyDiscountPossible && this.props.form.getFieldValue("discountsData.unionFamilyDiscount") ) {
      this.props.form.setFieldsValue({ "discountsData.unionFamilyDiscount": false });
    }
  };

  handleCancellationReinsuranceEnabledChange = (event: CheckboxChangeEvent): void => {
    this.setState({ cancellationReinsuranceEnabled: event.target.checked });
  };

  handleLuggageReinsuranceEnabledChange = (event: CheckboxChangeEvent): void => {
    this.setState({ luggageReinsuranceEnabled: event.target.checked });
  };

  resolveBeginningDateFrom = (): Moment => {
    return this.props.insuranceType === TravelInsuranceType.YEAR ? moment().add(1, "day") : moment();
  };

  resolveBeginningDateTo = (): Moment => {
    return moment().add(18, "month");
  };

  resolveEndDateFrom = (): Moment => {
    return this.state.effectiveBeginningDate ? this.state.effectiveBeginningDate : moment();
  };

  resolveEndDateTo = (): Moment => {
    return this.state.effectiveBeginningDate
      ? moment(this.state.effectiveBeginningDate).add(1, "year")
      : moment().add(1, "year");
  };

  resolveFirstPaymentDateFrom = (): Moment => {
    return moment().subtract(3, "month");
  };

  resolveFirstPaymentDateTo = (): Moment => {
    return moment();
  };

  render(): React.ReactNode {
    const { insuredClientsCount, unionFamilyDiscountPossible, cancellationReinsuranceEnabled, luggageReinsuranceEnabled } = this.state;
    const { insuranceType } = this.props;
    const { getFieldDecorator } = this.props.form;

    const colSpan = 8;
    const colSpanMiddle = 6;
    const colSpanSmall = 4;

    const beginningDateFrom = this.resolveBeginningDateFrom();
    const beginningDateTo = this.resolveBeginningDateTo();
    const endDateFrom = this.resolveEndDateFrom();
    const endDateTo = this.resolveEndDateTo();
    const firstPaymentDateFrom = this.resolveFirstPaymentDateFrom();
    const firstPaymentDateTo = this.resolveFirstPaymentDateTo();

    return (
      <>
        <Row gutter={rowGutter}>
          <Divider>{t("calc.travel.sections.generalData")}</Divider>

          <Col span={colSpan}>
            <Form.Item label={t("calc.travel.enums.insuranceType._label")}>
              {getFieldDecorator("generalData.insuranceType", {
                rules: [validations.notNull], initialValue: insuranceType
              })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(TravelInsuranceType).map(type => ({
                    value: type,
                    label: t("calc.travel.enums.insuranceType." + type)
                  }))}
                  onChange={this.handleInsuranceTypeChange} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.travel.attrs.generalData.effectiveBeginningDate")}>
              {getFieldDecorator("generalData.effectiveBeginningDate", {
                rules: [validations.notNull, validations.dateInInterval(beginningDateFrom, beginningDateTo)]
              })(
                <DatePicker
                  {...datePickerStandardProps}
                  showToday={false}
                  disabledDate={current => disableDatePickerOutOfInterval(current, beginningDateFrom, beginningDateTo)}
                  onChange={this.handleEffectiveBeginningDateChange} />
              )}
            </Form.Item>
          </Col>

          {insuranceType === TravelInsuranceType.SHORT_TERM || insuranceType === TravelInsuranceType.CANCELLATION ? (
            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.attrs.generalData.effectiveEndDate")}>
                {getFieldDecorator("generalData.effectiveEndDate", {
                  rules: [validations.notNull, validations.dateInInterval(endDateFrom, endDateTo)]
                })(
                  <DatePicker
                    {...datePickerStandardProps}
                    showToday={false}
                    disabledDate={current => disableDatePickerOutOfInterval(current, endDateFrom, endDateTo)} />
                )}
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.travel.enums.territorialValidity._label")}>
              {getFieldDecorator("generalData.territorialValidity", { rules: [validations.notNull] })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(TravelTerritorialValidity).map(validity => ({
                    value: validity,
                    label: t("calc.travel.enums.territorialValidity." + validity)
                  }))} />
              )}
            </Form.Item>
          </Col>

          {insuranceType === TravelInsuranceType.SHORT_TERM || insuranceType === TravelInsuranceType.YEAR ? (
            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.enums.riskGroup._label")}>
                {getFieldDecorator("generalData.riskGroup", { rules: [validations.notNull] })(
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(TravelRiskGroup).map(group => ({
                      value: group,
                      label: t("calc.travel.enums.riskGroup." + group)
                    }))} />
                )}
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        {insuranceType === TravelInsuranceType.CANCELLATION && (
          <Row gutter={rowGutter}>
            <Divider>{t("calc.travel.sections.cancellationInsurance")}</Divider>

            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.attrs.cancellationInsuranceData.tripPrice")}>
                {getFieldDecorator("cancellationInsuranceData.tripPrice", {
                  rules: [validations.notNull, validations.minNumber(100), validations.multipleOf100]
                })(
                  <InputNumberWithAddon addonType="euro" formatStyle="integer" min={100} step={100} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.enums.cancellationInsuranceCoverage._label")}>
                {getFieldDecorator("cancellationInsuranceData.coverage", { rules: [validations.none] })(
                  <Select
                    {...selectStandardProps} allowClear
                    options={Object.keys(CancellationInsuranceCoverage).map(coverage => ({
                      value: coverage,
                      label: t("calc.travel.enums.cancellationInsuranceCoverage." + coverage)
                    }))} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.attrs.cancellationInsuranceData.firstPaymentDate")}>
                {getFieldDecorator("cancellationInsuranceData.firstPaymentDate", {
                  rules: [validations.notFuture, validations.dateInInterval(firstPaymentDateFrom, firstPaymentDateTo)]
                })(
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current => disableDatePickerOutOfInterval(current, firstPaymentDateFrom, firstPaymentDateTo)} />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={rowGutter}>
          <Divider>{t("calc.travel.sections.clients")}</Divider>

          <Col span={colSpanMiddle}>
            <Form.Item label={t("calc.travel.attrs.clientsData.insuredClientsCount")}>
              {getFieldDecorator("clientsData.insuredClientsCount", {
                rules: [validations.notNull, validations.minNumber(1), validations.maxNumber(INSURED_CLIENTS_MAX_COUNT)],
                initialValue: insuredClientsCount
              })(
                <InputNumber min={1} max={INSURED_CLIENTS_MAX_COUNT} onChange={this.handleInsuredClientsCountChange} />
              )}
            </Form.Item>
          </Col>

          {insuranceType === TravelInsuranceType.SHORT_TERM && (
            <Col span={colSpanMiddle} offset={colSpanMiddle * 2}>
              <Divider className="divider-subheader" style={{ margin: "0 0 9px 0" }}>
                {t("calc.travel.sections.discounts")}
              </Divider>

              <Form.Item>
                {getFieldDecorator("discountsData.unionFamilyDiscount", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox disabled={!unionFamilyDiscountPossible}>
                    <LabelWithTooltip label={t("calc.travel.attrs.discountsData.unionFamilyDiscount")}
                                      tooltip={t("calc.travel.helpers.unionFamilyDiscountDesc")} />
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          )}

          {insuranceType === TravelInsuranceType.YEAR && (
            <Col span={colSpanMiddle} offset={colSpanMiddle * 2}>
              <Divider className="divider-subheader" style={{ margin: "0 0 9px 0" }}>
                {t("calc.travel.sections.discounts")}
              </Divider>

              <Form.Item className="two-line-form-item">
                {getFieldDecorator("discountsData.unionHealthInsuranceClient", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox>{t("calc.travel.attrs.discountsData.unionHealthInsuranceClient")}</Checkbox>
                )}
              </Form.Item>
            </Col>
          )}
        </Row>

        {insuredClientsCount > 0 && insuranceType === TravelInsuranceType.SHORT_TERM && (
          <>
            {[...Array(insuredClientsCount)].map((value, index) =>
              <Row gutter={rowGutter} key={index}>
                <Col span={colSpanMiddle}>
                  <Form.Item label={t("calc.travel.attrs.clientsData.insuredClients.ageLabel", { number: index + 1 })}>
                    {getFieldDecorator(`clientsData.insuredClients[${index}].age`, {
                      rules: [validations.notNull, validations.minNumber(1), validations.maxNumber(99)]
                    })(
                      <InputNumber
                        min={1} max={99}
                        onChange={value => this.handleInsuredClientAgeChange(value, index)} />
                    )}
                  </Form.Item>
                </Col>

                <Col span={colSpanMiddle}>
                  <Form.Item label={t("calc.travel.enums.travelClientDiscountType._label")}>
                    {getFieldDecorator(`clientsData.insuredClients[${index}].discountType`, { rules: [validations.none] })(
                      <Select
                        {...selectStandardProps} allowClear
                        options={Object.keys(TravelClientDiscountType).map(discount => ({
                          value: discount,
                          label: t("calc.travel.enums.travelClientDiscountType." + discount)
                        }))} />
                    )}
                  </Form.Item>
                </Col>

                <Col span={colSpanMiddle}>
                  <Form.Item className="form-item-without-label">
                    {getFieldDecorator(`clientsData.insuredClients[${index}].electronicDevicesReinsurance`, {
                      rules: [validations.none], valuePropName: "checked", initialValue: false
                    })(
                      <Checkbox>{t("calc.travel.attrs.clientsData.insuredClients.electronicDevicesReinsurance")}</Checkbox>
                    )}
                  </Form.Item>
                </Col>

                <Col span={colSpanMiddle}>
                  <Form.Item className="form-item-without-label">
                    {getFieldDecorator(`clientsData.insuredClients[${index}].travelDocumentsReinsurance`, {
                      rules: [validations.none], valuePropName: "checked", initialValue: false
                    })(
                      <Checkbox>{t("calc.travel.attrs.clientsData.insuredClients.travelDocumentsReinsurance")}</Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}

        {insuredClientsCount > 0 && insuranceType === TravelInsuranceType.YEAR && (
          <Row gutter={rowGutter}>
            {[...Array(insuredClientsCount)].map((value, index) =>
              <Col span={colSpanSmall} key={index}>
                <Form.Item label={t("calc.travel.attrs.clientsData.insuredClients.ageLabel", { number: index + 1 })}>
                  {getFieldDecorator(`clientsData.insuredClients[${index}].age`, {
                    rules: [validations.notNull, validations.minNumber(1), validations.maxNumber(99)]
                  })(
                    <InputNumber min={1} max={99} />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        )}

        {insuranceType === TravelInsuranceType.SHORT_TERM && (
          <>
            <Divider>{t("calc.travel.sections.reinsurances")}</Divider>

            <Row gutter={rowGutter}>
              <Col span={colSpanMiddle}>
                <Form.Item className="form-item-without-label">
                  <Checkbox
                    checked={cancellationReinsuranceEnabled}
                    onChange={this.handleCancellationReinsuranceEnabledChange}>
                    {t("calc.travel.sections.cancellationReinsurance")}
                  </Checkbox>
                </Form.Item>
              </Col>

              {cancellationReinsuranceEnabled && (
                <>
                  <Col span={colSpanMiddle}>
                    <Form.Item label={t("calc.travel.attrs.reinsurancesData.cancellationReinsurance.tripPrice")}>
                      {getFieldDecorator("reinsurancesData.cancellationReinsurance.tripPrice", {
                        rules: [validations.notNull, validations.minNumber(100), validations.multipleOf100]
                      })(
                        <InputNumberWithAddon addonType="euro" formatStyle="integer" min={100} step={100} />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={colSpanMiddle}>
                    <Form.Item label={t("calc.travel.enums.cancellationInsuranceCoverage._label")}>
                      {getFieldDecorator("reinsurancesData.cancellationReinsurance.coverage", { rules: [validations.none] })(
                        <Select
                          {...selectStandardProps} allowClear
                          options={Object.keys(CancellationInsuranceCoverage).map(coverage => ({
                            value: coverage,
                            label: t("calc.travel.enums.cancellationInsuranceCoverage." + coverage)
                          }))} />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={colSpanMiddle}>
                    <Form.Item label={t("calc.travel.attrs.reinsurancesData.cancellationReinsurance.firstPaymentDate")}>
                      {getFieldDecorator("reinsurancesData.cancellationReinsurance.firstPaymentDate", {
                        rules: [validations.notFuture, validations.dateInInterval(firstPaymentDateFrom, firstPaymentDateTo)]
                      })(
                        <DatePicker
                          {...datePickerClearableProps}
                          disabledDate={current => disableDatePickerOutOfInterval(current, firstPaymentDateFrom, firstPaymentDateTo)} />
                      )}
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            <Row gutter={rowGutter}>
              <Col span={colSpanMiddle}>
                <Form.Item className="form-item-without-label">
                  <Checkbox checked={luggageReinsuranceEnabled} onChange={this.handleLuggageReinsuranceEnabledChange}>
                    {t("calc.travel.sections.luggageReinsurance")}
                  </Checkbox>
                </Form.Item>
              </Col>

              {luggageReinsuranceEnabled && (
                <Col span={colSpanMiddle}>
                  <Form.Item label={t("calc.travel.enums.luggageReinsuranceCoverage._label")}>
                    {getFieldDecorator("reinsurancesData.luggageReinsuranceCoverage", { rules: [validations.notNull] })(
                      <Select
                        {...selectStandardProps}
                        options={Object.keys(LuggageReinsuranceCoverage).map(coverage => ({
                          value: coverage,
                          label: t("calc.travel.enums.luggageReinsuranceCoverage." + coverage)
                        }))} />
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={rowGutter}>
              <Col span={colSpanMiddle}>
                <Form.Item className="form-item-without-label">
                  {getFieldDecorator("reinsurancesData.rescueServiceReinsurance", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox>{t("calc.travel.attrs.reinsurancesData.rescueServiceReinsurance")}</Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={colSpanMiddle}>
                <Form.Item className="form-item-without-label">
                  {getFieldDecorator("reinsurancesData.abandonedHouseholdReinsurance", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox>{t("calc.travel.attrs.reinsurancesData.abandonedHouseholdReinsurance")}</Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={colSpanMiddle}>
                <Form.Item className="form-item-without-label">
                  {getFieldDecorator("reinsurancesData.vehicleAssistanceReinsurance", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox>{t("calc.travel.attrs.reinsurancesData.vehicleAssistanceReinsurance")}</Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col span={colSpanMiddle}>
                <Form.Item className="form-item-without-label">
                  {getFieldDecorator("reinsurancesData.petReinsurance", {
                    rules: [validations.none], valuePropName: "checked", initialValue: false
                  })(
                    <Checkbox>{t("calc.travel.attrs.reinsurancesData.petReinsurance")}</Checkbox>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default TravelCalcForm;
