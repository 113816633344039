import React from "react";
import debounce from "lodash/debounce";
import { Checkbox, Col, Divider, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { SelectValue } from "antd/lib/select";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { TravelFormClients, TravelGenForm } from "../../../types";
import { AuthorizedClientFormInputs, Client, ClientsAutocompleteProps } from "../../../../../../client/types";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import { ClientFormStage, ClientFormType, ClientType } from "../../../../../../client/enums";
import { contains } from "../../../../../../../common/utils/utils";
import validations, { validationFunctions } from "../../../../../../../common/utils/validationUtils";
import { phoneNumberNormalizeFunction } from "../../../../../../../common/utils/formUtils";
import { resolveClientIdentifier } from "../../../../../../client/utils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import ClientDrawerCreateForm from "../../../../../../client/components/drawers/ClientDrawerCreateForm";
import ClientDrawerUpdateForm from "../../../../../../client/components/drawers/ClientDrawerUpdateForm";
import AuthorizedClientsSection from "../../../../../../client/components/AuthorizedClientsSection";
import ClientAutocompleteInput from "../../../../../../client/components/ClientAutocompleteInput_Deprecated";

export interface Props {
  form: WrappedFormUtils;
  initialData: TravelGenForm;
  insurerIsAlsoInsured: boolean;
  authorizedClientsCount: number;
  clients: TravelFormClients;
  clientsAutocomplete: ClientsAutocompleteProps;
  clientsDuplicateErrors: ClientFormType[];
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  onInsurerIsAlsoInsuredChange(event: CheckboxChangeEvent): void;
  onAuthorizedClientAdd(): void;
  onAuthorizedClientDelete(): void;
  onClientChange(client: Client, type: ClientFormType, omitValidation?: boolean, callback?: () => void): void;
  onClientViolationErrorsChange(violations: FieldConstraintViolation[], type: ClientFormType): void;
  onClientDuplicateErrorChange(isDuplicate: boolean, type: ClientFormType): void;
}

interface State {
  readonly authorizedClient1InputsValue: AuthorizedClientFormInputs;
  readonly authorizedClient2InputsValue: AuthorizedClientFormInputs;
  readonly processedClientFormType: ClientFormType;
  readonly clientCreateFormVisible: boolean;
  readonly clientUpdateFormVisible: boolean;
  readonly clientsStages: ClientsStagesState;
}

interface ClientsStagesState {
  readonly insurer: ClientFormStage;
  readonly authorized1: ClientFormStage;
  readonly authorized2: ClientFormStage;
}

class TravelGenInsurerSection extends React.Component<Props, State> {

  private static readonly CLIENTS_AUTOCOMPLETE_MAX_SIZE = 7;

  constructor(props: Props) {
    super(props);

    const stateObject = {
      authorizedClient1InputsValue: { identifier: undefined, function: undefined },
      authorizedClient2InputsValue: { identifier: undefined, function: undefined },
      processedClientFormType: null,
      clientCreateFormVisible: false,
      clientUpdateFormVisible: false,
      clientsStages: { insurer: null, authorized1: null, authorized2: null }
    };

    if ( props.initialData ) {
      const { insurer, authorized1, authorized2 } = props.clients;

      if ( insurer ) {
        stateObject.clientsStages.insurer = ClientFormStage.EXISTING;
      }
      if ( authorized1 ) {
        stateObject.authorizedClient1InputsValue = {
          identifier: resolveClientIdentifier(authorized1),
          function: props.initialData.clientsData.authorizedClient1Function
        };
        stateObject.clientsStages.authorized1 = ClientFormStage.EXISTING;
      }
      if ( authorized2 ) {
        stateObject.authorizedClient2InputsValue = {
          identifier: resolveClientIdentifier(authorized2),
          function: props.initialData.clientsData.authorizedClient2Function
        };
        stateObject.clientsStages.authorized2 = ClientFormStage.EXISTING;
      }
    }

    this.state = stateObject;
  }

  resolveClientTypeByClientFormType = (type: ClientFormType): ClientType => {
    switch ( type ) {
      case ClientFormType.INSURER:
        return null;
      case ClientFormType.AUTHORIZED_1:
      case ClientFormType.AUTHORIZED_2:
        return ClientType.NATURAL;
      default:
        return null;
    }
  };

  resolveClientsAutocompleteOptions = (): DataSourceItemObject[] => {
    const allIdentifiers = this.getAllClientFormIdentifiers(this.state.processedClientFormType);
    return this.props.clientsAutocomplete.result.data
      .filter(client => !contains(allIdentifiers, client.identifier))
      .map<DataSourceItemObject>(client => ({
        value: client.identifier,
        text: client.identifier
      }));
  };

  deleteClientsAutocompleteResults = (): void => {
    if ( this.props.clientsAutocomplete.result.data.length > 0 ) {
      this.props.clientsAutocomplete.onResultDelete();
    }
  };

  handleClientsAutocompleteSearch = debounce(
    (value: string, type: ClientFormType): void => {
      if ( value && validationFunctions.validateSearchKeyword(value) ) {
        this.props.clientsAutocomplete.onSearch({
          keyword: value,
          clientType: this.resolveClientTypeByClientFormType(type),
          maxResultSize: TravelGenInsurerSection.CLIENTS_AUTOCOMPLETE_MAX_SIZE
        });
      }
      else {
        this.deleteClientsAutocompleteResults();
      }
    }, 500
  );

  handleClientsAutocompleteFocus = (type: ClientFormType): void => {
    if ( this.state.processedClientFormType !== type ) {
      this.setState({ processedClientFormType: type });
      this.deleteClientsAutocompleteResults();
    }
  };

  handleClientsAutocompleteSelect = (value: SelectValue, type: ClientFormType): void => {
    this.props.onClientChange(this.props.clientsAutocomplete.result.data.find(client => client.identifier === value), type);
    this.setClientFormStage(ClientFormStage.EXISTING, type);
    this.deleteClientsAutocompleteResults();
  };

  handleClientsAutocompleteChange = (value: SelectValue, type: ClientFormType): void => {
    if ( this.getClientFormStage(type) ) {
      this.setClientFormStage(null, type);
    }
    if ( this.getClientFromProps(type) ) {
      this.props.onClientChange(null, type);
    }
    if ( this.props.clientsViolationErrors.has(type) ) {
      this.props.onClientViolationErrorsChange(null, type);
    }
    if ( contains(this.props.clientsDuplicateErrors, type) ) {
      this.props.onClientDuplicateErrorChange(false, type);
    }
  };

  handleClientActionCreateClick = (type: ClientFormType): void => {
    this.setState({ processedClientFormType: type, clientCreateFormVisible: true });
  };

  handleClientActionUpdateClick = (type: ClientFormType): void => {
    this.setState({ processedClientFormType: type, clientUpdateFormVisible: true });
  };

  handleClientActionDeselectClick = (type: ClientFormType): void => {
    this.setClientFormIdentifier(null, type);
    this.setClientFormStage(null, type);
    this.props.onClientViolationErrorsChange(null, type);
    this.props.onClientChange(null, type);
  };

  handleClientUpdateFormSubmit = (client: Client, clientFormType: ClientFormType): void => {
    this.setState({ processedClientFormType: null, clientUpdateFormVisible: false });
    this.setClientFormStage(ClientFormStage.SELECTED, clientFormType);
    this.props.onClientViolationErrorsChange(null, clientFormType);
    this.props.onClientChange(client, clientFormType);
  };

  handleClientCreateFormSubmit = (client: Client, clientFormType: ClientFormType): void => {
    this.setState({ processedClientFormType: null, clientCreateFormVisible: false });
    this.setClientFormStage(ClientFormStage.SELECTED, clientFormType);
    this.props.onClientChange(client, clientFormType);
  };

  handleAuthorizedClientFormValueChange = (value: AuthorizedClientFormInputs, type: ClientFormType): void => {
    switch ( type ) {
      case ClientFormType.AUTHORIZED_1:
        this.setState({ authorizedClient1InputsValue: value });
        break;
      case ClientFormType.AUTHORIZED_2:
        this.setState({ authorizedClient2InputsValue: value });
        break;
    }
  };

  getClientFormIdentifier = (type: ClientFormType): string => {
    const { form } = this.props;
    switch ( type ) {
      case ClientFormType.INSURER:
        return form.getFieldValue("clientsData.insurerIdentifier");
      case ClientFormType.AUTHORIZED_1:
        return form.getFieldValue("clientsData.authorizedClient1Identifier");
      case ClientFormType.AUTHORIZED_2:
        return form.getFieldValue("clientsData.authorizedClient2Identifier");
      default:
        return null;
    }
  };

  getClientFormStage = (type: ClientFormType): ClientFormStage => {
    const { clientsStages } = this.state;
    switch ( type ) {
      case ClientFormType.INSURER:
        return clientsStages.insurer;
      case ClientFormType.AUTHORIZED_1:
        return clientsStages.authorized1;
      case ClientFormType.AUTHORIZED_2:
        return clientsStages.authorized2;
      default:
        return null;
    }
  };

  getClientFromProps = (type: ClientFormType): Client => {
    const { clients } = this.props;
    switch ( type ) {
      case ClientFormType.INSURER:
        return clients.insurer;
      case ClientFormType.AUTHORIZED_1:
        return clients.authorized1;
      case ClientFormType.AUTHORIZED_2:
        return clients.authorized2;
      default:
        return null;
    }
  };

  getAllClientFormIdentifiers = (excludedType: ClientFormType): string[] => {
    return Object.values([ClientFormType.INSURER, ClientFormType.AUTHORIZED_1, ClientFormType.AUTHORIZED_2])
      .filter(formType => formType !== excludedType)
      .map(formType => this.getClientFormIdentifier(formType))
      .filter(identifier => !!identifier)
  };

  setClientFormStage = (stage: ClientFormStage, type: ClientFormType): void => {
    switch ( type ) {
      case ClientFormType.INSURER:
        this.setState(previousState => ({ clientsStages: { ...previousState.clientsStages, insurer: stage } }));
        break;
      case ClientFormType.AUTHORIZED_1:
        this.setState(previousState => ({ clientsStages: { ...previousState.clientsStages, authorized1: stage } }));
        break;
      case ClientFormType.AUTHORIZED_2:
        this.setState(previousState => ({ clientsStages: { ...previousState.clientsStages, authorized2: stage } }));
        break;
    }
  };

  setClientFormIdentifier = (identifier: string, type: ClientFormType): void => {
    const { form } = this.props;
    switch ( type ) {
      case ClientFormType.INSURER:
        form.setFieldsValue({ "clientsData.insurerIdentifier": identifier });
        break;
      case ClientFormType.AUTHORIZED_1:
        form.setFieldsValue({ "clientsData.authorizedClient1Identifier": identifier });
        break;
      case ClientFormType.AUTHORIZED_2:
        form.setFieldsValue({ "clientsData.authorizedClient2Identifier": identifier });
        break;
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { result } = this.props.clientsAutocomplete;
    if ( result.id && result.data.length <= 1 && result.id !== prevProps.clientsAutocomplete.result.id ) {
      const { processedClientFormType } = this.state;
      const formIdentifier = this.getClientFormIdentifier(processedClientFormType);

      if ( formIdentifier && validationFunctions.validateClientIdentifier(formIdentifier,
        this.resolveClientTypeByClientFormType(processedClientFormType)) ) {
        if ( contains(this.getAllClientFormIdentifiers(processedClientFormType), formIdentifier) ) {
          this.props.onClientDuplicateErrorChange(true, processedClientFormType);
        }
        else if ( result.data.length === 0 ) {
          this.setClientFormStage(ClientFormStage.NEW, processedClientFormType);
        }
        else if ( result.data.length === 1 ) {
          const previousClient = this.getClientFromProps(processedClientFormType);
          if ( formIdentifier === result.data[0].identifier && (!previousClient || previousClient.identifier !== formIdentifier) ) {
            this.props.onClientChange(result.data[0], processedClientFormType);
            this.setClientFormStage(ClientFormStage.EXISTING, processedClientFormType);
          }
        }
      }
      else {
        this.setClientFormStage(null, processedClientFormType);
      }
    }
  }

  render(): React.ReactNode {
    const { processedClientFormType, clientsStages } = this.state;
    const { form, clientsAutocomplete } = this.props;
    const { insurer, authorized1, authorized2 } = this.props.clients;
    const { getFieldDecorator } = form;

    const clientAutocompleteCommonProps = {
      form,
      processedClientFormType,
      autocompleteInProgress: clientsAutocomplete.inProgress,
      options: this.resolveClientsAutocompleteOptions(),
      clientsViolationErrors: this.props.clientsViolationErrors,
      clientsDuplicateErrors: this.props.clientsDuplicateErrors,
      onClientActionCreateClick: this.handleClientActionCreateClick,
      onClientActionUpdateClick: this.handleClientActionUpdateClick,
      onClientActionDeselectClick: this.handleClientActionDeselectClick,
      onClientsAutocompleteFocus: this.handleClientsAutocompleteFocus,
      onClientsAutocompleteSearch: this.handleClientsAutocompleteSearch,
      onClientsAutocompleteSelect: this.handleClientsAutocompleteSelect,
      onClientsAutocompleteChange: this.handleClientsAutocompleteChange
    };

    const colSpan = 8;

    return (
      <>
        <Row gutter={rowGutter}>
          <Divider>{t("calc.travel.sections.insurer")}</Divider>

          <ClientAutocompleteInput
            formKey="clientsData.insurerIdentifier"
            formType={ClientFormType.INSURER}
            formStage={clientsStages.insurer}
            formInputOptions={{ rules: [validations.notBlank, validations.pinOrCrn] }}
            label={t("calc.travel.attrs.clientsData.insurerPinCrn")}
            client={insurer}
            {...clientAutocompleteCommonProps} />

          <Col span={colSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("clientsData.insurerIsAlsoInsured", {
                rules: [validations.none], valuePropName: "checked", initialValue: this.props.insurerIsAlsoInsured
              })(
                <Checkbox
                  disabled={!insurer || insurer.type !== ClientType.NATURAL}
                  onChange={this.props.onInsurerIsAlsoInsuredChange}>
                  {t("calc.travel.attrs.clientsData.insurerIsAlsoInsured")}
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.travel.attrs.clientsData.insurerEmail")}>
              {getFieldDecorator("clientsData.insurerEmail", {
                rules: [validations.notBlank, validations.size(1, 254), validations.email]
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.travel.attrs.clientsData.insurerPhone")}>
              {getFieldDecorator("clientsData.insurerPhone", {
                rules: [validations.notBlank, validations.size(1, 255), validations.mobilePhoneNumber],
                normalize: phoneNumberNormalizeFunction
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
        </Row>

        <AuthorizedClientsSection
          visible={insurer && insurer.type === ClientType.LEGAL}
          clientsCount={this.props.authorizedClientsCount}
          client1Props={{
            formStage: clientsStages.authorized1,
            formValue: this.state.authorizedClient1InputsValue,
            client: authorized1
          }}
          client2Props={{
            formStage: clientsStages.authorized2,
            formValue: this.state.authorizedClient2InputsValue,
            client: authorized2
          }}
          autocompleteProps={clientAutocompleteCommonProps}
          onValueChange={this.handleAuthorizedClientFormValueChange}
          onClientAdd={this.props.onAuthorizedClientAdd}
          onClientDelete={this.props.onAuthorizedClientDelete}
          onClientChange={this.props.onClientChange}
          onClientViolationErrorsChange={this.props.onClientViolationErrorsChange}
          onClientDuplicateErrorChange={this.props.onClientDuplicateErrorChange}
          onClientFormStageChange={this.setClientFormStage} />

        <ClientDrawerUpdateForm
          visible={this.state.clientUpdateFormVisible}
          client={this.getClientFromProps(processedClientFormType)}
          formType={processedClientFormType}
          violationErrors={this.props.clientsViolationErrors}
          onFormSubmit={this.handleClientUpdateFormSubmit} />

        <ClientDrawerCreateForm
          visible={this.state.clientCreateFormVisible}
          initialClientType={this.resolveClientTypeByClientFormType(processedClientFormType)}
          initialIdentifier={this.getClientFormIdentifier(processedClientFormType)}
          formType={processedClientFormType}
          onFormSubmit={this.handleClientCreateFormSubmit} />
      </>
    );
  }
}

export default TravelGenInsurerSection;
