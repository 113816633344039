import { combineReducers } from "redux";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { push } from "connected-react-router";
import { AxiosResponse } from "axios";
import moment from "moment";
import api from "./api";
import { AuthAccount, AuthData, AuthReducerState, LoginRequest } from "./types";
import { ActionCreator, RootState } from "../../common/types";
import { Permission } from "../../common/security/authorization/enums";
import { getEnumerationsActions } from "../enumerations/ducks";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../common/utils/reduxUtils";
import { hasPermission } from "../../common/utils/utils";

/**
 * ACTION TYPES
 */
export enum actionType {
  LOGIN = 'auth/LOGIN',
  LOGOUT = 'auth/LOGOUT',
  CHECK_AUTH_STATE = 'auth/CHECK_AUTH_STATE',
  REFRESH_TOKEN = 'auth/REFRESH_TOKEN',
  CLEAR_AUTH_DATA = 'auth/CLEAR_AUTH_DATA'
}

/**
 * ACTIONS
 */
export const loginActions = createApiActionCreators<LoginRequest, AuthData>(actionType.LOGIN);
export const logoutAction = createActionCreator<void>(actionType.LOGOUT);
export const checkAuthStateAction = createActionCreator<void>(actionType.CHECK_AUTH_STATE);
export const refreshTokenActions = createApiActionCreators<void, AuthData>(actionType.REFRESH_TOKEN);
export const clearAuthDataAction = createActionCreator<void>(actionType.CLEAR_AUTH_DATA);

/**
 * REDUCERS
 */
const initialState: AuthReducerState = {
  authData: {
    token: null,
    tokenValidUntil: null,
    account: null
  }
};

const authDataReducer = createReducer<AuthData>(initialState.authData, {
  [actionType.LOGIN]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.authData
  },
  [actionType.LOGOUT]: () => initialState.authData,
  [actionType.REFRESH_TOKEN]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.authData
  },
  [actionType.CLEAR_AUTH_DATA]: () => initialState.authData
});

export default combineReducers({ authData: authDataReducer });

/**
 * SELECTORS
 */
const selectAuth = (state: RootState): AuthReducerState => state.auth;
const selectAuthData = (state: RootState): AuthData => selectAuth(state).authData;

export const selectToken = (state: RootState): string => selectAuthData(state).token;
export const selectAccount = (state: RootState): AuthAccount => selectAuthData(state).account;
export const selectPermissions = (state: RootState): Permission[] => selectAccount(state)?.permissions || [];

export const selectIsUserAuthenticated = createSelector<RootState, AuthData, boolean>(selectAuthData, authData => isUserAuthenticated(authData));

export const selectHasPermissions = (...checkedPermissions: Permission[]) =>
  createSelector<RootState, Permission[], Permission[]>(selectPermissions,
    accountPermissions => checkedPermissions.filter(checkedPermission => hasPermission(accountPermissions, checkedPermission)));

/**
 * SAGAS
 */
// NOTE: yield operator in generic functions has ANY return type (https://github.com/Microsoft/TypeScript/issues/26959),
// so we explicitly add type after yield call
function* login({ payload }: ActionCreator<LoginRequest>) {
  try {
    const response: AxiosResponse<AuthData> = yield call(api.login, payload);
    yield put(loginActions.success(response.data));
    yield put(checkAuthStateAction());
    yield put(getEnumerationsActions.request());
  }
  catch ( error ) {
    yield put(loginActions.failure(error));
  }
}

function* logout() {
  yield put(push("/auth"));
  // we call checkAuthStateAction again and by using takeLatest effect we cancel previously timed refresh token action
  yield put(checkAuthStateAction());
}

function* checkAuthState() {
  const authData: AuthData = yield select(selectAuthData);

  if ( !isUserAuthenticated(authData) ) {
    yield put(clearAuthDataAction());
  }
  else {
    const validityTime = moment(authData.tokenValidUntil).subtract(10, "minute");

    if ( moment().isAfter(validityTime) ) {
      yield put(refreshTokenActions.request());
    }
    else {
      yield delay(validityTime.valueOf() - moment().valueOf());
      yield put(refreshTokenActions.request());
    }
  }
}

function* refreshToken() {
  try {
    const response: AxiosResponse<AuthData> = yield call(api.refreshToken);
    yield put(refreshTokenActions.success(response.data));
    yield put(checkAuthStateAction());
  }
  catch ( error ) {
    yield put(refreshTokenActions.failure(error));
  }
}

export function* authSaga() {
  yield takeLatest(createActionType(actionType.LOGIN, apiOperation.REQUEST), login);
  yield takeLatest(actionType.LOGOUT, logout);
  yield takeLatest(actionType.CHECK_AUTH_STATE, checkAuthState);
  yield takeLatest(createActionType(actionType.REFRESH_TOKEN, apiOperation.REQUEST), refreshToken);
}

/**
 * HELPER FUNCTIONS
 */
const isUserAuthenticated = (authData: AuthData): boolean => {
  return authData.token && new Date(authData.tokenValidUntil) > new Date();
};
