import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Col, Row } from "antd";
import { ActionProps, RootState } from "../../../../common/types";
import { JobSettings } from "../types";
import {
  abortJobActions,
  cancelJobActions,
  deleteStateJobItemsAction,
  filterJobRecordsActions,
  getAllJobsActions,
  scheduleJobActions,
  selectJobs,
  updateJobActions
} from "../ducks";

import JobSettingsView from "../components/views/JobSettingsView";

interface StateProps {
  jobs: JobSettings[]
}

interface ActionsMap {
  getAllJobs: typeof getAllJobsActions.request;
  updateJob: typeof updateJobActions.request;
  scheduleJob: typeof scheduleJobActions.request;
  filterJobRecords: typeof filterJobRecordsActions.request;
  cancelJob: typeof cancelJobActions.request;
  abortJob: typeof abortJobActions.request;
  deleteStateJobItems: typeof deleteStateJobItemsAction;
}

const JobsContainer = (props: StateProps & ActionProps<ActionsMap>) => {

  useEffect(() => {
    props.actions.getAllJobs();
    return () => props.actions.deleteStateJobItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row justify="center">
      <Col span={24} style={{ maxWidth: "1100px" }}>
        <JobSettingsView
          jobs={props.jobs}
          onAllJobsGet={props.actions.getAllJobs}
          onUpdate={props.actions.updateJob}
          onSchedule={props.actions.scheduleJob}
          onFilter={props.actions.filterJobRecords}
          onCancel={props.actions.cancelJob}
          onAbort={props.actions.abortJob} />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  jobs: selectJobs(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({
    getAllJobs: getAllJobsActions.request,
    updateJob: updateJobActions.request,
    scheduleJob: scheduleJobActions.request,
    filterJobRecords: filterJobRecordsActions.request,
    cancelJob: cancelJobActions.request,
    abortJob: abortJobActions.request,
    deleteStateJobItems: deleteStateJobItemsAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(JobsContainer);
