import React from "react";
import { Col, Input, Modal, Row } from "antd";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import validations from "../../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

export interface Props extends FormComponentProps<FormComponentObject> {
  visibleAsOffer: boolean;
  visibleAsDraft: boolean;
  initialName: string;
  onOkClick(clientName: string): void;
  onCancelClick();
}

export interface FormComponentObject {
  insuredClientName: string;
}

class RealtyClientNameModal extends React.Component<Props> {

  handleModalOkClick = (): void => {
    this.props.form.validateFields((errors, values) => {
      if ( !errors ) {
        this.props.onOkClick(values.insuredClientName);
      }
    })
  };

  render(): React.ReactNode {
    const { visibleAsOffer, visibleAsDraft, form } = this.props;
    const colSpan = 24;

    return (
      <Modal
        title={visibleAsOffer ? t("calc.realty.titles.offerCreate") : t("calc.realty.titles.draftCreate")}
        visible={visibleAsOffer || visibleAsDraft}
        okText={visibleAsOffer ? t("calc.realty.actions.generateOffer") : t("calc.realty.actions.saveDraft")}
        cancelText={t("common.close")}
        centered
        destroyOnClose
        onOk={this.handleModalOkClick}
        onCancel={this.props.onCancelClick}>

        <Form layout="vertical">

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item label={t("calc.realty.attrs.clientsData.insuredClientName")}>
                {form.getFieldDecorator("insuredClientName", {
                  rules: [validations.notBlank, validations.max(255)], initialValue: this.props.initialName
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>

      </Modal>
    );
  }
}

export default Form.create<Props>()(RealtyClientNameModal);
