import React from "react";
import { Col, Divider, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { MyIdentityPerson } from "../types";
import { LegalPerson, NaturalPerson, SelfEmployedPerson } from "../../person/types";
import { PersonType } from "../../person/enums";
import t from "../../../app/i18n";

import NaturalPersonDataView from "../../person/components/views/detail/NaturalPersonDataView";
import SelfEmployedPersonDataView from "../../person/components/views/detail/SelfEmployedPersonDataView";
import LegalPersonDataView from "../../person/components/views/detail/LegalPersonDataView";
import AddressView from "../../../common/modules/address/AddressView";
import ContactsView from "../../../common/modules/contact/ContactsView";
import PersonActiveTag from "../../person/components/PersonDeactivationTag";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";
import CopyToClipboardView from "../../../common/components/views/CopyToClipboardView";

export interface Props {
  person: MyIdentityPerson;
}

const MyIdentityPersonView = ({ person }: Props) => {

  const layout: ColProps = { span: 24, style: { maxWidth: "900px" } };

  let dataView;
  switch ( person.type ) {
    case PersonType.NATURAL:
      dataView = <NaturalPersonDataView person={person as NaturalPerson} />;
      break;
    case PersonType.SELF_EMPLOYED:
      dataView = <SelfEmployedPersonDataView person={person as SelfEmployedPerson} />;
      break;
    case PersonType.LEGAL:
      dataView = <LegalPersonDataView person={person as LegalPerson} />;
      break;
  }

  return (
    <>
      <ItemCreatedUpdatedInfoView item={person} className="margin-bottom-tiny" />

      <Divider orientation="left">{t("person.titles.basicData")}</Divider>

      <Row>
        <Col {...layout}>
          <table className="data-table-view">
            <tbody>
            <tr>
              <td colSpan={4}><PersonActiveTag personDeactivated={person.deactivated} /></td>
            </tr>
            <tr>
              <td>{t("person.attrs.nbsRegistrationNumber")}:</td>
              <td colSpan={3}><CopyToClipboardView content={person.nbsRegistrationNumber} /></td>
            </tr>
            {dataView}
            </tbody>
          </table>
        </Col>
      </Row>

      <Divider orientation="left">{t("person.titles.addresses")}</Divider>

      <Row>
        <Col {...layout}>
          <table className="data-table-view">
            <tbody>
            <tr>
              <td>{person.type === PersonType.LEGAL ? t("person.attrs.addressLongPO") : t("person.attrs.addressLong")}:</td>
              <td><AddressView address={person.address} showCountry /></td>
            </tr>
            <tr>
              <td>{t("person.attrs.serviceAddress")}:</td>
              <td><AddressView address={person.serviceAddress} showCountry /></td>
            </tr>
            <tr>
              <td>{t("person.attrs.correspondenceAddress")}:</td>
              <td><AddressView address={person.correspondenceAddress} showCountry /></td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <Divider orientation="left">{t("person.titles.contacts")}</Divider>

      <Row>
        <Col {...layout}>
          <ContactsView contacts={person.contacts} showUseForNotifications />
        </Col>
      </Row>
    </>
  )
};

export default MyIdentityPersonView;
