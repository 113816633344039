export enum ErrorResponseType {
  GENERAL = 'GENERAL',
  VALIDATION = 'VALIDATION',
  OPTIMISTIC_LOCK = 'OPTIMISTIC_LOCK',
  EXTERNAL_WEBSERVICE = 'EXTERNAL_WEBSERVICE'
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}