import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { CalcRecord, CalcRecordFilterPageRequest, CalcRecordFilterPageResult } from "./types";
import { EntityIdRequest } from "../../../common/types";

const FILTER_CALC_RECORDS = ApiRequestAdapter.create("/calculators/records", "GET");
const GET_CALC_RECORD_REQUEST_RESPONSE = ApiRequestAdapter.create("/calculators/records/{0}/request-response", "GET");
const GET_CALC_RECORD_WEBSERVICES_LOGS = ApiRequestAdapter.create("/calculators/records/{0}/webservices-logs", "GET");
const GET_CALC_RECORD_STACK_TRACES_LOGS = ApiRequestAdapter.create("/calculators/records/{0}/stack-traces-logs", "GET");

export const requests = {
  FILTER_CALC_RECORDS,
  GET_CALC_RECORD_REQUEST_RESPONSE,
  GET_CALC_RECORD_WEBSERVICES_LOGS,
  GET_CALC_RECORD_STACK_TRACES_LOGS
};

export default {
  filterCalcRecords: (request: CalcRecordFilterPageRequest): AxiosPromise<CalcRecordFilterPageResult> => {
    return apiService.get<CalcRecordFilterPageResult>(FILTER_CALC_RECORDS.url, { params: request });
  },
  getCalcRecordRequestResponse: (request: EntityIdRequest): AxiosPromise<CalcRecord> => {
    return apiService.get<CalcRecord>(GET_CALC_RECORD_REQUEST_RESPONSE.getParametrizedUrl(request.id));
  },
  getCalcRecordWebservicesLogs: (request: EntityIdRequest): AxiosPromise<CalcRecord> => {
    return apiService.get<CalcRecord>(GET_CALC_RECORD_WEBSERVICES_LOGS.getParametrizedUrl(request.id));
  },
  getCalcRecordStackTracesLogs: (request: EntityIdRequest): AxiosPromise<CalcRecord> => {
    return apiService.get<CalcRecord>(GET_CALC_RECORD_STACK_TRACES_LOGS.getParametrizedUrl(request.id));
  }
}
