import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { ConfirmPersonAccount } from "../../types";
import { Role } from "../../../../common/security/authorization/enums";
import { useFormErrorHandler } from "../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import t from "../../../../app/i18n";

export interface Props {
  confirmationDataParams: ConfirmationDataParamsProps;
  onFormSubmit(personAccount: ConfirmPersonAccount): void;
}

export interface ConfirmationDataParamsProps {
  type: Role;
  optimisticLockVersion: number;
  token: string;
}

const ConfirmPersonAccountForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "account.attrs", requests.CONFIRM_ACCOUNT);

  const handleFormFinish = (values: ConfirmPersonAccount | any): void => {
    props.onFormSubmit(Object.assign({}, values, props.confirmationDataParams));
  };

  return (
    <>
      <h2 className="center-align margin-bottom-large">{t("account.titles.confirm")}</h2>

      <Row justify="center">
        <Col span={24} style={{ maxWidth: "400px" }}>

          <Form form={form} layout="vertical" name="confirmPersonAccountForm" onFinish={handleFormFinish}>

            <Form.Item
              name="username"
              label={t("account.attrs.username")}
              rules={[validations.notBlank, validations.size(1, 70), validations.pattern(regexPatterns.usernameRegex)]}>
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label={t("account.attrs.password")}
              rules={validations.sufficientPassword}>
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="passwordRepeat"
              label={t("account.attrs.passwordRepeat")}
              rules={[...validations.sufficientPassword, validations.repeatedPassword(form)]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button className="margin-top-medium" type="primary" htmlType="submit" block>
                {t("account.actions.register")}
              </Button>
            </Form.Item>

          </Form>

        </Col>
      </Row>
    </>
  );
}

export default ConfirmPersonAccountForm;
