import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import {
  CrashCalcResultData,
  GapCalcResultData,
  GapInsuranceOption,
  MtplCalcResultData,
  PasCalcResultData,
  VehicleCalc,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleFormClients,
  VehicleGen,
  VehicleGenFormData
} from "./types";
import { CalcResponse, CalcResult } from "../types";
import { GapDuration, TimeWithoutAccident, VehicleInsurerRelation } from "./enums";
import { CalcType } from "../../enums";
import { ClientType } from "../../../client/enums";
import { InsuranceContractPaymentFrequency, VehicleCategory } from "../../../contract/enums";
import {
  sortResultGroupsByAnnualPremium,
  sortResultGroupsByInstitutionName,
  sortResultsWithinGroupsByCoverageIndex
} from "../utils";
import { contains, isNotEmptyArray } from "../../../../common/utils/utils";
import { toMoment } from "../../../../common/utils/formUtils";
import { createUpdateContractClientToClient } from "../../../client/utils";

export const resolveVehicleInsurerType = (clients: VehicleFormClients, insurerRelation: VehicleInsurerRelation): ClientType => {
  return insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER
    ? clients.insurer ? clients.insurer.type : null
    : clients.holder ? clients.holder.type : null;
};

export const sortAndGroupVehicleCalcResults = (calcResponse: CalcResponse<VehicleCalcResultData>): VehicleCalcResults => {
  return {
    mtpl: sortResults<MtplCalcResultData>(calcResponse.results.filter(result => result.calcType === CalcType.MTPL) as CalcResult<MtplCalcResultData>[]),
    crash: sortResults<CrashCalcResultData>(calcResponse.results.filter(result => result.calcType === CalcType.CRASH) as CalcResult<CrashCalcResultData>[]),
    gap: sortResults<GapCalcResultData>(calcResponse.results.filter(result => result.calcType === CalcType.GAP) as CalcResult<GapCalcResultData>[]),
    pas: sortResults<PasCalcResultData>(calcResponse.results.filter(result => result.calcType === CalcType.PAS) as CalcResult<PasCalcResultData>[])
  };
};

const sortResults = <T extends VehicleCalcResultData>(results: CalcResult<T>[]): CalcResult<T>[][] => {
  const resultGroups: CalcResult<T>[][] = [];

  cloneDeep(results).forEach(result => {
    const index = resultGroups.findIndex(items => items[0].insuranceInstitution.id === result.insuranceInstitution.id);
    if ( index === -1 ) {
      resultGroups.push([result as CalcResult<T>]);
    }
    else {
      resultGroups[index].push(result as CalcResult<T>);
    }
  });

  const successResultGroups: CalcResult<T>[][] = [];
  const notRecommendedGroups: CalcResult<T>[][] = [];
  const errorResultGroups: CalcResult<T>[][] = [];

  resultGroups.forEach(results => {
    if ( results.some(result => result.error) ) {
      errorResultGroups.push(results);
    }
    else if ( results.some(result => result.data.missingCoverages && result.data.missingCoverages.length > 0) ) {
      notRecommendedGroups.push(results);
    }
    else {
      successResultGroups.push(results);
    }
  });

  sortResultsWithinGroupsByCoverageIndex<T>(successResultGroups);
  sortResultsWithinGroupsByCoverageIndex<T>(notRecommendedGroups);
  sortResultsWithinGroupsByCoverageIndex<T>(errorResultGroups);
  sortResultGroupsByAnnualPremium<T>(successResultGroups);
  sortResultGroupsByAnnualPremium<T>(notRecommendedGroups);
  sortResultGroupsByInstitutionName<T>(errorResultGroups);

  return [...successResultGroups, ...notRecommendedGroups, ...errorResultGroups];
};

export const hasAnyVehicleResult = (results: VehicleCalcResults): boolean => {
  return isNotEmptyArray(results.mtpl) || isNotEmptyArray(results.crash) || isNotEmptyArray(results.gap) || isNotEmptyArray(results.pas);
}

export const findGapResultPremium = (options: GapInsuranceOption[],
                                     complicityReinsurance: boolean,
                                     duration: GapDuration,
                                     paymentFrequency: InsuranceContractPaymentFrequency): number => {
  if ( isNotEmptyArray(options) ) {
    const option = options.find(option => option.complicityReinsurance === complicityReinsurance
      && option.duration === duration && option.paymentFrequency === paymentFrequency);
    return option ? option.premium : null;
  }
  return null;
}

export const createVehicleCalcObjectFromCalcData = (calcData: VehicleCalc): VehicleCalc => {
  const data = cloneDeep(calcData);

  set(data, "vehicleData.firstRegistrationDate", toMoment(data.vehicleData.firstRegistrationDate));
  set(data, "generalData.effectiveBeginningDate", toMoment(data.generalData.effectiveBeginningDate));

  [2, 3, 4, 5, 8].forEach(years => {
    if ( !contains(YEARS_TO_ACCIDENT_TIMES_MAP.get(years), data.clientsData.holderTimeWithoutAccident) ) {
      delete data.clientsData[`holderAccidentsIn${years}Years`];
      if ( years === 2 ) {
        delete data.clientsData.holderHasOpenInsuranceEvent;
      }
    }
  });

  return data;
};

export const createVehicleFormClientsObjectFromCalcData = (calcData: VehicleCalc): VehicleFormClients => ({
  holder: createUpdateContractClientToClient(calcData.clientsData.clients[calcData.clientsData.holderIndex]),
  insurer: null,
  owner: null,
  authorized1: null,
  authorized2: null
});

export const createVehicleFormClientsObjectFromGenData = (genData: VehicleGen): VehicleFormClients => {
  const { clients, holderIndex, insurerIndex, ownerIndex, authorizedClient1Index, authorizedClient2Index } = genData.clientsData;
  return {
    holder: createUpdateContractClientToClient(clients[holderIndex]),
    insurer: insurerIndex !== holderIndex ? createUpdateContractClientToClient(clients[insurerIndex]) : null,
    owner: ownerIndex !== holderIndex && ownerIndex !== insurerIndex ? createUpdateContractClientToClient(clients[ownerIndex]) : null,
    authorized1: createUpdateContractClientToClient(clients[authorizedClient1Index]),
    authorized2: createUpdateContractClientToClient(clients[authorizedClient2Index])
  };
};

export const createVehicleCalcObjectFromGenData = (genData: VehicleGen): VehicleCalc => {
  const data = cloneDeep(genData);

  data.type = data.type === CalcType.GAP || data.type === CalcType.PAS ? CalcType.MTPL_CRASH : data.type;

  delete data.insuranceInstitutionId;
  delete data.coverage;
  delete data.calcResult;
  delete data.calcResponse;
  delete data.draftId;

  delete data.vehicleData.registrationCertificateNumber;
  delete data.vehicleData.colorId;
  delete data.vehicleData.damaged;
  delete data.vehicleData.damageDescription;
  delete data.vehicleData.standardKeys;
  delete data.vehicleData.standardWheels;
  delete data.vehicleData.nonStandardWheelsDesc;
  delete data.vehicleData.priceSource;
  delete data.vehicleData.key;
  delete data.vehicleData.equipment;
  delete data.vehicleData.purchaseDate;
  if ( data.vehicleData.security ) {
    data.vehicleData.security.forEach(security => delete security.numberOfKeysOrControllers);
  }

  delete data.clientsData.insurerIndex;
  delete data.clientsData.ownerIndex;
  delete data.clientsData.authorizedClient1Index;
  delete data.clientsData.authorizedClient2Index;
  delete data.clientsData.insurerEmail;
  delete data.clientsData.insurerPhone;
  delete data.clientsData.authorizedClient1Function;
  delete data.clientsData.authorizedClient2Function;
  delete data.clientsData.holderChildrenUnder15YearsName;
  delete data.clientsData.holderChildrenUnder15YearsBirthDate;
  delete data.clientsData.insurerPolicePenaltyInLast3Years;
  delete data.clientsData.insurerContractCancelledInLast3Years;

  delete data.generalData.paymentMethod;
  delete data.generalData.signCity;
  delete data.generalData.mtplInsuranceEffectiveBeginningDate;
  delete data.generalData.otherMtplInsurance;
  delete data.generalData.gapComplicityReinsurance;
  delete data.generalData.gapDuration;
  delete data.generalData.gapPaymentFrequency;
  delete data.generalData.crossSelling.groupamaContractNumber;
  delete data.generalData.crossSelling.unionHealthContractNumber;
  delete data.generalData.crossSelling.wustenrotContractNumbers;
  delete data.informationObligationData;

  return createVehicleCalcObjectFromCalcData(data);
};

export const createVehicleGenFormDataObject = (genData: VehicleGen, clients: VehicleFormClients): VehicleGenFormData => {
  const { vehicleData, clientsData, generalData, informationObligationData } = genData;
  return {
    formData: {
      vehicleData: {
        registrationCertificateNumber: vehicleData.registrationCertificateNumber,
        colorId: vehicleData.colorId,
        damaged: !!(vehicleData.damaged),
        damageDescription: vehicleData.damageDescription,
        standardKeys: !!(vehicleData.standardKeys),
        standardWheels: !!(vehicleData.standardWheels),
        nonStandardWheelsDesc: vehicleData.nonStandardWheelsDesc,
        priceSource: vehicleData.priceSource,
        key: vehicleData.key,
        equipment: vehicleData.equipment,
        security: vehicleData.security,
        odometer: vehicleData.odometer,
        purchaseDate: toMoment(vehicleData.purchaseDate)
      },
      clientsData: {
        insurerIdentifier: clients.insurer ? clients.insurer.identifier : null,
        ownerIdentifier: clients.owner ? clients.owner.identifier : null,
        authorizedClient1Identifier: clients.authorized1 ? clients.authorized1.identifier : null,
        authorizedClient2Identifier: clients.authorized2 ? clients.authorized2.identifier : null,
        insurerEmail: clientsData.insurerEmail,
        insurerPhone: clientsData.insurerPhone,
        authorizedClient1Function: clientsData.authorizedClient1Function,
        authorizedClient2Function: clientsData.authorizedClient2Function,
        holderChildrenUnder15YearsName: clientsData.holderChildrenUnder15YearsName,
        holderChildrenUnder15YearsBirthDate: toMoment(clientsData.holderChildrenUnder15YearsBirthDate),
        insurerPolicePenaltyInLast3Years: clientsData.insurerPolicePenaltyInLast3Years,
        insurerContractCancelledInLast3Years: clientsData.insurerContractCancelledInLast3Years
      },
      generalData: {
        paymentMethod: generalData.paymentMethod,
        signCity: generalData.signCity,
        mtplInsuranceEffectiveBeginningDate: toMoment(generalData.mtplInsuranceEffectiveBeginningDate),
        otherMtplInsurance: !!(generalData.otherMtplInsurance),
        gapComplicityReinsurance: generalData.gapComplicityReinsurance,
        gapDuration: generalData.gapDuration,
        gapPaymentFrequency: generalData.gapPaymentFrequency,
        crossSelling: {
          groupamaContractNumber: generalData.crossSelling.groupamaContract ? generalData.crossSelling.groupamaContractNumber : null,
          unionHealthContractNumber: generalData.crossSelling.unionHealthContract ? generalData.crossSelling.unionHealthContractNumber : null,
          wustenrotContractNumbers: generalData.crossSelling.wustenrotContractsCount ? generalData.crossSelling.wustenrotContractNumbers : null
        }
      },
      informationObligationData
    },
    authorizedClientsCount: clients.authorized1 && clients.authorized2 ? 2 : 1
  };
};

export const CALC_OMITTED_CATEGORIES = [
  VehicleCategory.MOTOR_SLEDGE_LS, VehicleCategory.TRACTOR_C, VehicleCategory.MACHINE_P, VehicleCategory.BUS_CITY_M
];

export const YEARS_TO_ACCIDENT_TIMES_MAP = new Map<number, TimeWithoutAccident[]>([
  [2, [TimeWithoutAccident.YEAR_OR_LESS, TimeWithoutAccident.AT_LEAST_1_YEAR]],
  [3, [TimeWithoutAccident.YEAR_OR_LESS, TimeWithoutAccident.AT_LEAST_1_YEAR, TimeWithoutAccident.AT_LEAST_2_YEARS]],
  [4, [TimeWithoutAccident.YEAR_OR_LESS, TimeWithoutAccident.AT_LEAST_1_YEAR, TimeWithoutAccident.AT_LEAST_2_YEARS, TimeWithoutAccident.AT_LEAST_3_YEARS]],
  [5, [TimeWithoutAccident.YEAR_OR_LESS, TimeWithoutAccident.AT_LEAST_1_YEAR, TimeWithoutAccident.AT_LEAST_2_YEARS, TimeWithoutAccident.AT_LEAST_3_YEARS,
    TimeWithoutAccident.AT_LEAST_4_YEARS]],
  [8, [TimeWithoutAccident.YEAR_OR_LESS, TimeWithoutAccident.AT_LEAST_1_YEAR, TimeWithoutAccident.AT_LEAST_2_YEARS, TimeWithoutAccident.AT_LEAST_3_YEARS,
    TimeWithoutAccident.AT_LEAST_4_YEARS, TimeWithoutAccident.AT_LEAST_5_YEARS, TimeWithoutAccident.AT_LEAST_6_YEARS, TimeWithoutAccident.AT_LEAST_7_YEARS]],
]);

export const M1_N1 = [
  VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1
];

export const SELF_MOVING = [
  VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.MOTORCYCLE_L,
  VehicleCategory.TRICYCLE_L, VehicleCategory.QUAD_BIKE_L, VehicleCategory.TRACTOR_T,
  VehicleCategory.TRACTOR_C, VehicleCategory.MACHINE_P, VehicleCategory.FORKLIFT_P,
  VehicleCategory.LORRY_N, VehicleCategory.SEMI_N3, VehicleCategory.BUS_CITY_M,
  VehicleCategory.BUS_OTHER_M, VehicleCategory.RV
];

export const SPECS_MAP = new Map<string, VehicleCategory[]>([
  [
    "fuelType", SELF_MOVING
  ],
  [
    "transmission", M1_N1
  ],
  [
    "bodywork", M1_N1
  ],
  [
    "seatsNumber", SELF_MOVING
  ],
  [
    "doorsNumber",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.BUS_CITY_M, VehicleCategory.BUS_OTHER_M]
  ],
  [
    "engineDisplacement", SELF_MOVING
  ],
  [
    "enginePower", SELF_MOVING
  ],
  [
    "odometer", SELF_MOVING
  ],
  [
    "steeringWheelOnTheRight",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.TRACTOR_T,
      VehicleCategory.TRACTOR_C, VehicleCategory.MACHINE_P, VehicleCategory.FORKLIFT_P,
      VehicleCategory.LORRY_N, VehicleCategory.SEMI_N3, VehicleCategory.BUS_CITY_M,
      VehicleCategory.BUS_OTHER_M, VehicleCategory.RV]
  ]
]);

export const REINSURANCES_MAP = new Map<string, VehicleCategory[]>([
  [
    "extendedAssistance",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L, VehicleCategory.QUAD_BIKE_L]
  ],
  [
    "glass",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.TRACTOR_T,
      VehicleCategory.TRACTOR_C, VehicleCategory.MACHINE_P, VehicleCategory.FORKLIFT_P,
      VehicleCategory.LORRY_N, VehicleCategory.SEMI_N3, VehicleCategory.BUS_CITY_M,
      VehicleCategory.BUS_OTHER_M, VehicleCategory.RV]
  ],
  [
    "animal", M1_N1
  ],
  [
    "element",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L, VehicleCategory.QUAD_BIKE_L]
  ],
  [
    "theftAndVandalism", M1_N1
  ],
  [
    "injury",
    [VehicleCategory.PERSONAL_M1, VehicleCategory.UTILITY_N1, VehicleCategory.MOTORCYCLE_L,
      VehicleCategory.TRICYCLE_L, VehicleCategory.QUAD_BIKE_L]
  ],
  [
    "gap", M1_N1
  ],
  [
    "replacementVehicle", M1_N1
  ],
  [
    "generaliAbroadVehicleRepair", M1_N1
  ]
]);
