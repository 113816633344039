import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { EntityIdRequest, EntityObjectRequest, SearchPageRequest, SearchPageResult } from "../../../common/types";
import { CreateUpdateInstitution, Institution, InstitutionList } from "./types";

const FILTER_INSTITUTIONS = ApiRequestAdapter.create("/institutions", "GET");
const GET_INSTITUTION = ApiRequestAdapter.create("/institutions/{0}", "GET");
const CREATE_INSTITUTION = ApiRequestAdapter.create("/institutions", "POST");
const UPDATE_INSTITUTION = ApiRequestAdapter.create("/institutions/{0}", "PUT");
const DELETE_INSTITUTION = ApiRequestAdapter.create("/institutions/{0}", "DELETE");

export const requests = {
  FILTER_INSTITUTIONS,
  GET_INSTITUTION,
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
  DELETE_INSTITUTION
};

export default {
  filterInstitutions: (request: SearchPageRequest): AxiosPromise<SearchPageResult<InstitutionList>> => {
    return apiService.get<SearchPageResult<InstitutionList>>(FILTER_INSTITUTIONS.url, { params: request });
  },
  getInstitution: (request: EntityIdRequest): AxiosPromise<Institution> => {
    return apiService.get<Institution>(GET_INSTITUTION.getParametrizedUrl(request.id));
  },
  createInstitution: (request: CreateUpdateInstitution): AxiosPromise<Institution> => {
    return apiService.post<Institution>(CREATE_INSTITUTION.url, request);
  },
  updateInstitution: (request: EntityObjectRequest<CreateUpdateInstitution>): AxiosPromise<Institution> => {
    return apiService.put<Institution>(UPDATE_INSTITUTION.getParametrizedUrl(request.id), request.object);
  },
  deleteInstitution: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_INSTITUTION.getParametrizedUrl(request.id));
  }
}
