import React from "react";
import { Col, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { InsuranceContract } from "../../../../types";
import {
  deleteContractActions,
  deleteContractAttachmentActions,
  uploadContractAttachmentsActions,
  verifyContractActions
} from "../../../../ducks";

import InsuranceContractHeaderSection from "./sections/InsuranceContractHeaderSection";
import InsuranceContractInsurancesSection from "./sections/InsuranceContractInsurancesSection";
import InsuranceContractDataSection from "./sections/InsuranceContractDataSection";
import ContractAttachmentsSection from "../ContractAttachmentsSection";

export interface Props {
  contract: InsuranceContract;
  onVerify: typeof verifyContractActions.request;
  onDelete: typeof deleteContractActions.request;
  onAttachmentsUpload: typeof uploadContractAttachmentsActions.request;
  onAttachmentDelete: typeof deleteContractAttachmentActions.request;
  onUpdateClick(): void;
}

const InsuranceContractDetailView = ({ contract, ...props }: Props) => {

  const layout: ColProps = { span: 24, style: { maxWidth: "1100px" } };

  return (
    <Row gutter={64} className="margin-top-small" justify="center">
      <Col {...layout}>

        <InsuranceContractHeaderSection
          contract={contract}
          onVerify={props.onVerify}
          onDelete={props.onDelete}
          onUpdateClick={props.onUpdateClick} />

        <InsuranceContractInsurancesSection contract={contract} />

        <InsuranceContractDataSection contract={contract} />

        <ContractAttachmentsSection
          contract={contract}
          onUpload={props.onAttachmentsUpload}
          onDelete={props.onAttachmentDelete} />

      </Col>
    </Row>
  )
};

export default InsuranceContractDetailView;
