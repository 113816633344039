import React from "react";
import ReactQuill from "react-quill";
import { Col, Form, Row, Select } from "antd";
import { Client } from "../../../../../client/types";
import { validations } from "../../../../../../common/utils/validationUtils";
import { quillEditorStandardProps, selectStandardProps } from "../../../../../../common/utils/formUtils";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

export interface Props {
  index: number;
  clients: Client[];
}

const GenericInsuranceFormPart = ({ index, clients }: Props) => {
  const colSpan = 6;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name={["insurances", index, "insuranceData", "description"]}
            label={t("contract.attrs.insurances.insuranceData.description")}
            rules={[validations.notBlank, validations.size(1, 8192)]}
            initialValue={null}>
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={colSpan}>
          <Form.Item
            name={["insurances", index, "insuredClientIdentifier"]}
            label={t("contract.attrs.insurances.insuredClientIdentifier")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={clients.filter(c => !!c).map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default GenericInsuranceFormPart;
