import React from "react";
import { Col, Divider, Popconfirm, Row, Table, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { ColumnsType } from "antd/lib/table";
import { CalcDraftAttachment, CalcDraftList } from "../types";
import { deleteCalcDraftAttachmentActions, uploadCalcDraftAttachmentsActions } from "../ducks";
import { formatFileSize, formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import t from "../../../../app/i18n";

import AddDeleteButton from "../../../../common/components/buttons/AddDeleteButton";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import FileTypeIcon from "../../../../common/components/icons/FileTypeIcon";
import ActionLinkIcon from "../../../../common/components/icons/ActionLinkIcon";

export interface Props {
  calcDraft: CalcDraftList;
  onUpload: typeof uploadCalcDraftAttachmentsActions.request;
  onDelete: typeof deleteCalcDraftAttachmentActions.request;
}

const CalcDraftAttachmentsTableView = (props: Props) => {

  const handleAttachmentUpload = (file: RcFile, fileList: RcFile[]): boolean => {
    if ( fileList.length > 0 ) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      props.onUpload({ id: props.calcDraft.id, object: formData });
    }

    return false;
  };

  const columns: ColumnsType<CalcDraftAttachment> = [
    {
      key: "filename",
      title: t("calc.draft.attrs.attachmentFilename"),
      render: (text, record) => <span>
        <FileTypeIcon contentType={record.contentType} size="huge" /> {record.filename}
      </span>
    },
    {
      key: "createdAt",
      title: t("calc.draft.attrs.attachmentCreatedAt"),
      render: (text, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "size",
      title: t("calc.draft.attrs.attachmentSize"),
      render: (text, record) => formatFileSize(record.size)
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (text, record) => (
        <>
          <ActionLinkIcon
            path={`/calc/${props.calcDraft.calcType.toLowerCase()}/drafts/${props.calcDraft.id}/attachments/${record.id}`}
            target="_blank" type="open" color="blue" text={t("common.open")} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("calc.draft.titles.deleteAttachmentConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => props.onDelete({ id1: props.calcDraft.id, id2: record.id })}>
            <span><ActionTextIcon type="delete" color="red" text={t("common.delete")} /></span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <>
      <h3 className="margin-bottom-small">{t("calc.draft.titles.attachmentsList")}</h3>

      {props.calcDraft.attachments && props.calcDraft.attachments.length > 0 ? (
        <Row>
          <Col span={24} style={{ maxWidth: "900px" }}>
            <Table<CalcDraftAttachment>
              size="small"
              className="table-row-stripped"
              rowKey="id"
              columns={columns}
              dataSource={props.calcDraft.attachments}
              pagination={{ pageSize: 10, size: "small", hideOnSinglePage: true }} />
          </Col>
        </Row>
      ) : (
        <span className="sub-header-info dashed">{t("calc.draft.helpers.noAttachments")}</span>
      )}

      <div className="margin-top-small">
        <Upload
          multiple
          showUploadList={false}
          beforeUpload={handleAttachmentUpload}>
          <AddDeleteButton type="add" label={t("calc.draft.actions.uploadAttachment")} />
        </Upload>
      </div>
    </>
  )
};

export default CalcDraftAttachmentsTableView;
