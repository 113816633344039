import React, { useState } from "react";
import { Checkbox, Col, Divider, Form, Input, Popconfirm, Row, Select, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { CalcSettings } from "../../types";
import { AttachmentConfType } from "../../enums";
import { deleteCalcSettingsAttachmentConfActions, uploadCalcSettingsAttachmentConfsActions } from "../../ducks";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import AddDeleteButton from "../../../../../common/components/buttons/AddDeleteButton";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import ActionLinkIcon from "../../../../../common/components/icons/ActionLinkIcon";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";

export interface Props {
  calcSettings: CalcSettings;
  onUploadAttachmentConf: typeof uploadCalcSettingsAttachmentConfsActions.request;
  onDeleteAttachmentConf: typeof deleteCalcSettingsAttachmentConfActions.request;
}

const CalcSettingsAttachmentConfsPart = ({ calcSettings, ...props }: Props) => {

  const [attachmentConfType, setAttachmentConfType] = useState<AttachmentConfType>(AttachmentConfType.STATIC);

  const handleAttachmentConfUpload = (file: RcFile, fileList: RcFile[]): boolean => {
    if ( calcSettings.attachmentConfs.find(conf => conf.originalFilename === file.name) ) {
      messageUtils.errorNotification(t("common.error"), t("calc.settings.validations.duplicateAttachmentConfFilename", {
        duplicateFilename: file.name
      }));
    }
    else {
      const formData = new FormData();
      formData.append("types", attachmentConfType);
      formData.append("files", file);
      props.onUploadAttachmentConf({ object: formData, id: calcSettings.id });
    }

    if ( fileList.length > 0 ) {
      fileList.splice(0, 1);
    }

    return false;
  };

  const colSpan = 5;
  const colSpanBig = 9;

  return calcSettings ? (
    <>
      <Divider orientation="left">{t("calc.settings.sections.attachments")}</Divider>

      {calcSettings.attachmentConfs.map((conf, index) => (
        <React.Fragment key={index}>
          <Row gutter={rowGutter}>
            <Col>
              <span>{t("calc.settings.attrs.attachmentConfs.originalFilename")}: </span>
              <ActionLinkIcon
                path={`/calc/settings/${calcSettings.type.toLowerCase()}/${calcSettings.id}/attachment-confs/${conf.id}`}
                target="_blank" type="open" color="green" size="large" text={conf.originalFilename} />
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col>
              <ItemCreatedUpdatedInfoView item={conf} />
            </Col>
          </Row>

          <HiddenInput name={["attachmentConfs", index, "id"]} />
          <HiddenInput name={["attachmentConfs", index, "optimisticLockVersion"]} />

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item label={t("calc.settings.enums.attachmentConfType._label")}>
                <Select
                  value={conf.type}
                  disabled={true}
                  options={Object.keys(AttachmentConfType).map(type => ({
                    value: type,
                    label: t("calc.settings.enums.attachmentConfType." + type)
                  }))} />
              </Form.Item>
            </Col>

            <Col span={colSpanBig}>
              <Form.Item
                name={["attachmentConfs", index, "filename"]}
                label={t("calc.settings.attrs.attachmentConfs.filename")}
                rules={[validations.notBlank, validations.pattern(regexPatterns.filenameRegex)]}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name={["attachmentConfs", index, "shouldAttachToEmail"]}
                className="form-item-without-label"
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}>
                <Checkbox>{t("calc.settings.attrs.attachmentConfs.shouldAttachToEmail")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan - 1}>
              <Form.Item
                name={["attachmentConfs", index, "shouldAttachToContract"]}
                className="form-item-without-label"
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}>
                <Checkbox>{t("calc.settings.attrs.attachmentConfs.shouldAttachToContract")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={1}>
              <Popconfirm
                title={t("calc.settings.titles.deleteAttachmentConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => props.onDeleteAttachmentConf({ id1: calcSettings.id, id2: conf.id })}>
                <span><DeleteIcon /></span>
              </Popconfirm>
            </Col>
          </Row>
        </React.Fragment>
      ))}

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("calc.settings.enums.attachmentConfType._label")}>
            <Select<AttachmentConfType>
              {...selectStandardProps}
              value={attachmentConfType}
              options={[AttachmentConfType.STATIC, AttachmentConfType.DYNAMIC].map(type => ({
                value: type,
                label: t("calc.settings.enums.attachmentConfType." + type)
              }))}
              onChange={setAttachmentConfType} />
          </Form.Item>
        </Col>

        <Col span={colSpan} className="control-item-without-label">
          <Upload
            showUploadList={false}
            beforeUpload={handleAttachmentConfUpload}>
            <AddDeleteButton type="add" label={t("calc.settings.actions.addAttachmentConf")} />
          </Upload>
        </Col>
      </Row>
    </>
  ) : null
}

export default CalcSettingsAttachmentConfsPart;
