import React from "react";
import { Col, DatePicker, Input, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { Country } from "../../../../../common/modules/enums";
import validations, { regexPatterns } from "../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerPresentAndFuture,
  fillBirthDateFromPinChangeEvent_deprecated,
  ibanNormalizeFunction,
  selectStandardProps,
  upperCaseStringNormalizeFunction
} from "../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

export interface Props {
  form: WrappedFormUtils;
  initialCrnValue?: string;
  disableCrnInput?: boolean;
}

const SelfEmployedClientDataFormPart = ({ form, initialCrnValue, disableCrnInput }: Props) => {
  const { getFieldDecorator } = form;
  const colSpan = 6;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.companyNameFOP")}>
            {getFieldDecorator("companyName", { rules: [validations.notBlank, validations.size(1, 255)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.academicDegree")}>
            {getFieldDecorator("academicDegree", {
              rules: [validations.size(1, 64), validations.academicDegree]
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.firstName")}>
            {getFieldDecorator("firstName", {
              rules: [validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.lastName")}>
            {getFieldDecorator("lastName", {
              rules: [validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.academicDegreeAfter")}>
            {getFieldDecorator("academicDegreeAfter", {
              rules: [validations.size(1, 64), validations.academicDegreeAfter]
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.personalIdentificationNumberLong")}>
            {getFieldDecorator("personalIdentificationNumber", { rules: [validations.notBlank, validations.pin] })(
              <Input onChange={event => fillBirthDateFromPinChangeEvent_deprecated(event, form)} />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.birthDate")}>
            {getFieldDecorator("birthDate", { rules: [validations.notNull, validations.notPresentAndFuture] })(
              <DatePicker {...datePickerStandardProps} showToday={false}
                          disabledDate={disableDatePickerPresentAndFuture} />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.identityCardNumber")}>
            {getFieldDecorator("identityCardNumber", {
              rules: [validations.notBlank, validations.size(6, 10), validations.pattern(regexPatterns.idCardRegex)],
              normalize: upperCaseStringNormalizeFunction
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.nationality")}>
            {getFieldDecorator("nationality", {
              rules: [validations.notNull], initialValue: Country.SVK
            })(
              <Select
                {...selectStandardProps}
                options={Object.keys(Country).map(country => ({ value: country, label: country }))} />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.bankAccountNumber")}>
            {getFieldDecorator("bankAccountNumber", {
              rules: [validations.size(1, 34), validations.iban], normalize: ibanNormalizeFunction
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.webPage")}>
            {getFieldDecorator("webPage", { rules: [validations.size(1, 2048), validations.pattern(regexPatterns.webPageRegex)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.companyRegistrationNumber")}>
            {getFieldDecorator("companyRegistrationNumber", {
              rules: [validations.notBlank, validations.crn], initialValue: initialCrnValue
            })(
              <Input disabled={disableCrnInput} />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.districtOffice")}>
            {getFieldDecorator("districtOffice", { rules: [validations.notBlank, validations.size(1, 255)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.taxId")}>
            {getFieldDecorator("taxId", { rules: [validations.length(10), validations.numeric] })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.tradeRegisterNumber")}>
            {getFieldDecorator("tradeRegisterNumber", {
              rules: [validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.tradeRegNumRegex)]
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.vatId")}>
            {getFieldDecorator("vatId", { rules: [validations.length(12), validations.pattern(regexPatterns.vatIdRegex)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item label={t("client.attrs.note")}>
            {getFieldDecorator("note", { rules: [validations.size(1, 8192)] })(
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default SelfEmployedClientDataFormPart;
