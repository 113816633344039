import "./realty-calc-results.less";
import React from "react";
import { Affix, Button, Col, Divider, Drawer, Row } from "antd";
import { DoubleRightOutlined, FileTextOutlined, FormOutlined } from "@ant-design/icons";
import { CalcResult } from "../../../types";
import { RealtyCalcResultData } from "../../types";
import t from "../../../../../../app/i18n";

import RealtyCalcResultItem from "./RealtyCalcResultItem";

export interface Props {
  visible: boolean;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  selectedResult?: CalcResult<RealtyCalcResultData>;
  onClose(): void;
  onSaveDraftClick(): void;
  onGenerateOfferClick(): void;
  onGenerateContractClick(result: CalcResult<RealtyCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<RealtyCalcResultData>): void;
}

const RealtyCalcResults = (props: Props) => (
  <Drawer
    title={t("calc.realty.sections.results")}
    width={800}
    visible={props.visible}
    onClose={props.onClose}
    closable>

    <div className="realty-calc-results-container">
      {props.calcResults.map((resultsRow, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <Row>
            <Col span={24} className="result-title">
              <h3>{resultsRow[0].insuranceInstitution.name}</h3>
            </Col>
          </Row>

          <Row>
            {resultsRow.map((result, index) => {
              const resultSelected = props.selectedResult &&
                props.selectedResult.insuranceInstitution.id === result.insuranceInstitution.id &&
                props.selectedResult.coverage === result.coverage;

              return (
                <Col span={12} key={index} className={resultSelected ? "result-data result-selected" : "result-data"}>
                  <RealtyCalcResultItem
                    result={result}
                    selected={resultSelected}
                    onGenerateContractClick={props.onGenerateContractClick}
                    onShowErrorsClick={props.onShowErrorsClick} />
                </Col>
              )
            })}
          </Row>

          {rowIndex < props.calcResults.length - 1 && (
            <Row>
              <Col span={24}><Divider /></Col>
            </Row>
          )}
        </React.Fragment>
      ))}
    </div>

    <Affix offsetBottom={0} className="realty-calc-results-navigation">
      <>
        <Button className="blue-button" icon={<FormOutlined />} onClick={props.onSaveDraftClick}>
          {t("calc.realty.actions.saveDraft")}
        </Button>

        <Button type="primary" icon={<FileTextOutlined />} onClick={props.onGenerateOfferClick}>
          {t("calc.realty.actions.generateOffer")}
        </Button>

        <Button icon={<DoubleRightOutlined />} onClick={props.onClose}>
          {t("common.close")}
        </Button>
      </>
    </Affix>

  </Drawer>
);

export default RealtyCalcResults;
