import React from "react";
import { Route, Switch } from "react-router";
import { Permission } from "../../../common/security/authorization/enums";

import RouteWithPermission from "../../../common/security/authorization/RouteWithPermission";
import LifeInsuranceTariffsContainer from "./containers/LifeInsuranceTariffsContainer";
import NotFound from "../../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission
      exact path="/admin/life-insurance-tariffs"
      component={LifeInsuranceTariffsContainer}
      permissions={[Permission.ADMIN_ENUMERATIONS]}
    />
    <Route component={NotFound} />
  </Switch>
);