import React, { ReactText, useState } from "react";
import { Col, Form, Input, Modal, Row, Select, Tree } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { AdminCreatePersonAccount } from "../../types";
import { AntTreeStrictCheckedProps } from "../../../../common/types";
import { Person } from "../../../person/types";
import { PersonType } from "../../../person/enums";
import { Permission } from "../../../../common/security/authorization/enums";
import { ContactType } from "../../../../common/modules/enums";
import { createPersonAccountActions } from "../../ducks";
import { buildPermissionTreeNodes } from "../../utils";
import { containsAll, getAllPermissionPrerequisites } from "../../../../common/utils/utils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import {
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { OrganizationPathDelimiter, rowGutter } from "../../../../common/constants";
import { requests } from "../../api";
import t from "../../../../app/i18n";

import PersonsEnumFormItemSelect from "../../../enumerations/components/form/PersonsEnumFormItemSelect";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  visible: boolean;
  person: Person;
  onFormSubmit: typeof createPersonAccountActions.request;
  onFormCancel(): void;
}

const PersonAccountCreateForm = ({ visible, person, onFormSubmit, onFormCancel }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "account.attrs", requests.CREATE_PERSON_ACCOUNT);

  const [formCheckedPermissions, setFormCheckedPermissions] = useState<Permission[]>([]);

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
    setFormCheckedPermissions([]);
  };

  const inProgress = useRequestFinishedCallback(requests.CREATE_PERSON_ACCOUNT, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: AdminCreatePersonAccount | Store) => {
        if ( formCheckedPermissions.length === 0 ) {
          messageUtils.errorMessage(t("account.helpers.noPermissions"));
        }
        else {
          onFormSubmit({
            id: person.id,
            object: { ...values, permissions: formCheckedPermissions } as AdminCreatePersonAccount
          });
        }
      })
      .catch(resolveFormValidationError);
  };

  const handlePermissionTreeCheck = (checked: ReactText[] | AntTreeStrictCheckedProps): void => {
    const checkedPermissions = (checked as AntTreeStrictCheckedProps).checked.map(checked => Permission[checked]);
    setFormCheckedPermissions(checkedPermissions.filter(permission => containsAll(checkedPermissions, ...getAllPermissionPrerequisites(permission))));
  };

  const colSpan = 12;

  return (
    <Modal
      width={700}
      visible={visible}
      title={t("account.titles.create")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="personAccountCreateForm">

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={<LabelWithTooltip label={t("account.attrs.name")}
                                       tooltip={t("account.helpers.accountNameInfo")} />}
              rules={[validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="email"
              label={t("account.attrs.email")}
              rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={person.contacts
                  .filter(contact => contact.type === ContactType.EMAIL || contact.type === ContactType.BROKER_EMAIL)
                  .map(contact => ({ value: contact.value, label: contact.value }))} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <PersonsEnumFormItemSelect
              formItemProps={{
                name: "backOfficeTargetId",
                label: <LabelWithTooltip label={t("account.attrs.backOfficeTargetId")}
                                         tooltip={t("account.helpers.backOfficeTargetInfo")} />,
                rules: [validations.none]
              }}
              selectProps={{ allowClear: true }}
              optionsProps={{ filterOrganizationPath: person.organizationPath }} />
          </Col>

          <Col span={colSpan}>
            <PersonsEnumFormItemSelect
              formItemProps={{
                name: "representativeTargetId",
                label: <LabelWithTooltip label={t("account.attrs.representativeTargetId")}
                                         tooltip={t("account.helpers.representativeTargetInfo")} />,
                rules: [validations.none]
              }}
              selectProps={{ allowClear: true }}
              optionsProps={{
                filter: personOption => personOption.organizationPath.startsWith(person.organizationPath)
                  && personOption.id !== person.id && personOption.type === PersonType.NATURAL
                  && !personOption.organizationPath.replace(person.organizationPath + OrganizationPathDelimiter, "").includes(OrganizationPathDelimiter)
              }} />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan * 2}>
            <Form.Item label={t("account.attrs.permissionsLabel")} required>
              <Tree
                checkStrictly checkable
                checkedKeys={{ checked: formCheckedPermissions, halfChecked: [] }}
                treeData={buildPermissionTreeNodes(formCheckedPermissions)}
                onCheck={handlePermissionTreeCheck} />
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </Modal>
  );
}

export default PersonAccountCreateForm;
