import React, { FormEvent } from "react";
import { Moment } from "moment";
import { Button, Col, Divider, Row, Select } from "antd";
import { ColProps } from "antd/lib/grid";
import { SaveOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CreateUpdateClient, CreateUpdateNaturalClient } from "../../types";
import { ClientType } from "../../enums";
import { ContactType } from "../../../../common/modules/enums";
import {
  fillBirthDateFromPin_deprecated,
  getAllRootFieldsNames_deprecated,
  momentToDateString,
  selectStandardProps
} from "../../../../common/utils/formUtils";
import validations from "../../../../common/utils/validationUtils";
import { contains, removeStringWhiteSpaces } from "../../../../common/utils/utils";
import { rowGutter } from "../../../../common/constants";
import t from "../../../../app/i18n";

import NaturalClientDataFormPart from "./parts/NaturalClientDataFormPart";
import SelfEmployedClientDataFormPart from "./parts/SelfEmployedClientDataFormPart";
import LegalClientDataFormPart from "./parts/LegalClientDataFormPart";
import ClientAddressesFormPart from "./parts/ClientAddressesFormPart";
import ContactsFormDeprecated from "../../../../common/modules/contact/ContactsForm_Deprecated";

export interface ClientCreateFormComponent extends React.Component<Props, State> {
  handleFormSubmit(event?: FormEvent): void;
  handleCorrespondenceAddressEnabledChange(enabled: boolean): void;
  handleClientTypeChange(clientType: ClientType): void;
  validateFormFields(): void;
}

export interface Props extends FormComponentProps<CreateUpdateClient> {
  initialClientType?: ClientType;
  excludedClientTypes?: ClientType[];
  initialCrnValue?: string;
  initialPinValue?: string;
  hideSubmitButton?: boolean;
  disableFormSubmitValidation?: boolean;
  onFormSubmit(clientData: CreateUpdateClient, errors?: any): void;
}

interface State {
  readonly selectedClientType: ClientType;
  readonly correspondenceAddressEnabled: boolean;
}

class ClientCreateForm extends React.Component<Props, State> implements ClientCreateFormComponent {

  getEnabledClientTypes = (): ClientType[] => {
    return this.props.excludedClientTypes
      ? Object.values(ClientType).filter(type => !contains(this.props.excludedClientTypes, type))
      : Object.values(ClientType);
  };

  readonly state: State = {
    selectedClientType: this.props.initialClientType || this.getEnabledClientTypes()[0],
    correspondenceAddressEnabled: false
  };

  handleFormSubmit = (event?: FormEvent): void => {
    if ( event ) {
      event.preventDefault();
    }

    if ( this.props.disableFormSubmitValidation ) {
      this.props.onFormSubmit(this.processAndGetFormValues());
    }
    else {
      this.props.form.validateFieldsAndScroll(this.getFieldsForValidation(), (errors, values) => {
        this.props.onFormSubmit(this.processAndGetFormValues(values), errors);
      });
    }
  };

  handleCorrespondenceAddressEnabledChange = (enabled: boolean): void => {
    this.setState({ correspondenceAddressEnabled: enabled });
  };

  handleClientTypeChange = (clientType: ClientType): void => {
    this.props.form.resetFields();
    this.setState({ selectedClientType: clientType });
  };

  validateFormFields = (): void => {
    this.props.form.validateFields(this.getFieldsForValidation());
  };

  processAndGetFormValues = (formValues?: CreateUpdateClient): CreateUpdateClient => {
    const processedValues = formValues ? { ...formValues } : this.props.form.getFieldsValue() as CreateUpdateClient;
    processedValues.bankAccountNumber = removeStringWhiteSpaces(processedValues.bankAccountNumber);
    processedValues.correspondenceAddress = this.state.correspondenceAddressEnabled ? processedValues.correspondenceAddress : null;
    processedValues.contacts = (processedValues.contacts ? processedValues.contacts : [])
      .map(c => ({ ...c, value: c.type === ContactType.PHONE_NUMBER ? removeStringWhiteSpaces(c.value) : c.value }));

    if ( processedValues.type === ClientType.NATURAL || processedValues.type === ClientType.SELF_EMPLOYED ) {
      (processedValues as CreateUpdateNaturalClient).birthDate = momentToDateString((processedValues as CreateUpdateNaturalClient).birthDate as Moment);
    }

    return processedValues;
  };

  getFieldsForValidation = (): string[] => {
    const fields = getAllRootFieldsNames_deprecated(this.props.form);
    return this.state.correspondenceAddressEnabled ? fields : fields.filter(field => field !== "correspondenceAddress");
  };

  componentDidMount(): void {
    if ( this.props.initialPinValue ) {
      fillBirthDateFromPin_deprecated(this.props.initialPinValue, this.props.form);
    }
  }

  render(): React.ReactNode {
    const { form, initialPinValue, initialCrnValue } = this.props;
    const { getFieldDecorator } = form;

    const formLayout: ColProps = { span: 24, style: { maxWidth: "900px" } };
    const colSpan = 6;

    let dataFormSection;
    switch ( this.state.selectedClientType ) {
      case ClientType.NATURAL:
        dataFormSection = <NaturalClientDataFormPart form={form}
                                                     initialPinValue={initialPinValue}
                                                     disablePinInput={!!initialPinValue} />;
        break;
      case ClientType.SELF_EMPLOYED:
        dataFormSection = <SelfEmployedClientDataFormPart form={form}
                                                          initialCrnValue={initialCrnValue}
                                                          disableCrnInput={!!initialCrnValue} />;
        break;
      case ClientType.LEGAL:
        dataFormSection = <LegalClientDataFormPart form={form}
                                                   initialCrnValue={initialCrnValue}
                                                   disableCrnInput={!!initialCrnValue} />;
        break;
    }

    return (
      <Form layout="vertical" onSubmit={this.handleFormSubmit}>

        <Row>
          <Col {...formLayout}>
            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <Form.Item label={t("client.enums.type._label")}>
                  {getFieldDecorator("type", {
                    rules: [validations.notNull], initialValue: this.state.selectedClientType
                  })(
                    <Select
                      {...selectStandardProps}
                      onChange={this.handleClientTypeChange}
                      disabled={!!this.props.initialClientType}
                      options={this.getEnabledClientTypes().map(type => ({
                        value: type,
                        label: t("client.enums.type." + type)
                      }))} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider orientation="left">{t("client.titles.basicData")}</Divider>

        <Row>
          <Col {...formLayout}>
            {dataFormSection}
          </Col>
        </Row>

        <Divider orientation="left">{t("client.titles.addresses")}</Divider>

        <Row>
          <Col {...formLayout}>
            <ClientAddressesFormPart
              form={form}
              clientType={this.state.selectedClientType}
              correspondenceAddressEnabled={this.state.correspondenceAddressEnabled}
              onCorrespondenceAddressEnabledChange={this.handleCorrespondenceAddressEnabledChange} />
          </Col>
        </Row>

        <Divider orientation="left">{t("client.titles.contacts")}</Divider>

        <Row>
          <Col {...formLayout}>
            <ContactsFormDeprecated form={form} />
          </Col>
        </Row>

        {!this.props.hideSubmitButton && (
          <Button className="margin-top-large" type="primary" htmlType="submit" icon={<SaveOutlined />}>
            {t("common.save")}
          </Button>
        )}

      </Form>
    );
  }
}

export default Form.create<Props>()(ClientCreateForm);
