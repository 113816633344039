import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../types";
import { Permission } from "./enums";
import { selectPermissions } from "../../../modules/auth/ducks";
import { hasAllPermissions, hasAnyPermission } from "../../utils/utils";

export interface Props {
  children: React.ReactNode;
  permissions: Permission[];
  anyPermissionPresent?: boolean;
}

const ComponentWithPermission = ({ permissions, anyPermissionPresent, children }: Props) => {
  const accountPermissions = useSelector<RootState, Permission[]>(selectPermissions);

  return (anyPermissionPresent ?
    hasAnyPermission(accountPermissions, permissions) :
    hasAllPermissions(accountPermissions, permissions)) ? <>{children}</> : null;
};

export default ComponentWithPermission;
