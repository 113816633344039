import fileSize from "filesize";
import ibanUtils from "iban";
import { formatNumber } from "libphonenumber-js";
import { isMoment, Moment } from "moment";
import cronParser from "cron-parser";
import { Address } from "../modules/types";
import { PlaceOfInsurance } from "../../modules/calculator/calcs/realty/types";
import { PersonBase } from "../../modules/person/types";
import { Client } from "../../modules/client/types";
import { PersonAccountBase } from "../../modules/account/types";
import { InstitutionBase } from "../../modules/admin/institution/types";
import { ProductBase } from "../../modules/admin/product/types";
import { LifeInsuranceTariffBase } from "../../modules/admin/lifeinsurancetariff/types";
import { PersonType } from "../../modules/person/enums";
import { isDefinedValue } from "./utils";
import t, { currentLanguage } from "../../app/i18n";

export const DATE_REGEX = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
export const DATE_TIME_REGEX = new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?$");

export const formatIban = (iban: string): string | null => iban ? ibanUtils.printFormat(iban, " ") : null;

export const formatPhoneNumber = (phone: string): string | null => phone ? formatNumber(phone, "International") : null;

export const formatLocaleNumber = (input: number): string | null => input ? Intl.NumberFormat(currentLanguage()).format(input) : null;

export const formatLocaleNumberWithNullAsZero = (input: number): string => Intl.NumberFormat(currentLanguage()).format(input);

export const formatLocaleCurrency = (input: number): string | null => {
  return isDefinedValue(input) ? Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "EUR"
  }).format(input) : null;
};

export const formatLocaleCurrencyWithNullAsZero = (input: number): string => {
  return Intl.NumberFormat(currentLanguage(), { style: "currency", currency: "EUR" }).format(input);
};

export const formatIntegerLocaleCurrency = (input: number): string => {
  return Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(input);
};

export const formatLocaleCurrencyWithMillionLabel = (input: number): string => {
  return Intl.NumberFormat(currentLanguage(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(input) + " mil €";
};

export const formatLocalePercentageNumber = (input: number, fractionDigits: number = 2): string | null => {
  return isDefinedValue(input) ? Intl.NumberFormat(currentLanguage(), {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }).format(input) + " %" : null;
};

export const formatLocalePercentageNumberWithNullAsZero = (input: number, fractionDigits: number = 2): string => {
  return Intl.NumberFormat(currentLanguage(), {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }).format(input) + " %";
};

export const formatLocaleDate = (input: string | Moment): string | null => {
  const date = isMoment(input) ? (input as Moment).toDate() : input ? new Date(input) : null;
  return date ? date.toLocaleDateString(currentLanguage()) : null;
};

export const formatLocaleDateTime = (input: string | Moment): string | null => {
  const date = isMoment(input) ? (input as Moment).toDate() : input ? new Date(input) : null;
  return date ? date.toLocaleString(currentLanguage()) : null;
};

export const formatFileSize = (size: number): string => fileSize(size, { locale: "sk" });

export const formatAddress = (address: Address, showCountry?: boolean): string => {
  return (address.street && address.street.length > 0 ? address.street : address.city)
    + (address.descriptiveNumber && address.descriptiveNumber.length > 0
      ? ` ${address.descriptiveNumber}` + (address.orientationNumber && address.orientationNumber.length > 0 ? `/${address.orientationNumber}` : "")
      : ` ${address.orientationNumber}`)
    + `, ${address.zipCode} ${address.city}`
    + (showCountry ? `, ${address.country}` : "");
};

export const formatPlaceOfInsurance = (place: PlaceOfInsurance, showCountry?: boolean): string => {
  return (place.street && place.street.length > 0 ? place.street : place.city)
    + (place.descriptiveNumber && place.descriptiveNumber.length > 0
      ? ` ${place.descriptiveNumber}` + (place.orientationNumber && place.orientationNumber.length > 0 ? `/${place.orientationNumber}` : "")
      : (place.orientationNumber && place.orientationNumber.length > 0 ? ` ${place.orientationNumber}` : ""))
    + (place.parcelNumber1
      ? (place.parcelNumber2
        ? ` (${t("address.attrs.parcelNumber")} ${place.parcelNumber1}, ${place.parcelNumber2})`
        : ` (${t("address.attrs.parcelNumber")} ${place.parcelNumber1})`)
      : "")
    + `, ${place.zipCode} ${place.city}`
    + (showCountry ? `, ${place.country}` : "");
};

export const formatPersonName = (person: PersonBase): string => {
  if ( person ) {
    const aggregatedName = person.type === PersonType.SELF_EMPLOYED ? person.aggregatedNaturalName : person.aggregatedName;
    return person.deactivated ? `${aggregatedName} (${t("person.helpers.deactivatedPersonShort")})` : aggregatedName;
  }
  return null;
};

export const formatPersonAccountName = (account: PersonAccountBase): string => {
  return account
    ? account.person.aggregatedName + (account.name ? ` (${account.name})` : '')
    : null;
};

export const formatClientName = (client: Client): string => {
  return client ? `${client.aggregatedName} (${client.identifier})` : null;
};

export const formatInstitutionName = (institution: InstitutionBase): string => {
  return institution
    ? institution.deactivated ? `${institution.name} (${t("admin.institution.helpers.deactivated")})` : institution.name
    : null;
};

export const formatProductName = (product: ProductBase): string => {
  return product
    ? product.deactivated ? `${product.name} (${t("admin.product.helpers.deactivated")})` : product.name
    : null;
};

export const formatCoverageLimits = (health: number, property: number): string => {
  return formatLocaleCurrencyWithMillionLabel(health) + " / " + formatLocaleCurrencyWithMillionLabel(property);
};

export const formatLifeInsuranceTariff = (tariff: LifeInsuranceTariffBase): string => {
  const formatName = `${tariff.code} - ${tariff.name}`;
  return tariff ? tariff.deactivated ? `${formatName} (${t("common.deactivated")})` : formatName : null;
}

export const formatLifeInsuranceTariffName = (tariff: LifeInsuranceTariffBase): string => {
  return tariff ? tariff.deactivated ? `${tariff.name} (${t("common.deactivated")})` : tariff.name : null;
}

export const formatCron = (value: string, nextDatesCount: number = 1): string => {
  if ( value ) {
    try {
      const expression = cronParser.parseExpression(value);
      const results = [];
      for ( let i = 0; i < nextDatesCount; i++ ) {
        if ( expression.hasNext() ) {
          results.push(formatLocaleDateTime(expression.next().toString()));
        }
      }
      return results.join(" | ");
    }
    catch ( e ) {
      return null;
    }
  }
  return null;
}
