import React from "react";
import { GenericInsurance, InsuranceContract } from "../../../../../types";
import { ClientFormType } from "../../../../../../client/enums";
import { formatLocaleCurrency, formatLocaleDate } from "../../../../../../../common/utils/formatUtils";
import t from "../../../../../../../app/i18n";

import ClientsListDrawerView from "../../../../../../client/components/drawers/ClientsListDrawerView";
import HtmlView from "../../../../../../../common/components/views/HtmlView";

export interface Props {
  contract: InsuranceContract;
  index: number;
  multipleInsurances: boolean;
  multipleClients: boolean;
}

const GenericInsuranceView = ({ contract, index, multipleInsurances, multipleClients }: Props) => {
  const { insuranceData, ...insurance } = contract.insurances[index] as GenericInsurance;

  return (
    <>
      <table className="data-table-view">
        <tbody>
        {multipleInsurances && (
          <>
            <tr>
              <td>{t("contract.attrs.insurances.contractEntryDate")}:</td>
              <td>{formatLocaleDate(insurance.contractEntryDate)}</td>
              <td>{t("contract.attrs.insurances.annualPremium")}:</td>
              <td className="right-align">{formatLocaleCurrency(insurance.annualPremium)}</td>
              <td colSpan={2} />
            </tr>
            <tr>
              <td>{t("contract.attrs.insurances.contractWithdrawalDate")}:</td>
              <td>{formatLocaleDate(insurance.contractWithdrawalDate)}</td>
              <td>{t("contract.attrs.insurances.partialPremium")}:</td>
              <td className="right-align">{formatLocaleCurrency(insurance.partialPremium)}</td>
              <td colSpan={2} />
            </tr>
          </>)}
        <tr>
          <td colSpan={6}>{t("contract.attrs.insurances.insuranceData.description")}:</td>
        </tr>
        <tr>
          <td colSpan={6}><HtmlView value={insuranceData.description} /></td>
        </tr>
        </tbody>
      </table>

      {multipleClients && (
        <ClientsListDrawerView className="margin-top-medium" clientsData={[{
          client: contract.clients[insurance.insuredClientIndex],
          clientFormTypes: [ClientFormType.INSURED]
        }]} />)}
    </>
  )
}

export default GenericInsuranceView;
