import React from "react";
import { Tag } from "antd";
import { PersonType } from "../enums";
import t from "../../../app/i18n";

export interface Props {
  type: PersonType;
  style?: React.CSSProperties;
}

const PersonTypeTag = ({ type, style }: Props) => {
  let color: string;

  switch ( type ) {
    case PersonType.NATURAL:
      color = "blue";
      break;
    case PersonType.SELF_EMPLOYED:
      color = "gold";
      break;
    case PersonType.LEGAL:
      color = "purple";
      break;
    default:
      return null;
  }

  return <Tag style={style} color={color}>{t("person.enums.type." + type)}</Tag>;
};

export default PersonTypeTag;
