import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { PasswordReset } from "../../types";
import { validations } from "../../../../common/utils/validationUtils";
import { useFormErrorHandler } from "../../../../common/utils/formUtils";
import { requests } from "../../api";
import t from "../../../../app/i18n";

export interface Props {
  resetPasswordDataParams: ResetPasswordDataParamsProps;
  onFormSubmit(passwordReset: PasswordReset): void;
}

export interface ResetPasswordDataParamsProps {
  optimisticLockVersion: number;
  token: string;
}

const ResetPasswordForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "account.attrs", requests.RESET_PASSWORD);

  const handleFormFinish = (values: PasswordReset | any): void => {
    props.onFormSubmit(Object.assign({}, values, props.resetPasswordDataParams));
  };

  return (
    <>
      <h2 className="center-align margin-bottom-large">{t("account.titles.passwordReset")}</h2>

      <Row justify="center">
        <Col span={24} style={{ maxWidth: "400px" }}>

          <Form form={form} layout="vertical" name="resetPasswordForm" onFinish={handleFormFinish}>

            <Form.Item
              name="password"
              label={t("account.attrs.password")}
              rules={validations.sufficientPassword}>
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="passwordRepeat"
              label={t("account.attrs.passwordRepeat")}
              rules={[...validations.sufficientPassword, validations.repeatedPassword(form)]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button className="margin-top-medium" type="primary" htmlType="submit" block>
                {t("account.actions.resetPassword")}
              </Button>
            </Form.Item>

          </Form>

        </Col>
      </Row>
    </>
  );
}

export default ResetPasswordForm;
