import React from "react";
import { RouteComponentProps } from "react-router";
import { replace } from "connected-react-router";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Modal, Tabs } from "antd";
import { HistoryOutlined, SolutionOutlined } from "@ant-design/icons";
import { ActionProps, RootState } from "../../../common/types";
import { Client, CreateUpdateClient } from "../types";
import { ValidationErrorResponse } from "../../types";
import {
  deleteClientActions,
  deleteStateClientDetailAction,
  getClientActions,
  selectClientDetail,
  updateClientActions
} from "../ducks";
import {
  deleteStateValidationErrorResponseAction,
  selectRouterLocationKey,
  selectRouterLocationSearchParam,
  selectValidationErrorResponse
} from "../../ducks";
import { requests } from "../api";
import { appendSearchParamsToCurrentPathname } from "../../../common/utils/utils";
import { setErrorsToForm_deprecated } from "../../../common/utils/formUtils";
import { serializeParams } from "../../../common/utils/apiUtils";
import t from "../../../app/i18n";

import ClientDetailView from "../components/views/detail/ClientDetailView";
import ClientUpdateForm, { ClientUpdateFormComponent } from "../components/forms/ClientUpdateForm";
import HistoryView from "../../../common/modules/history/HistoryView";
import ClientTypeTag from "../components/ClientTypeTag";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";

interface StateProps {
  client: Client;
  updateClientErrorResponse: ValidationErrorResponse;
  initialUrlActiveTab: string;
  locationKey: string;
}

interface ActionsMap {
  getClient: typeof getClientActions.request;
  updateClient: typeof updateClientActions.request;
  deleteClient: typeof deleteClientActions.request;
  deleteStateValidationErrorResponse: typeof deleteStateValidationErrorResponseAction;
  deleteStateClientDetail: typeof deleteStateClientDetailAction;
  replaceNavigation: typeof replace;
}

type Props = StateProps & ActionProps<ActionsMap> & RouteComponentProps;

interface State {
  readonly activeTabKey: string;
  readonly updateMode: boolean;
}

const TAB = {
  CLIENT: "1",
  CLIENT_HISTORY: "2"
};

class ClientDetailContainer extends React.Component<Props, State> {
  readonly state: State = {
    activeTabKey: TAB.CLIENT,
    updateMode: false
  };

  private formRef = React.createRef<ClientUpdateFormComponent>();

  handleActiveTabChange = (activeTabKey: string): void => {
    if ( this.state.activeTabKey === TAB.CLIENT && this.state.updateMode ) {
      Modal.confirm({
        title: t("client.helpers.unsavedChanges"),
        okText: t("client.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => this.moveViewToTab(activeTabKey)
      });
    }
    else {
      this.moveViewToTab(activeTabKey);
    }
  };

  handleUpdateModeToggle = (): void => {
    this.setState(previousState => ({ updateMode: !previousState.updateMode }));
  };

  handleUpdateFormSubmit = (clientData: CreateUpdateClient, errors: any): void => {
    if ( !errors ) {
      this.props.actions.updateClient({ id: this.props.client.id, object: clientData });
    }
  };

  handleDeleteClick = (): void => {
    this.props.actions.deleteClient({ id: this.props.client.id });
  };

  moveViewToTab = (activeTabKey: string): void => {
    this.setState({ activeTabKey });
    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(serializeParams({ activeTab: activeTabKey })));
  };

  componentDidMount(): void {
    if ( !this.props.client ) {
      this.props.actions.getClient({ id: this.props.match.params["id"] });
    }
    this.setState({ activeTabKey: this.props.initialUrlActiveTab });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( this.props.locationKey !== prevProps.locationKey ) {
      this.setState({ updateMode: false });
    }
    if ( this.state.updateMode && this.props.updateClientErrorResponse && this.formRef.current ) {
      setErrorsToForm_deprecated(this.formRef.current.props.form, this.props.updateClientErrorResponse.violations, "client.attrs");
      this.props.actions.deleteStateValidationErrorResponse();
    }
  }

  componentWillUnmount(): void {
    this.props.actions.deleteStateClientDetail();
  }

  render(): React.ReactNode {
    const { client } = this.props;

    return (
      <DisplayWrapper itemLoaded={!!client} notFoundCheckRequest={requests.GET_CLIENT}>
        {client && (
          <>
            <h2 className="left-float">{client.aggregatedName}</h2>
            <ClientTypeTag style={{ margin: "6px 0 0 8px" }} type={client.type} />

            <ItemCreatedUpdatedInfoView item={client} className="clear-both margin-bottom-medium" />

            <Tabs activeKey={this.state.activeTabKey} onChange={this.handleActiveTabChange}>
              <Tabs.TabPane key={TAB.CLIENT} tab={<span><SolutionOutlined />{t("client.titles.data")}</span>}>
                {this.state.updateMode ? (
                  <ClientUpdateForm
                    wrappedComponentRef={this.formRef}
                    client={client}
                    onFormSubmit={this.handleUpdateFormSubmit}
                    onCancelClick={this.handleUpdateModeToggle} />
                ) : (
                  <ClientDetailView
                    client={client}
                    onUpdateClick={this.handleUpdateModeToggle}
                    onDeleteClick={this.handleDeleteClick} />
                )}
              </Tabs.TabPane>

              <Tabs.TabPane key={TAB.CLIENT_HISTORY}
                            tab={<span><HistoryOutlined />{t("client.titles.history")}</span>}>
                <HistoryView item={client} translationRootPath="client.attrs" type="client" />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </DisplayWrapper>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  client: selectClientDetail(state),
  updateClientErrorResponse: selectValidationErrorResponse(state, requests.UPDATE_CLIENT),
  initialUrlActiveTab: selectRouterLocationSearchParam(state, "activeTab") || TAB.CLIENT,
  locationKey: selectRouterLocationKey(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getClient: getClientActions.request,
    updateClient: updateClientActions.request,
    deleteClient: deleteClientActions.request,
    deleteStateValidationErrorResponse: deleteStateValidationErrorResponseAction,
    deleteStateClientDetail: deleteStateClientDetailAction,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(ClientDetailContainer);
