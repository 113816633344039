import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { ConfigProvider } from "antd";

import configureStore from "../store/configureStore";
import history from "../store/rootHistory";
import routes from "../routes";
import { antDesignLocale } from "../i18n";
import { createRequestInterceptor, createResponseInterceptor } from "../../common/api/apiService";

import App from "./App";
import ErrorBoundary from "../../common/pages/ErrorBoundary/ErrorBoundary";
import AttachmentBoundary from "../../common/pages/AttachmentBoundary/AttachmentBoundary";

const { store, storePersistor } = configureStore();

createRequestInterceptor(store);
createResponseInterceptor(store);

const Root = () => (
  <I18nextProvider i18n={i18next}>
    <ConfigProvider locale={antDesignLocale()}>
      <Provider store={store}>
        <PersistGate persistor={storePersistor}>
          <ConnectedRouter history={history}>
            <ErrorBoundary>
              <AttachmentBoundary>
                <App>{routes}</App>
              </AttachmentBoundary>
            </ErrorBoundary>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </I18nextProvider>
);

export default Root;

// TODO (adresa + google places) - urobit autocomplete adresy cez Google Places API
//  - https://cloud.google.com/maps-platform/pricing/sheet/
//  - https://developers.google.com/places/web-service/autocomplete#place_types // https://developers.google.com/places/web-service/details#PlaceDetailsRequests
//  - urobit vlastnu axios instanciu + novy modul
//  - request na autocomplete + detail miesta (cez nase address API skontrolovat mesto, ci sa nejedna o mestsku stvrt)

// TODO pridat enumeration select na krajiny + preklady ku krajinam
// TODO skontrolovat pouzitie currentLanguage() + kde su pouzite konstany SK/sk_SK + pridat konstantu DEFAULT_LOCALE? + config i18n
// TODO na malom rozliseni vykreslit lave menu zmensene + upravit layout -> fixed side menu + header? (header asi nie)
// TODO pridat Google Analytics (konfiguracne z env)

// TODO AntD update:
//  - vsetky formulare z compatible prehodit na novu formu a odinstalovat @ant-design/compatible
//  - zmazat import "@ant-design/compatible/assets/index.css"; / import "antd/es/grid/style"; / ant-legacy-form-item-required / ant-legacy-form / form.ant-legacy-form CSS class
//  - parametre do Form.onFinish - pridane "| any" do types
