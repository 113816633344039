import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { CommissionsBatch, CommissionsUnit, CreateCommissionsUnit, UpdateCommissionsUnit } from "../../types";
import { createCommissionsUnitActions, updateCommissionsUnitActions } from "../../ducks";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import InstitutionsEnumFormItemSelect from "../../../../enumerations/components/form/InstitutionsEnumFormItemSelect";
import InputNumberWithAddon from "../../../../../common/components/form/components/InputNumberWithAddon";

export interface Props {
  batch: CommissionsBatch;
  unit: CommissionsUnit;
  visible: boolean;
  onCreate: typeof createCommissionsUnitActions.request;
  onUpdate: typeof updateCommissionsUnitActions.request;
  onFormCancel(): void;
}

const CommissionsUnitForm = ({ batch, unit, visible, onCreate, onUpdate, onFormCancel }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "commissions.batch.attrs.unit", requests.CREATE_COMMISSIONS_UNIT);
  useFormErrorHandler(form, "commissions.batch.attrs.unit", requests.UPDATE_COMMISSIONS_UNIT);

  useEffect(() => {
    if ( visible && unit ) {
      form.setFieldsValue({ ...unit, institutionId: unit.institution.id });
    }
  }, [visible, unit, form]);

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
  };

  const createInProgress = useRequestFinishedCallback(requests.CREATE_COMMISSIONS_UNIT, handleFormCancel);
  const updateInProgress = useRequestFinishedCallback(requests.UPDATE_COMMISSIONS_UNIT, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: CreateCommissionsUnit | UpdateCommissionsUnit | Store) => {
        if ( unit ) {
          onUpdate({ id1: batch.id, id2: unit.id, object: values as UpdateCommissionsUnit });
        }
        else {
          onCreate({ id: batch.id, object: values as CreateCommissionsUnit });
        }
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 8;

  return (
    <Modal
      width={700}
      visible={visible}
      title={unit ? t("commissions.batch.titles.updateUnit") : t("commissions.batch.titles.createUnit")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={createInProgress || updateInProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="commissionsUnitForm">
        <HiddenInput name="optimisticLockVersion" />

        {!unit && (
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <InstitutionsEnumFormItemSelect
                formItemProps={{
                  name: "institutionId",
                  label: t("commissions.batch.attrs.unit.institutionId"),
                  rules: [validations.notNull]
                }}
                optionsProps={{
                  groupByType: true,
                  filter: institution => !batch.commissionsUnits.flatMap(u => u.institution).some(i => i.id === institution.id)
                }} />
            </Col>
          </Row>
        )}

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="bankAccountCommissionAmount"
              label={t("commissions.batch.attrs.unit.bankAccountCommissionAmount")}
              rules={[validations.minNumber(0)]}>
              <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="invoiceCommissionAmount"
              label={t("commissions.batch.attrs.unit.invoiceCommissionAmount")}
              rules={[validations.minNumber(0)]}>
              <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="volumeCommissionAmount"
              label={t("commissions.batch.attrs.unit.volumeCommissionAmount")}
              rules={[validations.minNumber(0)]}>
              <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
};

export default CommissionsUnitForm;
