import React from "react";
import { Col, Divider, Input, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { ClientType } from "../../../../../../client/enums";
import { ClientExperience } from "../../../../../../contract/enums";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import validations from "../../../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

export interface Props {
  form: WrappedFormUtils;
  insurerType: ClientType;
}

const TravelGenInformationObligationSection = (props: Props) => {

  const colSpan = 6;
  const { getFieldDecorator } = props.form;

  return (
    <>
      <Divider>{t("calc.travel.sections.informationObligation")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("contract.enums.clientExperience._label")}>
            {getFieldDecorator("informationObligationData.clientExperience", {
              rules: [validations.notNull],
              initialValue: props.insurerType === ClientType.SELF_EMPLOYED || props.insurerType === ClientType.LEGAL
                ? ClientExperience.SUFFICIENT : undefined
            })(
              <Select
                {...selectStandardProps}
                disabled={props.insurerType === ClientType.SELF_EMPLOYED || props.insurerType === ClientType.LEGAL}
                options={Object.keys(ClientExperience).map(experience => ({
                  value: experience,
                  label: t("contract.enums.clientExperience." + experience)
                }))} />
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item label={t("calc.travel.attrs.informationObligationData.otherRequirements")}>
            {getFieldDecorator("informationObligationData.otherRequirements", { rules: [validations.none] })(
              <Input />
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item label={t("calc.travel.attrs.informationObligationData.otherRecommendationReasons")}>
            {getFieldDecorator("informationObligationData.otherRecommendationReasons", { rules: [validations.none] })(
              <Input />
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item label={t("calc.travel.attrs.informationObligationData.recommendedInsuranceRejectionReason")}>
            {getFieldDecorator("informationObligationData.recommendedInsuranceRejectionReason", { rules: [validations.none] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default TravelGenInformationObligationSection;
