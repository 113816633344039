import React from "react";
import { Col, Divider, Row, Tooltip } from "antd";
import { ClientFormStage } from "../enums";
import t from "../../../app/i18n";

import ActionTextIcon from "../../../common/components/icons/ActionTextIcon";

export interface Props {
  label: string;
  stage: ClientFormStage;
  optional?: boolean;
  deleteActionType?: "delete" | "deselect";
  onCreateClick(): void;
  onUpdateClick(): void;
  onDeleteClick?(): void;
}

const ClientActionsFormLabel = ({ label, stage, optional, deleteActionType, ...props }: Props) => {

  let actions = null;
  switch ( stage ) {
    case ClientFormStage.EMPTY:
      actions = props.onDeleteClick && (
        <Tooltip title={t("client.actions.deleteClient")}>
          <span><ActionTextIcon type="close" color="red" size="large" onClick={props.onDeleteClick} /></span>
        </Tooltip>
      );
      break;
    case ClientFormStage.NEW:
      actions = <Tooltip title={t("client.actions.createClient")}>
        <span><ActionTextIcon type="user-add" color="green" size="large" onClick={props.onCreateClick} /></span>
      </Tooltip>;
      break;
    case ClientFormStage.EXISTING:
    case ClientFormStage.SELECTED:
      actions = <>
        <Tooltip title={t("client.actions.updateClient")}>
          <span><ActionTextIcon type="edit" color="blue" size="large" onClick={props.onUpdateClick} /></span>
        </Tooltip>
        {props.onDeleteClick && (
          <>
            <Divider type="vertical" />
            <Tooltip
              title={deleteActionType === "delete" ? t("client.actions.deleteClient") : t("client.actions.deselectClient")}>
              <span><ActionTextIcon type="close" color="red" size="large" onClick={props.onDeleteClick} /></span>
            </Tooltip>
          </>
        )}
      </>;
      break;
  }

  return (
    <Row>
      <Col span={16} className={!optional ? "ant-legacy-form-item-required" : undefined}>{label}</Col>
      <Col span={8} className="right-align">{actions}</Col>
    </Row>
  );
};

export default ClientActionsFormLabel;
