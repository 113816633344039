import React from "react";
import { Checkbox, Col, Input, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { GetFieldDecoratorOptions, WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { ContactType } from "../enums";
import validations from "../../utils/validationUtils";
import { phoneNumberNormalizeFunction } from "../../utils/formUtils";
import { contains, containsAny } from "../../utils/utils";
import { rowGutter } from "../../constants";
import t from "../../../app/i18n";

import ContactTypeIcon from "./ContactTypeIcon";
import ContactTypePopover from "./ContactTypePopover";
import DeleteIcon from "../../components/icons/DeleteIcon";
import LabelWithTooltip from "../../components/form/labels/LabelWithTooltip";

export interface Props {
  form: WrappedFormUtils;
  index: number;
  selectedContactType: ContactType;
  contactTypeOptions: ContactType[];
  disabledContactTypeOptions: ContactType[];
  showUseForNotificationsItem: boolean;
  onContactTypeChange(index: number, contactType: ContactType): void;
  onDelete(index: number): void;
}

const ContactRowFormItem_Deprecated = (props: Props) => {

  const handleContactTypeChange = (contactType: ContactType): void => {
    props.onContactTypeChange(props.index, contactType);
  };

  const { index, showUseForNotificationsItem, selectedContactType } = props;
  const { getFieldDecorator } = props.form;

  const colSpan = 6;
  const smallColSpan = 5;

  const contactTypeLabel = containsAny(props.contactTypeOptions, ContactType.BROKER_EMAIL, ContactType.BROKER_PHONE_NUMBER)
    ? <span>{t("contact.enums.type._label")} <ContactTypePopover /></span>
    : t("contact.enums.type._label");

  const contactValueOptions: GetFieldDecoratorOptions = {
    normalize: value =>
      selectedContactType === ContactType.PHONE_NUMBER || selectedContactType === ContactType.BROKER_PHONE_NUMBER
        ? phoneNumberNormalizeFunction(value)
        : value
  };

  switch ( selectedContactType ) {
    case ContactType.EMAIL:
    case ContactType.BROKER_EMAIL:
      contactValueOptions.rules = [validations.notBlank, validations.size(1, 254), validations.email];
      break;
    case ContactType.PHONE_NUMBER:
    case ContactType.BROKER_PHONE_NUMBER:
      contactValueOptions.rules = [validations.notBlank, validations.size(1, 255), validations.phoneNumber];
      break;
    default:
      contactValueOptions.rules = [validations.notBlank, validations.size(1, 255)];
      break;
  }

  const useForNotificationsFormItem =
    <Form.Item className="form-item-without-label">
      {getFieldDecorator(`contacts[${index}].useForNotifications`, {
        rules: [validations.none], valuePropName: "checked", initialValue: false
      })(
        <Checkbox>
          <LabelWithTooltip label={t("contact.attrs.useForNotifications")}
                            tooltip={t("contact.helpers.useForNotificationsDesc")} />
        </Checkbox>
      )}
    </Form.Item>;

  return (
    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item label={contactTypeLabel}>
          {getFieldDecorator(`contacts[${index}].type`, {
            rules: [validations.notNull], initialValue: selectedContactType
          })(
            <Select
              options={props.contactTypeOptions.map(type => ({
                value: type,
                label: <><ContactTypeIcon type={type} /> {t("contact.enums.type." + type)}</>,
                disabled: contains(props.disabledContactTypeOptions, type)
              }))}
              onChange={handleContactTypeChange} />
          )}
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item label={t("contact.attrs.value")}>
          {getFieldDecorator(`contacts[${index}].value`, contactValueOptions)(
            <Input />
          )}
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item label={t("contact.attrs.description")}>
          {getFieldDecorator(`contacts[${index}].description`, { rules: [validations.size(1, 255)] })(
            <Input />
          )}
        </Form.Item>
      </Col>

      {showUseForNotificationsItem && (
        <Col span={smallColSpan}>
          {useForNotificationsFormItem}
        </Col>
      )}

      <Col span={1}>
        <DeleteIcon index={index} onClick={props.onDelete} />
      </Col>

      {!showUseForNotificationsItem && (
        <Col span={smallColSpan} style={{ visibility: "hidden" }}>
          {useForNotificationsFormItem}
        </Col>
      )}
    </Row>
  )
};

export default ContactRowFormItem_Deprecated;
