import i18next from "i18next";
import intervalPluralPostProcessor from "i18next-intervalplural-postprocessor";
import locales from "./locales";
import { isLocalhostDevMode } from "../../common/utils/utils";

import { Locale } from "antd/lib/locale-provider";
import sk_SK from "antd/lib/locale-provider/sk_SK";
import en_US from "antd/lib/locale-provider/en_US";

import moment from "moment";
import "moment/locale/sk";

const COMMON_NAMESPACE = "assure";
const resources = {};

Object.keys(locales).forEach(locale => {
  resources[locale] = {
    [COMMON_NAMESPACE]: locales[locale]
  }
});

i18next
  .use(intervalPluralPostProcessor)
  .init({
    defaultNS: COMMON_NAMESPACE,
    ns: COMMON_NAMESPACE,
    lng: "sk",
    fallbackLng: "en",
    debug: isLocalhostDevMode(),
    resources: resources,
    returnEmptyString: false,
    keySeparator: "."
  });

export const currentLanguage = (): string => i18next.language;

export const antDesignLocale = (): Locale => currentLanguage() === "sk" ? sk_SK : en_US;

export default i18next.t.bind(i18next);

moment.locale(currentLanguage());
moment.fn.toJSON = function () {
  return this.format("YYYY-MM-DDTHH:mm:ss.SSS");
};

// TODO (i18n) - upravit pouzitie i18next podla aktualnej dokumentacie
//  - https://github.com/i18next/react-i18next/tree/master/example/react
//  - https://react.i18next.com/legacy-v9/trans-component
