import React from "react";
import { Moment } from "moment";
import ReactQuill from "react-quill";
import { Card, Col, DatePicker, Form, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { CreateUpdateInsuranceContract, InsuranceContract } from "../../../../types";
import {
  ContractReactionStatus,
  ContractStatus,
  InsuranceContractInsurancePeriod,
  InsuranceContractPaymentFrequency,
  InsuranceContractPeriodType
} from "../../../../enums";
import { Permission } from "../../../../../../common/security/authorization/enums";
import {
  calculateInsuranceContractStatus,
  calculateInsurancePeriodEndDate,
  calculateLastContractCancellationDate,
  calculatePartialPremium
} from "../../../../utils";
import { valueToFloat } from "../../../../../../common/utils/utils";
import { validations } from "../../../../../../common/utils/validationUtils";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  disableDatePickerOutOfMaxDateIncluded,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  quillEditorStandardProps,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import ContractFormPersonsSection from "../../ContractFormPersonsSection";
import InputNumberWithAddon from "../../../../../../common/components/form/components/InputNumberWithAddon";
import ContractStatusTag from "../../../ContractStatusTag";

export interface Props {
  initialContract?: InsuranceContract;
  form: FormInstance;
  insurancesCount: number;
}

const InsuranceContractFormDataSection = ({ initialContract, form, insurancesCount }: Props) => {

  const handlePeriodTypeChange = (periodType: InsuranceContractPeriodType): void => {
    if ( periodType === InsuranceContractPeriodType.INDEFINITE ) {
      form.setFieldsValue({
        insurancePeriod: InsuranceContractInsurancePeriod.TECHNICAL_YEAR,
        effectiveEndDate: null
      });
    }
    else {
      form.setFieldsValue({ insurancePeriod: InsuranceContractInsurancePeriod.FIXED });
    }
    setContractSpecificDates(getContractStatus());
  }

  const handleInsurancePeriodChange = (): void => {
    setContractSpecificDates(getContractStatus());
  }

  const handlePaymentFrequencyChange = (frequency: InsuranceContractPaymentFrequency): void => {
    const { annualPremium, insurances } = form.getFieldsValue([["annualPremium"], ["insurances"]]) as CreateUpdateInsuranceContract;
    form.setFieldsValue({
      partialPremium: calculatePartialPremium(annualPremium, frequency),
      insurances: (insurances || []).map(insurance => ({
        ...insurance,
        partialPremium: calculatePartialPremium(insurance?.annualPremium, frequency)
      }))
    });
  }

  const handleAnnualPremiumChange = (annualPremium: number | string): void => {
    form.setFieldsValue({
      partialPremium: calculatePartialPremium(valueToFloat(annualPremium), form.getFieldValue("paymentFrequency"))
    });
  }

  const handleStatusDefiningDateChange = (): void => {
    const status = getContractStatus();
    form.setFieldsValue({ status });
    setContractSpecificDates(status);
  }

  const getContractStatus = (): ContractStatus => {
    const { effectiveBeginningDate, effectiveEndDate, cancellationDate, transferredToOtherBrokerDate } = form.getFieldsValue(
      [["effectiveBeginningDate"], ["effectiveEndDate"], ["cancellationDate"], ["transferredToOtherBrokerDate"]]
    ) as CreateUpdateInsuranceContract;
    return calculateInsuranceContractStatus(effectiveBeginningDate as Moment, effectiveEndDate as Moment,
      cancellationDate as Moment, transferredToOtherBrokerDate as Moment);
  }

  const setContractSpecificDates = (status: ContractStatus): void => {
    const { effectiveBeginningDate, effectiveEndDate, cancellationDate, periodType, insurancePeriod } = form.getFieldsValue(
      [["effectiveBeginningDate"], ["effectiveEndDate"], ["cancellationDate"], ["periodType"], ["insurancePeriod"]]
    ) as CreateUpdateInsuranceContract;

    const insurancePeriodEndDate = calculateInsurancePeriodEndDate(effectiveBeginningDate as Moment,
      effectiveEndDate as Moment, cancellationDate as Moment, periodType, insurancePeriod);
    const lastContractCancellationDate = calculateLastContractCancellationDate(status, effectiveBeginningDate as Moment,
      cancellationDate as Moment, insurancePeriodEndDate, periodType);

    form.setFieldsValue({ insurancePeriodEndDate, lastContractCancellationDate });
  }

  const colSpan = 4;

  return (
    <Card
      size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}
      extra={
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
          {({ getFieldValue }) => <ContractStatusTag status={getFieldValue("status")} />}
        </Form.Item>
      }>

      <ContractFormPersonsSection
        initialContract={initialContract}
        changePersonsPermission={Permission.CHANGE_PERSONS_ON_VERIFIED_INSURANCE} />

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="effectiveBeginningDate"
            label={t("contract.attrs.effectiveBeginningDate")}
            rules={[validations.notNull]}>
            <DatePicker {...datePickerStandardProps} onChange={handleStatusDefiningDateChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="periodType"
            label={t("contract.enums.periodType._label")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(InsuranceContractPeriodType).map(type => ({
                value: type, label: t("contract.enums.periodType." + type)
              }))}
              onChange={handlePeriodTypeChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.effectiveBeginningDate !== next.effectiveBeginningDate || prev.periodType !== next.periodType}>
            {({ getFieldValue }) => {
              const beginningDate = getFieldValue("effectiveBeginningDate") as Moment;
              const periodType = getFieldValue("periodType") as InsuranceContractPeriodType;
              const rules: Rule[] = periodType === InsuranceContractPeriodType.DEFINITE ? [validations.notNull] : [validations.none];
              if ( beginningDate ) {
                rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
              }
              return (
                <Form.Item
                  name="effectiveEndDate"
                  label={t("contract.attrs.effectiveEndDate")}
                  rules={rules}>
                  <DatePicker
                    {...datePickerStandardProps}
                    disabled={periodType === InsuranceContractPeriodType.INDEFINITE}
                    disabledDate={current => beginningDate ? disableDatePickerOutOfMinDate(current, beginningDate) : false}
                    onChange={handleStatusDefiningDateChange} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.effectiveBeginningDate !== next.effectiveBeginningDate || prev.effectiveEndDate !== next.effectiveEndDate}>
            {({ getFieldValue }) => {
              const beginningDate = getFieldValue("effectiveBeginningDate") as Moment;
              const endDate = getFieldValue("effectiveEndDate") as Moment;
              const rules = [];
              if ( beginningDate ) {
                rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
              }
              if ( endDate ) {
                rules.push(validations.notSameOrAfter(endDate, t("contract.attrs.effectiveEndDate")));
              }
              return (
                <Form.Item
                  name="cancellationDate"
                  label={t("contract.attrs.cancellationDate")}
                  rules={rules.length > 0 ? rules : [validations.none]}>
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current => (beginningDate && disableDatePickerOutOfMinDate(current, beginningDate))
                      || (endDate && disableDatePickerOutOfMaxDateIncluded(current, endDate))}
                    onChange={handleStatusDefiningDateChange} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.effectiveBeginningDate !== next.effectiveBeginningDate}>
            {({ getFieldValue }) => {
              const beginningDate = getFieldValue("effectiveBeginningDate") as Moment;
              return (
                <Form.Item
                  name="transferredToOtherBrokerDate"
                  label={t("contract.attrs.transferredToOtherBrokerDate")}
                  rules={beginningDate
                    ? [validations.notSameOrBefore(beginningDate, t("contract.attrs.effectiveBeginningDate"))]
                    : [validations.none]}>
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current => beginningDate ? disableDatePickerOutOfMinDateIncluded(current, beginningDate) : false}
                    onChange={handleStatusDefiningDateChange} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.periodType !== next.periodType}>
            {({ getFieldValue }) => {
              const periodType = getFieldValue("periodType") as InsuranceContractPeriodType;
              const options = [];
              if ( periodType === InsuranceContractPeriodType.INDEFINITE ) {
                options.push(InsuranceContractInsurancePeriod.TECHNICAL_YEAR, InsuranceContractInsurancePeriod.CALENDAR_YEAR);
              }
              else if ( periodType === InsuranceContractPeriodType.DEFINITE ) {
                options.push(InsuranceContractInsurancePeriod.FIXED);
              }
              return (
                <Form.Item
                  name="insurancePeriod"
                  label={t("contract.enums.insurancePeriod._label")}
                  rules={[validations.notNull]}>
                  <Select
                    {...selectStandardProps}
                    options={options.map(period => ({
                      value: period, label: t("contract.enums.insurancePeriod." + period)
                    }))}
                    onChange={handleInsurancePeriodChange} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="paymentFrequency"
            label={t("contract.enums.paymentFrequency._label")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(InsuranceContractPaymentFrequency).map(frequency => ({
                value: frequency, label: t("contract.enums.paymentFrequency." + frequency)
              }))}
              onChange={handlePaymentFrequencyChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="annualPremium"
            label={t("contract.attrs.annualPremium")}
            rules={[validations.notNull, validations.minNumber(0)]}>
            <InputNumberWithAddon
              addonType="euro" formatStyle="decimal" min={0.01} disabled={insurancesCount > 1}
              onChange={handleAnnualPremiumChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="partialPremium"
            label={t("contract.attrs.partialPremium")}
            rules={[validations.notNull, validations.minNumber(0)]}>
            <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0.01} disabled />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="insurancePeriodEndDate"
            label={t("contract.attrs.insurancePeriodEndDate")}
            rules={[validations.notNull]}>
            <DatePicker {...datePickerStandardProps} disabled />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="lastContractCancellationDate"
            label={t("contract.attrs.lastContractCancellationDate")}
            rules={[validations.none]}>
            <DatePicker {...datePickerStandardProps} disabled />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="reactionStatus"
            label={t("contract.enums.reactionStatus._label")}
            rules={[validations.notNull]}
            initialValue={ContractReactionStatus.CONTACT_CLIENT}>
            <Select
              {...selectStandardProps}
              options={Object.keys(ContractReactionStatus).map(status => ({
                value: status, label: t("contract.enums.reactionStatus." + status)
              }))} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="note"
            label={t("contract.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={null}>
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>

    </Card>
  )
}

export default InsuranceContractFormDataSection;
