import React from "react";
import { Input } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import validations from "../../../utils/validationUtils";

export interface Props {
  form: WrappedFormUtils;
  formKey: string;
  initialValue?: string;
}

const HiddenInput_Deprecated = ({ form, formKey, initialValue }: Props) => (
  <Form.Item className="display-none">
    {form.getFieldDecorator(formKey, { rules: [validations.none], initialValue })(<Input type="hidden" />)}
  </Form.Item>
);

export default HiddenInput_Deprecated;
