import React from "react";
import { Tag } from "antd";
import { OperationType } from "../enums";
import t from "../../../../app/i18n";

export interface Props {
  type: OperationType;
}

const OperationTypeTag = ({ type }: Props) => {
  let color: string;

  switch ( type ) {
    case OperationType.CALCULATE:
      color = "blue";
      break;
    case OperationType.RECALCULATE:
      color = "green";
      break;
    case OperationType.GENERATE:
      color = "magenta";
      break;
    default:
      return null;
  }

  return <Tag color={color}>{t("calc.records.enums.operationType." + type)}</Tag>
};

export default OperationTypeTag;
