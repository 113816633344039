import React from "react";
import { ContactsOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { ContactType } from "../enums";

export interface Props {
  type: ContactType;
}

const ContactTypeIcon = ({ type }: Props) => {
  switch ( type ) {
    case ContactType.EMAIL:
    case ContactType.BROKER_EMAIL:
      return <MailOutlined />;
    case ContactType.PHONE_NUMBER:
    case ContactType.BROKER_PHONE_NUMBER:
      return <PhoneOutlined />;
    case ContactType.OTHER:
      return <ContactsOutlined />;
    default:
      return null;
  }
};

export default ContactTypeIcon;
