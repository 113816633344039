import { combineReducers } from "redux";
import { replace, RouterActionType } from "connected-react-router";
import { put, select, takeLatest } from "redux-saga/effects";
import { Hash, Location, LocationKey, Pathname, Search } from "history";
import { ApiReducerState, RequestAction, RunningRequest, ValidationErrorResponse } from "./types";
import { ApiRequest, RootState } from "../common/types";
import { HttpStatus } from "../common/constants";
import { createActionCreator, createActionType, createReducer } from "../common/utils/reduxUtils";

import authRequests from "./auth/apiRequests";
import { requests as clientRequests } from "./client/api";
import { requests as personRequests } from "./person/api";
import { requests as institutionRequests } from "./admin/institution/api";
import { requests as calcRequests } from "./calculator/calcs/api";
import { requests as vehicleRequests } from "./calculator/vehicles/api";

/**
 * CONSTANTS
 * runningRequestsCountExclusions - array of identifiers of requests which should not be added to global running requests count
 */
const runningRequestsCountExclusions: Array<ApiRequest> = [
  authRequests.REFRESH_TOKEN,
  clientRequests.GET_CLIENT,
  clientRequests.AUTOCOMPLETE_CLIENTS,
  clientRequests.SEARCH_CLIENT,
  clientRequests.VALIDATE_CLIENT,
  clientRequests.VALIDATE_CLIENTS,
  personRequests.GET_PERSON,
  institutionRequests.GET_INSTITUTION,
  calcRequests.CALCULATE,
  calcRequests.GENERATE,
  vehicleRequests.AUTOCOMPLETE_VEHICLES,
  vehicleRequests.GET_VEHICLE_PRICE
];

/**
 * ACTION TYPES
 */
export enum actionType {
  CHANGE_LOCATION_KEY = 'router/CHANGE_LOCATION_KEY',
  START_REQUEST = 'api/START_REQUEST',
  FINISH_REQUEST = 'api/FINISH_REQUEST',
  DELETE_STATE_VALIDATION_ERROR_RESPONSE = 'api/DELETE_STATE_VALIDATION_ERROR_RESPONSE'
}

/**
 * ACTIONS
 */
export const changeLocationKeyAction = createActionCreator<void>(actionType.CHANGE_LOCATION_KEY);
export const startRequestAction = createActionCreator<RequestAction>(actionType.START_REQUEST);
export const finishRequestAction = createActionCreator<RequestAction>(actionType.FINISH_REQUEST);

export const deleteStateValidationErrorResponseAction = createActionCreator<void>(actionType.DELETE_STATE_VALIDATION_ERROR_RESPONSE);

/**
 * REDUCERS
 */
const initialSate: ApiReducerState = {
  runningRequestsCount: 0,
  runningRequests: {},
  validationErrorResponse: null
};

const runningRequestsCountReducer = createReducer<number, RequestAction>(initialSate.runningRequestsCount, {
  [actionType.START_REQUEST]: (state, payload) => {
    return shouldAddToRunningRequestsCount(payload.requestIdentifier) ? state + 1 : state;
  },
  [actionType.FINISH_REQUEST]: (state, payload) => {
    return shouldAddToRunningRequestsCount(payload.requestIdentifier) && state > 0 ? state - 1 : state;
  }
});

const runningRequestsReducer = createReducer<RunningRequest, RequestAction>(initialSate.runningRequests, {
  [actionType.START_REQUEST]: (state, payload) => ({
    ...state,
    [payload.requestIdentifier]: state[payload.requestIdentifier] ? state[payload.requestIdentifier] + 1 : 1
  }),
  [actionType.FINISH_REQUEST]: (state, payload) => {
    if ( state[payload.requestIdentifier] ) {
      const updatedState = {
        ...state,
        [payload.requestIdentifier]: Math.max(state[payload.requestIdentifier] - 1, 0)
      };

      if ( updatedState[payload.requestIdentifier] === 0 ) {
        delete updatedState[payload.requestIdentifier];
      }

      return updatedState;
    }
    return state;
  }
});

const validationErrorResponseReducer = createReducer<ValidationErrorResponse, RequestAction>(initialSate.validationErrorResponse, {
  [actionType.START_REQUEST]: () => initialSate.validationErrorResponse,
  [actionType.FINISH_REQUEST]: (state, payload) => {
    if ( payload.status === HttpStatus.UNPROCESSABLE_ENTITY || payload.status === HttpStatus.NOT_FOUND ) {
      return {
        id: new Date().getTime(),
        requestIdentifier: payload.requestIdentifier,
        status: payload.status,
        violations: payload.violations
      }
    }
    return initialSate.validationErrorResponse
  },
  [actionType.DELETE_STATE_VALIDATION_ERROR_RESPONSE]: () => initialSate.validationErrorResponse
});

export default combineReducers({
  runningRequestsCount: runningRequestsCountReducer,
  runningRequests: runningRequestsReducer,
  validationErrorResponse: validationErrorResponseReducer
});

/**
 * SELECTORS
 */
const selectApi = (state: RootState): ApiReducerState => state.api;

export const selectHasActiveRequest = (state: RootState): boolean => !!selectApi(state).runningRequestsCount;
export const selectIsRequestInProgress = (state: RootState, apiRequest: ApiRequest): boolean => {
  if ( apiRequest.hasParamsInUrl() ) {
    const runningRequests = selectApi(state).runningRequests;
    for ( const runningReqIdentifier of Object.keys(runningRequests) ) {
      if ( apiRequest.compareIdentifier(runningReqIdentifier) ) {
        return !!runningRequests[runningReqIdentifier];
      }
    }
    return false;
  }

  return !!selectApi(state).runningRequests[apiRequest.identifier];
};

export const selectValidationErrorResponse = (state: RootState, apiRequest: ApiRequest): ValidationErrorResponse => {
  const errorResponse = selectApi(state).validationErrorResponse;
  return errorResponse && apiRequest.compareIdentifier(errorResponse.requestIdentifier) ? errorResponse : null;
};

export const selectIsNotFoundErrorResponse = (state: RootState, apiRequest: ApiRequest): boolean => {
  const errorResponse = selectApi(state).validationErrorResponse;
  return errorResponse && apiRequest.compareIdentifier(errorResponse.requestIdentifier) && errorResponse.status === HttpStatus.NOT_FOUND;
};

export const selectRouterAction = (state: RootState): RouterActionType => state.router.action;
export const selectRouterLocation = (state: RootState): Location => state.router.location;
export const selectRouterLocationPathname = (state: RootState): Pathname => selectRouterLocation(state).pathname;
export const selectRouterLocationSearch = (state: RootState): Search => selectRouterLocation(state).search;
export const selectRouterLocationHash = (state: RootState): Hash => selectRouterLocation(state).hash;
export const selectRouterLocationKey = (state: RootState): LocationKey => selectRouterLocation(state).key;

export const selectRouterLocationSearchParam = (state: RootState, paramKey: string): string | null => {
  return new URLSearchParams(selectRouterLocationSearch(state)).get(paramKey);
};

/**
 * SAGAS
 */
function* changeLocationKey() {
  const location: Location = yield select(selectRouterLocation);
  yield put(replace(location.pathname + location.search));
}

export function* routerSaga() {
  yield takeLatest(createActionType(actionType.CHANGE_LOCATION_KEY), changeLocationKey);
}

/**
 * HELPER FUNCTIONS
 */
const shouldAddToRunningRequestsCount = (requestIdentifier: string): boolean => {
  for ( const exclusion of runningRequestsCountExclusions ) {
    if ( exclusion.compareIdentifier(requestIdentifier) ) {
      return false;
    }
  }
  return true;
};
