import React, { useState } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { AdminPersonAccount } from "../../types";
import { Person } from "../../../person/types";
import { ContactType } from "../../../../common/modules/enums";
import { createPersonAccountActions, updatePersonAccountActions } from "../../ducks";
import messageUtils from "../../../../common/utils/messageUtils";
import t from "../../../../app/i18n";

import PersonAccountsView from "../views/PersonAccountsView";
import PersonAccountCreateForm from "../forms/PersonAccountCreateForm";
import PersonAccountUpdateForm from "../forms/PersonAccountUpdateForm";

export interface Props {
  accounts: AdminPersonAccount[];
  person: Person;
  onCreateFormSubmit: typeof createPersonAccountActions.request;
  onUpdateFormSubmit: typeof updatePersonAccountActions.request;
}

const PersonAccountsPage = (props: Props) => {
  const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);
  const [updatedAccount, setUpdatedAccount] = useState<AdminPersonAccount>();

  const handleCreateAccountClick = (): void => {
    if ( props.person.contacts.some(contact => contact.type === ContactType.EMAIL || contact.type === ContactType.BROKER_EMAIL) ) {
      setCreateFormVisible(true);
    }
    else {
      messageUtils.errorMessage(t("account.helpers.noPersonEmails"));
    }
  };

  return (
    <>
      <PersonAccountsView accounts={props.accounts} onUpdateClick={setUpdatedAccount} />

      <PersonAccountCreateForm
        visible={createFormVisible}
        person={props.person}
        onFormSubmit={props.onCreateFormSubmit}
        onFormCancel={() => setCreateFormVisible(false)} />

      <PersonAccountUpdateForm
        visible={!!updatedAccount}
        account={updatedAccount}
        person={props.person}
        onFormSubmit={props.onUpdateFormSubmit}
        onFormCancel={() => setUpdatedAccount(null)} />

      <Button className="margin-top-large" type="primary" icon={<PlusOutlined />}
              onClick={handleCreateAccountClick}>
        {t("account.actions.createAccount")}
      </Button>
    </>
  );
}

export default PersonAccountsPage;
