import React, { ChangeEvent, ReactText } from "react";
import debounce from "lodash/debounce";
import { Alert, AutoComplete, Checkbox, Col, Divider, Input, InputNumber, Row, Select, Spin, } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { SelectValue } from "antd/lib/select";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { CalcDataSource } from "../../../../types";
import { Client, ClientsAutocompleteProps } from "../../../../../../client/types";
import { RealtyCalcClientsData, RealtyCalcGeneralBuildingData } from "../../../types";
import { ClientType } from "../../../../../../client/enums";
import { BuildingState, BuildingType } from "../../../enums";
import { Country } from "../../../../../../../common/modules/enums";
import validations, { regexPatterns, validationFunctions } from "../../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import ClientTypeTagWithName from "../../../../../../client/components/ClientTypeTagWithName";

export interface Props {
  form: WrappedFormUtils;
  initialClientsData: RealtyCalcClientsData;
  initialBuildingData: RealtyCalcGeneralBuildingData;
  calcDataSource: CalcDataSource;
  hasPlaceOfInsuranceError: boolean;
  clientsAutocomplete: ClientsAutocompleteProps;
  clientType: ClientType;
  insuredClient: Client;
  buildingState: BuildingState;
  onClientTypeChange(clientType: ClientType): void;
  onInsuredClientSelect(client: Client): void;
  onBuildingTypeChange(buildingType: BuildingType): void;
  onBuildingStateChange(buildingState: BuildingState): void;
  onHasPlaceOfInsuranceErrorCheck(): void;
}

interface State {
  readonly constructionYear: number;
  readonly isPlaceOfInsuranceStreetFilled: boolean;
}

const YEAR_NUMBER_INPUT_MIN = 1900;
const YEAR_NUMBER_INPUT_MAX = new Date().getFullYear();

class RealtyCalcGeneralStep extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const stateObject = {
      constructionYear: undefined,
      isPlaceOfInsuranceStreetFilled: false
    };

    if ( props.initialBuildingData ) {
      const { initialBuildingData } = props;
      stateObject.constructionYear = initialBuildingData.constructionYear;
      stateObject.isPlaceOfInsuranceStreetFilled = !!(initialBuildingData.placeOfInsurance && initialBuildingData.placeOfInsurance.street
        && initialBuildingData.placeOfInsurance.street.length > 0);
    }

    this.state = stateObject;
  }

  handleClientTypeChange = (clientType: ClientType): void => {
    this.handleClientsAutocompleteResultDelete();
    this.props.onInsuredClientSelect(null);
    this.props.onClientTypeChange(clientType);
  };

  handleClientsAutocompleteResultDelete = (): void => {
    if ( this.props.clientsAutocomplete.result.data.length > 0 ) {
      this.props.clientsAutocomplete.onResultDelete();
    }
  };

  handleClientsAutocompleteSearch = (keyword: string): void => {
    if ( keyword && validationFunctions.validateSearchKeyword(keyword) ) {
      this.props.clientsAutocomplete.onSearch({ keyword, clientType: this.props.clientType });
    }
    else {
      this.handleClientsAutocompleteResultDelete();
    }
  };

  handleClientsAutocompleteSearchDebounced = debounce(this.handleClientsAutocompleteSearch, 500);

  handleClientsAutocompleteSelect = (value: SelectValue): void => {
    this.props.onInsuredClientSelect(this.props.clientsAutocomplete.result.data.find(client => client.identifier === value));
    this.handleClientsAutocompleteResultDelete();
  };

  handleClientsAutocompleteChange = (value: SelectValue): void => {
    if ( this.props.insuredClient && this.props.insuredClient.identifier !== value ) {
      this.props.onInsuredClientSelect(null);
    }
  };

  resolveClientsAutocompleteOptions = (): DataSourceItemObject[] => {
    return this.props.clientsAutocomplete.result.data.map<DataSourceItemObject>(client => ({
      value: client.identifier,
      text: client.identifier
    }))
  };

  handleBuildingStateChange = (buildingState: BuildingState): void => {
    if ( buildingState === BuildingState.UNDER_CONSTRUCTION ) {
      this.props.form.setFieldsValue({
        "generalBuildingData.constructionYear": undefined,
        "generalBuildingData.reconstructionYear": undefined,
        "generalBuildingData.numberOfDamagesInLastTenYears": undefined
      });
      this.setState({ constructionYear: undefined });
    }
    this.props.onBuildingStateChange(buildingState);
  };

  handleConstructionYearChange = (constructionYear: ReactText): void => {
    if ( !constructionYear ) {
      this.props.form.setFieldsValue({ "generalBuildingData.reconstructionYear": undefined });
    }
    this.setState({ constructionYear: constructionYear as number });
  };

  handlePermanentlyOccupiedChange = (event: CheckboxChangeEvent): void => {
    if ( !event.target.checked ) {
      this.props.form.setFieldsValue({ "generalBuildingData.permanentlyOccupiedByDisabledPerson": false });
    }
  };

  handlePermanentlyOccupiedByDisabledPersonChange = (event: CheckboxChangeEvent): void => {
    if ( event.target.checked ) {
      this.props.form.setFieldsValue({ "generalBuildingData.permanentlyOccupied": true });
    }
  };

  handlePlaceOfInsuranceStreetChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ isPlaceOfInsuranceStreetFilled: !!(event.target.value && event.target.value.length > 0) }, () => {
      if ( this.props.buildingState === BuildingState.FINISHED ) {
        if ( this.state.isPlaceOfInsuranceStreetFilled ) {
          this.props.form.validateFields(["generalBuildingData.placeOfInsurance.descriptiveNumber"], { force: true });
        }
        else {
          this.props.form.validateFields(["generalBuildingData.placeOfInsurance.orientationNumber"], { force: true });
        }
      }
    });
  };

  handleHasPlaceOfInsuranceErrorCheck = (): void => {
    if ( this.props.hasPlaceOfInsuranceError && this.props.buildingState === BuildingState.UNDER_CONSTRUCTION ) {
      this.props.onHasPlaceOfInsuranceErrorCheck();
    }
  };

  componentDidMount(): void {
    if ( (this.props.calcDataSource === "calcData" || this.props.calcDataSource === "genData") && this.props.initialClientsData ) {
      this.handleClientsAutocompleteSearch(this.props.clientType === ClientType.NATURAL
        ? this.props.initialClientsData.insuredClientPin
        : this.props.initialClientsData.insuredClientCrn);
    }
  }

  componentWillUnmount(): void {
    this.handleClientsAutocompleteResultDelete();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { result } = this.props.clientsAutocomplete;
    if ( result.id && result.data.length === 1 && result.id !== prevProps.clientsAutocomplete.result.id ) {
      const { insuredClient } = this.props;
      const insuredClientPinCrn = this.props.clientType === ClientType.NATURAL
        ? this.props.form.getFieldValue("clientsData.insuredClientPin")
        : this.props.form.getFieldValue("clientsData.insuredClientCrn");

      if ( result.data[0].identifier === insuredClientPinCrn &&
        (!insuredClient || insuredClient.identifier !== insuredClientPinCrn) ) {
        this.props.onInsuredClientSelect(this.props.clientsAutocomplete.result.data[0]);
      }
    }
  }

  render(): React.ReactNode {
    const { hasPlaceOfInsuranceError, clientType, insuredClient, buildingState } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { constructionYear, isPlaceOfInsuranceStreetFilled } = this.state;

    const colSpan = 8;

    return (
      <>
        <Row gutter={rowGutter}>
          <Divider>{t("calc.realty.sections.client")}</Divider>

          <Col span={colSpan}>
            <Form.Item label={t("client.enums.type._label")}>
              {getFieldDecorator("clientsData.insuredClientType", {
                rules: [validations.notNull], initialValue: clientType
              })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(ClientType).map(type => ({
                    value: type,
                    label: t("client.enums.type." + type)
                  }))}
                  onChange={this.handleClientTypeChange} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            {clientType === ClientType.NATURAL ? (
              <Spin spinning={this.props.clientsAutocomplete.inProgress}>
                <Form.Item label={t("calc.realty.attrs.clientsData.insuredClientPin")}>
                  {getFieldDecorator("clientsData.insuredClientPin", { rules: [validations.notBlank, validations.pin] })(
                    <AutoComplete
                      dropdownMatchSelectWidth={false}
                      onSearch={this.handleClientsAutocompleteSearchDebounced}
                      onSelect={this.handleClientsAutocompleteSelect}
                      onChange={this.handleClientsAutocompleteChange}
                      options={this.resolveClientsAutocompleteOptions()} />
                  )}
                </Form.Item>
              </Spin>
            ) : (
              <Spin spinning={this.props.clientsAutocomplete.inProgress}>
                <Form.Item label={t("calc.realty.attrs.clientsData.insuredClientCrn")}>
                  {getFieldDecorator("clientsData.insuredClientCrn", { rules: [validations.notBlank, validations.crn] })(
                    <AutoComplete
                      dropdownMatchSelectWidth={false}
                      onSearch={this.handleClientsAutocompleteSearchDebounced}
                      onSelect={this.handleClientsAutocompleteSelect}
                      onChange={this.handleClientsAutocompleteChange}
                      options={this.resolveClientsAutocompleteOptions()} />
                  )}
                </Form.Item>
              </Spin>
            )}
          </Col>
          <Col span={colSpan} className="form-item-without-label sub-header-info">
            <ClientTypeTagWithName client={insuredClient} />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Divider>{t("calc.realty.sections.generalBuildingData")}</Divider>

          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.enums.buildingType._label")}>
              {getFieldDecorator("generalBuildingData.type", { rules: [validations.notNull] })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(BuildingType).map(type => ({
                    value: type,
                    label: t("calc.realty.enums.buildingType." + type)
                  }))}
                  onChange={this.props.onBuildingTypeChange} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.enums.buildingState._label")}>
              {getFieldDecorator("generalBuildingData.state", {
                rules: [validations.notNull], initialValue: buildingState
              })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(BuildingState).map(state => ({
                    value: state,
                    label: t("calc.realty.enums.buildingState." + state)
                  }))}
                  onChange={this.handleBuildingStateChange} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={<LabelWithTooltip label={t("calc.realty.attrs.generalBuildingData.constructionYear")}
                                                tooltip={t("calc.realty.helpers.constructionYear")} />}>
              {getFieldDecorator("generalBuildingData.constructionYear", {
                rules: [buildingState === BuildingState.FINISHED ? validations.notNull : validations.none],
                initialValue: constructionYear
              })(
                <InputNumber
                  min={YEAR_NUMBER_INPUT_MIN}
                  max={YEAR_NUMBER_INPUT_MAX}
                  onChange={this.handleConstructionYearChange}
                  disabled={buildingState === BuildingState.UNDER_CONSTRUCTION} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={<LabelWithTooltip label={t("calc.realty.attrs.generalBuildingData.reconstructionYear")}
                                                tooltip={t("calc.realty.helpers.reconstructionYear")} />}>
              {getFieldDecorator("generalBuildingData.reconstructionYear", { rules: [validations.none] })(
                <InputNumber
                  min={constructionYear ? constructionYear + 1 : YEAR_NUMBER_INPUT_MIN}
                  max={YEAR_NUMBER_INPUT_MAX}
                  disabled={buildingState === BuildingState.UNDER_CONSTRUCTION || !constructionYear} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label={<LabelWithTooltip label={t("calc.realty.attrs.generalBuildingData.numberOfDamagesInLastTenYears")}
                                       tooltip={t("calc.realty.helpers.numberOfDamages")} />}>
              {getFieldDecorator("generalBuildingData.numberOfDamagesInLastTenYears", { rules: [validations.none] })(
                <InputNumber min={0} disabled={buildingState === BuildingState.UNDER_CONSTRUCTION} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.generalBuildingData.numberOfFloodsInLastTenYears")}>
              {getFieldDecorator("generalBuildingData.numberOfFloodsInLastTenYears", { rules: [validations.none] })(
                <InputNumber min={0} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item>
              {getFieldDecorator("generalBuildingData.inCity", {
                rules: [validations.none], valuePropName: "checked", initialValue: true
              })(
                <Checkbox>{t("calc.realty.attrs.generalBuildingData.inCity")}</Checkbox>
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item>
              {getFieldDecorator("generalBuildingData.permanentlyOccupied", {
                rules: [validations.none], valuePropName: "checked", initialValue: true
              })(
                <Checkbox onChange={this.handlePermanentlyOccupiedChange}>
                  <LabelWithTooltip label={t("calc.realty.attrs.generalBuildingData.permanentlyOccupied")}
                                    tooltip={t("calc.realty.helpers.permanentlyOccupied")} />
                </Checkbox>
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item>
              {getFieldDecorator("generalBuildingData.permanentlyOccupiedByDisabledPerson", {
                rules: [validations.none], valuePropName: "checked", initialValue: false
              })(
                <Checkbox onChange={this.handlePermanentlyOccupiedByDisabledPersonChange}>
                  <LabelWithTooltip
                    label={t("calc.realty.attrs.generalBuildingData.permanentlyOccupiedByDisabledPerson")}
                    tooltip={t("calc.realty.helpers.permanentlyOccupiedByDisabledPerson")} />
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Divider>{t("calc.realty.attrs.generalBuildingData.placeOfInsurance._label")}</Divider>
          {hasPlaceOfInsuranceError && (
            <Col span={24} className="margin-bottom-medium">
              <Alert type="error" showIcon message={isPlaceOfInsuranceStreetFilled
                ? t("calc.realty.validations.invalidPlaceOfInsuranceOrientationNumber")
                : t("calc.realty.validations.invalidPlaceOfInsuranceDescriptionNumber")} />
            </Col>
          )}

          <Col span={12}>
            <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.street")}>
              {getFieldDecorator("generalBuildingData.placeOfInsurance.street", {
                rules: [validations.size(1, 255), validations.pattern(regexPatterns.streetRegex)]
              })(
                <Input onChange={this.handlePlaceOfInsuranceStreetChange} />
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.descriptiveNumber")}>
              {getFieldDecorator("generalBuildingData.placeOfInsurance.descriptiveNumber", {
                rules: [
                  buildingState === BuildingState.FINISHED && !isPlaceOfInsuranceStreetFilled ? validations.notBlank : validations.none,
                  validations.size(1, 64),
                  validations.pattern(regexPatterns.streetNumberRegex)
                ]
              })(
                <Input onChange={this.handleHasPlaceOfInsuranceErrorCheck} />
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.orientationNumber")}>
              {getFieldDecorator("generalBuildingData.placeOfInsurance.orientationNumber", {
                rules: [
                  buildingState === BuildingState.FINISHED && isPlaceOfInsuranceStreetFilled ? validations.notBlank : validations.none,
                  validations.size(1, 64),
                  validations.pattern(regexPatterns.streetNumberRegex)
                ]
              })(
                <Input onChange={this.handleHasPlaceOfInsuranceErrorCheck} />
              )}
            </Form.Item>
          </Col>
          {buildingState === BuildingState.UNDER_CONSTRUCTION && (
            <>
              <Col span={3}>
                <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.parcelNumber1")}>
                  {getFieldDecorator("generalBuildingData.placeOfInsurance.parcelNumber1", {
                    rules: [validations.size(1, 64), validations.pattern(regexPatterns.parcelNumberRegex)]
                  })(
                    <Input onChange={this.handleHasPlaceOfInsuranceErrorCheck} />
                  )}
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.parcelNumber2")}>
                  {getFieldDecorator("generalBuildingData.placeOfInsurance.parcelNumber2", {
                    rules: [validations.size(1, 64), validations.pattern(regexPatterns.parcelNumberRegex)]
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        <Row gutter={rowGutter}>
          <Col span={3}>
            <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.zipCode")}>
              {getFieldDecorator("generalBuildingData.placeOfInsurance.zipCode", {
                rules: [validations.notBlank, validations.length(5), validations.numeric]
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.city")}>
              {getFieldDecorator("generalBuildingData.placeOfInsurance.city", {
                rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.country")}>
              {getFieldDecorator("generalBuildingData.placeOfInsurance.country", {
                rules: [validations.notNull], initialValue: Country.SVK
              })(
                <Select
                  {...selectStandardProps} disabled
                  options={Object.keys(Country).map(country => ({ value: country, label: country }))} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
}

export default RealtyCalcGeneralStep;
