import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import {
  ChangeCommissionsBatchStepRequest,
  CommissionsBatch,
  CommissionsBatchInputAttachment,
  CommissionsFilterPageRequest,
  CommissionsFilterPageResult,
  CommissionsUnit,
  CreateCommissionsUnit,
  CreateImportedCommission,
  CreateUpdateCommissionsBatch,
  DeleteCommissionsUnitRequest,
  ImportedCommission,
  IncludeImportedCommission,
  PostponeImportedCommission,
  UpdateCalculatedCommissionList,
  UpdateCommissionsUnit,
  UpdateImportedCommission
} from "./types";
import {
  EntityIdRequest,
  EntityObjectRequest,
  PageRequest,
  PageResult,
  TwoLevelEntityIdRequest,
  TwoLevelEntityObjectRequest
} from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";

const FILTER_COMMISSIONS_BATCHES = ApiRequestAdapter.create("/commissions-batches", "GET");
const GET_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches/{0}", "GET");
const CREATE_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches", "POST");
const UPDATE_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches/{0}", "PUT");
const DELETE_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches/{0}", "DELETE");
const CHANGE_COMMISSIONS_BATCH_STEP = ApiRequestAdapter.create("/commissions-batches/{0}/change-step", "POST");

const CREATE_COMMISSIONS_UNIT = ApiRequestAdapter.create("/commissions-batches/{0}/units", "POST");
const UPDATE_COMMISSIONS_UNIT = ApiRequestAdapter.create("/commissions-batches/{0}/units/{1}", "PUT");
const DELETE_COMMISSIONS_UNIT = ApiRequestAdapter.create("/commissions-batches/{0}", "DELETE");

const UPLOAD_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create("/commissions-batches/{0}/attachments", "POST");
const REPLACE_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create("/commissions-batches/{0}/attachments/{1}", "PUT");
const DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create("/commissions-batches/{0}/attachments/{1}", "GET");
const DELETE_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create("/commissions-batches/{0}/attachments/{1}", "DELETE");

const FILTER_COMMISSIONS = ApiRequestAdapter.create("/commissions-batches/{0}/commissions", "GET");
const CREATE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions", "POST");
const UPDATE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}", "PUT");
const POSTPONE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}/postpone", "PUT");
const TRY_TO_INCLUDE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}/include", "PUT");
const UPDATE_CALCULATED_COMMISSIONS = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}/calculations", "PUT");
const DELETE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}", "DELETE");

const GENERATE_COMMISSIONS_OUTPUT = ApiRequestAdapter.create("/commissions-batches/{0}/persons/{1}", "GET");

export const requests = {
  FILTER_COMMISSIONS_BATCHES,
  GET_COMMISSIONS_BATCH,
  CREATE_COMMISSIONS_BATCH,
  UPDATE_COMMISSIONS_BATCH,
  DELETE_COMMISSIONS_BATCH,
  CHANGE_COMMISSIONS_BATCH_STEP,
  CREATE_COMMISSIONS_UNIT,
  UPDATE_COMMISSIONS_UNIT,
  DELETE_COMMISSIONS_UNIT,
  UPLOAD_COMMISSIONS_BATCH_ATTACHMENT,
  REPLACE_COMMISSIONS_BATCH_ATTACHMENT,
  DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT,
  DELETE_COMMISSIONS_BATCH_ATTACHMENT,
  FILTER_COMMISSIONS,
  CREATE_COMMISSION,
  UPDATE_COMMISSION,
  POSTPONE_COMMISSION,
  TRY_TO_INCLUDE_COMMISSION,
  UPDATE_CALCULATED_COMMISSIONS,
  DELETE_COMMISSION,
  GENERATE_COMMISSIONS_OUTPUT
}

export default {
  filterCommissionsBatches: (request: PageRequest): AxiosPromise<PageResult<CommissionsBatch>> => {
    return apiService.get<PageResult<CommissionsBatch>>(FILTER_COMMISSIONS_BATCHES.url, { params: request });
  },
  getCommissionsBatch: (request: EntityIdRequest): AxiosPromise<CommissionsBatch> => {
    return apiService.get<CommissionsBatch>(GET_COMMISSIONS_BATCH.getParametrizedUrl(request.id));
  },
  createCommissionsBatch: (request: CreateUpdateCommissionsBatch): AxiosPromise<CommissionsBatch> => {
    return apiService.post<CommissionsBatch>(CREATE_COMMISSIONS_BATCH.url, request);
  },
  updateCommissionsBatch: (request: EntityObjectRequest<CreateUpdateCommissionsBatch>): AxiosPromise<CommissionsBatch> => {
    return apiService.put<CommissionsBatch>(UPDATE_COMMISSIONS_BATCH.getParametrizedUrl(request.id), request.object);
  },
  deleteCommissionsBatch: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_BATCH.getParametrizedUrl(request.id));
  },
  changeCommissionsBatchStep: (request: EntityObjectRequest<ChangeCommissionsBatchStepRequest>): AxiosPromise<CommissionsBatch> => {
    return apiService.post<CommissionsBatch>(CHANGE_COMMISSIONS_BATCH_STEP.getParametrizedUrl(request.id), null, { params: request.object });
  },
  createCommissionsUnit: (request: EntityObjectRequest<CreateCommissionsUnit>): AxiosPromise<CommissionsUnit> => {
    return apiService.post<CommissionsUnit>(CREATE_COMMISSIONS_UNIT.getParametrizedUrl(request.id), request.object);
  },
  updateCommissionsUnit: (request: TwoLevelEntityObjectRequest<UpdateCommissionsUnit>): AxiosPromise<CommissionsUnit> => {
    return apiService.put<CommissionsUnit>(UPDATE_COMMISSIONS_UNIT.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteCommissionsUnit: (request: EntityObjectRequest<DeleteCommissionsUnitRequest>): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_UNIT.getParametrizedUrl(request.id), { params: request.object });
  },
  uploadCommissionsBatchAttachment: (request: EntityObjectRequest<FormData>): AxiosPromise<CommissionsBatchInputAttachment> => {
    return apiService.post<CommissionsBatchInputAttachment>(
      UPLOAD_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id),
      request.object,
      { headers: { "Content-Type": "multipart/form-data" } });
  },
  replaceCommissionsBatchAttachment: (request: TwoLevelEntityObjectRequest<FormData>): AxiosPromise<CommissionsBatchInputAttachment> => {
    return apiService.put<CommissionsBatchInputAttachment>(
      REPLACE_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id1, request.id2),
      request.object,
      { headers: { "Content-Type": "multipart/form-data" } });
  },
  downloadCommissionsBatchAttachment: (request: TwoLevelEntityIdRequest): AxiosPromise<Blob> => {
    return apiService.get<Blob>(
      DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), { responseType: "blob" });
  },
  deleteCommissionsBatchAttachment: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  },
  filterCommissions: (request: EntityObjectRequest<CommissionsFilterPageRequest>): AxiosPromise<CommissionsFilterPageResult> => {
    return apiService.get<CommissionsFilterPageResult>(FILTER_COMMISSIONS.getParametrizedUrl(request.id), {
      params: request.object,
      paramsSerializer: serializeParams
    });
  },
  createCommission: (request: EntityObjectRequest<CreateImportedCommission>): AxiosPromise<ImportedCommission> => {
    return apiService.post<ImportedCommission>(CREATE_COMMISSION.getParametrizedUrl(request.id), request.object);
  },
  updateCommission: (request: TwoLevelEntityObjectRequest<UpdateImportedCommission>): AxiosPromise<ImportedCommission> => {
    return apiService.put<ImportedCommission>(UPDATE_COMMISSION.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  postponeCommission: (request: TwoLevelEntityObjectRequest<PostponeImportedCommission>): AxiosPromise<ImportedCommission> => {
    return apiService.put<ImportedCommission>(POSTPONE_COMMISSION.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  tryToIncludeCommission: (request: TwoLevelEntityObjectRequest<IncludeImportedCommission>): AxiosPromise<ImportedCommission> => {
    return apiService.put<ImportedCommission>(TRY_TO_INCLUDE_COMMISSION.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  updateCalculatedCommissions: (request: TwoLevelEntityObjectRequest<UpdateCalculatedCommissionList>): AxiosPromise<ImportedCommission> => {
    return apiService.put<ImportedCommission>(UPDATE_CALCULATED_COMMISSIONS.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteCommission: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSION.getParametrizedUrl(request.id1, request.id2));
  },
  generateCommissionsOutput: (request: TwoLevelEntityIdRequest): AxiosPromise<Blob> => {
    return apiService.get<Blob>(GENERATE_COMMISSIONS_OUTPUT.getParametrizedUrl(request.id1, request.id2), { responseType: "blob" });
  }
}
