import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { replace } from "connected-react-router";
import { Modal, Tabs } from "antd";
import { HistoryOutlined, SolutionOutlined } from "@ant-design/icons";
import { Contract, InsuranceContract, LoanContract } from "../types";
import { ActionProps, RootState } from "../../../common/types";
import { ContractType } from "../enums";
import {
  deleteContractActions,
  deleteContractAttachmentActions,
  deleteStateContractDetailAction,
  getContractActions,
  selectContractDetail,
  updateContractActions,
  uploadContractAttachmentsActions,
  verifyContractActions
} from "../ducks";
import { selectRouterLocationKey, selectRouterLocationSearchParam } from "../../ducks";
import { appendSearchParamsToCurrentPathname } from "../../../common/utils/utils";
import { serializeParams } from "../../../common/utils/apiUtils";
import { requests as contractRequests } from "../api";
import t from "../../../app/i18n";

import InsuranceContractDetailView from "../components/views/detail/insurance/InsuranceContractDetailView";
import InsuranceContractForm from "../components/forms/insurance/InsuranceContractForm";
import LoanContractDetailView from "../components/views/detail/loan/LoanContractDetailView";
import LoanContractForm from "../components/forms/loan/LoanContractForm";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";

interface StateProps {
  contract: Contract;
  initialUrlActiveTab: string;
  locationKey: string;
}

interface ActionsMap {
  getContract: typeof getContractActions.request;
  verifyContract: typeof verifyContractActions.request;
  updateContract: typeof updateContractActions.request;
  deleteContract: typeof deleteContractActions.request;
  uploadContractAttachments: typeof uploadContractAttachmentsActions.request;
  deleteContractAttachment: typeof deleteContractAttachmentActions.request;
  deleteStateContractDetail: typeof deleteStateContractDetailAction;
  replaceNavigation: typeof replace;
}

type Props = StateProps & ActionProps<ActionsMap> & RouteComponentProps;

const TAB = {
  CONTRACT: "1",
  CONTRACT_HISTORY: "2"
}

const ContractDetailContainer = ({ contract, ...props }: Props) => {

  const [activeTabKey, setActiveTabKey] = useState<string>(TAB.CONTRACT);
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  useEffect(() => {
    if ( !contract ) {
      props.actions.getContract({ id: props.match.params["id"] });
    }
    setActiveTabKey(props.initialUrlActiveTab);
    return () => props.actions.deleteStateContractDetail();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ( updateMode ) {
      setUpdateMode(false);
    }
  }, [props.locationKey]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (tabKey: string): void => {
    if ( activeTabKey === TAB.CONTRACT && updateMode ) {
      Modal.confirm({
        title: t("contract.helpers.unsavedChanges"),
        okText: t("contract.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => moveViewToTab(tabKey)
      });
    }
    else {
      moveViewToTab(tabKey);
    }
  };

  const moveViewToTab = (activeTabKey: string): void => {
    setActiveTabKey(activeTabKey)
    props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(serializeParams({ activeTab: activeTabKey })));
  };

  const handleUpdateModeToggle = (): void => {
    setUpdateMode(!updateMode);
  };

  return (
    <DisplayWrapper itemLoaded={!!contract} notFoundCheckRequest={contractRequests.GET_CONTRACT}>
      {contract && (
        <Tabs activeKey={activeTabKey} tabPosition="right" onChange={handleTabChange}>
          <Tabs.TabPane key={TAB.CONTRACT} tab={<span><SolutionOutlined />{t("contract.titles.contractData")}</span>}>
            {contract.type === ContractType.INSURANCE_CONTRACT
              ? updateMode
                ? (
                  <InsuranceContractForm
                    initialContract={contract as InsuranceContract}
                    onUpdateFormSubmit={props.actions.updateContract}
                    onCancelClick={handleUpdateModeToggle} />
                ) : (
                  <InsuranceContractDetailView
                    contract={contract as InsuranceContract}
                    onUpdateClick={handleUpdateModeToggle}
                    onVerify={props.actions.verifyContract}
                    onDelete={props.actions.deleteContract}
                    onAttachmentsUpload={props.actions.uploadContractAttachments}
                    onAttachmentDelete={props.actions.deleteContractAttachment} />
                )
              : updateMode
                ? (
                  <LoanContractForm
                    initialContract={contract as LoanContract}
                    onUpdateFormSubmit={props.actions.updateContract}
                    onCancelClick={handleUpdateModeToggle} />
                ) : (
                  <LoanContractDetailView
                    contract={contract as LoanContract}
                    onUpdateClick={handleUpdateModeToggle}
                    onVerify={props.actions.verifyContract}
                    onDelete={props.actions.deleteContract}
                    onAttachmentsUpload={props.actions.uploadContractAttachments}
                    onAttachmentDelete={props.actions.deleteContractAttachment} />
                )}
          </Tabs.TabPane>

          <Tabs.TabPane key={TAB.CONTRACT_HISTORY}
                        tab={<span><HistoryOutlined />{t("contract.titles.history")}</span>}>

          </Tabs.TabPane>
        </Tabs>
      )}
    </DisplayWrapper>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  contract: selectContractDetail(state),
  initialUrlActiveTab: selectRouterLocationSearchParam(state, "activeTab") || TAB.CONTRACT,
  locationKey: selectRouterLocationKey(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getContract: getContractActions.request,
    verifyContract: verifyContractActions.request,
    updateContract: updateContractActions.request,
    deleteContract: deleteContractActions.request,
    uploadContractAttachments: uploadContractAttachmentsActions.request,
    deleteContractAttachment: deleteContractAttachmentActions.request,
    deleteStateContractDetail: deleteStateContractDetailAction,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(ContractDetailContainer);
