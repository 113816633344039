import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedUserWrapper from "../common/security/authentication/AuthenticatedUserWrapper";

import AuthRoutes from "../modules/auth/routes";
import AccountRoutes from "../modules/account/routes";
import DashboardRoutes from "../modules/dashboard/routes";
import MyIdentityRoutes from "../modules/myidentity/routes";
import ClientRoutes from "../modules/client/routes";
import ContractRoutes from "../modules/contract/routes";
import CalcRoutes from "../modules/calculator/routes";
import ReportRoutes from "../modules/report/routes";
import PersonRoutes from "../modules/person/routes";
import CommissionsRoutes from "../modules/commissions/routes";
import AdminRoutes from "../modules/admin/routes";
import NotFound from "../common/pages/NotFound/NotFound";

const wrappedDashboardRoutes = AuthenticatedUserWrapper(DashboardRoutes);
const wrappedMyIdentityRoutes = AuthenticatedUserWrapper(MyIdentityRoutes);
const wrappedClientRoutes = AuthenticatedUserWrapper(ClientRoutes);
const wrappedContractRoutes = AuthenticatedUserWrapper(ContractRoutes);
const wrappedCalcRoutes = AuthenticatedUserWrapper(CalcRoutes);
const wrappedReportRoutes = AuthenticatedUserWrapper(ReportRoutes);
const wrappedPersonRoutes = AuthenticatedUserWrapper(PersonRoutes);
const wrappedCommissionsRoutes = AuthenticatedUserWrapper(CommissionsRoutes);
const wrappedAdminRoutes = AuthenticatedUserWrapper(AdminRoutes);

const wrappedNotFoundComponent = AuthenticatedUserWrapper(NotFound);

export default (
  <Switch>
    <Route path="/auth" component={AuthRoutes} />
    <Route path="/accounts" component={AccountRoutes} />

    <Route exact path="/" component={wrappedDashboardRoutes} />
    <Route path="/me" component={wrappedMyIdentityRoutes} />
    <Route path="/clients" component={wrappedClientRoutes} />
    <Route path="/contracts" component={wrappedContractRoutes} />
    <Route path="/calc" component={wrappedCalcRoutes} />
    <Route path="/reports" component={wrappedReportRoutes} />
    <Route path="/persons" component={wrappedPersonRoutes} />
    <Route path="/commissions" component={wrappedCommissionsRoutes} />
    <Route path="/admin" component={wrappedAdminRoutes} />

    <Route component={wrappedNotFoundComponent} />
  </Switch>
);
