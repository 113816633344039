export enum VehiclePurpose {
  NORMAL = 'NORMAL',
  CAR_RENTAL = 'CAR_RENTAL',
  DRIVING_SCHOOL = 'DRIVING_SCHOOL',
  TAXI = 'TAXI',
  PRIORITY_VEHICLE = 'PRIORITY_VEHICLE',
  DANGEROUS_LOAD = 'DANGEROUS_LOAD',
  VETERAN_CAR = 'VETERAN_CAR',
  VETERAN_CAR_H = 'VETERAN_CAR_H',
  INTERNATIONAL_PEOPLE_TRANSPORT = 'INTERNATIONAL_PEOPLE_TRANSPORT'
}

export enum VehicleSecurityFeature {
  IMMOBILISER = 'IMMOBILISER',
  MECHANICAL = 'MECHANICAL',
  VIN_PRINT = 'VIN_PRINT',
  ALARM = 'ALARM',
  TRACKING_SYSTEM = 'TRACKING_SYSTEM'
}

export enum TimeWithoutAccident {
  NEVER = 'NEVER',                          // 120 months
  YEAR_OR_LESS = 'YEAR_OR_LESS',            // 0 months
  AT_LEAST_1_YEAR = 'AT_LEAST_1_YEAR',      // 12 months
  AT_LEAST_2_YEARS = 'AT_LEAST_2_YEARS',    // 24 months
  AT_LEAST_3_YEARS = 'AT_LEAST_3_YEARS',    // 36 months
  AT_LEAST_4_YEARS = 'AT_LEAST_4_YEARS',    // 48 months
  AT_LEAST_5_YEARS = 'AT_LEAST_5_YEARS',    // 60 months
  AT_LEAST_6_YEARS = 'AT_LEAST_6_YEARS',    // 72 months
  AT_LEAST_7_YEARS = 'AT_LEAST_7_YEARS',    // 84 months
  AT_LEAST_8_YEARS = 'AT_LEAST_8_YEARS',    // 96 months
  AT_LEAST_9_YEARS = 'AT_LEAST_9_YEARS',    // 108 months
  AT_LEAST_10_YEARS = 'AT_LEAST_10_YEARS',  // 120 months
}

export enum VehicleInsurerRelation {
  SAME_AS_HOLDER = 'SAME_AS_HOLDER',
  DIFFERENT_FROM_HOLDER = 'DIFFERENT_FROM_HOLDER'
}

export enum VehicleOwnerRelation {
  SAME_AS_HOLDER = 'SAME_AS_HOLDER',
  SAME_AS_INSURER = 'SAME_AS_INSURER',
  DIFFERENT_FROM_HOLDER_AND_INSURER = 'DIFFERENT_FROM_HOLDER_AND_INSURER'
}

export enum CrashRepairLimit {
  MAX_50EUR = 'MAX_50EUR',
  UNLIMITED = 'UNLIMITED'
}

export enum GlassReinsuranceAmount {
  AT_LEAST_300 = 'AT_LEAST_300',
  AT_LEAST_500 = 'AT_LEAST_500',
  AT_LEAST_750 = 'AT_LEAST_750',
  AT_LEAST_1000 = 'AT_LEAST_1000'
}

export enum ReplacementVehicleCategory {
  LOWER_STANDARD = 'LOWER_STANDARD',
  HIGHER_STANDARD = 'HIGHER_STANDARD'
}

export enum VehicleEquipmentType {
  SUNROOF = 'SUNROOF',
  NAVIGATION = 'NAVIGATION',
  LEATHER = 'LEATHER',
  ALLOY_WHEELS = 'ALLOY_WHEELS',
  OTHER = 'OTHER'
}

export enum WustenrotContractsCount {
  ONE_CONTRACT = 'ONE_CONTRACT',
  TWO_CONTRACTS = 'TWO_CONTRACTS',
  THREE_CONTRACTS = 'THREE_CONTRACTS',
  FOUR_OR_MORE_CONTRACTS = 'FOUR_OR_MORE_CONTRACTS'
}

export enum VehicleTerritorialValidity {
  SVK = 'SVK',
  GREEN_CARD_STATES = 'GREEN_CARD_STATES',
  EUROPE = 'EUROPE',
  EUROPE_TUR = 'EUROPE_TUR',
  EUROPE_TUR_EXCEPT_BLR_MDA_UKR_RUS = 'EUROPE_TUR_EXCEPT_BLR_MDA_UKR_RUS'
}

export enum GapDuration {
  MONTHS_24 = 'MONTHS_24',  // 2 years
  MONTHS_36 = 'MONTHS_36',  // 3 years
  MONTHS_48 = 'MONTHS_48',  // 4 years
  MONTHS_60 = 'MONTHS_60',  // 5 years
  MONTHS_72 = 'MONTHS_72',  // 6 years
}

export enum RecommendationReason {
  ANNUAL_PREMIUM = 'ANNUAL_PREMIUM',
  COMPLICITY = 'COMPLICITY',
  ASSISTANCE_SERVICES = 'ASSISTANCE_SERVICES',
  TOTAL_DAMAGE_ASSESSMENT = 'TOTAL_DAMAGE_ASSESSMENT',
  MTPL_CRASH_COMPLEX_COVERAGE = 'MTPL_CRASH_COMPLEX_COVERAGE',
  MTPL_CRASH_GAP_COMPLEX_COVERAGE = 'MTPL_CRASH_GAP_COMPLEX_COVERAGE',
  OTHER = 'OTHER'
}

export enum VehiclePriceSource {
  INSURANCE_COMPANY_PRICE_LIST = 'INSURANCE_COMPANY_PRICE_LIST',
  INVOICE = 'INVOICE',
  INSURANCE_COMPANY_EXPERT = 'INSURANCE_COMPANY_EXPERT'
}
