import React from "react";
import { Col, Input, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { NearbyBuildingType } from "../../../../enums";
import validations from "../../../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../../common/constants";
import t from "../../../../../../../../app/i18n";

import DeleteIcon from "../../../../../../../../common/components/icons/DeleteIcon";
import InputNumberWithAddon from "../../../../../../../../common/components/form/components/InputNumberWithAddon";

export interface Props {
  form: WrappedFormUtils;
  index: number;
  initialBuildingType: NearbyBuildingType;
  onDelete(index: number): void;
}

interface State {
  readonly buildingType: NearbyBuildingType;
}

class RealtyCalcReinsurancesSectionItem extends React.Component<Props, State> {
  readonly state: State = {
    buildingType: this.props.initialBuildingType
  };

  handleBuildingTypeChange = (buildingType: NearbyBuildingType): void => {
    this.setState({ buildingType });
  };

  shouldShowFloorAreaInput = (buildingType: NearbyBuildingType): boolean => {
    switch ( buildingType ) {
      case NearbyBuildingType.ARBOUR:
      case NearbyBuildingType.WORKSHOP:
      case NearbyBuildingType.DETACHED_GARAGE:
      case NearbyBuildingType.FARM_BUILDING:
      case NearbyBuildingType.COWSHED:
      case NearbyBuildingType.BOILER_ROOM:
      case NearbyBuildingType.SHED:
      case NearbyBuildingType.PERGOLA:
      case NearbyBuildingType.STABLE:
      case NearbyBuildingType.BARN:
      case NearbyBuildingType.OTHER:
        return true;
      default:
        return false;
    }
  };

  render(): React.ReactNode {
    const { index } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { buildingType } = this.state;

    const colSpan = 6;

    return (
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("calc.realty.enums.nearbyBuildingType._label")}>
            {getFieldDecorator(`realtyInsuranceData.nearbyBuildingReinsurances[${index}].type`, {
              rules: [validations.notNull], initialValue: buildingType
            })(
              <Select
                {...selectStandardProps}
                options={Object.keys(NearbyBuildingType).map(type => ({
                  value: type,
                  label: t("calc.realty.enums.nearbyBuildingType." + type)
                }))}
                onChange={this.handleBuildingTypeChange} />
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item label={t("calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.insuranceAmount")}>
            {getFieldDecorator(`realtyInsuranceData.nearbyBuildingReinsurances[${index}].insuranceAmount`, {
              rules: [validations.notNull, validations.multipleOf100]
            })(
              <InputNumberWithAddon addonType="euro" formatStyle="integer" min={100} />
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          {this.shouldShowFloorAreaInput(buildingType) && (
            <Form.Item label={t("calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.floorArea")}>
              {getFieldDecorator(`realtyInsuranceData.nearbyBuildingReinsurances[${index}].floorArea`, {
                rules: [validations.notNull]
              })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={1} />
              )}
            </Form.Item>
          )}
        </Col>

        <Col span={colSpan - 1}>
          {buildingType === NearbyBuildingType.OTHER && (
            <Form.Item label={t("calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.otherDesc")}>
              {getFieldDecorator(`realtyInsuranceData.nearbyBuildingReinsurances[${index}].otherDesc`, {
                rules: [validations.notBlank]
              })(
                <Input />
              )}
            </Form.Item>
          )}
        </Col>

        <Col span={1} className="right-align" style={{ paddingLeft: 0 }}>
          <DeleteIcon index={index} onClick={this.props.onDelete} />
        </Col>
      </Row>
    );
  }
}

export default RealtyCalcReinsurancesSectionItem;
