export enum ClientType {
  NATURAL = 'NATURAL',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  LEGAL = 'LEGAL'
}

export enum ClientFormType {
  INSURED = 'INSURED',
  INSURER = 'INSURER',
  AUTHORIZED_1 = 'AUTHORIZED_1',
  AUTHORIZED_2 = 'AUTHORIZED_2',
  VINCULATION = 'VINCULATION',
  HOLDER = 'HOLDER',
  OWNER = 'OWNER',
  DEBTOR = 'DEBTOR',
  CO_DEBTOR = 'CO_DEBTOR'
}

export enum ClientFormStage {
  EMPTY = 'EMPTY',
  NEW = 'NEW',
  EXISTING = 'EXISTING',
  SELECTED = 'SELECTED'
}

export enum ClientSearchActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}
