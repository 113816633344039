import React from "react";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { ContactType } from "../enums";
import { validations } from "../../utils/validationUtils";
import { phoneNumberNormalizeFunction } from "../../utils/formUtils";
import { contains, containsAny } from "../../utils/utils";
import { rowGutter } from "../../constants";
import t from "../../../app/i18n";

import ContactTypeIcon from "./ContactTypeIcon";
import ContactTypePopover from "./ContactTypePopover";
import DeleteIcon from "../../components/icons/DeleteIcon";
import LabelWithTooltip from "../../components/form/labels/LabelWithTooltip";

export interface Props {
  index: number;
  selectedContactType: ContactType;
  contactTypeOptions: ContactType[];
  disabledContactTypeOptions: ContactType[];
  showUseForNotificationsItem: boolean;
  onContactTypeChange(index: number, contactType: ContactType): void;
  onDelete(index: number): void;
}

const ContactRowFormItem = ({ index, selectedContactType, contactTypeOptions, disabledContactTypeOptions, showUseForNotificationsItem, ...props }: Props) => {

  const colSpan = 6;
  const smallColSpan = 5;

  const contactTypeLabel = containsAny(contactTypeOptions, ContactType.BROKER_EMAIL, ContactType.BROKER_PHONE_NUMBER)
    ? <span>{t("contact.enums.type._label")} <ContactTypePopover /></span>
    : t("contact.enums.type._label");

  const contactValueRules = [validations.notBlank, validations.size(1, 255)];
  switch ( selectedContactType ) {
    case ContactType.EMAIL:
    case ContactType.BROKER_EMAIL:
      contactValueRules.push(validations.email);
      break;
    case ContactType.PHONE_NUMBER:
    case ContactType.BROKER_PHONE_NUMBER:
      contactValueRules.push(validations.phoneNumber);
      break;
  }

  return (
    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <Form.Item
          name={["contacts", index, "type"]}
          label={contactTypeLabel}
          rules={[validations.notNull]}
          initialValue={selectedContactType}>
          <Select<ContactType>
            options={contactTypeOptions.map(type => ({
              value: type,
              label: <><ContactTypeIcon type={type} /> {t("contact.enums.type." + type)}</>,
              disabled: contains(disabledContactTypeOptions, type)
            }))}
            onChange={type => props.onContactTypeChange(index, type)} />
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item
          name={["contacts", index, "value"]}
          label={t("contact.attrs.value")}
          rules={contactValueRules}
          normalize={value =>
            selectedContactType === ContactType.PHONE_NUMBER || selectedContactType === ContactType.BROKER_PHONE_NUMBER
              ? phoneNumberNormalizeFunction(value) : value}>
          <Input />
        </Form.Item>
      </Col>

      <Col span={colSpan}>
        <Form.Item
          name={["contacts", index, "description"]}
          label={t("contact.attrs.description")}
          rules={[validations.size(1, 255)]}>
          <Input />
        </Form.Item>
      </Col>

      <Col span={showUseForNotificationsItem ? smallColSpan : 0}>
        <Form.Item
          hidden={!showUseForNotificationsItem} noStyle={!showUseForNotificationsItem}
          name={["contacts", index, "useForNotifications"]}
          valuePropName="checked"
          rules={[validations.none]}
          initialValue={false}
          className="form-item-without-label">
          <Checkbox>
            <LabelWithTooltip label={t("contact.attrs.useForNotifications")}
                              tooltip={t("contact.helpers.useForNotificationsDesc")} />
          </Checkbox>
        </Form.Item>
      </Col>

      <Col span={1}>
        <DeleteIcon index={index} onClick={props.onDelete} />
      </Col>
    </Row>
  )
};

export default ContactRowFormItem;
