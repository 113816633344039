import React from "react";
import { Col, Row } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { tInterval } from "../../../../../../../../common/utils/translationUtils";
import validations from "../../../../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../../../../common/constants";
import t from "../../../../../../../../app/i18n";

import InputNumberWithAddon from "../../../../../../../../common/components/form/components/InputNumberWithAddon";

interface Props {
  form: WrappedFormUtils;
  buildArea: number;
  itemsInRow: number;
  startIndex: number;
}

const RealtyCalcFloorAreasSectionItem = ({ form, buildArea, itemsInRow, startIndex }: Props) => {
  const colSpan = 8;

  const rowAreaItems = [];
  for ( let index = startIndex; index < startIndex + itemsInRow; index++ ) {
    rowAreaItems.push(
      <Col span={colSpan} key={index}>
        <Form.Item
          label={tInterval("calc.realty.attrs.houseOrRecreationalBuildingData.overgroundFloorAreas_interval", index + 1)}>
          {form.getFieldDecorator(`houseOrRecreationalBuildingData.overgroundFloorAreas[${index}]`, {
            rules: [validations.notNull, validations.maxNumber(buildArea, t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea"))]
          })(
            <InputNumberWithAddon addonType="area" formatStyle="integer" min={10} />
          )}
        </Form.Item>
      </Col>)
  }

  return <Row gutter={rowGutter}>{rowAreaItems}</Row>
};

export default RealtyCalcFloorAreasSectionItem;
