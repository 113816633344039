import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "./app/styles/global.less";

import "@ant-design/compatible/assets/index.css";
import "antd/es/grid/style";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";

import Root from "./app/containers/Root";

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
