import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import apiService from "../../common/api/apiService";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  Contract,
  ContractAttachment,
  ContractFilterPageRequest,
  ContractFilterPageResult,
  ContractList,
  CreateUpdateContract
} from "./types";
import { EntityIdRequest, EntityObjectRequest, TwoLevelEntityIdRequest } from "../../common/types";

const FILTER_CONTRACTS = ApiRequestAdapter.create("/contracts", "GET");
const GET_CONTRACT = ApiRequestAdapter.create("/contracts/{0}", "GET");
const CREATE_CONTRACT = ApiRequestAdapter.create("/contracts", "POST");
const VERIFY_CONTRACT = ApiRequestAdapter.create("/contracts/{0}/verify", "POST");
const UPDATE_CONTRACT = ApiRequestAdapter.create("/contracts/{0}", "PUT");
const DELETE_CONTRACT = ApiRequestAdapter.create("/contracts/{0}", "DELETE");

const UPLOAD_CONTRACT_ATTACHMENTS = ApiRequestAdapter.create("/contracts/{0}/attachments", "POST");
const DOWNLOAD_CONTRACT_ATTACHMENT = ApiRequestAdapter.create("/contracts/{0}/attachments/{1}", "GET");
const DELETE_CONTRACT_ATTACHMENT = ApiRequestAdapter.create("/contracts/{0}/attachments/{1}", "DELETE");

export const requests = {
  FILTER_CONTRACTS,
  GET_CONTRACT,
  CREATE_CONTRACT,
  VERIFY_CONTRACT,
  UPDATE_CONTRACT,
  DELETE_CONTRACT,
  UPLOAD_CONTRACT_ATTACHMENTS,
  DOWNLOAD_CONTRACT_ATTACHMENT,
  DELETE_CONTRACT_ATTACHMENT
};

export default {
  filterContracts: (request: ContractFilterPageRequest): AxiosPromise<ContractFilterPageResult<ContractList>> => {
    return apiService.get<ContractFilterPageResult<ContractList>>(FILTER_CONTRACTS.url, {
      params: request,
      paramsSerializer: serializeParams
    });
  },
  getContract: (request: EntityIdRequest): AxiosPromise<Contract> => {
    return apiService.get<Contract>(GET_CONTRACT.getParametrizedUrl(request.id))
  },
  createContract: (request: CreateUpdateContract): AxiosPromise<Contract> => {
    return apiService.post<Contract>(CREATE_CONTRACT.url, request)
  },
  verifyContract: (request: EntityIdRequest): AxiosPromise<Contract> => {
    return apiService.post<Contract>(VERIFY_CONTRACT.getParametrizedUrl(request.id));
  },
  updateContract: (request: EntityObjectRequest<CreateUpdateContract>): AxiosPromise<Contract> => {
    return apiService.put<Contract>(UPDATE_CONTRACT.getParametrizedUrl(request.id), request.object);
  },
  deleteContract: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_CONTRACT.getParametrizedUrl(request.id));
  },
  uploadContractAttachments: (request: EntityObjectRequest<FormData>): AxiosPromise<ContractAttachment[]> => {
    return apiService.post<ContractAttachment[]>(
      UPLOAD_CONTRACT_ATTACHMENTS.getParametrizedUrl(request.id),
      request.object,
      { headers: { "Content-Type": "multipart/form-data" } });
  },
  downloadContractAttachment: (request: TwoLevelEntityIdRequest): AxiosPromise<Blob> => {
    return apiService.get<Blob>(
      DOWNLOAD_CONTRACT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), { responseType: "blob" });
  },
  deleteContractAttachment: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_CONTRACT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  }
}
