import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import cloneDeep from "lodash/cloneDeep"
import { Modal } from "antd";
import { ActionProps, RootState } from "../../../../../common/types";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import {
  CreateUpdateRealtyCalcDraft,
  RealtyCalc,
  RealtyCalcDraft,
  RealtyCalcResultData,
  RealtyFormClients,
  RealtyGen,
  RealtyGenFormData
} from "../types";
import { Client, ClientAutocompleteResult } from "../../../../client/types";
import { ValidationErrorResponse } from "../../../../types";
import { CalcType } from "../../../enums";
import { OperationStage } from "../enums";
import { ClientFormType } from "../../../../client/enums";
import {
  calculateRealtyActions,
  deleteStateRealtyCalcResultsAction,
  deleteStateRealtyGenResultAction,
  deleteStateRealtyInitialCalcGenDataAction,
  deleteStateRealtySelectedDraftAction,
  generateRealtyActions,
  generateRealtyOfferActions,
  selectRealtyCalcResults,
  selectRealtyGenResult,
  selectRealtyInitialCalcData,
  selectRealtyInitialGenData,
  selectRealtySelectedDraft
} from "../ducks";
import {
  deleteStateValidationErrorResponseAction,
  selectIsRequestInProgress,
  selectValidationErrorResponse
} from "../../../../ducks";
import {
  autocompleteClientsActions,
  deleteStateClientsAutocompleteResultAction,
  selectClientsAutocompleteResult,
  validateClientActions,
  validateClientsActions
} from "../../../../client/ducks";
import { createCalcDraftActions, updateCalcDraftActions } from "../../../drafts/ducks";
import {
  createRealtyCalcObjectFromCalcData,
  createRealtyCalcObjectFromGenData,
  createRealtyFormClientsObject,
  createRealtyGenFormDataObject
} from "../utils";
import { clientToCreateUpdateContractClient } from "../../../../client/utils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { requests as clientRequests } from "../../../../client/api";
import { requests as calcRequests } from "../../api";
import t from "../../../../../app/i18n";

import RealtyCalcWrapper from "../components/calc/RealtyCalcWrapper";
import RealtyGenWrapper from "../components/gen/RealtyGenWrapper";
import RealtyClientNameModal from "../components/client/RealtyClientNameModal";
import CalcLoadingModal from "../../components/CalcLoadingModal";

interface StateProps {
  initialCalcData: RealtyCalc;
  initialGenData: RealtyGen;
  selectedDraft: RealtyCalcDraft;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  genResult: GenResponse;
  clientsAutocompleteResult: ClientAutocompleteResult;
  clientsAutocompleteInProgress: boolean;
  calcRequestInProgress: boolean;
  genRequestInProgress: boolean;
  validateClientErrorResponse: ValidationErrorResponse;
  validateClientsErrorResponse: ValidationErrorResponse;
}

interface ActionsMap {
  calculateRealty: typeof calculateRealtyActions.request;
  generateRealty: typeof generateRealtyActions.request;
  generateRealtyOffer: typeof generateRealtyOfferActions.request;
  autocompleteClients: typeof autocompleteClientsActions.request;
  validateClient: typeof validateClientActions.request;
  validateClients: typeof validateClientsActions.request;
  createCalcDraft: typeof createCalcDraftActions.request;
  updateCalcDraft: typeof updateCalcDraftActions.request;
  deleteStateRealtyCalcResults: typeof deleteStateRealtyCalcResultsAction;
  deleteStateRealtyGenResult: typeof deleteStateRealtyGenResultAction;
  deleteStateRealtySelectedDraft: typeof deleteStateRealtySelectedDraftAction;
  deleteStateRealtyInitialCalcGenData: typeof deleteStateRealtyInitialCalcGenDataAction;
  deleteStateClientsAutocompleteResult: typeof deleteStateClientsAutocompleteResultAction;
  deleteStateValidationErrorResponse: typeof deleteStateValidationErrorResponseAction;
}

interface State {
  readonly resetKey: number;
  readonly clients: RealtyFormClients;
  readonly genStepActive: boolean;
  readonly calcData: RealtyCalc;
  readonly calcDataSource: CalcDataSource;
  readonly genFormData: RealtyGenFormData;
  readonly selectedResult: CalcResult<RealtyCalcResultData>;
  readonly draftDataParts: DraftDataPartState;
  readonly clientName: string;
  readonly clientNameOfferModalVisible: boolean;
  readonly clientNameDraftModalVisible: boolean;
}

interface DraftDataPartState {
  draftData: RealtyGen;
  stage: OperationStage;
}

class RealtyCalcContainer extends React.Component<StateProps & ActionProps<ActionsMap>, State> {

  constructor(props: StateProps & ActionProps<ActionsMap>) {
    super(props);

    const stateObject = {
      resetKey: 0,
      clients: { insured: null, insurer: null, authorized1: null, authorized2: null, vinculation: null },
      genStepActive: false,
      calcData: null,
      calcDataSource: "init" as CalcDataSource,
      genFormData: null,
      selectedResult: null,
      draftDataParts: null,
      clientName: null,
      clientNameOfferModalVisible: false,
      clientNameDraftModalVisible: false
    };

    if ( props.initialCalcData ) {
      stateObject.calcData = createRealtyCalcObjectFromCalcData(props.initialCalcData);
      stateObject.calcDataSource = "calcData";
    }
    else if ( props.initialGenData ) {
      stateObject.clients = createRealtyFormClientsObject(props.initialGenData);
      stateObject.calcData = createRealtyCalcObjectFromGenData(props.initialGenData);
      stateObject.calcDataSource = "genData";
      stateObject.genFormData = createRealtyGenFormDataObject(props.initialGenData, stateObject.clients);
    }
    else if ( props.selectedDraft ) {
      const { draftData } = props.selectedDraft;
      stateObject.clients = createRealtyFormClientsObject(draftData);
      stateObject.calcData = createRealtyCalcObjectFromGenData(draftData);
      stateObject.calcDataSource = "draft";
      stateObject.genFormData = props.selectedDraft.stage === OperationStage.GENERATE
        ? createRealtyGenFormDataObject(draftData, stateObject.clients) : null;
      stateObject.clientName = props.selectedDraft.clientAggregatedName;
    }

    this.state = stateObject;
  }

  handleCalculationFormSubmit = (calcData: RealtyCalc): void => {
    this.setState({ calcData });
    this.props.actions.calculateRealty(calcData);
  };

  handleClientChange = (client: Client, type: ClientFormType, callback?: () => void): void => {
    switch ( type ) {
      case ClientFormType.INSURED:
        this.setState(previousState => ({ clients: { ...previousState.clients, insured: client } }), callback);
        break;
      case ClientFormType.INSURER:
        this.setState(previousState => ({ clients: { ...previousState.clients, insurer: client } }), callback);
        break;
      case ClientFormType.AUTHORIZED_1:
        this.setState(previousState => ({ clients: { ...previousState.clients, authorized1: client } }), callback);
        break;
      case ClientFormType.AUTHORIZED_2:
        this.setState(previousState => ({ clients: { ...previousState.clients, authorized2: client } }), callback);
        break;
      case ClientFormType.VINCULATION:
        this.setState(previousState => ({ clients: { ...previousState.clients, vinculation: client } }), callback);
        break;
    }
  };

  handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        this.setState(previousState => ({
          resetKey: previousState.resetKey + 1,
          clients: { insured: null, insurer: null, authorized1: null, authorized2: null, vinculation: null },
          calcData: null,
          calcDataSource: "init" as CalcDataSource,
          genFormData: null,
          selectedResult: null,
          draftDataParts: null,
          clientName: null
        }));
        this.props.actions.deleteStateRealtyCalcResults();
        this.props.actions.deleteStateRealtySelectedDraft();
        this.props.actions.deleteStateClientsAutocompleteResult();
      }
    });
  };

  handleCalcDataSourceReset = (): void => {
    this.setState({ calcDataSource: "init" });
  };

  handleGenerateContractClick = (calcData: RealtyCalc, selectedResult: CalcResult<RealtyCalcResultData>): void => {
    this.setState({ genStepActive: true, calcData, selectedResult });
  };

  handleReturnToCalculationClick = (genFormData: RealtyGenFormData): void => {
    this.setState({ genStepActive: false, calcDataSource: "calcData", genFormData });
  };

  handleSelectedResultChange = (selectedResult: CalcResult<RealtyCalcResultData>, callback?: () => void): void => {
    this.setState({ selectedResult }, callback);
  };

  handleCalcFormGenerateOfferClick = (calcData: RealtyCalc): void => {
    const { insured } = this.state.clients;
    if ( insured && insured.aggregatedName ) {
      this.setState({ calcData }, () => this.processOfferGeneration(insured.aggregatedName));
    }
    else {
      this.setState({ calcData, clientNameOfferModalVisible: true });
    }
  };

  handleGenFormGenerateOfferClick = (): void => {
    const { insured } = this.state.clients;
    if ( insured && insured.aggregatedName ) {
      this.processOfferGeneration(insured.aggregatedName)
    }
    else {
      this.setState({ clientNameOfferModalVisible: true });
    }
  };

  processOfferGeneration = (clientName: string): void => {
    this.props.actions.generateRealtyOffer({
      type: CalcType.REALTY,
      calcRequest: this.state.calcData,
      calcResponse: { results: this.props.calcResults.flat() },
      insuredClientName: clientName
    });
    this.setState({ clientName });
  };

  handleCalcFormSaveDraftClick = (calcData: RealtyCalc): void => {
    // @ts-ignore
    const draftData: RealtyGen = cloneDeep(calcData);

    const { insured } = this.state.clients;
    draftData.clientsData.clients = insured ? [clientToCreateUpdateContractClient(insured)] : [];
    draftData.clientsData.insuredClientIndex = insured ? 0 : undefined;

    if ( insured && insured.aggregatedName ) {
      this.setState({ draftDataParts: { draftData, stage: OperationStage.CALCULATE } },
        () => this.processDraftCreateOrUpdate(insured.aggregatedName))
    }
    else {
      this.setState({
        draftDataParts: { draftData, stage: OperationStage.CALCULATE },
        clientNameDraftModalVisible: true
      });
    }
  };

  handleGenFormSaveDraftClick = (genData: RealtyGen): void => {
    const { insured } = this.state.clients;
    if ( insured && insured.aggregatedName ) {
      this.setState({ draftDataParts: { draftData: genData, stage: OperationStage.GENERATE } },
        () => this.processDraftCreateOrUpdate(insured.aggregatedName))
    }
    else {
      this.setState({
        draftDataParts: { draftData: genData, stage: OperationStage.GENERATE },
        clientNameDraftModalVisible: true
      });
    }
  };

  processDraftCreateOrUpdate = (clientName: string): void => {
    const draft: CreateUpdateRealtyCalcDraft = {
      ...this.state.draftDataParts,
      type: CalcType.REALTY,
      calcResponse: { results: this.props.calcResults.flat() },
      clientAggregatedName: clientName
    };

    const { selectedDraft } = this.props;
    if ( selectedDraft ) {
      this.props.actions.updateCalcDraft({
        id: selectedDraft.id,
        object: { ...draft, optimisticLockVersion: selectedDraft.optimisticLockVersion }
      });
    }
    else {
      this.props.actions.createCalcDraft(draft);
    }
    this.setState({ clientName });
  };

  handleClientNameModalOkClick = (clientName: string): void => {
    if ( this.state.clientNameOfferModalVisible ) {
      this.processOfferGeneration(clientName);
    }
    else {
      this.processDraftCreateOrUpdate(clientName);
    }
    this.setState({ clientNameOfferModalVisible: false, clientNameDraftModalVisible: false });
  };

  handleClientNameModalCancelClick = (): void => {
    this.setState({ clientNameOfferModalVisible: false, clientNameDraftModalVisible: false });
  };

  componentWillUnmount(): void {
    this.props.actions.deleteStateRealtyCalcResults();
    this.props.actions.deleteStateRealtyGenResult();
    this.props.actions.deleteStateRealtySelectedDraft();
  }

  componentDidMount(): void {
    if ( this.props.selectedDraft ) {
      messageUtils.infoNotification(t("calc.draft.helpers.draftLoaded"), t("calc.draft.helpers.draftLoadedInfo"), 10);
    }
    if ( this.props.initialCalcData || this.props.initialGenData ) {
      this.props.actions.deleteStateRealtyInitialCalcGenData();
    }
  }

  render(): React.ReactNode {
    return (
      <>
        {this.state.genStepActive ? (
          <RealtyGenWrapper initialData={this.state.genFormData}
                            genResult={this.props.genResult}
                            calcData={this.state.calcData}
                            clients={this.state.clients}
                            calcResults={this.props.calcResults}
                            selectedResult={this.state.selectedResult}
                            draftId={this.props.selectedDraft ? this.props.selectedDraft.id : null}
                            clientsAutocomplete={{
                              result: this.props.clientsAutocompleteResult,
                              inProgress: this.props.clientsAutocompleteInProgress,
                              onSearch: this.props.actions.autocompleteClients,
                              onResultDelete: this.props.actions.deleteStateClientsAutocompleteResult,
                            }}
                            validateClientErrorResponse={this.props.validateClientErrorResponse}
                            validateClientsErrorResponse={this.props.validateClientsErrorResponse}
                            onGenerateFormSubmit={this.props.actions.generateRealty}
                            onValidateClient={this.props.actions.validateClient}
                            onValidateClients={this.props.actions.validateClients}
                            onGenResultDelete={this.props.actions.deleteStateRealtyGenResult}
                            onErrorResponseDelete={this.props.actions.deleteStateValidationErrorResponse}
                            onClientChange={this.handleClientChange}
                            onGenerateOfferClick={this.handleGenFormGenerateOfferClick}
                            onSelectedResultChange={this.handleSelectedResultChange}
                            onSaveDraftClick={this.handleGenFormSaveDraftClick}
                            onReturnToCalculationClick={this.handleReturnToCalculationClick} />
        ) : (
          <RealtyCalcWrapper key={this.state.resetKey}
                             calcData={this.state.calcData}
                             calcDataSource={this.state.calcDataSource}
                             calcResults={this.props.calcResults}
                             insuredClient={this.state.clients.insured}
                             clientsAutocomplete={{
                               result: this.props.clientsAutocompleteResult,
                               inProgress: this.props.clientsAutocompleteInProgress,
                               onSearch: this.props.actions.autocompleteClients,
                               onResultDelete: this.props.actions.deleteStateClientsAutocompleteResult,
                             }}
                             onCalcResultsDelete={this.props.actions.deleteStateRealtyCalcResults}
                             onCalculationFormSubmit={this.handleCalculationFormSubmit}
                             onInsuredClientChange={client => this.handleClientChange(client, ClientFormType.INSURED)}
                             onGenerateContractClick={this.handleGenerateContractClick}
                             onGenerateOfferClick={this.handleCalcFormGenerateOfferClick}
                             onSaveDraftClick={this.handleCalcFormSaveDraftClick}
                             onResetCalculatorClick={this.handleResetCalculatorClick}
                             onCalcDataSourceReset={this.handleCalcDataSourceReset} />
        )}

        <RealtyClientNameModal
          visibleAsOffer={this.state.clientNameOfferModalVisible}
          visibleAsDraft={this.state.clientNameDraftModalVisible}
          initialName={this.state.clientName}
          onOkClick={this.handleClientNameModalOkClick}
          onCancelClick={this.handleClientNameModalCancelClick} />

        {this.props.calcRequestInProgress || this.props.genRequestInProgress ? (
          <CalcLoadingModal
            calcRequestInProgress={this.props.calcRequestInProgress}
            genRequestInProgress={this.props.genRequestInProgress} />
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectRealtyInitialCalcData(state),
  initialGenData: selectRealtyInitialGenData(state),
  selectedDraft: selectRealtySelectedDraft(state),
  calcResults: selectRealtyCalcResults(state),
  genResult: selectRealtyGenResult(state),
  clientsAutocompleteResult: selectClientsAutocompleteResult(state),
  clientsAutocompleteInProgress: selectIsRequestInProgress(state, clientRequests.AUTOCOMPLETE_CLIENTS),
  calcRequestInProgress: selectIsRequestInProgress(state, calcRequests.CALCULATE),
  genRequestInProgress: selectIsRequestInProgress(state, calcRequests.GENERATE),
  validateClientErrorResponse: selectValidationErrorResponse(state, clientRequests.VALIDATE_CLIENT),
  validateClientsErrorResponse: selectValidationErrorResponse(state, clientRequests.VALIDATE_CLIENTS)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    calculateRealty: calculateRealtyActions.request,
    generateRealty: generateRealtyActions.request,
    generateRealtyOffer: generateRealtyOfferActions.request,
    autocompleteClients: autocompleteClientsActions.request,
    validateClient: validateClientActions.request,
    validateClients: validateClientsActions.request,
    createCalcDraft: createCalcDraftActions.request,
    updateCalcDraft: updateCalcDraftActions.request,
    deleteStateRealtyCalcResults: deleteStateRealtyCalcResultsAction,
    deleteStateRealtyGenResult: deleteStateRealtyGenResultAction,
    deleteStateRealtySelectedDraft: deleteStateRealtySelectedDraftAction,
    deleteStateRealtyInitialCalcGenData: deleteStateRealtyInitialCalcGenDataAction,
    deleteStateClientsAutocompleteResult: deleteStateClientsAutocompleteResultAction,
    deleteStateValidationErrorResponse: deleteStateValidationErrorResponseAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(RealtyCalcContainer);
