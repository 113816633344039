import React from "react";
import { Col, Row } from "antd";
import { ImportedCommission } from "../../types";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

export interface Props {
  commission: ImportedCommission;
}

const ImportedCommissionExtendedTableView = (props: Props) => (
  <Row gutter={rowGutter} justify="center" className="margin-top-tiny margin-bottom-tiny">
    <Col span={24} style={{ maxWidth: "95%" }}>
      {!!props.commission.errors.length && (
        <span>
          <b>{t("common.errors")}:</b> {props.commission.errors.map(error => error.description).join(", ")}
        </span>
      )}
    </Col>
  </Row>
);

export default ImportedCommissionExtendedTableView;
