import React from "react";
import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { LifeInsuranceTariff, LifeInsuranceTariffFilterPageResult } from "../../types";
import { deleteLifeInsuranceTariffActions } from "../../ducks";
import { paginationStandardProps } from "../../../../../common/utils/formUtils";
import {
  formatLifeInsuranceTariffName,
  formatLocaleDateTime,
  formatProductName
} from "../../../../../common/utils/formatUtils";
import t from "../../../../../app/i18n";

import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";

interface Props {
  tariffsPage: LifeInsuranceTariffFilterPageResult;
  onDelete: typeof deleteLifeInsuranceTariffActions.request;
  onTablePageChange(pageNumber: number): void;
  onUpdateClick(lifeInsuranceTariff: LifeInsuranceTariff): void;
}

const LifeInsuranceTariffTableView = (props: Props) => {

  const columns: ColumnsType<LifeInsuranceTariff> = [
    {
      key: "product",
      title: t("admin.lifeInsuranceTariff.attrs.productId"),
      render: (text, record) => formatProductName(record.product),
    },
    {
      key: "code",
      title: t("admin.lifeInsuranceTariff.attrs.code"),
      render: (text, record) => record.code
    },
    {
      key: "name",
      title: t("admin.lifeInsuranceTariff.attrs.name"),
      render: (text, record) => formatLifeInsuranceTariffName(record),
    },
    {
      key: "createdAt",
      title: t("common.createdAt"),
      render: (text, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "updatedAt",
      title: t("common.updatedAt"),
      render: (text, record) => formatLocaleDateTime(record.updatedAt)
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (text, record) => (
        <>
          <ActionTextIcon
            type="edit"
            color="blue"
            text={t("common.edit")}
            onClick={() => props.onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("admin.lifeInsuranceTariff.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}
          >
            <ActionTextIcon type="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </>
      )
    },
  ];

  return (
    <Table<LifeInsuranceTariff>
      className="table-row-stripped"
      columns={columns}
      dataSource={props.tariffsPage.pageData}
      rowClassName={record => record.deactivated ? "deactivated" : undefined}
      rowKey="id"
      size="small"
      pagination={{
        ...paginationStandardProps,
        current: props.tariffsPage.pageIndex + 1,
        pageSize: props.tariffsPage.pageSize,
        total: props.tariffsPage.totalElementsCount,
        onChange: props.onTablePageChange
      }}
    />
  );
}

export default LifeInsuranceTariffTableView;
