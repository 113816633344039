import "./clients-list-drawer-view.less";
import React, { useState } from "react";
import { Drawer } from "antd";
import { Client } from "../../types";
import { ClientFormType } from "../../enums";
import { AuthorizedClientCompanyFunction } from "../../../contract/enums";
import { isDefinedValue } from "../../../../common/utils/utils";
import t from "../../../../app/i18n";

import ClientDetailView from "../views/detail/ClientDetailView";
import ClientTypeTagWithName from "../ClientTypeTagWithName";
import ClientTypeTagWithDescription from "../ClientTypeTagWithDescription";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";

export interface Props {
  clientsData: ClientDataProps[];
  className?: string;
}

export interface ClientDataProps {
  client: Client;
  clientFormTypes?: ClientFormType[];
  companyFunction?: AuthorizedClientCompanyFunction;
}

const ClientsListDrawerView = ({ clientsData, className }: Props) => {

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [clientIndex, setClientIndex] = useState<number>();

  return (
    <>
      {isDefinedValue(clientIndex) && (
        <Drawer
          title={<ClientTypeTagWithName client={clientsData[clientIndex].client} />}
          width={750}
          visible={drawerVisible}
          closable
          onClose={() => setDrawerVisible(false)}
          afterVisibleChange={visible => {
            if ( !visible ) {
              setClientIndex(null);
            }
          }}>
          <ClientDetailView client={clientsData[clientIndex].client} />
        </Drawer>
      )}

      <table className={"data-table-view clients-list-drawer-view " + (className || "")}>
        <tbody>
        {clientsData.map((data, index) => {
          let clientLabel = data.clientFormTypes && data.clientFormTypes.length > 0
            ? data.clientFormTypes
              .map(type =>
                (type === ClientFormType.AUTHORIZED_1 || type === ClientFormType.AUTHORIZED_2) && data.companyFunction
                  ? `${t("client.enums.formType." + type)} (${t("contract.enums.authorizedClientCompanyFunction." + data.companyFunction)})`
                  : t("client.enums.formType." + type))
              .join(", ")
            : t("client.helpers.client");

          return (
            <tr key={index}>
              <td>{clientLabel}</td>
              <td className="client-name" onClick={() => {
                setClientIndex(index);
                setDrawerVisible(true);
              }}>
                <ClientTypeTagWithDescription client={data.client} />
                <span className="detail-icon margin-left-tiny">
                  <ActionTextIcon type="eye" color="blue" disableDelayEffect />
                </span>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </>
  )
}

export default ClientsListDrawerView;
