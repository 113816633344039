import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps } from "react-router";
import { replace } from "connected-react-router";
import { PasswordReset } from "../types";
import { ActionProps, RootState } from "../../../common/types";
import { resetPasswordActions } from "../ducks";
import { selectRouterLocationSearchParam } from "../../ducks";

import ResetPasswordForm from "../components/forms/ResetPasswordForm";

interface StateProps {
  optimisticLockVersion: number;
  token: string;
}

interface ActionsMap {
  resetPassword: typeof resetPasswordActions.request;
  replaceNavigation: typeof replace;
}

type Props = StateProps & ActionProps<ActionsMap> & RouteComponentProps;

const ResetPasswordContainer = (props: Props) => {

  useEffect(() => {
      const { optimisticLockVersion, token } = props;
      if ( isNaN(optimisticLockVersion) || !token ) {
        props.actions.replaceNavigation("/");
      }
    }, [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePasswordResetFormSubmit = (passwordReset: PasswordReset): void => {
    props.actions.resetPassword({ id: props.match.params["accountId"], object: passwordReset });
  };

  return <ResetPasswordForm
    resetPasswordDataParams={{ optimisticLockVersion: props.optimisticLockVersion, token: props.token }}
    onFormSubmit={handlePasswordResetFormSubmit} />
}

const mapStateToProps = (state: RootState): StateProps => ({
  optimisticLockVersion: parseInt(selectRouterLocationSearchParam(state, "optimisticLockVersion")),
  token: selectRouterLocationSearchParam(state, "token")
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    resetPassword: resetPasswordActions.request,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
