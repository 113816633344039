import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, TreeSelect } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DataNode } from "rc-tree-select/lib/interface";
import { Store } from "rc-field-form/lib/interface";
import { ProductGroupWithProducts } from "../../../../enumerations/types";
import { ContractFilterPageRequest, ContractFilterPageResult, ContractList } from "../../../types";
import { ContractStatus, contractStatusTMap } from "../../../enums";
import {
  disableDatePickerFuture,
  getDatePickerFormat,
  momentToDateString,
  momentToDateTimeString,
  resolveFormValidationError,
  selectTagsStandardProps,
  toMomentArray,
  treeNodeFilterFunction
} from "../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { formatLocaleCurrency, formatLocaleDate, formatProductName } from "../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import InstitutionsEnumFormItemSelect from "../../../../enumerations/components/form/InstitutionsEnumFormItemSelect";
import PersonsEnumFormItemSelect from "../../../../enumerations/components/form/PersonsEnumFormItemSelect";
import InputNumberWithAddon from "../../../../../common/components/form/components/InputNumberWithAddon";
import ContractStatusTag from "../../ContractStatusTag";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";

export interface Props {
  productGroups: ProductGroupWithProducts[];
  contractsPage: ContractFilterPageResult<ContractList>;
  onFilterSubmit(filter: ContractFilterPageRequest): void;
}

const ContractListFilterView = (props: Props) => {

  const [form] = Form.useForm();
  const [filterModalVisible, setFilterModelVisible] = useState<boolean>(false);
  const [filterModalText, setFilterModalText] = useState<string>();

  useEffect(() => {
    setFilterModalText(modalFilterToText({ ...props.contractsPage }));
  }, [props.contractsPage]);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: ContractFilterPageRequest | Store) => {
        const processedValues = { ...values } as ContractFilterPageRequest;
        const productIds = [];

        processedValues.productIds?.forEach(id => {
          const insuranceProductGroup = props.productGroups.filter(productGroup => productGroup.id === id);
          insuranceProductGroup.length > 0
            ? insuranceProductGroup[0].products.forEach(product => productIds.push(product.id))
            : productIds.push(id);
        });

        props.onFilterSubmit({
          ...processedValues,
          productIds,
          createdAtMin: momentToDateTimeString(processedValues.createdAtRange?.[0]?.startOf("day")),
          createdAtMax: momentToDateTimeString(processedValues.createdAtRange?.[1]?.endOf("day")),
          createdAtRange: undefined,
          effectiveBeginningDateOrSignDateMin: momentToDateString(processedValues.effectiveBeginningDateOrSignDateRange?.[0]),
          effectiveBeginningDateOrSignDateMax: momentToDateString(processedValues.effectiveBeginningDateOrSignDateRange?.[1]),
          effectiveBeginningDateOrSignDateRange: undefined,
          effectiveEndDateMin: momentToDateString(processedValues.effectiveEndDateRange?.[0]),
          effectiveEndDateMax: momentToDateString(processedValues.effectiveEndDateRange?.[1]),
          effectiveEndDateRange: undefined,
          cancellationDateMin: momentToDateString(processedValues.cancellationDateRange?.[0]),
          cancellationDateMax: momentToDateString(processedValues.cancellationDateRange?.[1]),
          cancellationDateRange: undefined,
          transferredToOtherBrokerDateMin: momentToDateString(processedValues.transferredToOtherBrokerDateRange?.[0]),
          transferredToOtherBrokerDateMax: momentToDateString(processedValues.transferredToOtherBrokerDateRange?.[1]),
          transferredToOtherBrokerDateRange: undefined,
          vehicleFirstRegistrationYearMin: processedValues.vehicleFirstRegistrationYearRange?.[0]?.get("year"),
          vehicleFirstRegistrationYearMax: processedValues.vehicleFirstRegistrationYearRange?.[1]?.get("year"),
          vehicleFirstRegistrationYearRange: undefined
        });
        setFilterModelVisible(false);
      })
      .catch(resolveFormValidationError);
  };

  const handleModalCancel = (): void => {
    form.resetFields([
      ["createdAtRange"], ["effectiveBeginningDateOrSignDateRange"], ["effectiveEndDateRange"], ["cancellationDateRange"],
      ["transferredToOtherBrokerDateRange"], ["annualPremiumOrApprovedAmountMin"], ["annualPremiumOrApprovedAmountMax"],
      ["vehicleFirstRegistrationYearRange"]
    ]);
    setFilterModelVisible(false);
  };

  const clearModalFilterFields = (): void => {
    form.setFieldsValue({
      createdAtRange: undefined,
      effectiveBeginningDateOrSignDateRange: undefined,
      effectiveEndDateRange: undefined,
      cancellationDateRange: undefined,
      transferredToOtherBrokerDateRange: undefined,
      annualPremiumOrApprovedAmountMin: null,
      annualPremiumOrApprovedAmountMax: null,
      vehicleFirstRegistrationYearRange: undefined
    } as ContractFilterPageRequest);
  };

  const clearMainFilterFields = (): void => {
    form.setFieldsValue({
      keyword: undefined,
      institutionIds: [],
      productIds: [],
      personIds: [],
      statuses: []
    } as ContractFilterPageRequest);
  };

  const handleModalFilterClear = (): void => {
    clearModalFilterFields();
    handleFormSubmit();
  };

  const handleFilterClear = (): void => {
    clearModalFilterFields();
    clearMainFilterFields();
    handleFormSubmit();
  };

  const createProductTree = (): DataNode[] => {
    return props.productGroups.map<DataNode>(productGroup => ({
      value: productGroup.id,
      title: productGroup.name,
      children: productGroup.products.filter(product => !product.deactivated).map<DataNode>(product => ({
        value: product.id,
        title: formatProductName(product)
      }))
    }));
  };

  const modalFilterToText = (filter: ContractFilterPageRequest): string => {
    const formatDateRange = (label: string, min: string, max: string): string => {
      return `${label}: ${formatLocaleDate(min)} ~ ${formatLocaleDate(max)}`;
    };
    const params = [];

    if ( filter.createdAtMin && filter.createdAtMax ) {
      params.push(formatDateRange(t("contract.filter.createdAtRange"), filter.createdAtMin, filter.createdAtMax));
    }
    if ( filter.effectiveBeginningDateOrSignDateMin && filter.effectiveBeginningDateOrSignDateMax ) {
      params.push(formatDateRange(t("contract.filter.effectiveBeginningDateOrSignDateRange"),
        filter.effectiveBeginningDateOrSignDateMin, filter.effectiveBeginningDateOrSignDateMax));
    }
    if ( filter.effectiveEndDateMin && filter.effectiveEndDateMax ) {
      params.push(formatDateRange(t("contract.filter.effectiveEndDateRange"), filter.effectiveEndDateMin, filter.effectiveEndDateMax));
    }
    if ( filter.cancellationDateMin && filter.cancellationDateMax ) {
      params.push(formatDateRange(t("contract.filter.cancellationDateRange"), filter.cancellationDateMin, filter.cancellationDateMax));
    }
    if ( filter.transferredToOtherBrokerDateMin && filter.transferredToOtherBrokerDateMax ) {
      params.push(formatDateRange(t("contract.filter.transferredToOtherBrokerDateRange"),
        filter.transferredToOtherBrokerDateMin, filter.transferredToOtherBrokerDateMax));
    }
    if ( filter.annualPremiumOrApprovedAmountMin ) {
      params.push(`${t("contract.filter.attrs.annualPremiumOrApprovedAmountMin")}: ${formatLocaleCurrency(filter.annualPremiumOrApprovedAmountMin)}`);
    }
    if ( filter.annualPremiumOrApprovedAmountMax ) {
      params.push(`${t("contract.filter.attrs.annualPremiumOrApprovedAmountMax")}: ${formatLocaleCurrency(filter.annualPremiumOrApprovedAmountMax)}`);
    }
    if ( filter.vehicleFirstRegistrationYearMin && filter.vehicleFirstRegistrationYearMax ) {
      params.push(`${t("contract.filter.vehicleFirstRegistrationYearRange")}: ${filter.vehicleFirstRegistrationYearMin} ~ ${filter.vehicleFirstRegistrationYearMax}`);
    }

    return params.join(" | ");
  };

  const { contractsPage } = props;

  const smallColSpan = 3;
  const colSpan = 4;
  const bigColSpan = 5;

  const datePickerRanges: Record<string, [Moment, Moment]> = {
    [t("common.today")]: [moment(), moment()],
    [t("common.thisWeek")]: [moment().startOf("week"), moment().endOf("week")],
    [t("common.thisMonth")]: [moment().startOf("month"), moment().endOf("month")],
    [t("common.lastMonth")]: [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month")
    ]
  };

  return (
    <>
      <h2>{t("contract.titles.list")}</h2>

      <Card size="small" className="card-filter" title={<>
        {t("contract.filter.title")}
        <span className="right-float">
          <ActionTextIcon type="delete" color="red" onClick={handleFilterClear} text={t("common.cancel")} />
        </span>
      </>}>

        <Form form={form} name="contractsFilterForm" layout="vertical">
          <Row gutter={rowGutter} justify="center">

            <Col span={colSpan}>
              <Form.Item
                name="keyword"
                label={<LabelWithPopover
                  label={t("contract.filter.keyword")}
                  popoverTitle={t("contract.filter.helpers.keywordPopoverTitle")}
                  popoverContent={
                    <>
                      {t("contract.filter.helpers.keywordPopoverSearchBy")}<br />
                      <ul>
                        <li>{t("contract.filter.helpers.keywordPopoverSearchBy1")}</li>
                        <li>{t("contract.filter.helpers.keywordPopoverSearchBy2")}</li>
                        <li>{t("contract.filter.helpers.keywordPopoverSearchBy3")}</li>
                        <li>{t("contract.filter.helpers.keywordPopoverSearchBy4")}</li>
                      </ul>
                    </>} />}
                rules={[validations.size(validationConstants.SEARCH_KEYWORD_MIN_LENGTH, validationConstants.SEARCH_KEYWORD_MAX_LENGTH)]}
                initialValue={contractsPage.keyword}>
                <Input allowClear />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <InstitutionsEnumFormItemSelect
                formItemProps={{
                  name: "institutionIds",
                  label: t("contract.filter.institutions"),
                  initialValue: contractsPage.institutionIds || []
                }}
                selectProps={{ mode: "multiple", allowClear: true }}
                optionsProps={{ groupByType: true }} />
            </Col>

            <Col span={bigColSpan}>
              <Form.Item
                name="productIds"
                label={t("contract.filter.products")}
                initialValue={contractsPage.productIds || []}>
                <TreeSelect
                  treeCheckable
                  allowClear
                  showCheckedStrategy={TreeSelect.SHOW_PARENT}
                  showSearch
                  dropdownMatchSelectWidth={false}
                  filterTreeNode={treeNodeFilterFunction}
                  treeData={createProductTree()} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <PersonsEnumFormItemSelect
                formItemProps={{
                  name: "personIds",
                  label: t("contract.filter.persons"),
                  initialValue: contractsPage.personIds || []
                }}
                selectProps={{ mode: "multiple", allowClear: true }} />
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="statuses"
                label={t("contract.filter.statuses")}
                initialValue={contractsPage.statuses || []}>
                <Select
                  {...selectTagsStandardProps(contractStatusTMap)}
                  allowClear
                  mode="multiple"
                  tagRender={props => <ContractStatusTag status={ContractStatus[props.value as string]}
                                                         closable={props.closable} onClose={props.onClose} />}
                  options={Object.keys(ContractStatus).map(status => ({
                    value: status,
                    label: <ContractStatusTag status={ContractStatus[status]} />
                  }))} />
              </Form.Item>
            </Col>

            <Col span={smallColSpan} className="center-align">
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} onClick={handleFormSubmit}>
                  {t("contract.filter.submit")}
                </Button>
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={rowGutter}>
            <Col span={24} className="extended-filter-action">
              <ActionTextIcon
                type="edit"
                color="blue"
                text={t("contract.filter.extended")}
                onClick={() => setFilterModelVisible(true)} />
              {filterModalText && <>
                <span> {filterModalText} </span>
                <ActionTextIcon
                  type="delete" color="red" onClick={handleModalFilterClear} text={t("common.cancel")} />
              </>}
            </Col>
          </Row>

          <Modal
            width={700}
            visible={filterModalVisible}
            title={t("contract.filter.extended")}
            okText={t("contract.filter.submit")}
            okButtonProps={{ icon: <SearchOutlined /> }}
            cancelText={t("common.cancel")}
            maskClosable={false}
            onOk={handleFormSubmit}
            onCancel={handleModalCancel}>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="createdAtRange"
                  label={t("contract.filter.createdAtRange")}
                  initialValue={toMomentArray([contractsPage.createdAtMin, contractsPage.createdAtMax])}>
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    disabledDate={current => disableDatePickerFuture(current)}
                    ranges={{
                      [t("common.today")]: [moment(), moment()],
                      [t("common.thisWeek")]: [moment().startOf("week"), moment()],
                      [t("common.thisMonth")]: [moment().startOf("month"), moment()],
                      [t("common.lastMonth")]: [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month")
                      ]
                    }}
                    placeholder={[t("common.from"), t("common.to")]} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="effectiveBeginningDateOrSignDateRange"
                  label={t("contract.filter.effectiveBeginningDateOrSignDateRange")}
                  initialValue={toMomentArray([contractsPage.effectiveBeginningDateOrSignDateMin, contractsPage.effectiveBeginningDateOrSignDateMax])}>
                  <DatePicker.RangePicker format={getDatePickerFormat()} ranges={datePickerRanges}
                                          placeholder={[t("common.from"), t("common.to")]} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="effectiveEndDateRange"
                  label={t("contract.filter.effectiveEndDateRange")}
                  initialValue={toMomentArray([contractsPage.effectiveEndDateMin, contractsPage.effectiveEndDateMax])}>
                  <DatePicker.RangePicker format={getDatePickerFormat()} ranges={datePickerRanges}
                                          placeholder={[t("common.from"), t("common.to")]} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="cancellationDateRange"
                  label={t("contract.filter.cancellationDateRange")}
                  initialValue={toMomentArray([contractsPage.cancellationDateMin, contractsPage.cancellationDateMax])}>
                  <DatePicker.RangePicker format={getDatePickerFormat()} ranges={datePickerRanges}
                                          placeholder={[t("common.from"), t("common.to")]} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="transferredToOtherBrokerDateRange"
                  label={t("contract.filter.transferredToOtherBrokerDateRange")}
                  initialValue={toMomentArray([contractsPage.transferredToOtherBrokerDateMin, contractsPage.transferredToOtherBrokerDateMax])}>
                  <DatePicker.RangePicker format={getDatePickerFormat()} ranges={datePickerRanges}
                                          placeholder={[t("common.from"), t("common.to")]} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  label={t("contract.filter.annualPremiumOrApprovedAmountRange")}>
                  <Row gutter={rowGutter}>
                    <Col span={12}>
                      <Form.Item
                        name="annualPremiumOrApprovedAmountMin"
                        initialValue={contractsPage.annualPremiumOrApprovedAmountMin}>
                        <InputNumberWithAddon addonType="euro" placeholder={t("common.from")} formatStyle="integer"
                                              min={1} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) => prev.annualPremiumOrApprovedAmountMin !== next.annualPremiumOrApprovedAmountMin}>
                        {({ getFieldValue }) => {
                          const min = getFieldValue(["annualPremiumOrApprovedAmountMin"]) || 1;
                          return <Form.Item
                            name="annualPremiumOrApprovedAmountMax"
                            initialValue={contractsPage.annualPremiumOrApprovedAmountMax}>
                            <InputNumberWithAddon addonType="euro" placeholder={t("common.to")} formatStyle="integer"
                                                  min={min} />
                          </Form.Item>;
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="vehicleFirstRegistrationYearRange"
                  label={t("contract.filter.vehicleFirstRegistrationYearRange")}
                  initialValue={
                    contractsPage.vehicleFirstRegistrationYearMin && contractsPage.vehicleFirstRegistrationYearMax
                    && [moment().year(contractsPage.vehicleFirstRegistrationYearMin), moment().year(contractsPage.vehicleFirstRegistrationYearMax)]
                  }>
                  <DatePicker.RangePicker picker="year" disabledDate={current => disableDatePickerFuture(current)}
                                          defaultPickerValue={[moment().subtract(12, "year"), moment().subtract(6, "year")]}
                                          placeholder={[t("common.from"), t("common.to")]} />
                </Form.Item>
              </Col>
            </Row>

          </Modal>
        </Form>

      </Card>
    </>
  )
}

export default ContractListFilterView;
