import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import calcsReducer, { calcsSaga } from "./calcs/ducks";
import recordsReducer, { calcRecordsSaga } from "./records/ducks";
import settingsReducer, { calcSettingsSaga } from "./settings/ducks";
import draftsReducer, { calcDraftsSaga } from "./drafts/ducks";
import vehiclesReducer, { calcVehiclesSaga } from "./vehicles/ducks";

/**
 * REDUCERS
 */
export default combineReducers({
  calcs: calcsReducer,
  records: recordsReducer,
  settings: settingsReducer,
  drafts: draftsReducer,
  vehicles: vehiclesReducer
});

/**
 * SAGAS
 */
export function* calculatorSaga() {
  yield all([calcsSaga(), calcRecordsSaga(), calcSettingsSaga(), calcDraftsSaga(), calcVehiclesSaga()]);
}
