import React, { useEffect } from "react";
import { Button, Checkbox, Col, Form, Popconfirm, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { CalcSettings, CreateCalcSettings, UpdateCalcSettings } from "../types";
import { UUID } from "../../../../common/types";
import { InstitutionType } from "../../../admin/institution/enums";
import { CalcType } from "../../enums";
import {
  createCalcSettingsActions,
  deleteCalcSettingsActions,
  deleteCalcSettingsAttachmentConfActions,
  updateCalcSettingsActions,
  uploadCalcSettingsAttachmentConfsActions
} from "../ducks";
import { removeStringWhiteSpaces } from "../../../../common/utils/utils";
import { useFormErrorHandler } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { formatIban } from "../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../common/constants";
import { requests } from "../api";
import t from "../../../../app/i18n";

import CalcSettingsProductsPart from "./parts/CalcSettingsProductsPart";
import CalcSettingsPaymentDetailsPart from "./parts/CalcSettingsPaymentDetailsPart";
import CalcSettingsAttachmentConfsPart from "./parts/CalcSettingsAttachmentConfsPart";
import InstitutionsEnumFormItemSelect from "../../../enumerations/components/form/InstitutionsEnumFormItemSelect";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  calcType: CalcType;
  calcSettings: CalcSettings[];
  onCreateCalcSettings: typeof createCalcSettingsActions.request;
  onUpdateCalcSettings: typeof updateCalcSettingsActions.request;
  onDeleteCalcSettings: typeof deleteCalcSettingsActions.request;
  onUploadAttachmentConf: typeof uploadCalcSettingsAttachmentConfsActions.request;
  onDeleteAttachmentConf: typeof deleteCalcSettingsAttachmentConfActions.request;
}

const CalcSettingsForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "calc.settings.attrs", requests.CREATE_CALC_SETTINGS);
  useFormErrorHandler(form, "calc.settings.attrs", requests.UPDATE_CALC_SETTINGS);

  useEffect(() => {
    if ( props.calcSettings.filter(s => s.type === props.calcType).length > 0 ) {
      const calcSettings = props.calcSettings.filter(s => s.type === props.calcType);
      const institutionId = form.getFieldValue("insuranceInstitutionId");
      if ( !institutionId ) {
        setCalcSettingsToForm(calcSettings[0]);
      }
      else {
        setCalcSettingsToForm(calcSettings.find(s => s.insuranceInstitution.id === institutionId));
      }
    }
  }, [props.calcSettings, props.calcType, form]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleInsuranceInstitutionIdChange = (institutionId: UUID): void => {
    setCalcSettingsToForm(props.calcSettings.find(s => s.insuranceInstitution.id === institutionId), institutionId);
  };

  const handleFormFinish = (values: CreateCalcSettings | UpdateCalcSettings): void => {
    const formData = { ...values };
    formData.paymentDetails.bankAccounts = [...formData.paymentDetails.bankAccounts].map(account => removeStringWhiteSpaces(account));

    const selectedCalcSettings = props.calcSettings.find(s => s.insuranceInstitution.id === values.insuranceInstitutionId);

    if ( selectedCalcSettings ) {
      (formData as UpdateCalcSettings).attachmentConfs = (formData as UpdateCalcSettings).attachmentConfs || [];
      delete formData.insuranceInstitutionId;
      props.onUpdateCalcSettings({ object: formData as UpdateCalcSettings, id: selectedCalcSettings.id });
    }
    else {
      props.onCreateCalcSettings(formData as CreateCalcSettings);
    }
  };

  const handleCalcSettingsDelete = (calcSettingsId: UUID): void => {
    form.resetFields();
    props.onDeleteCalcSettings({ id: calcSettingsId });
  };

  const setCalcSettingsToForm = (calcSettings: CalcSettings, insuranceInstitutionId?: UUID): void => {
    form.resetFields();
    if ( calcSettings ) {
      const formData = {
        ...calcSettings,
        paymentDetails: {
          ...calcSettings.paymentDetails,
          bankAccounts: calcSettings.paymentDetails.bankAccounts.map(account => formatIban(account))
        },
        insuranceInstitutionId: calcSettings.insuranceInstitution.id,
        insuranceInstitution: null,
        mtplProduct: null,
        crashProduct: null,
        gapProduct: null,
        pasProduct: null,
        realtyProduct: null,
        shortTermInsuranceProduct: null,
        yearInsuranceProduct: null,
        cancellationInsuranceProduct: null
      } as UpdateCalcSettings;

      formData.mtplProductId = calcSettings.mtplProduct?.id;
      formData.crashProductId = calcSettings.crashProduct?.id;
      formData.gapProductId = calcSettings.gapProduct?.id;
      formData.pasProductId = calcSettings.pasProduct?.id;
      formData.realtyProductId = calcSettings.realtyProduct?.id;
      formData.shortTermInsuranceProductId = calcSettings.shortTermInsuranceProduct?.id;
      formData.yearInsuranceProductId = calcSettings.yearInsuranceProduct?.id;
      formData.cancellationInsuranceProductId = calcSettings.cancellationInsuranceProduct?.id;

      form.setFieldsValue(formData);
    }
    else {
      form.setFieldsValue({ insuranceInstitutionId, paymentDetails: { bankAccounts: [""] }, });
    }
  }

  const getSelectedCalcSettings = (): CalcSettings => {
    const institutionId = form.getFieldValue("insuranceInstitutionId");
    return props.calcSettings.find(s => s.insuranceInstitution.id === institutionId);
  }

  const formLayout: ColProps = { span: 24, style: { maxWidth: "1000px" } };
  const colSpan = 5;

  return (
    <>
      <h2>{t("calc.settings.titles.settings") + " " + t("calc.enums.calcType." + props.calcType)}</h2>

      <Form form={form} layout="vertical" onFinish={handleFormFinish} name="calcSettingsForm">

        <HiddenInput name="type" initialValue={props.calcType} />
        <HiddenInput name="optimisticLockVersion" />

        <Row>
          <Col {...formLayout}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
              {() => (
                <ItemCreatedUpdatedInfoView
                  item={getSelectedCalcSettings()}
                  className="margin-bottom-small" />
              )}
            </Form.Item>

            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <InstitutionsEnumFormItemSelect
                  formItemProps={{
                    name: "insuranceInstitutionId",
                    label: t("calc.settings.attrs.insuranceInstitutionId"),
                    rules: [validations.notNull]
                  }}
                  selectProps={{ onChange: handleInsuranceInstitutionIdChange }}
                  optionsProps={{
                    optGroups: [
                      {
                        label: t("calc.settings.helpers.institutionCategoryExisting"),
                        filter: institution => institution.type === InstitutionType.INSURANCE_COMPANY
                          && props.calcSettings.some(settings => institution.id === settings.insuranceInstitution.id)
                      },
                      {
                        label: t("calc.settings.helpers.institutionCategoryAbsent"),
                        filter: institution => institution.type === InstitutionType.INSURANCE_COMPANY
                          && props.calcSettings.every(settings => institution.id !== settings.insuranceInstitution.id)
                      }
                    ]
                  }} />
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name="enabled"
                  className="form-item-without-label"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}>
                  <Checkbox>{t("calc.settings.attrs.enabled")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <CalcSettingsProductsPart calcType={props.calcType} />

            <CalcSettingsPaymentDetailsPart />

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
              {() => (
                <CalcSettingsAttachmentConfsPart
                  calcSettings={getSelectedCalcSettings()}
                  onUploadAttachmentConf={props.onUploadAttachmentConf}
                  onDeleteAttachmentConf={props.onDeleteAttachmentConf} />
              )}
            </Form.Item>

            <div className="margin-top-medium">
              <Button type="primary" htmlType="submit" className="margin-right-tiny" icon={<SaveOutlined />}>
                {t("common.save")}
              </Button>

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
                {() => {
                  const calcSettings = getSelectedCalcSettings();
                  return calcSettings ? (
                    <Popconfirm
                      title={t("calc.settings.titles.deleteConfirm")}
                      icon={<PopconfirmDeleteIcon />}
                      okText={t("common.yes")}
                      cancelText={t("common.no")}
                      okType="danger"
                      onConfirm={() => handleCalcSettingsDelete(calcSettings.id)}>
                      <Button icon={<DeleteOutlined />} danger>{t("common.delete")}</Button>
                    </Popconfirm>
                  ) : null
                }}
              </Form.Item>
            </div>

          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CalcSettingsForm;
