import React from "react";
import { Button, Col, Collapse, Row, Tag, Tree } from "antd";
import { ColProps } from "antd/lib/grid";
import { EditOutlined } from "@ant-design/icons";
import { DataNode } from "rc-tree/lib/interface";
import { AdminPersonAccount } from "../../types";
import { Permission, permissionStructureMap } from "../../../../common/security/authorization/enums";
import { contains } from "../../../../common/utils/utils";
import { formatPersonName } from "../../../../common/utils/formatUtils";
import t from "../../../../app/i18n";

import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";

export interface Props {
  accounts: AdminPersonAccount[];
  onUpdateClick(account: AdminPersonAccount): void;
}

const PersonAccountsView = (props: Props) => {

  const buildPermissionTreeNodes = (accountPermissions: Permission[]): DataNode[] => {
    return [...permissionStructureMap].map<DataNode>(([permission, subordinatePermissions]) => ({
        title: t("account.enums.permission." + permission),
        key: permission,
        value: permission,
        selectable: false,
        disabled: !contains(accountPermissions, permission),
        children: subordinatePermissions.map<DataNode>(subordinatePermission => ({
          title: t("account.enums.permission." + subordinatePermission),
          key: subordinatePermission,
          value: permission,
          selectable: false,
          disabled: !contains(accountPermissions, subordinatePermission)
        }))
      })
    );
  };

  const layout: ColProps = { span: 24, style: { maxWidth: "900px" } };

  return props.accounts.length > 0 ? (
    <Collapse accordion>
      {props.accounts.map(account => (
        <Collapse.Panel
          key={account.id}
          header={account.confirmed
            ? account.name ? `${account.name} | ${account.username}` : account.username
            : account.name ? `${account.name} | ${account.email}` : account.email}
          extra={
            <span onClick={event => event.stopPropagation()}>
              {!account.confirmed && <Tag color="orange">{t("account.helpers.unconfirmedAccount")}</Tag>}
              {account.disabled && <Tag color="purple">{t("account.helpers.disabledAccount")}</Tag>}
              {account.locked && <Tag color="red">{t("account.helpers.lockedAccount")}</Tag>}
            </span>
          }>

          <Row>
            <Col {...layout}>
              <ItemCreatedUpdatedInfoView item={account} />

              <table className="data-table-view margin-bottom-medium margin-top-medium">
                <tbody>
                <tr>
                  <td>{t("account.attrs.name")}:</td>
                  <td>{account.name}</td>
                  <td>{t("account.attrs.backOfficeTarget")}:</td>
                  <td>{formatPersonName(account.backOfficeTarget)}</td>
                </tr>
                <tr>
                  <td>{t("account.attrs.username")}:</td>
                  <td>{account.confirmed ? account.username : null}</td>
                  <td>{t("account.attrs.representativeTarget")}:</td>
                  <td>{formatPersonName(account.representativeTarget)}</td>
                </tr>
                <tr>
                  <td>{t("account.attrs.email")}:</td>
                  <td>{account.email}</td>
                  <td>{t("account.attrs.wrongLoginAttempts")}:</td>
                  <td>{account.wrongLoginAttempts}</td>
                </tr>
                </tbody>
              </table>

              <h4>{t("account.attrs.permissionsLabel")}</h4>
              <Tree
                showLine
                treeData={buildPermissionTreeNodes(account.permissions)} />

              <Button className="margin-top-medium" type="primary" icon={<EditOutlined />}
                      onClick={() => props.onUpdateClick(account)}>
                {t("account.actions.updateAccount")}
              </Button>
            </Col>
          </Row>

        </Collapse.Panel>
      ))}
    </Collapse>
  ) : null;
};

export default PersonAccountsView;
