import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  LifeInsuranceTariff,
  LifeInsuranceTariffFilterPageRequest,
  LifeInsuranceTariffFilterPageResult
} from "../types";
import { ActionProps, RootState } from "../../../../common/types";
import { selectRouterLocationSearch } from "../../../ducks";
import {
  createLifeInsuranceTariffActions,
  deleteLifeInsuranceTariffActions,
  deleteStateLifeInsuranceTariffsPageAction,
  filterLifeInsuranceTariffsActions,
  selectLifeInsuranceTariffsCurrentPage,
  updateLifeInsuranceTariffActions
} from "../ducks";
import { appendSearchParamsToURL } from "../../../../common/utils/utils";
import t from "../../../../app/i18n";

import LifeInsuranceTariffSearchView from "../components/views/LifeInsuranceTariffSearchView";
import LifeInsuranceTariffTableView from "../components/views/LifeInsuranceTariffTableView";
import LifeInsuranceTariffForm from "../components/forms/LifeInsuranceTariffForm";

interface StateProps {
  initialUrlSearchQuery: string;
  tariffsCurrentPage: LifeInsuranceTariffFilterPageResult;
}

interface ActionsMap {
  filterLifeInsuranceTariff: typeof filterLifeInsuranceTariffsActions.request;
  createLifeInsuranceTariff: typeof createLifeInsuranceTariffActions.request;
  updateLifeInsuranceTariff: typeof updateLifeInsuranceTariffActions.request;
  deleteLifeInsuranceTariff: typeof deleteLifeInsuranceTariffActions.request;
  deleteStateLifeInsuranceTariffsPage: typeof deleteStateLifeInsuranceTariffsPageAction;
  replaceNavigation: typeof replace;
}

const PAGE_SIZE = 20;

const LifeInsuranceTariffsContainer = (props: StateProps & ActionProps<ActionsMap>) => {

  useEffect(() => {
    const urlParams = new URLSearchParams(props.initialUrlSearchQuery);
    props.actions.filterLifeInsuranceTariff({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
      keyword: urlParams.get("keyword"),
      productIds: urlParams.getAll("productIds"),
    });
    return () => props.actions.deleteStateLifeInsuranceTariffsPage();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const [isShowingTariffModal, setIsShowingTariffModal] = useState<boolean>(false);
  const [tariffToUpdate, setTariffToUpdate] = useState<LifeInsuranceTariff>();

  const handleFilterSubmit = (filter: LifeInsuranceTariffFilterPageRequest): void => {
    props.actions.replaceNavigation(appendSearchParamsToURL(filter));
    props.actions.filterLifeInsuranceTariff({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
      keyword: filter.keyword,
      productIds: filter.productIds
    })
  }

  const handleTablePageChange = (pageNumber: number): void => {
    const { productIds, keyword, } = props.tariffsCurrentPage;
    props.actions.filterLifeInsuranceTariff({
      pageIndex: pageNumber - 1,
      pageSize: PAGE_SIZE,
      keyword: keyword,
      productIds: productIds,
    });
  };

  const handleCreateClick = (): void => {
    setIsShowingTariffModal(true);
  }

  const handleCancelModal = (): void => {
    setIsShowingTariffModal(false);
    setTariffToUpdate(null);
  }

  const handleUpdateClick = (tariff: LifeInsuranceTariff): void => {
    setIsShowingTariffModal(true);
    setTariffToUpdate(tariff);
  }

  return (
    <>
      <Row>
        <Col span={12}>
          <h2>{t("admin.lifeInsuranceTariff.titles.list")}</h2>
        </Col>

        <Col span={12} className="right-align">
          <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateClick}>
            {t("admin.lifeInsuranceTariff.actions.createTariff")}
          </Button>
        </Col>
      </Row>

      <LifeInsuranceTariffSearchView
        initialFilter={props.tariffsCurrentPage}
        onFilterSubmit={handleFilterSubmit}
      />

      <LifeInsuranceTariffTableView
        tariffsPage={props.tariffsCurrentPage}
        onTablePageChange={handleTablePageChange}
        onUpdateClick={handleUpdateClick}
        onDelete={props.actions.deleteLifeInsuranceTariff}
      />

      <LifeInsuranceTariffForm
        defaultProductId={props.tariffsCurrentPage.productIds?.[0]}
        isVisible={isShowingTariffModal}
        tariff={tariffToUpdate}
        onCreate={props.actions.createLifeInsuranceTariff}
        onUpdate={props.actions.updateLifeInsuranceTariff}
        onCancelModal={handleCancelModal}
      />
    </>
  );
}

const mapStateToProps = (state: RootState): StateProps => ({
  initialUrlSearchQuery: selectRouterLocationSearch(state),
  tariffsCurrentPage: selectLifeInsuranceTariffsCurrentPage(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterLifeInsuranceTariff: filterLifeInsuranceTariffsActions.request,
    createLifeInsuranceTariff: createLifeInsuranceTariffActions.request,
    updateLifeInsuranceTariff: updateLifeInsuranceTariffActions.request,
    deleteLifeInsuranceTariff: deleteLifeInsuranceTariffActions.request,
    deleteStateLifeInsuranceTariffsPage: deleteStateLifeInsuranceTariffsPageAction,
    replaceNavigation: replace,
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(LifeInsuranceTariffsContainer);
