import React from "react";
import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CommissionsBatch } from "../../../types";
import { PageResult } from "../../../../../../common/types";
import { CommissionsBatchStep } from "../../../enums";
import { deleteCommissionsBatchActions } from "../../../ducks";
import { paginationStandardProps } from "../../../../../../common/utils/formUtils";
import { formatLocaleCurrencyWithNullAsZero, formatLocaleDateTime } from "../../../../../../common/utils/formatUtils";
import t from "../../../../../../app/i18n";

import CommissionBatchStepTag from "../../CommissionBatchStepTag";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  batchesPage: PageResult<CommissionsBatch>;
  onDelete: typeof deleteCommissionsBatchActions.request;
  onShowDetailClick(batch: CommissionsBatch): void;
  onUpdateClick(batch: CommissionsBatch): void;
  onPageChange(pageNumber: number): void;
}

const CommissionsBatchTableView = (props: Props) => {

  const columns: ColumnsType<CommissionsBatch> = [
    {
      key: "name",
      title: t("commissions.batch.attrs.name"),
      dataIndex: "name"
    },
    {
      key: "createdAt",
      title: t("common.createdAt"),
      render: (value, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "updatedAt",
      title: t("common.updatedAt"),
      render: (value, record) => formatLocaleDateTime(record.updatedAt)
    },
    {
      key: "step",
      title: t("commissions.batch.enums.batchStep._label"),
      render: (value, record) => <CommissionBatchStepTag step={record.step} />
    },
    {
      key: "currentBatchCommissionAmount",
      title: t("commissions.batch.helpers.currentBatchCommissionAmountDesc"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrencyWithNullAsZero(record.currentBatchCommissionAmount)
    },
    {
      key: "volumeCommissionAmount",
      title: t("commissions.batch.helpers.volumeCommissionAmountDesc"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrencyWithNullAsZero(record.volumeCommissionAmount)
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (value, record) => (
        <>
          <ActionTextIcon type="eye" color="green" text={t("common.show")}
                          onClick={() => props.onShowDetailClick(record)} />

          <Divider type="vertical" />

          <ActionTextIcon type="edit" color="blue" text={t("common.edit")}
                          disabled={record.step === CommissionsBatchStep.FINISH}
                          onClick={() => props.onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("commissions.batch.helpers.deleteBatchConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="primary"
            onConfirm={() => props.onDelete({ id: record.id })}>
            <ActionTextIcon type="delete" color="red" text={t("common.delete")}
                            disabled={record.step === CommissionsBatchStep.FINISH} />
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table<CommissionsBatch>
      size="small"
      className="table-row-stripped"
      rowKey="id"
      columns={columns}
      dataSource={props.batchesPage.pageData}
      pagination={{
        ...paginationStandardProps,
        current: props.batchesPage.pageIndex + 1,
        pageSize: props.batchesPage.pageSize,
        total: props.batchesPage.totalElementsCount,
        onChange: props.onPageChange,
      }} />
  )
};

export default CommissionsBatchTableView;
