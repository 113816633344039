import React from "react";
import { Col, Divider, Row, Tooltip } from "antd";
import { ClientFormStage } from "../enums";
import t from "../../../app/i18n";

import ActionTextIcon from "../../../common/components/icons/ActionTextIcon";

export interface Props {
  label: string;
  stage: ClientFormStage;
  onCreateClick(): void;
  onUpdateClick(): void;
  onDeselectClick?(): void;
}

const ClientActionsFormLabel_Deprecated = ({ label, stage, ...props }: Props) => {

  let actions = null;
  switch ( stage ) {
    case ClientFormStage.NEW:
      actions = <Tooltip title={t("client.actions.createClient")}>
        <span><ActionTextIcon type="user-add" color="green" size="large" onClick={props.onCreateClick} /></span>
      </Tooltip>;
      break;
    case ClientFormStage.EXISTING:
    case ClientFormStage.SELECTED:
      actions = <>
        <Tooltip title={t("client.actions.updateClient")}>
          <span><ActionTextIcon type="edit" color="blue" size="large" onClick={props.onUpdateClick} /></span>
        </Tooltip>
        {props.onDeselectClick && (
          <>
            <Divider type="vertical" />
            <Tooltip title={t("client.actions.deselectClient")}>
              <span><ActionTextIcon type="close" color="red" size="large" onClick={props.onDeselectClick} /></span>
            </Tooltip>
          </>
        )}
      </>;
      break;
  }

  return (
    <span>
      <Row>
        <Col span={16}>
          <span className="ant-legacy-form-item-required">{label}</span>
        </Col>
        <Col span={8} className="right-align">{actions}</Col>
      </Row>
    </span>
  );
};

export default ClientActionsFormLabel_Deprecated;
