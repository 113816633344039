import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import api from "./api";
import {
  AttachmentConf,
  CalcSettings,
  CalcSettingsReducerState,
  CalcSettingsRequest,
  CreateCalcSettings,
  UpdateCalcSettings
} from "./types";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  TwoLevelEntityIdRequest
} from "../../../common/types";
import { openBlobFile, removeFromArray, replaceInArray } from "../../../common/utils/utils";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import messageUtils from "../../../common/utils/messageUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  CREATE = 'calc-settings/CREATE',
  GET = 'calc-settings/GET',
  UPDATE = 'calc-settings/UPDATE',
  DELETE = 'calc-settings/DELETE',
  DELETE_STATE_ITEMS = 'calc-settings/DELETE_STATE_ITEMS',
  UPLOAD_ATTACHMENT_CONFS = 'calc-settings-attachment/UPLOAD',
  DOWNLOAD_ATTACHMENT_CONF = 'calc-settings-attachment/DOWNLOAD',
  DELETE_ATTACHMENT_CONF = 'calc-settings-attachment/DELETE'
}

/**
 * ACTIONS
 */
export const createCalcSettingsActions = createApiActionCreators<CreateCalcSettings, CalcSettings[]>(actionType.CREATE);
export const getCalcSettingsActions = createApiActionCreators<CalcSettingsRequest, CalcSettings[]>(actionType.GET);
export const updateCalcSettingsActions = createApiActionCreators<EntityObjectRequest<UpdateCalcSettings>, CalcSettings[]>(actionType.UPDATE);
export const deleteCalcSettingsActions = createApiActionCreators<EntityIdRequest, CalcSettings[]>(actionType.DELETE);

export const deleteStateCalcSettingsItemsAction = createActionCreator<void>(actionType.DELETE_STATE_ITEMS);

export const uploadCalcSettingsAttachmentConfsActions = createApiActionCreators<EntityObjectRequest<FormData>, CalcSettings[]>(actionType.UPLOAD_ATTACHMENT_CONFS);
export const downloadCalcSettingsAttachmentConfActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.DOWNLOAD_ATTACHMENT_CONF);
export const deleteCalcSettingsAttachmentConfActions = createApiActionCreators<TwoLevelEntityIdRequest, CalcSettings[]>(actionType.DELETE_ATTACHMENT_CONF);

/**
 * REDUCERS
 */
const initialState: CalcSettingsReducerState = {
  items: []
};

const calcSettingsReducer = createReducer<CalcSettings[]>(initialState.items, {
  [actionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.items
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.UPLOAD_ATTACHMENT_CONFS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DOWNLOAD_ATTACHMENT_CONF]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_ATTACHMENT_CONF]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_ITEMS]: () => initialState.items
});

export default combineReducers({ items: calcSettingsReducer });

/**
 * SELECTORS
 */
const selectCalcSettings = (state: RootState): CalcSettingsReducerState => state.calculator.settings;

export const selectCalcSettingsItems = (state: RootState): CalcSettings[] => selectCalcSettings(state).items;

/**
 * SAGAS
 */
function* createCalcSettings({ payload }: ActionCreator<CreateCalcSettings>) {
  try {
    const response: AxiosResponse<CalcSettings> = yield call(api.createCalcSettings, payload);
    const items: CalcSettings[] = [...yield select(selectCalcSettingsItems)];
    items.push(response.data);
    yield put(createCalcSettingsActions.success(items));
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createCalcSettingsActions.failure(error));
  }
}

function* getCalcSettings({ payload }: ActionCreator<CalcSettingsRequest>) {
  try {
    const response: AxiosResponse<CalcSettings[]> = yield call(api.getCalcSettings, payload);
    yield put(getCalcSettingsActions.success(response.data));
  }
  catch ( error ) {
    yield put(getCalcSettingsActions.failure(error));
  }
}

function* updateCalcSettings({ payload }: ActionCreator<EntityObjectRequest<UpdateCalcSettings>>) {
  try {
    const response: AxiosResponse<CalcSettings> = yield call(api.updateCalcSettings, payload);
    let items: CalcSettings[] = yield select(selectCalcSettingsItems);
    items = replaceInArray(items, item => item.id === response.data.id, response.data);
    yield put(updateCalcSettingsActions.success(items));
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateCalcSettingsActions.failure(error));
  }
}

function* deleteCalcSettings({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteCalcSettings, payload);
    let items: CalcSettings[] = yield select(selectCalcSettingsItems);
    items = removeFromArray(items, item => item.id === payload.id);
    yield put(deleteCalcSettingsActions.success(items));
  }
  catch ( error ) {
    yield put(deleteCalcSettingsActions.failure(error));
  }
}

function* uploadAttachmentConfs({ payload }: ActionCreator<EntityObjectRequest<FormData>>) {
  try {
    const response: AxiosResponse<AttachmentConf[]> = yield call(api.uploadAttachmentConfs, payload);
    let items: CalcSettings[] = [...yield select(selectCalcSettingsItems)].map(item => ({ ...item }));
    const settings = items.find(sett => sett.id === payload.id);
    settings.attachmentConfs = response.data;
    yield put(uploadCalcSettingsAttachmentConfsActions.success(items));
  }
  catch ( error ) {
    yield put(uploadCalcSettingsAttachmentConfsActions.failure(error));
  }
}

function* downloadAttachmentConf({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    const response: AxiosResponse = yield call(api.downloadAttachmentConf, payload);
    openBlobFile(response);
    yield put(downloadCalcSettingsAttachmentConfActions.success());
  }
  catch ( error ) {
    yield put(downloadCalcSettingsAttachmentConfActions.failure(error));
  }
}

function* deleteAttachmentConf({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.deleteAttachmentConf, payload);
    const items: CalcSettings[] = [...yield select(selectCalcSettingsItems)].map(item => ({ ...item }));
    const settings = items.find(sett => sett.id === payload.id1);
    settings.attachmentConfs = removeFromArray(settings.attachmentConfs, conf => conf.id === payload.id2);
    yield put(deleteCalcSettingsAttachmentConfActions.success(items));
  }
  catch ( error ) {
    yield put(deleteCalcSettingsAttachmentConfActions.failure(error));
  }
}

export function* calcSettingsSaga() {
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createCalcSettings);
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getCalcSettings);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateCalcSettings);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deleteCalcSettings);
  yield takeLatest(createActionType(actionType.UPLOAD_ATTACHMENT_CONFS, apiOperation.REQUEST), uploadAttachmentConfs);
  yield takeLatest(createActionType(actionType.DOWNLOAD_ATTACHMENT_CONF, apiOperation.REQUEST), downloadAttachmentConf);
  yield takeLatest(createActionType(actionType.DELETE_ATTACHMENT_CONF, apiOperation.REQUEST), deleteAttachmentConf);
}
