import React from "react";
import { CommissionsBatch } from "../../../../types";

export interface Props {
  batch: CommissionsBatch;
}

const CommissionsBatchCalculationStep = ({ batch }: Props) => {
  return <span>STEP CALCULATION</span>
};

export default CommissionsBatchCalculationStep;
