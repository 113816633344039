import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { DataNode } from "rc-tree/lib/interface";
import {
  Permission,
  permissionPrerequisitesMap,
  permissionStructureMap
} from "../../common/security/authorization/enums";
import { contains, containsAll, getAllPermissionPrerequisites } from "../../common/utils/utils";
import t from "../../app/i18n";

export const buildPermissionTreeNodes = (checkedPermissions: Permission[]): DataNode[] => {
  return [...permissionStructureMap].map<DataNode>(([permission, subordinatePermissions]) => ({
    title: t("account.enums.permission." + permission),
    key: permission,
    value: permission,
    selectable: false,
    children: subordinatePermissions.map<DataNode>(subPermission => {
      const disabled = !containsAll(checkedPermissions, ...permissionPrerequisitesMap.get(subPermission));
      const title = disabled ? (
        <>
          {t("account.enums.permission." + subPermission)}
          <Tooltip placement="right" title={
            <span>
              {t("account.helpers.missingPrerequisites")}:&nbsp;
              {getAllPermissionPrerequisites(subPermission)
                .filter(prerequisite => !contains(checkedPermissions, prerequisite))
                .map(prerequisite => t("account.enums.permission." + prerequisite))
                .join(", ")}
            </span>
          }>
            <QuestionCircleOutlined className="margin-left-tiny cursor-help" />
          </Tooltip>
        </>
      ) : t("account.enums.permission." + subPermission);

      return { title, key: subPermission, selectable: false, disabled, disableCheckbox: disabled }
    })
  }))
};
