import React from "react";
import { MinusCircleOutlined } from "@ant-design/icons";

export interface Props {
  index?: number;
  onClick?(index?: number): void;
}

const DeleteIcon = (props: Props) =>
  <MinusCircleOutlined className="delete-icon" onClick={() => props.onClick?.(props.index)} />;

export default DeleteIcon;
