import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Contract } from "../../types";
import { RootState } from "../../../../common/types";
import { AuthAccount } from "../../../auth/types";
import { PersonType } from "../../../person/enums";
import { Permission } from "../../../../common/security/authorization/enums";
import { selectAccount } from "../../../auth/ducks";
import { hasPermission } from "../../../../common/utils/utils";
import { validations } from "../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../common/constants";
import t from "../../../../app/i18n";

import PersonsEnumFormItemSelect from "../../../enumerations/components/form/PersonsEnumFormItemSelect";

export interface Props {
  initialContract?: Contract;
  changePersonsPermission: Permission;
}

const ContractFormPersonsSection = ({ initialContract, changePersonsPermission }: Props) => {

  const currentAccount = useSelector<RootState, AuthAccount>(selectAccount);
  const disablePersonSelect = initialContract?.verified && !hasPermission(currentAccount.permissions, changePersonsPermission);

  const colSpan = 4;

  return (
    <Row gutter={rowGutter}>
      <Col span={colSpan}>
        <PersonsEnumFormItemSelect
          formItemProps={{
            name: "signerId",
            label: t("contract.attrs.signerId"),
            rules: [validations.notNull],
            initialValue: currentAccount.person.type === PersonType.LEGAL
              ? currentAccount.representativeTarget?.id : currentAccount.person.id
          }}
          selectProps={{ disabled: disablePersonSelect }}
          optionsProps={{
            selected: initialContract?.signer,
            filter: person => person.type !== PersonType.LEGAL
          }} />
      </Col>

      <Col span={colSpan}>
        <PersonsEnumFormItemSelect
          formItemProps={{
            name: "gainer1Id",
            label: t("contract.attrs.gainer1Id"),
            rules: [validations.notNull],
            initialValue: currentAccount.person.id
          }}
          selectProps={{ disabled: disablePersonSelect }}
          optionsProps={{ selected: initialContract?.gainer1 }} />
      </Col>

      <Col span={colSpan}>
        <PersonsEnumFormItemSelect
          formItemProps={{
            name: "gainer2Id",
            label: t("contract.attrs.gainer2Id"),
            rules: [validations.none]
          }}
          selectProps={{ allowClear: true, disabled: disablePersonSelect }}
          optionsProps={{ selected: initialContract?.gainer2 }} />
      </Col>

      <Col span={colSpan}>
        <PersonsEnumFormItemSelect
          formItemProps={{
            name: "managerId",
            label: t("contract.attrs.managerId"),
            rules: [validations.notNull],
            initialValue: currentAccount.person.type === PersonType.LEGAL
              ? currentAccount.representativeTarget?.id : currentAccount.person.id
          }}
          selectProps={{ disabled: disablePersonSelect }}
          optionsProps={{
            selected: initialContract?.manager,
            filter: person => person.type !== PersonType.LEGAL
          }} />
      </Col>
    </Row>
  )
}

export default ContractFormPersonsSection;
