import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Button, Col, Form, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { CreateUpdateLoanContract, LoanContract } from "../../../types";
import { Client } from "../../../../client/types";
import { FieldConstraintViolation } from "../../../../../common/types";
import { ContractType } from "../../../enums";
import { createContractActions, updateContractActions } from "../../../ducks";
import { clientToCreateUpdateContractClient, useClientValidation } from "../../../../client/utils";
import { resolveFormValidationError, toMoment, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { requests } from "../../../api";
import t from "../../../../../app/i18n";

import LoanContractFormHeaderSection from "./sections/LoanContractFormHeaderSection";
import LoanContractFormDataSection from "./sections/LoanContractFormDataSection";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";

export interface Props {
  initialContract?: LoanContract;
  onCreateFormSubmit?: typeof createContractActions.request;
  onUpdateFormSubmit?: typeof updateContractActions.request;
  onCancelClick?(): void;
}

const LoanContractForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "contract.attrs", props.initialContract ? requests.UPDATE_CONTRACT : requests.CREATE_CONTRACT);

  const clientValidation = useClientValidation();

  const [clients, setClients] = useState<Client[]>(props.initialContract?.clients || []);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<number, FieldConstraintViolation[]>>(new Map());

  useEffect(() => {
    if ( props.initialContract ) {
      const initialContract = cloneDeep(props.initialContract);
      form.setFieldsValue({
        ...initialContract,
        clients: null,
        bankInstitution: null,
        product: null,
        signer: null,
        gainer1: null,
        gainer2: null,
        manager: null,
        attachments: null,
        bankInstitutionId: initialContract.bankInstitution.id,
        productId: initialContract.product.id,
        clientIdentifiers: initialContract.clients.map(client => client.identifier),
        signerId: initialContract.signer.id,
        gainer1Id: initialContract.gainer1.id,
        gainer2Id: initialContract.gainer2?.id,
        managerId: initialContract.manager.id,
        signDate: toMoment(initialContract.signDate),
        loanMaturityDate: toMoment(initialContract.loanMaturityDate),
        effectiveEndDate: toMoment(initialContract.effectiveEndDate),
        cancellationDate: toMoment(initialContract.cancellationDate),
        fixationAnniversaryDate: toMoment(initialContract.fixationAnniversaryDate),
        contactClientDate: toMoment(initialContract.contactClientDate)
      } as CreateUpdateLoanContract);
    }
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ( clientValidation.errorResponse?.violations?.length > 0 ) {
      const violations = clientValidation.errorResponse.violations;
      const index = parseInt(violations[0].fieldPath.charAt(8));
      const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>([...clientsViolationErrors]);
      // TODO fieldpath - po update formulara klientov na antd v4 pouzit fieldPathToNamePath + dynamicky vyriesit index 18
      updatedViolationsErrors.set(index, violations.map(v => ({ ...v, fieldPath: v.fieldPath.substring(18) })));
      setClientsViolationErrors(updatedViolationsErrors);
    }
  }, [clientValidation.errorResponse]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormFinish = (): void => {
    form.validateFields()
      .then((values: CreateUpdateLoanContract | Store) => {
        if ( clientsViolationErrors.size > 0 ) {
          messageUtils.errorNotification(t("common.error"), t("contract.validations.formError"));
        }
        else {
          const processedValues = { ...values } as CreateUpdateLoanContract;

          processedValues.clients = clients.map(client => clientToCreateUpdateContractClient(client));
          processedValues.debtorIndex = 0;
          processedValues.coDebtorIndex = clients.length > 1 ? 1 : null;

          delete processedValues.clientIdentifiers;

          if ( props.initialContract ) {
            props.onUpdateFormSubmit?.({ id: props.initialContract.id, object: processedValues });
          }
          else {
            props.onCreateFormSubmit?.(processedValues);
          }
        }
      })
      .catch((errors: ValidateErrorEntity) => {
        messageUtils.errorNotification(t("common.error"), t("contract.validations.formError"));
        resolveFormValidationError(errors);
      });
  };

  const handleClientChange = (client: Client, index: number): void => {
    const updatedClients = [...clients];
    updatedClients[index] = client;
    setClients(updatedClients);

    if ( client ) {
      clientValidation.onValidate({ prefix: `clients[${index}]`, client: clientToCreateUpdateContractClient(client) });
    }
  };

  const formLayout: ColProps = { span: 24, style: { maxWidth: "1100px" } };

  return (
    <>
      <h2>{t("contract.titles.createLoanContract")}</h2>

      <Form form={form} layout="vertical" name="loanContractForm">

        <HiddenInput name="type" initialValue={ContractType.LOAN_CONTRACT} />
        <HiddenInput name="optimisticLockVersion" />
        <HiddenInput name="status" />

        <Row justify="center">
          <Col {...formLayout}>

            <LoanContractFormHeaderSection
              initialContract={props.initialContract}
              form={form}
              clients={clients}
              clientsViolationErrors={clientsViolationErrors}
              onClientChange={handleClientChange}
              onClientViolationErrorsChange={setClientsViolationErrors} />

            <LoanContractFormDataSection
              initialContract={props.initialContract}
              form={form} />

            <div className="margin-top-small">
              <Button type="primary" icon={<SaveOutlined />} onClick={handleFormFinish}>
                {t("common.save")}
              </Button>

              {props.onCancelClick && (
                <Button className="margin-left-tiny" onClick={props.onCancelClick} icon={<CloseOutlined />}>
                  {t("common.cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>

      </Form>
    </>
  )
};

export default LoanContractForm;
