import { useMemo } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateVehicleMapping,
  CreateVehicleMappingFormItem,
  UpdateVehicleMapping,
  UpdateVehicleMappingFormItem,
  VehicleAutocompleteProps,
  VehicleAutocompleteResult,
  VehicleMapping
} from "./types";
import { RootState } from "../../../common/types";
import { VehicleMappingType } from "./enums";
import {
  autocompleteVehiclesActions,
  deleteStateVehicleAutocompleteResultAction,
  selectCalcVehicleAutocompleteResult
} from "./ducks";
import { selectIsRequestInProgress } from "../../ducks";
import { requests } from "./api";

export const VEHICLE_TYPES_PATH_MAP = new Map<VehicleMappingType, string>([
  [VehicleMappingType.ALLIANZ, "allianz"],
  [VehicleMappingType.AXA, "axa"],
  [VehicleMappingType.COLONNADE, 'colonnade'],
  [VehicleMappingType.CSOB, "csob"],
  [VehicleMappingType.GENERALI, "generali"],
  [VehicleMappingType.GROUPAMA, "groupama"],
  [VehicleMappingType.KOMUNALNA, "komunalna"],
  [VehicleMappingType.KOOPERATIVA, "kooperativa"],
  [VehicleMappingType.UNION_MTPL, "unionMtpl"],
  [VehicleMappingType.UNION_CRASH, "unionCrash"],
  [VehicleMappingType.UNIQA, "uniqa"]
]);

export const VEHICLE_IDENTIFIER_DELIMITER = "<->";

export const useVehicleAutocomplete = (): VehicleAutocompleteProps => {
  const result = useSelector<RootState, VehicleAutocompleteResult>(selectCalcVehicleAutocompleteResult);
  const inProgress = useSelector<RootState, boolean>(state => selectIsRequestInProgress(state, requests.AUTOCOMPLETE_VEHICLES));

  const dispatch = useDispatch();
  const actions = useMemo(() => bindActionCreators({
    onSearch: autocompleteVehiclesActions.request,
    onResultDelete: deleteStateVehicleAutocompleteResultAction
  }, dispatch), [dispatch]);

  return { result, inProgress, onSearch: actions.onSearch, onResultDelete: actions.onResultDelete };
};

export const parseVehicleMappingsFromIdentifiers = (item: CreateVehicleMappingFormItem | UpdateVehicleMappingFormItem): CreateVehicleMapping | UpdateVehicleMapping => {
  const itemCopy = { ...item };

  Object.values(VehicleMappingType).forEach(type => {
    const typePath = VEHICLE_TYPES_PATH_MAP.get(type);
    const mappingIdentifier = itemCopy[typePath + "Identifier"] as string;
    if ( mappingIdentifier ) {
      const identifierTokens = mappingIdentifier.split(VEHICLE_IDENTIFIER_DELIMITER);
      itemCopy[typePath + "Id"] = identifierTokens[0];
      itemCopy[typePath + "Name"] = identifierTokens[1];
    }
    else {
      delete itemCopy[typePath + "Id"];
      delete itemCopy[typePath + "Name"];
    }
    delete itemCopy[typePath + "Identifier"];
  });

  return itemCopy as (CreateVehicleMapping | UpdateVehicleMapping);
};

export const parseVehicleMappingsToUpdateFormItems = (...items: VehicleMapping[]): UpdateVehicleMappingFormItem[] => {
  const updateItems = items.map<UpdateVehicleMappingFormItem>(mapping => ({
    ...mapping,
    createdAt: undefined,
    updatedAt: undefined
  }));

  updateItems.forEach(item => {
    Object.values(VehicleMappingType).forEach(type => {
      const idValue = item[VEHICLE_TYPES_PATH_MAP.get(type) + "Id"];
      const nameValue = item[VEHICLE_TYPES_PATH_MAP.get(type) + "Name"];
      item[VEHICLE_TYPES_PATH_MAP.get(type) + "Identifier"] = idValue && nameValue ? idValue + VEHICLE_IDENTIFIER_DELIMITER + nameValue : null;
    });
  });

  return updateItems;
};
