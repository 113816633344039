import React, { ChangeEvent } from "react";
import { Col, Input, Row, Select, Switch } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { Address } from "../types";
import { Country } from "../enums";
import validations, { regexPatterns } from "../../utils/validationUtils";
import { selectStandardProps } from "../../utils/formUtils";
import { rowGutter } from "../../constants";
import t from "../../../app/i18n";

export interface Props {
  form: WrappedFormUtils;
  initialAddress?: Address;
  rootKey: string;
  label: string;
  required?: boolean;
  switchProps?: AddressSwitchProps;
}

export interface AddressSwitchProps {
  enabled: boolean;
  onEnabledChange(enabled: boolean): void;
}

interface State {
  readonly isStreetFilled: boolean;
}

class AddressForm_Deprecated extends React.Component<Props, State> {
  readonly state: State = {
    isStreetFilled: !!(this.props.initialAddress && this.props.initialAddress.street && this.props.initialAddress.street.length > 0)
  };

  handleEnabledSwitchButtonChange = (checked: boolean): void => {
    if ( !checked ) {
      const { form, rootKey } = this.props;
      form.setFieldsValue({
        [`${rootKey}.street`]: undefined,
        [`${rootKey}.descriptiveNumber`]: undefined,
        [`${rootKey}.orientationNumber`]: undefined,
        [`${rootKey}.city`]: undefined,
        [`${rootKey}.zipCode`]: undefined,
        [`${rootKey}.country`]: Country.SVK
      });
    }

    this.props.switchProps.onEnabledChange(checked);
  };

  handleStreetChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ isStreetFilled: !!(event.target.value && event.target.value.length > 0) }, () => {
      if ( this.state.isStreetFilled ) {
        this.props.form.validateFields([`${this.props.rootKey}.descriptiveNumber`], { force: true });
      }
      else {
        this.props.form.validateFields([`${this.props.rootKey}.orientationNumber`], { force: true });
      }
    });
  };

  render(): React.ReactNode {
    const { isStreetFilled } = this.state;
    const { form, rootKey, switchProps } = this.props;
    const { getFieldDecorator } = form;

    const colSpan = 4;
    const smallColSpan = 3;

    const switchButton = switchProps ? (
      <span className="margin-left-tiny">
      <Switch size="small" checked={switchProps.enabled} onChange={this.handleEnabledSwitchButtonChange} />
    </span>
    ) : null;

    return (
      <>
        <Row gutter={rowGutter}>
          <Col span={24}
               className={["bold-text", "margin-bottom-tiny", ...(this.props.required ? ["ant-legacy-form-item-required"] : [])].join(" ")}>
            {this.props.label} {switchButton}
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("address.attrs.street")}>
              {getFieldDecorator(rootKey + ".street", {
                rules: [validations.size(1, 255), validations.pattern(regexPatterns.streetRegex)]
              })(
                <Input disabled={switchProps ? !switchProps.enabled : false} onChange={this.handleStreetChange} />
              )}
            </Form.Item>

          </Col>

          <Col span={smallColSpan}>
            <Form.Item label={t("address.attrs.descriptiveNumber")}>
              {getFieldDecorator(rootKey + ".descriptiveNumber", {
                rules: [
                  isStreetFilled ? validations.none : validations.notBlank,
                  validations.size(1, 64),
                  validations.pattern(regexPatterns.streetNumberRegex)
                ]
              })(
                <Input disabled={switchProps ? !switchProps.enabled : false} />
              )}
            </Form.Item>
          </Col>

          <Col span={smallColSpan}>
            <Form.Item label={t("address.attrs.orientationNumber")}>
              {getFieldDecorator(rootKey + ".orientationNumber", {
                rules: [
                  isStreetFilled ? validations.notBlank : validations.none,
                  validations.size(1, 64),
                  validations.pattern(regexPatterns.streetNumberRegex)
                ]
              })(
                <Input disabled={switchProps ? !switchProps.enabled : false} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("address.attrs.city")}>
              {getFieldDecorator(rootKey + ".city", {
                rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
              })(
                <Input disabled={switchProps ? !switchProps.enabled : false} />
              )}
            </Form.Item>
          </Col>

          <Col span={smallColSpan}>
            <Form.Item label={t("address.attrs.zipCode")}>
              {getFieldDecorator(rootKey + ".zipCode", { rules: [validations.notBlank, validations.length(5), validations.numeric] })(
                <Input disabled={switchProps ? !switchProps.enabled : false} />
              )}
            </Form.Item>
          </Col>

          <Col span={smallColSpan}>
            <Form.Item label={t("address.attrs.country")}>
              {getFieldDecorator(rootKey + ".country", { rules: [validations.notNull], initialValue: Country.SVK })(
                <Select
                  {...selectStandardProps}
                  disabled={switchProps ? !switchProps.enabled : false}
                  options={Object.keys(Country).map(country => ({ value: country, label: country }))} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}

export default AddressForm_Deprecated;
