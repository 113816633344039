import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./rootHistory";

import { ActionCreator, RootState } from "../../common/types";
import enumerationsReducer from "../../modules/enumerations/ducks";
import apiReducer from "../../modules/ducks";
import authReducer, { actionType } from "../../modules/auth/ducks";
import myIdentityReducer from "../../modules/myidentity/ducks";
import clientReducer from "../../modules/client/ducks";
import contractReducer from "../../modules/contract/ducks";
import calculatorReducer from "../../modules/calculator/ducks";
import personReducer from "../../modules/person/ducks";
import accountReducer from "../../modules/account/ducks";
import commissionsReducer from "../../modules/commissions/ducks";
import adminReducer from "../../modules/admin/ducks";

const rootReducer = combineReducers({
  router: connectRouter(history),
  enumerations: enumerationsReducer,
  api: apiReducer,
  auth: authReducer,
  myIdentity: myIdentityReducer,
  client: clientReducer,
  contract: contractReducer,
  calculator: calculatorReducer,
  person: personReducer,
  account: accountReducer,
  commissions: commissionsReducer,
  admin: adminReducer
});

export default (state: RootState, action: ActionCreator<any>): any => {
  // When state is defined as 'undefined' then initial state is loaded (and not the state from persistence layer)
  if ( action.type === actionType.LOGOUT ) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
}
