import React from "react";
import { Route, Switch } from "react-router-dom";
import { Permission } from "../../common/security/authorization/enums";
import RedirectWithPermission from "../../common/security/authorization/RedirectWithPermission";

import JobRoutes from "./jobs/routes";
import InstitutionRoutes from "./institution/routes";
import ProductRoutes from "./product/routes";
import LifeInsuranceTariffRoutes from "./lifeinsurancetariff/routes";
import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <Route
      exact path="/admin"
      render={() => <RedirectWithPermission permissionRedirects={[
        { permission: Permission.ADMIN_ENUMERATIONS, exact: true, from: "/admin", to: "/admin/institutions" },
        { permission: Permission.ADMIN_JOBS, exact: true, from: "/admin", to: "/admin/jobs" },
        { permission: Permission.ADMIN_CONTRACT_UPDATES, exact: true, from: "/admin", to: "/admin/contract-updates" }
      ]} />} />

    <Route path="/admin/jobs" component={JobRoutes} />
    <Route path="/admin/institutions" component={InstitutionRoutes} />
    <Route path="/admin/products" component={ProductRoutes} />
    <Route path="/admin/life-insurance-tariffs" component={LifeInsuranceTariffRoutes} />

    <Route exact path="/admin/contract-updates" render={() => <h2>Admin / contract updates</h2>} />

    <Route component={NotFound} />
  </Switch>
);
