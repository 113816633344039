import "./person-tree-view.less";
import React from "react";
import { PersonWithSubordinates } from "../../../types";

import PersonTypeTag from "../../PersonTypeTag";
import ActionLinkIcon from "../../../../../common/components/icons/ActionLinkIcon";

export interface Props {
  person: PersonWithSubordinates;
}

const PersonTreeNodeTitleView = ({ person }: Props) => (
  <div className={["person-tree-node", person.deactivated ? "deactivated" : ""].join(" ")}>
    <PersonTypeTag type={person.type} />&nbsp;

    <span className="margin-right-tiny">
      <span className="bold-text">{person.aggregatedName}</span>{` (${person.identifier})`}
    </span>

    <span className="show-detail-icon">
      <ActionLinkIcon path={`/persons/${person.id}`} type="eye" color="blue" disableDelayEffect />
    </span>
  </div>
);

export default PersonTreeNodeTitleView;
