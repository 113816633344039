import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { ActionProps, RootState } from "../../../../../common/types";
import { CalcDraftFilterPageResult } from "../../../drafts/types";
import { CalcType } from "../../../enums";
import {
  deleteCalcDraftActions,
  deleteCalcDraftAttachmentActions,
  deleteStateCalcDraftsPageAction,
  filterCalcDraftsActions,
  getCalcDraftActions,
  selectCalcDraftsCurrentPage,
  uploadCalcDraftAttachmentsActions
} from "../../../drafts/ducks";
import { selectRouterLocationKey, selectRouterLocationSearchParam } from "../../../../ducks";
import { appendSearchParamsToCurrentPathname } from "../../../../../common/utils/utils";
import { serializeParams } from "../../../../../common/utils/apiUtils";

import RealtyCalcDraftSearchHeaderView from "../components/draft/RealtyCalcDraftSearchHeaderView";
import RealtyCalcDraftTableView from "../components/draft/RealtyCalcDraftTableView";

interface StateProps {
  calcDraftsCurrentPage: CalcDraftFilterPageResult;
  initialUrlSearchKeyword: string;
  locationKey: string;
}

interface ActionsMap {
  filterCalcDrafts: typeof filterCalcDraftsActions.request;
  getCalcDraft: typeof getCalcDraftActions.request;
  deleteCalcDraft: typeof deleteCalcDraftActions.request;
  uploadCalcDraftAttachments: typeof uploadCalcDraftAttachmentsActions.request;
  deleteCalcDraftAttachment: typeof deleteCalcDraftAttachmentActions.request;
  deleteStateCalcDraftsPage: typeof deleteStateCalcDraftsPageAction;
  replaceNavigation: typeof replace;
}

class RealtyCalcDraftContainer extends React.Component<StateProps & ActionProps<ActionsMap>> {
  private static readonly PAGE_SIZE = 20;

  handleTablePageChange = (pageNumber: number): void => {
    this.props.actions.filterCalcDrafts({
      keyword: this.props.calcDraftsCurrentPage.keyword,
      pageIndex: pageNumber - 1,
      pageSize: RealtyCalcDraftContainer.PAGE_SIZE,
      calcType: CalcType.REALTY
    })
  };

  handleSearchSubmit = (keyword: string): void => {
    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(
      serializeParams({ keyword: keyword !== "" ? keyword : null })));

    this.props.actions.filterCalcDrafts({
      keyword,
      pageIndex: 0,
      pageSize: RealtyCalcDraftContainer.PAGE_SIZE,
      calcType: CalcType.REALTY
    })
  };

  componentWillUnmount(): void {
    this.props.actions.deleteStateCalcDraftsPage();
  }

  componentDidMount(): void {
    this.props.actions.filterCalcDrafts({
      keyword: this.props.initialUrlSearchKeyword,
      pageIndex: 0,
      pageSize: RealtyCalcDraftContainer.PAGE_SIZE,
      calcType: CalcType.REALTY
    })
  }

  render(): React.ReactNode {
    return (
      <>
        <RealtyCalcDraftSearchHeaderView
          initialSearchKeyword={this.props.initialUrlSearchKeyword}
          onSearchSubmit={this.handleSearchSubmit} />

        <RealtyCalcDraftTableView
          calcDraftsPage={this.props.calcDraftsCurrentPage}
          onShowInCalculator={this.props.actions.getCalcDraft}
          onDelete={this.props.actions.deleteCalcDraft}
          onUploadCalcDraftAttachments={this.props.actions.uploadCalcDraftAttachments}
          onDeleteCalcDraftAttachment={this.props.actions.deleteCalcDraftAttachment}
          onPageChange={this.handleTablePageChange} />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  calcDraftsCurrentPage: selectCalcDraftsCurrentPage(state),
  initialUrlSearchKeyword: selectRouterLocationSearchParam(state, "keyword"),
  locationKey: selectRouterLocationKey(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterCalcDrafts: filterCalcDraftsActions.request,
    getCalcDraft: getCalcDraftActions.request,
    deleteCalcDraft: deleteCalcDraftActions.request,
    uploadCalcDraftAttachments: uploadCalcDraftAttachmentsActions.request,
    deleteCalcDraftAttachment: deleteCalcDraftAttachmentActions.request,
    deleteStateCalcDraftsPage: deleteStateCalcDraftsPageAction,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(RealtyCalcDraftContainer);
