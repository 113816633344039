import React from "react";
import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CommissionsBatch, CommissionsBatchInputAttachment, CommissionsUnit } from "../../../../types";
import {
  deleteCommissionsBatchAttachmentActions,
  deleteCommissionsUnitActions,
  replaceCommissionsBatchAttachmentActions,
  uploadCommissionsBatchAttachmentActions
} from "../../../../ducks";
import {
  formatInstitutionName,
  formatLocaleCurrencyWithNullAsZero,
  formatLocaleDateTime
} from "../../../../../../../common/utils/formatUtils";
import { paginationStandardProps } from "../../../../../../../common/utils/formUtils";
import t from "../../../../../../../app/i18n";

import CommissionsUnitAttachmentsTableView from "./CommissionsUnitAttachmentsTableView";
import InstitutionTypeTag from "../../../../../../admin/institution/components/InstitutionTypeTag";
import StatusTag from "../../../../../../../common/components/tags/StatusTag";
import ActionTextIcon from "../../../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  batch: CommissionsBatch;
  onDelete: typeof deleteCommissionsUnitActions.request;
  onAttachmentUpload: typeof uploadCommissionsBatchAttachmentActions.request;
  onAttachmentReplace: typeof replaceCommissionsBatchAttachmentActions.request;
  onAttachmentDelete: typeof deleteCommissionsBatchAttachmentActions.request;
  onUpdateClick(unit: CommissionsUnit): void;
}

const PAGE_SIZE = 15;

const CommissionsUnitTableView = (props: Props) => {

  const columns: ColumnsType<CommissionsUnit> = [
    {
      key: "institution",
      title: t("commissions.batch.attrs.unit.institution"),
      render: (value, record) => <>
        <InstitutionTypeTag type={record.institution.type} /> {formatInstitutionName(record.institution)}
      </>
    },
    {
      key: "hasImportErrors",
      render: (value, record) => (props.batch.attachments as CommissionsBatchInputAttachment[])
          .filter(attachment => attachment.institution?.id === record.institution.id)
          .some(attachment => attachment.hasImportErrors)
        && <StatusTag status="error" tooltip={t("commissions.batch.helpers.importedAttachmentError")} />
    },
    {
      key: "createdAt",
      title: t("common.createdAt"),
      render: (value, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "updatedAt",
      title: t("common.updatedAt"),
      render: (value, record) => formatLocaleDateTime(record.updatedAt)
    },
    {
      key: "importedCommissionAmount",
      title: t("commissions.batch.attrs.unit.importedCommissionAmount"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrencyWithNullAsZero(record.importedCommissionAmount)
    },
    {
      key: "postponedCommissionAmount",
      title: t("commissions.batch.attrs.unit.postponedCommissionAmount"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrencyWithNullAsZero(record.postponedCommissionAmount)
    },
    {
      key: "bankAccountCommissionAmount",
      title: t("commissions.batch.attrs.unit.bankAccountCommissionAmount"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrencyWithNullAsZero(record.bankAccountCommissionAmount)
    },
    {
      key: "invoiceCommissionAmount",
      title: t("commissions.batch.attrs.unit.invoiceCommissionAmount"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrencyWithNullAsZero(record.invoiceCommissionAmount)
    },
    {
      key: "volumeCommissionAmount",
      title: t("commissions.batch.attrs.unit.volumeCommissionAmount"),
      className: "table-column-right-align",
      render: (value, record) => formatLocaleCurrencyWithNullAsZero(record.volumeCommissionAmount)
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (value, record) => (
        <>
          <ActionTextIcon type="edit" color="blue" text={t("common.edit")}
                          onClick={() => props.onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("commissions.batch.helpers.deleteUnitConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="primary"
            onConfirm={() => props.onDelete({
              id: props.batch.id,
              object: { institutionId: record.institution.id }
            })}>
            <ActionTextIcon type="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table<CommissionsUnit>
      size="small"
      className="table-row-stripped"
      rowKey="id"
      columns={columns}
      dataSource={props.batch.commissionsUnits}
      expandable={{
        expandedRowRender: record =>
          <CommissionsUnitAttachmentsTableView
            batch={props.batch}
            unit={record}
            onUpload={props.onAttachmentUpload}
            onReplace={props.onAttachmentReplace}
            onDelete={props.onAttachmentDelete} />
      }}
      pagination={{
        ...paginationStandardProps,
        position: ["bottomRight"],
        pageSize: PAGE_SIZE,
        total: props.batch.commissionsUnits.length
      }} />
  )
};

export default CommissionsUnitTableView;
