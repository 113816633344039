import React from "react";
import isEqual from "lodash/isEqual";
import { Col, Modal, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CalcDataSource, CalcResult } from "../../../types";
import { TravelCalc, TravelCalcResultData } from "../../types";
import { TravelInsuranceType } from "../../enums";
import { CalcType } from "../../../../enums";
import { deleteStateTravelCalcResultsAction } from "../../ducks";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { setErrorsToForm_deprecated } from "../../../../../../common/utils/formUtils";
import t from "../../../../../../app/i18n";

import TravelCalcForm from "./TravelCalcForm";
import TravelCalcNavigation from "./navigation/TravelCalcNavigation";
import TravelCalcResults from "../result/TravelCalcResults";

export interface Props extends FormComponentProps<TravelCalc> {
  calcData: TravelCalc;
  calcDataSource: CalcDataSource;
  calcResults: CalcResult<TravelCalcResultData>[][];
  onCalcResultsDelete: typeof deleteStateTravelCalcResultsAction;
  onCalculationFormSubmit(calcData: TravelCalc): void;
  onGenerateContractClick(calcData: TravelCalc, selectedResult: CalcResult<TravelCalcResultData>);
  onGenerateOfferClick(calcData: TravelCalc): void;
  onResetCalculatorClick(): void;
  onCalcDataSourceReset(): void;
}

interface State {
  readonly insuranceType: TravelInsuranceType;
  readonly resultsVisible: boolean;
}

class TravelCalcWrapper extends React.Component<Props, State> {
  readonly state: State = {
    insuranceType: this.props.calcData ? this.props.calcData.generalData.insuranceType : null,
    resultsVisible: false
  };

  handleInsuranceTypeChange = (insuranceType: TravelInsuranceType): void => {
    this.setState({ insuranceType }, () => {
      if ( this.props.form.getFieldValue("generalData.effectiveBeginningDate") ) {
        this.props.form.validateFields(["generalData.effectiveBeginningDate"], { force: true });
      }
      if ( this.props.form.getFieldValue("generalData.effectiveEndDate") ) {
        this.props.form.validateFields(["generalData.effectiveEndDate"], { force: true });
      }
    });
  };

  handleCalculationFormSubmit = (): void => {
    this.props.form.validateFields((errors, values) => {
      if ( errors ) {
        messageUtils.errorNotification(t("common.error"), t("calc.travel.validations.formError"));
      }
      else {
        this.props.onCalcResultsDelete();
        this.props.onCalculationFormSubmit(this.processAndGetCalcFormData(values));

        if ( this.props.calcDataSource !== "init" ) {
          this.props.onCalcDataSourceReset();
        }
      }
    });
  };

  handleResultsShow = (): void => {
    this.setState({ resultsVisible: true });
  };

  handleResultsClose = (): void => {
    this.setState({ resultsVisible: false });
  };

  handleResultGenerateContractClick = (result: CalcResult<TravelCalcResultData>): void => {
    this.setState({ resultsVisible: false });
    if ( this.checkAreCalcFormDataUnchanged() ) {
      this.props.onGenerateContractClick(this.processAndGetCalcFormData(), result);
    }
  };

  handleResultGenerateOfferClick = (): void => {
    this.setState({ resultsVisible: false });
    if ( this.checkAreCalcFormDataUnchanged() ) {
      this.props.onGenerateOfferClick(this.processAndGetCalcFormData());
    }
  };

  handleResultShowErrorsClick = (result: CalcResult<TravelCalcResultData>): void => {
    if ( result.error ) {
      setErrorsToForm_deprecated(this.props.form, result.error.violations, "calc.travel.attrs");
      this.setState({ resultsVisible: false });
    }
  };

  processAndGetCalcFormData = (calcData?: TravelCalc): TravelCalc => {
    const processedValues = calcData ? { ...calcData } : this.props.form.getFieldsValue() as TravelCalc;
    processedValues.type = CalcType.TRAVEL;
    return processedValues;
  };

  checkAreCalcFormDataUnchanged = (): boolean => {
    const calcDataUnchanged = isEqual(this.props.calcData, this.processAndGetCalcFormData());

    if ( !calcDataUnchanged ) {
      Modal.warning({
        title: t("common.warning"),
        content: t("calc.travel.validations.calcDataChanged"),
        okText: t("calc.travel.actions.recalculationSubmit"),
        maskClosable: true,
        onOk: this.handleCalculationFormSubmit,
        onCancel: this.handleCalculationFormSubmit
      });
    }

    if ( this.props.calcDataSource !== "init" ) {
      this.props.onCalcDataSourceReset();
    }

    return calcDataUnchanged;
  };

  resolvePageTitle = (): string => {
    return this.state.insuranceType
      ? t("calc.travel.titles.insuranceType." + this.state.insuranceType)
      : t("calc.travel.titles.noSelectedInsurance");
  };

  componentDidMount(): void {
    if ( this.props.calcData ) {
      this.props.form.setFieldsValue({ ...this.props.calcData, type: undefined });
      this.props.onCalcDataSourceReset();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( prevProps.calcResults.length === 0 && this.props.calcResults.length > 0 ) {
      this.setState({ resultsVisible: true });
    }
  }

  render(): React.ReactNode {
    const { insuranceType } = this.state;
    const { form, calcResults } = this.props;

    const formLayout: ColProps = { span: 24, style: { maxWidth: "950px" } };

    return (
      <>
        <h2>{this.resolvePageTitle()}</h2>

        <Row gutter={64} className="margin-top-medium" justify="center">
          <Col {...formLayout}>
            <Form layout="vertical">

              <TravelCalcForm
                form={form}
                initialData={this.props.calcData}
                insuranceType={insuranceType}
                onInsuranceTypeChange={this.handleInsuranceTypeChange} />

            </Form>
          </Col>
        </Row>

        <TravelCalcResults
          visible={this.state.resultsVisible}
          insuranceType={insuranceType}
          calcResults={calcResults}
          onClose={this.handleResultsClose}
          onGenerateOfferClick={this.handleResultGenerateOfferClick}
          onGenerateContractClick={this.handleResultGenerateContractClick}
          onShowErrorsClick={this.handleResultShowErrorsClick} />

        <TravelCalcNavigation
          hasResults={calcResults.length > 0}
          onFormSubmit={this.handleCalculationFormSubmit}
          onShowResultsClick={this.handleResultsShow}
          onResetCalculatorClick={this.props.onResetCalculatorClick} />
      </>
    );
  }
}

export default Form.create<Props>({
  onValuesChange: props => {
    if ( props.calcResults.length > 0 && props.calcDataSource === "init" ) {
      props.onCalcResultsDelete();
    }
  }
})(TravelCalcWrapper);
