import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Col, Form, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CalcRecordFilterPageRequest } from "../../types";
import { RootState } from "../../../../../common/types";
import { OperationType, operationTypeTMap } from "../../enums";
import { CalcType, calcTypeTMap } from "../../../enums";
import { Permission } from "../../../../../common/security/authorization/enums";
import { selectPermissions } from "../../../../auth/ducks";
import { hasAllPermissions, hasPermission } from "../../../../../common/utils/utils";
import { selectTagsStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import PersonsEnumFormItemSelect from "../../../../enumerations/components/form/PersonsEnumFormItemSelect";
import OperationTypeTag from "../OperationTypeTag";
import CalcTypeTag from "../CalcTypeTag";

export interface Props {
  initialFilter: CalcRecordFilterPageRequest;
  onFilterSubmit(filter: CalcRecordFilterPageRequest): void;
}

const CalcRecordsListFilterView = ({ initialFilter, onFilterSubmit }: Props) => {

  const permissions = useSelector<RootState, Permission[]>(selectPermissions);

  const resolveCalcTypeOptions = useCallback((): CalcType[] => {
    const calcTypes: CalcType[] = [];

    if ( hasPermission(permissions, Permission.MTPL_CALCULATOR_ADMIN) ) {
      calcTypes.push(CalcType.MTPL);
    }
    if ( hasPermission(permissions, Permission.CRASH_CALCULATOR_ADMIN) ) {
      calcTypes.push(CalcType.CRASH);
    }
    if ( hasAllPermissions(permissions, [Permission.MTPL_CALCULATOR_ADMIN, Permission.CRASH_CALCULATOR_ADMIN]) ) {
      calcTypes.push(CalcType.MTPL_CRASH);
    }
    if ( hasPermission(permissions, Permission.CRASH_CALCULATOR_ADMIN) ) {
      calcTypes.push(CalcType.GAP, CalcType.PAS);
    }
    if ( hasPermission(permissions, Permission.REALTY_CALCULATOR_ADMIN) ) {
      calcTypes.push(CalcType.REALTY);
    }
    if ( hasPermission(permissions, Permission.TRAVEL_CALCULATOR_ADMIN) ) {
      calcTypes.push(CalcType.TRAVEL);
    }

    return calcTypes;
  }, [permissions]);

  const colSpan = 6;

  return (
    <>
      <h2>{t("calc.records.titles.list")}</h2>

      <Card
        title={t("calc.records.filter.title")}
        size="small" className="card-filter" style={{ maxWidth: "1200px" }}>

        <Form layout="vertical" onFinish={onFilterSubmit} name="calcRecordsListFilterForm">
          <Row gutter={rowGutter} justify="center">

            <Col span={colSpan}>
              <Form.Item
                name="operationType"
                label={t("calc.records.enums.operationType._label")}
                rules={[validations.none]}
                initialValue={initialFilter.operationType}>
                <Select
                  {...selectTagsStandardProps(operationTypeTMap)} allowClear
                  options={Object.keys(OperationType).map(type => ({
                    value: type,
                    label: <OperationTypeTag type={OperationType[type]} />
                  }))} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="calcType"
                label={t("calc.enums.calcType._label")}
                rules={[validations.none]}
                initialValue={initialFilter.calcType}>
                <Select
                  {...selectTagsStandardProps(calcTypeTMap)} allowClear
                  options={resolveCalcTypeOptions().map(type => ({
                    value: type,
                    label: <CalcTypeTag type={type} />
                  }))} />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <PersonsEnumFormItemSelect
                formItemProps={{
                  name: "personId",
                  label: t("calc.records.filter.person"),
                  rules: [validations.none],
                  initialValue: initialFilter.personId
                }}
                selectProps={{ allowClear: true }}
                optionsProps={{ includeDeactivated: true }} />
            </Col>

            <Col span={colSpan} className="center-align">
              <Form.Item>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  {t("calc.records.filter.submit")}
                </Button>
              </Form.Item>
            </Col>

          </Row>
        </Form>

      </Card>
    </>
  );
}

export default CalcRecordsListFilterView;
