import t from "../../app/i18n";

export enum CalcType {
  MTPL = 'MTPL',
  CRASH = 'CRASH',
  MTPL_CRASH = 'MTPL_CRASH',
  GAP = 'GAP',
  PAS = 'PAS',
  REALTY = 'REALTY',
  TRAVEL = 'TRAVEL'
}

export const calcTypeTMap = new Map<CalcType, string>(
  Object.values(CalcType).map(type => [type, t("calc.enums.calcType." + type)])
);
