import React from "react";
import { Divider } from "antd";

export interface Props {
  tokens: string[];
  showDivider?: boolean;
}

const MultiLineText = ({ tokens, showDivider }: Props) => (
  tokens ? (
    <>
      {tokens
        .filter(token => token && token.length > 0)
        .map((token, index) => (
          <React.Fragment key={index}>
            {token}
            {index < tokens.length - 1
              ? (showDivider ? <Divider className="divider-with-small-margins" /> : <br />)
              : null}
          </React.Fragment>
        ))}
    </>
  ) : null
);

export default MultiLineText;
