import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ValidationErrorResponse } from "../../types";
import { ActionProps, RootState } from "../../../common/types";
import { CreateUpdateClient } from "../types";
import { deleteStateValidationErrorResponseAction, selectValidationErrorResponse } from "../../ducks";
import { createClientActions } from "../ducks";
import { requests } from "../api";
import { setErrorsToForm_deprecated } from "../../../common/utils/formUtils";
import t from "../../../app/i18n";

import ClientCreateForm, { ClientCreateFormComponent } from "../components/forms/ClientCreateForm";

interface StateProps {
  createClientErrorResponse: ValidationErrorResponse;
}

interface ActionsMap {
  createClient: typeof createClientActions.request;
  deleteStateValidationErrorResponse: typeof deleteStateValidationErrorResponseAction;
}

class ClientCreateContainer extends React.Component<StateProps & ActionProps<ActionsMap>> {

  private formRef = React.createRef<ClientCreateFormComponent>();

  handleCreateFormSubmit = (clientData: CreateUpdateClient, errors: any): void => {
    if ( !errors ) {
      this.props.actions.createClient(clientData);
    }
  };

  componentDidUpdate(): void {
    if ( this.props.createClientErrorResponse ) {
      setErrorsToForm_deprecated(this.formRef.current.props.form, this.props.createClientErrorResponse.violations, "client.attrs");
      this.props.actions.deleteStateValidationErrorResponse();
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <h2>{t("client.titles.create")}</h2>

        <ClientCreateForm wrappedComponentRef={this.formRef} onFormSubmit={this.handleCreateFormSubmit} />
      </>
    )
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  createClientErrorResponse: selectValidationErrorResponse(state, requests.CREATE_CLIENT)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    createClient: createClientActions.request,
    deleteStateValidationErrorResponse: deleteStateValidationErrorResponseAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(ClientCreateContainer);
