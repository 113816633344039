import React from "react";
import { Link } from "react-router-dom";
import { Location } from "history";
import { Layout } from "antd";
import { AuthAccount } from "../../modules/auth/types";
import { logoutAction } from "../../modules/auth/ducks";
import { getActiveEnvProfile } from "../../common/utils/utils";

import logo from "../../assets/images/assure-logo-green.png";
import HeaderMenu from "./HeaderMenu";
import UserSection from "./UserSection";

export interface Props {
  location: Location;
  userAuthenticated: boolean;
  account: AuthAccount;
  onLogout: typeof logoutAction;
}

const Header = (props: Props) => (
  <Layout.Header>

    <Link to="/">
      <div className="logo">
        <img src={logo} alt="logo" />
        {getActiveEnvProfile() !== "prod" && (
          <div className={"active-profile " + getActiveEnvProfile()}>{getActiveEnvProfile().toUpperCase()}</div>
        )}
      </div>
    </Link>

    <UserSection account={props.account} userAuthenticated={props.userAuthenticated} />

    <HeaderMenu {...props} permissions={props.account ? props.account.permissions : []} />

  </Layout.Header>
);

export default Header;
