import React from "react";
import { Route, Switch } from "react-router-dom";
import { regexPatterns } from "../../common/utils/validationUtils";
import { Permission } from "../../common/security/authorization/enums";
import RouteWithPermission from "../../common/security/authorization/RouteWithPermission";

import ContractListContainer from "./containers/ContractListContainer";
import ContractDetailContainer from "./containers/ContractDetailContainer";
import ContractCreateContainer from "./containers/ContractCreateContainer";
import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission exact path="/contracts"
                         key="contracts-generic"
                         component={ContractListContainer}
                         permissions={[Permission.INSURANCE, Permission.LOAN]}
                         anyPermission />

    <RouteWithPermission exact path="/contracts/anniversary-date"
                         key="contract-anniversary-date"
                         component={ContractListContainer}
                         permissions={[Permission.INSURANCE, Permission.LOAN]}
                         anyPermission />

    <RouteWithPermission exact path="/contracts/unpaid"
                         key="contract-unpaid"
                         component={ContractListContainer}
                         permissions={[Permission.INSURANCE, Permission.LOAN]}
                         anyPermission />

    <RouteWithPermission exact path={`/contracts/:id(${regexPatterns.uuidRegex.source})`}
                         component={ContractDetailContainer}
                         permissions={[Permission.INSURANCE, Permission.LOAN]}
                         anyPermission />

    <RouteWithPermission exact path="/contracts/new-insurance"
                         component={ContractCreateContainer}
                         permissions={[Permission.INSURANCE_CREATE]} />

    <RouteWithPermission exact path="/contracts/new-loan"
                         component={ContractCreateContainer}
                         permissions={[Permission.LOAN_CREATE]} />

    <Route component={NotFound} />
  </Switch>
);
