import React, { ReactText } from "react";
import { replace } from "connected-react-router";
import { Button, Space, Switch, Tree } from "antd";
import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import { DataNode } from "rc-tree/lib/interface";
import { PersonWithSubordinates } from "../../../types";
import { appendSearchParamsToCurrentPathname, isNotEmptyArray } from "../../../../../common/utils/utils";
import { serializeParams } from "../../../../../common/utils/apiUtils";
import t from "../../../../../app/i18n";

import PersonTreeNodeTitleView from "./PersonTreeNodeTitleView";

export interface Props {
  personsTree: PersonWithSubordinates[];
  initialIncludeDeactivated: boolean;
  replaceNavigation: typeof replace;
}

interface State {
  readonly includeDeactivated: boolean;
  readonly treeExpandedKeys: string[];
}

class PersonTreeView extends React.Component<Props, State> {
  readonly state: State = {
    includeDeactivated: this.props.initialIncludeDeactivated,
    treeExpandedKeys: []
  };

  handleIncludeDeactivatedChange = (includeDeactivated: boolean): void => {
    this.props.replaceNavigation(appendSearchParamsToCurrentPathname(
      serializeParams({ includeDeactivated: includeDeactivated ? includeDeactivated : null })));
    this.setState({ includeDeactivated });
  };

  handleTreeExpand = (treeExpandedKeys: ReactText[]): void => {
    this.setState({ treeExpandedKeys: treeExpandedKeys as string[] });
  };

  handleExpandTreeClick = (): void => {
    const aggregatePersonIds = (persons: PersonWithSubordinates[]): string[] =>
      [].concat(...persons.map(person =>
        person.subordinates && person.subordinates.length > 0
          ? [person.id, ...aggregatePersonIds(person.subordinates)]
          : [person.id]));

    this.setState({ treeExpandedKeys: aggregatePersonIds(this.props.personsTree) });
  };

  handleCollapseTreeClick = (): void => {
    this.setState({ treeExpandedKeys: this.props.personsTree.map(person => person.id) });
  };

  buildTreeNodes = (persons: PersonWithSubordinates[]): DataNode[] => {
    return persons
      .filter(person => this.state.includeDeactivated || !person.deactivated)
      .map<DataNode>(person => ({
        title: <PersonTreeNodeTitleView person={person} />,
        key: person.id,
        value: person.id,
        selectable: false,
        children: isNotEmptyArray(person.subordinates) ? this.buildTreeNodes(person.subordinates) : undefined
      }));
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( prevProps.personsTree.length === 0 && this.props.personsTree.length > 0 ) {
      this.setState({ treeExpandedKeys: this.props.personsTree.map(person => person.id) });
    }
  }

  render(): React.ReactNode {
    const { personsTree } = this.props;

    return (
      <>
        <h2>{t("person.titles.tree")}</h2>

        <div className="margin-bottom-medium">
          <Space size={0}>
            <Button size="small" onClick={this.handleExpandTreeClick}>{t("person.actions.expandTree")}</Button>
            <Button size="small" onClick={this.handleCollapseTreeClick}>{t("person.actions.collapseTree")}</Button>
          </Space>

          <span className="margin-left-medium">
          <Switch
            size="small"
            defaultChecked={this.props.initialIncludeDeactivated}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={this.handleIncludeDeactivatedChange} />
          <span className="margin-left-tiny">{t("person.actions.includeDeactivated")}</span>
        </span>
        </div>

        {personsTree.length > 0 && (
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            expandedKeys={this.state.treeExpandedKeys}
            treeData={this.buildTreeNodes(personsTree)}
            onExpand={this.handleTreeExpand} />
        )}
      </>
    );
  }
}

export default PersonTreeView;
