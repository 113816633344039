import React from "react";
import { Checkbox, Form } from "antd";
import { NamePath } from "rc-field-form/lib/interface";
import { validations } from "../../../utils/validationUtils";

export interface Props {
  name: NamePath;
  initialValue?: string;
}

const HiddenCheckbox = ({ name, initialValue }: Props) => (
  <Form.Item hidden noStyle name={name} rules={[validations.none]} valuePropName="checked"
             initialValue={initialValue || false}>
    <Checkbox type="hidden" />
  </Form.Item>
);

export default HiddenCheckbox;
