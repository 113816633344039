import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { FormItemProps } from "antd/lib/form";
import { VehicleBrandWithModels } from "../../types";
import { RootState } from "../../../../common/types";
import { selectVehicleBrandsEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
}

const VehicleBrandEnumFormItemSelect = ({ formItemProps, selectProps }: Props) => {

  const vehicleBrandsEnumerations = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnumerations);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={vehicleBrandsEnumerations.map(brand => ({ value: brand.id, label: brand.name }))} />
    </Form.Item>
  );
}

export default VehicleBrandEnumFormItemSelect;
