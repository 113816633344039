import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { CommissionsBatch } from "../types";
import { ActionProps, PageResult, RootState } from "../../../../common/types";
import {
  createCommissionsBatchActions,
  deleteCommissionsBatchActions,
  deleteStateBatchesPageAction,
  filterCommissionsBatchesActions,
  selectBatchesPage,
  setStateBatchDetailAction,
  updateCommissionsBatchActions
} from "../ducks";

import CommissionsBatchListHeaderView from "../components/views/list/CommissionsBatchListHeaderView";
import CommissionsBatchTableView from "../components/views/list/CommissionsBatchTableView";
import CommissionsBatchForm from "../components/forms/CommissionsBatchForm";

interface StateProps {
  batchesPage: PageResult<CommissionsBatch>;
}

interface ActionsMap {
  filterCommissionsBatches: typeof filterCommissionsBatchesActions.request;
  createCommissionsBatch: typeof createCommissionsBatchActions.request;
  updateCommissionsBatch: typeof updateCommissionsBatchActions.request;
  deleteCommissionsBatch: typeof deleteCommissionsBatchActions.request;
  setStateBatchDetail: typeof setStateBatchDetailAction;
  deleteStateBatchesPage: typeof deleteStateBatchesPageAction;
  pushNavigation: typeof push;
}

const PAGE_SIZE = 20;

const CommissionsBatchListContainer = (props: StateProps & ActionProps<ActionsMap>) => {

  const [batchFormVisible, setBatchFormVisible] = useState<boolean>(false);
  const [batchToUpdate, setBatchToUpdate] = useState<CommissionsBatch>();

  useEffect(() => {
    props.actions.filterCommissionsBatches({ pageIndex: 0, pageSize: PAGE_SIZE });
    return () => props.actions.deleteStateBatchesPage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTablePageChange = (pageNumber: number): void => {
    props.actions.filterCommissionsBatches({ pageIndex: pageNumber - 1, pageSize: PAGE_SIZE });
  };

  const handleShowDetailClick = (batch: CommissionsBatch): void => {
    props.actions.setStateBatchDetail(batch);
    props.actions.pushNavigation("/commissions/batches/" + batch.id);
  };

  const handleUpdateClick = (batch: CommissionsBatch): void => {
    setBatchFormVisible(true);
    setBatchToUpdate(batch);
  };

  const handleFormCancel = (): void => {
    setBatchFormVisible(false);
    setBatchToUpdate(null);
  };

  return (
    <>
      <CommissionsBatchListHeaderView
        onCreateBatchClick={() => setBatchFormVisible(true)} />

      <CommissionsBatchTableView
        batchesPage={props.batchesPage}
        onShowDetailClick={handleShowDetailClick}
        onUpdateClick={handleUpdateClick}
        onDelete={props.actions.deleteCommissionsBatch}
        onPageChange={handleTablePageChange} />

      <CommissionsBatchForm
        batch={batchToUpdate}
        visible={batchFormVisible}
        onCreate={props.actions.createCommissionsBatch}
        onUpdate={props.actions.updateCommissionsBatch}
        onFormCancel={handleFormCancel} />
    </>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  batchesPage: selectBatchesPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterCommissionsBatches: filterCommissionsBatchesActions.request,
    createCommissionsBatch: createCommissionsBatchActions.request,
    updateCommissionsBatch: updateCommissionsBatchActions.request,
    deleteCommissionsBatch: deleteCommissionsBatchActions.request,
    setStateBatchDetail: setStateBatchDetailAction,
    deleteStateBatchesPage: deleteStateBatchesPageAction,
    pushNavigation: push
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(CommissionsBatchListContainer);
