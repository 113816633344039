import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, InputNumber, Popconfirm, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Store } from "rc-field-form/lib/interface";
import { CreateUpdatePerson, Person } from "../../types";
import { UUID } from "../../../../common/types";
import { PersonType } from "../../enums";
import { contains, removeStringWhiteSpaces } from "../../../../common/utils/utils";
import { validations } from "../../../../common/utils/validationUtils";
import {
  getAllFieldsNames,
  resolveFormValidationError,
  toMoment,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../common/constants";
import t from "../../../../app/i18n";

import NaturalPersonDataFormPart from "./parts/NaturalPersonDataFormPart";
import SelfEmployedPersonDataFormPart from "./parts/SelfEmployedPersonDataFormPart";
import LegalPersonDataFormPart from "./parts/LegalPersonDataFormPart";
import PersonAddressesFormPart from "./parts/PersonAddressesFormPart";
import ContactsForm from "../../../../common/modules/contact/ContactsForm";
import PersonsEnumFormItemSelect from "../../../enumerations/components/form/PersonsEnumFormItemSelect";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  person: Person;
  onFormSubmit(person: CreateUpdatePerson): void;
  onCancelClick(): void;
}

const PersonUpdateForm = ({ person, ...props }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "person.attrs", requests.UPDATE_PERSON);

  const [parentId, setParentId] = useState<UUID>(person.parent ? person.parent.id : null);
  const [personDeactivated, setPersonDeactivated] = useState<boolean>(person.deactivated);
  const [serviceAddressEnabled, setServiceAddressEnabled] = useState<boolean>(!!person.serviceAddress);
  const [correspondenceAddressEnabled, setCorrespondenceAddressEnabled] = useState<boolean>(!!person.correspondenceAddress);

  useEffect(() => {
    form.setFieldsValue({
      ...person,
      parentId: person.parent ? person.parent.id : null,
      birthDate: toMoment(person["birthDate"])
    });
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = (): void => {
    let fieldsToValidate = getAllFieldsNames(form);
    fieldsToValidate = serviceAddressEnabled ? fieldsToValidate : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "serviceAddress"));
    fieldsToValidate = correspondenceAddressEnabled ? fieldsToValidate : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "correspondenceAddress"));

    form.validateFields(fieldsToValidate)
      .then((values: CreateUpdatePerson | Store) => {
        const processedValues = { ...values } as CreateUpdatePerson;

        processedValues.bankAccountNumber = removeStringWhiteSpaces(processedValues.bankAccountNumber);
        processedValues.contacts = processedValues.contacts ? processedValues.contacts : [];
        processedValues.serviceAddress = serviceAddressEnabled ? processedValues.serviceAddress : null;
        processedValues.correspondenceAddress = correspondenceAddressEnabled ? processedValues.correspondenceAddress : null;

        props.onFormSubmit(processedValues);
      })
      .catch(resolveFormValidationError);
  };

  const formLayout: ColProps = { span: 24, style: { maxWidth: "900px" } };
  const colSpan = 6;

  let dataFormSection;
  switch ( person.type ) {
    case PersonType.NATURAL:
      dataFormSection = <NaturalPersonDataFormPart form={form} />;
      break;
    case PersonType.SELF_EMPLOYED:
      dataFormSection = <SelfEmployedPersonDataFormPart form={form} />;
      break;
    case PersonType.LEGAL:
      dataFormSection = <LegalPersonDataFormPart />;
      break;
  }

  return (
    <Form form={form} layout="vertical" name="personUpdateForm">

      <HiddenInput name="type" />
      <HiddenInput name="optimisticLockVersion" />

      <Row>
        <Col {...formLayout}>
          <Row gutter={rowGutter}>
            {person.parent && (
              <Col span={colSpan}>
                <PersonsEnumFormItemSelect
                  formItemProps={{
                    name: "parentId",
                    label: t("person.attrs.parentId"),
                    rules: [validations.notNull]
                  }}
                  selectProps={{ onChange: setParentId }}
                  optionsProps={{
                    selected: person.parent,
                    includeDeactivated: personDeactivated,
                    filterOrganizationPath: person.organizationPath
                  }} />
              </Col>
            )}

            <Col span={colSpan}>
              <Form.Item
                name="nbsRegistrationNumber"
                label={t("person.attrs.nbsRegistrationNumber")}
                rules={[validations.size(1, 32), validations.numeric]}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="deactivated"
                valuePropName="checked"
                rules={[validations.none]}
                className="form-item-without-label">
                <Checkbox onChange={e => setPersonDeactivated(e.target.checked)}>
                  <LabelWithTooltip label={t("person.attrs.deactivated")}
                                    tooltip={t("person.helpers.deactivatedDesc")} />
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item
                name="oldCrmId"
                label={t("person.attrs.oldCrmId")}
                rules={[validations.notNull]}>
                <InputNumber min={1} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider orientation="left">{t("person.titles.basicData")}</Divider>

      <Row>
        <Col {...formLayout}>
          {dataFormSection}
        </Col>
      </Row>

      <Divider orientation="left">{t("person.titles.addresses")}</Divider>

      <Row>
        <Col {...formLayout}>
          <PersonAddressesFormPart
            form={form}
            personType={person.type}
            serviceAddressEnabled={serviceAddressEnabled}
            correspondenceAddressEnabled={correspondenceAddressEnabled}
            onServiceAddressEnabledChange={setServiceAddressEnabled}
            onCorrespondenceAddressEnabledChange={setCorrespondenceAddressEnabled} />
        </Col>
      </Row>

      <Divider orientation="left">{t("person.titles.contacts")}</Divider>

      <Row>
        <Col {...formLayout}>
          <ContactsForm form={form} initialContacts={person.contacts} useBrokerContactTypes />
        </Col>
      </Row>

      <div className="margin-top-large">
        {person.parent && person.parent.id !== parentId ? (
          <Popconfirm
            overlayStyle={{ maxWidth: "350px" }}
            title={t("person.titles.updateConfirm")}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            onConfirm={handleFormSubmit}>
            <Button type="primary" className="margin-right-tiny" icon={<SaveOutlined />}>{t("common.save")}</Button>
          </Popconfirm>
        ) : (
          <Button type="primary" className="margin-right-tiny" icon={<SaveOutlined />} onClick={handleFormSubmit}>
            {t("common.save")}
          </Button>
        )}
        <Button onClick={props.onCancelClick} icon={<CloseOutlined />}>{t("common.cancel")}</Button>
      </div>

    </Form>
  );
}

export default PersonUpdateForm;
