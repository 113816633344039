import React from "react";
import { Button, Col, Divider, Form, Input, Popconfirm, Row, Select, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { ProductGroup, UpdateProduct, UpdateProductGroupWithProducts } from "../types";
import { UUID } from "../../../../common/types";
import { ProductType } from "../enums";
import {
  createProductActions,
  createProductGroupActions,
  deleteProductActions,
  deleteProductGroupActions,
  updateProductGroupAndProductsActions
} from "../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../common/constants";
import t from "../../../../app/i18n";

import ProductGroupCreateForm from "./forms/ProductGroupCreateForm";
import ProductCreateForm from "./forms/ProductCreateForm";
import ProductRowForm from "./forms/ProductRowForm";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";

export interface Props {
  productGroups: ProductGroup[];
  locationKey: string;
  onCreateProductGroupFormSubmit: typeof createProductGroupActions.request;
  onCreateProductFormSubmit: typeof createProductActions.request;
  onUpdateProductGroupAndProductsFormSubmit: typeof updateProductGroupAndProductsActions.request;
  onDeleteProductGroupClick: typeof deleteProductGroupActions.request;
  onDeleteProductClick: typeof deleteProductActions.request;
}

interface State {
  readonly selectedProductGroupId: UUID;
  readonly productGroupNameEditEnabled: boolean;
  readonly createProductGroupFormVisible: boolean;
  readonly createProductFormVisible: boolean;
}

class ProductFormView extends React.Component<Props, State> {

  formRef = React.createRef<FormInstance>();

  readonly state: State = {
    selectedProductGroupId: null,
    productGroupNameEditEnabled: false,
    createProductGroupFormVisible: false,
    createProductFormVisible: false
  };

  handleProductGroupNameEditEnable = (): void => {
    this.setState({ productGroupNameEditEnabled: true });
  };

  handleProductGroupNameEditDisable = (): void => {
    this.setState({ productGroupNameEditEnabled: false });
    const productGroup = this.getProductGroupById(this.state.selectedProductGroupId);
    if ( productGroup ) {
      this.formRef.current.setFieldsValue({ name: productGroup.name });
    }
  };

  handleProductGroupChange = (selectedProductGroupId: UUID): void => {
    this.setSelectedProductGroup(this.getProductGroupById(selectedProductGroupId));
  };

  handleCreateProductGroupClick = (): void => {
    this.setState({ createProductGroupFormVisible: true });
  };

  handleCreateProductGroupFormCancel = (): void => {
    this.setState({ createProductGroupFormVisible: false });
  };

  handleCreateProductClick = (): void => {
    this.setState({ createProductFormVisible: true });
  };

  handleCreateProductFormCancel = (): void => {
    this.setState({ createProductFormVisible: false });
  };

  handleUpdateProductsFormFinish = (values: UpdateProductGroupWithProducts | any): void => {
    this.props.onUpdateProductGroupAndProductsFormSubmit({
      id: this.state.selectedProductGroupId,
      object: { ...values, products: values.products ? values.products : [] }
    });
  };

  setSelectedProductGroup = (productGroup: ProductGroup): void => {
    if ( productGroup ) {
      this.setState({ selectedProductGroupId: productGroup.id }, () => {
        this.formRef.current.setFieldsValue({
          ...productGroup,
          products: productGroup.products ? productGroup.products.map<UpdateProduct>(product => ({
            ...product,
            insuranceInstitutionIds: product.type === ProductType.INSURANCE_PRODUCT && product.insuranceInstitutions
              ? product.insuranceInstitutions.map(institution => institution.id) : undefined,
            bankInstitutionIds: product.type === ProductType.LOAN_PRODUCT && product.bankInstitutions
              ? product.bankInstitutions.map(bank => bank.id) : undefined
          })) : undefined
        });
      });
    }
  };

  getProductGroupById = (id: UUID): ProductGroup => {
    return this.props.productGroups.find(group => group.id === id);
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( this.props.productGroups.length > 0 && !this.state.selectedProductGroupId ) {
      this.setSelectedProductGroup(this.props.productGroups[0]);
    }
    else if ( this.props.productGroups.length > prevProps.productGroups.length ) {
      this.setSelectedProductGroup(this.props.productGroups[this.props.productGroups.length - 1]);
    }
    else if ( this.props.productGroups.length < prevProps.productGroups.length ) {
      this.setSelectedProductGroup(this.props.productGroups[0]);
    }
    else if ( this.props.locationKey !== prevProps.locationKey ) {
      this.setSelectedProductGroup(this.getProductGroupById(this.state.selectedProductGroupId));

      if ( this.state.productGroupNameEditEnabled ) {
        this.setState({ productGroupNameEditEnabled: false });
      }
    }
  }

  render(): React.ReactNode {
    const { selectedProductGroupId, productGroupNameEditEnabled } = this.state;
    const selectedProductGroup = this.getProductGroupById(selectedProductGroupId);
    const colSpan = 6;

    return (
      <>
        <h2>{t("admin.product.titles.page")}</h2>

        <Form ref={this.formRef} layout="vertical" name="productFormView" className="standard-container"
              onFinish={this.handleUpdateProductsFormFinish}>

          <Divider orientation="left">{t("admin.product.titles.productGroups")}</Divider>

          <HiddenInput name="optimisticLockVersion" />

          <Row gutter={rowGutter}>
            <Col span={24}>
              <ItemCreatedUpdatedInfoView item={selectedProductGroup} />
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={productGroupNameEditEnabled ? 0 : colSpan}>
              <Form.Item label={t("admin.product.attrs.productGroup")}>
                <Select
                  {...selectStandardProps}
                  value={selectedProductGroupId}
                  options={this.props.productGroups.map(group => ({ value: group.id, label: group.name }))}
                  onChange={this.handleProductGroupChange} />
              </Form.Item>
            </Col>

            <Col span={productGroupNameEditEnabled ? colSpan : 0}>
              <Form.Item
                name="name"
                label={t("admin.product.attrs.productGroupName")}
                rules={[validations.notBlank, validations.size(1, 64)]}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={1} style={{ marginTop: "32px" }}>
              {productGroupNameEditEnabled ? (
                <Tooltip title={t("admin.product.actions.editProductGroupNameCancel")}>
                <span>
                  <ActionTextIcon type="close" color="red" size="huge"
                                  onClick={this.handleProductGroupNameEditDisable} />
                </span>
                </Tooltip>
              ) : (
                <Tooltip title={t("admin.product.actions.editProductGroupName")}>
                <span>
                  <ActionTextIcon type="edit" color="blue" size="huge"
                                  onClick={this.handleProductGroupNameEditEnable} />
                </span>
                </Tooltip>
              )}
            </Col>

            <Col span={colSpan * 2} offset={1}>
              <Button className="blue-button margin-right-tiny" icon={<PlusOutlined />} size="small"
                      style={{ marginTop: "32px" }}
                      onClick={this.handleCreateProductGroupClick}>
                {t("admin.product.actions.createProductGroup")}
              </Button>

              {selectedProductGroup && (
                <Popconfirm
                  title={t("admin.product.titles.deleteProductGroupConfirm")}
                  icon={<PopconfirmDeleteIcon />}
                  okText={t("common.yes")}
                  cancelText={t("common.no")}
                  okType="danger"
                  onConfirm={() => this.props.onDeleteProductGroupClick({ id: selectedProductGroupId })}>
                  <Button icon={<DeleteOutlined />} size="small" style={{ marginTop: "32px" }} danger>
                    {t("admin.product.actions.deleteProductGroup")}
                  </Button>
                </Popconfirm>
              )}
            </Col>
          </Row>

          {selectedProductGroup && (
            <>
              <Divider orientation="left">{t("admin.product.titles.products")}</Divider>

              {selectedProductGroup.products && selectedProductGroup.products.map((product, index) =>
                <ProductRowForm key={index} index={index} productGroupId={selectedProductGroupId}
                                product={product} onDeleteClick={this.props.onDeleteProductClick} />)}

              <Row>
                <Button className="blue-button" icon={<PlusOutlined />} size="small"
                        onClick={this.handleCreateProductClick}>
                  {t("admin.product.actions.createProduct")}
                </Button>
              </Row>

              <Row className="margin-top-large">
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                  {t("admin.product.actions.saveProductsChanges")}
                </Button>
              </Row>
            </>
          )}

        </Form>

        <ProductGroupCreateForm
          visible={this.state.createProductGroupFormVisible}
          onFormSubmit={this.props.onCreateProductGroupFormSubmit}
          onFormCancel={this.handleCreateProductGroupFormCancel} />

        <ProductCreateForm
          productGroupId={selectedProductGroupId}
          visible={this.state.createProductFormVisible}
          onFormSubmit={this.props.onCreateProductFormSubmit}
          onFormCancel={this.handleCreateProductFormCancel} />
      </>
    );
  }
}

export default ProductFormView;
