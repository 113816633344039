import React from "react";
import { LegalPerson } from "../../../types";
import { formatIban } from "../../../../../common/utils/formatUtils";
import { createLinkHref } from "../../../../../common/utils/utils";
import t from "../../../../../app/i18n";

import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";

export interface Props {
  person: LegalPerson;
}

const LegalPersonDataView = ({ person }: Props) => (
  <>
    <tr>
      <td>{t("person.attrs.companyNamePO")}:</td>
      <td colSpan={3}>{person.companyName}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("person.attrs.companyRegistrationNumber")}:</td>
      <td><CopyToClipboardView content={person.companyRegistrationNumber} /></td>
      <td>{t("person.attrs.commercialRegisterDistrictOffice")}:</td>
      <td>{person.commercialRegisterDistrictOffice}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.taxId")}:</td>
      <td><CopyToClipboardView content={person.taxId} /></td>
      <td>{t("person.attrs.commercialRegisterSection")}:</td>
      <td>{person.commercialRegisterSection}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.vatId")}:</td>
      <td><CopyToClipboardView content={person.vatId} /></td>
      <td>{t("person.attrs.commercialRegisterInsertionNumber")}:</td>
      <td>{person.commercialRegisterInsertionNumber}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.bankAccountNumber")}:</td>
      <td><CopyToClipboardView content={formatIban(person.bankAccountNumber)} /></td>
      <td>{t("person.attrs.webPage")}:</td>
      <td>{person.webPage
        ? <a href={createLinkHref(person.webPage)} target="_blank" rel="noopener noreferrer">{person.webPage}</a>
        : null}
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("person.attrs.note")}:</td>
      <td colSpan={3}>{person.note}</td>
    </tr>
  </>
);

export default LegalPersonDataView;
