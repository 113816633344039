import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { ActionProps, RootState } from "../../../common/types";
import { createPersonActions } from "../ducks";

import PersonCreateForm from "../components/forms/PersonCreateForm";

interface ActionsMap {
  createPerson: typeof createPersonActions.request;
}

const PersonCreateContainer = (props: ActionProps<ActionsMap>) => {
  return <PersonCreateForm onFormSubmit={props.actions.createPerson} />
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    createPerson: createPersonActions.request
  }, dispatch)
});

export default connect<{}, ActionProps<ActionsMap>, {}, RootState>(null, mapDispatchToProps)(PersonCreateContainer);
