import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export interface Props {
  status: "success" | "error";
}

const TimelineDotIcon = ({ status }: Props) => (
  status === "success"
    ? <CheckCircleOutlined className="timeline-dot-icon" />
    : <CloseCircleOutlined className="timeline-dot-icon" />
);

export default TimelineDotIcon;
