import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import {
  CalcDraft,
  CalcDraftAttachment,
  CalcDraftFilterPageRequest,
  CalcDraftFilterPageResult,
  CreateUpdateCalcDraft
} from "./types";
import { EntityIdRequest, EntityObjectRequest, TwoLevelEntityIdRequest } from "../../../common/types";

const FILTER_CALC_DRAFTS = ApiRequestAdapter.create("/calculators/drafts", "GET");
const GET_CALC_DRAFT = ApiRequestAdapter.create("calculators/drafts/{0}", "GET");
const CREATE_CALC_DRAFT = ApiRequestAdapter.create("/calculators/drafts", "POST");
const UPDATE_CALC_DRAFT = ApiRequestAdapter.create("/calculators/drafts/{0}", "PUT");
const DELETE_CALC_DRAFT = ApiRequestAdapter.create("/calculators/drafts/{0}", "DELETE");
const UPLOAD_CALC_DRAFT_ATTACHMENTS = ApiRequestAdapter.create("/calculators/drafts/{0}/attachments", "POST");
const DOWNLOAD_CALC_DRAFT_ATTACHMENT = ApiRequestAdapter.create("/calculators/drafts/{0}/attachments/{1}", "GET");
const DELETE_CALC_DRAFT_ATTACHMENT = ApiRequestAdapter.create("/calculators/drafts/{0}/attachments/{1}", "DELETE");

export const requests = {
  FILTER_CALC_DRAFTS,
  GET_CALC_DRAFT,
  CREATE_CALC_DRAFT,
  UPDATE_CALC_DRAFT,
  DELETE_CALC_DRAFT,
  UPLOAD_CALC_DRAFT_ATTACHMENTS,
  DOWNLOAD_CALC_DRAFT_ATTACHMENT,
  DELETE_CALC_DRAFT_ATTACHMENT
};

export default {
  filterCalcDrafts: (request: CalcDraftFilterPageRequest): AxiosPromise<CalcDraftFilterPageResult> => {
    return apiService.get<CalcDraftFilterPageResult>(FILTER_CALC_DRAFTS.url, { params: request });
  },
  getCalcDraft: (request: EntityIdRequest): AxiosPromise<CalcDraft> => {
    return apiService.get<CalcDraft>(GET_CALC_DRAFT.getParametrizedUrl(request.id));
  },
  createCalcDraft: (request: CreateUpdateCalcDraft): AxiosPromise<CalcDraft> => {
    return apiService.post<CalcDraft>(CREATE_CALC_DRAFT.url, request);
  },
  updateCalcDraft: (request: EntityObjectRequest<CreateUpdateCalcDraft>): AxiosPromise<CalcDraft> => {
    return apiService.put<CalcDraft>(UPDATE_CALC_DRAFT.getParametrizedUrl(request.id), request.object);
  },
  deleteCalcDraft: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_CALC_DRAFT.getParametrizedUrl(request.id));
  },
  uploadCalcDraftAttachments: (request: EntityObjectRequest<FormData>): AxiosPromise<CalcDraftAttachment[]> => {
    return apiService.post<CalcDraftAttachment[]>(
      UPLOAD_CALC_DRAFT_ATTACHMENTS.getParametrizedUrl(request.id),
      request.object,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  },
  downloadCalcDraftAttachment: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.get(DOWNLOAD_CALC_DRAFT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), { responseType: "blob" });
  },
  deleteCalcDraftAttachment: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_CALC_DRAFT_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  }
}
