import React from "react";
import { Moment } from "moment";
import Big from "big.js";
import { Card, Col, Collapse, DatePicker, Form, Row } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import {
  CreateUpdateInsurance,
  CreateUpdateInsuranceContract,
  CreateUpdateLifeInsurance,
  CreateUpdateTravelInsurance,
  Insurance,
  InsuranceContract
} from "../../../../types";
import { Client } from "../../../../../client/types";
import { ProductBase } from "../../../../../admin/product/types";
import { InsuranceType } from "../../../../enums";
import { ProductType } from "../../../../../admin/product/enums";
import { calculatePartialPremium } from "../../../../utils";
import { valueToFloat } from "../../../../../../common/utils/utils";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMinDate
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import VehicleInsuranceFormPart from "../parts/VehicleInsuranceFormPart";
import RealtyInsuranceFormPart from "../parts/RealtyInsuranceFormPart";
import TravelInsuranceFormPart from "../parts/TravelInsuranceFormPart";
import LifeInsuranceFormPart from "../parts/LifeInsuranceFormPart";
import GenericInsuranceFormPart from "../parts/GenericInsuranceFormPart";
import ProductsEnumFormItemSelect from "../../../../../enumerations/components/form/ProductsEnumFormItemSelect";
import InputNumberWithAddon from "../../../../../../common/components/form/components/InputNumberWithAddon";
import HiddenInput from "../../../../../../common/components/form/components/HiddenInput";
import AddDeleteButton from "../../../../../../common/components/buttons/AddDeleteButton";

export interface Props {
  initialContract?: InsuranceContract;
  form: FormInstance;
  clients: Client[];
  insurancesCount: number;
  onInsurancesCountChange(count: number): void;
}

const InsuranceContractFormInsurancesSection = ({ initialContract, form, clients, insurancesCount, ...props }: Props) => {

  const handleInsuranceDelete = (index: number) => {
    let updatedInsurances = [...(form.getFieldValue(["insurances"]) || [])] as CreateUpdateInsurance[];
    props.onInsurancesCountChange(insurancesCount - 1);

    updatedInsurances.splice(index, 1);
    if ( updatedInsurances.length === 1 ) {
      updatedInsurances[0] = {
        ...updatedInsurances[0],
        contractEntryDate: null,
        contractWithdrawalDate: null,
        annualPremium: null,
        partialPremium: null
      }
    }
    form.setFieldsValue({ insurances: updatedInsurances });
  }

  const handleProductChange = (product: ProductBase, index: number): void => {
    const insurances = [...(form.getFieldValue(["insurances"]) || [])] as CreateUpdateInsurance[];

    if ( !insurances[index]?.id ) {
      insurances[index] = { ...insurances[index], type: product.insuranceType };

      if ( product.insuranceType === InsuranceType.TRAVEL ) {
        let travelInsurance = insurances[index] as CreateUpdateTravelInsurance;
        if ( !travelInsurance.insuranceData?.insuredClients ) {
          travelInsurance = {
            ...travelInsurance,
            insuranceData: {
              ...travelInsurance.insuranceData,
              insuredClients: [{ firstName: "", lastName: "", birthDate: null }]
            }
          }
        }
        insurances[index] = travelInsurance;
      }
    }

    if ( product.insuranceType === InsuranceType.LIFE ) {
      let lifeInsurance = insurances[index] as CreateUpdateLifeInsurance;
      lifeInsurance = { ...lifeInsurance, tariffId: null };
      insurances[index] = lifeInsurance;
    }

    form.setFieldsValue({ insurances });
  };

  const handleAnnualPremiumChange = (annualPremium: number | string, index: number): void => {
    const contract = form.getFieldsValue([["insurances"], ["paymentFrequency"]]) as CreateUpdateInsuranceContract;

    const insurances = [...contract.insurances || []];
    insurances[index] = {
      ...insurances?.[index],
      annualPremium: valueToFloat(annualPremium),
      partialPremium: calculatePartialPremium(valueToFloat(annualPremium), contract.paymentFrequency)
    }

    let totalAnnualPremium = new Big(0);
    insurances.forEach(insurance => totalAnnualPremium = totalAnnualPremium.plus(insurance?.annualPremium || 0));
    const totalAnnualPremiumValue = parseFloat(totalAnnualPremium.round(2, 1).valueOf());

    form.setFieldsValue({
      annualPremium: totalAnnualPremiumValue,
      partialPremium: calculatePartialPremium(totalAnnualPremiumValue, contract.paymentFrequency),
      insurances
    });
  };

  const getInitialInsurance = (index: number): Insurance => {
    const insuranceId = form.getFieldValue(["insurances", index, "id"]);
    return initialContract?.insurances.find(i => i.id === insuranceId);
  }

  const colSpan = 4;

  return (
    <Card size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.insurances")}>
      <Collapse defaultActiveKey={initialContract?.insurances.map((_, index) => index.toString()) || "0"}>
        {[...Array(insurancesCount)].map((_, index) => (
          <Collapse.Panel
            key={index}
            forceRender={true}
            header={<b>{t("contract.attrs.insurances._label")} {index + 1}</b>}
            extra={
              <span onClick={event => event.stopPropagation()}>
                <AddDeleteButton
                  type="delete" label={t("contract.actions.deleteInsurance")} disabled={insurancesCount === 1}
                  index={index} onClick={handleInsuranceDelete} />
              </span>
            }>

            <HiddenInput name={["insurances", index, "id"]} />
            <HiddenInput name={["insurances", index, "optimisticLockVersion"]} />
            <HiddenInput name={["insurances", index, "type"]} />

            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) => prev.insurances?.[index]?.id !== next.insurances?.[index]?.id
                    || prev.productGroupId !== next.productGroupId || prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
                  {({ getFieldValue }) => {
                    const groupId = getFieldValue("productGroupId");
                    return <ProductsEnumFormItemSelect
                      formItemProps={{
                        name: ["insurances", index, "productId"],
                        label: t("contract.attrs.insurances.productId"),
                        rules: [validations.notNull]
                      }}
                      optionsProps={{
                        selected: getInitialInsurance(index)?.product,
                        institutionId: getFieldValue("insuranceInstitutionId"),
                        productGroupId: groupId,
                        filterType: ProductType.INSURANCE_PRODUCT,
                        onChange: product => handleProductChange(product, index)
                      }}
                      selectProps={{ placeholder: !!!groupId ? t("contract.helpers.productPlaceholder") : undefined }} />
                  }}
                </Form.Item>
              </Col>
            </Row>

            {insurancesCount > 1 && (
              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.effectiveBeginningDate !== next.effectiveBeginningDate || prev.effectiveEndDate !== next.effectiveEndDate}>
                    {({ getFieldValue }) => {
                      const beginningDate = getFieldValue("effectiveBeginningDate") as Moment;
                      const endDate = getFieldValue("effectiveEndDate") as Moment;
                      const rules: Rule[] = [validations.notNull];
                      if ( beginningDate ) {
                        rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
                      }
                      if ( endDate ) {
                        rules.push(validations.notAfter(endDate, t("contract.attrs.effectiveEndDate")));
                      }
                      return (
                        <Form.Item
                          name={["insurances", index, "contractEntryDate"]}
                          label={t("contract.attrs.insurances.contractEntryDate")}
                          rules={rules}>
                          <DatePicker
                            {...datePickerStandardProps}
                            disabledDate={current => (beginningDate && disableDatePickerOutOfMinDate(current, beginningDate))
                              || (endDate && disableDatePickerOutOfMaxDate(current, endDate))} />
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) => prev.insurances?.[index]?.contractEntryDate !== next.insurances?.[index]?.contractEntryDate
                      || prev.effectiveBeginningDate !== next.effectiveBeginningDate || prev.effectiveEndDate !== next.effectiveEndDate}>
                    {({ getFieldValue }) => {
                      const entryDate = getFieldValue(["insurances", index, "contractEntryDate"]) as Moment;
                      const beginningDate = getFieldValue("effectiveBeginningDate") as Moment;
                      const endDate = getFieldValue("effectiveEndDate") as Moment;
                      const rules = [];
                      if ( entryDate ) {
                        rules.push(validations.notBefore(entryDate, t("contract.attrs.insurances.contractEntryDate")));
                      }
                      else if ( beginningDate ) {
                        rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
                      }
                      if ( endDate ) {
                        rules.push(validations.notAfter(endDate, t("contract.attrs.effectiveEndDate")));
                      }
                      return (
                        <Form.Item
                          name={["insurances", index, "contractWithdrawalDate"]}
                          label={t("contract.attrs.insurances.contractWithdrawalDate")}
                          rules={rules}>
                          <DatePicker
                            {...datePickerClearableProps}
                            disabledDate={current =>
                              ((entryDate || beginningDate) && disableDatePickerOutOfMinDate(current, entryDate || beginningDate))
                              || (endDate && disableDatePickerOutOfMaxDate(current, endDate))} />
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["insurances", index, "annualPremium"]}
                    label={t("contract.attrs.insurances.annualPremium")}
                    rules={[validations.notNull, validations.minNumber(0)]}>
                    <InputNumberWithAddon
                      addonType="euro" formatStyle="decimal" min={0.01}
                      onChange={value => handleAnnualPremiumChange(value, index)} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["insurances", index, "partialPremium"]}
                    label={t("contract.attrs.insurances.partialPremium")}
                    rules={[validations.notNull, validations.minNumber(0)]}>
                    <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0.01} disabled />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.insurances?.[index]?.type !== next.insurances?.[index]?.type}>
              {({ getFieldValue }) => {
                const type = getFieldValue(["insurances", index, "type"]) as InsuranceType;
                switch ( type ) {
                  case InsuranceType.MTPL:
                  case InsuranceType.CRASH:
                  case InsuranceType.GAP:
                  case InsuranceType.PAS:
                    return <VehicleInsuranceFormPart index={index} clients={clients} form={form} type={type} />;
                  case InsuranceType.REALTY:
                    return <RealtyInsuranceFormPart index={index} clients={clients} form={form} />;
                  case InsuranceType.TRAVEL:
                    return <TravelInsuranceFormPart index={index} />;
                  case InsuranceType.LIFE:
                    return <LifeInsuranceFormPart index={index} clients={clients} />;
                  case InsuranceType.GENERIC:
                    return <GenericInsuranceFormPart index={index} clients={clients} />;
                  default:
                    return null;
                }
              }}
            </Form.Item>

          </Collapse.Panel>
        ))}
      </Collapse>

      <AddDeleteButton
        type="add" label={t("contract.actions.addInsurance")} className="margin-top-medium"
        onClick={() => props.onInsurancesCountChange(insurancesCount + 1)} />
    </Card>
  )
}

export default InsuranceContractFormInsurancesSection;
