import React, { useEffect } from "react";
import moment from "moment";
import { Col, Form, Input, Modal, Row } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { CommissionsBatch, CreateUpdateCommissionsBatch } from "../../types";
import { createCommissionsBatchActions, updateCommissionsBatchActions } from "../../ducks";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import HiddenInput from "../../../../../common/components/form/components/HiddenInput";

export interface Props {
  batch: CommissionsBatch;
  visible: boolean;
  onCreate?: typeof createCommissionsBatchActions.request;
  onUpdate: typeof updateCommissionsBatchActions.request;
  onFormCancel(): void;
}

const CommissionsBatchForm = ({ batch, visible, onCreate, onUpdate, onFormCancel }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "commissions.batch.attrs", requests.CREATE_COMMISSIONS_BATCH);
  useFormErrorHandler(form, "commissions.batch.attrs", requests.UPDATE_COMMISSIONS_BATCH);

  useEffect(() => {
    if ( visible ) {
      form.setFieldsValue({
        optimisticLockVersion: batch?.optimisticLockVersion,
        name: batch ? batch.name : moment().format("YYYY-MM-DD_HH:mm ")
      });
    }
  }, [visible, batch, form]);

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
  };

  const createInProgress = useRequestFinishedCallback(requests.CREATE_COMMISSIONS_BATCH, handleFormCancel);
  const updateInProgress = useRequestFinishedCallback(requests.UPDATE_COMMISSIONS_BATCH, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: CreateUpdateCommissionsBatch | Store) => {
        if ( batch ) {
          onUpdate({ id: batch.id, object: values as CreateUpdateCommissionsBatch });
        }
        else {
          onCreate?.(values as CreateUpdateCommissionsBatch);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={600}
      visible={visible}
      title={batch ? t("commissions.batch.titles.updateBatch") : t("commissions.batch.titles.createBatch")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={createInProgress || updateInProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="commissionsBatchForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={16} offset={4}>
            <Form.Item
              name="name"
              label={t("commissions.batch.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 64)]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
};

export default CommissionsBatchForm;
