import React from "react";
import { Tag } from "antd";
import { CommissionType } from "../enums";
import t from "../../../../app/i18n";

export interface Props {
  type: CommissionType;
}

const CommissionTypeTag = ({ type }: Props) => {
  let color: string;

  switch ( type ) {
    case CommissionType.INITIAL:
      color = "geekblue";
      break;
    case CommissionType.SUBSEQUENT:
      color = "purple";
      break;
    default:
      return null;
  }

  return <Tag color={color}>{t("commissions.batch.enums.commissionType." + type)}</Tag>;
};

export default CommissionTypeTag;
