import React from "react";
import { Col, Divider, Form, Row } from "antd";
import { CalcType } from "../../../enums";
import { validations } from "../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import ProductsEnumFormItemSelect from "../../../../enumerations/components/form/ProductsEnumFormItemSelect";

export interface Props {
  calcType: CalcType;
}

const CalcSettingsProductsPart = ({ calcType }: Props) => {

  const formProductsIds = [];
  switch ( calcType ) {
    case CalcType.MTPL:
      formProductsIds.push("mtplProductId");
      break;
    case CalcType.CRASH:
      formProductsIds.push("crashProductId");
      break;
    case CalcType.GAP:
      formProductsIds.push("gapProductId");
      break;
    case CalcType.PAS:
      formProductsIds.push("pasProductId");
      break;
    case CalcType.REALTY:
      formProductsIds.push("realtyProductId");
      break;
    case CalcType.TRAVEL:
      formProductsIds.push("shortTermInsuranceProductId", "yearInsuranceProductId", "cancellationInsuranceProductId");
      break;
  }

  const colSpan = 5;

  return (
    <>
      <Divider orientation="left">{t("calc.settings.sections.basicSettings")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.insuranceInstitutionId !== next.insuranceInstitutionId}>
        {({ getFieldValue }) => {
          const institutionId = getFieldValue("insuranceInstitutionId");
          return (
            <Row gutter={rowGutter}>
              {formProductsIds.map((productId, index) =>
                <Col span={colSpan} key={index}>
                  <ProductsEnumFormItemSelect
                    formItemProps={{
                      name: productId,
                      label: t(`calc.settings.attrs.${productId}`),
                      rules: [validations.notNull]
                    }}
                    optionsProps={{ institutionId, groupByProductGroup: true }} />
                </Col>
              )}
            </Row>
          )
        }}
      </Form.Item>
    </>
  )
};

export default CalcSettingsProductsPart;
