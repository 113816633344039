import React from "react";
import { Col, Input, Row } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import validations, { regexPatterns } from "../../../../../common/utils/validationUtils";
import { ibanNormalizeFunction } from "../../../../../common/utils/formUtils";
import {
  commercialRegisterDistrictOffices,
  commercialRegisterSections,
  rowGutter
} from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";

export interface Props {
  form: WrappedFormUtils;
  initialCrnValue?: string;
  disableCrnInput?: boolean;
}

const LegalClientDataFormPart = ({ form, initialCrnValue, disableCrnInput }: Props) => {
  const { getFieldDecorator } = form;
  const colSpan = 6;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.companyNamePO")}>
            {getFieldDecorator("companyName", { rules: [validations.notBlank, validations.size(1, 255)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.companyRegistrationNumber")}>
            {getFieldDecorator("companyRegistrationNumber", {
              rules: [validations.notBlank, validations.crn], initialValue: initialCrnValue
            })(
              <Input disabled={disableCrnInput} />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            label={<LabelWithPopover label={t("client.attrs.commercialRegisterDistrictOffice")}
                                     popoverContent={t("helpers.allowedValues", { values: commercialRegisterDistrictOffices.join(", ") })} />}>
            {getFieldDecorator("commercialRegisterDistrictOffice", {
              rules: [validations.notBlank, validations.size(1, 64), validations.commercialRegisterDistrictOffice]
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.taxId")}>
            {getFieldDecorator("taxId", { rules: [validations.length(10), validations.numeric] })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            label={<LabelWithPopover label={t("client.attrs.commercialRegisterSection")}
                                     popoverContent={t("helpers.allowedValues", { values: commercialRegisterSections.join(", ") })} />}>
            {getFieldDecorator("commercialRegisterSection", {
              rules: [validations.notBlank, validations.size(1, 64), validations.commercialRegisterSection]
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.vatId")}>
            {getFieldDecorator("vatId", { rules: [validations.length(12), validations.pattern(regexPatterns.vatIdRegex)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.commercialRegisterInsertionNumber")}>
            {getFieldDecorator("commercialRegisterInsertionNumber", { rules: [validations.notBlank, validations.size(1, 64)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.bankAccountNumber")}>
            {getFieldDecorator("bankAccountNumber", {
              rules: [validations.size(1, 34), validations.iban], normalize: ibanNormalizeFunction
            })(
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("client.attrs.webPage")}>
            {getFieldDecorator("webPage", { rules: [validations.size(1, 2048), validations.pattern(regexPatterns.webPageRegex)] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item label={t("client.attrs.note")}>
            {getFieldDecorator("note", { rules: [validations.size(1, 8192)] })(
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default LegalClientDataFormPart;
