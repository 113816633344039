import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ActionProps, RootState } from "../../../common/types";
import { loginActions } from "../ducks";

import LoginForm from "../components/LoginForm";

interface ActionsMap {
  login: typeof loginActions.request;
}

const LoginContainer = (props: ActionProps<ActionsMap>) => {
  return <LoginForm onFormSubmit={props.actions.login} />;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    login: loginActions.request
  }, dispatch)
});

export default connect<{}, ActionProps<ActionsMap>, {}, RootState>(null, mapDispatchToProps)(LoginContainer);
