import React, { useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { ColProps } from "antd/lib/grid";
import { SaveOutlined } from "@ant-design/icons";
import { Store } from "rc-field-form/lib/interface"
import { CreateUpdatePerson } from "../../types";
import { PersonType } from "../../enums";
import { createPersonActions } from "../../ducks";
import { contains, removeStringWhiteSpaces } from "../../../../common/utils/utils";
import {
  getAllFieldsNames,
  resolveFormValidationError,
  selectStandardProps,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../common/constants";
import t from "../../../../app/i18n";

import NaturalPersonDataFormPart from "./parts/NaturalPersonDataFormPart";
import SelfEmployedPersonDataFormPart from "./parts/SelfEmployedPersonDataFormPart";
import LegalPersonDataFormPart from "./parts/LegalPersonDataFormPart";
import PersonAddressesFormPart from "./parts/PersonAddressesFormPart";
import ContactsForm from "../../../../common/modules/contact/ContactsForm";
import PersonsEnumFormItemSelect from "../../../enumerations/components/form/PersonsEnumFormItemSelect";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  onFormSubmit: typeof createPersonActions.request;
}

const PersonCreateForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "person.attrs", requests.CREATE_PERSON);

  const [personType, setPersonType] = useState<PersonType>(PersonType.NATURAL);
  const [personDeactivated, setPersonDeactivated] = useState<boolean>(false);
  const [serviceAddressEnabled, setServiceAddressEnabled] = useState<boolean>(false);
  const [correspondenceAddressEnabled, setCorrespondenceAddressEnabled] = useState<boolean>(false);

  const handleFormSubmit = (): void => {
    let fieldsToValidate = getAllFieldsNames(form);
    fieldsToValidate = serviceAddressEnabled ? fieldsToValidate : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "serviceAddress"));
    fieldsToValidate = correspondenceAddressEnabled ? fieldsToValidate : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "correspondenceAddress"));

    form.validateFields(fieldsToValidate)
      .then((values: CreateUpdatePerson | Store) => {
        const processedValues = { ...values } as CreateUpdatePerson;

        processedValues.bankAccountNumber = removeStringWhiteSpaces(processedValues.bankAccountNumber);
        processedValues.contacts = processedValues.contacts ? processedValues.contacts : [];
        processedValues.serviceAddress = serviceAddressEnabled ? processedValues.serviceAddress : null;
        processedValues.correspondenceAddress = correspondenceAddressEnabled ? processedValues.correspondenceAddress : null;

        props.onFormSubmit(processedValues);
      })
      .catch(resolveFormValidationError);
  };

  const formLayout: ColProps = { span: 24, style: { maxWidth: "900px" } };
  const colSpan = 6;

  let dataFormSection;
  switch ( personType ) {
    case PersonType.NATURAL:
      dataFormSection = <NaturalPersonDataFormPart form={form} />;
      break;
    case PersonType.SELF_EMPLOYED:
      dataFormSection = <SelfEmployedPersonDataFormPart form={form} />;
      break;
    case PersonType.LEGAL:
      dataFormSection = <LegalPersonDataFormPart />;
      break;
  }

  return (
    <>
      <h2>{t("person.titles.create")}</h2>

      <Form form={form} layout="vertical" name="personCreateForm">

        <Row>
          <Col {...formLayout}>
            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <Form.Item
                  name="type"
                  label={t("person.enums.type._label")}
                  rules={[validations.notNull]}
                  initialValue={personType}>
                  <Select<PersonType>
                    {...selectStandardProps}
                    options={Object.keys(PersonType).map(type => ({
                      value: type,
                      label: t("person.enums.type." + type)
                    }))}
                    onChange={setPersonType} />
                </Form.Item>
              </Col>

              <Col offset={colSpan * 2} span={colSpan}>
                <Form.Item
                  name="oldCrmId"
                  label={t("person.attrs.oldCrmId")}
                  rules={[validations.notNull]}>
                  <InputNumber min={1} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <PersonsEnumFormItemSelect
                  formItemProps={{
                    name: "parentId",
                    label: t("person.attrs.parentId"),
                    rules: [validations.notNull]
                  }}
                  optionsProps={{ includeDeactivated: personDeactivated }} />
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name="nbsRegistrationNumber"
                  label={t("person.attrs.nbsRegistrationNumber")}
                  rules={[validations.size(1, 32), validations.numeric]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name="deactivated"
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={personDeactivated}
                  className="form-item-without-label">
                  <Checkbox onChange={e => setPersonDeactivated(e.target.checked)}>
                    <LabelWithTooltip label={t("person.attrs.deactivated")}
                                      tooltip={t("person.helpers.deactivatedDesc")} />
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider orientation="left">{t("person.titles.basicData")}</Divider>

        <Row>
          <Col {...formLayout}>
            {dataFormSection}
          </Col>
        </Row>

        <Divider orientation="left">{t("person.titles.addresses")}</Divider>

        <Row>
          <Col {...formLayout}>
            <PersonAddressesFormPart
              form={form}
              personType={personType}
              serviceAddressEnabled={serviceAddressEnabled}
              correspondenceAddressEnabled={correspondenceAddressEnabled}
              onServiceAddressEnabledChange={setServiceAddressEnabled}
              onCorrespondenceAddressEnabledChange={setCorrespondenceAddressEnabled} />
          </Col>
        </Row>

        <Divider orientation="left">{t("person.titles.contacts")}</Divider>

        <Row>
          <Col {...formLayout}>
            <ContactsForm form={form} useBrokerContactTypes />
          </Col>
        </Row>

        <Button className="margin-top-large" type="primary" icon={<SaveOutlined />} onClick={handleFormSubmit}>
          {t("common.save")}
        </Button>

      </Form>
    </>
  );
}

export default PersonCreateForm;
