import moment, { Moment } from "moment";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { ValidationRule } from "@ant-design/compatible/lib/form/Form";
import {
  TravelCalc,
  TravelCalcInsuredClient,
  TravelFormClients,
  TravelGen,
  TravelGenFormData,
  TravelGenFormInsuredClient
} from "./types";
import { CalcType } from "../../enums";
import { createUpdateContractClientToClient } from "../../../client/utils";
import { parseBirthDateFromPin } from "../../../../common/utils/utils";
import { toMoment } from "../../../../common/utils/formUtils";
import { validationFunctions } from "../../../../common/utils/validationUtils";
import t from "../../../../app/i18n";

export const resolveUnionFamilyDiscountPossible = (clients: TravelCalcInsuredClient[]): boolean => {
  return clients
    ? clients.filter(c => c.age && c.age >= 18).length === 2 && clients.filter(c => c.age && c.age < 18).length > 0
    : false;
};

export const createTravelCalcObjectFromCalcData = (calcData: TravelCalc): TravelCalc => {
  const data = cloneDeep(calcData);
  set(data, "generalData.effectiveBeginningDate", toMoment(calcData.generalData.effectiveBeginningDate));
  set(data, "generalData.effectiveEndDate", toMoment(calcData.generalData.effectiveEndDate));

  if ( data.cancellationInsuranceData ) {
    set(data, "cancellationInsuranceData.firstPaymentDate", toMoment(calcData.cancellationInsuranceData.firstPaymentDate));
  }

  if ( data.reinsurancesData && data.reinsurancesData.cancellationReinsurance ) {
    set(data, "reinsurancesData.cancellationReinsurance.firstPaymentDate", toMoment(calcData.reinsurancesData.cancellationReinsurance.firstPaymentDate));
  }

  return data;
};

export const createTravelFormClientsObject = (genData: TravelGen): TravelFormClients => ({
  insurer: createUpdateContractClientToClient(genData.clientsData.clients[0]),
  authorized1: createUpdateContractClientToClient(genData.clientsData.clients[1]),
  authorized2: createUpdateContractClientToClient(genData.clientsData.clients[2])
});

export const createTravelCalcObjectFromGenData = (genData: TravelGen): TravelCalc => {
  const genDataCopy = cloneDeep(genData);
  const data = {
    type: CalcType.TRAVEL,
    generalData: genDataCopy.generalData,
    clientsData: {
      insuredClientsCount: genDataCopy.clientsData.insuredClientsCount,
      insuredClients: genDataCopy.clientsData.insuredClients ? genDataCopy.clientsData.insuredClients.map<TravelCalcInsuredClient>(client => ({
        age: client.age,
        discountType: client.discountType,
        electronicDevicesReinsurance: client.electronicDevicesReinsurance,
        travelDocumentsReinsurance: client.travelDocumentsReinsurance
      })) : null
    },
    cancellationInsuranceData: genDataCopy.cancellationInsuranceData,
    reinsurancesData: genDataCopy.reinsurancesData,
    discountsData: genDataCopy.discountsData
  };

  if ( data.cancellationInsuranceData ) {
    delete data.cancellationInsuranceData.serviceType;
  }

  if ( data.reinsurancesData ) {
    if ( data.reinsurancesData.cancellationReinsurance ) {
      delete data.reinsurancesData.cancellationReinsurance.serviceType;
    }
    delete data.reinsurancesData.vehicleAssistanceReinsuranceData;
    delete data.reinsurancesData.abandonedHouseholdReinsuranceData;
    delete data.reinsurancesData.petReinsuranceData;
  }

  return createTravelCalcObjectFromCalcData(data);
};

export const createTravelGenFormDataObject = (genData: TravelGen, clients: TravelFormClients): TravelGenFormData => {
  const data = {
    formData: {
      clientsData: {
        insurerIdentifier: clients.insurer.identifier,
        insurerIsAlsoInsured: genData.clientsData.insurerIsAlsoInsured,
        insurerEmail: genData.clientsData.insurerEmail,
        insurerPhone: genData.clientsData.insurerPhone,
        authorizedClient1Identifier: clients.authorized1 ? clients.authorized1.identifier : null,
        authorizedClient1Function: genData.clientsData.authorizedClient1Function,
        authorizedClient2Identifier: clients.authorized2 ? clients.authorized2.identifier : null,
        authorizedClient2Function: genData.clientsData.authorizedClient2Function,
        insuredClients: genData.clientsData.insuredClients.map<TravelGenFormInsuredClient>(client => ({
          firstName: client.firstName,
          lastName: client.lastName,
          academicDegree: client.academicDegree,
          academicDegreeAfter: client.academicDegreeAfter,
          birthDate: toMoment(client.birthDate),
          personalIdentificationNumber: client.personalIdentificationNumber,
          discountIdentifier: client.discountIdentifier
        }))
      },
      informationObligationData: genData.informationObligationData
    },
    clientsData: {
      clientsDuplicateErrors: [],
      authorizedClientsCount: clients.authorized1 && clients.authorized2 ? 2 : 1
    }
  } as TravelGenFormData;

  if ( genData.reinsurancesData ) {
    if ( genData.reinsurancesData.cancellationReinsurance ) {
      set(data, "formData.reinsurancesData.cancellationReinsurance.serviceType", genData.reinsurancesData.cancellationReinsurance.serviceType);
    }
    set(data, "formData.reinsurancesData.vehicleAssistanceReinsuranceData", genData.reinsurancesData.vehicleAssistanceReinsuranceData);
    set(data, "formData.reinsurancesData.abandonedHouseholdReinsuranceData", genData.reinsurancesData.abandonedHouseholdReinsuranceData);
    set(data, "formData.reinsurancesData.petReinsuranceData", genData.reinsurancesData.petReinsuranceData);
  }

  return data;
};

export const insuredClientAgeValidationRule = (effectiveBeginningDate: Moment | string, age: number): ValidationRule => ({
  validator: (rule, value, callback) => callback(
    value && moment.isMoment(effectiveBeginningDate) && age
    && moment(effectiveBeginningDate).add(1, "day").diff(value, "years") !== age
      ? t("calc.travel.validations.incorrectBirthDate")
      : undefined
  )
});

export const insuredClientAgeFromPinValidationRule = (effectiveBeginningDate: Moment | string, age: number): ValidationRule => ({
  validator: (rule, value, callback) => callback(
    value && validationFunctions.validatePin(value) && moment.isMoment(effectiveBeginningDate) && age
    && moment(effectiveBeginningDate).add(1, "day").diff(parseBirthDateFromPin(value), "years") !== age
      ? t("calc.travel.validations.incorrectBirthDateFromPin")
      : undefined
  )
});
