import React from "react";
import { Link } from "react-router-dom";
import { ClientBase } from "../types";

import ClientTypeTag from "./ClientTypeTag";

export interface Props {
  client: ClientBase;
  showLink?: boolean;
}

const ClientTypeTagWithName = ({ client, showLink }: Props) => (
  client ?
    <>
      <ClientTypeTag type={client.type} style={{ fontWeight: "normal" }} />
      {showLink && client.id
        ? <Link to={`/clients/${client.id}`}>{client.aggregatedName}</Link>
        : client.aggregatedName}
    </>
    : null
);

export default ClientTypeTagWithName;
