import React from "react";
import { Moment } from "moment";
import { Col, DatePicker, Form, Modal, Row } from "antd";
import { JobSettings, ScheduleJobRequest } from "../../types";
import { scheduleJobActions } from "../../ducks";
import {
  dateTimePickerClearableProps,
  momentToDateTimeString,
  resolveFormValidationError
} from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { rowGutter } from "../../../../../common/constants";
import { requests } from "../../api";
import t from "../../../../../app/i18n";

export interface Props {
  job: JobSettings;
  visible: boolean;
  onSchedule: typeof scheduleJobActions.request;
  onFormCancel(): void;
}

const ScheduleJobForm = ({ job, visible, onSchedule, onFormCancel }: Props) => {

  const [form] = Form.useForm();

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
  };

  const inProgress = useRequestFinishedCallback(requests.SCHEDULE_JOB, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: ScheduleJobRequest) => onSchedule({
        id: job.id,
        object: { scheduledFor: momentToDateTimeString(values.scheduledFor as Moment) }
      }))
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={400}
      visible={visible}
      title={job ? t("admin.job.titles.scheduleJob", { jobName: job.name }) : null}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="scheduleJobForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={16}>
            <Form.Item
              name="scheduledFor"
              label={t("admin.job.attrs.record.scheduledFor")}
              rules={[validations.none]}>
              <DatePicker {...dateTimePickerClearableProps} showTime />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
}

export default ScheduleJobForm;
