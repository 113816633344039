import React from "react";
import { InsuranceContract, LifeInsurance } from "../../../../../types";
import { ClientFormType } from "../../../../../../client/enums";
import {
  formatLifeInsuranceTariff,
  formatLocaleCurrency,
  formatLocaleDate
} from "../../../../../../../common/utils/formatUtils";
import t from "../../../../../../../app/i18n";

import ClientsListDrawerView from "../../../../../../client/components/drawers/ClientsListDrawerView";

export interface Props {
  contract: InsuranceContract;
  index: number;
  multipleInsurances: boolean;
  multipleClients: boolean;
}

const LifeInsuranceView = ({ contract, index, multipleInsurances, multipleClients }: Props) => {
  const { insuranceData, ...insurance } = contract.insurances[index] as LifeInsurance;

  return (
    <>
      <table className="data-table-view">
        <tbody>
        {multipleInsurances && (
          <>
            <tr>
              <td>{t("contract.attrs.insurances.contractEntryDate")}:</td>
              <td>{formatLocaleDate(insurance.contractEntryDate)}</td>
              <td>{t("contract.attrs.insurances.annualPremium")}:</td>
              <td className="right-align">{formatLocaleCurrency(insurance.annualPremium)}</td>
              <td colSpan={2} />
            </tr>
            <tr>
              <td>{t("contract.attrs.insurances.contractWithdrawalDate")}:</td>
              <td>{formatLocaleDate(insurance.contractWithdrawalDate)}</td>
              <td>{t("contract.attrs.insurances.partialPremium")}:</td>
              <td className="right-align">{formatLocaleCurrency(insurance.partialPremium)}</td>
              <td colSpan={2} />
            </tr>
          </>)}
        <tr>
          <td>{t("contract.attrs.insurances.tariffId")}:</td>
          <td>{formatLifeInsuranceTariff(insurance.tariff)}</td>
          <td>{t("contract.attrs.insurances.insuranceData.insurancePeriod")}:</td>
          <td>{insuranceData.insurancePeriod}</td>
          <td>{t("contract.attrs.insurances.insuranceData.insuranceAmount")}:</td>
          <td className="right-align">{formatLocaleCurrency(insuranceData.insuranceAmount)}</td>
        </tr>
        </tbody>
      </table>

      {multipleClients && (
        <ClientsListDrawerView className="margin-top-medium" clientsData={[{
          client: contract.clients[insurance.insuredClientIndex],
          clientFormTypes: [ClientFormType.INSURED]
        }]} />)}
    </>
  )
}

export default LifeInsuranceView;
