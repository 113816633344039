import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import batchReducer, { batchSaga } from "./batch/ducks";

/**
 * REDUCERS
 */
export default combineReducers({
  batch: batchReducer
});

/**
 * SAGAS
 */
export function* commissionsSaga() {
  yield all([batchSaga()]);
}
