import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { HistoryItem, HistoryRecordType, LabelledHistoryItem } from "../types";
import { validationFunctions } from "../../utils/validationUtils";
import {
  DATE_REGEX,
  DATE_TIME_REGEX,
  formatIban,
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocaleDateTime,
  formatLocaleNumber
} from "../../utils/formatUtils";
import { isAddress, isArrayOfContacts, isLabelledHistoryItem } from "../../utils/typesUtils";
import { OrganizationPathDelimiter } from "../../constants";
import t from "../../../app/i18n";

import PersonEnumName from "../../../modules/enumerations/components/view/PersonEnumName";
import AddressView from "../address/AddressView";
import ContactsView from "../contact/ContactsView";

export interface Props {
  item: HistoryItem;
  recordType?: HistoryRecordType;
}

const HistoryItemValueView = ({ item, recordType }: Props) => {
  const { value, fieldName } = item;

  if ( value === null || value === undefined ) {
    return <span className="sub-header-info dashed">{t("history.helpers.noValue")}</span>
  }
  else if ( typeof value === "boolean" ) {
    return value ? <CheckOutlined /> : <CloseOutlined />;
  }
  else if ( recordType === "person" && fieldName === "organizationPath" && typeof value === "string" ) {
    const tokens = (value as string).split(OrganizationPathDelimiter);
    return <PersonEnumName id={tokens[tokens.length - 2]} />
  }
  else if ( typeof value === "string" ) {
    let stringValue = value;
    if ( DATE_TIME_REGEX.test(value) ) {
      stringValue = formatLocaleDateTime(value);
    }
    else if ( DATE_REGEX.test(value) ) {
      stringValue = formatLocaleDate(value);
    }
    else if ( validationFunctions.validateIban(value) ) {
      stringValue = formatIban(value);
    }
    return <span>{stringValue}</span>
  }
  else if ( typeof value === "number" ) {
    return <span>{Number.isInteger(value) ? formatLocaleNumber(value) : formatLocaleCurrency(value)}</span>
  }
  else if ( typeof value === "object" ) {
    if ( Object.keys(value).length === 0 ) {
      return <span className="sub-header-info dashed">{t("history.helpers.noValue")}</span>
    }
    else if ( isLabelledHistoryItem(value) ) {
      return <span>{(value as LabelledHistoryItem).label}</span>
    }
    else if ( isAddress(value) ) {
      return <AddressView address={value} showCountry />
    }
    else if ( isArrayOfContacts(value) ) {
      return <ContactsView contacts={value} showUseForNotifications={recordType === "person"} />
    }
  }

  console.error("Unsupported value type in HistoryView", value);

  return <span>{t("history.helpers.unsupportedValueType")}</span>
};

export default HistoryItemValueView;
