import "./contact.less";
import React from "react";
import { Tooltip } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import { Contact } from "../types";
import { ContactType } from "../enums";
import { formatPhoneNumber } from "../../utils/formatUtils";
import t from "../../../app/i18n";

import ContactTypeIcon from "./ContactTypeIcon";

export interface Props {
  contacts: Contact[];
  showUseForNotifications?: boolean;
}

const ContactsView = ({ contacts, showUseForNotifications }: Props) => {

  const resolveContactValue = (contact: Contact): React.ReactNode => {
    switch ( contact.type ) {
      case ContactType.EMAIL:
      case ContactType.BROKER_EMAIL:
        return <a href={`mailto:${contact.value}`}>{contact.value}</a>;
      case ContactType.PHONE_NUMBER:
      case ContactType.BROKER_PHONE_NUMBER:
        return <a href={`tel:${contact.value}`}>{formatPhoneNumber(contact.value)}</a>;
      default:
        return contact.value;
    }
  };

  return contacts && contacts.length > 0 ? (
    <table className="contacts-table-view">
      <tbody>
      {contacts.map((contact, index) =>
        <tr key={index}>
          <td><ContactTypeIcon type={contact.type} /> {t("contact.enums.type." + contact.type)}</td>
          <td>{resolveContactValue(contact)}</td>
          <td>{contact.description}</td>
          {showUseForNotifications && (
            <td>
              {contact.useForNotifications &&
              <Tooltip title={t("contact.helpers.useForNotifications")}><NotificationOutlined /></Tooltip>}
            </td>
          )}
        </tr>
      )}
      </tbody>
    </table>
  ) : (
    <span className="sub-header-info dashed">{t("contact.helpers.empty")}</span>
  );
};

export default ContactsView;
