import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { EntityIdRequest, EntityObjectRequest, TwoLevelEntityIdRequest } from "../../../common/types";
import { CreateProduct, CreateProductGroup, Product, ProductGroup, UpdateProductGroupWithProducts } from "./types";

const GET_PRODUCT_GROUPS = ApiRequestAdapter.create("/product-groups", "GET");
const CREATE_PRODUCT_GROUP = ApiRequestAdapter.create("/product-groups", "POST");
const UPDATE_PRODUCT_GROUP = ApiRequestAdapter.create("/product-groups/{0}", "PUT");
const DELETE_PRODUCT_GROUP = ApiRequestAdapter.create("/product-groups/{0}", "DELETE");
const CREATE_PRODUCT = ApiRequestAdapter.create("/product-groups/{0}/products", "POST");
const DELETE_PRODUCT = ApiRequestAdapter.create("/product-groups/{0}/products/{1}", "DELETE");

export const requests = {
  GET_PRODUCT_GROUPS,
  CREATE_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  CREATE_PRODUCT,
  DELETE_PRODUCT
};

export default {
  getProductGroups: (): AxiosPromise<ProductGroup[]> => {
    return apiService.get<ProductGroup[]>(GET_PRODUCT_GROUPS.url);
  },
  createProductGroup: (request: CreateProductGroup): AxiosPromise<ProductGroup> => {
    return apiService.post<ProductGroup>(CREATE_PRODUCT_GROUP.url, request);
  },
  updateProductGroupAndProducts: (request: EntityObjectRequest<UpdateProductGroupWithProducts>): AxiosPromise<ProductGroup> => {
    return apiService.put<ProductGroup>(UPDATE_PRODUCT_GROUP.getParametrizedUrl(request.id), request.object);
  },
  deleteProductGroup: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_PRODUCT_GROUP.getParametrizedUrl(request.id));
  },
  createProduct: (request: EntityObjectRequest<CreateProduct>): AxiosPromise<Product> => {
    return apiService.post<Product>(CREATE_PRODUCT.getParametrizedUrl(request.id), request.object);
  },
  deleteProduct: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_PRODUCT.getParametrizedUrl(request.id1, request.id2));
  }
}
