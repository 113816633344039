import React from "react";
import { Link } from "react-router-dom";
import { EditOutlined, ExportOutlined, EyeOutlined, InteractionOutlined } from "@ant-design/icons";

export interface Props {
  path: string;
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
  type: "edit" | "eye" | "open" | "manage";
  text?: string;
  color: "blue" | "green" | "red" | "orange";
  size?: "small" | "default" | "large" | "huge";
  disabled?: boolean;
  disableDelayEffect?: boolean;
  className?: string;
}

const ActionLinkIcon = (props: Props) => {
  const classNames = ["action-icon"];
  classNames.push(props.disabled ? "disabled" : props.color);
  classNames.push(props.size ? props.size : "default");
  if ( !props.disableDelayEffect ) {
    classNames.push("delay-effect");
  }
  if ( props.className ) {
    classNames.push(props.className);
  }

  let icon;
  switch ( props.type ) {
    case "edit":
      icon = <EditOutlined />;
      break;
    case "eye":
      icon = <EyeOutlined />;
      break;
    case "open":
      icon = <ExportOutlined />;
      break;
    case "manage":
      icon = <InteractionOutlined />;
      break;
  }

  const linkBody = <>{icon}{props.text && <span className="margin-left-tiny">{props.text}</span>}</>;

  return props.disabled
    ? <span className={classNames.join(" ")}>{linkBody}</span>
    : <Link to={props.path} target={props.target} className={classNames.join(" ")}>{linkBody}</Link>
};

export default ActionLinkIcon;
