import React from "react";
import { Col, Divider, Input, InputNumber, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { TravelCalcReinsurancesData, TravelGenAbandonedHouseholdReinsuranceData } from "../../../types";
import {
  AbandonedHouseholdReinsuranceType,
  CancellationInsuranceServiceType,
  PetReinsuranceType,
  VehicleAssistanceReinsuranceType
} from "../../../enums";
import { InstitutionEnum } from "../../../../../../admin/institution/enums";
import validations, { regexPatterns } from "../../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import AddressFormDeprecated from "../../../../../../../common/modules/address/AddressForm_Deprecated";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  form: WrappedFormUtils;
  initialAbandonedHouseholdData: TravelGenAbandonedHouseholdReinsuranceData;
  selectedInstitutionEnum: InstitutionEnum;
  reinsurancesData: TravelCalcReinsurancesData;
}

interface State {
  readonly householdReinsuranceType: AbandonedHouseholdReinsuranceType;
}

class TravelGenReinsurancesSection extends React.Component<Props, State> {
  readonly state: State = {
    householdReinsuranceType: this.props.initialAbandonedHouseholdData ? this.props.initialAbandonedHouseholdData.type : null
  };

  onHouseholdReinsuranceTypeChange = (householdReinsuranceType: AbandonedHouseholdReinsuranceType): void => {
    this.setState({ householdReinsuranceType });
  };

  render(): React.ReactNode {
    const { form, selectedInstitutionEnum, reinsurancesData, initialAbandonedHouseholdData } = this.props;
    const { householdReinsuranceType } = this.state;

    const colSpan = 6;
    const colSpanBig = 8;

    const isUnionSelected = selectedInstitutionEnum === InstitutionEnum.UNION;
    const isGeneraliSelected = selectedInstitutionEnum === InstitutionEnum.GENERALI;

    return (
      <>
        {reinsurancesData.cancellationReinsurance && isUnionSelected && (
          <Row gutter={rowGutter}>
            <Divider>{t("calc.travel.attrs.reinsurancesData.cancellationReinsurance._label")}</Divider>

            <Col span={colSpanBig}>
              <Form.Item label={t("calc.travel.enums.cancellationInsuranceServiceType._label")}>
                {form.getFieldDecorator("reinsurancesData.cancellationReinsurance.serviceType", {
                  rules: [validations.notNull]
                })(
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(CancellationInsuranceServiceType).map(type => ({
                      value: type,
                      label: t("calc.travel.enums.cancellationInsuranceServiceType." + type)
                    }))} />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        {reinsurancesData.abandonedHouseholdReinsurance && (isGeneraliSelected || isUnionSelected) && (
          <>
            <Row gutter={rowGutter}>
              <Divider>{t("calc.travel.attrs.reinsurancesData.abandonedHouseholdReinsuranceData._label")}</Divider>

              <Col span={colSpan}>
                <Form.Item label={t("calc.travel.enums.abandonedHouseholdReinsuranceType._label")}>
                  {form.getFieldDecorator("reinsurancesData.abandonedHouseholdReinsuranceData.type", {
                    rules: [validations.notNull], initialValue: householdReinsuranceType
                  })(
                    <Select
                      {...selectStandardProps}
                      options={Object.keys(AbandonedHouseholdReinsuranceType).map(type => ({
                        value: type,
                        label: t("calc.travel.enums.abandonedHouseholdReinsuranceType." + type)
                      }))}
                      onChange={this.onHouseholdReinsuranceTypeChange} />
                  )}
                </Form.Item>
              </Col>

              {householdReinsuranceType === AbandonedHouseholdReinsuranceType.FLAT && (
                <>
                  <Col span={colSpan}>
                    <Form.Item
                      label={t("calc.travel.attrs.reinsurancesData.abandonedHouseholdReinsuranceData.apartmentNumber")}>
                      {form.getFieldDecorator("reinsurancesData.abandonedHouseholdReinsuranceData.apartmentNumber", {
                        rules: [validations.notBlank, validations.size(1, 64)]
                      })(
                        <Input />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={colSpan}>
                    <Form.Item
                      label={t("calc.travel.attrs.reinsurancesData.abandonedHouseholdReinsuranceData.apartmentFloor")}>
                      {form.getFieldDecorator("reinsurancesData.abandonedHouseholdReinsuranceData.apartmentFloor", {
                        rules: [validations.notNull, validations.minNumber(0), validations.maxNumber(40)]
                      })(
                        <InputNumber min={0} max={40} />
                      )}
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            <AddressFormDeprecated
              form={form}
              initialAddress={initialAbandonedHouseholdData ? initialAbandonedHouseholdData.address : null}
              rootKey="reinsurancesData.abandonedHouseholdReinsuranceData.address"
              label={t("calc.travel.sections.abandonedHouseholdAddress")} />
          </>
        )}

        {reinsurancesData.vehicleAssistanceReinsurance && (isGeneraliSelected || isUnionSelected) && (
          <Row gutter={rowGutter}>
            <Divider>{t("calc.travel.attrs.reinsurancesData.vehicleAssistanceReinsuranceData._label")}</Divider>

            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.attrs.reinsurancesData.vehicleAssistanceReinsuranceData.licensePlate")}>
                {form.getFieldDecorator("reinsurancesData.vehicleAssistanceReinsuranceData.licensePlate", {
                  rules: [validations.notBlank, validations.fullLicensePlate]
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>

            {isUnionSelected && (
              <Col span={colSpan}>
                <Form.Item label={t("calc.travel.enums.vehicleAssistanceReinsuranceType._label")}>
                  {form.getFieldDecorator("reinsurancesData.vehicleAssistanceReinsuranceData.type", {
                    rules: [validations.notNull]
                  })(
                    <Select
                      {...selectStandardProps}
                      options={Object.keys(VehicleAssistanceReinsuranceType).map(type => ({
                        value: type,
                        label: t("calc.travel.enums.vehicleAssistanceReinsuranceType." + type)
                      }))} />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        )}

        {reinsurancesData.petReinsurance && isUnionSelected && (
          <Row gutter={rowGutter}>
            <Divider>{t("calc.travel.attrs.reinsurancesData.petReinsuranceData._label")}</Divider>

            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.enums.petReinsuranceType._label")}>
                {form.getFieldDecorator("reinsurancesData.petReinsuranceData.type", {
                  rules: [validations.notNull]
                })(
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(PetReinsuranceType).map(type => ({
                      value: type,
                      label: t("calc.travel.enums.petReinsuranceType." + type)
                    }))} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={
                <LabelWithTooltip
                  label={t("calc.travel.attrs.reinsurancesData.petReinsuranceData.vaccinationIdentifier")}
                  tooltip={t("calc.travel.helpers.vaccinationIdentifierDesc")} />}>
                {form.getFieldDecorator("reinsurancesData.petReinsuranceData.vaccinationIdentifier", {
                  rules: [validations.notBlank, validations.length(11), validations.pattern(regexPatterns.vaccinationIdentifierRegex)]
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
      </>
    )
  }
}

export default TravelGenReinsurancesSection;
