import { all } from "redux-saga/effects";

import { accountSaga } from "../../modules/account/ducks";
import { adminSaga } from "../../modules/admin/ducks";
import { authSaga } from "../../modules/auth/ducks";
import { calculatorSaga } from "../../modules/calculator/ducks";
import { clientSaga } from "../../modules/client/ducks";
import { contractSaga } from "../../modules/contract/ducks";
import { enumerationsSaga } from "../../modules/enumerations/ducks";
import { eventSaga } from "../../modules/event/ducks";
import { myIdentitySaga } from "../../modules/myidentity/ducks";
import { personSaga } from "../../modules/person/ducks";
import { commissionsSaga } from "../../modules/commissions/ducks";
import { routerSaga } from "../../modules/ducks";

export default function* rootSaga() {
  yield all([
    accountSaga(),
    adminSaga(),
    authSaga(),
    calculatorSaga(),
    clientSaga(),
    contractSaga(),
    enumerationsSaga(),
    eventSaga(),
    myIdentitySaga(),
    personSaga(),
    commissionsSaga(),
    routerSaga()
  ]);
}
