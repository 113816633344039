import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";

// constants moved to separated file from auth/api.ts due to cyclic dependencies what caused errors in imports
const LOGIN = ApiRequestAdapter.create("/auth", "POST");
const REFRESH_TOKEN = ApiRequestAdapter.create("/auth/refresh-token", "POST");

export default {
  LOGIN,
  REFRESH_TOKEN
};
