import { AxiosPromise } from "axios";
import apiService from "../../common/api/apiService";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { MyIdentity, UpdateMyIdentity } from "./types";

const GET_MY_IDENTITY = ApiRequestAdapter.create("/me", "GET");
const UPDATE_MY_IDENTITY = ApiRequestAdapter.create("/me", "PUT");

export const requests = {
  GET_MY_IDENTITY,
  UPDATE_MY_IDENTITY
};

export default {
  getMyIdentity: (): AxiosPromise<MyIdentity> => {
    return apiService.get<MyIdentity>(GET_MY_IDENTITY.url);
  },
  updateMyIdentity: (request: UpdateMyIdentity): AxiosPromise<MyIdentity> => {
    return apiService.put<MyIdentity>(UPDATE_MY_IDENTITY.url, request);
  }
}
