import React from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SearchPageResult, UUID } from "../../../../../common/types";
import { ClientList } from "../../../types";
import { paginationStandardProps } from "../../../../../common/utils/formUtils";
import t from "../../../../../app/i18n";

import AddressView from "../../../../../common/modules/address/AddressView";
import ClientTypeTag from "../../ClientTypeTag";

export interface Props {
  clientsPage: SearchPageResult<ClientList>;
  onRowDoubleClick(clientId: UUID): void;
  onPageChange(pageNumber: number): void;
}

const ClientListTableView = (props: Props) => {

  const columns: ColumnsType<ClientList> = [
    {
      key: "aggregatedName",
      title: t("client.attrs.aggregatedName"),
      render: (text, record) => <Link to={`/clients/${record.id}`}>{record.aggregatedName}</Link>
    },
    {
      key: "type",
      title: t("client.enums.type._label"),
      render: (text, record) => <ClientTypeTag type={record.type} />
    },
    {
      key: "personalIdentificationNumber",
      title: t("client.attrs.personalIdentificationNumber"),
      dataIndex: "personalIdentificationNumber"
    },
    {
      key: "companyRegistrationNumber",
      title: t("client.attrs.companyRegistrationNumber"),
      dataIndex: "companyRegistrationNumber"
    },
    {
      key: "address",
      title: t("client.attrs.address"),
      render: (text, record) => <AddressView address={record.address} />
    }
  ];

  return (
    <Table<ClientList>
      size="small"
      className="table-row-stripped"
      rowKey="id"
      columns={columns}
      dataSource={props.clientsPage.pageData}
      pagination={{
        ...paginationStandardProps,
        current: props.clientsPage.pageIndex + 1,
        pageSize: props.clientsPage.pageSize,
        total: props.clientsPage.totalElementsCount,
        onChange: props.onPageChange
      }}
      onRow={record => ({
        onDoubleClick: () => props.onRowDoubleClick(record.id)
      })}
    />
  )
};

export default ClientListTableView;
