import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { RouteComponentProps } from "react-router";
import { Location } from "history";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { VehicleBrandMappingFilterPageResult, VehicleMappingData, VehicleModelMappingFilterPageResult } from "../types";
import { ActionProps, RootState, UUID } from "../../../../common/types";
import { ValidationErrorResponse } from "../../../types";
import { VehicleMappingType } from "../enums";
import {
  createVehicleBrandMappingActions,
  createVehicleModelMappingActions,
  deleteStateVehicleMappingsDataAction,
  filterVehicleBrandMappingsActions,
  filterVehicleModelMappingsActions,
  getVehicleMappingsActions,
  mapVehicleMappingsUpdatesActions,
  remapVehicleMappingsActions,
  selectCalcVehicleBrandsCurrentPage,
  selectCalcVehicleMappings,
  selectCalcVehicleModelsCurrentPage,
  updateVehicleBrandMappingsActions,
  updateVehicleModelMappingsActions
} from "../ducks";
import {
  deleteStateValidationErrorResponseAction,
  selectIsRequestInProgress,
  selectRouterLocation,
  selectValidationErrorResponse
} from "../../../ducks";
import { getEnumerationsActions } from "../../../enumerations/ducks";
import { requests } from "../api";
import { contains } from "../../../../common/utils/utils";
import messageUtils from "../../../../common/utils/messageUtils";
import t from "../../../../app/i18n";

import VehicleSettingsHeader from "../components/VehicleSettingsHeader";
import VehicleBrandMappingsListForm from "../components/brands/VehicleBrandMappingsListForm";
import VehicleModelMappingsListForm from "../components/models/VehicleModelMappingsListForm";

interface StateProps {
  vehicleMappingsData: VehicleMappingData[];
  brandsCurrentPage: VehicleBrandMappingFilterPageResult;
  modelsCurrentPage: VehicleModelMappingFilterPageResult;
  createVehicleBrandMappingErrorResponse: ValidationErrorResponse;
  createVehicleBrandMappingRequestInProgress: boolean;
  updateVehicleBrandMappingsErrorResponse: ValidationErrorResponse;
  createVehicleModelMappingErrorResponse: ValidationErrorResponse;
  createVehicleModelMappingRequestInProgress: boolean;
  updateVehicleModelMappingErrorResponse: ValidationErrorResponse;
  location: Location;
}

interface ActionsMap {
  getVehicleMappings: typeof getVehicleMappingsActions.request;
  remapVehicleMappings: typeof remapVehicleMappingsActions.request;
  mapVehicleMappingsUpdates: typeof mapVehicleMappingsUpdatesActions.request;
  filterVehicleBrandMappings: typeof filterVehicleBrandMappingsActions.request;
  createVehicleBrandMapping: typeof createVehicleBrandMappingActions.request;
  updateVehicleBrandMappings: typeof updateVehicleBrandMappingsActions.request;
  filterVehicleModelMappings: typeof filterVehicleModelMappingsActions.request;
  createVehicleModelMapping: typeof createVehicleModelMappingActions.request;
  updateVehicleModelMappings: typeof updateVehicleModelMappingsActions.request;
  getEnumerations: typeof getEnumerationsActions.request;
  deleteStateVehicleMappingsData: typeof deleteStateVehicleMappingsDataAction;
  deleteStateValidationErrorResponse: typeof deleteStateValidationErrorResponseAction;
  pushNavigation: typeof push;
}

interface State {
  readonly selectedMappingTypes: VehicleMappingType[];
  readonly selectedBrandId: UUID;
}

class VehicleSettingsContainer extends React.Component<StateProps & ActionProps<ActionsMap> & RouteComponentProps, State> {
  readonly state: State = {
    selectedMappingTypes: Object.values(VehicleMappingType),
    selectedBrandId: this.props.match.params["id"]
  };

  handleSelectedMappingTypesChange = (checkedValues: CheckboxValueType[]): void => {
    this.setState({ selectedMappingTypes: checkedValues as VehicleMappingType[] });

    if ( this.props.vehicleMappingsData.length > 0 ) {
      const fetchedMappings = this.props.vehicleMappingsData.flatMap(mapping => mapping.type);
      const missingMappings = (checkedValues as VehicleMappingType[]).filter(mapping => !contains(fetchedMappings, mapping));
      if ( missingMappings.length > 0 ) {
        this.props.actions.getVehicleMappings({ queriedMappings: missingMappings });
      }
    }
  };

  handleDownloadMappingsClick = (): void => {
    if ( this.checkIfAtLeastMappingTypeIsSelected() ) {
      this.props.actions.getVehicleMappings({ queriedMappings: this.state.selectedMappingTypes });
    }
  };

  handleRemapMappingsClick = (): void => {
    if ( this.checkIfAtLeastMappingTypeIsSelected() ) {
      this.props.actions.remapVehicleMappings({ queriedMappings: this.state.selectedMappingTypes });
    }
  };

  handleMapMappingsUpdatesClick = (): void => {
    if ( this.checkIfAtLeastMappingTypeIsSelected() ) {
      this.props.actions.mapVehicleMappingsUpdates({ queriedMappings: this.state.selectedMappingTypes });
    }
  };

  handleShowModelsClick = (brandMappingId: UUID): void => {
    this.setState({ selectedBrandId: brandMappingId });
    this.props.actions.pushNavigation(`/calc/settings/vehicle-brands/${brandMappingId}/vehicle-models`);
  };

  handleReturnToBrandsClick = (): void => {
    this.setState({ selectedBrandId: null });
    this.props.actions.pushNavigation(`/calc/settings/vehicle-brands`);
  };

  checkIfAtLeastMappingTypeIsSelected = (): boolean => {
    if ( this.state.selectedMappingTypes.length === 0 ) {
      messageUtils.warnMessage(t("calc.vehicles.validations.noMappingTypeSelected"));
      return false;
    }
    return true;
  };

  componentWillUnmount(): void {
    this.props.actions.deleteStateVehicleMappingsData();
    this.props.actions.getEnumerations();
  }

  render(): React.ReactNode {
    const { selectedMappingTypes, selectedBrandId } = this.state;
    const { vehicleMappingsData } = this.props;

    const vehicleMappingsInitialized = vehicleMappingsData && vehicleMappingsData.length > 0;

    return (
      <>
        <h2>{t("calc.vehicles.titles.pageTitle")}</h2>

        <VehicleSettingsHeader
          selectedMappingTypes={selectedMappingTypes}
          showDownloadButton={!vehicleMappingsInitialized}
          onSelectedMappingTypesChange={this.handleSelectedMappingTypesChange}
          onDownloadMappingsClick={this.handleDownloadMappingsClick}
          onRemapMappingsClick={this.handleRemapMappingsClick}
          onMapMappingsUpdatesClick={this.handleMapMappingsUpdatesClick} />

        {vehicleMappingsInitialized ? (
          selectedBrandId ? (
            <VehicleModelMappingsListForm
              selectedBrandId={selectedBrandId}
              selectedMappingTypes={selectedMappingTypes}
              mappingData={vehicleMappingsData}
              currentPage={this.props.modelsCurrentPage}
              createMappingErrorResponse={this.props.createVehicleModelMappingErrorResponse}
              createMappingRequestInProgress={this.props.createVehicleModelMappingRequestInProgress}
              updateMappingsErrorResponse={this.props.updateVehicleModelMappingErrorResponse}
              location={this.props.location}
              onFilterMappings={this.props.actions.filterVehicleModelMappings}
              onCreateMapping={this.props.actions.createVehicleModelMapping}
              onUpdateMappings={this.props.actions.updateVehicleModelMappings}
              onErrorResponseDelete={this.props.actions.deleteStateValidationErrorResponse}
              onReturnToBrandsClick={this.handleReturnToBrandsClick} />
          ) : (
            <VehicleBrandMappingsListForm
              selectedMappingTypes={selectedMappingTypes}
              mappingData={vehicleMappingsData}
              currentPage={this.props.brandsCurrentPage}
              createMappingErrorResponse={this.props.createVehicleBrandMappingErrorResponse}
              createMappingRequestInProgress={this.props.createVehicleBrandMappingRequestInProgress}
              updateMappingsErrorResponse={this.props.updateVehicleBrandMappingsErrorResponse}
              locationKey={this.props.location.key}
              onFilterMappings={this.props.actions.filterVehicleBrandMappings}
              onCreateMapping={this.props.actions.createVehicleBrandMapping}
              onUpdateMappings={this.props.actions.updateVehicleBrandMappings}
              onErrorResponseDelete={this.props.actions.deleteStateValidationErrorResponse}
              onShowModelsClick={this.handleShowModelsClick} />
          )
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  vehicleMappingsData: selectCalcVehicleMappings(state),
  brandsCurrentPage: selectCalcVehicleBrandsCurrentPage(state),
  modelsCurrentPage: selectCalcVehicleModelsCurrentPage(state),
  createVehicleBrandMappingErrorResponse: selectValidationErrorResponse(state, requests.CREATE_VEHICLE_BRAND_MAPPING),
  createVehicleBrandMappingRequestInProgress: selectIsRequestInProgress(state, requests.CREATE_VEHICLE_BRAND_MAPPING),
  updateVehicleBrandMappingsErrorResponse: selectValidationErrorResponse(state, requests.UPDATE_VEHICLE_BRAND_MAPPINGS),
  createVehicleModelMappingErrorResponse: selectValidationErrorResponse(state, requests.CREATE_VEHICLE_MODEL_MAPPING),
  createVehicleModelMappingRequestInProgress: selectIsRequestInProgress(state, requests.CREATE_VEHICLE_MODEL_MAPPING),
  updateVehicleModelMappingErrorResponse: selectValidationErrorResponse(state, requests.UPDATE_VEHICLE_MODEL_MAPPINGS),
  location: selectRouterLocation(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getVehicleMappings: getVehicleMappingsActions.request,
    remapVehicleMappings: remapVehicleMappingsActions.request,
    mapVehicleMappingsUpdates: mapVehicleMappingsUpdatesActions.request,
    filterVehicleBrandMappings: filterVehicleBrandMappingsActions.request,
    createVehicleBrandMapping: createVehicleBrandMappingActions.request,
    updateVehicleBrandMappings: updateVehicleBrandMappingsActions.request,
    filterVehicleModelMappings: filterVehicleModelMappingsActions.request,
    createVehicleModelMapping: createVehicleModelMappingActions.request,
    updateVehicleModelMappings: updateVehicleModelMappingsActions.request,
    getEnumerations: getEnumerationsActions.request,
    deleteStateVehicleMappingsData: deleteStateVehicleMappingsDataAction,
    deleteStateValidationErrorResponse: deleteStateValidationErrorResponseAction,
    pushNavigation: push
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(VehicleSettingsContainer);
