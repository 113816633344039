import React from "react";
import { Route, Switch } from "react-router";
import RouteWithPermission from "../../../common/security/authorization/RouteWithPermission";
import { Permission } from "../../../common/security/authorization/enums";
import { regexPatterns } from "../../../common/utils/validationUtils";

import CommissionsBatchListContainer from "./containers/CommissionsBatchListContainer";
import CommissionsBatchDetailContainer from "./containers/CommissionsBatchDetailContainer";
import CommissionsBatchAttachmentImportsContainer from "./containers/CommissionsBatchAttachmentImportsContainer";
import CommissionsBatchManualImportsContainer from "./containers/CommissionsBatchManualImportsContainer";
import NotFound from "../../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission exact path="/commissions/batches"
                         component={CommissionsBatchListContainer}
                         permissions={[Permission.COMMISSIONS]} />

    <RouteWithPermission exact path={`/commissions/batches/:id(${regexPatterns.uuidRegex.source})`}
                         component={CommissionsBatchDetailContainer}
                         permissions={[Permission.COMMISSIONS_MANAGE]} />

    <RouteWithPermission exact
                         path={`/commissions/batches/:id1(${regexPatterns.uuidRegex.source})/attachments/:id2(${regexPatterns.uuidRegex.source})/imports`}
                         component={CommissionsBatchAttachmentImportsContainer}
                         permissions={[Permission.COMMISSIONS_MANAGE]} />

    <RouteWithPermission exact
                         path={`/commissions/batches/:id1(${regexPatterns.uuidRegex.source})/institutions/:id2(${regexPatterns.uuidRegex.source})/manual-imports`}
                         component={CommissionsBatchManualImportsContainer}
                         permissions={[Permission.COMMISSIONS_MANAGE]} />

    <Route component={NotFound} />
  </Switch>
);
