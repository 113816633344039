import React, { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Pathname } from "history";
import { Spin } from "antd";
import { ActionProps, RootState } from "../../types";
import { selectIsRequestInProgress, selectRouterLocationPathname } from "../../../modules/ducks";
import { downloadContractAttachmentActions } from "../../../modules/contract/ducks";
import { downloadCalcSettingsAttachmentConfActions } from "../../../modules/calculator/settings/ducks";
import { downloadCalcDraftAttachmentActions } from "../../../modules/calculator/drafts/ducks";
import { downloadCommissionsBatchAttachmentActions } from "../../../modules/commissions/batch/ducks";
import { selectIsUserAuthenticated } from "../../../modules/auth/ducks";
import { requests as contractRequests } from "../../../modules/contract/api";
import { requests as calcSettingsRequests } from "../../../modules/calculator/settings/api";
import { requests as calcDraftRequests } from "../../../modules/calculator/drafts/api";
import { requests as commissionsBatchRequests } from "../../../modules/commissions/batch/api";
import { ATTACHMENT_VIEWER_ID } from "../../utils/utils";
import { regexPatterns } from "../../utils/validationUtils";

export interface Props {
  children: any;
}

interface StateProps {
  userAuthenticated: boolean;
  locationPathname: Pathname;
  downloadAttachmentInProgress: boolean;
}

interface ActionsMap {
  downloadContractAttachment: typeof downloadContractAttachmentActions.request;
  downloadCalcSettingsAttachmentConf: typeof downloadCalcSettingsAttachmentConfActions.request;
  downloadCalcDraftAttachment: typeof downloadCalcDraftAttachmentActions.request;
  downloadCommissionsBatchAttachment: typeof downloadCommissionsBatchAttachmentActions.request;
}

type ComponentProps = Props & StateProps & ActionProps<ActionsMap>;

const contractAttachmentRegex = new RegExp(`^/contracts/${regexPatterns.uuidRegex.source}/attachments/${regexPatterns.uuidRegex.source}$`);
const calcSettingsAttachmentConfRegex = new RegExp(`^/calc/settings/(mtpl|crash|gap|pas|travel|realty)/${regexPatterns.uuidRegex.source}/attachment-confs/${regexPatterns.uuidRegex.source}$`);
const calcDraftAttachmentRegex = new RegExp(`^/calc/(vehicle|travel|realty)/drafts/${regexPatterns.uuidRegex.source}/attachments/${regexPatterns.uuidRegex.source}$`);
const commissionsBatchAttachmentRegex = new RegExp(`^/commissions/batches/${regexPatterns.uuidRegex.source}/attachments/${regexPatterns.uuidRegex.source}$`);

const AttachmentBoundary = (props: ComponentProps) => {
  const [shouldShowAttachment, setShouldShowAttachment] = useState<boolean>(false);

  useEffect(() => {
    if ( props.userAuthenticated ) {
      if ( contractAttachmentRegex.test(props.locationPathname) ) {
        const ids = props.locationPathname.match(new RegExp(regexPatterns.uuidRegex, "g"));
        props.actions.downloadContractAttachment({ id1: ids[0], id2: ids[1] });
        setShouldShowAttachment(true);
      }
      else if ( calcSettingsAttachmentConfRegex.test(props.locationPathname) ) {
        const ids = props.locationPathname.match(new RegExp(regexPatterns.uuidRegex, "g"));
        props.actions.downloadCalcSettingsAttachmentConf({ id1: ids[0], id2: ids[1] });
        setShouldShowAttachment(true);
      }
      else if ( calcDraftAttachmentRegex.test(props.locationPathname) ) {
        const ids = props.locationPathname.match(new RegExp(regexPatterns.uuidRegex, "g"));
        props.actions.downloadCalcDraftAttachment({ id1: ids[0], id2: ids[1] });
        setShouldShowAttachment(true);
      }
      else if ( commissionsBatchAttachmentRegex.test(props.locationPathname) ) {
        const ids = props.locationPathname.match(new RegExp(regexPatterns.uuidRegex, "g"));
        props.actions.downloadCommissionsBatchAttachment({ id1: ids[0], id2: ids[1] });
        setShouldShowAttachment(true);
      }
      else {
        setShouldShowAttachment(false);
      }
    }
    else {
      setShouldShowAttachment(false);
    }
  }, [props.userAuthenticated, props.locationPathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return shouldShowAttachment ?
    <Spin spinning={props.downloadAttachmentInProgress} size="large">
      <iframe
        id={ATTACHMENT_VIEWER_ID}
        title={ATTACHMENT_VIEWER_ID}
        style={{
          border: 0,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%"
        }}
        allowFullScreen />
    </Spin> : props.children;
};

const mapStateToProps = (state: RootState): StateProps => ({
  userAuthenticated: selectIsUserAuthenticated(state),
  locationPathname: selectRouterLocationPathname(state),
  downloadAttachmentInProgress:
    selectIsRequestInProgress(state, contractRequests.DOWNLOAD_CONTRACT_ATTACHMENT)
    || selectIsRequestInProgress(state, calcSettingsRequests.DOWNLOAD_ATTACHMENT_CONF)
    || selectIsRequestInProgress(state, calcDraftRequests.DOWNLOAD_CALC_DRAFT_ATTACHMENT)
    || selectIsRequestInProgress(state, commissionsBatchRequests.DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    downloadContractAttachment: downloadContractAttachmentActions.request,
    downloadCalcSettingsAttachmentConf: downloadCalcSettingsAttachmentConfActions.request,
    downloadCalcDraftAttachment: downloadCalcDraftAttachmentActions.request,
    downloadCommissionsBatchAttachment: downloadCommissionsBatchAttachmentActions.request
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, Props, RootState>(mapStateToProps, mapDispatchToProps)(AttachmentBoundary);
