import React from "react";
import { Route, Switch } from "react-router";
import { Permission } from "../../../common/security/authorization/enums";
import RouteWithPermission from "../../../common/security/authorization/RouteWithPermission";

import JobsContainer from "./containers/JobsContainer";
import NotFound from "../../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission exact path="/admin/jobs"
                         component={JobsContainer}
                         permissions={[Permission.ADMIN_JOBS]} />

    <Route component={NotFound} />
  </Switch>
);