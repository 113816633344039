import React, { useEffect, useState } from "react";
import moment from "moment";
import groupBy from "lodash/groupBy";
import { Card, Cascader, Checkbox, Col, DatePicker, Divider, Input, Row, Select } from "antd";
import { CascaderOptionType } from "antd/lib/cascader";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import {
  CrashCalcResultData,
  GapCalcResultData,
  MtplCalcResultData,
  VehicleCalc,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleGenForm,
  VehicleGenFormGeneralData
} from "../../../types";
import { CalcResult } from "../../../../types";
import { GapDuration, RecommendationReason, WustenrotContractsCount } from "../../../enums";
import { CalcType } from "../../../../../enums";
import { ClientExperience, InsuranceContractPaymentFrequency, PaymentMethod } from "../../../../../../contract/enums";
import { ClientType } from "../../../../../../client/enums";
import { InstitutionEnum } from "../../../../../../admin/institution/enums";
import { findGapResultPremium } from "../../../utils";
import { filterApplicableSuccessResults, isApplicableSuccessResult } from "../../../../utils";
import validations, { regexPatterns } from "../../../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerFuture,
  disableDatePickerOutOfInterval,
  selectStandardProps,
  toMoment
} from "../../../../../../../common/utils/formUtils";
import { tInterval } from "../../../../../../../common/utils/translationUtils";
import { formatCoverageLimits, formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  form: WrappedFormUtils;
  initialGeneralData: VehicleGenFormGeneralData;
  insurerType: ClientType;
  calcData: VehicleCalc;
  selectedResult: CalcResult<VehicleCalcResultData>;
  calcResults: VehicleCalcResults;
  recommendedResultConfirmationVisible: boolean;
  onRecommendedResultChange(): void;
}

const VehicleGenOtherDataSection = (props: Props) => {

  const [recommendationReason, setRecommendationReason] = useState<RecommendationReason>();
  const [gapPremium, setGapPremium] = useState<number>();

  useEffect(() => {
    setRecommendationReason(null);
    setGapPremium(props.initialGeneralData && props.selectedResult.calcType === CalcType.GAP
      ? findGapResultPremium((props.selectedResult.data as GapCalcResultData).options,
        props.initialGeneralData.gapComplicityReinsurance, props.initialGeneralData.gapDuration,
        props.initialGeneralData.gapPaymentFrequency)
      : null)
  }, [props.initialGeneralData, props.selectedResult]);

  const handleGapComplicityReinsuranceChange = (event: CheckboxChangeEvent): void => {
    const { generalData } = props.form.getFieldsValue(["generalData"]) as VehicleGenForm;
    setGapPremium(findGapResultPremium((props.selectedResult.data as GapCalcResultData).options, event.target.checked,
      generalData.gapDuration, generalData.gapPaymentFrequency));
  }

  const handleGapDurationChange = (duration: GapDuration): void => {
    const { generalData } = props.form.getFieldsValue(["generalData"]) as VehicleGenForm;
    setGapPremium(findGapResultPremium((props.selectedResult.data as GapCalcResultData).options,
      generalData.gapComplicityReinsurance, duration, generalData.gapPaymentFrequency));
  }

  const handleGapPaymentFrequency = (frequency: InsuranceContractPaymentFrequency): void => {
    const { generalData } = props.form.getFieldsValue(["generalData"]) as VehicleGenForm;
    setGapPremium(findGapResultPremium((props.selectedResult.data as GapCalcResultData).options,
      generalData.gapComplicityReinsurance, generalData.gapDuration, frequency));
  }

  const resolveRecommendedResultOptions = (allResults: CalcResult<VehicleCalcResultData>[][]): CascaderOptionType[] => {
    return allResults.filter(resultsRow => filterApplicableSuccessResults(resultsRow).length > 0)
      .map<CascaderOptionType>(resultsRow => {
        if ( calcType === CalcType.MTPL ) {
          const results = filterApplicableSuccessResults(resultsRow as CalcResult<MtplCalcResultData>[]);
          return {
            label: results[0].coverage
              ? results[0].insuranceInstitution.name
              : results[0].insuranceInstitution.name
              + ` (${formatCoverageLimits(results[0].data.healthCoverageLimit, results[0].data.propertyCoverageLimit)})`,
            value: results[0].insuranceInstitution.institutionEnum,
            children: results[0].coverage
              ? results.map<CascaderOptionType>(result => ({
                label: `${result.coverage} (${formatCoverageLimits(result.data.healthCoverageLimit, result.data.propertyCoverageLimit)})`,
                value: result.coverage
              }))
              : undefined
          };
        }
        else {
          let results = filterApplicableSuccessResults(resultsRow as CalcResult<CrashCalcResultData>[]);
          if ( results[0].insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ ) {
            const resultsGroups = groupBy(results, result => result.data.complicity);
            results = Object.keys(resultsGroups).map(key => resultsGroups[key][0])
          }
          return {
            label: results[0].insuranceInstitution.name,
            value: results[0].insuranceInstitution.institutionEnum,
            children: results.map<CascaderOptionType>(result => ({
              label: `${t("calc.vehicle.results.complicity")} ${result.data.complicity}`,
              value: result.coverage
            }))
          }
        }
      })
  }

  const { getFieldDecorator } = props.form;
  const { clientsData } = props.calcData;
  const { crossSelling } = props.calcData.generalData;
  const { institutionEnum } = props.selectedResult.insuranceInstitution;
  const { calcType, coverage } = props.selectedResult;

  const onlyBankTransferAllowed = institutionEnum === InstitutionEnum.ALLIANZ
    || institutionEnum === InstitutionEnum.CSOB || institutionEnum === InstitutionEnum.WUSTENROT;
  const insurerIsCompany = props.insurerType === ClientType.SELF_EMPLOYED || props.insurerType === ClientType.LEGAL;
  const mtplDateFrom = toMoment(props.calcData.generalData.effectiveBeginningDate);
  const mtplDateTo = toMoment(props.calcData.generalData.effectiveBeginningDate).add(1, "years").subtract(1, "days");

  const colSpan = 4;

  return (
    <Card size="small" type="inner" className="card-box" title={t("calc.vehicle.sections.otherData")}>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("contract.enums.paymentMethod._label")}>
            {getFieldDecorator("generalData.paymentMethod", {
              rules: [validations.notNull],
              initialValue: onlyBankTransferAllowed ? PaymentMethod.BANK_TRANSFER : undefined
            })(
              <Select
                {...selectStandardProps}
                disabled={onlyBankTransferAllowed}
                options={Object.keys(PaymentMethod).map(method => ({
                  value: method,
                  label: t("contract.enums.paymentMethod." + method)
                }))} />
            )}
          </Form.Item>
        </Col>

        {institutionEnum === InstitutionEnum.ALLIANZ
        || institutionEnum === InstitutionEnum.AXA_NON_LIFE
        || institutionEnum === InstitutionEnum.GENERALI
        || institutionEnum === InstitutionEnum.KOMUNALNA
        || institutionEnum === InstitutionEnum.KOOPERATIVA ? (
          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.generalData.signCity")}>
              {getFieldDecorator("generalData.signCity", {
                rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
        ) : null}

        {institutionEnum === InstitutionEnum.ALLIANZ && calcType === CalcType.CRASH && (
          <>
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.attrs.generalData.mtplInsuranceEffectiveBeginningDate")}>
                {getFieldDecorator("generalData.mtplInsuranceEffectiveBeginningDate", {
                  rules: [validations.notNull, validations.dateInInterval(mtplDateFrom, mtplDateTo)]
                })(
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current => disableDatePickerOutOfInterval(current, mtplDateFrom, mtplDateTo)} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan * 2}>
              <Form.Item label={t("calc.vehicle.attrs.additionalData.allianzMtplCoverage")}>
                {getFieldDecorator("additionalData.allianzMtplCoverage", {
                  rules: [validations.notNull]
                })(
                  <Select
                    {...selectStandardProps}
                    options={(props.calcResults.crash.find(resultsRow =>
                      resultsRow.length > 0 && resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ) || [])
                      .filter(result => isApplicableSuccessResult(result))
                      .filter(result => result.coverage.startsWith(coverage))
                      .map(result => ({
                        value: result.coverage,
                        label: `${formatCoverageLimits(result.data.healthCoverageLimit, result.data.propertyCoverageLimit)} | `
                          + t("calc.vehicle.helpers.annualPremiumSummary", {
                            annualPremium: formatLocaleCurrency(result.data.mtplInsuranceAnnualPremium),
                            totalAnnualPremium: formatLocaleCurrency(result.data.mtplInsuranceAnnualPremium + result.data.annualPremium)
                          })
                      }))} />
                )}
              </Form.Item>
            </Col>
          </>
        )}

        {institutionEnum === InstitutionEnum.GROUPAMA && (
          <>
            {crossSelling.groupamaContract && (
              <Col span={colSpan + 1}>
                <Form.Item label={t("calc.vehicle.attrs.generalData.crossSelling.groupamaContractNumber")}>
                  {getFieldDecorator("generalData.crossSelling.groupamaContractNumber", {
                    rules: [validations.notNull, validations.size(4, 15)]
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            )}

            {clientsData.holderChildrenUnder15Years && (
              <>
                <Col span={colSpan}>
                  <Form.Item label={t("calc.vehicle.attrs.clientsData.holderChildrenUnder15YearsName")}>
                    {getFieldDecorator("clientsData.holderChildrenUnder15YearsName", {
                      rules: [validations.notBlank, validations.size(5, 64)]
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item label={t("calc.vehicle.attrs.clientsData.holderChildrenUnder15YearsBirthDate")}>
                    {getFieldDecorator("clientsData.holderChildrenUnder15YearsBirthDate", {
                      rules: [
                        validations.notNull, validations.notFuture,
                        validations.dateInInterval(moment().subtract(15, "year").startOf("year"), moment())
                      ]
                    })(
                      <DatePicker
                        {...datePickerStandardProps}
                        disabledDate={disableDatePickerFuture} />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={colSpan + 1}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("generalData.otherMtplInsurance", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox>{t("calc.vehicle.attrs.generalData.otherMtplInsurance")}</Checkbox>
                )}
              </Form.Item>
            </Col>
          </>
        )}

        {institutionEnum === InstitutionEnum.UNION && crossSelling.unionHealthContract && (
          <Col span={colSpan + 1}>
            <Form.Item
              label={<LabelWithTooltip
                label={t("calc.vehicle.attrs.generalData.crossSelling.unionHealthContractNumber")}
                tooltip={t("calc.vehicle.helpers.unionHealthContractNumberDesc")} />}>
              {getFieldDecorator("generalData.crossSelling.unionHealthContractNumber", {
                rules: [validations.notNull, validations.size(4, 15)]
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
        )}

        {institutionEnum === InstitutionEnum.WUSTENROT && crossSelling.wustenrotContractsCount && (
          <>
            {[...Array(Object.values(WustenrotContractsCount).indexOf(crossSelling.wustenrotContractsCount) + 1)]
              .map((value, index) => (
                <Col span={colSpan} key={index}>
                  <Form.Item
                    label={tInterval("calc.vehicle.attrs.generalData.crossSelling.wustenrotContractNumbers_interval", index + 1)}>
                    {getFieldDecorator(`generalData.crossSelling.wustenrotContractNumbers[${index}]`, {
                      rules: [validations.notNull, validations.size(4, 15)]
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              ))}
          </>
        )}
      </Row>

      {institutionEnum === InstitutionEnum.COLONNADE && calcType === CalcType.GAP && (
        <>
          <Divider className="divider-subheader">{t("calc.vehicle.sections.gapData")}</Divider>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("generalData.gapComplicityReinsurance", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox onChange={handleGapComplicityReinsuranceChange}>
                    {t("calc.vehicle.attrs.generalData.gapComplicityReinsurance")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.enums.gapDuration._label")}>
                {getFieldDecorator("generalData.gapDuration", { rules: [validations.notNull] })(
                  <Select
                    {...selectStandardProps}
                    options={[GapDuration.MONTHS_36, GapDuration.MONTHS_48, GapDuration.MONTHS_60].map(duration => ({
                      value: duration,
                      label: t("calc.vehicle.enums.gapDuration." + duration)
                    }))}
                    onChange={handleGapDurationChange} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.helpers.gapPaymentFrequencyLabel")}>
                {getFieldDecorator("generalData.gapPaymentFrequency", { rules: [validations.notNull] })(
                  <Select
                    {...selectStandardProps}
                    options={[InsuranceContractPaymentFrequency.ONCE, InsuranceContractPaymentFrequency.ANNUALLY].map(frequency => ({
                      value: frequency,
                      label: t("contract.enums.paymentFrequency." + frequency)
                    }))}
                    onChange={handleGapPaymentFrequency} />
                )}
              </Form.Item>
            </Col>

            {gapPremium && (
              <Col span={colSpan} className="form-item-without-label">
                {t("calc.vehicle.results.annualPremium")}: <b>{formatLocaleCurrency(gapPremium)}</b>
              </Col>
            )}
          </Row>
        </>
      )}

      <Divider className="divider-subheader">{t("calc.vehicle.sections.informationObligationData")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("contract.enums.clientExperience._label")}>
            {getFieldDecorator("informationObligationData.clientExperience", {
              rules: [validations.notNull],
              initialValue: insurerIsCompany ? ClientExperience.SUFFICIENT : undefined
            })(
              <Select
                {...selectStandardProps}
                disabled={insurerIsCompany}
                options={Object.keys(ClientExperience).map(experience => ({
                  value: experience,
                  label: t("contract.enums.clientExperience." + experience)
                }))} />
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan + 2}>
          <Form.Item label={t("calc.vehicle.attrs.informationObligationData.otherRequirements")}>
            {getFieldDecorator("informationObligationData.otherRequirements", { rules: [validations.none] })(
              <Input />
            )}
          </Form.Item>
        </Col>

        {calcType !== CalcType.CRASH && (
          <Col span={colSpan + 2}>
            <Form.Item label={t("calc.vehicle.attrs.informationObligationData.otherRecommendationReasons")}>
              {getFieldDecorator("informationObligationData.otherRecommendationReasons", { rules: [validations.none] })(
                <Input />
              )}
            </Form.Item>
          </Col>
        )}

        <Col span={colSpan * 2}>
          <Form.Item label={t("calc.vehicle.attrs.informationObligationData.recommendedInsuranceRejectionReason")}>
            {getFieldDecorator("informationObligationData.recommendedInsuranceRejectionReason", { rules: [validations.none] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>

      {(calcType === CalcType.MTPL || calcType === CalcType.CRASH) && (
        <Row gutter={rowGutter}>
          <Col span={colSpan * 2}>
            <Form.Item label={t("calc.vehicle.attrs.informationObligationData.recommendedResult")}>
              {getFieldDecorator("informationObligationData.recommendedResult", { rules: [validations.notNull] })(
                <Cascader
                  allowClear={false}
                  expandTrigger="hover"
                  onChange={props.onRecommendedResultChange}
                  options={resolveRecommendedResultOptions(
                    calcType === CalcType.MTPL ? props.calcResults.mtpl : props.calcResults.crash)} />
              )}
            </Form.Item>
          </Col>

          {calcType === CalcType.MTPL && props.recommendedResultConfirmationVisible && (
            <Col span={colSpan * 3}>
              <Form.Item className="form-item-without-label">
                {getFieldDecorator("informationObligationData.recommendedResultConfirmation", {
                  rules: [validations.none], valuePropName: "checked", initialValue: false
                })(
                  <Checkbox>
                    {t("calc.vehicle.attrs.informationObligationData.recommendedResultConfirmation")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          )}

          {calcType === CalcType.CRASH && (
            <>
              <Col span={colSpan + 2}>
                <Form.Item label={t("calc.vehicle.enums.recommendationReason._label")}>
                  {getFieldDecorator("informationObligationData.recommendationReason", {
                    rules: [validations.notNull], initialValue: recommendationReason
                  })(
                    <Select<RecommendationReason>
                      {...selectStandardProps}
                      options={Object.keys(RecommendationReason).map(reason => ({
                        value: reason,
                        label: t("calc.vehicle.enums.recommendationReason." + reason)
                      }))}
                      onChange={setRecommendationReason} />
                  )}
                </Form.Item>
              </Col>

              {recommendationReason === RecommendationReason.OTHER && (
                <Col span={colSpan + 2}>
                  <Form.Item label={t("calc.vehicle.attrs.informationObligationData.otherRecommendationReasons")}>
                    {getFieldDecorator("informationObligationData.otherRecommendationReasons", { rules: [validations.notBlank] })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Row>
      )}
    </Card>
  )
};

export default VehicleGenOtherDataSection;
