import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { FormItemProps } from "antd/lib/form";
import { VehicleBrandWithModels } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { selectVehicleBrandsEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  brandId: UUID;
}

const VehicleModelEnumFormItemSelect = ({ formItemProps, selectProps, brandId }: Props) => {

  const vehicleBrandsEnumerations = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnumerations);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={(vehicleBrandsEnumerations
          .find(brand => brand.id === brandId)?.models || [])
          .map(model => ({ value: model.id, label: model.name }))} />
    </Form.Item>
  );
}

export default VehicleModelEnumFormItemSelect;
