import React from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { VehicleBrandWithModels } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { selectVehicleBrandsEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props extends SelectProps<string> {
  brandId: UUID;
}

interface StateProps {
  vehicleBrandsEnumerations: VehicleBrandWithModels[];
}

type ComponentProps = Props & StateProps;

class VehicleModelEnumerationsSelect_Deprecated extends React.Component<ComponentProps> {

  render(): React.ReactNode {
    const { vehicleBrandsEnumerations, brandId, ...selectProps } = this.props;
    const brand = vehicleBrandsEnumerations.find(brand => brandId && brandId === brand.id);

    return (
      <Select
        {...selectStandardProps} {...selectProps}
        options={(brand ? brand.models : []).map(model => ({ value: model.id, label: model.name }))} />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  vehicleBrandsEnumerations: selectVehicleBrandsEnumerations(state)
});

export default connect<StateProps, {}, Props, ComponentProps, RootState>(mapStateToProps, null, null, { forwardRef: true })(VehicleModelEnumerationsSelect_Deprecated);
