import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/calc" />} />

    <Route component={NotFound} />
  </Switch>
);
