import React from "react";
import { Tag } from "antd";
import { CommissionsBatchStep } from "../enums";
import t from "../../../../app/i18n";

export interface Props {
  step: CommissionsBatchStep;
}

const CommissionBatchStepTag = ({ step }: Props) => {
  let color: string;

  switch ( step ) {
    case CommissionsBatchStep.IMPORT:
      color = "volcano";
      break;
    case CommissionsBatchStep.CALCULATION:
      color = "blue";
      break;
    case CommissionsBatchStep.REVIEW:
      color = "purple";
      break;
    case CommissionsBatchStep.FINISH:
      color = "green";
      break;
    default:
      return null;
  }

  return <Tag color={color}>{t("commissions.batch.enums.batchStep." + step)}</Tag>;
};

export default CommissionBatchStepTag;
