import React from "react";
import debounce from "lodash/debounce";
import moment, { Moment } from "moment";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DownloadOutlined, EuroOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { VehicleCalcForm, VehicleCalcType, VehicleCalcVehicleData } from "../../../types";
import { VehicleAutocompleteProps, VehiclePriceRequestProps } from "../../../../../vehicles/types";
import { UUID } from "../../../../../../../common/types";
import { Vehicle } from "../../../../../../contract/types";
import { CrashRepairLimit, VehiclePurpose, VehicleSecurityFeature } from "../../../enums";
import { AutocompleteVehiclesBy } from "../../../../../vehicles/enums";
import { Bodywork, FuelType, Transmission, VehicleCategory } from "../../../../../../contract/enums";
import { CalcType } from "../../../../../enums";
import { downloadCardReaderActions } from "../../../../ducks";
import { CALC_OMITTED_CATEGORIES, M1_N1, SPECS_MAP } from "../../../utils";
import { contains, isDefinedValue, removeStringWhiteSpaces } from "../../../../../../../common/utils/utils";
import messageUtils from "../../../../../../../common/utils/messageUtils";
import { formatIntegerLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import validations, { validationFunctions } from "../../../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerPast,
  disableDatePickerPresentAndFuture,
  licensePlateNormalizeFunction,
  momentToDateString,
  selectStandardProps,
  toMoment,
  upperCaseStringNormalizeFunction
} from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import t from "../../../../../../../app/i18n";

import VehicleBrandEnumerationsSelect
  from "../../../../../../enumerations/components/deprecated/VehicleBrandEnumerationsSelect_Deprecated";
import VehicleModelEnumerationsSelect
  from "../../../../../../enumerations/components/deprecated/VehicleModelEnumerationsSelect_Deprecated";
import InputNumberWithAddon from "../../../../../../../common/components/form/components/InputNumberWithAddon";
import HiddenInputDeprecated from "../../../../../../../common/components/form/components/HiddenInput_Deprecated";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import ComponentWithPermission from "../../../../../../../common/security/authorization/ComponentWithPermission";

export interface Props {
  form: WrappedFormUtils;
  initialVehicleData: VehicleCalcVehicleData;
  calcType: VehicleCalcType;
  firstRegistrationDate: Moment;
  category: VehicleCategory;
  vehicleAutocomplete: VehicleAutocompleteProps;
  vehiclePriceRequest: VehiclePriceRequestProps;
  otherVehicleBrandsIds: UUID[];
  otherVehicleModelsIds: UUID[];
  onDownloadCardReader: typeof downloadCardReaderActions.request;
  onFirstRegistrationDateChange(firstRegistrationDate: Moment): void;
  onCategoryChange(category: VehicleCategory): void;
}

interface State {
  readonly newVehicle: boolean;
  readonly brandId: UUID;
  readonly modelId: UUID;
  readonly fuelType: FuelType;
  readonly price: number;
  readonly vehicleAutocompleteFocusedItem: AutocompleteVehiclesBy;
}

const AUTOCOMPLETE_MAX_SIZE = 7;

class VehicleCalcVehicleDataSection extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const stateObject = {
      newVehicleDisabled: false,
      newVehicle: false,
      brandId: null,
      modelId: null,
      fuelType: null,
      price: null,
      vehicleAutocompleteFocusedItem: null
    };

    if ( props.initialVehicleData ) {
      stateObject.newVehicle = props.initialVehicleData.newVehicle;
      stateObject.brandId = props.initialVehicleData.brandId;
      stateObject.modelId = props.initialVehicleData.modelId;
      stateObject.fuelType = props.initialVehicleData.fuelType;
      stateObject.price = props.initialVehicleData.price;
    }

    this.state = stateObject;
  }

  handleNewVehicleChange = (event: CheckboxChangeEvent): void => {
    this.setState({ newVehicle: event.target.checked }, () => {
      const { vehicleData } = this.props.form.getFieldsValue(["vehicleData"]) as VehicleCalcForm;
      this.props.form.setFieldsValue({
        "vehicleData.price": this.props.calcType !== CalcType.MTPL || event.target.checked ? vehicleData.price : null,
        "vehicleData.firstRegistrationDate": null
      });
      this.props.onFirstRegistrationDateChange(null);
    })
  };

  handleBrandIdChange = (brandId: UUID): void => {
    this.props.form.setFieldsValue({ "vehicleData.modelId": undefined });
    this.setState({ brandId, modelId: null });
  };

  handleModelIdChange = (modelId: UUID): void => {
    this.setState({ modelId });
  };

  handleFuelTypeChange = (fuelType: FuelType): void => {
    this.setState({ fuelType }, () => {
      const { vehicleData } = this.props.form.getFieldsValue(["vehicleData"]) as VehicleCalcForm;
      this.props.form.setFieldsValue({
        vehicleData: {
          engineDisplacement: fuelType !== FuelType.ELECTRICITY ? vehicleData.engineDisplacement : undefined
        }
      });
    });
  };

  handlePriceBlur = (): void => {
    const { vehicleData } = this.props.form.getFieldsValue(["vehicleData"]) as VehicleCalcForm;
    this.setState({ price: vehicleData.price });
  };

  handleVehiclePriceRequestSubmit = (): void => {
    this.props.form.validateFieldsAndScroll(["vehicleData.vin", "vehicleData.category",
      "vehicleData.firstRegistrationDate", "vehicleData.engineDisplacement", "vehicleData.enginePower",
      "vehicleData.totalWeight", "vehicleData.fuelType", "vehicleData.transmission", "vehicleData.brandId",
      "vehicleData.modelId", "vehicleData.customBrand", "vehicleData.customModel"], (errors, values: VehicleCalcForm) => {
      if ( errors || !values.vehicleData.vin ) {
        messageUtils.errorNotification(t("common.error"), t("calc.vehicle.validations.vehiclePriceRequestError"), 10);
      }
      else {
        this.props.vehiclePriceRequest.onGetPrice({
          vin: values.vehicleData.vin,
          firstRegistrationDate: momentToDateString(values.vehicleData.firstRegistrationDate as Moment),
          brandId: values.vehicleData.brandId,
          modelId: values.vehicleData.modelId,
          customBrand: values.vehicleData.customBrand,
          customModel: values.vehicleData.customModel,
          category: values.vehicleData.category,
          engineDisplacement: values.vehicleData.engineDisplacement,
          enginePower: values.vehicleData.enginePower,
          fuelType: values.vehicleData.fuelType,
          transmission: values.vehicleData.transmission,
          totalWeight: values.vehicleData.totalWeight
        });
      }
    })
  };

  handleVehicleAutocompleteFocus = (vehicleAutocompleteFocusedItem: AutocompleteVehiclesBy): void => {
    this.setState({ vehicleAutocompleteFocusedItem });
  };

  handleVehicleAutocompleteResultDelete = (): void => {
    if ( this.props.vehicleAutocomplete.result.id ) {
      this.props.vehicleAutocomplete.onResultDelete();
    }
  };

  handleVehicleAutocompleteSearch = debounce(
    (value: string, focusedItem: AutocompleteVehiclesBy): void => {
      const keyword = removeStringWhiteSpaces(value);
      if ( keyword?.length >= 5 && validationFunctions.validateSearchKeyword(keyword) ) {
        this.props.vehicleAutocomplete.onSearch({
          keyword: keyword.toUpperCase(),
          maxResultSize: AUTOCOMPLETE_MAX_SIZE,
          by: focusedItem
        });
      }
      else {
        this.handleVehicleAutocompleteResultDelete();
      }
    }, 500
  );

  handleVehicleAutocompleteSelect = (value: string, focusedItem: AutocompleteVehiclesBy): void => {
    this.processVehicleAutocompleteFormFill(
      this.props.vehicleAutocomplete.result.data.find(vehicle =>
        focusedItem === AutocompleteVehiclesBy.VIN ? vehicle.vin === value : vehicle.licensePlate === removeStringWhiteSpaces(value)),
      focusedItem, true);
  };

  resolveVehicleAutocompleteOptions = (focusedItem: AutocompleteVehiclesBy): DataSourceItemObject[] => {
    return focusedItem === this.props.vehicleAutocomplete.result.by
      ? this.props.vehicleAutocomplete.result.data.map<DataSourceItemObject>(vehicle => ({
        value: focusedItem === AutocompleteVehiclesBy.VIN ? vehicle.vin : licensePlateNormalizeFunction(vehicle.licensePlate),
        text: focusedItem === AutocompleteVehiclesBy.VIN ? vehicle.vin : licensePlateNormalizeFunction(vehicle.licensePlate)
      }))
      : [];
  };

  processVehicleAutocompleteFormFill = (vehicle: Vehicle, focusedItem: AutocompleteVehiclesBy, deleteResult: boolean = false): void => {
    const brandId = vehicle.model && vehicle.model.brand ? vehicle.model.brand.id : null;
    const modelId = brandId && vehicle.model ? vehicle.model.id : null;
    const firstRegistrationDate = toMoment(vehicle.firstRegistrationDate);
    const newVehicle = !!firstRegistrationDate && firstRegistrationDate.isSameOrAfter(moment(), "day");

    this.props.onFirstRegistrationDateChange(firstRegistrationDate);
    this.props.onCategoryChange(vehicle.category);

    this.setState({
      newVehicle,
      brandId,
      modelId,
      fuelType: vehicle.fuelType,
      price: null
    }, () => {
      const formData = this.props.form.getFieldsValue(["vehicleData"]) as VehicleCalcForm;
      this.props.form.setFieldsValue({
        vehicleData: {
          licensePlate: focusedItem === AutocompleteVehiclesBy.VIN && formData.vehicleData.licensePlate
            ? formData.vehicleData.licensePlate : vehicle.licensePlate,
          vin: vehicle.vin,
          firstRegistrationDate,
          brandId,
          modelId,
          newVehicle,
          category: vehicle.category,
          fuelType: vehicle.fuelType,
          transmission: vehicle.transmission,
          bodywork: vehicle.bodywork,
          seatsNumber: vehicle.seatsNumber,
          doorsNumber: vehicle.doorsNumber,
          engineDisplacement: vehicle.engineDisplacement,
          enginePower: vehicle.enginePower,
          totalWeight: vehicle.totalWeight,
          odometer: null,
          purpose: null,
          repairLimit: null,
          usedNotInsured: false,
          steeringWheelOnTheRight: false,
          price: null,
          timePrice: null,
          buyingPrice: null,
          security: formData.vehicleData.security.map(value => ({
            ...value,
            selected: false
          }))
        }
      });

      if ( deleteResult ) {
        this.handleVehicleAutocompleteResultDelete();
      }
    });
  };

  processVehiclePriceResponse = (price: number): void => {
    this.props.form.setFieldsValue({ "vehicleData.price": price });
    messageUtils.infoNotification(t("calc.vehicle.helpers.vehiclePriceResponseTitle"),
      t("calc.vehicle.helpers.vehiclePriceResponseDesc", { price: formatIntegerLocaleCurrency(price) }));
    this.setState({ price }, () => this.props.vehiclePriceRequest.onResultDelete());
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( this.props.vehicleAutocomplete.result.id && this.props.vehicleAutocomplete.result.data.length === 1
      && this.props.vehicleAutocomplete.result.id !== prevProps.vehicleAutocomplete.result.id ) {
      const { result } = this.props.vehicleAutocomplete;

      if ( (result.by === AutocompleteVehiclesBy.LICENSE_PLATE && result.data[0].licensePlate === result.keyword)
        || (result.by === AutocompleteVehiclesBy.VIN && result.data[0].vin === result.keyword) ) {
        this.processVehicleAutocompleteFormFill(result.data[0], this.state.vehicleAutocompleteFocusedItem);
      }
    }

    if ( !isDefinedValue(prevProps.vehiclePriceRequest.price) && isDefinedValue(this.props.vehiclePriceRequest.price) ) {
      this.processVehiclePriceResponse(this.props.vehiclePriceRequest.price);
    }
  }

  render(): React.ReactNode {
    const { newVehicle, brandId, modelId, fuelType, price, vehicleAutocompleteFocusedItem } = this.state;
    const { form, calcType, firstRegistrationDate, category, vehicleAutocomplete, vehiclePriceRequest } = this.props;
    const { getFieldDecorator } = form;

    const showCustomBrandInput = contains(this.props.otherVehicleBrandsIds, brandId);
    const showCustomModelInput = contains(this.props.otherVehicleModelsIds, modelId);

    const colSpan = 4;
    const twoColumnColSpan = 12;
    const threeColumnColSpan = 8;

    return (
      <Card size="small" type="inner" className="card-box" title={t("calc.vehicle.sections.vehicleData")}
            extra={
              <ComponentWithPermission permissions={[Permission.CARD_READER]}>
                <Button icon={<DownloadOutlined />} onClick={() => this.props.onDownloadCardReader()}>
                  {t("calc.helpers.downloadCardReader")}
                </Button>
              </ComponentWithPermission>}>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Spin
              spinning={vehicleAutocomplete.inProgress && vehicleAutocompleteFocusedItem === AutocompleteVehiclesBy.LICENSE_PLATE}>
              <Form.Item label={<LabelWithTooltip label={t("calc.vehicle.attrs.vehicleData.licensePlate")}
                                                  tooltip={t("calc.vehicle.helpers.licensePlateDesc")} />}>
                {getFieldDecorator("vehicleData.licensePlate", {
                  rules: [validations.notBlank, validations.licensePlate], normalize: licensePlateNormalizeFunction
                })(
                  <AutoComplete
                    dropdownMatchSelectWidth={false}
                    onFocus={() => this.handleVehicleAutocompleteFocus(AutocompleteVehiclesBy.LICENSE_PLATE)}
                    onSearch={value => this.handleVehicleAutocompleteSearch(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                    onSelect={value => this.handleVehicleAutocompleteSelect(value, AutocompleteVehiclesBy.LICENSE_PLATE)}
                    options={this.resolveVehicleAutocompleteOptions(AutocompleteVehiclesBy.LICENSE_PLATE)} />
                )}
              </Form.Item>
            </Spin>
          </Col>

          <Col span={colSpan}>
            <Spin
              spinning={vehicleAutocomplete.inProgress && vehicleAutocompleteFocusedItem === AutocompleteVehiclesBy.VIN}>
              <Form.Item label={t("calc.vehicle.attrs.vehicleData.vin")}>
                {getFieldDecorator("vehicleData.vin", {
                  rules: [(newVehicle ? validations.none : validations.notBlank), validations.size(1, 17)],
                  normalize: upperCaseStringNormalizeFunction
                })(
                  <AutoComplete
                    dropdownMatchSelectWidth={false}
                    onFocus={() => this.handleVehicleAutocompleteFocus(AutocompleteVehiclesBy.VIN)}
                    onSearch={value => this.handleVehicleAutocompleteSearch(value, AutocompleteVehiclesBy.VIN)}
                    onSelect={value => this.handleVehicleAutocompleteSelect(value, AutocompleteVehiclesBy.VIN)}
                    options={this.resolveVehicleAutocompleteOptions(AutocompleteVehiclesBy.VIN)} />
                )}
              </Form.Item>
            </Spin>
          </Col>

          <Col span={colSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("vehicleData.newVehicle", {
                rules: [validations.none], valuePropName: "checked", initialValue: newVehicle
              })(
                <Checkbox onChange={this.handleNewVehicleChange}>
                  {t("calc.vehicle.attrs.vehicleData.newVehicle")}
                </Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.firstRegistrationDate")}>
              {getFieldDecorator("vehicleData.firstRegistrationDate", {
                rules: [validations.notNull, newVehicle ? validations.notPast : validations.notPresentAndFuture],
                initialValue: firstRegistrationDate
              })(
                <DatePicker
                  {...datePickerStandardProps}
                  disabledDate={newVehicle ? disableDatePickerPast : disableDatePickerPresentAndFuture}
                  onChange={this.props.onFirstRegistrationDateChange} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.brandId")}>
              {getFieldDecorator("vehicleData.brandId", {
                rules: [validations.notNull], initialValue: brandId
              })(
                <VehicleBrandEnumerationsSelect onChange={this.handleBrandIdChange} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.modelId")}>
              {getFieldDecorator("vehicleData.modelId", { rules: [validations.notNull] })(
                <VehicleModelEnumerationsSelect
                  brandId={brandId}
                  placeholder={t("calc.vehicle.helpers.modelSelectPlaceholder")}
                  onChange={this.handleModelIdChange} />
              )}
            </Form.Item>
          </Col>
        </Row>

        {(showCustomBrandInput || showCustomModelInput) && (
          <Row gutter={rowGutter}>
            {showCustomBrandInput && (
              <Col span={colSpan} offset={colSpan * 4}>
                <Form.Item label={t("calc.vehicle.attrs.vehicleData.customBrand")}>
                  {getFieldDecorator("vehicleData.customBrand", {
                    rules: [validations.notNull, validations.size(1, 255)]
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            )}

            {showCustomModelInput && (
              <Col span={colSpan} offset={showCustomBrandInput ? 0 : colSpan * 5}>
                <Form.Item label={t("calc.vehicle.attrs.vehicleData.customModel")}>
                  {getFieldDecorator("vehicleData.customModel", {
                    rules: [validations.notNull, validations.size(1, 255)]
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        )}

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("contract.enums.vehicleCategory._label")}>
              {getFieldDecorator("vehicleData.category", {
                rules: [validations.notNull], initialValue: category
              })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(VehicleCategory)
                    .filter(category => !contains(CALC_OMITTED_CATEGORIES, category))
                    .map(category => ({ value: category, label: t("contract.enums.vehicleCategory." + category) }))}
                  onChange={this.props.onCategoryChange} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.engineDisplacement")}>
              {getFieldDecorator("vehicleData.engineDisplacement", { rules: [validations.minNumber(1)] })(
                <InputNumberWithAddon
                  addonType="engineDisplacement" formatStyle="integer" min={1}
                  disabled={!contains(SPECS_MAP.get("engineDisplacement"), category) || fuelType === FuelType.ELECTRICITY} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.enginePower")}>
              {getFieldDecorator("vehicleData.enginePower", { rules: [validations.minNumber(1)] })(
                <InputNumberWithAddon
                  addonType="enginePower" formatStyle="integer" min={1}
                  disabled={!contains(SPECS_MAP.get("enginePower"), category)} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("contract.enums.fuelType._label")}>
              {getFieldDecorator("vehicleData.fuelType", {
                rules: [validations.none], initialValue: fuelType
              })(
                <Select
                  {...selectStandardProps} allowClear
                  disabled={!contains(SPECS_MAP.get("fuelType"), category)}
                  options={Object.keys(FuelType).map(type => ({
                    value: type,
                    label: t("contract.enums.fuelType." + type)
                  }))}
                  onChange={this.handleFuelTypeChange} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("contract.enums.transmission._label")}>
              {getFieldDecorator("vehicleData.transmission", { rules: [validations.none] })(
                <Select
                  {...selectStandardProps} allowClear
                  disabled={!contains(SPECS_MAP.get("transmission"), category)}
                  options={Object.keys(Transmission).map(transmission => ({
                    value: transmission,
                    label: t("contract.enums.transmission." + transmission)
                  }))} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("contract.enums.bodywork._label")}>
              {getFieldDecorator("vehicleData.bodywork", { rules: [validations.none] })(
                <Select
                  {...selectStandardProps} allowClear
                  disabled={!contains(SPECS_MAP.get("bodywork"), category)}
                  options={Object.keys(Bodywork).map(bodywork => ({
                    value: bodywork,
                    label: t("contract.enums.bodywork." + bodywork)
                  }))} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.totalWeight")}>
              {getFieldDecorator("vehicleData.totalWeight", {
                rules: [validations.notNull, validations.minNumber(1)]
              })(
                <InputNumberWithAddon addonType="weight" formatStyle="integer" min={1} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.seatsNumber")}>
              {getFieldDecorator("vehicleData.seatsNumber", { rules: [validations.minNumber(0)] })(
                <InputNumber min={0} disabled={!contains(SPECS_MAP.get("seatsNumber"), category)} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.doorsNumber")}>
              {getFieldDecorator("vehicleData.doorsNumber", { rules: [validations.minNumber(0)] })(
                <InputNumber min={0} disabled={!contains(SPECS_MAP.get("doorsNumber"), category)} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.attrs.vehicleData.odometer")}>
              {getFieldDecorator("vehicleData.odometer", { rules: [validations.minNumber(0)] })(
                <InputNumberWithAddon
                  addonType="distance" formatStyle="integer" min={0}
                  disabled={calcType === CalcType.MTPL || !contains(SPECS_MAP.get("odometer"), category)} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.vehicle.enums.vehiclePurpose._label")}>
              {getFieldDecorator("vehicleData.purpose", { rules: [validations.notNull] })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(VehiclePurpose).map(purpose => ({
                    value: purpose,
                    label: t("calc.vehicle.enums.vehiclePurpose." + purpose)
                  }))} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={<LabelWithTooltip label={t("calc.vehicle.enums.crashRepairLimit._label")}
                                                tooltip={t("calc.vehicle.helpers.repairLimitDesc")} />}>
              {getFieldDecorator("vehicleData.repairLimit", { rules: [validations.none] })(
                <Select
                  {...selectStandardProps} allowClear
                  disabled={calcType === CalcType.MTPL}
                  options={Object.keys(CrashRepairLimit).map(limit => ({
                    value: limit,
                    label: t("calc.vehicle.enums.crashRepairLimit." + limit)
                  }))} />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item className="two-line-form-item-without-label">
              {getFieldDecorator("vehicleData.steeringWheelOnTheRight", {
                rules: [validations.none], valuePropName: "checked", initialValue: false
              })(
                <Checkbox disabled={!contains(SPECS_MAP.get("steeringWheelOnTheRight"), category)}>
                  {t("calc.vehicle.attrs.vehicleData.steeringWheelOnTheRight")}
                </Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("vehicleData.usedNotInsured", {
                rules: [validations.none], valuePropName: "checked", initialValue: false
              })(
                <Checkbox disabled={calcType === CalcType.MTPL}>
                  {t("calc.vehicle.attrs.vehicleData.usedNotInsured")}
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter * 2}>
          <Col span={twoColumnColSpan}>
            <Divider className="divider-subheader">{t("calc.vehicle.sections.vehiclePrice")}</Divider>

            <Row gutter={rowGutter}>
              <Col span={24}>
                <Button
                  size="small" icon={<EuroOutlined />} className="blue-button margin-bottom-small"
                  loading={vehiclePriceRequest.inProgress} onClick={this.handleVehiclePriceRequestSubmit}
                  disabled={calcType === CalcType.MTPL || !contains(M1_N1, category)}>
                  {t("calc.vehicle.actions.getVehiclePrice")}
                </Button>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={threeColumnColSpan}>
                <Form.Item label={<LabelWithTooltip label={t("calc.vehicle.attrs.vehicleData.price")}
                                                    tooltip={t("calc.vehicle.helpers.priceDesc")} />}>
                  {getFieldDecorator("vehicleData.price", { rules: [validations.minNumber(1)] })(
                    <InputNumberWithAddon
                      addonType="euro" formatStyle="integer" min={1} onBlur={this.handlePriceBlur}
                      disabled={calcType === CalcType.MTPL && (!newVehicle || !contains(M1_N1, category))} />
                  )}
                </Form.Item>
              </Col>

              <Col span={threeColumnColSpan}>
                <Form.Item label={t("calc.vehicle.attrs.vehicleData.timePrice")}>
                  {getFieldDecorator("vehicleData.timePrice", {
                    rules: [
                      validations.minNumber(1),
                      validations.maxNumber(price, t("calc.vehicle.attrs.vehicleData.price"))
                    ]
                  })(
                    <InputNumberWithAddon
                      addonType="euro" formatStyle="integer" min={1} max={price || undefined}
                      disabled={calcType === CalcType.MTPL} />
                  )}
                </Form.Item>
              </Col>

              <Col span={threeColumnColSpan}>
                <Form.Item label={t("calc.vehicle.attrs.vehicleData.buyingPrice")}>
                  {getFieldDecorator("vehicleData.buyingPrice", {
                    rules: [
                      validations.minNumber(1),
                      validations.maxNumber(price, t("calc.vehicle.attrs.vehicleData.price"))
                    ]
                  })(
                    <InputNumberWithAddon
                      addonType="euro" formatStyle="integer" min={1} max={price || undefined}
                      disabled={calcType === CalcType.MTPL} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={twoColumnColSpan}>
            <Divider className="divider-subheader">{t("calc.vehicle.sections.vehicleSecurity")}</Divider>

            <Row gutter={rowGutter} className="margin-top-large">
              {Object.values(VehicleSecurityFeature).map((feature, index) => (
                <Col span={threeColumnColSpan} key={index}>
                  <HiddenInputDeprecated form={form} formKey={`vehicleData.security[${index}].feature`}
                                         initialValue={feature} />

                  <Form.Item>
                    {getFieldDecorator(`vehicleData.security[${index}].selected`, {
                      rules: [validations.none], valuePropName: "checked", initialValue: false
                    })(
                      <Checkbox>{t("calc.vehicle.enums.vehicleSecurityFeature." + feature)}</Checkbox>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default VehicleCalcVehicleDataSection;
