import { AxiosPromise } from "axios";
import apiService from "../../common/api/apiService";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import {
  Client,
  ClientAutocompleteRequest,
  ClientAutocompleteResultDto,
  ClientList,
  ClientSearchRequest,
  ClientSearchResult,
  CreateUpdateClient,
  ValidateContractClientRequest,
  ValidateContractClientsRequest
} from "./types";
import { EntityIdRequest, EntityObjectRequest, SearchPageRequest, SearchPageResult } from "../../common/types";

const FILTER_CLIENTS = ApiRequestAdapter.create("/clients", "GET");
const AUTOCOMPLETE_CLIENTS = ApiRequestAdapter.create("/clients/autocomplete", "GET");
const SEARCH_CLIENT = ApiRequestAdapter.create("/clients/search", "GET");
const VALIDATE_CLIENT = ApiRequestAdapter.create("/clients/validate", "POST");
const VALIDATE_CLIENTS = ApiRequestAdapter.create("/clients/validate-all", "POST");
const GET_CLIENT = ApiRequestAdapter.create("/clients/{0}", "GET");
const UPDATE_CLIENT = ApiRequestAdapter.create("/clients/{0}", "PUT");
const CREATE_CLIENT = ApiRequestAdapter.create("/clients", "POST");
const DELETE_CLIENT = ApiRequestAdapter.create("/clients/{0}", "DELETE");

export const requests = {
  FILTER_CLIENTS,
  AUTOCOMPLETE_CLIENTS,
  SEARCH_CLIENT,
  VALIDATE_CLIENT,
  VALIDATE_CLIENTS,
  GET_CLIENT,
  UPDATE_CLIENT,
  CREATE_CLIENT,
  DELETE_CLIENT
};

export default {
  filterClients: (request: SearchPageRequest): AxiosPromise<SearchPageResult<ClientList>> => {
    return apiService.get<SearchPageResult<ClientList>>(FILTER_CLIENTS.url, { params: request });
  },
  autocompleteClients: (request: ClientAutocompleteRequest): AxiosPromise<ClientAutocompleteResultDto> => {
    return apiService.get<ClientAutocompleteResultDto>(AUTOCOMPLETE_CLIENTS.url, { params: request });
  },
  searchClient: (request: ClientSearchRequest): AxiosPromise<ClientSearchResult> => {
    return apiService.get<ClientSearchResult>(SEARCH_CLIENT.url, { params: request });
  },
  validateClient: (request: ValidateContractClientRequest): AxiosPromise => {
    return apiService.post<void>(VALIDATE_CLIENT.url, request);
  },
  validateClients: (request: ValidateContractClientsRequest): AxiosPromise => {
    return apiService.post<void>(VALIDATE_CLIENTS.url, request);
  },
  getClient: (request: EntityIdRequest): AxiosPromise<Client> => {
    return apiService.get<Client>(GET_CLIENT.getParametrizedUrl(request.id));
  },
  updateClient: (request: EntityObjectRequest<CreateUpdateClient>): AxiosPromise<Client> => {
    return apiService.put<Client>(UPDATE_CLIENT.getParametrizedUrl(request.id), request.object);
  },
  createClient: (request: CreateUpdateClient): AxiosPromise<Client> => {
    return apiService.post<Client>(CREATE_CLIENT.url, request);
  },
  deleteClient: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_CLIENT.getParametrizedUrl(request.id));
  }
}
