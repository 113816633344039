import t from "../../app/i18n";

export enum ContractType {
  INSURANCE_CONTRACT = 'INSURANCE_CONTRACT',
  LOAN_CONTRACT = 'LOAN_CONTRACT'
}

export enum InsuranceType {
  GENERIC = 'GENERIC',
  MTPL = 'MTPL',
  CRASH = 'CRASH',
  GAP = 'GAP',
  PAS = 'PAS',
  REALTY = 'REALTY',
  TRAVEL = 'TRAVEL',
  LIFE = 'LIFE'
}

export enum InsuranceCoverageType {
  BASIC_ASSISTANCE = 'BASIC_ASSISTANCE',
  EXTENDED_ASSISTANCE = 'EXTENDED_ASSISTANCE',
  GLASS_WINDSHIELD = 'GLASS_WINDSHIELD',
  GLASS = 'GLASS',
  ANIMAL = 'ANIMAL',
  ELEMENT = 'ELEMENT',
  ELEMENT_RODENT = 'ELEMENT_RODENT',
  THEFT = 'THEFT',
  THEFT_AND_VANDALISM = 'THEFT_AND_VANDALISM',
  INJURY_DRIVER = 'INJURY_DRIVER',
  INJURY_CREW = 'INJURY_CREW',
  GAP = 'GAP',
  REPLACEMENT_VEHICLE = 'REPLACEMENT_VEHICLE',
  GENERALI_ABROAD_VEHICLE_REPAIR = 'GENERALI_ABROAD_VEHICLE_REPAIR',
  BAD_ROAD_CONDITIONS = 'BAD_ROAD_CONDITIONS',
  PNEU = 'PNEU',
  LUGGAGE = 'LUGGAGE'
}

export enum ContractStatus {
  UNSTARTED = 'UNSTARTED',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  TRANSFERRED_TO_BROKER = 'TRANSFERRED_TO_BROKER'
}

export enum ContractOrigin {
  TYPED = 'TYPED',
  CALC = 'CALC',
  IMPORT = 'IMPORT'
}

export enum ContractReactionStatus {
  CONTACT_CLIENT = 'CONTACT_CLIENT',
  CONTRACT_IS_CONTINUED = 'CONTRACT_IS_CONTINUED',
  CANCEL_CONTRACT = 'CANCEL_CONTRACT',
  SIGN_NEW_CONTRACT = 'SIGN_NEW_CONTRACT',
  CONTRACT_IS_NOT_CONTINUED = 'CONTRACT_IS_NOT_CONTINUED',
  SIGNED_CONTRACT = 'SIGNED_CONTRACT'
}

export enum ContractAttachmentType {
  CONTRACT = 'CONTRACT',
  ADDENDUM = 'ADDENDUM',
  EMAIL = 'EMAIL',
  INFORMATION_OBLIGATION = 'INFORMATION_OBLIGATION',
  PHOTO_DOCUMENTATION = 'PHOTO_DOCUMENTATION',
  REGISTRATION_CERTIFICATE = 'REGISTRATION_CERTIFICATE',
  ACQUISITION_DOCUMENT = 'ACQUISITION_DOCUMENT',
  CREDIT_DOCUMENT = 'CREDIT_DOCUMENT',
  WHITE_CARD = 'WHITE_CARD',
  GREEN_CARD = 'GREEN_CARD',
  ACCEPTANCE_PROTOCOL = 'ACCEPTANCE_PROTOCOL',
  OWNERSHIP_DOCUMENT = 'OWNERSHIP_DOCUMENT',
  EXPERT_TESTIMONY = 'EXPERT_TESTIMONY',
  CADASTRAL_MAP = 'CADASTRAL_MAP',
  OFFER = 'OFFER',
  CLIENT_SIGNATURE = 'CLIENT_SIGNATURE',
  OTHER = 'OTHER'
}

export enum InsuranceContractPeriodType {
  DEFINITE = 'DEFINITE',
  INDEFINITE = 'INDEFINITE'
}

export enum InsuranceContractInsurancePeriod {
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  TECHNICAL_YEAR = 'TECHNICAL_YEAR',
  FIXED = 'FIXED'
}

export enum InsuranceContractPaymentFrequency {
  ANNUALLY = 'ANNUALLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE'
}

export enum PaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  POSTAL_ORDER = 'POSTAL_ORDER'
}

export enum AuthorizedClientCompanyFunction {
  EXECUTIVE_MANAGER = 'EXECUTIVE_MANAGER',
  AUTHORIZED_EMPLOYEE = 'AUTHORIZED_EMPLOYEE'
}

export enum ClientExperience {
  SUFFICIENT = 'SUFFICIENT',
  MINIMAL = 'MINIMAL',
  NONE = 'NONE'
}

export enum LoanRateType {
  FIXED_ONE_YEAR = 'FIXED_ONE_YEAR',
  FIXED_TWO_YEARS = 'FIXED_TWO_YEARS',
  FIXED_THREE_YEARS = 'FIXED_THREE_YEARS',
  FIXED_FOUR_YEARS = 'FIXED_FOUR_YEARS',
  FIXED_FIVE_YEARS = 'FIXED_FIVE_YEARS',
  FIXED_SIX_YEARS = 'FIXED_SIX_YEARS',
  FIXED_SEVEN_YEARS = 'FIXED_SEVEN_YEARS',
  FIXED_EIGHT_YEARS = 'FIXED_EIGHT_YEARS',
  FIXED_NINE_YEARS = 'FIXED_NINE_YEARS',
  FIXED_TEN_YEARS = 'FIXED_TEN_YEARS',
  VARIABLE = 'VARIABLE'
}

export enum VehicleCategory {
  PERSONAL_M1 = 'PERSONAL_M1',
  UTILITY_N1 = 'UTILITY_N1',
  MOTORCYCLE_L = 'MOTORCYCLE_L',
  TRICYCLE_L = 'TRICYCLE_L',
  QUAD_BIKE_L = 'QUAD_BIKE_L',
  MOTOR_SLEDGE_LS = 'MOTOR_SLEDGE_LS',
  TRACTOR_T = 'TRACTOR_T',
  TRACTOR_C = 'TRACTOR_C',
  MACHINE_P = 'MACHINE_P',
  FORKLIFT_P = 'FORKLIFT_P',
  LORRY_N = 'LORRY_N',
  SEMI_N3 = 'SEMI_N3',
  BUS_CITY_M = 'BUS_CITY_M',
  BUS_OTHER_M = 'BUS_OTHER_M',
  RV = 'RV',
  SIDECAR_PERSONAL_O = 'SIDECAR_PERSONAL_O',
  SIDECAR_CARGO_O = 'SIDECAR_CARGO_O',
  SIDECAR_CARAVAN_O = 'SIDECAR_CARAVAN_O',
  SIDECAR_TRACTOR_R = 'SIDECAR_TRACTOR_R',
  TRAILER_O = 'TRAILER_O'
}

export enum FuelType {
  PETROL = 'PETROL',
  DIESEL = 'DIESEL',
  ELECTRICITY = 'ELECTRICITY',
  PETROL_HYBRID = 'PETROL_HYBRID',
  DIESEL_HYBRID = 'DIESEL_HYBRID',
  LPG = 'LPG',
  PETROL_LPG = 'PETROL_LPG',
  DIESEL_LPG = 'DIESEL_LPG',
  CNG = 'CNG',
  PETROL_CNG = 'PETROL_CNG',
  DIESEL_CNG = 'DIESEL_CNG',
  HYDROGEN = 'HYDROGEN',
  ETHANOL = 'ETHANOL'
}

export enum Transmission {
  A_1 = 'A_1',
  A_3 = 'A_3',
  A_4 = 'A_4',
  A_5 = 'A_5',
  A_6 = 'A_6',
  A_7 = 'A_7',
  A_8 = 'A_8',
  A_9 = 'A_9',
  A_12 = 'A_12',
  A_16 = 'A_16',
  M_2 = 'M_2',
  M_4 = 'M_4',
  M_5 = 'M_5',
  M_6 = 'M_6',
  M_7 = 'M_7',
  M_8 = 'M_8',
  M_9 = 'M_9',
  M_12 = 'M_12',
  M_14 = 'M_14',
  M_16 = 'M_16',
  AV = 'AV',
  R_5 = 'R_5',
  R_6 = 'R_6',
  R_7 = 'R_7',
  R_8 = 'R_8',
  R_12 = 'R_12'
}

export enum Bodywork {
  SEDAN = 'SEDAN',
  HATCHBACK = 'HATCHBACK',
  COMBI = 'COMBI',
  PICKUP = 'PICKUP',
  COUPE = 'COUPE',
  MPV = 'MPV',
  LIMOUSINE = 'LIMOUSINE',
  CABRIOLET = 'CABRIOLET',
  ROADSTER = 'ROADSTER',
  TARGA = 'TARGA',
  BUS = 'BUS'
}

export enum ContractView {
  INTERNAL = 'INTERNAL',
  INTERNAL_GENERIC_REPORT = 'INTERNAL_GENERIC_REPORT',
  INTERNAL_ANNIVERSARY_DATE_REPORT = 'INTERNAL_ANNIVERSARY_DATE_REPORT',
  INTERNAL_UNPAID_REPORT = 'INTERNAL_UNPAID_REPORT',
  PUBLIC = 'PUBLIC',
  PUBLIC_ACTIVE_REPORT = 'PUBLIC_ACTIVE_REPORT',
  PUBLIC_INACTIVE_REPORT = 'PUBLIC_INACTIVE_REPORT',
  PUBLIC_UNPAID_REPORT = 'PUBLIC_UNPAID_REPORT'
}

export enum ContractOrderByColumn {
  CREATED_AT = 'CREATED_AT',
  EFFECTIVE_BEGINNING_OR_SIGN_DATE = 'EFFECTIVE_BEGINNING_OR_SIGN_DATE',
  ANNUAL_PREMIUM_OR_APPROVED_AMOUNT = 'ANNUAL_PREMIUM_OR_APPROVED_AMOUNT',
  LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE = 'LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE',
  INSURANCE_PERIOD_END_OR_FIXATION_ANNIVERSARY_DATE = 'INSURANCE_PERIOD_END_OR_FIXATION_ANNIVERSARY_DATE',
  PAID_UNTIL_DATE = 'PAID_UNTIL_DATE',
  AMOUNT_OWED = 'AMOUNT_OWED'
}

export const contractStatusTMap = new Map<ContractStatus, string>(
  Object.values(ContractStatus).map(status => [status, t("contract.enums.status." + status)])
);
