import ibanUtils from "iban";
import isLowerCase from "voca/is_lower_case";
import isUpperCase from "voca/is_upper_case";
import isNumeric from "voca/is_numeric";
import { NumberType, parsePhoneNumber } from "libphonenumber-js/max";
import XRegExp from "xregexp";
import moment, { Moment } from "moment";
import { FormInstance, Rule } from "antd/lib/form";
import { NamePath } from "rc-field-form/lib/interface"
import { ValidationRule, WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { ClientType } from "../../modules/client/enums";
import {
  academicDegrees,
  academicDegreesAfter,
  commercialRegisterDistrictOffices,
  commercialRegisterSections,
  LicensePlateRegionPrefixes
} from "../constants";
import { ALL_WHITE_SPACES_PATTERN, contains, isDefinedValue } from "./utils";
import { formatLocaleDate } from "./formatUtils";
import t from "../../app/i18n";

// Regex patterns
const uuidRegex = new RegExp("[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}");
const urlPathParamRegex = new RegExp("{\\d{1,2}}");

const webPageRegex = new RegExp("^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$");
const numberRegex = new RegExp("^\\d*$");
const wordRegex = XRegExp("^[\\p{L} \\-]*$");
const usernameRegex = new RegExp("^[a-zA-Z0-9_\\-]*$");
const emailRegex = new RegExp("^[a-zA-Z0-9_.\\-]+@[a-zA-Z0-9_.\\-]+\\.[a-zA-Z0-9]+$");
const nameRegex = XRegExp("^[\\p{L} \\-,.]*$");
const idCardRegex = new RegExp("^[A-Z0-9]{6,10}$");
const vatIdRegex = new RegExp("^SK[0-9]{10}$");
const tradeRegNumRegex = new RegExp("^[0-9\\-]*$");
const parcelNumberRegex = new RegExp("^[0-9]+(/[0-9]+)?$");
const streetRegex = XRegExp("^[\\p{L}0-9 .,\\-]*$");
const streetNumberRegex = new RegExp("^[0-9/A-Z]*$");
const filenameRegex = new RegExp("^[0-9a-zA-Z-_.]*$");
const vaccinationIdentifierRegex = new RegExp("^SK[0-9]{9}$");
const registrationCertificateNumberRegex = new RegExp("^[A-Z]{2}\\d{6}$");

const plateSuffixPattern = new RegExp("^([0-9]{3}[A-Z]{2})|([A-Z]{5})|([A-Z]{4}[0-9])|([A-Z]{3}[0-9]{2})$");
const eePlatePattern = new RegExp("^EE[0-9]{5}$");
const zzPlatePattern = new RegExp("^ZZ[0-9]{5}$");
const pPlatePatter = new RegExp("^P[A-Z]?[0-9]{5}$");
const armyPlatePatter = new RegExp("^[0-9]{7}$");

export const regexPatterns = {
  uuidRegex,
  urlPathParamRegex,
  webPageRegex,
  numberRegex,
  wordRegex,
  usernameRegex,
  emailRegex,
  nameRegex,
  idCardRegex,
  vatIdRegex,
  tradeRegNumRegex,
  parcelNumberRegex,
  streetRegex,
  streetNumberRegex,
  filenameRegex,
  vaccinationIdentifierRegex,
  registrationCertificateNumberRegex
};

// Custom validation functions
const SEARCH_KEYWORD_MIN_LENGTH = 3;
const SEARCH_KEYWORD_MAX_LENGTH = 64;

const validateSearchKeyword = (keyword: string): boolean => {
  return keyword && keyword !== ""
    ? keyword.trim().length >= SEARCH_KEYWORD_MIN_LENGTH && keyword.trim().length <= SEARCH_KEYWORD_MAX_LENGTH
    : true;
};

const validateIban = (value: string): boolean => !value || ibanUtils.isValid(value);

const validatePin = (value: string): boolean => {
  if ( !value ) {
    return true;
  }

  if ( isNumeric(value) && value.length >= 9 && value.length <= 10 ) {
    const day = parseInt(value.substring(4, 6));
    let month = parseInt(value.substring(2, 4));
    month = month > 50 ? month - 51 : month - 1;

    const yearString = value.substring(0, 2);
    const year = value.length === 9
      ? parseInt("19" + yearString)
      : parseInt((moment().get("year") - parseInt(yearString) < 2000 ? "19" : "20") + yearString);

    const birthDate = moment().year(year).month(month).date(day);
    if ( birthDate.get("date") !== day || birthDate.get("month") !== month || birthDate.get("year") !== year ) {
      return false;
    }

    return value.length === 9 ? parseInt(value.substring(0, 2)) < 54 : value.substring(6) === "9999" || parseInt(value) % 11 === 0;
  }

  return false;
};

const validateCrn = (value: string): boolean => !value || (numberRegex.test(value) && value.length === 8);

const validatePinOrCrn = (value: string): boolean => !value || validatePin(value) || validateCrn(value);

const validateClientIdentifier = (value: string, type: ClientType): boolean => {
  switch ( type ) {
    case ClientType.NATURAL:
      return validationFunctions.validatePin(value);
    case ClientType.SELF_EMPLOYED:
    case ClientType.LEGAL:
      return validationFunctions.validateCrn(value);
    default:
      return validationFunctions.validatePinOrCrn(value);
  }
};

const validateLicensePlate = (value: string): boolean => {
  if ( value ) {
    const trimmedValue = value.replace(ALL_WHITE_SPACES_PATTERN, "");
    if ( trimmedValue.length === 2 ) {
      return contains(LicensePlateRegionPrefixes, trimmedValue);
    }
    else if ( trimmedValue.length === 6 || trimmedValue.length === 7 ) {
      return contains(LicensePlateRegionPrefixes, trimmedValue.substring(0, 2))
        ? plateSuffixPattern.test(trimmedValue.substring(2))
        : eePlatePattern.test(trimmedValue) || zzPlatePattern.test(trimmedValue) || pPlatePatter.test(trimmedValue) || armyPlatePatter.test(trimmedValue);
    }
    return false;
  }
  return true;
};

const validatePhoneNumber = (value: string, type?: NumberType): boolean => {
  if ( value ) {
    try {
      const phoneNumber = parsePhoneNumber(value, "SK");
      return phoneNumber.isValid() && (type ? phoneNumber.getType() === type : true);
    }
    catch ( error ) {
      return false;
    }
  }
  return true;
}

export const validationFunctions = {
  validateSearchKeyword,
  validateIban,
  validatePin,
  validateCrn,
  validatePinOrCrn,
  validateClientIdentifier,
  validateLicensePlate,
  validatePhoneNumber
};

export const validationConstants = {
  SEARCH_KEYWORD_MIN_LENGTH,
  SEARCH_KEYWORD_MAX_LENGTH
};

/**
 * Empty rule with no validations.
 * Can be used e.g. as cleaner of field error messages in case when error of given field was set programmatically
 * and no other validation rule is suitable for use.
 */
const none: ValidationRule = {
  validator: (rule, value, callback) => callback()
};

// Type rules
const number: ValidationRule = { type: "number", message: t("validation.number") };

const integer: ValidationRule = { type: "integer", message: t("validation.integer") };

const float: ValidationRule = { type: "float", message: t("validation.float") };

const date: ValidationRule = { type: "date", message: t("validation.date.invalid") };

const url: ValidationRule = { type: "url", message: t("validation.url") };

const email: ValidationRule = { pattern: emailRegex, message: t("validation.email") };

// Validation rules
const notNull: ValidationRule = { required: true, message: t("validation.notNull") };

const notBlank: ValidationRule = { required: true, whitespace: true, message: t("validation.notBlank") };

const length = (len: number): ValidationRule => ({ len, message: t("validation.length", { len }) });

const size = (min: number, max: number): ValidationRule => ({ min, max, message: t("validation.size", { min, max }) });

const min = (min: number): ValidationRule => ({ min, message: t("validation.min", { min }) });

const max = (max: number): ValidationRule => ({ max, message: t("validation.max", { max }) });

const pattern = (pattern: RegExp | string): ValidationRule => ({
  pattern: typeof pattern === "string" ? new RegExp(pattern) : pattern, message: t("validation.pattern")
});

const minNumber = (min: number, minLabel?: string): ValidationRule => ({
  validator: (rule, value, callback) => callback(
    isDefinedValue(value) && isDefinedValue(min) && value < min
      ? t("validation.minNumber", { min: minLabel ? minLabel : min })
      : undefined
  )
});

const maxNumber = (max: number, maxLabel?: string): ValidationRule => ({
  validator: (rule, value, callback) => callback(
    isDefinedValue(value) && isDefinedValue(max) && value > max
      ? t("validation.maxNumber", { max: maxLabel ? maxLabel : max })
      : undefined
  )
});

const notPresentAndFuture: ValidationRule = {
  validator: (rule, value, callback) => callback(
    moment.isMoment(value) && (value as Moment).isSameOrAfter(moment(), "day") ? t("validation.date.notPresentAndFuture") : undefined
  )
};

const notFuture: ValidationRule = {
  validator: (rule, value, callback) => callback(
    moment.isMoment(value) && (value as Moment).isAfter(moment(), "day") ? t("validation.date.notFuture") : undefined
  )
};

const notPresentAndPast: ValidationRule = {
  validator: (rule, value, callback) => callback(
    moment.isMoment(value) && (value as Moment).isSameOrBefore(moment(), "day") ? t("validation.date.notPresentAndPast") : undefined
  )
};

const notPast: ValidationRule = {
  validator: (rule, value, callback) => callback(
    moment.isMoment(value) && (value as Moment).isBefore(moment(), "day") ? t("validation.date.notPast") : undefined
  )
};

const notPresent: ValidationRule = {
  validator: (rule, value, callback) => callback(
    moment.isMoment(value) && (value as Moment).isSame(moment(), "day") ? t("validation.date.notPresent") : undefined
  )
};

const dateInInterval = (min: Moment, max: Moment): ValidationRule => ({
  validator: (rule, value, callback) => callback(
    moment.isMoment(value) && ((value as Moment).isBefore(min, "day") || (value as Moment).isAfter(max, "day"))
      ? t("validation.date.inInterval", { min: formatLocaleDate(min), max: formatLocaleDate(max) })
      : undefined
  )
});

const multipleOf100: ValidationRule = {
  validator: (rule, value, callback) => callback(value && value % 100 !== 0 ? t("validation.multipleOf100") : undefined)
};

const multipleOf1000: ValidationRule = {
  validator: (rule, value, callback) => callback(value && value % 1000 !== 0 ? t("validation.multipleOf1000") : undefined)
};

const iban: ValidationRule = {
  validator: (rule, value, callback) => callback(validateIban(value) ? undefined : t("validation.iban"))
};

const numeric: ValidationRule = {
  validator: (rule, value, callback) => callback(value && !numberRegex.test(value) ? t("validation.number") : undefined)
};

const phoneNumber: ValidationRule = {
  validator: (rule, value, callback) => callback(validatePhoneNumber(value) ? undefined : t("validation.phoneNumber"))
};

const fixedLinePhoneNumber: ValidationRule = {
  validator: (rule, value, callback) => callback(validatePhoneNumber(value, "FIXED_LINE") ? undefined : t("validation.fixedLinePhoneNumber"))
};

const mobilePhoneNumber: ValidationRule = {
  validator: (rule, value, callback) => callback(validatePhoneNumber(value, "MOBILE") ? undefined : t("validation.mobilePhoneNumber"))
};

const sufficientPassword: ValidationRule[] = [
  notBlank,
  {
    validator: (rule, value, callback) => {
      if ( !value || value.trim().length < 8 ) {
        callback(t("validation.password.length"));
        return;
      }

      let hasLowerCaseLetter = false;
      let hasUpperCaseLetter = false;
      let hasNumber = false;

      for ( let i = 0; i < value.length; i++ ) {
        if ( isLowerCase(value.charAt(i)) ) {
          hasLowerCaseLetter = true;
        }
        else if ( isUpperCase(value.charAt(i)) ) {
          hasUpperCaseLetter = true;
        }
        else if ( isNumeric(value.charAt(i)) ) {
          hasNumber = true;
        }
      }

      if ( !hasLowerCaseLetter ) {
        callback(t("validation.password.lowerCaseMissing"));
        return;
      }
      if ( !hasUpperCaseLetter ) {
        callback(t("validation.password.upperCaseMissing"));
        return;
      }
      if ( !hasNumber ) {
        callback(t("validation.password.numberMissing"));
        return;
      }

      callback();
    }
  }
];

const repeatedPassword = (form: WrappedFormUtils, passwordKey: string = "password"): ValidationRule => ({
  validator: (rule, value, callback) => callback(value && value !== form.getFieldValue(passwordKey) ? t("validation.password.repeatError") : undefined)
});

const pin: ValidationRule = {
  validator: (rule, value, callback) => callback(validatePin(value) ? undefined : t("validation.pin"))
};

const crn: ValidationRule = {
  validator: (rule, value, callback) => callback(validateCrn(value) ? undefined : t("validation.crn"))
};

const pinOrCrn: ValidationRule = {
  validator: (rule, value, callback) => callback(validatePinOrCrn(value) ? undefined : t("validation.pinOrCrn"))
};

const licensePlate: ValidationRule = {
  validator: (rule, value, callback) => {
    if ( value ) {
      const trimmedValue = value.replace(ALL_WHITE_SPACES_PATTERN, "");
      callback(trimmedValue.length >= 2 && trimmedValue.length <= 7 && validateLicensePlate(value) ? undefined : t("validation.licensePlate"));
    }
    return callback();
  }
};

const fullLicensePlate: ValidationRule = {
  validator: (rule, value, callback) => callback(
    value && value.replace(ALL_WHITE_SPACES_PATTERN, "").length === 7 && validateLicensePlate(value) ? undefined : t("validation.licensePlate")
  )
};

const academicDegree: ValidationRule = {
  validator: (rule, value, callback) => callback(value && !contains(academicDegrees, value) ? t("validation.academicDegree") : undefined)
};

const academicDegreeAfter: ValidationRule = {
  validator: (rule, value, callback) => callback(value && !contains(academicDegreesAfter, value) ? t("validation.academicDegreeAfter") : undefined)
};

const commercialRegisterSection: ValidationRule = {
  validator: (rule, value, callback) => callback(value && !contains(commercialRegisterSections, value) ? t("validation.commercialRegisterSection") : undefined)
};

const commercialRegisterDistrictOffice: ValidationRule = {
  validator: (rule, value, callback) => callback(value && !contains(commercialRegisterDistrictOffices, value) ? t("validation.commercialRegisterDistrictOffice") : undefined)
};

export default {
  none,
  number,
  integer,
  float,
  date,
  url,
  email,
  notNull,
  notBlank,
  length,
  size,
  min,
  max,
  pattern,
  minNumber,
  maxNumber,
  notPresentAndFuture,
  notFuture,
  notPresentAndPast,
  notPast,
  notPresent,
  dateInInterval,
  multipleOf100,
  multipleOf1000,
  iban,
  numeric,
  phoneNumber,
  fixedLinePhoneNumber,
  mobilePhoneNumber,
  sufficientPassword,
  repeatedPassword,
  pin,
  crn,
  pinOrCrn,
  licensePlate,
  fullLicensePlate,
  academicDegree,
  academicDegreeAfter,
  commercialRegisterSection,
  commercialRegisterDistrictOffice
}

// Ant Design v4 validation rules

/**
 * Empty rule with no validations.
 * Can be used e.g. as cleaner of field error messages in case when error of given field was set programmatically
 * and no other validation rule is suitable for use.
 */
const none_v4: Rule = { validator: _ => Promise.resolve() };

const url_v4: Rule = { type: "url", message: t("validation.url") };

const email_v4: Rule = { pattern: emailRegex, message: t("validation.email") };

const notNull_v4: Rule = { required: true, message: t("validation.notNull") };

const notBlank_v4: Rule = { required: true, whitespace: true, message: t("validation.notBlank") };

const length_v4 = (len: number): Rule => ({ len, message: t("validation.length", { len }) });

const size_v4 = (min: number, max: number): Rule => ({ min, max, message: t("validation.size", { min, max }) });

const min_v4 = (min: number): Rule => ({ min, message: t("validation.min", { min }) });

const max_v4 = (max: number): Rule => ({ max, message: t("validation.max", { max }) });

const pattern_v4 = (pattern: RegExp | string): Rule => ({
  pattern: typeof pattern === "string" ? new RegExp(pattern) : pattern, message: t("validation.pattern")
});

const minNumber_v4 = (min: number, minLabel?: string): Rule => ({
  validator: (rule, value) =>
    isDefinedValue(value) && isDefinedValue(min) && value < min
      ? Promise.reject(t("validation.minNumber", { min: minLabel ? minLabel : min }))
      : Promise.resolve()
});

const maxNumber_v4 = (max: number, maxLabel?: string): Rule => ({
  validator: (rule, value,) =>
    isDefinedValue(value) && isDefinedValue(max) && value > max
      ? Promise.reject(t("validation.maxNumber", { max: maxLabel ? maxLabel : max }))
      : Promise.resolve()
});

const notPresentAndFuture_v4: Rule = {
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isSameOrAfter(moment(), "day")
      ? Promise.reject(t("validation.date.notPresentAndFuture"))
      : Promise.resolve()
};

const notFuture_v4: Rule = {
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isAfter(moment(), "day")
      ? Promise.reject(t("validation.date.notFuture"))
      : Promise.resolve()
};

const notPresentAndPast_v4: Rule = {
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isSameOrBefore(moment(), "day")
      ? Promise.reject(t("validation.date.notPresentAndPast"))
      : Promise.resolve()
};

const notPast_v4: Rule = {
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isBefore(moment(), "day")
      ? Promise.reject(t("validation.date.notPast"))
      : Promise.resolve()
};

const notPresent_v4: Rule = {
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isSame(moment(), "day")
      ? Promise.reject(t("validation.date.notPresent"))
      : Promise.resolve()

};

const notBefore_v4 = (min: Moment, minLabel?: string): Rule => ({
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isBefore(min, "day")
      ? Promise.reject(t("validation.date.notBefore", { min: minLabel || formatLocaleDate(min) }))
      : Promise.resolve()
});

const notSameOrBefore_v4 = (min: Moment, minLabel?: string): Rule => ({
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isSameOrBefore(min, "day")
      ? Promise.reject(t("validation.date.notSameOrBefore", { min: minLabel || formatLocaleDate(min) }))
      : Promise.resolve()
});

const notAfter_v4 = (max: Moment, maxLabel?: string): Rule => ({
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isAfter(max, "day")
      ? Promise.reject(t("validation.date.notAfter", { max: maxLabel || formatLocaleDate(max) }))
      : Promise.resolve()
});

const notSameOrAfter_v4 = (max: Moment, maxLabel?: string): Rule => ({
  validator: (rule, value) =>
    moment.isMoment(value) && (value as Moment).isSameOrAfter(max, "day")
      ? Promise.reject(t("validation.date.notSameOrAfter", { max: maxLabel || formatLocaleDate(max) }))
      : Promise.resolve()
});

const dateInInterval_v4 = (min: Moment, max: Moment): Rule => ({
  validator: (rule, value) =>
    moment.isMoment(value) && ((value as Moment).isBefore(min, "day") || (value as Moment).isAfter(max, "day"))
      ? Promise.reject(t("validation.date.inInterval", { min: formatLocaleDate(min), max: formatLocaleDate(max) }))
      : Promise.resolve()
});

const multipleOf100_v4: Rule = {
  validator: (rule, value) => value && value % 100 !== 0 ? Promise.reject(t("validation.multipleOf100")) : Promise.resolve()
};

const multipleOf1000_v4: Rule = {
  validator: (rule, value) => value && value % 1000 !== 0 ? Promise.reject(t("validation.multipleOf1000")) : Promise.resolve()
};

const iban_v4: Rule = {
  validator: (rule, value) => validateIban(value) ? Promise.resolve() : Promise.reject(t("validation.iban"))
};

const numeric_v4: Rule = {
  validator: (rule, value) => value && !numberRegex.test(value) ? Promise.reject(t("validation.number")) : Promise.resolve()
};

const phoneNumber_v4: Rule = {
  validator: (rule, value) => validatePhoneNumber(value) ? Promise.resolve() : Promise.reject(t("validation.phoneNumber"))
};

const fixedLinePhoneNumber_v4: Rule = {
  validator: (rule, value) => validatePhoneNumber(value, "FIXED_LINE") ? Promise.resolve() : Promise.reject(t("validation.fixedLinePhoneNumber"))
};

const mobilePhoneNumber_v4: Rule = {
  validator: (rule, value) => validatePhoneNumber(value, "MOBILE") ? Promise.resolve() : Promise.reject(t("validation.mobilePhoneNumber"))
};

const sufficientPassword_v4: Rule[] = [
  notBlank_v4,
  {
    validator: (rule, value) => {
      if ( !value || value.trim().length < 8 ) {
        return Promise.reject(t("validation.password.length"));
      }

      let hasLowerCaseLetter = false;
      let hasUpperCaseLetter = false;
      let hasNumber = false;

      for ( let i = 0; i < value.length; i++ ) {
        if ( isLowerCase(value.charAt(i)) ) {
          hasLowerCaseLetter = true;
        }
        else if ( isUpperCase(value.charAt(i)) ) {
          hasUpperCaseLetter = true;
        }
        else if ( isNumeric(value.charAt(i)) ) {
          hasNumber = true;
        }
      }

      if ( !hasLowerCaseLetter ) {
        return Promise.reject(t("validation.password.lowerCaseMissing"));
      }
      if ( !hasUpperCaseLetter ) {
        return Promise.reject(t("validation.password.upperCaseMissing"));
      }
      if ( !hasNumber ) {
        return Promise.reject(t("validation.password.numberMissing"));
      }

      return Promise.resolve();
    }
  }
];

const repeatedPassword_v4 = (form: FormInstance, passwordKey: string = "password"): Rule => ({
  validator: (rule, value) => value && value !== form.getFieldValue(passwordKey)
    ? Promise.reject(t("validation.password.repeatError"))
    : Promise.resolve()
});

const pin_v4: Rule = {
  validator: (rule, value) => validatePin(value) ? Promise.resolve() : Promise.reject(t("validation.pin"))
};

const crn_v4: Rule = {
  validator: (rule, value) => validateCrn(value) ? Promise.resolve() : Promise.reject(t("validation.crn"))
};

const pinOrCrn_v4: Rule = {
  validator: (rule, value) => validatePinOrCrn(value) ? Promise.resolve() : Promise.reject(t("validation.pinOrCrn"))
};

const licensePlate_v4: Rule = {
  validator: (rule, value) => {
    if ( value ) {
      const trimmedValue = value.replace(ALL_WHITE_SPACES_PATTERN, "");
      return trimmedValue.length >= 2 && trimmedValue.length <= 7 && validateLicensePlate(value)
        ? Promise.resolve()
        : Promise.reject(t("validation.licensePlate"));
    }
    return Promise.resolve();
  }
};

const fullLicensePlate_v4: Rule = {
  validator: (rule, value) =>
    value && value.replace(ALL_WHITE_SPACES_PATTERN, "").length === 7 && validateLicensePlate(value)
      ? Promise.resolve()
      : Promise.reject(t("validation.licensePlate"))
};

const academicDegree_v4: Rule = {
  validator: (rule, value) =>
    value && !contains(academicDegrees, value)
      ? Promise.reject(t("validation.academicDegree"))
      : Promise.resolve()
};

const academicDegreeAfter_v4: Rule = {
  validator: (rule, value) =>
    value && !contains(academicDegreesAfter, value)
      ? Promise.reject(t("validation.academicDegreeAfter"))
      : Promise.resolve()
};

const commercialRegisterSection_v4: Rule = {
  validator: (rule, value) =>
    value && !contains(commercialRegisterSections, value)
      ? Promise.reject(t("validation.commercialRegisterSection"))
      : Promise.resolve()
};

const commercialRegisterDistrictOffice_v4: Rule = {
  validator: (rule, value) =>
    value && !contains(commercialRegisterDistrictOffices, value)
      ? Promise.reject(t("validation.commercialRegisterDistrictOffice"))
      : Promise.resolve()
};

const noRepeatedValue_v4 = (form: FormInstance, arrayPath: NamePath, messageKey: string = "validation.noRepeatedValue"): Rule => ({
  validator: (rule, value) =>
    value && (form.getFieldValue(arrayPath) as any[] || []).filter(v => v === value).length > 1
      ? Promise.reject(t(messageKey))
      : Promise.resolve()
});

const noRepeatedClient_v4 = (form: FormInstance, arrayPath: NamePath): Rule => noRepeatedValue_v4(form, arrayPath, "validation.noRepeatedClient");

const notNullIfOtherNull_v4 = (form: FormInstance, comparedFieldPath: NamePath, comparedFieldName: string): Rule => ({
  validator: (rule, value) =>
    !value && !form.getFieldValue(comparedFieldPath)
      ? Promise.reject(t("validation.notNullIfOtherNull", { fieldName: comparedFieldName }))
      : Promise.resolve()
});

const notFalseIfOtherFalse_v4 = (form: FormInstance, comparedFieldPath: NamePath, comparedFieldName: string): Rule => ({
  validator: (rule, value) =>
    !value && !form.getFieldValue(comparedFieldPath)
      ? Promise.reject(t("validation.notFalseIfOtherFalse", { fieldName: comparedFieldName }))
      : Promise.resolve()
});

export const validations = {
  none: none_v4,
  url: url_v4,
  email: email_v4,
  notNull: notNull_v4,
  notBlank: notBlank_v4,
  length: length_v4,
  size: size_v4,
  min: min_v4,
  max: max_v4,
  pattern: pattern_v4,
  minNumber: minNumber_v4,
  maxNumber: maxNumber_v4,
  notPresentAndFuture: notPresentAndFuture_v4,
  notFuture: notFuture_v4,
  notPresentAndPast: notPresentAndPast_v4,
  notPast: notPast_v4,
  notPresent: notPresent_v4,
  notBefore: notBefore_v4,
  notSameOrBefore: notSameOrBefore_v4,
  notAfter: notAfter_v4,
  notSameOrAfter: notSameOrAfter_v4,
  dateInInterval: dateInInterval_v4,
  multipleOf100: multipleOf100_v4,
  multipleOf1000: multipleOf1000_v4,
  iban: iban_v4,
  numeric: numeric_v4,
  phoneNumber: phoneNumber_v4,
  fixedLinePhoneNumber: fixedLinePhoneNumber_v4,
  mobilePhoneNumber: mobilePhoneNumber_v4,
  sufficientPassword: sufficientPassword_v4,
  repeatedPassword: repeatedPassword_v4,
  pin: pin_v4,
  crn: crn_v4,
  pinOrCrn: pinOrCrn_v4,
  licensePlate: licensePlate_v4,
  fullLicensePlate: fullLicensePlate_v4,
  academicDegree: academicDegree_v4,
  academicDegreeAfter: academicDegreeAfter_v4,
  commercialRegisterSection: commercialRegisterSection_v4,
  commercialRegisterDistrictOffice: commercialRegisterDistrictOffice_v4,
  noRepeatedValue: noRepeatedValue_v4,
  noRepeatedClient: noRepeatedClient_v4,
  notNullIfOtherNull: notNullIfOtherNull_v4,
  notFalseIfOtherFalse: notFalseIfOtherFalse_v4
}
