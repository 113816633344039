import React from "react";
import { FieldConstraintViolation } from "../../types";
import { tFieldName, tValidationMessage } from "../../utils/translationUtils";

export interface Props {
  violations: FieldConstraintViolation[];
  rootPath: string;
}

const FieldViolationsView = ({ violations, rootPath }: Props) => (
  violations && violations.length > 0 ? (
    <>
      {violations.map((violation, index) => (
        <span key={index}>
          <span><b>{tFieldName(rootPath, violation.fieldPath)}:</b> </span>
          <span>{violation.errors.map(error => tValidationMessage(rootPath, error)).join(" ")}</span><br />
        </span>
      ))}
    </>
  ) : null
);

export default FieldViolationsView;
