import React from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { CertificateVehicleColor } from "../../../contract/types";
import { RootState } from "../../../../common/types";
import { selectVehicleColorsEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props extends SelectProps<string> {
}

interface StateProps {
  vehicleColorsEnumerations: CertificateVehicleColor[];
}

type ComponentProps = Props & StateProps;

class VehicleColorEnumerationsSelect_Deprecated extends React.Component<ComponentProps> {

  render(): React.ReactNode {
    const { vehicleColorsEnumerations, ...selectProps } = this.props;

    return (
      <Select
        {...selectStandardProps} {...selectProps}
        options={vehicleColorsEnumerations.map(color => ({ value: color.id, label: color.value }))} />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  vehicleColorsEnumerations: selectVehicleColorsEnumerations(state)
});

export default connect<StateProps, {}, Props, ComponentProps, RootState>(mapStateToProps, null, null, { forwardRef: true })(VehicleColorEnumerationsSelect_Deprecated);
