import React from "react";
import { Moment } from "moment";
import ReactQuill from "react-quill";
import { Card, Col, DatePicker, Form, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { CreateUpdateLoanContract, LoanContract } from "../../../../types";
import { ContractReactionStatus, LoanRateType } from "../../../../enums";
import { Permission } from "../../../../../../common/security/authorization/enums";
import {
  calculateContactClientDate,
  calculateFixationAnniversaryDate,
  calculateLoanContractStatus
} from "../../../../utils";
import { validations } from "../../../../../../common/utils/validationUtils";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  disableDatePickerOutOfMaxDateIncluded,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  quillEditorStandardProps,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import ContractFormPersonsSection from "../../ContractFormPersonsSection";
import InputNumberWithAddon from "../../../../../../common/components/form/components/InputNumberWithAddon";
import ContractStatusTag from "../../../ContractStatusTag";

export interface Props {
  initialContract?: LoanContract;
  form: FormInstance;
}

const LoanContractFormDataSection = ({ initialContract, form }: Props) => {

  const handleSignDateChange = (): void => {
    setContractSpecificDates();
    handleStatusDefiningDateChange();
  };

  const handleStatusDefiningDateChange = (): void => {
    const { signDate, effectiveEndDate, cancellationDate } =
      form.getFieldsValue([["signDate"], ["effectiveEndDate"], ["cancellationDate"]]) as CreateUpdateLoanContract;
    form.setFieldsValue({
      status: calculateLoanContractStatus(signDate as Moment, effectiveEndDate as Moment, cancellationDate as Moment)
    });
  };

  const handleRateTypeChange = (): void => {
    setContractSpecificDates();
  };

  const handleFixationAnniversaryDateChange = (fixationAnniversaryDate: Moment): void => {
    form.setFieldsValue({ contactClientDate: calculateContactClientDate(fixationAnniversaryDate) });
  };

  const setContractSpecificDates = (): void => {
    const { signDate, rateType, fixationAnniversaryDate }
      = form.getFieldsValue([["signDate"], ["rateType"], ["fixationAnniversaryDate"]]) as CreateUpdateLoanContract;

    if ( rateType !== LoanRateType.VARIABLE || !fixationAnniversaryDate ) {
      const calculatedAnniversaryDate = calculateFixationAnniversaryDate(signDate as Moment, rateType);
      form.setFieldsValue({
        fixationAnniversaryDate: calculatedAnniversaryDate,
        contactClientDate: calculateContactClientDate(calculatedAnniversaryDate)
      });
    }
  };

  const colSpan = 4;

  return (
    <Card
      size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}
      extra={
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
          {({ getFieldValue }) => <ContractStatusTag status={getFieldValue("status")} />}
        </Form.Item>
      }>

      <ContractFormPersonsSection
        initialContract={initialContract}
        changePersonsPermission={Permission.CHANGE_PERSONS_ON_VERIFIED_LOAN} />

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="signDate"
            label={t("contract.attrs.signDate")}
            rules={[validations.notNull]}>
            <DatePicker {...datePickerStandardProps} onChange={handleSignDateChange} />
          </Form.Item>
        </Col>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
          {({ getFieldValue }) => {
            const signDate = getFieldValue("signDate") as Moment;
            const maturityDateRules: Rule[] = [validations.notNull];
            let endDateRules: Rule[] = [validations.none];
            if ( signDate ) {
              maturityDateRules.push(validations.notSameOrBefore(signDate, t("contract.attrs.signDate")));
              endDateRules = [validations.notBefore(signDate, t("contract.attrs.signDate"))];
            }
            return (
              <>
                <Col span={colSpan}>
                  <Form.Item
                    name="loanMaturityDate"
                    label={t("contract.attrs.loanMaturityDate")}
                    rules={maturityDateRules}>
                    <DatePicker
                      {...datePickerStandardProps}
                      disabledDate={current => signDate ? disableDatePickerOutOfMinDateIncluded(current, signDate) : false} />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name="effectiveEndDate"
                    label={t("contract.attrs.effectiveEndDate")}
                    rules={endDateRules}>
                    <DatePicker
                      {...datePickerClearableProps}
                      disabledDate={current => signDate ? disableDatePickerOutOfMinDate(current, signDate) : false}
                      onChange={handleStatusDefiningDateChange} />
                  </Form.Item>
                </Col>
              </>
            )
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.signDate !== next.signDate || prev.effectiveEndDate !== next.effectiveEndDate}>
          {({ getFieldValue }) => {
            const signDate = getFieldValue("signDate") as Moment;
            const endDate = getFieldValue("effectiveEndDate") as Moment;
            const rules = [];
            if ( signDate ) {
              rules.push(validations.notBefore(signDate, t("contract.attrs.signDate")));
            }
            if ( endDate ) {
              rules.push(validations.notSameOrAfter(endDate, t("contract.attrs.effectiveEndDate")));
            }
            return (
              <Col span={colSpan}>
                <Form.Item
                  name="cancellationDate"
                  label={t("contract.attrs.cancellationDate")}
                  rules={rules.length > 0 ? rules : [validations.none]}>
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current => (signDate && disableDatePickerOutOfMinDate(current, signDate))
                      || (endDate && disableDatePickerOutOfMaxDateIncluded(current, endDate))}
                    onChange={handleStatusDefiningDateChange} />
                </Form.Item>
              </Col>
            )
          }}
        </Form.Item>

        <Col span={colSpan}>
          <Form.Item
            name="approvedAmount"
            label={t("contract.attrs.approvedAmount")}
            rules={[validations.notNull, validations.minNumber(0)]}>
            <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0.01} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="monthlyPaymentAmount"
            label={t("contract.attrs.monthlyPaymentAmount")}
            rules={[validations.notNull, validations.minNumber(0)]}>
            <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0.01} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="ltvRatio"
            label={t("contract.attrs.ltvRatio")}
            rules={[validations.notNull, validations.minNumber(0), validations.maxNumber(100)]}>
            <InputNumberWithAddon addonType="percentage" formatStyle="decimal" min={0.01} max={100} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="interestRate"
            label={t("contract.attrs.interestRate")}
            rules={[validations.notNull, validations.minNumber(0), validations.maxNumber(100)]}>
            <InputNumberWithAddon addonType="percentage" formatStyle="decimal" min={0.01} max={100} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="rateType"
            label={t("contract.enums.loanRateType._label")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(LoanRateType).map(type => ({
                value: type, label: t("contract.enums.loanRateType." + type)
              }))}
              onChange={handleRateTypeChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.rateType !== next.rateType}>
            {({ getFieldValue }) => {
              const rateType = getFieldValue("rateType");
              return (
                <Form.Item
                  name="fixationAnniversaryDate"
                  label={t("contract.attrs.fixationAnniversaryDate")}
                  rules={[validations.notNull]}>
                  <DatePicker
                    {...datePickerStandardProps}
                    disabled={rateType && rateType !== LoanRateType.VARIABLE}
                    onChange={handleFixationAnniversaryDateChange} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="contactClientDate"
            label={t("contract.attrs.contactClientDate")}
            rules={[validations.notNull]}>
            <DatePicker {...datePickerStandardProps} disabled />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="reactionStatus"
            label={t("contract.enums.reactionStatus._label")}
            rules={[validations.notNull]}
            initialValue={ContractReactionStatus.CONTACT_CLIENT}>
            <Select
              {...selectStandardProps}
              options={Object.keys(ContractReactionStatus).map(status => ({
                value: status, label: t("contract.enums.reactionStatus." + status)
              }))} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="note"
            label={t("contract.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={null}>
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}

export default LoanContractFormDataSection;
