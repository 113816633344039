import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "connected-react-router";
import api from "./api";
import { changeLocationKeyAction } from "../ducks";
import { getEnumerationsActions } from "../enumerations/ducks";
import {
  CreateUpdatePerson,
  Person,
  PersonList,
  PersonReducerState,
  PersonsTreeRequest,
  PersonWithSubordinates
} from "./types";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  SearchPageRequest,
  SearchPageResult
} from "../../common/types";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../common/utils/reduxUtils";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'person/FILTER',
  GET_TREE = 'person/GET_TREE',
  GET = 'person/GET',
  CREATE = 'person/CREATE',
  UPDATE = 'person/UPDATE',
  DELETE = 'person/DELETE',
  DELETE_STATE_LIST = 'person/DELETE_STATE_LIST',
  DELETE_STATE_TREE = 'person/DELETE_STATE_TREE',
  DELETE_STATE_DETAIL = 'person/DELETE_STATE_DETAIL'
}

/**
 * ACTIONS
 */
export const filterPersonsActions = createApiActionCreators<SearchPageRequest, SearchPageResult<PersonList>>(actionType.FILTER);
export const getPersonsTreeActions = createApiActionCreators<PersonsTreeRequest, PersonWithSubordinates[]>(actionType.GET_TREE);
export const getPersonActions = createApiActionCreators<EntityIdRequest, Person>(actionType.GET);
export const createPersonActions = createApiActionCreators<CreateUpdatePerson, Person>(actionType.CREATE);
export const updatePersonActions = createApiActionCreators<EntityObjectRequest<CreateUpdatePerson>, Person>(actionType.UPDATE);
export const deletePersonActions = createApiActionCreators<EntityIdRequest, void>(actionType.DELETE);

export const deleteStatePersonsPageAction = createActionCreator<void>(actionType.DELETE_STATE_LIST);
export const deleteStatePersonsTreeAction = createActionCreator<void>(actionType.DELETE_STATE_TREE);
export const deleteStatePersonDetailAction = createActionCreator<void>(actionType.DELETE_STATE_DETAIL);

/**
 * REDUCERS
 */
const initialState: PersonReducerState = {
  currentPage: initSearchPageResult<PersonList>(),
  tree: [],
  personDetail: null
};

const currentPageReducer = createReducer<SearchPageResult<PersonList>>(initialState.currentPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentPage
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.currentPage
  },
  [actionType.DELETE_STATE_LIST]: () => initialState.currentPage
});

const treeReducer = createReducer<PersonWithSubordinates[]>(initialState.tree, {
  [actionType.GET_TREE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.tree
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.tree
  },
  [actionType.DELETE_STATE_TREE]: () => initialState.tree
});

const personDetailReducer = createReducer<Person>(initialState.personDetail, {
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.personDetail
  },
  [actionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.personDetail
  },
  [actionType.DELETE_STATE_DETAIL]: () => initialState.personDetail
});

export default combineReducers({
  currentPage: currentPageReducer,
  tree: treeReducer,
  personDetail: personDetailReducer
});

/**
 * SELECTORS
 */
const selectPerson = (state: RootState): PersonReducerState => state.person;

export const selectPersonsCurrentPage = (state: RootState): SearchPageResult<PersonList> => selectPerson(state).currentPage;
export const selectPersonsTree = (state: RootState): PersonWithSubordinates[] => selectPerson(state).tree;
export const selectPersonDetail = (state: RootState): Person => selectPerson(state).personDetail;

/**
 * SAGAS
 */
function* filterPersons({ payload }: ActionCreator<SearchPageRequest>) {
  try {
    const response: AxiosResponse<SearchPageResult<PersonList>> = yield call(api.filterPersons, payload);
    yield put(filterPersonsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterPersonsActions.failure(error));
  }
}

function* getPersonsTree({ payload }: ActionCreator<PersonsTreeRequest>) {
  try {
    const response: AxiosResponse<PersonWithSubordinates[]> = yield call(api.getPersonsTree, payload);
    yield put(getPersonsTreeActions.success(response.data));
  }
  catch ( error ) {
    yield put(getPersonsTreeActions.failure(error));
  }
}

function* getPersonDetail({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<Person> = yield call(api.getPerson, payload);
    yield put(getPersonActions.success(response.data));
  }
  catch ( error ) {
    yield put(getPersonActions.failure(error));
  }
}

function* createPerson({ payload }: ActionCreator<CreateUpdatePerson>) {
  try {
    const response: AxiosResponse<Person> = yield call(api.createPerson, payload);
    yield put(createPersonActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(replace("/persons/" + response.data.id));
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createPersonActions.failure(error));
  }
}

function* updatePerson({ payload }: ActionCreator<EntityObjectRequest<CreateUpdatePerson>>) {
  try {
    const response: AxiosResponse<Person> = yield call(api.updatePerson, payload);
    yield put(updatePersonActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updatePersonActions.failure(error));
  }
}

function* deletePerson({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deletePerson, payload);
    yield put(deletePersonActions.success());
    yield put(getEnumerationsActions.request());
    yield put(replace("/persons"));
  }
  catch ( error ) {
    yield put(deletePersonActions.failure(error));
  }
}

export function* personSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterPersons);
  yield takeLatest(createActionType(actionType.GET_TREE, apiOperation.REQUEST), getPersonsTree);
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getPersonDetail);
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createPerson);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updatePerson);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deletePerson);
}
