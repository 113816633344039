import React from "react";
import { Button, Col, Divider, Popconfirm, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { LegalPerson, NaturalPerson, Person, SelfEmployedPerson } from "../../../types";
import { PersonType } from "../../../enums";
import { Permission } from "../../../../../common/security/authorization/enums";
import { getOldCrmBaseUrl } from "../../../../../common/utils/utils";
import { formatPersonName } from "../../../../../common/utils/formatUtils";
import t from "../../../../../app/i18n";

import NaturalPersonDataView from "./NaturalPersonDataView";
import SelfEmployedPersonDataView from "./SelfEmployedPersonDataView";
import LegalPersonDataView from "./LegalPersonDataView";
import AddressView from "../../../../../common/modules/address/AddressView";
import ContactsView from "../../../../../common/modules/contact/ContactsView";
import PersonActiveTag from "../../PersonDeactivationTag";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";

export interface Props {
  person: Person;
  onUpdateClick(): void;
  onDeleteClick(): void;
}

const PersonDetailView = ({ person, onUpdateClick, onDeleteClick }: Props) => {

  const layout: ColProps = { span: 24, style: { maxWidth: "900px" } };

  let dataView;
  switch ( person.type ) {
    case PersonType.NATURAL:
      dataView = <NaturalPersonDataView person={person as NaturalPerson} />;
      break;
    case PersonType.SELF_EMPLOYED:
      dataView = <SelfEmployedPersonDataView person={person as SelfEmployedPerson} />;
      break;
    case PersonType.LEGAL:
      dataView = <LegalPersonDataView person={person as LegalPerson} />;
      break;
  }

  return (
    <>
      <Divider orientation="left">{t("person.titles.basicData")}</Divider>

      <Row>
        <Col {...layout}>
          <table className="data-table-view">
            <tbody>
            <tr>
              <td colSpan={4}><PersonActiveTag personDeactivated={person.deactivated} /></td>
            </tr>
            {person.oldCrmId && (
              <tr>
                <td>{t("person.attrs.oldCrmId")}:</td>
                <td colSpan={3}>
                  <a target="_blank" rel="noopener noreferrer"
                     href={`${getOldCrmBaseUrl()}/admin/person/${person.oldCrmId}`}>
                    {person.oldCrmId}
                  </a>
                </td>
              </tr>
            )}
            <tr>
              <td>{t("person.attrs.parentId")}:</td>
              <td>{formatPersonName(person.parent)}</td>
              <td>{t("person.attrs.nbsRegistrationNumber")}:</td>
              <td><CopyToClipboardView content={person.nbsRegistrationNumber} /></td>
            </tr>
            {dataView}
            </tbody>
          </table>
        </Col>
      </Row>

      <Divider orientation="left">{t("person.titles.addresses")}</Divider>

      <Row>
        <Col {...layout}>
          <table className="data-table-view">
            <tbody>
            <tr>
              <td>{person.type === PersonType.LEGAL ? t("person.attrs.addressLongPO") : t("person.attrs.addressLong")}:</td>
              <td><AddressView address={person.address} showCountry /></td>
            </tr>
            <tr>
              <td>{t("person.attrs.serviceAddress")}:</td>
              <td><AddressView address={person.serviceAddress} showCountry /></td>
            </tr>
            <tr>
              <td>{t("person.attrs.correspondenceAddress")}:</td>
              <td><AddressView address={person.correspondenceAddress} showCountry /></td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <Divider orientation="left">{t("person.titles.contacts")}</Divider>

      <Row>
        <Col {...layout}>
          <ContactsView contacts={person.contacts} showUseForNotifications />
        </Col>
      </Row>

      <div className="margin-top-large">
        <ComponentWithPermission permissions={[Permission.PERSON_UPDATE]}>
          <Button className="margin-right-tiny" type="primary" onClick={onUpdateClick} icon={<EditOutlined />}>
            {t("common.edit")}
          </Button>
        </ComponentWithPermission>

        <ComponentWithPermission permissions={[Permission.PERSON_DELETE]}>
          <Popconfirm
            title={t("person.titles.deleteConfirm", { aggregatedName: person.aggregatedName })}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={onDeleteClick}>
            <Button icon={<DeleteOutlined />} danger>{t("common.delete")}</Button>
          </Popconfirm>
        </ComponentWithPermission>
      </div>
    </>
  );
};

export default PersonDetailView;
