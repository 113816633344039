import React from "react";
import { Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CalcRecordFilterPageResult, CalcRecordList } from "../../types";
import {
  getCalcRecordAndRedirectToCalculatorActions,
  getCalcRecordRequestResponseActions,
  getCalcRecordStackTracesLogsActions,
  getCalcRecordWebservicesLogsActions
} from "../../ducks";
import { formatLocaleDateTime, formatPersonAccountName } from "../../../../../common/utils/formatUtils";
import { paginationStandardProps } from "../../../../../common/utils/formUtils";
import t from "../../../../../app/i18n";

import OperationTypeTag from "../OperationTypeTag";
import CalcTypeTag from "../CalcTypeTag";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";

export interface Props {
  calcRecordsPage: CalcRecordFilterPageResult;
  onRequestResponseLogsClick: typeof getCalcRecordRequestResponseActions.request;
  onWebserviceLogsClick: typeof getCalcRecordWebservicesLogsActions.request;
  onStackTraceLogsClick: typeof getCalcRecordStackTracesLogsActions.request;
  onShowInCalcFormClick: typeof getCalcRecordAndRedirectToCalculatorActions.request;
  onPageChange(pageNumber: number): void;
}

const CalcRecordsListTableView = (props: Props) => {

  const columns: ColumnsType<CalcRecordList> = [
    {
      key: "createdAt",
      title: t("calc.records.attrs.createdAt"),
      render: (text, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "account",
      title: t("calc.records.attrs.account"),
      render: (text, record) => formatPersonAccountName(record.account)
    },
    {
      key: "operationType",
      title: t("calc.records.enums.operationType._label"),
      render: (text, record) => <OperationTypeTag type={record.operationType} />
    },
    {
      key: "calcType",
      title: t("calc.enums.calcType._label"),
      render: (text, record) => <CalcTypeTag type={record.calcType} />
    },
    {
      key: "operations",
      className: "table-column-right-align",
      render: (text, record) => (
        <>
          <ActionTextIcon type="laptop" color="blue" text={t("calc.records.actions.showLogs")}
                          onClick={() => props.onRequestResponseLogsClick({ id: record.id })} />
          <Divider type="vertical" />

          <ActionTextIcon type="cloud" color="blue" text={t("calc.records.actions.showWebserviceLogs")}
                          disabled={!record.hasWebservicesLogs}
                          onClick={() => props.onWebserviceLogsClick({ id: record.id })} />
          <Divider type="vertical" />

          <ActionTextIcon type="exclamation" color="orange"
                          text={t("calc.records.actions.showStackTraceLogs")}
                          disabled={!record.hasStackTracesLogs}
                          onClick={() => props.onStackTraceLogsClick({ id: record.id })} />
          <Divider type="vertical" />

          <ActionTextIcon type="select" color="green" text={t("calc.records.actions.showInCalcForm")}
                          onClick={() => props.onShowInCalcFormClick({ id: record.id })} />
        </>
      )
    }
  ];

  return (
    <Table<CalcRecordList>
      size="small"
      className="table-row-stripped"
      rowKey="id"
      columns={columns}
      dataSource={props.calcRecordsPage.pageData}
      pagination={{
        ...paginationStandardProps,
        current: props.calcRecordsPage.pageIndex + 1,
        pageSize: props.calcRecordsPage.pageSize,
        total: props.calcRecordsPage.totalElementsCount,
        onChange: props.onPageChange,
      }} />
  )
};

export default CalcRecordsListTableView;
