export enum JobType {
  UPDATE_OUTDATED_STATUSES_AND_DATES_ON_CONTRACTS = 'UPDATE_OUTDATED_STATUSES_AND_DATES_ON_CONTRACTS',
  DELETE_CALC_RECORDS_OLDER_THAN_1_MONTH = 'DELETE_CALC_RECORDS_OLDER_THAN_1_MONTH',
  DELETE_CALC_DRAFTS_OLDER_THAN_6_MONTHS = 'DELETE_CALC_DRAFTS_OLDER_THAN_6_MONTHS',
  DELETE_JOB_RECORDS_OLDER_THAN_6_MONTHS = 'DELETE_JOB_RECORDS_OLDER_THAN_6_MONTHS',
  DEMO_LOG = 'DEMO_LOG'
}

export enum JobState {
  MANUALLY_SCHEDULED = 'MANUALLY_SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  ABORTED = 'ABORTED',
  TERMINATED_BY_ERROR = 'TERMINATED_BY_ERROR'
}