import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "connected-react-router";
import api from "./api";
import { CreateUpdateInstitution, Institution, InstitutionList, InstitutionReducerState } from "./types";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  SearchPageRequest,
  SearchPageResult
} from "../../../common/types";
import { getEnumerationsActions } from "../../enumerations/ducks";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'institution/FILTER',
  GET = 'institution/GET',
  CREATE = 'institution/CREATE',
  UPDATE = 'institution/UPDATE',
  DELETE = 'institution/DELETE',
  DELETE_STATE_LIST = 'institution/DELETE_STATE_LIST',
  DELETE_STATE_DETAIL = 'institution/DELETE_STATE_DETAIL'
}

/**
 * ACTIONS
 */
export const filterInstitutionsActions = createApiActionCreators<SearchPageRequest, SearchPageResult<InstitutionList>>(actionType.FILTER);
export const getInstitutionActions = createApiActionCreators<EntityIdRequest, Institution>(actionType.GET);
export const createInstitutionActions = createApiActionCreators<CreateUpdateInstitution, Institution>(actionType.CREATE);
export const updateInstitutionActions = createApiActionCreators<EntityObjectRequest<CreateUpdateInstitution>, Institution>(actionType.UPDATE);
export const deleteInstitutionActions = createApiActionCreators<EntityIdRequest, void>(actionType.DELETE);

export const deleteStateInstitutionsPageAction = createActionCreator<void>(actionType.DELETE_STATE_LIST);
export const deleteStateInstitutionDetailAction = createActionCreator<void>(actionType.DELETE_STATE_DETAIL);

/**
 * REDUCERS
 */
const initialState: InstitutionReducerState = {
  currentPage: initSearchPageResult<InstitutionList>(),
  institutionDetail: null
};

const currentPageReducer = createReducer<SearchPageResult<InstitutionList>>(initialState.currentPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentPage
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.currentPage
  },
  [actionType.DELETE_STATE_LIST]: () => initialState.currentPage
});

const institutionDetailReducer = createReducer<Institution>(initialState.institutionDetail, {
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.institutionDetail
  },
  [actionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.institutionDetail
  },
  [actionType.DELETE_STATE_DETAIL]: () => initialState.institutionDetail
});

export default combineReducers({
  currentPage: currentPageReducer,
  institutionDetail: institutionDetailReducer
});

/**
 * SELECTORS
 */
const selectInstitution = (state: RootState): InstitutionReducerState => state.admin.institution;

export const selectInstitutionsCurrentPage = (state: RootState): SearchPageResult<InstitutionList> => selectInstitution(state).currentPage;
export const selectInstitutionDetail = (state: RootState): Institution => selectInstitution(state).institutionDetail;

/**
 * SAGAS
 */
function* filterInstitutions({ payload }: ActionCreator<SearchPageRequest>) {
  try {
    const response: AxiosResponse<SearchPageResult<InstitutionList>> = yield call(api.filterInstitutions, payload);
    yield put(filterInstitutionsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterInstitutionsActions.failure(error));
  }
}

function* getInstitutionDetail({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<Institution> = yield call(api.getInstitution, payload);
    yield put(getInstitutionActions.success(response.data));
  }
  catch ( error ) {
    yield put(getInstitutionActions.failure(error));
  }
}

function* createInstitution({ payload }: ActionCreator<CreateUpdateInstitution>) {
  try {
    const response: AxiosResponse<Institution> = yield call(api.createInstitution, payload);
    yield put(createInstitutionActions.success(response.data));
    yield put(getEnumerationsActions.request());
    yield put(replace("/admin/institutions/" + response.data.id));
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createInstitutionActions.failure(error));
  }
}

function* updateInstitution({ payload }: ActionCreator<EntityObjectRequest<CreateUpdateInstitution>>) {
  try {
    const response: AxiosResponse<Institution> = yield call(api.updateInstitution, payload);
    yield put(updateInstitutionActions.success(response.data));
    yield put(getEnumerationsActions.request());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateInstitutionActions.failure(error));
  }
}

function* deleteInstitution({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteInstitution, payload);
    yield put(deleteInstitutionActions.success());
    yield put(getEnumerationsActions.request());
    yield put(replace("/admin/institutions/"));
  }
  catch ( error ) {
    yield put(deleteInstitutionActions.failure(error));
  }
}

export function* institutionSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterInstitutions);
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getInstitutionDetail);
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createInstitution);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateInstitution);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deleteInstitution);
}
