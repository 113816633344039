import React from "react";
import { Alert, Checkbox, Col, Divider, Row, Select, Switch } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { RealtyCalcRealtyInsuranceData } from "../../../types";
import {
  BuildingState,
  BuildingType,
  CivilLiabilityReinsurance,
  HouseholdEquipment,
  Liability,
  StructuralComponentsReinsuranceAmount
} from "../../../enums";
import { ClientType } from "../../../../../../client/enums";
import validations from "../../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import RealtyCalcReinsurancesSection from "./sections/RealtyCalcReinsurancesSection";
import InputNumberWithAddon from "../../../../../../../common/components/form/components/InputNumberWithAddon";

export interface Props {
  form: WrappedFormUtils;
  initialRealtyData: RealtyCalcRealtyInsuranceData;
  clientType: ClientType;
  buildingType: BuildingType;
  buildingState: BuildingState;
  hasInsurancesError: boolean;
  realtyInsuranceEnabled: boolean;
  householdInsuranceEnabled: boolean;
  structuralComponentsReinsuranceEnabled: boolean;
  onRealtyInsuranceEnabledChange(checked: boolean): void;
  onHouseholdInsuranceEnabledChange(checked: boolean): void;
  onStructuralComponentsReinsuranceEnabledChange(event: CheckboxChangeEvent): void;
}

const RealtyCalcHouseholdRealtyStep = ({
                                         form, initialRealtyData, clientType, buildingType, buildingState,
                                         hasInsurancesError, realtyInsuranceEnabled, householdInsuranceEnabled,
                                         structuralComponentsReinsuranceEnabled, ...props
                                       }: Props) => {
  const { getFieldDecorator } = form;
  const colSpan = 8;

  const realtyFormItems = realtyInsuranceEnabled ? (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item label={t("calc.realty.attrs.realtyInsuranceData.insuranceAmount")}>
            {getFieldDecorator("realtyInsuranceData.insuranceAmount", {
              rules: [validations.notNull, validations.multipleOf1000]
            })(
              <InputNumberWithAddon addonType="euro" formatStyle="integer" min={1000} step={1000} />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item className="form-item-without-label">
            {getFieldDecorator("realtyInsuranceData.expertTestimony", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.realtyInsuranceData.expertTestimony")}</Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item className="form-item-without-label">
            {getFieldDecorator("realtyInsuranceData.usedByOwner", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.realtyInsuranceData.usedByOwner")}</Checkbox>
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item className="form-item-without-label">
            {getFieldDecorator("realtyInsuranceData.usedByRelative", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.realtyInsuranceData.usedByRelative")}</Checkbox>
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item className="form-item-without-label">
            {getFieldDecorator("realtyInsuranceData.usedByOther", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.realtyInsuranceData.usedByOther")}</Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>

      {buildingType === BuildingType.APARTMENT_IN_APARTMENT_BUILDING || buildingType === BuildingType.APARTMENT_IN_FAMILY_HOUSE ? null : (
        <div className="margin-bottom-medium">
          <Divider className="divider-subheader">{t("calc.realty.sections.realtyReinsurances")}</Divider>
          <RealtyCalcReinsurancesSection
            form={form}
            initialReinsurances={initialRealtyData ? initialRealtyData.nearbyBuildingReinsurances : null} />
        </div>
      )}
    </>
  ) : null;

  let householdFormItems = null;
  if ( buildingState === BuildingState.UNDER_CONSTRUCTION ) {
    householdFormItems =
      <Alert message={t("calc.realty.helpers.householdInsuranceDisabledBuildingType")} type="info" showIcon />;
  }
  else if ( clientType !== ClientType.NATURAL ) {
    householdFormItems =
      <Alert message={t("calc.realty.helpers.householdInsuranceDisabledClientType")} type="info" showIcon />;
  }
  else if ( householdInsuranceEnabled ) {
    householdFormItems = (
      <>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.enums.householdEquipment._label")}>
              {getFieldDecorator("householdInsuranceData.equipment", { rules: [validations.none] })(
                <Select
                  {...selectStandardProps} allowClear
                  options={Object.keys(HouseholdEquipment).map(equipment => ({
                    value: equipment,
                    label: t("calc.realty.enums.householdEquipment." + equipment)
                  }))} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.householdInsuranceData.insuranceAmount")}>
              {getFieldDecorator("householdInsuranceData.insuranceAmount", {
                rules: [validations.notNull, validations.multipleOf1000]
              })(
                <InputNumberWithAddon addonType="euro" formatStyle="integer" min={1000} step={1000} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Divider className="divider-subheader">{t("calc.realty.sections.householdReinsurances")}</Divider>

          <Col span={colSpan}>
            <Form.Item className="form-item-without-label">
              <Checkbox
                disabled={realtyInsuranceEnabled || (buildingType !== BuildingType.APARTMENT_IN_APARTMENT_BUILDING && buildingType !== BuildingType.APARTMENT_IN_FAMILY_HOUSE)}
                checked={structuralComponentsReinsuranceEnabled}
                onChange={props.onStructuralComponentsReinsuranceEnabledChange}>
                <LabelWithTooltip
                  label={t("calc.realty.attrs.householdInsuranceData.structuralComponentsReinsurance")}
                  tooltip={t("calc.realty.helpers.structuralComponentsReinsurance")} />
              </Checkbox>
            </Form.Item>
          </Col>
          {structuralComponentsReinsuranceEnabled ? (
            <Col span={colSpan}>
              <Form.Item label={t("calc.realty.enums.structuralComponents._label")}>
                {getFieldDecorator("householdInsuranceData.structuralComponentsReinsuranceAmount", { rules: [validations.notNull] })(
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(StructuralComponentsReinsuranceAmount).map(components => ({
                      value: components,
                      label: t("calc.realty.enums.structuralComponents." + components)
                    }))} />
                )}
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      </>
    );
  }

  return (
    <>
      {hasInsurancesError ? (
        <Row className="margin-bottom-medium">
          <Alert type="error" showIcon message={t("calc.realty.validations.noInsuranceSelected")} />
        </Row>
      ) : null}

      <Row gutter={rowGutter}>
        <Divider>
          <span className="margin-right-small">{t("calc.realty.sections.realtyInsurance")}</span>
          <Switch
            checked={realtyInsuranceEnabled}
            onChange={props.onRealtyInsuranceEnabledChange}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />} />
        </Divider>
      </Row>
      {realtyFormItems}

      <Row gutter={rowGutter}>
        <Divider>
          <span className="margin-right-small">{t("calc.realty.sections.householdInsurance")}</span>
          <Switch
            checked={householdInsuranceEnabled}
            disabled={buildingState === BuildingState.UNDER_CONSTRUCTION || clientType !== ClientType.NATURAL}
            onChange={props.onHouseholdInsuranceEnabledChange}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />} />
        </Divider>
      </Row>
      {householdFormItems}

      <Row gutter={rowGutter} className="margin-top-medium">
        <Divider>{t("calc.realty.sections.liability")}</Divider>

        <Col span={colSpan}>
          <Form.Item label={realtyInsuranceEnabled
            ? householdInsuranceEnabled
              ? t("calc.realty.attrs.liabilityInsuranceData.liabilityFromRealtyAndHousehold")
              : t("calc.realty.attrs.liabilityInsuranceData.liabilityFromRealty")
            : householdInsuranceEnabled
              ? t("calc.realty.attrs.liabilityInsuranceData.liabilityFromHousehold")
              : t("calc.realty.enums.liability._label")}>
            {getFieldDecorator("liabilityInsuranceData.liability", { rules: [validations.none] })(
              <Select
                {...selectStandardProps} allowClear
                options={Object.keys(Liability).map(liability => ({
                  value: liability,
                  label: t("calc.realty.enums.liability." + liability)
                }))} />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} className="margin-top-medium">
        <Divider>{t("calc.realty.sections.reinsurances")}</Divider>

        <Col span={colSpan}>
          <Form.Item label={<LabelWithTooltip label={t("calc.realty.enums.civilLiability._label")}
                                              tooltip={t("calc.realty.helpers.civilLiabilityReinsurance")} />}>
            {getFieldDecorator("generalInsuranceData.civilLiabilityReinsurance", { rules: [validations.none] })(
              <Select
                {...selectStandardProps} allowClear
                disabled={clientType !== ClientType.NATURAL}
                options={Object.keys(CivilLiabilityReinsurance).map(liability => ({
                  value: liability,
                  label: t("calc.realty.enums.civilLiability." + liability)
                }))} />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item>
            {getFieldDecorator("generalInsuranceData.floodReinsurance", {
              rules: [validations.none], valuePropName: "checked", initialValue: true
            })(
              <Checkbox>{t("calc.realty.attrs.generalInsuranceData.floodReinsurance")}</Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item>
            {getFieldDecorator("generalInsuranceData.glassReinsurance", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.generalInsuranceData.glassReinsurance")}</Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item>
            {getFieldDecorator("generalInsuranceData.earthquakeReinsurance", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.generalInsuranceData.earthquakeReinsurance")}</Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item>
            {getFieldDecorator("generalInsuranceData.cyberneticReinsurance", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.generalInsuranceData.cyberneticReinsurance")}</Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default RealtyCalcHouseholdRealtyStep;
