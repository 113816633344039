import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { replace } from "connected-react-router";
import { Modal, Tabs } from "antd";
import { HistoryOutlined, KeyOutlined, SolutionOutlined } from "@ant-design/icons";
import { CreateUpdatePerson, Person } from "../types";
import { ActionProps, RootState } from "../../../common/types";
import { AdminPersonAccount } from "../../account/types";
import { Permission } from "../../../common/security/authorization/enums";
import {
  deletePersonActions,
  deleteStatePersonDetailAction,
  getPersonActions,
  selectPersonDetail,
  updatePersonActions
} from "../ducks";
import { selectRouterLocationKey, selectRouterLocationSearchParam } from "../../ducks";
import {
  createPersonAccountActions,
  deleteStatePersonAccountsAction,
  getPersonAccountsActions,
  selectPersonAccounts,
  updatePersonAccountActions
} from "../../account/ducks";
import { selectPermissions } from "../../auth/ducks";
import { appendSearchParamsToCurrentPathname, hasPermission } from "../../../common/utils/utils";
import { serializeParams } from "../../../common/utils/apiUtils";
import { requests } from "../api";
import t from "../../../app/i18n";

import PersonDetailView from "../components/views/detail/PersonDetailView";
import PersonUpdateForm from "../components/forms/PersonUpdateForm";
import PersonAccountsPage from "../../account/components/pages/PersonAccountsPage";
import HistoryView from "../../../common/modules/history/HistoryView";
import PersonTypeTag from "../components/PersonTypeTag";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";

interface StateProps {
  person: Person;
  personAccounts: AdminPersonAccount[];
  hasPersonAccountsPermission: boolean;
  initialUrlActiveTab: string;
  locationKey: string;
}

interface ActionsMap {
  getPerson: typeof getPersonActions.request;
  updatePerson: typeof updatePersonActions.request;
  deletePerson: typeof deletePersonActions.request;
  deleteStatePersonDetail: typeof deleteStatePersonDetailAction;
  getPersonAccounts: typeof getPersonAccountsActions.request;
  createPersonAccount: typeof createPersonAccountActions.request;
  updatePersonAccount: typeof updatePersonAccountActions.request;
  deleteStatePersonAccounts: typeof deleteStatePersonAccountsAction;
  replaceNavigation: typeof replace;
}

type Props = StateProps & ActionProps<ActionsMap> & RouteComponentProps;

interface State {
  readonly activeTabKey: string;
  readonly personUpdateMode: boolean;
  readonly accountsFetchNeeded: boolean;
}

const TAB = {
  PERSON: "1",
  PERSON_HISTORY: "2",
  PERSON_ACCOUNTS: "3"
};

class PersonDetailContainer extends React.Component<Props, State> {
  readonly state: State = {
    activeTabKey: TAB.PERSON,
    personUpdateMode: false,
    accountsFetchNeeded: false
  };

  handleActiveTabChange = (activeTabKey: string): void => {
    if ( this.state.activeTabKey === TAB.PERSON && this.state.personUpdateMode ) {
      Modal.confirm({
        title: t("person.helpers.unsavedChanges"),
        okText: t("person.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => this.moveViewToTab(activeTabKey)
      });
    }
    else {
      this.moveViewToTab(activeTabKey)
    }
  };

  handlePersonUpdateModeToggle = (): void => {
    this.setState(previousState => ({ personUpdateMode: !previousState.personUpdateMode }));
  };

  handlePersonDeleteClick = (): void => {
    this.props.actions.deletePerson({ id: this.props.person.id });
  };

  handlePersonUpdateFormSubmit = (personData: CreateUpdatePerson): void => {
    const { person } = this.props;
    this.props.actions.updatePerson({ id: person.id, object: personData });

    if ( this.props.hasPersonAccountsPermission && person.parent && person.parent.id !== personData.parentId ) {
      this.setState({ accountsFetchNeeded: true });
    }
  };

  moveViewToTab = (activeTabKey: string): void => {
    this.setState({ activeTabKey });
    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(serializeParams({ activeTab: activeTabKey })));
  };

  componentDidMount(): void {
    if ( !this.props.person ) {
      this.props.actions.getPerson({ id: this.props.match.params["id"] });
    }
    if ( this.props.hasPersonAccountsPermission ) {
      this.props.actions.getPersonAccounts({ id: this.props.match.params["id"] });
    }
    this.setState({
      activeTabKey: this.props.initialUrlActiveTab === TAB.PERSON_ACCOUNTS
        ? this.props.hasPersonAccountsPermission ? TAB.PERSON_ACCOUNTS : TAB.PERSON
        : this.props.initialUrlActiveTab
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( this.state.personUpdateMode ) {
      if ( this.props.locationKey !== prevProps.locationKey ) {
        if ( this.state.accountsFetchNeeded ) {
          this.setState({ personUpdateMode: false, accountsFetchNeeded: false });
          this.props.actions.getPersonAccounts({ id: this.props.match.params["id"] });
        }
        else {
          this.setState({ personUpdateMode: false });
        }
      }
    }
  }

  componentWillUnmount(): void {
    this.props.actions.deleteStatePersonDetail();
    this.props.actions.deleteStatePersonAccounts();
  }

  render(): React.ReactNode {
    const { person } = this.props;

    return (
      <DisplayWrapper itemLoaded={!!person} notFoundCheckRequest={requests.GET_PERSON}>
        {person && (
          <>
            <h2 className="left-float">{person.aggregatedName}</h2>
            <PersonTypeTag style={{ margin: "6px 0 0 8px" }} type={person.type} />

            <ItemCreatedUpdatedInfoView item={person} className="clear-both margin-bottom-medium" />

            <Tabs activeKey={this.state.activeTabKey} onChange={this.handleActiveTabChange}>
              <Tabs.TabPane key={TAB.PERSON} tab={<span><SolutionOutlined />{t("person.titles.data")}</span>}>
                {this.state.personUpdateMode ? (
                  <PersonUpdateForm
                    person={person}
                    onFormSubmit={this.handlePersonUpdateFormSubmit}
                    onCancelClick={this.handlePersonUpdateModeToggle} />
                ) : (
                  <PersonDetailView
                    person={person}
                    onUpdateClick={this.handlePersonUpdateModeToggle}
                    onDeleteClick={this.handlePersonDeleteClick} />
                )}
              </Tabs.TabPane>

              <Tabs.TabPane key={TAB.PERSON_HISTORY}
                            tab={<span><HistoryOutlined />{t("person.titles.history")}</span>}>
                <HistoryView item={person} translationRootPath="person.attrs" type="person" />
              </Tabs.TabPane>

              {this.props.hasPersonAccountsPermission && (
                <Tabs.TabPane key={TAB.PERSON_ACCOUNTS}
                              tab={<span><KeyOutlined />{t("person.titles.accounts")}</span>}>
                  <PersonAccountsPage
                    accounts={this.props.personAccounts}
                    person={person}
                    onCreateFormSubmit={this.props.actions.createPersonAccount}
                    onUpdateFormSubmit={this.props.actions.updatePersonAccount} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </>
        )}
      </DisplayWrapper>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  person: selectPersonDetail(state),
  personAccounts: selectPersonAccounts(state),
  hasPersonAccountsPermission: hasPermission(selectPermissions(state), Permission.PERSON_ACCOUNTS),
  initialUrlActiveTab: selectRouterLocationSearchParam(state, "activeTab") || TAB.PERSON,
  locationKey: selectRouterLocationKey(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getPerson: getPersonActions.request,
    updatePerson: updatePersonActions.request,
    deletePerson: deletePersonActions.request,
    deleteStatePersonDetail: deleteStatePersonDetailAction,
    getPersonAccounts: getPersonAccountsActions.request,
    createPersonAccount: createPersonAccountActions.request,
    updatePersonAccount: updatePersonAccountActions.request,
    deleteStatePersonAccounts: deleteStatePersonAccountsAction,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(PersonDetailContainer);
