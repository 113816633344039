import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { Tabs } from "antd";
import { KeyOutlined, SolutionOutlined } from "@ant-design/icons";
import { MyIdentity } from "../types";
import { ActionProps, RootState } from "../../../common/types";
import { ValidationErrorResponse } from "../../types";
import {
  deleteStateMyIdentityCurrentUserAction,
  getMyIdentityActions,
  selectMyIdentityCurrentUser,
  updateMyIdentityActions
} from "../ducks";
import {
  deleteStateValidationErrorResponseAction,
  selectIsRequestInProgress,
  selectRouterLocationKey,
  selectRouterLocationSearchParam,
  selectValidationErrorResponse
} from "../../ducks";
import { appendSearchParamsToCurrentPathname } from "../../../common/utils/utils";
import { serializeParams } from "../../../common/utils/apiUtils";
import { requests } from "../api";
import t from "../../../app/i18n";

import MyIdentityPersonView from "../components/MyIdentityPersonView";
import MyIdentityAccountView from "../components/MyIdentityAccountView";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";

interface StateProps {
  currentUser: MyIdentity;
  updateMyIdentityErrorResponse: ValidationErrorResponse;
  updateMyIdentityAccountRequestInProgress: boolean;
  initialUrlActiveTab: string;
  locationKey: string;
}

interface ActionsMap {
  getMyIdentity: typeof getMyIdentityActions.request;
  updateMyIdentity: typeof updateMyIdentityActions.request;
  deleteStateMyIdentityCurrentUser: typeof deleteStateMyIdentityCurrentUserAction;
  deleteStateValidationErrorResponse: typeof deleteStateValidationErrorResponseAction;
  replaceNavigation: typeof replace;
}

interface State {
  readonly activeTabKey: string;
}

const TAB = { USER: "1", ACCOUNT: "2" };

class MyIdentityContainer extends React.Component<StateProps & ActionProps<ActionsMap>, State> {
  readonly state: State = {
    activeTabKey: TAB.USER
  };

  handleActiveTabChange = (activeTabKey: string): void => {
    this.setState({ activeTabKey });
    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(serializeParams({ activeTab: activeTabKey })));
  };

  componentDidMount(): void {
    this.props.actions.getMyIdentity();
    this.setState({ activeTabKey: this.props.initialUrlActiveTab });
  }

  componentWillUnmount(): void {
    this.props.actions.deleteStateMyIdentityCurrentUser();
  }

  render(): React.ReactNode {
    const { currentUser } = this.props;

    return (
      <DisplayWrapper itemLoaded={!!currentUser} notFoundCheckRequest={requests.GET_MY_IDENTITY}>
        {currentUser && (
          <>
            <h2>{currentUser.person ? currentUser.person.aggregatedName : currentUser.client.aggregatedName}</h2>

            <Tabs activeKey={this.state.activeTabKey} onChange={this.handleActiveTabChange}>
              <Tabs.TabPane key={TAB.USER} tab={<span><SolutionOutlined />{t("myIdentity.titles.userData")}</span>}>
                {currentUser.person && <MyIdentityPersonView person={currentUser.person} />}
              </Tabs.TabPane>

              <Tabs.TabPane key={TAB.ACCOUNT}
                            tab={<span><KeyOutlined />{t("myIdentity.titles.accountData")}</span>}>
                <MyIdentityAccountView
                  account={currentUser.account}
                  userContacts={currentUser.person
                    ? currentUser.person.contacts ? currentUser.person.contacts : []
                    : currentUser.client.contacts ? currentUser.client.contacts : []}
                  updateAccountErrorResponse={this.props.updateMyIdentityErrorResponse}
                  updateAccountRequestInProgress={this.props.updateMyIdentityAccountRequestInProgress}
                  locationKey={this.props.locationKey}
                  onUpdateAccountFormSubmit={this.props.actions.updateMyIdentity}
                  onErrorResponseDelete={this.props.actions.deleteStateValidationErrorResponse} />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </DisplayWrapper>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: selectMyIdentityCurrentUser(state),
  updateMyIdentityErrorResponse: selectValidationErrorResponse(state, requests.UPDATE_MY_IDENTITY),
  updateMyIdentityAccountRequestInProgress: selectIsRequestInProgress(state, requests.UPDATE_MY_IDENTITY),
  initialUrlActiveTab: selectRouterLocationSearchParam(state, "activeTab") || TAB.USER,
  locationKey: selectRouterLocationKey(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getMyIdentity: getMyIdentityActions.request,
    updateMyIdentity: updateMyIdentityActions.request,
    deleteStateMyIdentityCurrentUser: deleteStateMyIdentityCurrentUserAction,
    deleteStateValidationErrorResponse: deleteStateValidationErrorResponseAction,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(MyIdentityContainer);
