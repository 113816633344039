import React, { useEffect } from "react";
import { Button, Checkbox, Col, Form, Input, Popconfirm, Row, Select } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { CreateUpdateInstitution, Institution } from "../../types";
import { InstitutionEnum, InstitutionType } from "../../enums";
import { deleteInstitutionActions, updateInstitutionActions } from "../../ducks";
import { selectStandardProps, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../common/constants";
import { requests } from "../../api";
import t from "../../../../../app/i18n";

import AddressForm from "../../../../../common/modules/address/AddressForm";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  institution: Institution;
  onUpdateSubmit: typeof updateInstitutionActions.request;
  onDeleteClick: typeof deleteInstitutionActions.request;
}

const InstitutionUpdateForm = ({ institution, onUpdateSubmit, onDeleteClick }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "admin.institution.attrs", requests.UPDATE_INSTITUTION);

  useEffect(() => form.setFieldsValue(institution), [institution, form]);

  const handleFormFinish = (values: CreateUpdateInstitution): void => {
    onUpdateSubmit({ id: institution.id, object: values });
  };

  const handleDeleteClick = (): void => {
    onDeleteClick({ id: institution.id });
  };

  const colSpan = 6;

  return (
    <>
      <h2>{t("admin.institution.titles.update")}</h2>
      <ItemCreatedUpdatedInfoView item={institution} className="margin-bottom-large" />

      <Form form={form} layout="vertical" className="standard-container" name="institutionUpdateForm"
            onFinish={handleFormFinish}>

        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="type"
              label={t("admin.institution.enums.institutionType._label")}
              rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(InstitutionType).map(type => ({
                  value: type,
                  label: t("admin.institution.enums.institutionType." + type)
                }))} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="institutionEnum"
              label={t("admin.institution.enums.institutionEnum._label")}
              rules={[validations.none]}>
              <Select
                {...selectStandardProps} allowClear
                options={Object.keys(InstitutionEnum).map(institutionEnum => ({
                  value: institutionEnum,
                  label: t("admin.institution.enums.institutionEnum." + institutionEnum)
                }))} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="deactivated"
              valuePropName="checked"
              className="form-item-without-label"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox>
                <LabelWithTooltip label={t("admin.institution.attrs.deactivated")}
                                  tooltip={t("admin.institution.helpers.deactivatedDesc")} />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("admin.institution.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 64)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <Form.Item
              name="fullName"
              label={t("admin.institution.attrs.fullName")}
              rules={[validations.notBlank, validations.size(1, 1024)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="companyRegistrationNumber"
              label={t("admin.institution.attrs.companyRegistrationNumber")}
              rules={[validations.notBlank, validations.crn]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <AddressForm form={form} rootKey={["address"]} label={t("admin.institution.attrs.address")} />
          </Col>
        </Row>

        <div className="margin-top-medium">
          <Button className="margin-right-tiny" type="primary" htmlType="submit" icon={<SaveOutlined />}>
            {t("common.save")}
          </Button>

          <Popconfirm
            title={t("admin.institution.titles.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={handleDeleteClick}>
            <Button icon={<DeleteOutlined />} danger>{t("common.delete")}</Button>
          </Popconfirm>
        </div>

      </Form>
    </>
  );
}

export default InstitutionUpdateForm;
