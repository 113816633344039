import React from "react";
import { Button, Col, Divider, Popconfirm, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Client, LegalClient, NaturalClient, SelfEmployedClient } from "../../../types";
import { ClientType } from "../../../enums";
import { Permission } from "../../../../../common/security/authorization/enums";
import t from "../../../../../app/i18n";

import NaturalClientDataView from "./NaturalClientDataView";
import SelfEmployedClientDataView from "./SelfEmployedClientDataView";
import LegalClientDataView from "./LegalClientDataView";
import AddressView from "../../../../../common/modules/address/AddressView";
import ContactsView from "../../../../../common/modules/contact/ContactsView";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  client: Client;
  onUpdateClick?(): void;
  onDeleteClick?(): void;
}

const ClientDetailView = ({ client, onUpdateClick, onDeleteClick }: Props) => {

  const layout: ColProps = { span: 24, style: { maxWidth: "900px" } };

  let dataView;
  switch ( client.type ) {
    case ClientType.NATURAL:
      dataView = <NaturalClientDataView client={client as NaturalClient} />;
      break;
    case ClientType.SELF_EMPLOYED:
      dataView = <SelfEmployedClientDataView client={client as SelfEmployedClient} />;
      break;
    case ClientType.LEGAL:
      dataView = <LegalClientDataView client={client as LegalClient} />;
      break;
  }

  return (
    <>
      <Divider orientation="left">{t("client.titles.basicData")}</Divider>

      <Row>
        <Col {...layout}>
          {dataView}
        </Col>
      </Row>

      <Divider orientation="left">{t("client.titles.addresses")}</Divider>

      <Row>
        <Col {...layout}>
          <table className="data-table-view">
            <tbody>
            <tr>
              <td>{client.type === ClientType.LEGAL ? t("client.attrs.addressLongPO") : t("client.attrs.addressLong")}:</td>
              <td><AddressView address={client.address} showCountry /></td>
            </tr>
            <tr>
              <td>{t("client.attrs.correspondenceAddress")}:</td>
              <td><AddressView address={client.correspondenceAddress} showCountry /></td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <Divider orientation="left">{t("client.titles.contacts")}</Divider>

      <Row>
        <Col {...layout}>
          <ContactsView contacts={client.contacts} />
        </Col>
      </Row>

      <div className="margin-top-large">
        {onUpdateClick && (
          <ComponentWithPermission permissions={[Permission.CLIENT_UPDATE]}>
            <Button className="margin-right-tiny" type="primary" onClick={onUpdateClick} icon={<EditOutlined />}>
              {t("common.edit")}
            </Button>
          </ComponentWithPermission>)}

        {onDeleteClick && (
          <ComponentWithPermission permissions={[Permission.CLIENT_DELETE]}>
            <Popconfirm
              title={t("client.titles.deleteConfirm", { aggregatedName: client.aggregatedName })}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={onDeleteClick}>
              <Button icon={<DeleteOutlined />} danger>{t("common.delete")}</Button>
            </Popconfirm>
          </ComponentWithPermission>)}
      </div>
    </>
  );
};

export default ClientDetailView;
