import React from "react";
import { Tag } from "antd";
import { ClientType } from "../enums";
import t from "../../../app/i18n";

export interface Props {
  type: ClientType;
  style?: React.CSSProperties;
}

const ClientTypeTag = ({ type, style }: Props) => {
  let color: string;

  switch ( type ) {
    case ClientType.NATURAL:
      color = "blue";
      break;
    case ClientType.SELF_EMPLOYED:
      color = "gold";
      break;
    case ClientType.LEGAL:
      color = "purple";
      break;
    default:
      return null;
  }

  return <Tag style={style} color={color}>{t("client.enums.type." + type)}</Tag>;
};

export default ClientTypeTag;
