import React from "react";
import { Link } from "react-router-dom";
import { Location, LocationDescriptor } from "history";
import { Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { LogoutOutlined } from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { Permission } from "../../common/security/authorization/enums";
import { logoutAction } from "../../modules/auth/ducks";
import {
  getOldCrmBaseUrl,
  hasAllPermissions,
  hasAnyPermission,
  hasPermission,
  parseFirstPartOfPathname
} from "../../common/utils/utils";
import t from "../i18n";

export interface Props {
  location: Location;
  userAuthenticated: boolean;
  permissions: Permission[];
  onLogout: typeof logoutAction;
}

interface State {
  readonly currentSelectedKey: string;
}

export interface MenuItemLinkProps extends MenuItemProps {
  to: LocationDescriptor;
  label: string;
}

const MenuItemLink = ({ to, label, ...menuItemProps }: MenuItemLinkProps) => {
  return <Menu.Item {...menuItemProps}><Link to={to}>{label}</Link></Menu.Item>
};

class HeaderMenu extends React.Component<Props, State> {
  private static readonly LOGOUT_KEY = "logout";

  readonly state: State = {
    currentSelectedKey: parseFirstPartOfPathname(this.props.location.pathname)
  };

  handleMenuItemClick = (clickInfo: MenuInfo): void => {
    const key = clickInfo.key as string;
    if ( key === HeaderMenu.LOGOUT_KEY ) {
      this.setState({ currentSelectedKey: null });
      this.props.onLogout();
    }
    else if ( key.startsWith("/old") ) {
      window.open(getOldCrmBaseUrl() + key.replace("old-", ""), "_self");
    }
    else if ( key !== this.props.location.pathname ) {
      this.setState({ currentSelectedKey: key });
    }
  };

  createMenuItems = (): React.ReactNode => {
    const { permissions } = this.props;
    const menuItems = [];

    /*menuItems.push(<MenuItemLink key="/" to={this.resolveLocationDescriptor("/")}
                                 label={t("navigation.headerMenu.dashboard")} />);*/

    if ( hasAnyPermission(permissions,
      [Permission.MTPL_CALCULATOR, Permission.CRASH_CALCULATOR, Permission.REALTY_CALCULATOR, Permission.TRAVEL_CALCULATOR]) ) {
      menuItems.push(<MenuItemLink key="/calc" to={this.resolveLocationDescriptor(this.resolveCalcPath())}
                                   label={t("navigation.headerMenu.calc")} />);
    }

    if ( hasAllPermissions(permissions, [Permission.CLIENT, Permission.ADMIN]) ) {
      menuItems.push(<MenuItemLink key="/clients" to={this.resolveLocationDescriptor("/clients")}
                                   label={t("navigation.headerMenu.clients")} />);
    }

    if ( hasPermission(permissions, Permission.CLIENT) ) {
      menuItems.push(<MenuItemLink key="/old-clients" to="/old-clients"
                                   label={t("navigation.headerMenu.clientsOld")} />);
    }

    if ( hasAnyPermission(permissions, [Permission.INSURANCE, Permission.LOAN]) && hasPermission(permissions, Permission.ADMIN) ) {
      menuItems.push(<MenuItemLink key="/contracts" to={this.resolveLocationDescriptor("/contracts")}
                                   label={t("navigation.headerMenu.contracts")} />);
    }

    if ( hasAnyPermission(permissions, [Permission.INSURANCE, Permission.LOAN]) ) {
      menuItems.push(<MenuItemLink key="/old-contracts" to="/old-contracts"
                                   label={t("navigation.headerMenu.contractsOld")} />);
    }

    if ( hasPermission(permissions, Permission.ADMIN) ) {
      menuItems.push(<MenuItemLink key="/reports" to={this.resolveLocationDescriptor("/reports")}
                                   label={t("navigation.headerMenu.reports")} />);
    }

    if ( hasPermission(permissions, Permission.PERSON) ) {
      menuItems.push(<MenuItemLink key="/persons" to={this.resolveLocationDescriptor("/persons")}
                                   label={t("navigation.headerMenu.persons")} />);
    }

    if ( hasPermission(permissions, Permission.COMMISSIONS) ) {
      menuItems.push(<MenuItemLink key="/commissions" to={this.resolveLocationDescriptor(this.resolveCommissionsPath())}
                                   label={t("navigation.headerMenu.commissions")} />);
    }

    if ( hasAnyPermission(permissions,
      [Permission.ADMIN_CONTRACT_UPDATES, Permission.ADMIN_JOBS, Permission.ADMIN_ENUMERATIONS]) ) {
      menuItems.push(<MenuItemLink key="/admin" to={this.resolveLocationDescriptor(this.resolveAdminPath())}
                                   label={t("navigation.headerMenu.admin")} />);
    }

    menuItems.push(
      <Menu.Item key={HeaderMenu.LOGOUT_KEY} className="ant-menu-item-logout"><LogoutOutlined /></Menu.Item>
    );

    return menuItems;
  };

  resolveLocationDescriptor = (pathname: string): LocationDescriptor => ({
    pathname,
    search: this.props.location.pathname === pathname ? this.props.location.search : undefined
  });

  resolveCalcPath = (): string => {
    const { permissions } = this.props;
    if ( hasAnyPermission(permissions, [Permission.MTPL_CALCULATOR, Permission.CRASH_CALCULATOR]) ) {
      return "/calc/vehicle";
    }
    else if ( hasPermission(permissions, Permission.REALTY_CALCULATOR) ) {
      return "/calc/realty";
    }
    else if ( hasPermission(permissions, Permission.TRAVEL_CALCULATOR) ) {
      return "/calc/travel";
    }
    return "/";
  };

  resolveAdminPath = (): string => {
    const { permissions } = this.props;
    if ( hasPermission(permissions, Permission.ADMIN_ENUMERATIONS) ) {
      return "/admin/institutions";
    }
    else if ( hasPermission(permissions, Permission.ADMIN_JOBS) ) {
      return "/admin/jobs";
    }
    else if ( hasPermission(permissions, Permission.ADMIN_CONTRACT_UPDATES) ) {
      return "/admin/contract-updates";
    }
    return "/";
  };

  resolveCommissionsPath = (): string => {
    const { permissions } = this.props;
    return hasPermission(permissions, Permission.COMMISSIONS) ? "/commissions/batches" : "/";
  }

  componentDidUpdate(): void {
    const pathname = parseFirstPartOfPathname(this.props.location.pathname);
    if ( this.state.currentSelectedKey !== pathname ) {
      this.setState({ currentSelectedKey: pathname });
    }
  }

  render(): React.ReactNode {
    return (
      this.props.userAuthenticated ? (
        <Menu
          theme="light"
          mode="horizontal"
          onClick={this.handleMenuItemClick}
          selectedKeys={[this.state.currentSelectedKey]}>

          {this.createMenuItems()}
        </Menu>
      ) : null
    );
  }
}

export default HeaderMenu;
