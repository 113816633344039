import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { CalcResponse, CalcResult, CalcResultData, GenResponseViolations, StepInfo } from "./types";
import { ErrorResponse, FieldConstraintViolation } from "../../../common/types";
import { ClientFormType } from "../../client/enums";
import { HttpStatus } from "../../../common/constants";
import { setErrorsToForm_deprecated } from "../../../common/utils/formUtils";
import { showErrorResponseNotification } from "../../../common/utils/apiUtils";
import t from "../../../app/i18n";

export const sortAndGroupCalcResults = <T extends CalcResultData>(calcResponse: CalcResponse<T>): CalcResult<T>[][] => {
  const sortedResults: CalcResult<T>[][] = [];

  calcResponse.results
    .forEach(result => {
      const index = sortedResults.findIndex(items => items[0].insuranceInstitution.id === result.insuranceInstitution.id);
      if ( index === -1 ) {
        sortedResults.push([result as CalcResult<T>]);
      }
      else {
        sortedResults[index].push(result as CalcResult<T>);
      }
    });

  sortResultGroupsByInstitutionName<T>(sortedResults);
  sortResultsWithinGroupsByCoverageIndex<T>(sortedResults);

  return sortedResults;
};

export const checkIfStepsAreSuccessful = <T>(steps: StepInfo<T>[], ...checkedSteps: T[]): boolean => {
  return checkedSteps.every(checkedStep => steps.some(step => step.step === checkedStep && step.successful));
};

export const filterApplicableResults = <T extends CalcResultData = CalcResultData>(results: CalcResult<T>[]): CalcResult<T>[] => {
  return results.filter(result => isApplicableResult(result));
};

export const isApplicableResult = <T extends CalcResultData = CalcResultData>(result: CalcResult<T>): boolean => {
  return !result.data || !result.data.notApplicable;
};

export const filterApplicableSuccessResults = <T extends CalcResultData = CalcResultData>(results: CalcResult<T>[]): CalcResult<T>[] => {
  return results.filter(result => isApplicableSuccessResult(result));
};

export const isApplicableSuccessResult = <T extends CalcResultData = CalcResultData>(result: CalcResult<T>): boolean => {
  return result.data && !result.data.notApplicable;
};

export const sortResultGroupsByInstitutionName = <T extends CalcResultData>(resultGroups: CalcResult<T>[][]): void => {
  resultGroups.sort((a, b) => a[0].insuranceInstitution.name.localeCompare(b[0].insuranceInstitution.name));
};

export const sortResultGroupsByAnnualPremium = <T extends CalcResultData>(resultGroups: CalcResult<T>[][]): void => {
  resultGroups.sort((a, b) => {
    const result1 = a.find(result => result.data && !result.data.notApplicable);
    const result2 = b.find(result => result.data && !result.data.notApplicable);
    return !result1 ? 1 : !result2 ? -1 : result1.data.annualPremium - result2.data.annualPremium;
  });
};

export const sortResultsWithinGroupsByCoverageIndex = <T extends CalcResultData>(resultGroups: CalcResult<T>[][]): void => {
  for ( let index = 0; index < resultGroups.length; index++ ) {
    resultGroups[index] = resultGroups[index].sort((a, b) => a.coverageOrderIndex - b.coverageOrderIndex);
  }
};

export const processClientsDataViolations = (violations: FieldConstraintViolation[],
                                             clientsIndicesMap: Map<ClientFormType, number>): Map<ClientFormType, FieldConstraintViolation[]> => {
  const violationsMap = new Map<ClientFormType, FieldConstraintViolation[]>();

  if ( violations ) {
    violations
      .filter(violation => violation.fieldPath.startsWith("clientsData.clients"))
      .forEach(violation => {
        for ( const [type, index] of clientsIndicesMap ) {
          if ( index === parseInt(violation.fieldPath.substring(violation.fieldPath.indexOf("[") + 1, violation.fieldPath.indexOf("]"))) ) {
            const processedViolation = {
              ...violation,
              fieldPath: violation.fieldPath.substring(30, violation.fieldPath.length)
            };
            violationsMap.set(type, violationsMap.has(type) ? [...violationsMap.get(type), processedViolation] : [processedViolation]);
            break;
          }
        }
      });
  }

  return violationsMap;
};

export const processGenResultError = (error: ErrorResponse,
                                      clientsIndicesMap: Map<ClientFormType, number>,
                                      form: WrappedFormUtils,
                                      translationRootPath: string): GenResponseViolations => {
  if ( error.status === HttpStatus.UNPROCESSABLE_ENTITY && error.violations ) {
    const clientsViolations = processClientsDataViolations(error.violations, clientsIndicesMap);
    const notificationViolations = error.violations.filter(violation => !violation.fieldPath.startsWith("clientsData.clients"));

    if ( clientsViolations.size > 0 ) {
      notificationViolations.unshift({
        fieldPath: "clientsData._label",
        errors: [t("calc.validations.clientsViolationError")],
        fieldValue: null
      });
    }

    setErrorsToForm_deprecated(form, error.violations, translationRootPath);

    return { clientsViolations, notificationViolations };
  }

  showErrorResponseNotification(error);
  return null;
};
