import React from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { Col, Modal, Row, Steps, Tabs } from "antd";
import { ColProps } from "antd/lib/grid";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CalcDataSource, CalcResult } from "../../../types";
import { RealtyCalc, RealtyCalcResultData } from "../../types";
import { Client, ClientsAutocompleteProps } from "../../../../../client/types";
import { BuildingState, BuildingType } from "../../enums";
import { CalcType } from "../../../../enums";
import { ClientType } from "../../../../../client/enums";
import { deleteStateRealtyCalcResultsAction } from "../../ducks";
import { contains, containsAny } from "../../../../../../common/utils/utils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { setErrorsToForm_deprecated } from "../../../../../../common/utils/formUtils";
import t from "../../../../../../app/i18n";

import RealtyCalcGeneralStep from "./steps/RealtyCalcGeneralStep";
import RealtyCalcApartmentStep from "./steps/RealtyCalcApartmentStep";
import RealtyCalcHouseStep from "./steps/RealtyCalcHouseStep";
import RealtyCalcHouseholdRealtyStep from "./steps/RealtyCalcHouseholdRealtyStep";
import RealtyCalcInsuranceStep from "./steps/RealtyCalcInsuranceStep";
import RealtyCalcResults from "../result/RealtyCalcResults";
import RealtyCalcNavigation from "./navigation/RealtyCalcNavigation";

export interface Props extends FormComponentProps<RealtyCalc> {
  calcData: RealtyCalc;
  calcDataSource: CalcDataSource;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  insuredClient: Client;
  clientsAutocomplete: ClientsAutocompleteProps;
  onCalcResultsDelete: typeof deleteStateRealtyCalcResultsAction;
  onCalculationFormSubmit(calcData: RealtyCalc): void;
  onInsuredClientChange(client: Client): void;
  onGenerateContractClick(calcData: RealtyCalc, selectedResult: CalcResult<RealtyCalcResultData>): void;
  onGenerateOfferClick(calcData: RealtyCalc): void;
  onSaveDraftClick(calcData: RealtyCalc): void;
  onResetCalculatorClick(): void;
  onCalcDataSourceReset(): void;
}

interface State {
  readonly currentStep: number;
  readonly lastReachedStep: number;
  readonly errorSteps: Set<number>;
  readonly hasPlaceOfInsuranceError: boolean;
  readonly hasInsurancesError: boolean;
  readonly clientType: ClientType;
  readonly buildingType: BuildingType;
  readonly buildingState: BuildingState;
  readonly realtyInsuranceEnabled: boolean;
  readonly householdInsuranceEnabled: boolean;
  readonly structuralComponentsReinsuranceEnabled: boolean;
  readonly resultsVisible: boolean;
}

class RealtyCalcWrapper extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const stateObject = {
      currentStep: 0,
      lastReachedStep: 0,
      errorSteps: new Set<number>(),
      hasPlaceOfInsuranceError: false,
      hasInsurancesError: false,
      clientType: ClientType.NATURAL,
      buildingType: null,
      buildingState: null,
      realtyInsuranceEnabled: false,
      householdInsuranceEnabled: false,
      structuralComponentsReinsuranceEnabled: false,
      resultsVisible: false
    };

    if ( props.calcData ) {
      const { clientsData, generalBuildingData, realtyInsuranceData, householdInsuranceData } = props.calcData;

      stateObject.lastReachedStep = 3;
      stateObject.clientType = clientsData ? clientsData.insuredClientType : stateObject.clientType;
      stateObject.buildingType = generalBuildingData.type;
      stateObject.buildingState = generalBuildingData.state;
      stateObject.realtyInsuranceEnabled = !!realtyInsuranceData;
      stateObject.householdInsuranceEnabled = !!householdInsuranceData;
      stateObject.structuralComponentsReinsuranceEnabled = !!(householdInsuranceData && householdInsuranceData.structuralComponentsReinsuranceAmount);
    }

    this.state = stateObject;
  }

  handleClientTypeChange = (clientType: ClientType): void => {
    this.setState({ clientType });
    this.props.form.setFieldsValue({
      "clientsData.insuredClientPin": undefined,
      "clientsData.insuredClientCrn": undefined
    });
    if ( clientType !== ClientType.NATURAL ) {
      this.setState({ householdInsuranceEnabled: false });
      this.props.form.setFieldsValue({ "generalInsuranceData.civilLiabilityReinsurance": undefined });
    }
  };

  handleBuildingTypeChange = (buildingType: BuildingType): void => {
    this.setState({ buildingType });
    if ( buildingType !== BuildingType.APARTMENT_IN_APARTMENT_BUILDING && buildingType !== BuildingType.APARTMENT_IN_FAMILY_HOUSE ) {
      this.setState({ structuralComponentsReinsuranceEnabled: false });
    }
  };

  handleBuildingStateChange = (buildingState: BuildingState): void => {
    this.setState(previousState => ({
      buildingState,
      householdInsuranceEnabled: buildingState === BuildingState.UNDER_CONSTRUCTION ? false : previousState.householdInsuranceEnabled,
      structuralComponentsReinsuranceEnabled: buildingState === BuildingState.UNDER_CONSTRUCTION ? false : previousState.structuralComponentsReinsuranceEnabled,
      hasPlaceOfInsuranceError: buildingState === BuildingState.FINISHED ? false : previousState.hasPlaceOfInsuranceError
    }), () => {
      if ( buildingState === BuildingState.UNDER_CONSTRUCTION ) {
        this.props.form.validateFields(["generalBuildingData.placeOfInsurance.descriptiveNumber"], { force: true });
        this.props.form.validateFields(["generalBuildingData.placeOfInsurance.orientationNumber"], { force: true });
      }
    });
  };

  handleRealtyInsuranceEnabledChange = (checked: boolean): void => {
    this.setState({ realtyInsuranceEnabled: checked }, () => this.checkHasInsurancesError());
    if ( checked ) {
      this.setState({ structuralComponentsReinsuranceEnabled: false });
    }
  };

  handleHouseholdInsuranceEnabledChange = (checked: boolean): void => {
    this.setState({ householdInsuranceEnabled: checked }, () => this.checkHasInsurancesError());
    if ( !checked ) {
      this.setState({ structuralComponentsReinsuranceEnabled: false });
    }
  };

  handleStructuralComponentsReinsuranceEnabledChange = (event: CheckboxChangeEvent): void => {
    this.setState({ structuralComponentsReinsuranceEnabled: event.target.checked });
  };

  handleResultsSaveDraftClick = (): void => {
    this.setState({ resultsVisible: false });
    if ( this.checkAreCalcFormDataUnchanged() ) {
      this.props.onSaveDraftClick(this.processAndGetCalcFormData());
    }
  };

  handleResultsGenerateOfferClick = (): void => {
    this.setState({ resultsVisible: false });
    if ( this.checkAreCalcFormDataUnchanged() ) {
      this.props.onGenerateOfferClick(this.processAndGetCalcFormData());
    }
  };

  handleResultsShow = (): void => {
    this.setState({ resultsVisible: true });
  };

  handleResultsClose = (): void => {
    this.setState({ resultsVisible: false });
  };

  handleResultGenerateContractClick = (result: CalcResult<RealtyCalcResultData>): void => {
    this.setState({ resultsVisible: false });
    if ( this.checkAreCalcFormDataUnchanged() ) {
      this.props.onGenerateContractClick(this.processAndGetCalcFormData(), result);
    }
  };

  handleResultShowErrorsClick = (result: CalcResult<RealtyCalcResultData>): void => {
    if ( result.error && result.error.violations ) {
      const errorSteps = new Set<number>();

      result.error.violations.forEach(violation => {
        const path = violation.fieldPath;
        if ( path.startsWith("clientsData") || path.startsWith("generalBuildingData") ) {
          errorSteps.add(0);
        }
        else if ( path.startsWith("houseOrRecreationalBuildingData") || path.startsWith("apartmentData") ) {
          errorSteps.add(1);
        }
        else if ( path.startsWith("realtyInsuranceData")
          || path.startsWith("householdInsuranceData")
          || path.startsWith("liabilityInsuranceData") ) {
          errorSteps.add(2);
        }
        else if ( path.startsWith("generalInsuranceData") ) {
          if ( path.endsWith("floodReinsurance")
            || path.endsWith("glassReinsurance")
            || path.endsWith("earthquakeReinsurance")
            || path.endsWith("civilLiabilityReinsurance")
            || path.endsWith("cyberneticReinsurance") ) {
            errorSteps.add(2)
          }
          else {
            errorSteps.add(3);
          }
        }
      });

      setErrorsToForm_deprecated(this.props.form, result.error.violations, "calc.realty.attrs");

      const currentStep = Math.min(...errorSteps);
      errorSteps.delete(currentStep);

      this.setState({ resultsVisible: false, currentStep, errorSteps });
    }
  };

  handleCalculationFormSubmit = (): void => {
    this.props.form.validateFields((errors, values) => {
      const hasPlaceOfInsuranceError = this.checkHasPlaceOfInsuranceError();
      const hasInsurancesError = this.checkHasInsurancesError();

      if ( errors || hasPlaceOfInsuranceError || hasInsurancesError ) {
        messageUtils.errorNotification(t("common.error"), t("calc.realty.validations.formError"));
        const errorKeys = errors ? Object.keys(errors) : [];

        if ( this.state.currentStep !== 0 && (hasPlaceOfInsuranceError || containsAny(errorKeys, "clientsData", "generalBuildingData")) ) {
          this.setState({ currentStep: 0 });
        }
        else if ( this.state.currentStep !== 1 && containsAny(errorKeys, "houseOrRecreationalBuildingData", "apartmentData") ) {
          this.setState({ currentStep: 1 });
        }
        else if ( this.state.currentStep !== 2 && (hasInsurancesError || containsAny(errorKeys, "realtyInsuranceData", "householdInsuranceData")) ) {
          this.setState({ currentStep: 2 });
        }
        else if ( this.state.currentStep !== 3 && contains(errorKeys, "generalInsuranceData") ) {
          this.setState({ currentStep: 3 });
        }
      }
      else {
        this.props.onCalcResultsDelete();
        this.props.onCalculationFormSubmit(this.processAndGetCalcFormData(values));

        if ( this.props.calcDataSource !== "init" ) {
          this.props.onCalcDataSourceReset();
        }
      }
    });
  };

  handleNextStepMove = (): void => {
    this.validateAndMoveToStep(this.state.currentStep + 1);
  };

  handlePreviousStepMove = (): void => {
    this.validateAndMoveToStep(this.state.currentStep - 1);
  };

  handleStepMove = (targetStep: number): void => {
    if ( targetStep !== this.state.currentStep && this.checkTimelineMoveToStep(targetStep) ) {
      this.validateAndMoveToStep(targetStep);
    }
  };

  validateAndMoveToStep = (targetStep: number): void => {
    let fieldsToValidate = [];
    let hasPlaceOfInsuranceError = false;
    let hasInsurancesError = false;

    switch ( this.state.currentStep ) {
      case 0:
        hasPlaceOfInsuranceError = this.checkHasPlaceOfInsuranceError();
        fieldsToValidate = ["clientsData", "generalBuildingData"];
        break;
      case 1:
        fieldsToValidate = ["houseOrRecreationalBuildingData", "apartmentData"];
        break;
      case 2:
        hasInsurancesError = this.checkHasInsurancesError();
        fieldsToValidate = ["realtyInsuranceData", "householdInsuranceData"];
        break;
      case 3:
        fieldsToValidate = ["generalInsuranceData"];
        break;
    }

    this.props.form.validateFieldsAndScroll(fieldsToValidate, errors => {
      let errorSteps = cloneDeep(this.state.errorSteps);

      if ( this.state.currentStep > targetStep ) {
        if ( errors || hasPlaceOfInsuranceError || hasInsurancesError ) {
          errorSteps.add(this.state.currentStep);
        }
        errorSteps.delete(targetStep);
        this.setState({ currentStep: targetStep, errorSteps });
      }
      else if ( !errors && !hasPlaceOfInsuranceError && !hasInsurancesError ) {
        errorSteps.delete(targetStep);
        this.setState(previousState => ({
          currentStep: targetStep,
          lastReachedStep: Math.max(previousState.lastReachedStep, targetStep),
          errorSteps
        }));
      }
      else {
        messageUtils.errorNotification(t("common.error"), t("calc.realty.validations.formError"));
      }
    });
  };

  checkTimelineMoveToStep = (targetStep: number): boolean => {
    const minErrorStep = this.state.errorSteps.size > 0 ? Math.min(...this.state.errorSteps) : Number.MAX_SAFE_INTEGER;
    return this.state.lastReachedStep + 1 >= targetStep && minErrorStep >= targetStep;
  };

  checkHasPlaceOfInsuranceError = (): boolean => {
    const hasPlaceOfInsuranceError = this.state.buildingState === BuildingState.UNDER_CONSTRUCTION &&
      !this.props.form.getFieldValue("generalBuildingData.placeOfInsurance.parcelNumber1") &&
      (this.props.form.getFieldValue("generalBuildingData.placeOfInsurance.street")
        ? !this.props.form.getFieldValue("generalBuildingData.placeOfInsurance.orientationNumber")
        : !this.props.form.getFieldValue("generalBuildingData.placeOfInsurance.descriptiveNumber"));

    this.setState({ hasPlaceOfInsuranceError });

    return hasPlaceOfInsuranceError;
  };

  checkHasInsurancesError = (): boolean => {
    const hasInsurancesError = !this.state.householdInsuranceEnabled && !this.state.realtyInsuranceEnabled;
    this.setState({ hasInsurancesError });
    return hasInsurancesError;
  };

  checkAreCalcFormDataUnchanged = (): boolean => {
    const calcDataUnchanged = isEqual(this.props.calcData, this.processAndGetCalcFormData());

    if ( !calcDataUnchanged ) {
      let warningMessage;
      switch ( this.props.calcDataSource ) {
        case "draft":
          warningMessage = t("calc.realty.validations.calcDataChangedDraft");
          break;
        case "calcData":
        case "genData":
          warningMessage = t("calc.realty.validations.calcDataChangedInit");
          break;
        default:
          warningMessage = t("calc.realty.validations.calcDataChanged");
          break;
      }

      Modal.warning({
        title: t("common.warning"),
        content: warningMessage,
        okText: t("calc.realty.actions.recalculationSubmit"),
        maskClosable: true,
        onOk: this.handleCalculationFormSubmit,
        onCancel: this.handleCalculationFormSubmit
      });
    }

    if ( this.props.calcDataSource !== "init" ) {
      this.props.onCalcDataSourceReset();
    }

    return calcDataUnchanged;
  };

  processAndGetCalcFormData = (calcData?: RealtyCalc): RealtyCalc => {
    const processedValues = calcData ? { ...calcData } : this.props.form.getFieldsValue() as RealtyCalc;

    processedValues.type = CalcType.REALTY;
    if ( processedValues.realtyInsuranceData && !processedValues.realtyInsuranceData.nearbyBuildingReinsurances ) {
      processedValues.realtyInsuranceData.nearbyBuildingReinsurances = [];
    }

    return processedValues;
  };

  resolveStepStatus = (step: number): "wait" | "process" | "finish" | "error" => {
    return this.state.errorSteps.has(step) ? "error" : undefined;
  };

  resolveStepClassName = (targetStep: number): string => {
    return this.checkTimelineMoveToStep(targetStep) ? "cursor-pointer" : "cursor-not-allowed";
  };

  resolveBuildingTypeStepTitle = (): string => {
    switch ( this.state.buildingType ) {
      case BuildingType.APARTMENT_IN_APARTMENT_BUILDING:
        return t("calc.realty.timeline.calcStep2ApartmentInApartmentBuilding");
      case BuildingType.APARTMENT_IN_FAMILY_HOUSE:
        return t("calc.realty.timeline.calcStep2ApartmentInFamilyHouse");
      case BuildingType.HOUSE:
        return t("calc.realty.timeline.calcStep2House");
      case BuildingType.RECREATIONAL_BUILDING:
        return t("calc.realty.timeline.calcStep2RecreationBuilding");
      default:
        return t("calc.realty.timeline.calcStep2Unknown");
    }
  };

  resolvePageTitle = (): string => {
    let pageTitle;
    switch ( this.state.buildingType ) {
      case BuildingType.APARTMENT_IN_APARTMENT_BUILDING:
        pageTitle = t("calc.realty.titles.apartmentInApartmentBuilding");
        break;
      case BuildingType.APARTMENT_IN_FAMILY_HOUSE:
        pageTitle = t("calc.realty.titles.apartmentInFamilyHouse");
        break;
      case BuildingType.HOUSE:
        pageTitle = t("calc.realty.titles.house");
        break;
      case BuildingType.RECREATIONAL_BUILDING:
        pageTitle = t("calc.realty.titles.recreationalBuilding");
        break;
      default:
        return t("calc.realty.timeline.calcStep2Unknown");
    }

    const insurancesTranslations = [];
    if ( this.state.realtyInsuranceEnabled ) {
      insurancesTranslations.push(t("calc.realty.sections.realtyInsurance"));
    }
    if ( this.state.householdInsuranceEnabled ) {
      insurancesTranslations.push(t("calc.realty.sections.householdInsurance"));
    }

    return insurancesTranslations.length === 0 ? pageTitle : `${pageTitle} (${insurancesTranslations.join(", ")})`;
  };

  componentDidMount(): void {
    if ( this.props.calcData ) {
      this.props.form.setFieldsValue({ ...this.props.calcData, type: undefined });
      this.props.onCalcDataSourceReset();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( prevProps.calcResults.length === 0 && this.props.calcResults.length > 0 ) {
      this.setState({ resultsVisible: true });
    }
  }

  render(): React.ReactNode {
    const { buildingType } = this.state;
    const { form, calcData } = this.props;
    const formLayout: ColProps = { span: 24, style: { maxWidth: "950px" } };

    return (
      <>
        <h2>{this.resolvePageTitle()}</h2>

        <Row className="margin-top-medium">
          <Col span={24}>
            <Steps current={this.state.currentStep}>
              <Steps.Step
                className="cursor-pointer"
                onClick={() => this.handleStepMove(0)}
                status={this.resolveStepStatus(0)}
                title={t("calc.realty.timeline.calcStep1")}
                description={t("calc.realty.timeline.calcStep1Desc")} />

              <Steps.Step
                className={this.resolveStepClassName(1)}
                onClick={() => this.handleStepMove(1)}
                status={this.resolveStepStatus(1)}
                title={this.resolveBuildingTypeStepTitle()}
                description={t("calc.realty.timeline.calcStep2Desc")} />

              <Steps.Step
                className={this.resolveStepClassName(2)}
                onClick={() => this.handleStepMove(2)}
                status={this.resolveStepStatus(2)}
                title={t("calc.realty.timeline.calcStep3")}
                description={t("calc.realty.timeline.calcStep3Desc")} />

              <Steps.Step
                className={this.resolveStepClassName(3)}
                onClick={() => this.handleStepMove(3)}
                status={this.resolveStepStatus(3)}
                title={t("calc.realty.timeline.calcStep4")}
                description={t("calc.realty.timeline.calcStep4Desc")} />
            </Steps>
          </Col>
        </Row>

        <Row gutter={64} className="margin-top-large" justify="center">
          <Col {...formLayout}>
            <Form layout="vertical">

              <Tabs activeKey={this.state.currentStep.toString()} renderTabBar={() => <></>}>

                <Tabs.TabPane key="0" forceRender>
                  <RealtyCalcGeneralStep
                    form={form}
                    initialClientsData={calcData ? calcData.clientsData : null}
                    initialBuildingData={calcData ? calcData.generalBuildingData : null}
                    calcDataSource={this.props.calcDataSource}
                    hasPlaceOfInsuranceError={this.state.hasPlaceOfInsuranceError}
                    clientsAutocomplete={this.props.clientsAutocomplete}
                    clientType={this.state.clientType}
                    insuredClient={this.props.insuredClient}
                    buildingState={this.state.buildingState}
                    onClientTypeChange={this.handleClientTypeChange}
                    onInsuredClientSelect={this.props.onInsuredClientChange}
                    onBuildingTypeChange={this.handleBuildingTypeChange}
                    onBuildingStateChange={this.handleBuildingStateChange}
                    onHasPlaceOfInsuranceErrorCheck={this.checkHasPlaceOfInsuranceError} />
                </Tabs.TabPane>

                <Tabs.TabPane key="1" forceRender>
                  {buildingType === BuildingType.APARTMENT_IN_APARTMENT_BUILDING || buildingType === BuildingType.APARTMENT_IN_FAMILY_HOUSE ? (
                    <RealtyCalcApartmentStep
                      form={form}
                      initialFloorFrom={calcData && calcData.apartmentData ? calcData.apartmentData.floorFrom : null} />
                  ) : (
                    <RealtyCalcHouseStep
                      form={form}
                      initialData={calcData ? calcData.houseOrRecreationalBuildingData : null}
                      buildingType={buildingType} />
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane key="2" forceRender>
                  <RealtyCalcHouseholdRealtyStep
                    form={form}
                    initialRealtyData={calcData ? calcData.realtyInsuranceData : null}
                    clientType={this.state.clientType}
                    buildingType={buildingType}
                    buildingState={this.state.buildingState}
                    hasInsurancesError={this.state.hasInsurancesError}
                    realtyInsuranceEnabled={this.state.realtyInsuranceEnabled}
                    householdInsuranceEnabled={this.state.householdInsuranceEnabled}
                    structuralComponentsReinsuranceEnabled={this.state.structuralComponentsReinsuranceEnabled}
                    onRealtyInsuranceEnabledChange={this.handleRealtyInsuranceEnabledChange}
                    onHouseholdInsuranceEnabledChange={this.handleHouseholdInsuranceEnabledChange}
                    onStructuralComponentsReinsuranceEnabledChange={this.handleStructuralComponentsReinsuranceEnabledChange} />
                </Tabs.TabPane>

                <Tabs.TabPane key="3" forceRender>
                  <RealtyCalcInsuranceStep form={form} />
                </Tabs.TabPane>

              </Tabs>

            </Form>
          </Col>
        </Row>

        <RealtyCalcResults
          visible={this.state.resultsVisible}
          calcResults={this.props.calcResults}
          onClose={this.handleResultsClose}
          onSaveDraftClick={this.handleResultsSaveDraftClick}
          onGenerateOfferClick={this.handleResultsGenerateOfferClick}
          onGenerateContractClick={this.handleResultGenerateContractClick}
          onShowErrorsClick={this.handleResultShowErrorsClick} />

        <RealtyCalcNavigation
          currentStep={this.state.currentStep}
          lastReachedStep={this.state.lastReachedStep}
          hasErrors={this.state.errorSteps.size > 0 || this.state.hasInsurancesError}
          hasResults={this.props.calcResults.length > 0}
          onFormSubmit={this.handleCalculationFormSubmit}
          onShowResultsClick={this.handleResultsShow}
          onMoveToNextStepClick={this.handleNextStepMove}
          onMoveToPreviousStepClick={this.handlePreviousStepMove}
          onResetCalculatorClick={this.props.onResetCalculatorClick} />

      </>
    )
  }
}

export default Form.create<Props>({
  onValuesChange: props => {
    if ( props.calcResults.length > 0 && props.calcDataSource === "init" ) {
      props.onCalcResultsDelete();
    }
  }
})(RealtyCalcWrapper);
