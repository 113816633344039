import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { requestPasswordResetActions } from "../../ducks";
import { validations } from "../../../../common/utils/validationUtils";
import { useFormErrorHandler } from "../../../../common/utils/formUtils";
import { requests } from "../../api";
import t from "../../../../app/i18n";

export interface Props {
  onFormSubmit: typeof requestPasswordResetActions.request | any;
}

const RequestPasswordResetForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "account.attrs", requests.REQUEST_PASSWORD_RESET);

  return (
    <>
      <h2 className="center-align margin-bottom-large">{t("account.titles.passwordReset")}</h2>

      <Row justify="center">
        <Col span={24} style={{ maxWidth: "400px" }}>

          <Form form={form} layout="vertical" name="requestPasswordResetForm" onFinish={props.onFormSubmit}>

            <Form.Item
              name="username"
              label={t("account.attrs.username")}
              rules={[validations.notBlank, validations.size(1, 70)]}>
              <Input />
            </Form.Item>

            <Form.Item>
              <Button className="margin-top-medium" type="primary" htmlType="submit" block>
                {t("account.actions.resetPassword")}
              </Button>
            </Form.Item>

          </Form>

        </Col>
      </Row>
    </>
  );
}

export default RequestPasswordResetForm;
