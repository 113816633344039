import React from "react";
import { Alert, Button, Drawer } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Client, CreateUpdateClient, SelfEmployedClient } from "../../types";
import { ClientFormType, ClientType } from "../../enums";
import { createClientAggregatedName, createClientAggregatedNaturalName, resolveClientIdentifier } from "../../utils";
import t from "../../../../app/i18n";

import ClientCreateForm, { ClientCreateFormComponent } from "../forms/ClientCreateForm";

export interface Props<Type = ClientFormType> {
  visible: boolean;
  initialClientType?: ClientType;
  initialIdentifier: string;
  formType: Type;
  placement?: "calc" | "contract";
  onFormSubmit(client: Client, clientFormType: Type): void;
}

const ClientDrawerCreateForm = <Type, >(props: Props<Type>) => {

  const formRef = React.createRef<ClientCreateFormComponent>();

  const handleFormSubmit = (clientData: CreateUpdateClient): void => {
    // @ts-ignore
    const client: Client = { ...clientData };

    client.identifier = resolveClientIdentifier(client);
    client.aggregatedName = createClientAggregatedName(client);
    if ( client.type === ClientType.SELF_EMPLOYED ) {
      (client as SelfEmployedClient).aggregatedNaturalName = createClientAggregatedNaturalName(client);
    }

    props.onFormSubmit(client, props.formType);
  };

  const handleDrawerClose = (): void => {
    if ( formRef.current ) {
      formRef.current.handleFormSubmit();
    }
  };

  return (
    <Drawer
      title={
        <>
          <span>{t("client.titles.create")}</span>
          <div className="margin-top-tiny">
            <Alert
              showIcon
              message={props.placement === "contract"
                ? t("client.helpers.clientDataUpdateInfoContract") : t("client.helpers.clientDataUpdateInfoCalc")} />
          </div>
        </>
      }
      width={950}
      visible={props.visible}
      closable
      destroyOnClose
      onClose={handleDrawerClose}>

      <ClientCreateForm
        wrappedComponentRef={formRef}
        initialClientType={props.initialClientType}
        initialPinValue={
          props.initialClientType === ClientType.NATURAL || (props.initialIdentifier && props.initialIdentifier.length > 8)
            ? props.initialIdentifier
            : undefined
        }
        initialCrnValue={
          props.initialClientType === ClientType.SELF_EMPLOYED || props.initialClientType === ClientType.LEGAL
          || (props.initialIdentifier && props.initialIdentifier.length === 8)
            ? props.initialIdentifier
            : undefined
        }
        excludedClientTypes={
          props.initialClientType ? undefined : props.initialIdentifier && props.initialIdentifier.length === 8
            ? [ClientType.NATURAL]
            : [ClientType.SELF_EMPLOYED, ClientType.LEGAL]
        }
        hideSubmitButton
        disableFormSubmitValidation
        onFormSubmit={handleFormSubmit} />

      <div className="margin-top-large">
        <Button type="primary" icon={<CheckOutlined />} onClick={handleDrawerClose}>{t("common.submit")}</Button>
      </div>

    </Drawer>
  );
};

export default ClientDrawerCreateForm;
