import React from "react";
import { connect } from "react-redux";
import { Route, RouteProps } from "react-router";
import { RootState } from "../../types";
import { Permission } from "./enums";
import { selectPermissions } from "../../../modules/auth/ducks";
import { hasAllPermissions, hasAnyPermission } from "../../utils/utils";

import NotFound from "../../pages/NotFound/NotFound";

export interface Props extends RouteProps {
  permissions: Permission[];
  anyPermission?: boolean;
}

interface StateProps {
  accountPermissions: Permission[];
}

const RouteWithPermission = ({ component, accountPermissions, permissions, anyPermission, ...props }: Props & StateProps) => {
  const renderedComponent = (anyPermission ?
    hasAnyPermission(accountPermissions, permissions) :
    hasAllPermissions(accountPermissions, permissions)) ? component : NotFound;
  return <Route {...props} component={renderedComponent} />;
};

const mapStateToProps = (state: RootState): StateProps => ({
  accountPermissions: selectPermissions(state)
});

export default connect<StateProps, {}, Props, RootState>(mapStateToProps)(RouteWithPermission);
