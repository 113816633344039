import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CommissionsBatch, CommissionsUnit } from "../../../../types";
import {
  createCommissionsUnitActions,
  deleteCommissionsBatchAttachmentActions,
  deleteCommissionsUnitActions,
  replaceCommissionsBatchAttachmentActions,
  updateCommissionsUnitActions,
  uploadCommissionsBatchAttachmentActions
} from "../../../../ducks";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import CommissionsUnitTableView from "../views/CommissionsUnitTableView";
import CommissionsUnitForm from "../../../forms/CommissionsUnitForm";

export interface Props {
  batch: CommissionsBatch;
  onUnitCreate: typeof createCommissionsUnitActions.request;
  onUnitUpdate: typeof updateCommissionsUnitActions.request;
  onUnitDelete: typeof deleteCommissionsUnitActions.request;
  onAttachmentUpload: typeof uploadCommissionsBatchAttachmentActions.request;
  onAttachmentReplace: typeof replaceCommissionsBatchAttachmentActions.request;
  onAttachmentDelete: typeof deleteCommissionsBatchAttachmentActions.request;
}

const CommissionsBatchImportStep = ({ batch, ...props }: Props) => {

  const [unitFormVisible, setUnitFormVisible] = useState<boolean>(false);
  const [unitToUpdate, setUnitToUpdate] = useState<CommissionsUnit>();

  const handleUpdateClick = (unit: CommissionsUnit): void => {
    setUnitFormVisible(true);
    setUnitToUpdate(unit);
  };

  const handleFormCancel = (): void => {
    setUnitFormVisible(false);
    setUnitToUpdate(null);
  };

  return (
    <>
      <Row gutter={rowGutter} className="margin-bottom-small">
        <Col span={12}>
          <h3>{t("commissions.batch.titles.unitsList")}</h3>
        </Col>
        <Col span={12} className="right-align">
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setUnitFormVisible(true)}>
            {t("commissions.batch.actions.addUnit")}
          </Button>
        </Col>
      </Row>

      <CommissionsUnitTableView
        batch={batch}
        onUpdateClick={handleUpdateClick}
        onDelete={props.onUnitDelete}
        onAttachmentUpload={props.onAttachmentUpload}
        onAttachmentReplace={props.onAttachmentReplace}
        onAttachmentDelete={props.onAttachmentDelete} />

      <CommissionsUnitForm
        batch={batch}
        unit={unitToUpdate}
        visible={unitFormVisible}
        onCreate={props.onUnitCreate}
        onUpdate={props.onUnitUpdate}
        onFormCancel={handleFormCancel} />
    </>
  )
};

export default CommissionsBatchImportStep;
