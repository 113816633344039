import React from "react";
import { Tag } from "antd";
import t from "../../../app/i18n";

export interface Props {
  personDeactivated: boolean;
}

const PersonActiveTag = ({ personDeactivated }: Props) => (
  personDeactivated
    ? <Tag color="red">{t("person.helpers.deactivatedPerson")}</Tag>
    : <Tag color="green">{t("person.helpers.activePerson")}</Tag>
);

export default PersonActiveTag;
