import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Modal } from "antd";
import { TravelCalc, TravelCalcResultData, TravelFormClients, TravelGen, TravelGenFormData } from "../types";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import { ActionProps, RootState } from "../../../../../common/types";
import { Client, ClientAutocompleteResult } from "../../../../client/types";
import { ValidationErrorResponse } from "../../../../types";
import { CalcType } from "../../../enums";
import { ClientFormType } from "../../../../client/enums";
import {
  calculateTravelActions,
  deleteStateTravelCalcResultsAction,
  deleteStateTravelGenResultAction,
  deleteStateTravelInitialCalcGenDataAction,
  generateTravelActions,
  generateTravelOfferActions,
  selectTravelCalcResults,
  selectTravelGenResult,
  selectTravelInitialCalcData,
  selectTravelInitialGenData
} from "../ducks";
import {
  deleteStateValidationErrorResponseAction,
  selectIsRequestInProgress,
  selectValidationErrorResponse
} from "../../../../ducks";
import {
  autocompleteClientsActions,
  deleteStateClientsAutocompleteResultAction,
  selectClientsAutocompleteResult,
  validateClientActions,
  validateClientsActions
} from "../../../../client/ducks";
import {
  createTravelCalcObjectFromCalcData,
  createTravelCalcObjectFromGenData,
  createTravelFormClientsObject,
  createTravelGenFormDataObject
} from "../utils";
import { createClientAggregatedName } from "../../../../client/utils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { requests as calcRequests } from "../../api";
import { requests as clientRequests } from "../../../../client/api";
import t from "../../../../../app/i18n";

import TravelCalcWrapper from "../components/calc/TravelCalcWrapper";
import TravelGenWrapper from "../components/gen/TravelGenWrapper";
import TravelClientNameModal from "../components/client/TravelClientNameModal";
import CalcLoadingModal from "../../components/CalcLoadingModal";

interface StateProps {
  initialCalcData: TravelCalc;
  initialGenData: TravelGen;
  calcResults: CalcResult<TravelCalcResultData>[][];
  genResult: GenResponse;
  clientsAutocompleteResult: ClientAutocompleteResult;
  clientsAutocompleteInProgress: boolean;
  calcRequestInProgress: boolean;
  genRequestInProgress: boolean;
  validateClientErrorResponse: ValidationErrorResponse;
  validateClientsErrorResponse: ValidationErrorResponse;
}

interface ActionsMap {
  calculateTravel: typeof calculateTravelActions.request;
  generateTravel: typeof generateTravelActions.request;
  generateTravelOffer: typeof generateTravelOfferActions.request;
  autocompleteClients: typeof autocompleteClientsActions.request;
  validateClient: typeof validateClientActions.request;
  validateClients: typeof validateClientsActions.request;
  deleteStateTravelCalcResults: typeof deleteStateTravelCalcResultsAction;
  deleteStateTravelGenResult: typeof deleteStateTravelGenResultAction;
  deleteStateTravelInitialCalcGenData: typeof deleteStateTravelInitialCalcGenDataAction;
  deleteStateClientsAutocompleteResult: typeof deleteStateClientsAutocompleteResultAction;
  deleteStateValidationErrorResponse: typeof deleteStateValidationErrorResponseAction;
}

interface State {
  readonly resetKey: number;
  readonly clients: TravelFormClients;
  readonly genStepActive: boolean;
  readonly calcData: TravelCalc;
  readonly calcDataSource: CalcDataSource;
  readonly genFormData: TravelGenFormData;
  readonly selectedResult: CalcResult<TravelCalcResultData>;
  readonly insuredClientName: string;
  readonly clientNameModalVisible: boolean;
}

class TravelCalcContainer extends React.Component<StateProps & ActionProps<ActionsMap>, State> {

  constructor(props: StateProps & ActionProps<ActionsMap>) {
    super(props);

    const stateObject = {
      resetKey: 0,
      clients: { insurer: null, authorized1: null, authorized2: null },
      genStepActive: false,
      calcData: null,
      calcDataSource: "init" as CalcDataSource,
      genFormData: null,
      selectedResult: null,
      insuredClientName: null,
      clientNameModalVisible: false
    };

    if ( props.initialCalcData ) {
      stateObject.calcData = createTravelCalcObjectFromCalcData(props.initialCalcData);
      stateObject.calcDataSource = "calcData";
    }
    else if ( props.initialGenData ) {
      stateObject.clients = createTravelFormClientsObject(props.initialGenData);
      stateObject.calcData = createTravelCalcObjectFromGenData(props.initialGenData);
      stateObject.calcDataSource = "genData";
      stateObject.genFormData = createTravelGenFormDataObject(props.initialGenData, stateObject.clients);
    }

    this.state = stateObject;
  }

  handleCalculationFormSubmit = (calcData: TravelCalc): void => {
    this.setState({ calcData });
    this.props.actions.calculateTravel(calcData);
  };

  handleClientChange = (client: Client, type: ClientFormType, callback?: () => void): void => {
    switch ( type ) {
      case ClientFormType.INSURER:
        this.setState(previousState => ({ clients: { ...previousState.clients, insurer: client } }), callback);
        break;
      case ClientFormType.AUTHORIZED_1:
        this.setState(previousState => ({ clients: { ...previousState.clients, authorized1: client } }), callback);
        break;
      case ClientFormType.AUTHORIZED_2:
        this.setState(previousState => ({ clients: { ...previousState.clients, authorized2: client } }), callback);
        break;
    }
  };

  handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        this.setState(previousState => ({
          resetKey: previousState.resetKey + 1,
          clients: { insurer: null, authorized1: null, authorized2: null },
          calcData: null,
          calcDataSource: "init" as CalcDataSource,
          genFormData: null,
          selectedResult: null,
          insuredClientName: null
        }));
        this.props.actions.deleteStateTravelCalcResults();
        this.props.actions.deleteStateClientsAutocompleteResult();
      }
    });
  };

  handleCalcDataSourceReset = (): void => {
    this.setState({ calcDataSource: "init" });
  };

  handleGenerateContractClick = (calcData: TravelCalc, selectedResult: CalcResult<TravelCalcResultData>): void => {
    this.setState({ genStepActive: true, calcData, selectedResult });
  };

  handleCalcFormGenerateOfferClick = (calcData: TravelCalc): void => {
    this.setState({ calcData, clientNameModalVisible: true });
  };

  handleGenFormGenerateOfferClick = (): void => {
    if ( this.state.clients.insurer ) {
      this.handleClientNameModalOkClick(createClientAggregatedName(this.state.clients.insurer));
    }
    else {
      this.setState({ clientNameModalVisible: true });
    }
  };

  handleReturnToCalculationClick = (genFormData: TravelGenFormData): void => {
    this.setState({ genStepActive: false, calcDataSource: "calcData", genFormData });
  };

  handleSelectedResultChange = (selectedResult: CalcResult<TravelCalcResultData>, callback?: () => void): void => {
    this.setState({ selectedResult }, callback);
  };

  handleClientNameModalOkClick = (insuredClientName: string): void => {
    this.props.actions.generateTravelOffer({
      type: CalcType.TRAVEL,
      calcRequest: this.state.calcData,
      calcResponse: { results: this.props.calcResults.flat() },
      insuredClientName
    });
    this.setState({ insuredClientName, clientNameModalVisible: false });
  };

  handleClientNameModalCancelClick = (): void => {
    this.setState({ clientNameModalVisible: false });
  };

  componentWillUnmount(): void {
    this.props.actions.deleteStateTravelCalcResults();
    this.props.actions.deleteStateTravelGenResult();
  }

  componentDidMount(): void {
    if ( this.props.initialCalcData || this.props.initialGenData ) {
      this.props.actions.deleteStateTravelInitialCalcGenData();
      messageUtils.infoNotification(t("calc.records.helpers.calcDataInitTitle"), t("calc.records.helpers.calcDataInit"), 10);
    }
  }

  render(): React.ReactNode {
    return (
      <>
        {this.state.genStepActive ? (
          <TravelGenWrapper initialData={this.state.genFormData}
                            genResult={this.props.genResult}
                            calcData={this.state.calcData}
                            clients={this.state.clients}
                            calcResults={this.props.calcResults}
                            selectedResult={this.state.selectedResult}
                            clientsAutocomplete={{
                              result: this.props.clientsAutocompleteResult,
                              inProgress: this.props.clientsAutocompleteInProgress,
                              onSearch: this.props.actions.autocompleteClients,
                              onResultDelete: this.props.actions.deleteStateClientsAutocompleteResult
                            }}
                            validateClientErrorResponse={this.props.validateClientErrorResponse}
                            validateClientsErrorResponse={this.props.validateClientsErrorResponse}
                            onGenerateFormSubmit={this.props.actions.generateTravel}
                            onValidateClient={this.props.actions.validateClient}
                            onValidateClients={this.props.actions.validateClients}
                            onGenResultDelete={this.props.actions.deleteStateTravelGenResult}
                            onErrorResponseDelete={this.props.actions.deleteStateValidationErrorResponse}
                            onClientChange={this.handleClientChange}
                            onGenerateOfferClick={this.handleGenFormGenerateOfferClick}
                            onSelectedResultChange={this.handleSelectedResultChange}
                            onReturnToCalculationClick={this.handleReturnToCalculationClick} />
        ) : (
          <TravelCalcWrapper key={this.state.resetKey}
                             calcData={this.state.calcData}
                             calcDataSource={this.state.calcDataSource}
                             calcResults={this.props.calcResults}
                             onCalcResultsDelete={this.props.actions.deleteStateTravelCalcResults}
                             onCalculationFormSubmit={this.handleCalculationFormSubmit}
                             onGenerateContractClick={this.handleGenerateContractClick}
                             onGenerateOfferClick={this.handleCalcFormGenerateOfferClick}
                             onResetCalculatorClick={this.handleResetCalculatorClick}
                             onCalcDataSourceReset={this.handleCalcDataSourceReset} />
        )}

        <TravelClientNameModal visible={this.state.clientNameModalVisible}
                               initialName={this.state.insuredClientName}
                               onOkClick={this.handleClientNameModalOkClick}
                               onCancelClick={this.handleClientNameModalCancelClick} />

        {this.props.calcRequestInProgress || this.props.genRequestInProgress ? (
          <CalcLoadingModal
            calcRequestInProgress={this.props.calcRequestInProgress}
            genRequestInProgress={this.props.genRequestInProgress} />
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectTravelInitialCalcData(state),
  initialGenData: selectTravelInitialGenData(state),
  calcResults: selectTravelCalcResults(state),
  genResult: selectTravelGenResult(state),
  clientsAutocompleteResult: selectClientsAutocompleteResult(state),
  clientsAutocompleteInProgress: selectIsRequestInProgress(state, clientRequests.AUTOCOMPLETE_CLIENTS),
  calcRequestInProgress: selectIsRequestInProgress(state, calcRequests.CALCULATE),
  genRequestInProgress: selectIsRequestInProgress(state, calcRequests.GENERATE),
  validateClientErrorResponse: selectValidationErrorResponse(state, clientRequests.VALIDATE_CLIENT),
  validateClientsErrorResponse: selectValidationErrorResponse(state, clientRequests.VALIDATE_CLIENTS)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    calculateTravel: calculateTravelActions.request,
    generateTravel: generateTravelActions.request,
    generateTravelOffer: generateTravelOfferActions.request,
    autocompleteClients: autocompleteClientsActions.request,
    validateClient: validateClientActions.request,
    validateClients: validateClientsActions.request,
    deleteStateTravelCalcResults: deleteStateTravelCalcResultsAction,
    deleteStateTravelGenResult: deleteStateTravelGenResultAction,
    deleteStateTravelInitialCalcGenData: deleteStateTravelInitialCalcGenDataAction,
    deleteStateClientsAutocompleteResult: deleteStateClientsAutocompleteResultAction,
    deleteStateValidationErrorResponse: deleteStateValidationErrorResponseAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(TravelCalcContainer);
