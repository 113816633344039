import React from "react";
import { useSelector } from "react-redux";
import { VehicleBrandWithModels } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { selectVehicleBrandsEnumerations, VEHICLE_OTHER_MAPPING_NAME } from "../../ducks";
import t from "../../../../app/i18n";

export interface Props {
  brandId: UUID;
  otherBrandName?: string;
}

const VehicleBrandEnumName = ({ brandId, otherBrandName }: Props) => {
  const vehicleBrandsEnumerations = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnumerations);

  const brand = vehicleBrandsEnumerations.find(brand => brand.id === brandId);
  return brand
    ? brand.name === VEHICLE_OTHER_MAPPING_NAME && otherBrandName ? <>{`${brand.name} (${otherBrandName})`}</> : <>{brand.name}</>
    : <>{t("calc.vehicles.helpers.unknownBrand")}</>
};

export default VehicleBrandEnumName;
