import {
  ActionCreator,
  ActionCreatorFunction,
  ApiActionCreators,
  NormalizedError,
  ReducerMap,
  ReducerObject
} from "../types";
import flattenDeep from "lodash/flattenDeep";
import reduce from "lodash/reduce";

export enum apiOperation {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

/**
 * Creates action type by flattening (joining) the input parts together with '_'.
 * @param parts - parts to be flattened
 */
export const createActionType = (...parts: string[]): string => flattenDeep(parts).join('_');

/**
 * Creates action creator function which returns object consisting of type and input payload data.
 * @param types - types flattened together used as action type identifier
 */
export const createActionCreator = <T>(...types: string[]): ActionCreatorFunction<T> =>
  (payload: T): ActionCreator<T> => ({
    type: createActionType(...types),
    payload
  });

/**
 * Creates request/success/failure action creators object.
 * @param types - types flattened together used as action type identifier
 */
export const createApiActionCreators = <R, S, F = NormalizedError>(...types: string[]): ApiActionCreators<R, S, F> => ({
  request: createActionCreator<R>(...types, apiOperation.REQUEST),
  success: createActionCreator<S>(...types, apiOperation.SUCCESS),
  failure: createActionCreator<F>(...types, apiOperation.FAILURE),
});

/**
 * Creates new reducer.
 * @param initialState - initial state of reducer
 * @param reducerMap - map of reducer functions
 */
export const createReducer = <S, P = S>(initialState: S, reducerMap: ReducerMap<S, P>): ReducerObject<S, P> => {
  const iterator = (reducers, initial = {}, prefix = []) =>
    reduce(
      reducers,
      (acc, reducer, type) => {
        if ( typeof reducer === 'function' ) {
          return { ...acc, [createActionType(...prefix, type)]: reducer };
        }
        return iterator(reducer, acc, [createActionType(...prefix, type)]);
      },
      initial
    );

  const flattened = iterator(reducerMap);

  return (state: S = initialState, action: ActionCreator<P>): S => {
    const reducer = flattened[action.type];
    return reducer ? reducer(state, action.payload) : state;
  };
};
