import React from "react";
import { Button } from "antd";
import { CalculatorOutlined, DoubleLeftOutlined, SolutionOutlined } from "@ant-design/icons";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import t from "../../../../../../../app/i18n";

import ComponentWithPermission from "../../../../../../../common/security/authorization/ComponentWithPermission";

export interface Props {
  onFormSubmit(): void;
  onShowResultsClick(): void;
  onReturnToCalculationClick(): void;
}

const TravelGenNavigation = (props: Props) => (
  <div className="bottom-fixed-navigation">

    <div className="navigation-center">
      <ComponentWithPermission permissions={[Permission.TRAVEL_CALCULATOR_GENERATE_CONTRACT]}>
        <Button icon={<SolutionOutlined />} type="primary" onClick={props.onFormSubmit}>
          {t("calc.travel.actions.generateContract")}
        </Button>
      </ComponentWithPermission>
    </div>

    <div className="navigation-right">
      <Button className="blue-button" icon={<DoubleLeftOutlined />} onClick={props.onShowResultsClick}>
        {t("common.showResults")}
      </Button>
    </div>

    <div className="navigation-left">
      <Button icon={<CalculatorOutlined />} onClick={props.onReturnToCalculationClick}>
        {t("calc.travel.actions.backToCalculation")}
      </Button>
    </div>

  </div>
);

export default TravelGenNavigation;
