import React from "react";
import debounce from "lodash/debounce";
import { Card, Checkbox, Col, Input, Row } from "antd";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { SelectValue } from "antd/lib/select";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import {
  VehicleCalcClientsData,
  VehicleCalcResultData,
  VehicleFormClients,
  VehicleGenFormClientsData
} from "../../../types";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import { AuthorizedClientFormInputs, Client, ClientsAutocompleteProps } from "../../../../../../client/types";
import { VehicleInsurerRelation, VehicleOwnerRelation } from "../../../enums";
import { CalcResult } from "../../../../types";
import { ClientFormStage, ClientFormType, ClientType } from "../../../../../../client/enums";
import { InstitutionEnum } from "../../../../../../admin/institution/enums";
import { resolveVehicleInsurerType } from "../../../utils";
import { contains } from "../../../../../../../common/utils/utils";
import validations, { validationFunctions } from "../../../../../../../common/utils/validationUtils";
import { phoneNumberNormalizeFunction } from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import ClientDrawerCreateForm from "../../../../../../client/components/drawers/ClientDrawerCreateForm";
import ClientDrawerUpdateForm from "../../../../../../client/components/drawers/ClientDrawerUpdateForm";
import ClientAutocompleteInput from "../../../../../../client/components/ClientAutocompleteInput_Deprecated";
import AuthorizedClientsSection from "../../../../../../client/components/AuthorizedClientsSection";

export interface Props {
  form: WrappedFormUtils;
  initialClientsData: VehicleGenFormClientsData;
  clientsData: VehicleCalcClientsData;
  selectedResult: CalcResult<VehicleCalcResultData>;
  clients: VehicleFormClients;
  authorizedClientsCount: number;
  clientsAutocomplete: ClientsAutocompleteProps;
  clientsDuplicateErrors: ClientFormType[];
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  onAuthorizedClientAdd(): void;
  onAuthorizedClientDelete(): void;
  onClientChange(client: Client, type: ClientFormType, omitValidation?: boolean, callback?: () => void): void;
  onClientViolationErrorsChange(violations: FieldConstraintViolation[], type: ClientFormType): void;
  onClientDuplicateErrorChange(isDuplicate: boolean, type: ClientFormType): void;
}

interface State {
  readonly authorizedClient1InputsValue: AuthorizedClientFormInputs;
  readonly authorizedClient2InputsValue: AuthorizedClientFormInputs;
  readonly processedClientFormType: ClientFormType;
  readonly clientCreateFormVisible: boolean;
  readonly clientUpdateFormVisible: boolean;
  readonly clientsStages: ClientsStagesState;
}

interface ClientsStagesState {
  readonly insurer: ClientFormStage;
  readonly owner: ClientFormStage;
  readonly authorized1: ClientFormStage;
  readonly authorized2: ClientFormStage;
}

class VehicleGenClientsDataSection extends React.Component<Props, State> {

  private static readonly CLIENTS_AUTOCOMPLETE_MAX_SIZE = 7;

  constructor(props: Props) {
    super(props);

    const stateObject = {
      authorizedClient1InputsValue: { identifier: undefined, function: undefined },
      authorizedClient2InputsValue: { identifier: undefined, function: undefined },
      processedClientFormType: null,
      clientCreateFormVisible: false,
      clientUpdateFormVisible: false,
      clientsStages: { insurer: null, owner: null, authorized1: null, authorized2: null }
    };

    if ( props.initialClientsData ) {
      const { insurerRelation, ownerRelation } = props.clientsData;
      const { authorizedClient1Function, authorizedClient2Function } = props.initialClientsData;
      const { insurer, owner, authorized1, authorized2 } = props.clients;

      if ( insurer && insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER ) {
        stateObject.clientsStages.insurer = ClientFormStage.EXISTING;
      }

      if ( owner && ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER ) {
        stateObject.clientsStages.owner = ClientFormStage.EXISTING
      }

      if ( authorized1 ) {
        stateObject.clientsStages.authorized1 = ClientFormStage.EXISTING;
        stateObject.authorizedClient1InputsValue = {
          identifier: authorized1.identifier,
          function: authorizedClient1Function
        }
      }

      if ( authorized2 ) {
        stateObject.clientsStages.authorized2 = ClientFormStage.EXISTING;
        stateObject.authorizedClient2InputsValue = {
          identifier: authorized2.identifier,
          function: authorizedClient2Function
        }
      }
    }

    this.state = stateObject;
  }

  resolveClientTypeByClientFormType = (type: ClientFormType): ClientType => {
    switch ( type ) {
      case ClientFormType.INSURER:
        return ClientType.NATURAL;
      case ClientFormType.OWNER:
        return this.props.clientsData.leasing ? ClientType.LEGAL : ClientType.NATURAL;
      case ClientFormType.AUTHORIZED_1:
      case ClientFormType.AUTHORIZED_2:
        return ClientType.NATURAL;
      default:
        return null;
    }
  };

  resolveClientsAutocompleteOptions = (): DataSourceItemObject[] => {
    const allIdentifiers = this.getAllClientFormIdentifiers(this.state.processedClientFormType);
    return this.props.clientsAutocomplete.result.data
      .filter(client => !contains(allIdentifiers, client.identifier))
      .map<DataSourceItemObject>(client => ({ value: client.identifier, text: client.identifier }));
  };

  handleClientsAutocompleteResultDelete = (): void => {
    if ( this.props.clientsAutocomplete.result.data.length > 0 ) {
      this.props.clientsAutocomplete.onResultDelete();
    }
  };

  handleClientsAutocompleteSearch = debounce(
    (value: string, type: ClientFormType): void => {
      if ( value && validationFunctions.validateSearchKeyword(value) ) {
        this.props.clientsAutocomplete.onSearch({
          keyword: value,
          clientType: this.resolveClientTypeByClientFormType(type),
          maxResultSize: VehicleGenClientsDataSection.CLIENTS_AUTOCOMPLETE_MAX_SIZE
        });
      }
      else {
        this.handleClientsAutocompleteResultDelete();
      }
    }, 500
  );

  handleClientsAutocompleteFocus = (type: ClientFormType): void => {
    if ( this.state.processedClientFormType !== type ) {
      this.setState({ processedClientFormType: type });
      this.handleClientsAutocompleteResultDelete();
    }
  };

  handleClientsAutocompleteSelect = (value: SelectValue, type: ClientFormType): void => {
    this.props.onClientChange(this.props.clientsAutocomplete.result.data.find(client => client.identifier === value), type);
    this.setClientFormStage(ClientFormStage.EXISTING, type);
    this.handleClientsAutocompleteResultDelete();
  };

  handleClientsAutocompleteChange = (value: SelectValue, type: ClientFormType): void => {
    if ( this.getClientFormStage(type) ) {
      this.setClientFormStage(null, type);
    }
    if ( this.getClientFromProps(type) ) {
      this.props.onClientChange(null, type);
    }
    if ( this.props.clientsViolationErrors.has(type) ) {
      this.props.onClientViolationErrorsChange(null, type);
    }
    if ( contains(this.props.clientsDuplicateErrors, type) ) {
      this.props.onClientDuplicateErrorChange(false, type);
    }
  };

  handleClientActionCreateClick = (type: ClientFormType): void => {
    this.setState({ processedClientFormType: type, clientCreateFormVisible: true });
  };

  handleClientActionUpdateClick = (type: ClientFormType): void => {
    this.setState({ processedClientFormType: type, clientUpdateFormVisible: true });
  };

  handleClientActionDeselectClick = (type: ClientFormType): void => {
    this.setClientFormIdentifier(null, type);
    this.setClientFormStage(null, type);
    this.props.onClientViolationErrorsChange(null, type);
    this.props.onClientChange(null, type);
  };

  handleClientUpdateFormSubmit = (client: Client, clientFormType: ClientFormType): void => {
    this.setState({ processedClientFormType: null, clientUpdateFormVisible: false });
    this.setClientFormStage(ClientFormStage.SELECTED, clientFormType);
    this.props.onClientViolationErrorsChange(null, clientFormType);
    this.props.onClientChange(client, clientFormType);
  };

  handleClientCreateFormSubmit = (client: Client, clientFormType: ClientFormType): void => {
    this.setState({ processedClientFormType: null, clientCreateFormVisible: false });
    this.setClientFormStage(ClientFormStage.SELECTED, clientFormType);
    this.props.onClientChange(client, clientFormType);
  };

  handleAuthorizedClientFormValueChange = (value: AuthorizedClientFormInputs, type: ClientFormType): void => {
    switch ( type ) {
      case ClientFormType.AUTHORIZED_1:
        this.setState({ authorizedClient1InputsValue: value });
        break;
      case ClientFormType.AUTHORIZED_2:
        this.setState({ authorizedClient2InputsValue: value });
        break;
    }
  };

  getClientFormIdentifier = (type: ClientFormType): string => {
    const { form } = this.props;
    switch ( type ) {
      case ClientFormType.INSURER:
        return form.getFieldValue("clientsData.insurerIdentifier");
      case ClientFormType.OWNER:
        return form.getFieldValue("clientsData.ownerIdentifier");
      case ClientFormType.AUTHORIZED_1:
        return form.getFieldValue("clientsData.authorizedClient1Identifier");
      case ClientFormType.AUTHORIZED_2:
        return form.getFieldValue("clientsData.authorizedClient2Identifier");
      default:
        return null;
    }
  };

  getClientFormStage = (type: ClientFormType): ClientFormStage => {
    const { clientsStages } = this.state;
    switch ( type ) {
      case ClientFormType.INSURER:
        return clientsStages.insurer;
      case ClientFormType.OWNER:
        return clientsStages.owner;
      case ClientFormType.AUTHORIZED_1:
        return clientsStages.authorized1;
      case ClientFormType.AUTHORIZED_2:
        return clientsStages.authorized2;
      default:
        return null;
    }
  };

  getClientFromProps = (type: ClientFormType): Client => {
    const { clients } = this.props;
    switch ( type ) {
      case ClientFormType.HOLDER:
        return clients.holder;
      case ClientFormType.INSURER:
        return clients.insurer;
      case ClientFormType.OWNER:
        return clients.owner;
      case ClientFormType.AUTHORIZED_1:
        return clients.authorized1;
      case ClientFormType.AUTHORIZED_2:
        return clients.authorized2;
      default:
        return null;
    }
  };

  getAllClientFormIdentifiers = (excludedType: ClientFormType): string[] => {
    return [this.props.clients.holder.identifier,
      ...Object.values([ClientFormType.INSURER, ClientFormType.OWNER, ClientFormType.AUTHORIZED_1, ClientFormType.AUTHORIZED_2])
        .filter(formType => formType !== excludedType)
        .map(formType => this.getClientFormIdentifier(formType))
        .filter(identifier => !!identifier)]
  };

  setClientFormStage = (stage: ClientFormStage, type: ClientFormType): void => {
    switch ( type ) {
      case ClientFormType.INSURER:
        this.setState(previousState => ({ clientsStages: { ...previousState.clientsStages, insurer: stage } }));
        break;
      case ClientFormType.OWNER:
        this.setState(previousState => ({ clientsStages: { ...previousState.clientsStages, owner: stage } }));
        break;
      case ClientFormType.AUTHORIZED_1:
        this.setState(previousState => ({ clientsStages: { ...previousState.clientsStages, authorized1: stage } }));
        break;
      case ClientFormType.AUTHORIZED_2:
        this.setState(previousState => ({ clientsStages: { ...previousState.clientsStages, authorized2: stage } }));
        break;
    }
  };

  setClientFormIdentifier = (identifier: string, type: ClientFormType): void => {
    const { form } = this.props;
    switch ( type ) {
      case ClientFormType.INSURER:
        form.setFieldsValue({ "clientsData.insurerIdentifier": identifier });
        break;
      case ClientFormType.OWNER:
        form.setFieldsValue({ "clientsData.ownerIdentifier": identifier });
        break;
      case ClientFormType.AUTHORIZED_1:
        form.setFieldsValue({ "clientsData.authorizedClient1Identifier": identifier });
        break;
      case ClientFormType.AUTHORIZED_2:
        form.setFieldsValue({ "clientsData.authorizedClient2Identifier": identifier });
        break;
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { result } = this.props.clientsAutocomplete;
    if ( result.id && result.data.length <= 1 && result.id !== prevProps.clientsAutocomplete.result.id ) {
      const { processedClientFormType } = this.state;
      const formIdentifier = this.getClientFormIdentifier(processedClientFormType);

      if ( formIdentifier && validationFunctions.validateClientIdentifier(formIdentifier,
        this.resolveClientTypeByClientFormType(processedClientFormType)) ) {
        if ( contains(this.getAllClientFormIdentifiers(processedClientFormType), formIdentifier) ) {
          this.props.onClientDuplicateErrorChange(true, processedClientFormType);
        }
        else if ( result.data.length === 0 ) {
          this.setClientFormStage(ClientFormStage.NEW, processedClientFormType);
        }
        else if ( result.data.length === 1 ) {
          const previousClient = this.getClientFromProps(processedClientFormType);
          if ( formIdentifier === result.data[0].identifier && (!previousClient || previousClient.identifier !== formIdentifier) ) {
            this.props.onClientChange(result.data[0], processedClientFormType);
            this.setClientFormStage(ClientFormStage.EXISTING, processedClientFormType);
          }
        }
      }
      else {
        this.setClientFormStage(null, processedClientFormType);
      }
    }
  }

  render(): React.ReactNode {
    const { processedClientFormType, clientsStages } = this.state;
    const { form, clientsData, clientsAutocomplete } = this.props;
    const { institutionEnum } = this.props.selectedResult.insuranceInstitution;
    const { holder, insurer, owner, authorized1, authorized2 } = this.props.clients;
    const { getFieldDecorator } = form;
    const colSpan = 4;

    const clientAutocompleteCommonProps = {
      form,
      processedClientFormType,
      autocompleteInProgress: clientsAutocomplete.inProgress,
      options: this.resolveClientsAutocompleteOptions(),
      inputColSpan: colSpan + 2,
      clientNameColSpan: colSpan + 3,
      clientsViolationErrors: this.props.clientsViolationErrors,
      clientsDuplicateErrors: this.props.clientsDuplicateErrors,
      onClientActionCreateClick: this.handleClientActionCreateClick,
      onClientActionUpdateClick: this.handleClientActionUpdateClick,
      onClientActionDeselectClick: this.handleClientActionDeselectClick,
      onClientsAutocompleteFocus: this.handleClientsAutocompleteFocus,
      onClientsAutocompleteSearch: this.handleClientsAutocompleteSearch,
      onClientsAutocompleteSelect: this.handleClientsAutocompleteSelect,
      onClientsAutocompleteChange: this.handleClientsAutocompleteChange
    };

    return (
      <>
        <Card size="small" type="inner" className="card-box" title={t("calc.vehicle.sections.clientsData")}>

          <div className="sub-header-info normal-font-size margin-bottom-small">
            <span>{t("calc.vehicle.sections.holder")}: </span>{holder.aggregatedName} ({holder.identifier})
            {clientsData.insurerRelation === VehicleInsurerRelation.SAME_AS_HOLDER && (
              <>
                <br /><span>{t("calc.vehicle.sections.insurer")}: </span>{t("calc.vehicle.sections.insurerSameAsHolder")}
              </>
            )}
            {clientsData.ownerRelation === VehicleOwnerRelation.SAME_AS_INSURER && (
              <>
                <br /><span>{t("calc.vehicle.sections.owner")}: </span>{t("calc.vehicle.sections.ownerSameAsInsurer")}
              </>
            )}
            {clientsData.ownerRelation === VehicleOwnerRelation.SAME_AS_HOLDER && (
              <>
                <br /><span>{t("calc.vehicle.sections.owner")}: </span>{t("calc.vehicle.sections.ownerSameAsHolder")}
              </>
            )}
          </div>

          <Row gutter={rowGutter}>
            {clientsData.insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER && (
              <ClientAutocompleteInput
                formKey="clientsData.insurerIdentifier"
                formType={ClientFormType.INSURER}
                formStage={clientsStages.insurer}
                formInputOptions={{ rules: [validations.notBlank, validations.pin] }}
                label={t("calc.vehicle.attrs.clientsData.insurerIdentifier")}
                client={insurer}
                {...clientAutocompleteCommonProps} />
            )}

            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.attrs.clientsData.insurerEmail")}>
                {getFieldDecorator("clientsData.insurerEmail", {
                  rules: [validations.notBlank, validations.size(1, 254), validations.email]
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicle.attrs.clientsData.insurerPhone")}>
                {getFieldDecorator("clientsData.insurerPhone", {
                  rules: [validations.notBlank, validations.size(1, 255), validations.mobilePhoneNumber],
                  normalize: phoneNumberNormalizeFunction
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>

          {clientsData.ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER && (
            <Row gutter={rowGutter}>
              <ClientAutocompleteInput
                formKey="clientsData.ownerIdentifier"
                formType={ClientFormType.OWNER}
                formStage={clientsStages.owner}
                formInputOptions={{
                  rules: [validations.notBlank, clientsData.leasing ? validations.crn : validations.pin]
                }}
                label={clientsData.leasing
                  ? t("calc.vehicle.attrs.clientsData.ownerLeasingIdentifier")
                  : t("calc.vehicle.attrs.clientsData.ownerIdentifier")}
                client={owner}
                {...clientAutocompleteCommonProps} />
            </Row>
          )}

          {institutionEnum === InstitutionEnum.CSOB && (
            <>
              <Row gutter={rowGutter}>
                <Col span={colSpan * 3}>
                  <Form.Item>
                    {getFieldDecorator("clientsData.insurerPolicePenaltyInLast3Years", {
                      rules: [validations.none], valuePropName: "checked", initialValue: false
                    })(
                      <Checkbox>{t("calc.vehicle.attrs.clientsData.insurerPolicePenaltyInLast3Years")}</Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={rowGutter}>
                <Col span={colSpan * 3}>
                  <Form.Item>
                    {getFieldDecorator("clientsData.insurerContractCancelledInLast3Years", {
                      rules: [validations.none], valuePropName: "checked", initialValue: false
                    })(
                      <Checkbox>{t("calc.vehicle.attrs.clientsData.insurerContractCancelledInLast3Years")}</Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <AuthorizedClientsSection
            visible={resolveVehicleInsurerType(this.props.clients, clientsData.insurerRelation) === ClientType.LEGAL}
            clientsCount={this.props.authorizedClientsCount}
            client1Props={{
              formStage: clientsStages.authorized1,
              formValue: this.state.authorizedClient1InputsValue,
              client: authorized1
            }}
            client2Props={{
              formStage: clientsStages.authorized2,
              formValue: this.state.authorizedClient2InputsValue,
              client: authorized2
            }}
            autocompleteProps={clientAutocompleteCommonProps}
            onValueChange={this.handleAuthorizedClientFormValueChange}
            onClientAdd={this.props.onAuthorizedClientAdd}
            onClientDelete={this.props.onAuthorizedClientDelete}
            onClientChange={this.props.onClientChange}
            onClientViolationErrorsChange={this.props.onClientViolationErrorsChange}
            onClientDuplicateErrorChange={this.props.onClientDuplicateErrorChange}
            onClientFormStageChange={this.setClientFormStage} />

        </Card>

        <ClientDrawerCreateForm
          visible={this.state.clientCreateFormVisible}
          initialClientType={this.resolveClientTypeByClientFormType(processedClientFormType)}
          initialIdentifier={this.getClientFormIdentifier(processedClientFormType)}
          formType={processedClientFormType}
          onFormSubmit={this.handleClientCreateFormSubmit} />

        <ClientDrawerUpdateForm
          visible={this.state.clientUpdateFormVisible}
          client={this.getClientFromProps(processedClientFormType)}
          formType={processedClientFormType}
          violationErrors={this.props.clientsViolationErrors}
          onFormSubmit={this.handleClientUpdateFormSubmit} />

      </>
    )
  }
}

export default VehicleGenClientsDataSection;
