import React from "react";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";

export interface Props {
  onCreateBatchClick(): void;
}

const CommissionsBatchListHeaderView = ({ onCreateBatchClick }: Props) => (
  <Row gutter={rowGutter} className="margin-bottom-small">
    <Col span={12}>
      <h2>{t("commissions.batch.titles.batchesList")}</h2>
    </Col>
    <Col span={12} className="right-align">
      <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
        <Button type="primary" icon={<PlusOutlined />} onClick={onCreateBatchClick}>
          {t("commissions.batch.actions.addBatch")}
        </Button>
      </ComponentWithPermission>
    </Col>
  </Row>
);

export default CommissionsBatchListHeaderView;
