import React from "react";
import { Route, Switch } from "react-router";
import RouteWithPermission from "../../common/security/authorization/RouteWithPermission";
import { regexPatterns } from "../../common/utils/validationUtils";
import { Permission } from "../../common/security/authorization/enums";

import PersonListContainer from "./containers/PersonListContainer";
import PersonTreeContainer from "./containers/PersonTreeContainer";
import PersonCreateContainer from "./containers/PersonCreateContainer";
import PersonDetailContainer from "./containers/PersonDetailContainer";
import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission exact path="/persons"
                         component={PersonListContainer}
                         permissions={[Permission.PERSON]} />

    <RouteWithPermission exact path="/persons/tree"
                         component={PersonTreeContainer}
                         permissions={[Permission.PERSON]} />

    <RouteWithPermission exact path="/persons/new"
                         component={PersonCreateContainer}
                         permissions={[Permission.PERSON_CREATE]} />

    <RouteWithPermission exact path={`/persons/:id(${regexPatterns.uuidRegex.source})`}
                         component={PersonDetailContainer}
                         permissions={[Permission.PERSON]} />

    <Route component={NotFound} />
  </Switch>
);
