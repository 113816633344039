import "./copy-to-clipboard-view.less";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import messageUtils from "../../utils/messageUtils";
import t from "../../../app/i18n";

export interface Props {
  content: string;
  contentToCopy?: string;
}

const CopyToClipboardView = ({ content, contentToCopy }: Props) => {
  return content ? (
    <span className="copy-to-clipboard-view">
      {content}&nbsp;
      <CopyToClipboard
        text={contentToCopy || content}
        onCopy={() => messageUtils.infoMessage(t("common.clipboardCopied"))}>
        <Tooltip title={t("common.copy")} mouseEnterDelay={0.5}>
          <span><CopyOutlined /></span>
        </Tooltip>
      </CopyToClipboard>
    </span>
  ) : null;
};

export default CopyToClipboardView;
