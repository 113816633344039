import { AxiosPromise } from "axios";
import apiService from "../../common/api/apiService";
import { AuthData, LoginRequest } from "./types";
import requests from "./apiRequests";

export default {
  login: (request: LoginRequest): AxiosPromise<AuthData> => {
    return apiService.post<AuthData>(requests.LOGIN.url, request);
  },
  refreshToken: (): AxiosPromise<AuthData> => {
    return apiService.post<AuthData>(requests.REFRESH_TOKEN.url);
  }
}
