import React from "react";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import { ApiRequest, RootState } from "../../types";
import { selectIsNotFoundErrorResponse } from "../../../modules/ducks";

import NotFound from "../../pages/NotFound/NotFound";

export interface Props {
  children: React.ReactNode;
  itemLoaded: boolean;
  notFoundCheckRequest?: ApiRequest;
}

interface StateProps {
  hasNotFoundErrorResponse: boolean;
}

interface State {
  readonly itemNotFound: boolean;
}

class DisplayWrapper extends React.Component<Props & StateProps, State> {
  readonly state: State = {
    itemNotFound: false
  };

  componentDidUpdate(prevProps: Readonly<Props & StateProps>): void {
    if ( !prevProps.hasNotFoundErrorResponse && this.props.hasNotFoundErrorResponse ) {
      this.setState({ itemNotFound: true });
    }
  }

  render(): React.ReactNode {
    return this.props.itemLoaded
      ? <>{this.props.children}</>
      : this.state.itemNotFound ? <NotFound /> : (
        <>
          <Skeleton active paragraph={{ rows: 6 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 2 }} />
        </>
      );
  }
}

const mapStateToProps = (state: RootState, props: Props): StateProps => ({
  hasNotFoundErrorResponse: props.notFoundCheckRequest ? selectIsNotFoundErrorResponse(state, props.notFoundCheckRequest) : false
});

export default connect<StateProps, {}, Props, RootState>(mapStateToProps)(DisplayWrapper);
