import React from "react";
import { IdcardOutlined } from "@ant-design/icons";
import { Address } from "../types";
import { formatAddress } from "../../utils/formatUtils";

export interface Props {
  address: Address;
  showIcon?: boolean;
  showCountry?: boolean;
}

const AddressView = ({ address, showIcon, showCountry }: Props) => (
  address ? (
    <>
      {showIcon ? <><IdcardOutlined /> </> : null}
      {formatAddress(address, showCountry)}
    </>
  ) : null
);

export default AddressView;
