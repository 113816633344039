import React, { useState } from "react";
import { Button, Col, Modal, Popconfirm, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CloseOutlined } from "@ant-design/icons";
import { JobRecord, JobSettings } from "../../types";
import { JobState } from "../../enums";
import { abortJobActions, cancelJobActions, filterJobRecordsActions } from "../../ducks";
import { isDefinedValue } from "../../../../../common/utils/utils";
import { formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { paginationStandardProps } from "../../../../../common/utils/formUtils";
import { initNonEmptyPageResult, initPageResult } from "../../../../../common/utils/apiUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import JobStateTag from "../JobStateTag";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  job: JobSettings;
  onFilter: typeof filterJobRecordsActions.request;
  onCancel: typeof cancelJobActions.request;
  onAbort: typeof abortJobActions.request;
}

const PAGE_SIZE = 5;

const JobRecordsTableView = ({ job, ...props }: Props) => {

  const [resultToShow, setResultToShow] = useState<any>();

  const handleShowOtherRecordsClick = (): void => {
    props.onFilter({ id: job.id, object: { pageIndex: 0, pageSize: PAGE_SIZE } });
  };

  const handleTablePageChange = (pageNumber: number): void => {
    props.onFilter({ id: job.id, object: { pageIndex: pageNumber - 1, pageSize: PAGE_SIZE } });
  };

  const columns: ColumnsType<JobRecord> = [
    {
      key: "createdAt",
      title: t("admin.job.attrs.record.createdAt"),
      render: (text, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "state",
      title: t("admin.job.enums.jobState._label"),
      render: (text, record) => <JobStateTag state={record.state} />
    },
    {
      key: "startedAt",
      title: t("admin.job.attrs.record.startedAt"),
      render: (text, record) => formatLocaleDateTime(record.startedAt)
    },
    {
      key: "finishedAt",
      title: t("admin.job.attrs.record.finishedAt"),
      render: (text, record) => formatLocaleDateTime(record.finishedAt)
    },
    {
      key: "manuallyScheduledFor",
      title: t("admin.job.attrs.record.manuallyScheduledFor"),
      render: (text, record) => formatLocaleDateTime(record.manuallyScheduledFor)
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (text, record) => {
        switch ( record.state ) {
          case JobState.MANUALLY_SCHEDULED:
            return <Popconfirm
              title={t("admin.job.helpers.cancelJobConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={() => props.onCancel({ id1: job.id, id2: record.id })}>
              <ActionTextIcon type="close" color="orange" text={t("common.cancel")} />
            </Popconfirm>
          case JobState.IN_PROGRESS:
            return <Popconfirm
              title={t("admin.job.helpers.abortJobConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={() => props.onAbort({ id1: job.id, id2: record.id })}>
              <ActionTextIcon type="close" color="red" text={t("common.cancel")} />
            </Popconfirm>
          default:
            return <ActionTextIcon type="eye" color="blue" text={t("admin.job.actions.showResult")}
                                   disabled={!isDefinedValue(record.result)}
                                   onClick={() => setResultToShow(record.result)} />
        }
      }
    }
  ];

  const recordsPage = job.records
    ? job.records
    : job.mostRecentRecord
      ? initNonEmptyPageResult<JobRecord>([job.mostRecentRecord], PAGE_SIZE)
      : initPageResult<JobRecord>();

  return (
    <>
      <p className="margin-top-small">
        <b>{t("admin.job.sections.mostRecentRecords")}</b>
      </p>

      <Table<JobRecord>
        size="small"
        className="table-row-stripped"
        rowKey="id"
        columns={columns}
        dataSource={recordsPage.pageData}
        pagination={{
          ...paginationStandardProps,
          hideOnSinglePage: true,
          position: ["bottomRight"],
          current: recordsPage.pageIndex + 1,
          pageSize: recordsPage.pageSize,
          total: recordsPage.totalElementsCount,
          onChange: handleTablePageChange
        }} />

      {!job.records ?
        <Row gutter={rowGutter}>
          <Col span={24} className="center-align">
            <Button size="small" className="blue-button margin-top-small" onClick={handleShowOtherRecordsClick}>
              {t("admin.job.actions.showOtherRecords")}
            </Button>
          </Col>
        </Row> : null}

      <Modal
        visible={isDefinedValue(resultToShow)}
        title={t("admin.job.titles.jobResult", { jobName: job.name })}
        width={700}
        centered
        onCancel={() => setResultToShow(null)}
        footer={
          <Button onClick={() => setResultToShow(null)} icon={<CloseOutlined />}>{t("common.close")}</Button>
        }>
        {resultToShow && (
          <Row gutter={rowGutter} justify="center">
            <Col span={20}>
              <pre className="modal-code-block">
                {JSON.stringify(resultToShow, null, 2)}
              </pre>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  )
}

export default JobRecordsTableView;
