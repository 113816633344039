import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Col, Divider, Popconfirm, Row, Table, Upload } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CommissionsBatch, CommissionsBatchInputAttachment, CommissionsUnit } from "../../../../types";
import { RootState } from "../../../../../../../common/types";
import { CommissionSourceTemplate } from "../../../../../../enumerations/types";
import {
  deleteCommissionsBatchAttachmentActions,
  replaceCommissionsBatchAttachmentActions,
  uploadCommissionsBatchAttachmentActions
} from "../../../../ducks";
import { selectCommissionSourceTemplates } from "../../../../../../enumerations/ducks";
import {
  formatFileSize,
  formatLocaleCurrencyWithNullAsZero,
  formatLocaleDateTime
} from "../../../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import StatusTag from "../../../../../../../common/components/tags/StatusTag";
import AddDeleteButton from "../../../../../../../common/components/buttons/AddDeleteButton";
import FileTypeIcon from "../../../../../../../common/components/icons/FileTypeIcon";
import ActionLinkIcon from "../../../../../../../common/components/icons/ActionLinkIcon";
import ActionTextIcon from "../../../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../../../common/components/icons/PopconfirmDeleteIcon";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  batch: CommissionsBatch;
  unit: CommissionsUnit;
  onUpload: typeof uploadCommissionsBatchAttachmentActions.request;
  onReplace: typeof replaceCommissionsBatchAttachmentActions.request;
  onDelete: typeof deleteCommissionsBatchAttachmentActions.request;
}

const CommissionsUnitAttachmentsTableView = (props: Props) => {

  const sourceTemplates = useSelector<RootState, CommissionSourceTemplate[]>(selectCommissionSourceTemplates);

  const [sourceChecked, setSourceChecked] = useState<boolean>(false);
  const [defaultTemplateChecked, setDefaultTemplateChecked] = useState<boolean>(false);

  const handleSourceCheckedChange = (event: CheckboxChangeEvent): void => {
    setSourceChecked(event.target.checked);
    if ( !event.target.checked && defaultTemplateChecked ) {
      setDefaultTemplateChecked(false);
    }
  };

  const handleAttachmentsUpload = (file: RcFile,
                                   fileList: RcFile[],
                                   replacedAttachment?: CommissionsBatchInputAttachment): boolean => {
    if ( fileList.length > 0 ) {
      const formData = new FormData();
      formData.append("file", file);

      if ( replacedAttachment ) {
        formData.append("source", replacedAttachment.source.toString());
        formData.append("forceDefaultImporter", "false");
        props.onReplace({ id1: props.batch.id, id2: replacedAttachment.id, object: formData });
      }
      else {
        formData.append("institutionId", props.unit.institution.id);
        formData.append("source", sourceChecked.toString());
        formData.append("forceDefaultImporter", defaultTemplateChecked.toString());
        props.onUpload({ id: props.batch.id, object: formData });
      }

      fileList.splice(0, 1);
    }

    return false;
  };

  const resolveSourceAttachmentLabel = (): React.ReactNode => {
    const columns = sourceTemplates.find(t => t.institution?.id === props.unit.institution.id)?.expectedColumns.join(", ");
    return columns
      ? <LabelWithTooltip label={t("commissions.batch.attrs.attachment.source")}
                          tooltip={t("commissions.batch.helpers.sourceAttachmentHint") + columns} />
      : t("commissions.batch.attrs.attachment.source");
  };

  const resolveDefaultTemplateLabel = (): React.ReactNode => {
    const columns = sourceTemplates.find(t => !t.institution)?.expectedColumns.join(", ");
    return columns
      ? <LabelWithTooltip label={t("commissions.batch.helpers.useDefaultTemplate")}
                          tooltip={t("commissions.batch.helpers.defaultSourceAttachmentHint") + columns} />
      : t("commissions.batch.helpers.useDefaultTemplate");
  };

  const columns: ColumnsType<CommissionsBatchInputAttachment> = [
    {
      key: "filename",
      title: t("common.filename"),
      render: (value, record) => <><FileTypeIcon contentType={record.contentType} /> {record.filename}</>
    },
    {
      key: "hasImportErrors",
      render: (value, record) => record.hasImportErrors
        && <StatusTag status="error" tooltip={t("commissions.batch.helpers.importedAttachmentError")} />
    },
    {
      key: "createdAt",
      title: t("common.createdAt"),
      render: (value, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "source",
      title: t("commissions.batch.attrs.attachment.source"),
      render: (value, record) => record.source ? <CheckOutlined /> : <CloseOutlined />
    },
    {
      key: "importedCommissionAmount",
      title: t("commissions.batch.attrs.attachment.importedCommissionAmount"),
      className: "table-column-right-align",
      render: (text, record) => record.source ? formatLocaleCurrencyWithNullAsZero(record.importedCommissionAmount) : null
    },
    {
      key: "postponedCommissionAmount",
      title: t("commissions.batch.attrs.attachment.postponedCommissionAmount"),
      className: "table-column-right-align",
      render: (text, record) => record.source ? formatLocaleCurrencyWithNullAsZero(record.postponedCommissionAmount) : null
    },
    {
      key: "size",
      title: t("common.size"),
      render: (value, record) => formatFileSize(record.size)
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (text, record) => (
        <>
          {record.source && (
            <>
              <Upload
                showUploadList={false}
                accept={".csv"}
                beforeUpload={(file, fileList) => handleAttachmentsUpload(file, fileList, record)}>
                <ActionTextIcon type="sync" color="orange" text={t("common.replace")} />
              </Upload>

              <Divider type="vertical" />

              <ActionLinkIcon type="eye" color="green" text={t("common.show")}
                              path={`/commissions/batches/${props.batch.id}/attachments/${record.id}/imports`} />

              <Divider type="vertical" />
            </>
          )}

          <ActionLinkIcon path={`/commissions/batches/${props.batch.id}/attachments/${record.id}`}
                          target="_blank" type="open" color="blue" text={t("common.open")} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("commissions.batch.helpers.deleteAttachment")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="primary"
            onConfirm={() => props.onDelete({
              id1: props.batch.id,
              id2: record.id
            })}>
            <ActionTextIcon type="delete" color="red" text={t("common.delete")} />
          </Popconfirm>
        </>
      )
    }
  ];

  const attachments = (props.batch.attachments as CommissionsBatchInputAttachment[])
    .filter(attachment => attachment.institution?.id === props.unit.institution.id);

  return (
    <Row gutter={rowGutter} justify="center" className="margin-top-tiny margin-bottom-medium">
      <Col span={24} style={{ maxWidth: "90%" }}>

        <Row gutter={rowGutter} className="margin-bottom-medium">
          <Col span={24}>
            <b>{t("commissions.batch.helpers.manuallyAddedCommissions")}: </b>

            {props.unit.manuallyAddedCommissionAmount > 0 ? (
              <span>
                {t("common.all")}: {formatLocaleCurrencyWithNullAsZero(props.unit.manuallyAddedCommissionAmount)} |&nbsp;
                {t("common.postponed")}: {formatLocaleCurrencyWithNullAsZero(props.unit.manuallyAddedPostponedCommissionAmount)}
              </span>
            ) : (
              <span className="sub-header-info dashed">{t("common.none")}</span>
            )}

            <ActionLinkIcon type="manage" color="blue" className="margin-left-small"
                            text={t("commissions.batch.actions.manageManualImports")}
                            path={`/commissions/batches/${props.batch.id}/institutions/${props.unit.institution.id}/manual-imports`} />
          </Col>
        </Row>

        <Table<CommissionsBatchInputAttachment>
          size="small"
          className="table-row-stripped no-header-background"
          rowKey="id"
          columns={columns}
          dataSource={attachments}
          pagination={false} />

        <Row gutter={rowGutter} className="margin-top-medium">
          <Col span={24}>
            <Upload
              showUploadList={false}
              accept={sourceChecked ? ".csv" : undefined}
              beforeUpload={handleAttachmentsUpload}>
              <AddDeleteButton type="add" label={t("commissions.batch.actions.addAttachment")} />
            </Upload>

            <Checkbox
              className="margin-left-small"
              checked={sourceChecked}
              onChange={handleSourceCheckedChange}>
              {resolveSourceAttachmentLabel()}
            </Checkbox>

            <Checkbox
              className="margin-left-small"
              checked={defaultTemplateChecked}
              disabled={!sourceChecked}
              onChange={e => setDefaultTemplateChecked(e.target.checked)}>
              {resolveDefaultTemplateLabel()}
            </Checkbox>
          </Col>
        </Row>

      </Col>
    </Row>
  );
};

export default CommissionsUnitAttachmentsTableView;
