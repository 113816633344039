import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { Modal } from "antd";
import api from "./api";
import { MyIdentity, MyIdentityReducerState, UpdateMyIdentity } from "./types";
import { ActionCreator, RootState } from "../../common/types";
import { changeLocationKeyAction } from "../ducks";
import { logoutAction } from "../auth/ducks";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../common/utils/reduxUtils";
import t from "../../app/i18n";

/**
 * ACTION TYPES
 */
export enum actionType {
  GET = 'my-identity/GET',
  UPDATE = 'my-identity/UPDATE',
  DELETE_STATE_CURRENT_USER = 'my-identity/DELETE_STATE_CURRENT_USER'
}

/**
 * ACTIONS
 */
export const getMyIdentityActions = createApiActionCreators<void, MyIdentity>(actionType.GET);
export const updateMyIdentityActions = createApiActionCreators<UpdateMyIdentity, MyIdentity>(actionType.UPDATE);

export const deleteStateMyIdentityCurrentUserAction = createActionCreator<void>(actionType.DELETE_STATE_CURRENT_USER);

/**
 * REDUCERS
 */
const initialState: MyIdentityReducerState = {
  currentUser: null
};

const currentUserReducer = createReducer<MyIdentity>(initialState.currentUser, {
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentUser
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_CURRENT_USER]: () => initialState.currentUser
});

export default combineReducers({ currentUser: currentUserReducer });

/**
 * SELECTORS
 */
const selectMyIdentity = (state: RootState): MyIdentityReducerState => state.myIdentity;

export const selectMyIdentityCurrentUser = (state: RootState): MyIdentity => selectMyIdentity(state).currentUser;

/**
 * SAGAS
 */
function* getMyIdentity() {
  try {
    const response: AxiosResponse<MyIdentity> = yield call(api.getMyIdentity);
    yield put(getMyIdentityActions.success(response.data));
  }
  catch ( error ) {
    yield put(getMyIdentityActions.failure(error));
  }
}

function* updateMyIdentity({ payload }: ActionCreator<UpdateMyIdentity>) {
  try {
    const response: AxiosResponse<MyIdentity> = yield call(api.updateMyIdentity, payload);

    const previousUsersData: MyIdentity = yield select(selectMyIdentityCurrentUser);
    yield put(updateMyIdentityActions.success(response.data));
    yield put(changeLocationKeyAction());

    if ( payload.account.newPassword || previousUsersData.account.username !== payload.account.username ) {
      yield new Promise(resolve =>
        Modal.success({
          title: t("common.operationSuccess"),
          content: t("myIdentity.helpers.updateAccountOrPasswordSuccess"),
          okText: t("common.ok"),
          onOk: () => resolve()
        })
      );
      yield put(logoutAction());
    }
  }
  catch ( error ) {
    yield put(updateMyIdentityActions.failure(error));
  }
}

export function* myIdentitySaga() {
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getMyIdentity);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateMyIdentity);
}
