import React, { useEffect } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { LifeInsuranceTariffFilterPageRequest, LifeInsuranceTariffFilterPageResult } from "../../types";
import { ProductType } from "../../../product/enums";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import ProductsEnumFormItemSelect from "../../../../enumerations/components/form/ProductsEnumFormItemSelect";

interface Props {
  initialFilter: LifeInsuranceTariffFilterPageResult;
  onFilterSubmit(filter: LifeInsuranceTariffFilterPageRequest): void;
}

const LifeInsuranceTariffSearchView = (props: Props) => {

  const [form] = Form.useForm<LifeInsuranceTariffFilterPageRequest>();

  const colSpan = 8;

  useEffect(() => {
    form.setFieldsValue({
      productIds: props.initialFilter.productIds,
      keyword: props.initialFilter.keyword
    });
  }, [props.initialFilter, form]);

  return (
    <Card
      title={t("admin.lifeInsuranceTariff.titles.filter")}
      size="small"
      className="card-filter"
      style={{ maxWidth: "1000px" }}
    >
      <Form form={form} onFinish={props.onFilterSubmit} layout="vertical" name="lifeInsuranceTariffSearchForm">
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <ProductsEnumFormItemSelect
              formItemProps={{
                name: "productIds",
                label: t("admin.lifeInsuranceTariff.attrs.productId"),
                rules: [validations.none]
              }}
              optionsProps={{
                filterType: ProductType.INSURANCE_PRODUCT,
                groupByProductGroup: true,
              }}
              selectProps={{
                allowClear: true
              }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.search")}
              rules={[validations.size(validationConstants.SEARCH_KEYWORD_MIN_LENGTH, validationConstants.SEARCH_KEYWORD_MAX_LENGTH)]}
            >
              <Input allowClear placeholder={t("admin.lifeInsuranceTariff.helpers.searchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan} className="center-align">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t("common.filter")}</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default LifeInsuranceTariffSearchView;
