import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { ActionProps, RootState } from "../../../../common/types";
import { CalcRecord, CalcRecordFilterPageRequest, CalcRecordFilterPageResult } from "../types";
import { PersonBase } from "../../../person/types";
import { CalcType } from "../../enums";
import { OperationType } from "../enums";
import {
  deleteStateCalcRecordDetailAction,
  deleteStateCalcRecordsPageAction,
  filterCalcRecordsActions,
  getCalcRecordAndRedirectToCalculatorActions,
  getCalcRecordRequestResponseActions,
  getCalcRecordStackTracesLogsActions,
  getCalcRecordWebservicesLogsActions,
  selectCalcRecordDetail,
  selectCalcRecordsCurrentPage
} from "../ducks";
import { selectPersonsEnumerations } from "../../../enumerations/ducks";
import { selectRouterLocationSearch } from "../../../ducks";
import { serializeParams } from "../../../../common/utils/apiUtils";
import { appendSearchParamsToCurrentPathname } from "../../../../common/utils/utils";

import CalcRecordsListFilterView from "../components/views/CalcRecordsListFilterView";
import CalcRecordsListTableView from "../components/views/CalcRecordsListTableView";
import CalcRecordRequestResponseLogsModal from "../components/modals/CalcRecordRequestResponseLogsModal";
import CalcRecordWebservicesLogsModal from "../components/modals/CalcRecordWebservicesLogsModal";
import CalcRecordStackTracesLogsModal from "../components/modals/CalcRecordStackTracesLogsModal";

interface StateProps {
  calcRecordsCurrentPage: CalcRecordFilterPageResult;
  calcRecord: CalcRecord;
  personsEnumerations: PersonBase[];
  initialUrlSearchQuery: string;
}

interface ActionsMap {
  filterCalcRecords: typeof filterCalcRecordsActions.request;
  getCalcRecordRequestResponse: typeof getCalcRecordRequestResponseActions.request;
  getCalcRecordWebservicesLogs: typeof getCalcRecordWebservicesLogsActions.request;
  getCalcRecordStackTracesLogs: typeof getCalcRecordStackTracesLogsActions.request;
  getCalcRecordAndRedirectToCalculator: typeof getCalcRecordAndRedirectToCalculatorActions.request;
  deleteStateCalcRecordsPage: typeof deleteStateCalcRecordsPageAction;
  deleteStateCalcRecordDetail: typeof deleteStateCalcRecordDetailAction;
  replaceNavigation: typeof replace;
}

interface State {
  readonly initialFilter: CalcRecordFilterPageRequest;
  readonly requestResponseLogsModalVisible: boolean;
  readonly webserviceLogsModalVisible: boolean;
  readonly stackTraceLogsModalVisible: boolean;
}

class CalcRecordsContainer extends React.Component<StateProps & ActionProps<ActionsMap>, State> {
  private static readonly PAGE_SIZE = 25;

  readonly state: State = {
    initialFilter: {
      pageIndex: 0,
      pageSize: CalcRecordsContainer.PAGE_SIZE,
      operationType: undefined,
      calcType: undefined,
      personId: undefined
    },
    requestResponseLogsModalVisible: false,
    webserviceLogsModalVisible: false,
    stackTraceLogsModalVisible: false
  };

  handleTablePageChange = (pageNumber: number): void => {
    const { operationType, calcType, personId } = this.props.calcRecordsCurrentPage;
    this.props.actions.filterCalcRecords({
      pageIndex: pageNumber - 1,
      pageSize: CalcRecordsContainer.PAGE_SIZE,
      operationType,
      calcType,
      personId
    });
  };

  handleFilterSubmit = (filter: CalcRecordFilterPageRequest): void => {
    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(serializeParams(filter)));
    this.props.actions.filterCalcRecords({
      ...filter,
      pageIndex: 0,
      pageSize: CalcRecordsContainer.PAGE_SIZE
    })
  };

  handleLogsModalsCancelClick = (): void => {
    this.setState({
      requestResponseLogsModalVisible: false,
      webserviceLogsModalVisible: false,
      stackTraceLogsModalVisible: false
    });
  };

  handleLogsModalsAfterCloseExecuted = (): void => {
    this.props.actions.deleteStateCalcRecordDetail();
  };

  componentDidMount(): void {
    const urlParams = new URLSearchParams(this.props.initialUrlSearchQuery);
    const personIdUrlParam = urlParams.get("personId");
    const initialFilter: CalcRecordFilterPageRequest = {
      pageIndex: 0,
      pageSize: CalcRecordsContainer.PAGE_SIZE,
      calcType: CalcType[urlParams.get("calcType")],
      operationType: OperationType[urlParams.get("operationType")],
      personId: personIdUrlParam && this.props.personsEnumerations.some(person => person.id === personIdUrlParam) ? personIdUrlParam : undefined
    };

    this.props.actions.filterCalcRecords(initialFilter);
    this.setState({ initialFilter });
  }

  componentWillUnmount(): void {
    this.props.actions.deleteStateCalcRecordsPage();
    this.props.actions.deleteStateCalcRecordDetail();
  }

  componentDidUpdate(prevProps: Readonly<StateProps & ActionProps<ActionsMap>>): void {
    if ( !prevProps.calcRecord && this.props.calcRecord ) {
      const { calcRecord } = this.props;

      if ( calcRecord.request && calcRecord.response ) {
        this.setState({ requestResponseLogsModalVisible: true });
      }
      else if ( calcRecord.webservicesLogs && calcRecord.webservicesLogs.length > 0 ) {
        this.setState({ webserviceLogsModalVisible: true });
      }
      else if ( calcRecord.stackTracesLogs && calcRecord.stackTracesLogs.length > 0 ) {
        this.setState({ stackTraceLogsModalVisible: true });
      }
    }
  }

  render(): React.ReactNode {
    const { calcRecord } = this.props;

    return (
      <>
        <CalcRecordsListFilterView
          initialFilter={this.state.initialFilter}
          onFilterSubmit={this.handleFilterSubmit} />

        <CalcRecordsListTableView
          calcRecordsPage={this.props.calcRecordsCurrentPage}
          onPageChange={this.handleTablePageChange}
          onRequestResponseLogsClick={this.props.actions.getCalcRecordRequestResponse}
          onWebserviceLogsClick={this.props.actions.getCalcRecordWebservicesLogs}
          onStackTraceLogsClick={this.props.actions.getCalcRecordStackTracesLogs}
          onShowInCalcFormClick={this.props.actions.getCalcRecordAndRedirectToCalculator} />

        <CalcRecordRequestResponseLogsModal
          visible={this.state.requestResponseLogsModalVisible}
          record={calcRecord}
          onCancelClick={this.handleLogsModalsCancelClick}
          onAfterClose={this.handleLogsModalsAfterCloseExecuted} />

        <CalcRecordWebservicesLogsModal
          visible={this.state.webserviceLogsModalVisible}
          record={calcRecord}
          onCancelClick={this.handleLogsModalsCancelClick}
          onAfterClose={this.handleLogsModalsAfterCloseExecuted} />

        <CalcRecordStackTracesLogsModal
          visible={this.state.stackTraceLogsModalVisible}
          record={calcRecord}
          onCancelClick={this.handleLogsModalsCancelClick}
          onAfterClose={this.handleLogsModalsAfterCloseExecuted} />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  calcRecordsCurrentPage: selectCalcRecordsCurrentPage(state),
  calcRecord: selectCalcRecordDetail(state),
  personsEnumerations: selectPersonsEnumerations(state),
  initialUrlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterCalcRecords: filterCalcRecordsActions.request,
    getCalcRecordRequestResponse: getCalcRecordRequestResponseActions.request,
    getCalcRecordWebservicesLogs: getCalcRecordWebservicesLogsActions.request,
    getCalcRecordStackTracesLogs: getCalcRecordStackTracesLogsActions.request,
    getCalcRecordAndRedirectToCalculator: getCalcRecordAndRedirectToCalculatorActions.request,
    deleteStateCalcRecordsPage: deleteStateCalcRecordsPageAction,
    deleteStateCalcRecordDetail: deleteStateCalcRecordDetailAction,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(CalcRecordsContainer);
