import React from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { VehicleBrandWithModels } from "../../types";
import { RootState } from "../../../../common/types";
import { selectVehicleBrandsEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props extends SelectProps<string> {
}

interface StateProps {
  vehicleBrandsEnumerations: VehicleBrandWithModels[];
}

type ComponentProps = Props & StateProps;

class VehicleBrandEnumerationsSelect_Deprecated extends React.Component<ComponentProps> {

  render(): React.ReactNode {
    const { vehicleBrandsEnumerations, ...selectProps } = this.props;

    return (
      <Select
        {...selectStandardProps} {...selectProps}
        options={vehicleBrandsEnumerations.map(brand => ({ value: brand.id, label: brand.name }))} />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  vehicleBrandsEnumerations: selectVehicleBrandsEnumerations(state)
});

export default connect<StateProps, {}, Props, ComponentProps, RootState>(mapStateToProps, null, null, { forwardRef: true })(VehicleBrandEnumerationsSelect_Deprecated);
