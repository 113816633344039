import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Pathname } from "history";
import { ActionProps, RootState } from "../../../../common/types";
import { CalcSettings } from "../types";
import { CalcType } from "../../enums";
import {
  createCalcSettingsActions,
  deleteCalcSettingsActions,
  deleteCalcSettingsAttachmentConfActions,
  deleteStateCalcSettingsItemsAction,
  getCalcSettingsActions,
  selectCalcSettingsItems,
  updateCalcSettingsActions,
  uploadCalcSettingsAttachmentConfsActions
} from "../ducks";
import { selectRouterLocationPathname } from "../../../ducks";

import CalcSettingsForm from "../components/CalcSettingsForm";

interface StateProps {
  currentCalcSettings: CalcSettings[];
  locationPathname: Pathname;
}

interface ActionsMap {
  createCalcSettings: typeof createCalcSettingsActions.request;
  getCalcSettings: typeof getCalcSettingsActions.request;
  updateCalcSettings: typeof updateCalcSettingsActions.request;
  deleteCalcSettings: typeof deleteCalcSettingsActions.request;
  uploadAttachmentConf: typeof uploadCalcSettingsAttachmentConfsActions.request;
  deleteAttachmentConf: typeof deleteCalcSettingsAttachmentConfActions.request;
  deleteStateCalcSettingsItems: typeof deleteStateCalcSettingsItemsAction;
}

const CalcSettingsContainer = (props: StateProps & ActionProps<ActionsMap>) => {

  const parseCalcType = (): CalcType => {
    const locationPathnameParts = props.locationPathname.split("/");
    return CalcType[locationPathnameParts[locationPathnameParts.length - 1].toUpperCase()];
  };

  useEffect(() => {
    props.actions.getCalcSettings({ calcType: parseCalcType() });
    return () => props.actions.deleteStateCalcSettingsItems();
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  return <CalcSettingsForm calcType={parseCalcType()}
                           calcSettings={props.currentCalcSettings}
                           onCreateCalcSettings={props.actions.createCalcSettings}
                           onUpdateCalcSettings={props.actions.updateCalcSettings}
                           onDeleteCalcSettings={props.actions.deleteCalcSettings}
                           onUploadAttachmentConf={props.actions.uploadAttachmentConf}
                           onDeleteAttachmentConf={props.actions.deleteAttachmentConf} />
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentCalcSettings: selectCalcSettingsItems(state),
  locationPathname: selectRouterLocationPathname(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    createCalcSettings: createCalcSettingsActions.request,
    getCalcSettings: getCalcSettingsActions.request,
    updateCalcSettings: updateCalcSettingsActions.request,
    deleteCalcSettings: deleteCalcSettingsActions.request,
    uploadAttachmentConf: uploadCalcSettingsAttachmentConfsActions.request,
    deleteAttachmentConf: deleteCalcSettingsAttachmentConfActions.request,
    deleteStateCalcSettingsItems: deleteStateCalcSettingsItemsAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(CalcSettingsContainer);
