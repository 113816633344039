export enum InstitutionType {
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  BANK = 'BANK'
}

export enum InstitutionEnum {
  AGRA = 'AGRA',
  ALLIANZ = 'ALLIANZ',
  AXA_ASSISTANCE = 'AXA_ASSISTANCE',
  AXA_NON_LIFE = 'AXA_NON_LIFE',
  AXA_LIFE = 'AXA_LIFE',
  COFACE = 'COFACE',
  COLONNADE = 'COLONNADE',
  CSOB = 'CSOB',
  ECP = 'ECP',
  EULER = 'EULER',
  GENERALI = 'GENERALI',
  GENERTEL = 'GENERTEL',
  GROUPAMA = 'GROUPAMA',
  HDI = 'HDI',
  KOMUNALNA = 'KOMUNALNA',
  KOOPERATIVA = 'KOOPERATIVA',
  MET_LIFE = 'MET_LIFE',
  MET_LIFE_INSURANCE = 'MET_LIFE_INSURANCE',
  MSIG = 'MSIG',
  UNION = 'UNION',
  UNIQA = 'UNIQA',
  WUSTENROT = 'WUSTENROT',
  CSOB_BANK = 'CSOB_BANK',
  OTP = 'OTP',
  SLSP = 'SLSP',
  UNI_CREDIT = 'UNI_CREDIT',
  VUB = 'VUB'
}
