import React, { useEffect } from "react";
import { Col, Form, Input, Modal, Row } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { ImportedCommission, PostponeImportedCommission } from "../../types";
import { UUID } from "../../../../../common/types";
import { postponeCommissionActions } from "../../ducks";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../common/constants";
import { requests } from "../../api";
import t from "../../../../../app/i18n";

import HiddenInput from "../../../../../common/components/form/components/HiddenInput";

export interface Props {
  commission: ImportedCommission;
  batchId: UUID;
  visible: boolean;
  onPostpone: typeof postponeCommissionActions.request;
  onFormCancel(): void;
}

const ImportedCommissionPostponeForm = ({ commission, batchId, visible, onPostpone, onFormCancel }: Props) => {

  const [form] = Form.useForm<PostponeImportedCommission>();
  useFormErrorHandler(form, "commissions.batch.attrs.commission", requests.POSTPONE_COMMISSION);

  const colSpan = 24;

  useEffect(() => {
    if ( visible && commission ) {
      form.setFieldsValue({ optimisticLockVersion: commission.optimisticLockVersion });
    }
  }, [visible, commission, form]);

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
  };

  const inProgress = useRequestFinishedCallback(requests.POSTPONE_COMMISSION, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: PostponeImportedCommission | Store) => {
        onPostpone({ id1: batchId, id2: commission.id, object: values as PostponeImportedCommission });
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={800}
      visible={visible}
      title={t("commissions.batch.titles.postponeCommission")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="importedCommissionPostponeForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="manualPostponementReason"
              label={t("commissions.batch.attrs.commission.manualPostponementReason")}
              rules={[validations.notBlank, validations.size(1, 255)]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
};

export default ImportedCommissionPostponeForm;
