import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { EntityObjectRequest, PageRequest, PageResult, TwoLevelEntityIdRequest } from "../../../common/types";
import { JobRecord, JobSettings, ScheduleJobRequest, UpdateJobSettings } from "./types";

const GET_JOBS = ApiRequestAdapter.create("/jobs", "GET");
const UPDATE_JOB = ApiRequestAdapter.create("/jobs/{0}", "PUT");
const SCHEDULE_JOB = ApiRequestAdapter.create("/jobs/{0}/records", "POST");
const FILTER_JOB_RECORDS = ApiRequestAdapter.create("/jobs/{0}/records", "GET");
const CANCEL_JOB = ApiRequestAdapter.create("/jobs/{0}/records/{1}/cancel", "PUT");
const ABORT_JOB = ApiRequestAdapter.create("/jobs/{0}/records/{1}/abort", "PUT");

export const requests = {
  GET_JOBS,
  UPDATE_JOB,
  SCHEDULE_JOB,
  FILTER_JOB_RECORDS,
  CANCEL_JOB,
  ABORT_JOB
};

export default {
  getJobs: (): AxiosPromise<JobSettings[]> => {
    return apiService.get<JobSettings[]>(GET_JOBS.url);
  },
  updateJob: (request: EntityObjectRequest<UpdateJobSettings>): AxiosPromise<JobSettings> => {
    return apiService.put<JobSettings>(UPDATE_JOB.getParametrizedUrl(request.id), request.object);
  },
  scheduleJob: (request: EntityObjectRequest<ScheduleJobRequest>): AxiosPromise => {
    return apiService.post(SCHEDULE_JOB.getParametrizedUrl(request.id), null, { params: request.object });
  },
  filterJobRecords: (request: EntityObjectRequest<PageRequest>): AxiosPromise<PageResult<JobRecord>> => {
    return apiService.get<PageResult<JobRecord>>(FILTER_JOB_RECORDS.getParametrizedUrl(request.id), { params: request.object });
  },
  cancelJob: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.put(CANCEL_JOB.getParametrizedUrl(request.id1, request.id2));
  },
  abortJob: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.put(ABORT_JOB.getParametrizedUrl(request.id1, request.id2));
  }
};