import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import {
  CreateUpdateLifeInsuranceTariff,
  LifeInsuranceTariff,
  LifeInsuranceTariffFilterPageRequest,
  LifeInsuranceTariffFilterPageResult
} from "./types";
import { EntityIdRequest, EntityObjectRequest } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";

const FILTER_LIFE_INSURANCE_TARIFFS = ApiRequestAdapter.create("/life-insurance-tariffs", "GET");
const CREATE_LIFE_INSURANCE_TARIFF = ApiRequestAdapter.create("/life-insurance-tariffs", "POST");
const UPDATE_LIFE_INSURANCE_TARIFF = ApiRequestAdapter.create("/life-insurance-tariffs/{0}", "PUT");
const DELETE_LIFE_INSURANCE_TARIFF = ApiRequestAdapter.create("/life-insurance-tariffs/{0}", "DELETE");


export const requests = {
  FILTER_LIFE_INSURANCE_TARIFFS,
  CREATE_LIFE_INSURANCE_TARIFF,
  UPDATE_LIFE_INSURANCE_TARIFF,
  DELETE_LIFE_INSURANCE_TARIFF,
};

export default {
  filterLifeInsuranceTariffs: (request: LifeInsuranceTariffFilterPageRequest): AxiosPromise<LifeInsuranceTariffFilterPageResult> => {
    return apiService.get<LifeInsuranceTariffFilterPageResult>(FILTER_LIFE_INSURANCE_TARIFFS.url, {
      params: request,
      paramsSerializer: serializeParams
    });
  },
  createLifeInsuranceTariff: (request: CreateUpdateLifeInsuranceTariff): AxiosPromise<LifeInsuranceTariff> => {
    return apiService.post<LifeInsuranceTariff>(CREATE_LIFE_INSURANCE_TARIFF.url, request);
  },
  updateLifeInsuranceTariff: (request: EntityObjectRequest<CreateUpdateLifeInsuranceTariff>): AxiosPromise<LifeInsuranceTariff> => {
    return apiService.put<LifeInsuranceTariff>(UPDATE_LIFE_INSURANCE_TARIFF.getParametrizedUrl(request.id), request.object);
  },
  deleteLifeInsuranceTariff: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_LIFE_INSURANCE_TARIFF.getParametrizedUrl(request.id));
  }
}
