import React from "react";
import { Col, Form, Input, Row } from "antd";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import { ibanNormalizeFunction } from "../../../../../common/utils/formUtils";
import {
  commercialRegisterDistrictOffices,
  commercialRegisterSections,
  rowGutter
} from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";

const LegalPersonDataFormPart = () => {
  const colSpan = 6;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="companyName"
            label={t("person.attrs.companyNamePO")}
            rules={[validations.notBlank, validations.size(1, 255)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="companyRegistrationNumber"
            label={t("person.attrs.companyRegistrationNumber")}
            rules={[validations.notBlank, validations.crn]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="commercialRegisterDistrictOffice"
            label={<LabelWithPopover label={t("person.attrs.commercialRegisterDistrictOffice")}
                                     popoverContent={t("helpers.allowedValues", { values: commercialRegisterDistrictOffices.join(", ") })} />}
            rules={[validations.size(1, 64), validations.commercialRegisterDistrictOffice]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="taxId"
            label={t("person.attrs.taxId")}
            rules={[validations.length(10), validations.numeric]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="commercialRegisterSection"
            label={<LabelWithPopover label={t("person.attrs.commercialRegisterSection")}
                                     popoverContent={t("helpers.allowedValues", { values: commercialRegisterSections.join(", ") })} />}
            rules={[validations.size(1, 64), validations.commercialRegisterSection]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="vatId"
            label={t("person.attrs.vatId")}
            rules={[validations.length(12), validations.pattern(regexPatterns.vatIdRegex)]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="commercialRegisterInsertionNumber"
            label={t("person.attrs.commercialRegisterInsertionNumber")}
            rules={[validations.size(1, 64)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="bankAccountNumber"
            label={t("person.attrs.bankAccountNumber")}
            rules={[validations.size(1, 34), validations.iban]}
            normalize={ibanNormalizeFunction}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="webPage"
            label={t("person.attrs.webPage")}
            rules={[validations.size(1, 2048), validations.pattern(regexPatterns.webPageRegex)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item
            name="note"
            label={t("person.attrs.note")}
            rules={[validations.size(1, 8192)]}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default LegalPersonDataFormPart;
