import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export interface Props {
  label: string;
  tooltip: string;
}

const LabelWithTooltip = ({ label, tooltip }: Props) => (
  <span>
    {label}&nbsp;
    <Tooltip title={tooltip}>
      <QuestionCircleOutlined className="cursor-help" />
    </Tooltip>
  </span>
);

export default LabelWithTooltip;
