import React, { useEffect } from "react";
import { Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { CreateUpdateLifeInsuranceTariff, LifeInsuranceTariff } from "../../types";
import { UUID } from "../../../../../common/types";
import { ProductType } from "../../../product/enums";
import { createLifeInsuranceTariffActions, updateLifeInsuranceTariffActions } from "../../ducks";
import { validations } from "../../../../../common/utils/validationUtils";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ProductsEnumFormItemSelect from "../../../../enumerations/components/form/ProductsEnumFormItemSelect";

interface Props {
  defaultProductId: UUID;
  isVisible: boolean;
  tariff?: LifeInsuranceTariff;
  onCreate: typeof createLifeInsuranceTariffActions.request;
  onUpdate: typeof updateLifeInsuranceTariffActions.request;
  onCancelModal(): void;
}

const LifeInsuranceTariffForm = ({ defaultProductId, isVisible, tariff, onCreate, onUpdate, onCancelModal }: Props) => {

  const [form] = Form.useForm<CreateUpdateLifeInsuranceTariff>();

  useFormErrorHandler(form, "admin.lifeInsuranceTariff.attrs", requests.CREATE_LIFE_INSURANCE_TARIFF);
  useFormErrorHandler(form, "admin.lifeInsuranceTariff.attrs", requests.UPDATE_LIFE_INSURANCE_TARIFF);

  useEffect(() => {
    if ( isVisible ) {
      form.setFieldsValue({
        productId: tariff ? tariff.product.id : defaultProductId,
        ...tariff,
      })
    }
  }, [isVisible, defaultProductId, tariff, form]);

  const handleFormCancel = (): void => {
    onCancelModal();
    form.resetFields();
  }

  const createInProgress = useRequestFinishedCallback(requests.CREATE_LIFE_INSURANCE_TARIFF, handleFormCancel);
  const updateInProgress = useRequestFinishedCallback(requests.UPDATE_LIFE_INSURANCE_TARIFF, handleFormCancel);

  const handleFormFinish = (): void => {
    form.validateFields()
      .then(values => {
        if ( tariff ) {
          onUpdate({ id: tariff.id, object: values as CreateUpdateLifeInsuranceTariff });
        }
        else {
          onCreate({ ...values as CreateUpdateLifeInsuranceTariff, deactivated: false })
        }
      })
      .catch(resolveFormValidationError);
  }

  return (
    <Modal
      width={600}
      visible={isVisible}
      title={t(tariff ? "admin.lifeInsuranceTariff.titles.updateTariff" : "admin.lifeInsuranceTariff.titles.createTariff")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      confirmLoading={createInProgress || updateInProgress}
      forceRender
      onCancel={handleFormCancel}
      onOk={handleFormFinish}
    >

      <Form form={form} layout="vertical" name="lifeInsuranceTariffForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col flex={1}>
            <ProductsEnumFormItemSelect
              formItemProps={{
                name: "productId",
                label: t("admin.lifeInsuranceTariff.attrs.productId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                filterType: ProductType.INSURANCE_PRODUCT,
                groupByProductGroup: true,
              }}
            />
          </Col>

          <Col flex={1}>
            {tariff && (
              <Form.Item
                name="deactivated"
                className="form-item-without-label"
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("admin.lifeInsuranceTariff.attrs.deactivated")}
                    tooltip={t("admin.lifeInsuranceTariff.helpers.deactivatedDesc")}
                  />
                </Checkbox>
              </Form.Item>

            )}
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={6}>
            <Form.Item
              name="code"
              label={t("admin.lifeInsuranceTariff.attrs.code")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col flex={1}>
            <Form.Item
              name="name"
              label={t("admin.lifeInsuranceTariff.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 1024)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
}

export default LifeInsuranceTariffForm;
