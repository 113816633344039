import React, { useState } from "react";
import { Button, Col, PageHeader, Popconfirm, Row, Space, Steps, Tabs } from "antd";
import { DeleteOutlined, DoubleLeftOutlined, DoubleRightOutlined, EditOutlined } from "@ant-design/icons";
import { CommissionsBatch } from "../../../types";
import { CommissionsBatchStep, CommissionsBatchStepDirection } from "../../../enums";
import {
  changeCommissionsBatchStepActions,
  createCommissionsUnitActions,
  deleteCommissionsBatchActions,
  deleteCommissionsBatchAttachmentActions,
  deleteCommissionsUnitActions,
  replaceCommissionsBatchAttachmentActions,
  updateCommissionsBatchActions,
  updateCommissionsUnitActions,
  uploadCommissionsBatchAttachmentActions
} from "../../../ducks";
import { formatLocaleCurrencyWithNullAsZero } from "../../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import CommissionsBatchImportStep from "./steps/CommissionsBatchImportStep";
import CommissionsBatchCalculationStep from "./steps/CommissionsBatchCalculationStep";
import CommissionsBatchReviewStep from "./steps/CommissionsBatchReviewStep";
import CommissionsBatchForm from "../../forms/CommissionsBatchForm";
import CommissionBatchStepTag from "../../CommissionBatchStepTag";
import ItemCreatedUpdatedInfoView from "../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  batch: CommissionsBatch;
  onBatchUpdate: typeof updateCommissionsBatchActions.request;
  onBatchDelete: typeof deleteCommissionsBatchActions.request;
  onBatchStepChange: typeof changeCommissionsBatchStepActions.request;
  onUnitCreate: typeof createCommissionsUnitActions.request;
  onUnitUpdate: typeof updateCommissionsUnitActions.request;
  onUnitDelete: typeof deleteCommissionsUnitActions.request;
  onAttachmentUpload: typeof uploadCommissionsBatchAttachmentActions.request;
  onAttachmentReplace: typeof replaceCommissionsBatchAttachmentActions.request;
  onAttachmentDelete: typeof deleteCommissionsBatchAttachmentActions.request;
}

const CommissionsBatchDetailView = ({ batch, ...props }: Props) => {

  const [batchFormVisible, setBatchFormVisible] = useState<boolean>(false);

  return (
    <>
      <PageHeader
        className="page-header-box"
        title={t("commissions.batch.titles.batchDetail", { name: batch.name })}
        tags={<CommissionBatchStepTag step={batch.step} />}
        extra={
          <Space>
            <Button type="primary" icon={<DoubleLeftOutlined />}
                    disabled={batch.step === CommissionsBatchStep.IMPORT || batch.step === CommissionsBatchStep.FINISH}
                    onClick={() => props.onBatchStepChange({
                      id: batch.id,
                      object: { direction: CommissionsBatchStepDirection.PREVIOUS }
                    })}>
              {t("commissions.batch.actions.batchPreviousStep")}
            </Button>

            <Button type="primary" icon={<DoubleRightOutlined />}
                    disabled={batch.step === CommissionsBatchStep.FINISH}
                    onClick={() => props.onBatchStepChange({
                      id: batch.id,
                      object: { direction: CommissionsBatchStepDirection.NEXT }
                    })}>
              {t("commissions.batch.actions.batchNextStep")}
            </Button>

            <Button className="blue-button" icon={<EditOutlined />}
                    disabled={batch.step === CommissionsBatchStep.FINISH}
                    onClick={() => setBatchFormVisible(true)}>
              {t("common.edit")}
            </Button>

            <Popconfirm
              title={t("commissions.batch.helpers.deleteBatchConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="primary"
              onConfirm={() => props.onBatchDelete({ id: batch.id })}>
              <Button danger icon={<DeleteOutlined />} disabled={batch.step === CommissionsBatchStep.FINISH}>
                {t("common.delete")}
              </Button>
            </Popconfirm>
          </Space>
        }>

        <ItemCreatedUpdatedInfoView item={batch} style={{ marginTop: "-15px" }} className="margin-bottom-small" />

        <Row gutter={rowGutter}>
          <Col span={15}>
            <table className="data-table-view">
              <tbody>
              <tr>
                <td>{t("commissions.batch.attrs.importedCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.importedCommissionAmount)}
                </td>
              </tr>
              <tr>
                <td>{t("commissions.batch.attrs.postponedCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.postponedCommissionAmount)}
                </td>
              </tr>
              <tr>
                <td>{t("commissions.batch.attrs.previousBatchesCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.previousBatchesCommissionAmount)}
                </td>
              </tr>
              <tr>
                <td>{t("commissions.batch.attrs.currentBatchCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.currentBatchCommissionAmount)}
                </td>
              </tr>
              <tr>
                <td>{t("commissions.batch.attrs.calculatedCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.calculatedCommissionAmount)}
                </td>
              </tr>
              <tr>
                <td>{t("commissions.batch.attrs.bankAccountCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.bankAccountCommissionAmount)}
                </td>
              </tr>
              <tr>
                <td>{t("commissions.batch.attrs.invoiceCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.invoiceCommissionAmount)}
                </td>
              </tr>
              <tr>
                <td>{t("commissions.batch.attrs.volumeCommissionAmount")}:</td>
                <td className="right-align">
                  {formatLocaleCurrencyWithNullAsZero(batch.volumeCommissionAmount)}
                </td>
              </tr>
              </tbody>
            </table>
          </Col>

          <Col span={9} className="right-align">
            <Steps size="small" progressDot current={Object.keys(CommissionsBatchStep).indexOf(batch.step)}>
              <Steps.Step title={t("commissions.batch.enums.batchStep." + CommissionsBatchStep.IMPORT)} />
              <Steps.Step title={t("commissions.batch.enums.batchStep." + CommissionsBatchStep.CALCULATION)} />
              <Steps.Step title={t("commissions.batch.enums.batchStep." + CommissionsBatchStep.REVIEW)} />
              <Steps.Step title={t("commissions.batch.enums.batchStep." + CommissionsBatchStep.FINISH)} />
            </Steps>
          </Col>
        </Row>
      </PageHeader>

      <Tabs activeKey={batch.step} className="margin-top-medium">

        <Tabs.TabPane key={CommissionsBatchStep.IMPORT}>
          <CommissionsBatchImportStep
            batch={batch}
            onUnitCreate={props.onUnitCreate}
            onUnitUpdate={props.onUnitUpdate}
            onUnitDelete={props.onUnitDelete}
            onAttachmentUpload={props.onAttachmentUpload}
            onAttachmentReplace={props.onAttachmentReplace}
            onAttachmentDelete={props.onAttachmentDelete} />
        </Tabs.TabPane>

        <Tabs.TabPane key={CommissionsBatchStep.CALCULATION}>
          <CommissionsBatchCalculationStep batch={batch} />
        </Tabs.TabPane>

        <Tabs.TabPane key={CommissionsBatchStep.REVIEW}>
          <CommissionsBatchReviewStep batch={batch} />
        </Tabs.TabPane>

      </Tabs>

      <CommissionsBatchForm
        batch={batch}
        visible={batchFormVisible}
        onUpdate={props.onBatchUpdate}
        onFormCancel={() => setBatchFormVisible(false)} />

    </>
  )
};

export default CommissionsBatchDetailView;
