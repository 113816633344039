import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { ActionProps, RootState } from "../../../../common/types";
import { ProductGroup } from "../types";
import {
  createProductActions,
  createProductGroupActions,
  deleteProductActions,
  deleteProductGroupActions,
  deleteStateProductGroupsListAction,
  getProductGroupsActions,
  selectProductGroups,
  updateProductGroupAndProductsActions
} from "../ducks";
import { selectRouterLocationKey } from "../../../ducks";

import ProductFormView from "../components/ProductFormView";

interface StateProps {
  productGroups: ProductGroup[];
  locationKey: string;
}

interface ActionsMap {
  getProductGroups: typeof getProductGroupsActions.request;
  createProductGroup: typeof createProductGroupActions.request;
  createProduct: typeof createProductActions.request;
  updateProductGroupAndProducts: typeof updateProductGroupAndProductsActions.request;
  deleteProductGroup: typeof deleteProductGroupActions.request;
  deleteProduct: typeof deleteProductActions.request;
  deleteStateProductGroupsList: typeof deleteStateProductGroupsListAction;
}

const ProductContainer = (props: StateProps & ActionProps<ActionsMap>) => {

  useEffect(() => {
    props.actions.getProductGroups();
    return () => {
      props.actions.deleteStateProductGroupsList();
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return <ProductFormView productGroups={props.productGroups}
                          locationKey={props.locationKey}
                          onCreateProductGroupFormSubmit={props.actions.createProductGroup}
                          onCreateProductFormSubmit={props.actions.createProduct}
                          onUpdateProductGroupAndProductsFormSubmit={props.actions.updateProductGroupAndProducts}
                          onDeleteProductGroupClick={props.actions.deleteProductGroup}
                          onDeleteProductClick={props.actions.deleteProduct} />
}

const mapStateToProps = (state: RootState): StateProps => ({
  productGroups: selectProductGroups(state),
  locationKey: selectRouterLocationKey(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getProductGroups: getProductGroupsActions.request,
    createProductGroup: createProductGroupActions.request,
    createProduct: createProductActions.request,
    updateProductGroupAndProducts: updateProductGroupAndProductsActions.request,
    deleteProductGroup: deleteProductGroupActions.request,
    deleteProduct: deleteProductActions.request,
    deleteStateProductGroupsList: deleteStateProductGroupsListAction,
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(ProductContainer);
