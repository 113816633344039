import React, { ReactText, useEffect, useState } from "react";
import { Alert, Checkbox, Col, Form, Input, Modal, Row, Tree } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { AdminPersonAccount, AdminUpdatePersonAccount } from "../../types";
import { Person } from "../../../person/types";
import { AntTreeStrictCheckedProps } from "../../../../common/types";
import { Permission } from "../../../../common/security/authorization/enums";
import { PersonType } from "../../../person/enums";
import { updatePersonAccountActions } from "../../ducks";
import { buildPermissionTreeNodes } from "../../utils";
import { containsAll, getAllPermissionPrerequisites } from "../../../../common/utils/utils";
import messageUtils from "../../../../common/utils/messageUtils";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { OrganizationPathDelimiter, rowGutter } from "../../../../common/constants";
import { requests } from "../../api";
import t from "../../../../app/i18n";

import PersonsEnumFormItemSelect from "../../../enumerations/components/form/PersonsEnumFormItemSelect";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  visible: boolean;
  account: AdminPersonAccount;
  person: Person;
  onFormSubmit: typeof updatePersonAccountActions.request;
  onFormCancel(): void;
}

const PersonAccountUpdateForm = ({ visible, account, person, onFormSubmit, onFormCancel }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "account.attrs", requests.UPDATE_PERSON_ACCOUNT);

  const [formCheckedPermissions, setFormCheckedPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    if ( visible && account ) {
      form.setFieldsValue({
        optimisticLockVersion: account.optimisticLockVersion,
        name: account.name,
        locked: account.locked,
        disabled: account.disabled,
        backOfficeTargetId: account.backOfficeTarget?.id,
        representativeTargetId: account.representativeTarget?.id
      });
      setFormCheckedPermissions(account.permissions);
    }
  }, [visible, account, form]);

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
    setFormCheckedPermissions([]);
  };

  const inProgress = useRequestFinishedCallback(requests.UPDATE_PERSON_ACCOUNT, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: AdminUpdatePersonAccount | Store) => {
        if ( formCheckedPermissions.length === 0 ) {
          messageUtils.errorMessage(t("account.helpers.noPermissions"));
        }
        else {
          onFormSubmit({
            id1: person.id,
            id2: account.id,
            object: { ...values, permissions: formCheckedPermissions } as AdminUpdatePersonAccount
          });
        }
      })
      .catch(resolveFormValidationError);
  };

  const handlePermissionTreeCheck = (checked: ReactText[] | AntTreeStrictCheckedProps): void => {
    const checkedPermissions = (checked as AntTreeStrictCheckedProps).checked.map(checked => Permission[checked]);
    setFormCheckedPermissions(checkedPermissions.filter(permission => containsAll(checkedPermissions, ...getAllPermissionPrerequisites(permission))));
  };

  const colSpanBig = 12;
  const colSpanSmall = 6;

  return (
    <Modal
      width={700}
      visible={visible}
      title={account
        ? `${t("account.titles.update")}: ${account.confirmed ? account.name ? `${account.name} | ${account.username}` : account.username : account.email}`
        : t("account.titles.update")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="personAccountUpdateForm">

        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={colSpanBig}>
            <Form.Item
              name="name"
              label={<LabelWithTooltip label={t("account.attrs.name")}
                                       tooltip={t("account.helpers.accountNameInfo")} />}
              rules={[validations.size(1, 255), validations.pattern(regexPatterns.nameRegex)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpanSmall}>
            <Form.Item
              name="disabled"
              className="form-item-without-label"
              valuePropName="checked"
              initialValue={false}
              rules={[validations.none]}>
              <Checkbox>{t("account.attrs.disabled")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpanSmall}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.locked !== next.locked}>
              {({ getFieldValue }) => (
                <Form.Item
                  name="locked"
                  className="form-item-without-label"
                  valuePropName="checked"
                  initialValue={false}
                  rules={[validations.none]}>
                  <Checkbox disabled={!getFieldValue("locked")}>{t("account.attrs.locked")}</Checkbox>
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpanBig}>
            <PersonsEnumFormItemSelect
              formItemProps={{
                name: "backOfficeTargetId",
                label: <LabelWithTooltip label={t("account.attrs.backOfficeTargetId")}
                                         tooltip={t("account.helpers.backOfficeTargetInfo")} />,
                rules: [validations.none]
              }}
              selectProps={{ allowClear: true }}
              optionsProps={{ selected: account?.backOfficeTarget, filterOrganizationPath: person.organizationPath }} />
          </Col>

          <Col span={colSpanBig}>
            <PersonsEnumFormItemSelect
              formItemProps={{
                name: "representativeTargetId",
                label: <LabelWithTooltip label={t("account.attrs.representativeTargetId")}
                                         tooltip={t("account.helpers.representativeTargetInfo")} />,
                rules: [validations.none]
              }}
              selectProps={{ allowClear: true }}
              optionsProps={{
                selected: account?.representativeTarget,
                filter: personOption => personOption.organizationPath.startsWith(person.organizationPath)
                  && personOption.id !== person.id && personOption.type === PersonType.NATURAL
                  && !personOption.organizationPath.replace(person.organizationPath + OrganizationPathDelimiter, "").includes(OrganizationPathDelimiter)
              }} />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpanBig * 2}>
            <Form.Item label={t("account.attrs.permissionsLabel")} required>
              <Tree
                checkStrictly checkable
                checkedKeys={{ checked: formCheckedPermissions, halfChecked: [] }}
                treeData={buildPermissionTreeNodes(formCheckedPermissions)}
                onCheck={handlePermissionTreeCheck} />
            </Form.Item>
          </Col>
        </Row>

      </Form>

      {account && account.confirmed && <Alert message={t("account.helpers.updateInfo")} type="info" showIcon />}

    </Modal>
  );
}

export default PersonAccountUpdateForm;
