import React from "react";
import { Tag } from "antd";
import { InstitutionType } from "../enums";
import t from "../../../../app/i18n";

export interface Props {
  type: InstitutionType;
}

const InstitutionTypeTag = ({ type }: Props) => {
  let color: string;

  switch ( type ) {
    case InstitutionType.INSURANCE_COMPANY:
      color = "blue";
      break;
    case InstitutionType.BANK:
      color = "green";
      break;
    default:
      return null;
  }

  return <Tag color={color}>{t("admin.institution.enums.institutionType." + type)}</Tag>;
};

export default InstitutionTypeTag;
