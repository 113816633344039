import { combineReducers } from "redux";
import { TravelCalc, TravelCalcReducerState, TravelCalcResultData, TravelGen, TravelOffer } from "./types";
import { CalcResult, GenResponse } from "../types";
import { RootState } from "../../../../common/types";
import {
  apiOperation,
  createActionCreator,
  createApiActionCreators,
  createReducer
} from "../../../../common/utils/reduxUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  CALCULATE = 'calc-travel/CALCULATE',
  GENERATE = 'calc-travel/GENERATE',
  GENERATE_OFFER = 'calc-travel/GENERATE_OFFER',
  SET_CALC_RESULTS = 'calc-travel/SET_CALC_RESULTS',
  SET_INITIAL_CALC_DATA = 'calc-travel/SET_INITIAL_CALC_DATA',
  SET_INITIAL_GEN_DATA = 'calc-travel/SET_INITIAL_GEN_DATA',
  DELETE_STATE_CALC_RESULTS = 'calc-travel/DELETE_STATE_CALC_RESULTS',
  DELETE_STATE_GEN_RESULT = 'calc-travel/DELETE_STATE_GEN_RESULT',
  DELETE_STATE_INITIAL_CALC_GEN_DATA = 'calc-travel/DELETE_STATE_INITIAL_CALC_GEN_DATA'
}

/**
 * ACTIONS
 */
export const calculateTravelActions = createApiActionCreators<TravelCalc, CalcResult<TravelCalcResultData>[][]>(actionType.CALCULATE);
export const generateTravelActions = createApiActionCreators<TravelGen, GenResponse>(actionType.GENERATE);
export const generateTravelOfferActions = createApiActionCreators<TravelOffer, void>(actionType.GENERATE_OFFER);

export const setTravelCalcResultsAction = createActionCreator<CalcResult<TravelCalcResultData>[][]>(actionType.SET_CALC_RESULTS);
export const setTravelInitialCalcDataAction = createActionCreator<TravelCalc>(actionType.SET_INITIAL_CALC_DATA);
export const setTravelInitialGenDataAction = createActionCreator<TravelGen>(actionType.SET_INITIAL_GEN_DATA);

export const deleteStateTravelCalcResultsAction = createActionCreator<void>(actionType.DELETE_STATE_CALC_RESULTS);
export const deleteStateTravelGenResultAction = createActionCreator<void>(actionType.DELETE_STATE_GEN_RESULT);
export const deleteStateTravelInitialCalcGenDataAction = createActionCreator<void>(actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA);

/**
 * REDUCERS
 */
const initialState: TravelCalcReducerState = {
  calcResults: [],
  genResult: null,
  initialCalcData: null,
  initialGenData: null
};

const calcResultsReducer = createReducer<CalcResult<TravelCalcResultData>[][]>(initialState.calcResults, {
  [actionType.CALCULATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.calcResults
  },
  [actionType.SET_CALC_RESULTS]: (state, payload) => payload,
  [actionType.DELETE_STATE_CALC_RESULTS]: () => initialState.calcResults
});

const genResultReducer = createReducer<GenResponse>(initialState.genResult, {
  [actionType.GENERATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.genResult
  },
  [actionType.DELETE_STATE_GEN_RESULT]: () => initialState.genResult
});

const initialCalcDataReducer = createReducer<TravelCalc>(initialState.initialCalcData, {
  [actionType.SET_INITIAL_CALC_DATA]: (state, payload) => payload,
  [actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA]: () => initialState.initialCalcData
});

const initialGenDataReducer = createReducer<TravelGen>(initialState.initialGenData, {
  [actionType.SET_INITIAL_GEN_DATA]: (state, payload) => payload,
  [actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA]: () => initialState.initialGenData
});

export default combineReducers({
  calcResults: calcResultsReducer,
  genResult: genResultReducer,
  initialCalcData: initialCalcDataReducer,
  initialGenData: initialGenDataReducer
});

/**
 * SELECTORS
 */
const selectTravelCalc = (state: RootState): TravelCalcReducerState => state.calculator.calcs.travel;

export const selectTravelCalcResults = (state: RootState): CalcResult<TravelCalcResultData>[][] => selectTravelCalc(state).calcResults;
export const selectTravelGenResult = (state: RootState): GenResponse => selectTravelCalc(state).genResult;
export const selectTravelInitialCalcData = (state: RootState): TravelCalc => selectTravelCalc(state).initialCalcData;
export const selectTravelInitialGenData = (state: RootState): TravelGen => selectTravelCalc(state).initialGenData;
