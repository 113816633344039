import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ActionProps, RootState } from "../../../common/types";
import { requestPasswordResetActions } from "../ducks";

import RequestPasswordResetForm from "../components/forms/RequestPasswordResetForm";

interface ActionsMap {
  requestPasswordReset: typeof requestPasswordResetActions.request;
}

const RequestPasswordResetContainer = (props: ActionProps<ActionsMap>) => {
  return <RequestPasswordResetForm onFormSubmit={props.actions.requestPasswordReset} />
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    requestPasswordReset: requestPasswordResetActions.request
  }, dispatch)
});

export default connect<{}, ActionProps<ActionsMap>, {}, RootState>(null, mapDispatchToProps)(RequestPasswordResetContainer);
