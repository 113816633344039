import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import cloneDeep from "lodash/cloneDeep";
import api from "./api";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  TwoLevelEntityIdRequest
} from "../../../common/types";
import {
  CreateProduct,
  CreateProductGroup,
  Product,
  ProductGroup,
  ProductReducerState,
  UpdateProductGroupWithProducts
} from "./types";
import { changeLocationKeyAction } from "../../ducks";
import { getEnumerationsActions } from "../../enumerations/ducks";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import { removeFromArray, replaceInArray } from "../../../common/utils/utils";
import messageUtils from "../../../common/utils/messageUtils";

/**
 * ACTION TYPES
 */
export enum productGroupActionType {
  GET = 'product-groups/GET',
  CREATE = 'product-groups/CREATE',
  UPDATE = 'product-groups/UPDATE',
  DELETE = 'product-groups/DELETE',
  DELETE_STATE_LIST = 'product-groups/DELETE_STATE_LIST'
}

export enum productActionType {
  CREATE = 'products/CREATE',
  DELETE = 'products/DELETE',
}

/**
 * ACTIONS
 */
export const getProductGroupsActions = createApiActionCreators<void, ProductGroup[]>(productGroupActionType.GET);
export const createProductGroupActions = createApiActionCreators<CreateProductGroup, ProductGroup[]>(productGroupActionType.CREATE);
export const updateProductGroupAndProductsActions = createApiActionCreators<EntityObjectRequest<UpdateProductGroupWithProducts>, ProductGroup[]>(productGroupActionType.UPDATE);
export const deleteProductGroupActions = createApiActionCreators<EntityIdRequest, ProductGroup[]>(productGroupActionType.DELETE);
export const createProductActions = createApiActionCreators<EntityObjectRequest<CreateProduct>, ProductGroup[]>(productActionType.CREATE);
export const deleteProductActions = createApiActionCreators<TwoLevelEntityIdRequest, ProductGroup[]>(productActionType.DELETE);

export const deleteStateProductGroupsListAction = createActionCreator<void>(productGroupActionType.DELETE_STATE_LIST);

/**
 * REDUCERS
 */
const initialState: ProductReducerState = {
  productGroups: []
};

const productGroupsReducer = createReducer<ProductGroup[]>(initialState.productGroups, {
  [productGroupActionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.productGroups
  },
  [productGroupActionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [productGroupActionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [productGroupActionType.DELETE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [productActionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [productActionType.DELETE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [productGroupActionType.DELETE_STATE_LIST]: {
    [apiOperation.SUCCESS]: () => initialState.productGroups
  }
});

export default combineReducers({ productGroups: productGroupsReducer });

/**
 * SELECTORS
 */
const selectProduct = (state: RootState): ProductReducerState => state.admin.product;

export const selectProductGroups = (state: RootState): ProductGroup[] => selectProduct(state).productGroups;

/**
 * SAGAS
 */
function* getProductGroups() {
  try {
    const response: AxiosResponse<ProductGroup[]> = yield call(api.getProductGroups);
    yield put(getProductGroupsActions.success(response.data));
  }
  catch ( error ) {
    yield put(getProductGroupsActions.failure(error));
  }
}

function* createProductGroup({ payload }: ActionCreator<CreateProductGroup>) {
  try {
    const response: AxiosResponse<ProductGroup> = yield call(api.createProductGroup, payload);
    const productGroups: ProductGroup[] = [...yield select(selectProductGroups)];
    productGroups.push(response.data);
    yield put(createProductGroupActions.success(productGroups));
    yield put(getEnumerationsActions.request());
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createProductGroupActions.failure(error));
  }
}

function* updateProductGroupAndProducts({ payload }: ActionCreator<EntityObjectRequest<UpdateProductGroupWithProducts>>) {
  try {
    const response: AxiosResponse<ProductGroup> = yield call(api.updateProductGroupAndProducts, payload);
    let productGroups: ProductGroup[] = [...yield select(selectProductGroups)];
    productGroups = replaceInArray(productGroups, item => item.id === response.data.id, response.data);
    yield put(updateProductGroupAndProductsActions.success(productGroups));
    yield put(getEnumerationsActions.request());
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateProductGroupAndProductsActions.failure(error));
  }
}

function* deleteProductGroup({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteProductGroup, payload);
    let productGroups: ProductGroup[] = [...yield select(selectProductGroups)];
    productGroups = removeFromArray(productGroups, item => item.id === payload.id);
    yield put(deleteProductGroupActions.success(productGroups));
    yield put(getEnumerationsActions.request());
  }
  catch ( error ) {
    yield put(deleteProductGroupActions.failure(error));
  }
}

function* createProduct({ payload }: ActionCreator<EntityObjectRequest<CreateProduct>>) {
  try {
    const response: AxiosResponse<Product> = yield call(api.createProduct, payload);
    const productGroups: ProductGroup[] = cloneDeep(yield select(selectProductGroups));
    productGroups.find(productGroup => productGroup.id === payload.id).products.push(response.data);
    yield put(createProductActions.success(productGroups));
    yield put(getEnumerationsActions.request());
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createProductActions.failure(error));
  }
}

function* deleteProduct({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.deleteProduct, payload);
    const productGroups: ProductGroup[] = cloneDeep(yield select(selectProductGroups));
    const productGroup = productGroups.find(productGroup => productGroup.id === payload.id1);
    productGroup.products = removeFromArray(productGroup.products, item => item.id === payload.id2);
    yield put(deleteProductActions.success(productGroups));
    yield put(getEnumerationsActions.request());
    yield put(changeLocationKeyAction());
  }
  catch ( error ) {
    yield put(deleteProductActions.failure(error));
  }
}

export function* productSaga() {
  yield takeLatest(createActionType(productGroupActionType.GET, apiOperation.REQUEST), getProductGroups);
  yield takeLatest(createActionType(productGroupActionType.CREATE, apiOperation.REQUEST), createProductGroup);
  yield takeLatest(createActionType(productGroupActionType.UPDATE, apiOperation.REQUEST), updateProductGroupAndProducts);
  yield takeLatest(createActionType(productGroupActionType.DELETE, apiOperation.REQUEST), deleteProductGroup);
  yield takeLatest(createActionType(productActionType.CREATE, apiOperation.REQUEST), createProduct);
  yield takeLatest(createActionType(productActionType.DELETE, apiOperation.REQUEST), deleteProduct);
}
