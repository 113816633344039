import React from "react";
import { Divider, Layout } from "antd";
import t from "../i18n";

const Footer = () => (
  <Layout.Footer>
    <b>{t("common.companyName")}</b>
    <br />

    {t("common.copyright", { year: new Date().getFullYear() })}
    <Divider type="vertical" />
    {t("common.appVersion", { version: "1.0.0" })}
  </Layout.Footer>
);

export default Footer;
