import React from "react";
import { Route, Switch } from "react-router-dom";
import { Permission } from "../../common/security/authorization/enums";
import RedirectWithPermission from "../../common/security/authorization/RedirectWithPermission";

import CommissionsBatchRoutes from "./batch/routes";
import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <Route
      exact path="/commissions"
      render={() => <RedirectWithPermission permissionRedirects={[
        { permission: Permission.COMMISSIONS, exact: true, from: "/commissions", to: "/commissions/batches" }
      ]} />} />

    <Route path="/commissions/batches" component={CommissionsBatchRoutes} />

    <Route component={NotFound} />
  </Switch>
);
