import React from "react";
import { Layout, Skeleton, Spin } from "antd";
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ActionProps, RootState } from "../../common/types";
import { AuthAccount } from "../../modules/auth/types";
import { getEnumerationsActions, selectEnumerationsInitialized } from "../../modules/enumerations/ducks";
import { selectHasActiveRequest } from "../../modules/ducks";
import { checkAuthStateAction, logoutAction, selectAccount, selectIsUserAuthenticated } from "../../modules/auth/ducks";
import { getActiveEnvProfile } from "../../common/utils/utils";

import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import Footer from "../components/Footer";

interface StateProps {
  userAuthenticated: boolean;
  account: AuthAccount;
  showSpinner: boolean;
  enumerationsInitialized: boolean;
}

interface ActionsMap {
  getEnumerations: typeof getEnumerationsActions.request;
  checkAuthState: typeof checkAuthStateAction;
  logout: typeof logoutAction;
}

class App extends React.Component<StateProps & ActionProps<ActionsMap> & RouteComponentProps> {

  componentDidMount(): void {
    this.props.actions.checkAuthState();
    if ( this.props.userAuthenticated ) {
      this.props.actions.getEnumerations();
    }
  }

  render(): React.ReactNode {
    return (
      <Spin spinning={this.props.showSpinner} size="large">
        <Layout className={"root-layout " + getActiveEnvProfile()}>

          <Header location={this.props.location}
                  userAuthenticated={this.props.userAuthenticated}
                  account={this.props.account}
                  onLogout={this.props.actions.logout} />

          <Layout>

            <SideMenu location={this.props.location}
                      permissions={this.props.account ? this.props.account.permissions : []} />

            <Layout>
              <Layout.Content>
                {!this.props.userAuthenticated || this.props.enumerationsInitialized ? this.props.children : (
                  <>
                    <Skeleton active paragraph={{ rows: 6 }} />
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </>
                )}
              </Layout.Content>
              <Footer />
            </Layout>

          </Layout>

        </Layout>
      </Spin>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  userAuthenticated: selectIsUserAuthenticated(state),
  account: selectAccount(state),
  showSpinner: selectHasActiveRequest(state),
  enumerationsInitialized: selectEnumerationsInitialized(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getEnumerations: getEnumerationsActions.request,
    checkAuthState: checkAuthStateAction,
    logout: logoutAction
  }, dispatch)
});

export default compose<any, any, any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
