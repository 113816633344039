import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import api from "./api";
import {
  CreateVehicleMapping,
  UpdateVehicleMappingList,
  VehicleAutocompleteRequest,
  VehicleAutocompleteResult,
  VehicleAutocompleteResultDto,
  VehicleBrandMappingFilterPageResult,
  VehicleBrandMappingFilterPageResultDto,
  VehicleMapping,
  VehicleMappingData,
  VehicleMappingFilterPageRequest,
  VehicleMappingTypesRequest,
  VehicleModelMappingFilterPageResult,
  VehiclePriceRequest,
  VehiclesReducerState
} from "./types";
import { ActionCreator, EntityObjectRequest, RootState } from "../../../common/types";
import { Vehicle } from "../../contract/types";
import { changeLocationKeyAction } from "../../ducks";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import { contains, replaceInArray } from "../../../common/utils/utils";
import { initAutocompleteResult, initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  AUTOCOMPLETE = 'vehicles/AUTOCOMPLETE',
  GET_PRICE = 'vehicles/GET_PRICE',
  GET_MAPPINGS = 'vehicles/GET_MAPPINGS',
  REMAP_MAPPINGS = 'vehicles/REMAP_MAPPINGS',
  UPDATE_MAPPINGS = 'vehicles/UPDATE_MAPPINGS',
  FILTER_BRANDS = 'vehicles/FILTER_BRANDS',
  CREATE_BRAND = 'vehicles/CREATE_BRAND',
  UPDATE_BRANDS = 'vehicles/UPDATE_BRANDS',
  FILTER_MODELS = 'vehicles/FILTER_MODELS',
  CREATE_MODEL = 'vehicles/CREATE_MODEL',
  UPDATE_MODELS = 'vehicles/UPDATE_MODELS',
  DELETE_STATE_AUTOCOMPLETE_RESULT = 'vehicles/DELETE_STATE_AUTOCOMPLETE_RESULT',
  DELETE_STATE_PRICE_RESULT = 'vehicles/DELETE_STATE_PRICE_RESULT',
  DELETE_STATE_MAPPINGS_DATA = 'vehicles/DELETE_STATE_MAPPINGS_DATA'
}

/**
 * ACTIONS
 */
export const autocompleteVehiclesActions = createApiActionCreators<VehicleAutocompleteRequest, VehicleAutocompleteResult>(actionType.AUTOCOMPLETE);
export const getVehiclePriceActions = createApiActionCreators<VehiclePriceRequest, number>(actionType.GET_PRICE);

export const getVehicleMappingsActions = createApiActionCreators<VehicleMappingTypesRequest, VehicleMappingData[]>(actionType.GET_MAPPINGS);
export const remapVehicleMappingsActions = createApiActionCreators<VehicleMappingTypesRequest, void>(actionType.REMAP_MAPPINGS);
export const mapVehicleMappingsUpdatesActions = createApiActionCreators<VehicleMappingTypesRequest, void>(actionType.UPDATE_MAPPINGS);

export const filterVehicleBrandMappingsActions = createApiActionCreators<VehicleMappingFilterPageRequest, VehicleBrandMappingFilterPageResult>(actionType.FILTER_BRANDS);
export const createVehicleBrandMappingActions = createApiActionCreators<CreateVehicleMapping, VehicleMapping>(actionType.CREATE_BRAND);
export const updateVehicleBrandMappingsActions = createApiActionCreators<UpdateVehicleMappingList, VehicleBrandMappingFilterPageResult>(actionType.UPDATE_BRANDS);

export const filterVehicleModelMappingsActions = createApiActionCreators<EntityObjectRequest<VehicleMappingFilterPageRequest>, VehicleBrandMappingFilterPageResult>(actionType.FILTER_MODELS);
export const createVehicleModelMappingActions = createApiActionCreators<EntityObjectRequest<CreateVehicleMapping>, VehicleMapping>(actionType.CREATE_MODEL);
export const updateVehicleModelMappingsActions = createApiActionCreators<EntityObjectRequest<UpdateVehicleMappingList>, VehicleBrandMappingFilterPageResult>(actionType.UPDATE_MODELS);

export const deleteStateVehicleAutocompleteResultAction = createActionCreator<void>(actionType.DELETE_STATE_AUTOCOMPLETE_RESULT);
export const deleteStateVehiclePriceResultAction = createActionCreator<void>(actionType.DELETE_STATE_PRICE_RESULT);
export const deleteStateVehicleMappingsDataAction = createActionCreator<void>(actionType.DELETE_STATE_MAPPINGS_DATA);

/**
 * REDUCERS
 */
const initialState: VehiclesReducerState = {
  autocompleteResult: {
    ...initAutocompleteResult<Vehicle>(),
    id: null,
    by: null
  },
  priceResult: null,
  mappings: [],
  currentBrandsPage: {
    ...initSearchPageResult<VehicleMapping>(),
    queriedMappings: [],
    excludeMapped: false,
    id: null
  },
  currentModelsPage: {
    ...initSearchPageResult<VehicleMapping>(),
    queriedMappings: [],
    excludeMapped: false,
    brand: null,
    id: null
  }
};

const autocompleteResultReducer = createReducer<VehicleAutocompleteResult>(initialState.autocompleteResult, {
  [actionType.AUTOCOMPLETE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.autocompleteResult
  },
  [actionType.DELETE_STATE_AUTOCOMPLETE_RESULT]: () => initialState.autocompleteResult
});

const priceResultReducer = createReducer<number>(initialState.priceResult, {
  [actionType.GET_PRICE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.priceResult
  },
  [actionType.DELETE_STATE_PRICE_RESULT]: () => initialState.priceResult
});

const mappingsReducer = createReducer<VehicleMappingData[]>(initialState.mappings, {
  [actionType.GET_MAPPINGS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.mappings
  },
  [actionType.DELETE_STATE_MAPPINGS_DATA]: () => initialState.mappings
});

const currentBrandsPageReducer = createReducer<VehicleBrandMappingFilterPageResult>(initialState.currentBrandsPage, {
  [actionType.FILTER_BRANDS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentBrandsPage
  },
  [actionType.UPDATE_BRANDS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_MAPPINGS_DATA]: () => initialState.currentBrandsPage
});

const currentModelsPageReducer = createReducer<VehicleBrandMappingFilterPageResult>(initialState.currentModelsPage, {
  [actionType.FILTER_MODELS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentModelsPage
  },
  [actionType.UPDATE_MODELS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_MAPPINGS_DATA]: () => initialState.currentModelsPage
});

export default combineReducers({
  autocompleteResult: autocompleteResultReducer,
  priceResult: priceResultReducer,
  mappings: mappingsReducer,
  currentBrandsPage: currentBrandsPageReducer,
  currentModelsPage: currentModelsPageReducer
});

/**
 * SELECTORS
 */
const selectVehicles = (state: RootState): VehiclesReducerState => state.calculator.vehicles;

export const selectCalcVehicleAutocompleteResult = (state: RootState): VehicleAutocompleteResult => selectVehicles(state).autocompleteResult;
export const selectCalcVehiclePriceResult = (state: RootState): number => selectVehicles(state).priceResult;
export const selectCalcVehicleMappings = (state: RootState): VehicleMappingData[] => selectVehicles(state).mappings;
export const selectCalcVehicleBrandsCurrentPage = (state: RootState): VehicleBrandMappingFilterPageResult => selectVehicles(state).currentBrandsPage;
export const selectCalcVehicleModelsCurrentPage = (state: RootState): VehicleModelMappingFilterPageResult => selectVehicles(state).currentModelsPage;

/**
 * SAGAS
 */
function* autocompleteVehicles({ payload }: ActionCreator<VehicleAutocompleteRequest>) {
  try {
    const response: AxiosResponse<VehicleAutocompleteResultDto> = yield call(api.autocompleteVehicles, payload);
    yield put(autocompleteVehiclesActions.success({ id: new Date().getTime(), ...response.data }));
  }
  catch ( error ) {
    yield put(autocompleteVehiclesActions.failure(error));
  }
}

function* getVehiclePrice({ payload }: ActionCreator<VehiclePriceRequest>) {
  try {
    const response: AxiosResponse<number> = yield call(api.getVehiclePrice, payload);
    yield put(getVehiclePriceActions.success(response.data));
  }
  catch ( error ) {
    yield put(getVehiclePriceActions.failure(error));
  }
}

function* getVehicleMappings({ payload }: ActionCreator<VehicleMappingTypesRequest>) {
  try {
    const response: AxiosResponse<VehicleMappingData[]> = yield call(api.getVehicleMappings, payload);

    const previousMappings = [];
    const queriedMappings = payload.queriedMappings ? payload.queriedMappings : [];
    ([...yield select(selectCalcVehicleMappings)] as VehicleMappingData[]).forEach(mapping => {
      if ( !contains(queriedMappings, mapping.type) ) {
        previousMappings.push(mapping);
      }
    });

    yield put(getVehicleMappingsActions.success([...previousMappings, ...response.data]));
  }
  catch ( error ) {
    yield put(getVehicleMappingsActions.failure(error));
  }
}

function* remapVehicleMappings({ payload }: ActionCreator<VehicleMappingTypesRequest>) {
  try {
    yield call(api.remapVehicleMappings, payload);
    yield put(remapVehicleMappingsActions.success());
    yield put(getVehicleMappingsActions.request({ queriedMappings: payload.queriedMappings }));
  }
  catch ( error ) {
    yield put(remapVehicleMappingsActions.failure(error));
  }
}

function* mapVehicleMappingsUpdates({ payload }: ActionCreator<VehicleMappingTypesRequest>) {
  try {
    yield call(api.mapVehicleMappingsUpdates, payload);
    yield call(api.unmapObsoleteVehicleMappings, payload);
    yield put(mapVehicleMappingsUpdatesActions.success());
    yield put(getVehicleMappingsActions.request({ queriedMappings: payload.queriedMappings }));
  }
  catch ( error ) {
    yield put(mapVehicleMappingsUpdatesActions.failure(error));
  }
}

function* filterVehicleBrands({ payload }: ActionCreator<VehicleMappingFilterPageRequest>) {
  try {
    const response: AxiosResponse<VehicleBrandMappingFilterPageResultDto> = yield call(api.filterVehicleBrands, payload);
    yield put(filterVehicleBrandMappingsActions.success({ ...response.data, id: new Date().getTime() }));
  }
  catch ( error ) {
    yield put(filterVehicleBrandMappingsActions.failure(error));
  }
}

function* createVehicleBrand({ payload }: ActionCreator<CreateVehicleMapping>) {
  try {
    const response: AxiosResponse<VehicleMapping> = yield call(api.createVehicleBrandMapping, payload);
    yield put(createVehicleBrandMappingActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: VehicleBrandMappingFilterPageResult = yield { ...select(selectCalcVehicleBrandsCurrentPage) };
    yield put(filterVehicleBrandMappingsActions.request({
      keyword: currentPage.keyword,
      pageSize: currentPage.pageSize,
      pageIndex: currentPage.pageIndex,
      queriedMappings: currentPage.queriedMappings,
      excludeMapped: currentPage.excludeMapped
    }));
  }
  catch ( error ) {
    yield put(createVehicleBrandMappingActions.failure(error));
  }
}

function* updateVehicleBrands({ payload }: ActionCreator<UpdateVehicleMappingList>) {
  try {
    const response: AxiosResponse<VehicleMapping[]> = yield call(api.updateVehicleBrandMappings, payload);

    const currentPage: VehicleBrandMappingFilterPageResult = yield { ...select(selectCalcVehicleBrandsCurrentPage) };
    let pageData = [...currentPage.pageData];
    response.data.forEach(updatedItem => pageData = replaceInArray(pageData, item => item.id === updatedItem.id, updatedItem));
    yield put(updateVehicleBrandMappingsActions.success({ ...currentPage, pageData, id: new Date().getTime() }));

    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateVehicleBrandMappingsActions.failure(error));
  }
}

function* filterVehicleModels({ payload }: ActionCreator<EntityObjectRequest<VehicleMappingFilterPageRequest>>) {
  try {
    const response: AxiosResponse<VehicleBrandMappingFilterPageResultDto> = yield call(api.filterVehicleModels, payload);
    yield put(filterVehicleModelMappingsActions.success({ ...response.data, id: new Date().getTime() }));
  }
  catch ( error ) {
    yield put(filterVehicleModelMappingsActions.failure(error));
  }
}

function* createVehicleModel({ payload }: ActionCreator<EntityObjectRequest<CreateVehicleMapping>>) {
  try {
    const response: AxiosResponse<VehicleMapping> = yield call(api.createVehicleModelMapping, payload);
    yield put(createVehicleModelMappingActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: VehicleModelMappingFilterPageResult = yield { ...select(selectCalcVehicleModelsCurrentPage) };
    yield put(filterVehicleModelMappingsActions.request({
      id: currentPage.brand.id,
      object: {
        keyword: currentPage.keyword,
        pageSize: currentPage.pageSize,
        pageIndex: currentPage.pageIndex,
        queriedMappings: currentPage.queriedMappings,
        excludeMapped: currentPage.excludeMapped
      }
    }));
  }
  catch ( error ) {
    yield put(createVehicleModelMappingActions.failure(error));
  }
}

function* updateVehicleModels({ payload }: ActionCreator<EntityObjectRequest<UpdateVehicleMappingList>>) {
  try {
    const response: AxiosResponse<VehicleMapping[]> = yield call(api.updateVehicleModelMappings, payload);

    const currentPage: VehicleModelMappingFilterPageResult = yield { ...select(selectCalcVehicleModelsCurrentPage) };
    let pageData = [...currentPage.pageData];
    response.data.forEach(updatedItem => pageData = replaceInArray(pageData, item => item.id === updatedItem.id, updatedItem));
    yield put(updateVehicleModelMappingsActions.success({ ...currentPage, pageData, id: new Date().getTime() }));

    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateVehicleModelMappingsActions.failure(error));
  }
}

export function* calcVehiclesSaga() {
  yield takeLatest(createActionType(actionType.AUTOCOMPLETE, apiOperation.REQUEST), autocompleteVehicles);
  yield takeLatest(createActionType(actionType.GET_PRICE, apiOperation.REQUEST), getVehiclePrice);

  yield takeLatest(createActionType(actionType.GET_MAPPINGS, apiOperation.REQUEST), getVehicleMappings);
  yield takeLatest(createActionType(actionType.REMAP_MAPPINGS, apiOperation.REQUEST), remapVehicleMappings);
  yield takeLatest(createActionType(actionType.UPDATE_MAPPINGS, apiOperation.REQUEST), mapVehicleMappingsUpdates);

  yield takeLatest(createActionType(actionType.FILTER_BRANDS, apiOperation.REQUEST), filterVehicleBrands);
  yield takeLatest(createActionType(actionType.CREATE_BRAND, apiOperation.REQUEST), createVehicleBrand);
  yield takeLatest(createActionType(actionType.UPDATE_BRANDS, apiOperation.REQUEST), updateVehicleBrands);

  yield takeLatest(createActionType(actionType.FILTER_MODELS, apiOperation.REQUEST), filterVehicleModels);
  yield takeLatest(createActionType(actionType.CREATE_MODEL, apiOperation.REQUEST), createVehicleModel);
  yield takeLatest(createActionType(actionType.UPDATE_MODELS, apiOperation.REQUEST), updateVehicleModels);
}
