import "./history-view.less";
import React from "react";
import { Table } from "antd";
import { HistoryChangeCreator, HistoryItem, HistoryRecord, HistoryRecordType } from "../types";
import { contains } from "../../utils/utils";
import { formatLocaleDateTime } from "../../utils/formatUtils";
import t from "../../../app/i18n";

import HistoryItemValueView from "./HistoryItemValueView";

export interface Props {
  item: HistoryItemProps;
  translationRootPath: string;
  type?: HistoryRecordType;
  excludeFields?: string[];
}

export interface HistoryItemProps {
  historyRecords: HistoryRecord[];
  [key: string]: any;
}

const HistoryView = ({ item, translationRootPath, type, excludeFields }: Props) => {

  const formatChangeCreatorName = (creator: HistoryChangeCreator): string => {
    return creator.personId
      ? creator.personAggregatedName + (creator.personAccountName ? ` (${creator.personAccountName})` : ` (${creator.personAccountUsername})`)
      : t("history.helpers.systemChangeCreator")
  };

  const formatItemTitle = (record: HistoryRecord): string => {
    return `${formatLocaleDateTime(record.changeCreatedAt)} | ${formatChangeCreatorName(record.changeCreatedBy)}`;
  };

  const findClosestNewerHistoryItem = (recordIndex: number, fieldName: string): HistoryItem => {
    for ( let i = recordIndex + 1; i < item.historyRecords.length; i++ ) {
      const historyItem = item.historyRecords[i].items.find(item => item.fieldName === fieldName);
      if ( historyItem ) {
        return historyItem;
      }
    }
    return { fieldName, value: item[fieldName] };
  };

  if ( item && item.historyRecords ) {
    const data = item.historyRecords.map((record, recordIndex) => {
      const items = record.items
        ? excludeFields ? record.items.filter(item => !contains(excludeFields, item.fieldName)) : record.items
        : [];

      return items.length === 0 ? null : (
        <React.Fragment key={recordIndex}>
          <Table<HistoryItem>
            size="middle"
            className="table-row-stripped history-view-table"
            rowKey={record => record.fieldName}
            dataSource={items}
            title={() => <h4>{formatItemTitle(record)}</h4>}
            pagination={false}
            columns={[
              {
                key: "fieldName",
                className: "history-item-label-column",
                render: (text, item) => t(translationRootPath + "." + item.fieldName)
              },
              {
                key: "oldValue",
                className: "history-item-value-column",
                render: (text, item) => <HistoryItemValueView item={item} recordType={type} />
              },
              {
                key: "newValue",
                className: "history-item-value-column",
                render: (text, item) =>
                  <HistoryItemValueView item={findClosestNewerHistoryItem(recordIndex, item.fieldName)}
                                        recordType={type} />
              }
            ]} />
        </React.Fragment>
      )
    });

    return <>{data}</>;
  }

  return <span className="sub-header-info dashed">{t("history.helpers.noData")}</span>;
};

export default HistoryView;
