import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { CommissionsBatch } from "../types";
import { ActionProps, RootState } from "../../../../common/types";
import {
  changeCommissionsBatchStepActions,
  createCommissionsUnitActions,
  deleteCommissionsBatchActions,
  deleteCommissionsBatchAttachmentActions,
  deleteCommissionsUnitActions,
  deleteStateBatchDetailAction,
  getCommissionsBatchActions,
  replaceCommissionsBatchAttachmentActions,
  selectBatchDetail,
  updateCommissionsBatchActions,
  updateCommissionsUnitActions,
  uploadCommissionsBatchAttachmentActions
} from "../ducks";
import { requests } from "../api";

import CommissionsBatchDetailView from "../components/views/detail/CommissionsBatchDetailView";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";

interface StateProps {
  batch: CommissionsBatch;
}

interface ActionsMap {
  getCommissionsBatch: typeof getCommissionsBatchActions.request;
  updateCommissionsBatch: typeof updateCommissionsBatchActions.request;
  deleteCommissionsBatch: typeof deleteCommissionsBatchActions.request;
  changeCommissionsBatchStep: typeof changeCommissionsBatchStepActions.request;
  createCommissionsUnit: typeof createCommissionsUnitActions.request;
  updateCommissionsUnit: typeof updateCommissionsUnitActions.request;
  deleteCommissionsUnit: typeof deleteCommissionsUnitActions.request;
  uploadCommissionsBatchAttachment: typeof uploadCommissionsBatchAttachmentActions.request;
  replaceCommissionsBatchAttachment: typeof replaceCommissionsBatchAttachmentActions.request;
  deleteCommissionsBatchAttachment: typeof deleteCommissionsBatchAttachmentActions.request;
  deleteStateBatchDetail: typeof deleteStateBatchDetailAction;
}

const CommissionsBatchDetailContainer = (props: StateProps & ActionProps<ActionsMap> & RouteComponentProps) => {

  useEffect(() => {
    if ( !props.batch ) {
      props.actions.getCommissionsBatch({ id: props.match.params["id"] });
    }
    return () => props.actions.deleteStateBatchDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DisplayWrapper itemLoaded={!!props.batch} notFoundCheckRequest={requests.GET_COMMISSIONS_BATCH}>
      <CommissionsBatchDetailView
        batch={props.batch}
        onBatchUpdate={props.actions.updateCommissionsBatch}
        onBatchDelete={props.actions.deleteCommissionsBatch}
        onBatchStepChange={props.actions.changeCommissionsBatchStep}
        onUnitCreate={props.actions.createCommissionsUnit}
        onUnitUpdate={props.actions.updateCommissionsUnit}
        onUnitDelete={props.actions.deleteCommissionsUnit}
        onAttachmentUpload={props.actions.uploadCommissionsBatchAttachment}
        onAttachmentReplace={props.actions.replaceCommissionsBatchAttachment}
        onAttachmentDelete={props.actions.deleteCommissionsBatchAttachment} />
    </DisplayWrapper>
  )
};

const mapStateToProps = (state: RootState): StateProps => ({
  batch: selectBatchDetail(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getCommissionsBatch: getCommissionsBatchActions.request,
    updateCommissionsBatch: updateCommissionsBatchActions.request,
    deleteCommissionsBatch: deleteCommissionsBatchActions.request,
    changeCommissionsBatchStep: changeCommissionsBatchStepActions.request,
    createCommissionsUnit: createCommissionsUnitActions.request,
    updateCommissionsUnit: updateCommissionsUnitActions.request,
    deleteCommissionsUnit: deleteCommissionsUnitActions.request,
    uploadCommissionsBatchAttachment: uploadCommissionsBatchAttachmentActions.request,
    replaceCommissionsBatchAttachment: replaceCommissionsBatchAttachmentActions.request,
    deleteCommissionsBatchAttachment: deleteCommissionsBatchAttachmentActions.request,
    deleteStateBatchDetail: deleteStateBatchDetailAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(CommissionsBatchDetailContainer);
