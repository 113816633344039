import { combineReducers } from "redux";
import { CrashOffer, MtplOffer, VehicleCalc, VehicleCalcReducerState, VehicleCalcResults, VehicleGen } from "./types";
import { GenResponse } from "../types";
import { RootState } from "../../../../common/types";
import {
  apiOperation,
  createActionCreator,
  createApiActionCreators,
  createReducer
} from "../../../../common/utils/reduxUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  CALCULATE = 'calc-vehicle/CALCULATE',
  GENERATE = 'calc-vehicle/GENERATE',
  GENERATE_OFFER = 'calc-vehicle/GENERATE_OFFER',
  SET_CALC_RESULTS = 'calc-vehicle/SET_CALC_RESULTS',
  SET_INITIAL_CALC_DATA = 'calc-vehicle/SET_INITIAL_CALC_DATA',
  SET_INITIAL_GEN_DATA = 'calc-vehicle/SET_INITIAL_GEN_DATA',
  DELETE_STATE_CALC_RESULTS = 'calc-vehicle/DELETE_STATE_CALC_RESULTS',
  DELETE_STATE_GEN_RESULT = 'calc-vehicle/DELETE_STATE_GEN_RESULT',
  DELETE_STATE_INITIAL_CALC_GEN_DATA = 'calc-vehicle/DELETE_STATE_INITIAL_CALC_GEN_DATA'
}

/**
 * ACTIONS
 */
export const calculateVehicleActions = createApiActionCreators<VehicleCalc, VehicleCalcResults>(actionType.CALCULATE);
export const generateVehicleActions = createApiActionCreators<VehicleGen, GenResponse>(actionType.GENERATE);
export const generateVehicleOfferActions = createApiActionCreators<MtplOffer | CrashOffer, void>(actionType.GENERATE_OFFER);

export const setVehicleCalcResultsAction = createActionCreator<VehicleCalcResults>(actionType.SET_CALC_RESULTS);
export const setVehicleInitialCalcDataAction = createActionCreator<VehicleCalc>(actionType.SET_INITIAL_CALC_DATA);
export const setVehicleInitialGenDataAction = createActionCreator<VehicleGen>(actionType.SET_INITIAL_GEN_DATA);

export const deleteStateVehicleCalcResultsAction = createActionCreator<void>(actionType.DELETE_STATE_CALC_RESULTS);
export const deleteStateVehicleGenResultAction = createActionCreator<void>(actionType.DELETE_STATE_GEN_RESULT);
export const deleteStateVehicleInitialCalcGenDataAction = createActionCreator<void>(actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA);

/**
 * REDUCERS
 */
const initialState: VehicleCalcReducerState = {
  calcResults: { mtpl: [], crash: [], gap: [], pas: [] },
  genResult: null,
  initialCalcData: null,
  initialGenData: null
};

const calcResultsReducer = createReducer<VehicleCalcResults>(initialState.calcResults, {
  [actionType.CALCULATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.calcResults
  },
  [actionType.SET_CALC_RESULTS]: (state, payload) => payload,
  [actionType.DELETE_STATE_CALC_RESULTS]: () => initialState.calcResults
});

const genResultReducer = createReducer<GenResponse>(initialState.genResult, {
  [actionType.GENERATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.genResult
  },
  [actionType.DELETE_STATE_GEN_RESULT]: () => initialState.genResult
});

const initialCalcDataReducer = createReducer<VehicleCalc>(initialState.initialCalcData, {
  [actionType.SET_INITIAL_CALC_DATA]: (state, payload) => payload,
  [actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA]: () => initialState.initialCalcData
});

const initialGenDataReducer = createReducer<VehicleGen>(initialState.initialGenData, {
  [actionType.SET_INITIAL_GEN_DATA]: (state, payload) => payload,
  [actionType.DELETE_STATE_INITIAL_CALC_GEN_DATA]: () => initialState.initialGenData
});

export default combineReducers({
  calcResults: calcResultsReducer,
  genResult: genResultReducer,
  initialCalcData: initialCalcDataReducer,
  initialGenData: initialGenDataReducer
});

/**
 * SELECTORS
 */
const selectVehicleCalc = (state: RootState): VehicleCalcReducerState => state.calculator.calcs.vehicle;

export const selectVehicleCalcResults = (state: RootState): VehicleCalcResults => selectVehicleCalc(state).calcResults;
export const selectVehicleGenResult = (state: RootState): GenResponse => selectVehicleCalc(state).genResult;
export const selectVehicleInitialCalcData = (state: RootState): VehicleCalc => selectVehicleCalc(state).initialCalcData;
export const selectVehicleInitialGenData = (state: RootState): VehicleGen => selectVehicleCalc(state).initialGenData;
