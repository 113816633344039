import React from "react";
import { Button, Col, Input, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import messageUtils from "../../../../../common/utils/messageUtils";
import { validationConstants, validationFunctions } from "../../../../../common/utils/validationUtils";
import t from "../../../../../app/i18n";

interface Props {
  initialSearchKeyword: string;
  onSearchSubmit(keyword: string): void;
  onCreateClick(): void;
}

const InstitutionListSearchHeaderView = (props: Props) => {

  const handleSearchSubmit = (keyword: string): void => {
    if ( validationFunctions.validateSearchKeyword(keyword) ) {
      props.onSearchSubmit(keyword);
    }
    else {
      messageUtils.errorMessage(t("validation.size", {
        min: validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
        max: validationConstants.SEARCH_KEYWORD_MAX_LENGTH
      }));
    }
  };

  return (
    <Row className="margin-bottom-medium">
      <Col span={6}>
        <h2>{t("admin.institution.titles.list")}</h2>
      </Col>

      <Col span={18} className="right-align">
        <Button className="margin-right-small" type="primary" icon={<PlusOutlined />} onClick={props.onCreateClick}>
          {t("admin.institution.actions.createInstitution")}
        </Button>

        <Input.Search
          style={{ width: "350px" }}
          enterButton
          allowClear
          placeholder={t("admin.institution.actions.searchHint")}
          defaultValue={props.initialSearchKeyword}
          onSearch={handleSearchSubmit} />
      </Col>
    </Row>
  );
};

export default InstitutionListSearchHeaderView;
