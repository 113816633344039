import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import jobReducer, { jobSaga } from "./jobs/ducks";
import institutionReducer, { institutionSaga } from "./institution/ducks";
import productReducer, { productSaga } from "./product/ducks";
import lifeInsuranceTariffReducer, { lifeInsuranceTariffSaga } from "./lifeinsurancetariff/ducks";

/**
 * REDUCERS
 */
export default combineReducers({
  job: jobReducer,
  institution: institutionReducer,
  product: productReducer,
  lifeInsuranceTariff: lifeInsuranceTariffReducer
});

/**
 * SAGAS
 */
export function* adminSaga() {
  yield all([jobSaga(), institutionSaga(), productSaga(), lifeInsuranceTariffSaga()]);
}
