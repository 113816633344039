import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import { Pathname } from "history";
import {
  ChangeCommissionsBatchStepRequest,
  CommissionsBatch,
  CommissionsBatchInputAttachment,
  CommissionsBatchReducerState,
  CommissionsFilterPageRequest,
  CommissionsFilterPageResult,
  CommissionsUnit,
  CreateCommissionsUnit,
  CreateImportedCommission,
  CreateUpdateCommissionsBatch,
  DeleteCommissionsUnitRequest,
  ImportedCommission,
  IncludeImportedCommission,
  PostponeImportedCommission,
  UpdateCalculatedCommissionList,
  UpdateCommissionsUnit,
  UpdateImportedCommission
} from "./types";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  PageRequest,
  PageResult,
  RootState,
  TwoLevelEntityIdRequest,
  TwoLevelEntityObjectRequest,
  UUID
} from "../../../common/types";
import { changeLocationKeyAction, selectRouterLocationPathname } from "../../ducks";
import api from "./api";
import { openBlobFile, replaceInArray } from "../../../common/utils/utils";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import { initPageResult, initSearchPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import t from "../../../app/i18n";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'commissions-batch/FILTER',
  GET = 'commissions-batch/GET',
  CREATE = 'commissions-batch/CREATE',
  UPDATE = 'commissions-batch/UPDATE',
  DELETE = 'commissions-batch/DELETE',
  CHANGE_STEP = 'commissions-batch/CHANGE_STEP',
  SET_STATE_LIST = 'commissions-batch/SET_STATE_LIST',
  SET_STATE_DETAIL = 'commissions-batch/SET_STATE_DETAIL',
  DELETE_STATE_LIST = 'commissions-batch/DELETE_STATE_LIST',
  DELETE_STATE_DETAIL = 'commissions-batch/DELETE_STATE_DETAIL',

  CREATE_UNIT = 'commissions-unit/CREATE',
  UPDATE_UNIT = 'commissions-unit/UPDATE',
  DELETE_UNIT = 'commissions-unit/DELETE',

  UPLOAD_ATTACHMENT = 'commissions-batch-attachment/UPLOAD',
  REPLACE_ATTACHMENT = 'commissions-batch-attachment/REPLACE',
  DOWNLOAD_ATTACHMENT = 'commissions-batch-attachment/DOWNLOAD',
  DELETE_ATTACHMENT = 'commissions-batch-attachment/DELETE',

  FILTER_COMMISSIONS = 'commission/FILTER',
  CREATE_COMMISSION = 'commission/CREATE',
  UPDATE_COMMISSION = 'commission/UPDATE',
  POSTPONE_COMMISSION = 'commission/POSTPONE',
  TRY_TO_INCLUDE_COMMISSION = 'commission/TRY_TO_INCLUDE',
  UPDATE_CALCULATED_COMMISSIONS = 'commission/UPDATE_CALCULATED_COMMISSIONS',
  DELETE_COMMISSION = 'commission/DELETE',
  SET_STATE_COMMISSIONS_PAGE = 'commission/SET_STATE_COMMISSIONS_PAGE',
  DELETE_STATE_COMMISSIONS_PAGE = 'commission/DELETE_STATE_COMMISSIONS_PAGE'
}

/**
 * ACTIONS
 */
export const filterCommissionsBatchesActions = createApiActionCreators<PageRequest, PageResult<CommissionsBatch>>(actionType.FILTER);
export const getCommissionsBatchActions = createApiActionCreators<EntityIdRequest, CommissionsBatch>(actionType.GET);
export const createCommissionsBatchActions = createApiActionCreators<CreateUpdateCommissionsBatch, CommissionsBatch>(actionType.CREATE);
export const updateCommissionsBatchActions = createApiActionCreators<EntityObjectRequest<CreateUpdateCommissionsBatch>, CommissionsBatch>(actionType.UPDATE);
export const deleteCommissionsBatchActions = createApiActionCreators<EntityIdRequest, void>(actionType.DELETE);
export const changeCommissionsBatchStepActions = createApiActionCreators<EntityObjectRequest<ChangeCommissionsBatchStepRequest>, CommissionsBatch>(actionType.CHANGE_STEP);

export const setStateBatchesPageAction = createActionCreator<PageResult<CommissionsBatch>>(actionType.SET_STATE_LIST);
export const setStateBatchDetailAction = createActionCreator<CommissionsBatch>(actionType.SET_STATE_DETAIL);
export const deleteStateBatchesPageAction = createActionCreator<void>(actionType.DELETE_STATE_LIST);
export const deleteStateBatchDetailAction = createActionCreator<void>(actionType.DELETE_STATE_DETAIL);

export const createCommissionsUnitActions = createApiActionCreators<EntityObjectRequest<CreateCommissionsUnit>, CommissionsUnit>(actionType.CREATE_UNIT);
export const updateCommissionsUnitActions = createApiActionCreators<TwoLevelEntityObjectRequest<UpdateCommissionsUnit>, CommissionsUnit>(actionType.UPDATE_UNIT);
export const deleteCommissionsUnitActions = createApiActionCreators<EntityObjectRequest<DeleteCommissionsUnitRequest>, void>(actionType.DELETE_UNIT);

export const uploadCommissionsBatchAttachmentActions = createApiActionCreators<EntityObjectRequest<FormData>, CommissionsBatchInputAttachment>(actionType.UPLOAD_ATTACHMENT);
export const replaceCommissionsBatchAttachmentActions = createApiActionCreators<TwoLevelEntityObjectRequest<FormData>, CommissionsBatchInputAttachment>(actionType.REPLACE_ATTACHMENT);
export const downloadCommissionsBatchAttachmentActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.DOWNLOAD_ATTACHMENT);
export const deleteCommissionsBatchAttachmentActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.DELETE_ATTACHMENT);

export const filterCommissionsActions = createApiActionCreators<EntityObjectRequest<CommissionsFilterPageRequest>, CommissionsFilterPageResult>(actionType.FILTER_COMMISSIONS);
export const createCommissionActions = createApiActionCreators<EntityObjectRequest<CreateImportedCommission>, ImportedCommission>(actionType.CREATE_COMMISSION);
export const updateCommissionActions = createApiActionCreators<TwoLevelEntityObjectRequest<UpdateImportedCommission>, ImportedCommission>(actionType.UPDATE_COMMISSION);
export const postponeCommissionActions = createApiActionCreators<TwoLevelEntityObjectRequest<PostponeImportedCommission>, ImportedCommission>(actionType.POSTPONE_COMMISSION);
export const tryToIncludeCommissionActions = createApiActionCreators<TwoLevelEntityObjectRequest<IncludeImportedCommission>, ImportedCommission>(actionType.TRY_TO_INCLUDE_COMMISSION);
export const updateCalculatedCommissionsActions = createApiActionCreators<TwoLevelEntityObjectRequest<UpdateCalculatedCommissionList>, ImportedCommission>(actionType.UPDATE_CALCULATED_COMMISSIONS);
export const deleteCommissionActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.DELETE_COMMISSION);

export const setStateCommissionsPageAction = createActionCreator<CommissionsFilterPageResult>(actionType.SET_STATE_COMMISSIONS_PAGE);
export const deleteStateCommissionsPageAction = createActionCreator<void>(actionType.DELETE_STATE_COMMISSIONS_PAGE);

/**
 * REDUCERS
 */
const initialState: CommissionsBatchReducerState = {
  batchesPage: initPageResult<CommissionsBatch>(),
  batchDetail: null,
  commissionsPage: {
    ...initSearchPageResult<ImportedCommission>(),
    include: null,
    onlyManual: false,
    onlyPostponed: false,
    postponementReasons: [],
    institutionIds: [],
    sourceIds: [],
    sources: [],
    commissionAmountsSum: null
  }
}

const batchesPageReducer = createReducer<PageResult<CommissionsBatch>>(initialState.batchesPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.batchesPage
  },
  [actionType.SET_STATE_LIST]: (state, payload) => payload,
  [actionType.DELETE_STATE_LIST]: () => initialState.batchesPage
});

const batchDetailReducer = createReducer<CommissionsBatch>(initialState.batchDetail, {
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.batchDetail
  },
  [actionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.CHANGE_STEP]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.batchDetail
  },
  [actionType.SET_STATE_DETAIL]: (state, payload) => payload,
  [actionType.DELETE_STATE_DETAIL]: () => initialState.batchDetail
});

const commissionsPageReducer = createReducer<CommissionsFilterPageResult>(initialState.commissionsPage, {
  [actionType.FILTER_COMMISSIONS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.commissionsPage
  },
  [actionType.SET_STATE_COMMISSIONS_PAGE]: (state, payload) => payload,
  [actionType.DELETE_STATE_COMMISSIONS_PAGE]: () => initialState.commissionsPage
});

export default combineReducers({
  batchesPage: batchesPageReducer,
  batchDetail: batchDetailReducer,
  commissionsPage: commissionsPageReducer,
});

/**
 * SELECTORS
 */
const selectBatch = (state: RootState): CommissionsBatchReducerState => state.commissions.batch;

export const selectBatchesPage = (state: RootState): PageResult<CommissionsBatch> => selectBatch(state).batchesPage;
export const selectBatchDetail = (state: RootState): CommissionsBatch => selectBatch(state).batchDetail;
export const selectCommissionsPage = (state: RootState): CommissionsFilterPageResult => selectBatch(state).commissionsPage;

/**
 * SAGAS - BATCH
 */
function* filterCommissionsBatches({ payload }: ActionCreator<PageRequest>) {
  try {
    const response: AxiosResponse<PageResult<CommissionsBatch>> = yield call(api.filterCommissionsBatches, payload);
    yield put(filterCommissionsBatchesActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterCommissionsBatchesActions.failure(error));
  }
}

function* getCommissionsBatch({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.getCommissionsBatch, payload);
    yield put(getCommissionsBatchActions.success(response.data));
  }
  catch ( error ) {
    yield put(getCommissionsBatchActions.failure(error));
  }
}

function* createCommissionsBatch({ payload }: ActionCreator<CreateUpdateCommissionsBatch>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.createCommissionsBatch, payload);
    yield put(createCommissionsBatchActions.success(response.data));
    yield put(changeLocationKeyAction());
    yield put(push("/commissions/batches/" + response.data.id));
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createCommissionsBatchActions.failure(error));
  }
}

function* updateCommissionsBatch({ payload }: ActionCreator<EntityObjectRequest<CreateUpdateCommissionsBatch>>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.updateCommissionsBatch, payload);

    if ( yield select(selectBatchDetail) ) {
      yield put(updateCommissionsBatchActions.success(response.data));
    }
    else {
      yield put(updateCommissionsBatchActions.success(null));
      const batchesPage: PageResult<CommissionsBatch> = yield select(selectBatchesPage);
      yield put(setStateBatchesPageAction({
        ...batchesPage,
        pageData: replaceInArray(batchesPage.pageData, item => item.id === payload.id, response.data)
      }));
    }

    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateCommissionsBatchActions.failure(error));
  }
}

function* deleteCommissionsBatch({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteCommissionsBatch, payload);

    if ( yield select(selectBatchDetail) ) {
      yield put(deleteCommissionsBatchActions.success());
      yield put(replace("/commissions/batches/"));
    }
    else {
      yield put(deleteCommissionsBatchActions.success());
      const page: PageResult<CommissionsBatch> = yield select(selectBatchesPage);
      yield put(filterCommissionsBatchesActions.request({
        pageIndex: page.pageElementsCount === 1 ? Math.max(page.pageIndex - 1, 0) : page.pageIndex,
        pageSize: page.pageSize
      }));
    }
  }
  catch ( error ) {
    yield put(deleteCommissionsBatchActions.failure(error));
  }
}

function* changeCommissionsBatchStep({ payload }: ActionCreator<EntityObjectRequest<ChangeCommissionsBatchStepRequest>>) {
  try {
    const response: AxiosResponse<CommissionsBatch> = yield call(api.changeCommissionsBatchStep, payload);
    yield put(changeCommissionsBatchStepActions.success(response.data));
  }
  catch ( error ) {
    yield put(changeCommissionsBatchStepActions.failure(error));
  }
}

/**
 * SAGAS - UNIT
 */
function* createCommissionsUnit({ payload }: ActionCreator<EntityObjectRequest<CreateCommissionsUnit>>) {
  try {
    const response: AxiosResponse<CommissionsUnit> = yield call(api.createCommissionsUnit, payload);
    yield put(createCommissionsUnitActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id }));
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createCommissionsUnitActions.failure(error));
  }
}

function* updateCommissionsUnit({ payload }: ActionCreator<TwoLevelEntityObjectRequest<UpdateCommissionsUnit>>) {
  try {
    const response: AxiosResponse<CommissionsUnit> = yield call(api.updateCommissionsUnit, payload);
    yield put(updateCommissionsUnitActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id1 }));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateCommissionsUnitActions.failure(error));
  }
}

function* deleteCommissionsUnit({ payload }: ActionCreator<EntityObjectRequest<DeleteCommissionsUnitRequest>>) {
  try {
    yield call(api.deleteCommissionsUnit, payload);
    yield put(deleteCommissionsUnitActions.success());
    yield put(getCommissionsBatchActions.request({ id: payload.id }));
  }
  catch ( error ) {
    yield put(deleteCommissionsUnitActions.failure(error));
  }
}

/**
 * SAGAS - ATTACHMENT
 */
function* uploadCommissionsBatchAttachment({ payload }: ActionCreator<EntityObjectRequest<FormData>>) {
  try {
    const response: AxiosResponse<CommissionsBatchInputAttachment> = yield call(api.uploadCommissionsBatchAttachment, payload);
    yield put(uploadCommissionsBatchAttachmentActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id }));
  }
  catch ( error ) {
    yield put(uploadCommissionsBatchAttachmentActions.failure(error));
  }
}

function* replaceCommissionsBatchAttachment({ payload }: ActionCreator<TwoLevelEntityObjectRequest<FormData>>) {
  try {
    const response: AxiosResponse<CommissionsBatchInputAttachment> = yield call(api.replaceCommissionsBatchAttachment, payload);
    yield put(replaceCommissionsBatchAttachmentActions.success(response.data));
    yield put(getCommissionsBatchActions.request({ id: payload.id1 }));
  }
  catch ( error ) {
    yield put(replaceCommissionsBatchAttachmentActions.failure(error));
  }
}

function* downloadCommissionsBatchAttachment({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCommissionsBatchAttachment, payload);
    openBlobFile(response);
    yield put(downloadCommissionsBatchAttachmentActions.success());
  }
  catch ( error ) {
    yield put(downloadCommissionsBatchAttachmentActions.failure(error));
  }
}

function* deleteCommissionsBatchAttachment({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.deleteCommissionsBatchAttachment, payload);
    yield put(deleteCommissionsBatchAttachmentActions.success());
    yield put(getCommissionsBatchActions.request({ id: payload.id1 }));
  }
  catch ( error ) {
    yield put(deleteCommissionsBatchAttachmentActions.failure(error));
  }
}

/**
 * SAGAS - COMMISSION
 */
function* filterCommissions({ payload }: ActionCreator<EntityObjectRequest<CommissionsFilterPageRequest>>) {
  try {
    const response: AxiosResponse<CommissionsFilterPageResult> = yield call(api.filterCommissions, payload);
    yield put(filterCommissionsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterCommissionsActions.failure(error));
  }
}

function* createCommission({ payload }: ActionCreator<EntityObjectRequest<CreateImportedCommission>>) {
  try {
    const response: AxiosResponse<ImportedCommission> = yield call(api.createCommission, payload);
    yield put(createCommissionActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();
    yield* refreshCommissionsPage(payload.id);
  }
  catch ( error ) {
    yield put(createCommissionActions.failure(error));
  }
}

function* updateCommission({ payload }: ActionCreator<TwoLevelEntityObjectRequest<UpdateImportedCommission>>) {
  try {
    const response: AxiosResponse<ImportedCommission> = yield call(api.updateCommission, payload);
    yield put(updateCommissionActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* replaceImportedCommissionOnPage(response.data, payload.id1, true);
  }
  catch ( error ) {
    yield put(updateCommissionActions.failure(error));
  }
}

function* postponeCommission({ payload }: ActionCreator<TwoLevelEntityObjectRequest<PostponeImportedCommission>>) {
  try {
    const response: AxiosResponse<ImportedCommission> = yield call(api.postponeCommission, payload);
    yield put(postponeCommissionActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
    yield* replaceImportedCommissionOnPage(response.data, payload.id1);
  }
  catch ( error ) {
    yield put(postponeCommissionActions.failure(error));
  }
}

function* tryToIncludeCommission({ payload }: ActionCreator<TwoLevelEntityObjectRequest<IncludeImportedCommission>>) {
  try {
    const response: AxiosResponse<ImportedCommission> = yield call(api.tryToIncludeCommission, payload);
    yield put(tryToIncludeCommissionActions.success(response.data));
    if ( response.data.postponed ) {
      messageUtils.warnNotification(t("common.warning"), t("commissions.batch.helpers.commissionIncludeFailure"));
    }
    else {
      messageUtils.itemUpdatedNotification();
    }
    yield* replaceImportedCommissionOnPage(response.data, payload.id1);
  }
  catch ( error ) {
    yield put(tryToIncludeCommissionActions.failure(error));
  }
}

function* updateCalculatedCommissions({ payload }: ActionCreator<TwoLevelEntityObjectRequest<UpdateCalculatedCommissionList>>) {
  try {
    const response: AxiosResponse<ImportedCommission> = yield call(api.updateCalculatedCommissions, payload);
    yield put(updateCalculatedCommissionsActions.success(response.data));
    yield* replaceImportedCommissionOnPage(response.data, payload.id1);
  }
  catch ( error ) {
    yield put(updateCalculatedCommissionsActions.failure(error));
  }
}

function* deleteCommission({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.deleteCommission, payload);
    yield put(deleteCommissionActions.success());
    yield* refreshCommissionsPage(payload.id1, true);
  }
  catch ( error ) {
    yield put(deleteCommissionActions.failure(error));
  }
}

function* replaceImportedCommissionOnPage(commission: ImportedCommission, batchId: UUID, shouldRefresh?: boolean) {
  if ( shouldRefresh ) {
    yield* refreshCommissionsPage(batchId);
  }
  else {
    const page: CommissionsFilterPageResult = yield select(selectCommissionsPage);
    yield put(setStateCommissionsPageAction({
      ...page,
      pageData: replaceInArray(page.pageData, c => c.id === commission.id, commission)
    }));
  }

  const location: Pathname = yield select(selectRouterLocationPathname);
  if ( !location.endsWith("imports") ) {
    yield put(getCommissionsBatchActions.request({ id: batchId }));
  }
}

function* refreshCommissionsPage(batchId: UUID, movePage?: boolean) {
  const page: CommissionsFilterPageResult = yield select(selectCommissionsPage);
  yield put(filterCommissionsActions.request({
    id: batchId,
    object: {
      pageIndex: movePage
        ? page.pageElementsCount === 1 ? Math.max(page.pageIndex - 1, 0) : page.pageIndex
        : page.pageIndex,
      pageSize: page.pageSize,
      keyword: page.keyword,
      include: page.include,
      onlyManual: page.onlyManual,
      onlyPostponed: page.onlyPostponed,
      postponementReasons: page.postponementReasons,
      institutionIds: page.institutionIds,
      sourceIds: page.sourceIds
    }
  }));
}

export function* batchSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterCommissionsBatches);
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getCommissionsBatch);
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createCommissionsBatch);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateCommissionsBatch);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deleteCommissionsBatch);
  yield takeLatest(createActionType(actionType.CHANGE_STEP, apiOperation.REQUEST), changeCommissionsBatchStep);

  yield takeLatest(createActionType(actionType.CREATE_UNIT, apiOperation.REQUEST), createCommissionsUnit);
  yield takeLatest(createActionType(actionType.UPDATE_UNIT, apiOperation.REQUEST), updateCommissionsUnit);
  yield takeLatest(createActionType(actionType.DELETE_UNIT, apiOperation.REQUEST), deleteCommissionsUnit);

  yield takeLatest(createActionType(actionType.UPLOAD_ATTACHMENT, apiOperation.REQUEST), uploadCommissionsBatchAttachment);
  yield takeLatest(createActionType(actionType.REPLACE_ATTACHMENT, apiOperation.REQUEST), replaceCommissionsBatchAttachment);
  yield takeLatest(createActionType(actionType.DOWNLOAD_ATTACHMENT, apiOperation.REQUEST), downloadCommissionsBatchAttachment);
  yield takeLatest(createActionType(actionType.DELETE_ATTACHMENT, apiOperation.REQUEST), deleteCommissionsBatchAttachment);

  yield takeLatest(createActionType(actionType.FILTER_COMMISSIONS, apiOperation.REQUEST), filterCommissions);
  yield takeLatest(createActionType(actionType.CREATE_COMMISSION, apiOperation.REQUEST), createCommission);
  yield takeLatest(createActionType(actionType.UPDATE_COMMISSION, apiOperation.REQUEST), updateCommission);
  yield takeLatest(createActionType(actionType.POSTPONE_COMMISSION, apiOperation.REQUEST), postponeCommission);
  yield takeLatest(createActionType(actionType.TRY_TO_INCLUDE_COMMISSION, apiOperation.REQUEST), tryToIncludeCommission);
  yield takeLatest(createActionType(actionType.UPDATE_CALCULATED_COMMISSIONS, apiOperation.REQUEST), updateCalculatedCommissions);
  yield takeLatest(createActionType(actionType.DELETE_COMMISSION, apiOperation.REQUEST), deleteCommission);
}
