import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import { ActionProps, RootState, SearchPageResult, UUID } from "../../../../common/types";
import { InstitutionList } from "../types";
import { deleteStateInstitutionsPageAction, filterInstitutionsActions, selectInstitutionsCurrentPage } from "../ducks";
import { selectRouterLocationSearchParam } from "../../../ducks";
import { appendSearchParamsToCurrentPathname } from "../../../../common/utils/utils";
import { serializeParams } from "../../../../common/utils/apiUtils";

import InstitutionListSearchHeaderView from "../components/views/InstitutionListSearchHeaderView";
import InstitutionListTableView from "../components/views/InstitutionListTableView";

interface StateProps {
  institutionsCurrentPage: SearchPageResult<InstitutionList>;
  initialUrlSearchKeyword: string;
}

interface ActionsMap {
  filterInstitutions: typeof filterInstitutionsActions.request;
  deleteStateInstitutionsPage: typeof deleteStateInstitutionsPageAction;
  pushNavigation: typeof push;
  replaceNavigation: typeof replace;
}

const PAGE_SIZE = 20;

const InstitutionListContainer = (props: StateProps & ActionProps<ActionsMap>) => {

  useEffect(() => {
    props.actions.filterInstitutions({
      keyword: props.initialUrlSearchKeyword,
      pageIndex: 0,
      pageSize: PAGE_SIZE
    });
    return () => props.actions.deleteStateInstitutionsPage();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleTablePageChange = (pageNumber: number): void => {
    props.actions.filterInstitutions({
      keyword: props.institutionsCurrentPage.keyword,
      pageIndex: pageNumber - 1,
      pageSize: PAGE_SIZE
    })
  };

  const handleSearchSubmit = (keyword: string): void => {
    props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(
      serializeParams({ keyword: keyword !== "" ? keyword : null })));
    props.actions.filterInstitutions({ keyword, pageIndex: 0, pageSize: PAGE_SIZE });
  };

  const handleEditClick = (institutionId: UUID): void => {
    props.actions.pushNavigation("/admin/institutions/" + institutionId);
  };

  const handleCreateClick = (): void => {
    props.actions.pushNavigation("/admin/institutions/new");
  };

  return (
    <>
      <InstitutionListSearchHeaderView
        initialSearchKeyword={props.initialUrlSearchKeyword}
        onSearchSubmit={handleSearchSubmit}
        onCreateClick={handleCreateClick} />

      <InstitutionListTableView
        institutionsPage={props.institutionsCurrentPage}
        onEditClick={handleEditClick}
        onPageChange={handleTablePageChange} />
    </>
  );
}

const mapStateToProps = (state: RootState): StateProps => ({
  institutionsCurrentPage: selectInstitutionsCurrentPage(state),
  initialUrlSearchKeyword: selectRouterLocationSearchParam(state, "keyword")
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterInstitutions: filterInstitutionsActions.request,
    deleteStateInstitutionsPage: deleteStateInstitutionsPageAction,
    pushNavigation: push,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(InstitutionListContainer);
