import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  Contract,
  ContractAttachment,
  ContractFilterPageRequest,
  ContractFilterPageResult,
  ContractList,
  ContractReducerState,
  CreateUpdateContract,
  CreateUpdateInsuranceContract,
  CreateUpdateLoanContract
} from "./types";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  TwoLevelEntityIdRequest
} from "../../common/types";
import { changeLocationKeyAction } from "../ducks";
import api from "./api";
import { openBlobFile, removeFromArray } from "../../common/utils/utils";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../common/utils/reduxUtils";
import { initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import t from "../../app/i18n";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'contract/FILTER',
  GET = 'contract/GET',
  CREATE = 'contract/CREATE',
  VERIFY = 'contract/VERIFY',
  UPDATE = 'contract/UPDATE',
  DELETE = 'contract/DELETE',
  DELETE_STATE_LIST = 'contract/DELETE_STATE_LIST',
  DELETE_STATE_DETAIL = 'contract/DELETE_STATE_DETAIL',
  UPLOAD_ATTACHMENTS = 'contract-attachment/UPLOAD',
  DOWNLOAD_ATTACHMENT = 'contract-attachment/DOWNLOAD',
  DELETE_ATTACHMENT = 'contract-attachment/DELETE'
}

/**
 * ACTIONS
 */
export const filterContractsActions = createApiActionCreators<ContractFilterPageRequest, ContractFilterPageResult<ContractList>>(actionType.FILTER);
export const getContractActions = createApiActionCreators<EntityIdRequest, Contract>(actionType.GET);
export const createContractActions = createApiActionCreators<CreateUpdateInsuranceContract | CreateUpdateLoanContract, Contract>(actionType.CREATE);
export const verifyContractActions = createApiActionCreators<EntityIdRequest, Contract>(actionType.VERIFY);
export const updateContractActions = createApiActionCreators<EntityObjectRequest<CreateUpdateInsuranceContract | CreateUpdateLoanContract>, Contract>(actionType.UPDATE);
export const deleteContractActions = createApiActionCreators<EntityIdRequest, void>(actionType.DELETE);

export const uploadContractAttachmentsActions = createApiActionCreators<EntityObjectRequest<FormData>, Contract>(actionType.UPLOAD_ATTACHMENTS);
export const downloadContractAttachmentActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.DOWNLOAD_ATTACHMENT);
export const deleteContractAttachmentActions = createApiActionCreators<TwoLevelEntityIdRequest, Contract>(actionType.DELETE_ATTACHMENT);

export const deleteStateContractPageAction = createActionCreator<void>(actionType.DELETE_STATE_LIST);
export const deleteStateContractDetailAction = createActionCreator<void>(actionType.DELETE_STATE_DETAIL);

/**
 * REDUCERS
 */
const initialState: ContractReducerState = {
  currentPage: {
    ...initSearchPageResult<ContractList>(),
    report: null,
    orderBy: [],
    orderDirections: [],
    institutionIds: [],
    productIds: [],
    personIds: [],
    statuses: []
  },
  contractDetail: null,
};

const currentPageReducer = createReducer<ContractFilterPageResult<ContractList>>(initialState.currentPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentPage
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.currentPage
  },
  [actionType.DELETE_STATE_LIST]: () => initialState.currentPage
});

const contractDetailReducer = createReducer<Contract>(initialState.contractDetail, {
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.contractDetail
  },
  [actionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.VERIFY]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.contractDetail
  },
  [actionType.UPLOAD_ATTACHMENTS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_ATTACHMENT]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_DETAIL]: () => initialState.contractDetail
});

export default combineReducers({
  currentPage: currentPageReducer,
  contractDetail: contractDetailReducer
});

/**
 * SELECTORS
 */
const selectContract = (state: RootState): ContractReducerState => state.contract;

export const selectCurrentContractsPage = (state: RootState): ContractFilterPageResult<ContractList> => selectContract(state).currentPage;
export const selectContractDetail = (state: RootState): Contract => selectContract(state).contractDetail;

/**
 * SAGAS
 */
function* filterContracts({ payload }: ActionCreator<ContractFilterPageRequest>) {
  try {
    const response: AxiosResponse<ContractFilterPageResult<ContractList>> = yield call(api.filterContracts, payload);
    yield put(filterContractsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterContractsActions.failure(error));
  }
}

function* getContractDetail({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.getContract, payload);
    yield put(getContractActions.success(response.data));
  }
  catch ( error ) {
    yield put(getContractActions.failure(error));
  }
}

function* createContract({ payload }: ActionCreator<CreateUpdateContract>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.createContract, payload);
    yield put(createContractActions.success(response.data));
    yield put(replace("/contracts/" + response.data.id));
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createContractActions.failure(error));
  }
}

function* verifyContract({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.verifyContract, payload);
    yield put(verifyContractActions.success(response.data));
    messageUtils.successNotification(t("common.operationSuccess"), t("contract.helpers.verifyContractSuccess"));
  }
  catch ( error ) {
    yield put(verifyContractActions.failure(error));
  }
}

function* updateContract({ payload }: ActionCreator<EntityObjectRequest<CreateUpdateContract>>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.updateContract, payload);
    yield put(updateContractActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateContractActions.failure(error));
  }
}

function* deleteContract({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteContract, payload);
    yield put(deleteContractActions.success());
    yield put(replace("/contracts"));
  }
  catch ( error ) {
    yield put(deleteContractActions.failure(error));
  }
}

function* uploadContractAttachments({ payload }: ActionCreator<EntityObjectRequest<FormData>>) {
  try {
    const response: AxiosResponse<ContractAttachment[]> = yield call(api.uploadContractAttachments, payload);
    const contract: Contract = yield select(selectContractDetail);
    yield put(uploadContractAttachmentsActions.success({ ...contract, attachments: response.data }));
  }
  catch ( error ) {
    yield put(uploadContractAttachmentsActions.failure(error));
  }
}

function* downloadContractAttachment({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadContractAttachment, payload);
    openBlobFile(response);
    yield put(downloadContractAttachmentActions.success());
  }
  catch ( error ) {
    yield put(downloadContractAttachmentActions.failure(error));
  }
}

function* deleteContractAttachment({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.deleteContractAttachment, payload);
    const contract: Contract = yield select(selectContractDetail);
    yield put(deleteContractAttachmentActions.success({
      ...contract,
      attachments: removeFromArray(contract.attachments, item => item.id === payload.id2)
    }));
  }
  catch ( error ) {
    yield put(deleteContractAttachmentActions.failure(error));
  }
}

export function* contractSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterContracts);
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getContractDetail);
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createContract);
  yield takeLatest(createActionType(actionType.VERIFY, apiOperation.REQUEST), verifyContract);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateContract);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deleteContract);

  yield takeLatest(createActionType(actionType.UPLOAD_ATTACHMENTS, apiOperation.REQUEST), uploadContractAttachments);
  yield takeLatest(createActionType(actionType.DOWNLOAD_ATTACHMENT, apiOperation.REQUEST), downloadContractAttachment);
  yield takeLatest(createActionType(actionType.DELETE_ATTACHMENT, apiOperation.REQUEST), deleteContractAttachment);
}
