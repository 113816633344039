import React from "react";
import { Route, Switch } from "react-router";
import { regexPatterns } from "../../common/utils/validationUtils";
import AnonymousUserWrapper from "../../common/security/authentication/AnonymousUserWrapper";
import AuthenticatedUserWrapper from "../../common/security/authentication/AuthenticatedUserWrapper";

import RequestPasswordResetContainer from "./containers/RequestPasswordResetContainer";
import ResetPasswordContainer from "./containers/ResetPasswordContainer";
import ConfirmAccountContainer from "./containers/ConfirmAccountContainer";
import NotFound from "../../common/pages/NotFound/NotFound";

const wrappedRequestPasswordResetContainer = AnonymousUserWrapper(RequestPasswordResetContainer);
const wrappedResetPasswordContainer = AnonymousUserWrapper(ResetPasswordContainer);
const wrappedConfirmAccountContainer = AnonymousUserWrapper(ConfirmAccountContainer);

const wrappedNotFoundComponent = AuthenticatedUserWrapper(NotFound);

export default () => (
  <Switch>
    <Route exact path="/accounts/request-password-reset" component={wrappedRequestPasswordResetContainer} />

    <Route exact path={`/accounts/:accountId(${regexPatterns.uuidRegex.source})/password-reset`}
           component={wrappedResetPasswordContainer} />

    <Route exact path={`/accounts/:accountId(${regexPatterns.uuidRegex.source})/confirm`}
           component={wrappedConfirmAccountContainer} />

    <Route component={wrappedNotFoundComponent} />
  </Switch>
);
