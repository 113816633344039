import React from "react";
import moment from "moment";
import { Card, Collapse, Tag } from "antd";
import {
  InsuranceContract,
  LifeInsurance,
  RealtyInsurance,
  TravelInsurance,
  VehicleInsurance
} from "../../../../../types";
import { InsuranceType } from "../../../../../enums";
import { parseVehicleModelName } from "../../../../../utils";
import { isDefinedValue } from "../../../../../../../common/utils/utils";
import { toMoment } from "../../../../../../../common/utils/formUtils";
import { tInterval } from "../../../../../../../common/utils/translationUtils";
import { formatLifeInsuranceTariff, formatPlaceOfInsurance } from "../../../../../../../common/utils/formatUtils";
import t from "../../../../../../../app/i18n";

import GenericInsuranceView from "../views/GenericInsuranceView";
import VehicleInsuranceView from "../views/VehicleInsuranceView";
import RealtyInsuranceView from "../views/RealtyInsuranceView";
import TravelInsuranceView from "../views/TravelInsuranceView";
import LifeInsuranceView from "../views/LifeInsuranceView";

export interface Props {
  contract: InsuranceContract;
}

const InsuranceContractInsurancesSection = ({ contract }: Props) => {
  const multipleInsurances = contract.insurances.length > 1;
  let authorizedClientsCount = isDefinedValue(contract.authorizedClient1Index)
    ? isDefinedValue(contract.authorizedClient2Index) ? 2 : 1
    : 0;
  const multipleClients = multipleInsurances && contract.clients.length - authorizedClientsCount > 1;

  return (
    <Card size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.insurances")}>
      <Collapse>
        {contract.insurances.map((insurance, index) => {
          let insuranceHeader;
          let insuranceView;
          switch ( insurance.type ) {
            case InsuranceType.MTPL:
            case InsuranceType.CRASH:
            case InsuranceType.GAP:
            case InsuranceType.PAS:
              const { vehicle, licensePlate } = (insurance as VehicleInsurance);
              insuranceHeader = <span>
                <b>{insurance.product.name}</b> |&nbsp;
                {[parseVehicleModelName(vehicle), licensePlate, vehicle.vin].join(" | ")}
              </span>
              insuranceView =
                <VehicleInsuranceView contract={contract} index={index} multipleInsurances={multipleInsurances}
                                      multipleClients={multipleClients} />;
              break;
            case InsuranceType.REALTY:
              const realtyData = (insurance as RealtyInsurance).insuranceData;
              const realtyHouseholdLabel = realtyData.realty && realtyData.household
                ? t("contract.helpers.realtyAndHousehold")
                : realtyData.realty
                  ? t("contract.attrs.insurances.insuranceData.realty._label") : t("contract.attrs.insurances.insuranceData.household._label");
              insuranceHeader = <span>
                <b>{insurance.product.name}</b> |&nbsp;
                {[t("calc.realty.enums.buildingType." + realtyData.buildingType),
                  formatPlaceOfInsurance(realtyData.placeOfInsurance), realtyHouseholdLabel].join(" | ")}
              </span>;
              insuranceView =
                <RealtyInsuranceView contract={contract} index={index} multipleInsurances={multipleInsurances}
                                     multipleClients={multipleClients} />;
              break;
            case InsuranceType.TRAVEL:
              const travelData = (insurance as TravelInsurance).insuranceData;
              insuranceHeader = <span>
                <b>{insurance.product.name}</b> |&nbsp;
                {[
                  t("calc.travel.enums.territorialValidity." + travelData.territorialValidity),
                  tInterval("contract.helpers.insuredClientsCount_interval", travelData.insuredClients.length)
                ].join(" | ")}
              </span>;
              insuranceView =
                <TravelInsuranceView contract={contract} index={index} multipleInsurances={multipleInsurances} />;
              break;
            case InsuranceType.LIFE:
              insuranceHeader = <span>
                <b>{insurance.product.name}</b> |&nbsp;
                {formatLifeInsuranceTariff((insurance as LifeInsurance).tariff)}
              </span>
              insuranceView =
                <LifeInsuranceView contract={contract} index={index} multipleInsurances={multipleInsurances}
                                   multipleClients={multipleClients} />;
              break;
            case InsuranceType.GENERIC:
              insuranceHeader = <b>{insurance.product.name}</b>;
              insuranceView =
                <GenericInsuranceView contract={contract} index={index} multipleInsurances={multipleInsurances}
                                      multipleClients={multipleClients} />;
              break;
          }

          return (
            <Collapse.Panel
              key={insurance.id}
              header={insuranceHeader}
              extra={
                <span onClick={event => event.stopPropagation()}>
                  {multipleInsurances && (
                    <>
                      {toMoment(insurance.contractEntryDate).isAfter(moment(), "day")
                      && <Tag color="green">{t("contract.helpers.insuranceBeforeEntry")}</Tag>}
                      {insurance.contractWithdrawalDate && toMoment(insurance.contractWithdrawalDate).isBefore(moment(), "day")
                      && <Tag color="geekblue">{t("contract.helpers.insuranceAfterWithdrawal")}</Tag>}
                    </>
                  )}
                  {insurance.product.deactivated
                  && <Tag color="red">{t("contract.helpers.insuranceInactiveProduct")}</Tag>}
                </span>
              }>
              {insuranceView}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </Card>
  );
}

export default React.memo(InsuranceContractInsurancesSection);
