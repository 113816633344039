import React from "react";
import { Button, Col, Modal, PageHeader, Popconfirm, Row, Statistic, Tag } from "antd";
import { DeleteOutlined, EditOutlined, FileDoneOutlined } from "@ant-design/icons";
import { LoanContract } from "../../../../../types";
import { ClientFormType } from "../../../../../../client/enums";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import { deleteContractActions, verifyContractActions } from "../../../../../ducks";
import { isDefinedValue } from "../../../../../../../common/utils/utils";
import { threeSpacedStringNormalizeFunction } from "../../../../../../../common/utils/formUtils";
import { formatInstitutionName, formatProductName } from "../../../../../../../common/utils/formatUtils";
import t from "../../../../../../../app/i18n";

import ClientsListDrawerView from "../../../../../../client/components/drawers/ClientsListDrawerView";
import ItemCreatedUpdatedInfoView from "../../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import CopyToClipboardView from "../../../../../../../common/components/views/CopyToClipboardView";
import ComponentWithPermission from "../../../../../../../common/security/authorization/ComponentWithPermission";
import ContractStatusTag from "../../../../ContractStatusTag";
import PopconfirmDeleteIcon from "../../../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  contract: LoanContract;
  onVerify: typeof verifyContractActions.request;
  onDelete: typeof deleteContractActions.request;
  onUpdateClick(): void;
}

const LoanContractHeaderSection = ({ contract, ...props }: Props) => {

  const handleVerifyContractClick = (): void => {
    Modal.confirm({
      title: t("contract.helpers.verifyContractTitle"),
      content: t("contract.helpers.verifyContractDesc"),
      okText: t("common.confirm"),
      cancelText: t("common.back"),
      onOk: () => {
        props.onVerify({ id: contract.id });
      }
    });
  };

  return (
    <PageHeader
      className="page-header-box"
      title={t("contract.sections.mainData")}
      tags={[
        <ContractStatusTag key={1} status={contract.status} />,
        !contract.verified ? <Tag key={2} color="red">{t("contract.helpers.contractNotVerified")}</Tag> : null
      ]}
      extra={
        <>
          <ComponentWithPermission permissions={[Permission.LOAN_UPDATE]}>
            <Button type="primary" icon={<EditOutlined />} onClick={props.onUpdateClick}>
              {t("contract.actions.updateContract")}
            </Button>
          </ComponentWithPermission>

          {!contract.verified && (
            <ComponentWithPermission permissions={[Permission.LOAN_VERIFY]}>
              <Button className="blue-button" icon={<FileDoneOutlined />} onClick={handleVerifyContractClick}>
                {t("contract.actions.verifyContract")}
              </Button>
            </ComponentWithPermission>
          )}

          <ComponentWithPermission permissions={[Permission.LOAN_DELETE]}>
            <Popconfirm
              title={t("contract.helpers.deleteContractConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={() => props.onDelete({ id: contract.id })}>
              <Button danger icon={<DeleteOutlined />}>{t("contract.actions.deleteContract")}</Button>
            </Popconfirm>
          </ComponentWithPermission>
        </>
      }>

      <ItemCreatedUpdatedInfoView item={contract} style={{ marginTop: "-15px" }} className="margin-bottom-small" />

      <Row>
        <Statistic
          className="statistic-small"
          title={t("contract.attrs.contractNumber")}
          value={contract.contractNumber}
          formatter={value => <CopyToClipboardView content={threeSpacedStringNormalizeFunction(value as string)}
                                                   contentToCopy={value as string} />} />

        <Statistic
          className="statistic-small margin-left-medium"
          title={t("contract.attrs.loanNumber")}
          value={contract.loanNumber}
          formatter={value => <CopyToClipboardView content={threeSpacedStringNormalizeFunction(value as string)}
                                                   contentToCopy={value as string} />} />

        <Statistic
          className="statistic-small margin-left-medium"
          title={t("contract.attrs.bankInstitutionId")}
          value={formatInstitutionName(contract.bankInstitution)} />

        <Statistic
          className="statistic-small margin-left-medium"
          title={t("contract.attrs.product")}
          value={formatProductName(contract.product)} />
      </Row>

      <Row>
        <Col span={24}>
          <h3 className="margin-top-medium">{t("contract.sections.clients")}</h3>
          <ClientsListDrawerView clientsData={[
            { clientFormTypes: [ClientFormType.DEBTOR], client: contract.clients[contract.debtorIndex] },
            ...(isDefinedValue(contract.coDebtorIndex)
              ? { clientFormTypes: [ClientFormType.CO_DEBTOR], client: contract.clients[contract.coDebtorIndex] }
              : [])
          ]} />
        </Col>
      </Row>
    </PageHeader>
  )
};

export default React.memo(LoanContractHeaderSection);
