import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Col, Modal, Row } from "antd";
import { CloseOutlined, CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { CalcRecord } from "../../types";
import messageUtils from "../../../../../common/utils/messageUtils";
import { saveTextFile } from "../../../../../common/utils/utils";
import { formatLocaleDateTime, formatPersonAccountName } from "../../../../../common/utils/formatUtils";
import t from "../../../../../app/i18n";

export interface Props {
  visible: boolean;
  record: CalcRecord;
  onCancelClick(): void;
  onAfterClose(): void;
}

const CalcRecordRequestResponseLogsModal = ({ visible, record, onCancelClick, onAfterClose }: Props) => {

  const handleLogFileSave = (content: string, type: "request" | "response"): void => {
    saveTextFile(content, createLogsTitle() + " _ " + t("common." + type));
  };

  const handleCopyInfoMessageDisplay = (): void => {
    messageUtils.infoMessage(t("common.clipboardCopied"));
  };

  const createLogsTitle = (): string => {
    return t("calc.records.titles.logs") + ` - ${formatPersonAccountName(record.account)} / ` + formatLocaleDateTime(record.createdAt);
  };

  if ( record && record.request && record.response ) {
    const requestString = JSON.stringify(record.request, null, 2);
    const responseString = JSON.stringify(record.response, null, 2);

    return <Modal
      visible={visible}
      title={createLogsTitle()}
      width={1000}
      centered
      onCancel={onCancelClick}
      afterClose={onAfterClose}
      footer={<Button onClick={onCancelClick} icon={<CloseOutlined />}>{t("common.close")}</Button>}>

      <Row gutter={16}>

        <Col span={12}>
          <h3>{t("common.request")}</h3>

          <pre className="modal-code-block">
            {requestString}
          </pre>

          <div className="center-align margin-top-small">
            <Button icon={<DownloadOutlined />} className="blue-button margin-right-medium"
                    onClick={() => handleLogFileSave(requestString, "request")}>
              {t("common.download")}
            </Button>

            <CopyToClipboard text={requestString} onCopy={handleCopyInfoMessageDisplay}>
              <Button icon={<CopyOutlined />} className="blue-button">{t("common.copy")}</Button>
            </CopyToClipboard>
          </div>
        </Col>

        <Col span={12}>
          <h3>{t("common.response")}</h3>

          <pre className="modal-code-block">
            {responseString}
          </pre>

          <div className="center-align margin-top-small">
            <Button icon={<DownloadOutlined />} className="blue-button margin-right-medium"
                    onClick={() => handleLogFileSave(responseString, "response")}>
              {t("common.download")}
            </Button>

            <CopyToClipboard text={responseString} onCopy={handleCopyInfoMessageDisplay}>
              <Button icon={<CopyOutlined />} className="blue-button">{t("common.copy")}</Button>
            </CopyToClipboard>
          </div>
        </Col>

      </Row>

    </Modal>
  }

  return null;
};

export default CalcRecordRequestResponseLogsModal;
