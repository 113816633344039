import React from "react";
import { useSelector } from "react-redux";
import { VehicleBrandWithModels } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { selectVehicleBrandsEnumerations, VEHICLE_OTHER_MAPPING_NAME } from "../../ducks";
import t from "../../../../app/i18n";

export interface Props {
  brandId: UUID;
  modelId: UUID;
  otherModelName?: string;
}

const VehicleBrandEnumerationsName = ({ brandId, modelId, otherModelName }: Props) => {
  const vehicleBrandsEnumerations = useSelector<RootState, VehicleBrandWithModels[]>(selectVehicleBrandsEnumerations);

  const model = vehicleBrandsEnumerations.find(brand => brand.id === brandId)?.models.find(model => model.id === modelId);
  return model
    ? model.name === VEHICLE_OTHER_MAPPING_NAME && otherModelName ? <>{`${model.name} (${otherModelName})`}</> : <>{model.name}</>
    : <>{t("calc.vehicles.helpers.unknownModel")}</>
};

export default VehicleBrandEnumerationsName;
