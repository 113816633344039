import React from "react";
import { Route, Switch } from "react-router-dom";

import MyIdentityContainer from "./containers/MyIdentityContainer";
import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <Route exact path="/me" component={MyIdentityContainer} />

    <Route component={NotFound} />
  </Switch>
)
