import "./html-view.less";
import React from "react";
import ReactQuill from "react-quill";

export interface Props {
  value: string;
}

const HtmlView = ({ value }: Props) => <ReactQuill className="html-view" theme="bubble" readOnly value={value} />

export default HtmlView;
