import React from "react";
import { SelfEmployedClient } from "../../../types";
import { formatIban, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { createLinkHref } from "../../../../../common/utils/utils";
import t from "../../../../../app/i18n";

import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";

export interface Props {
  client: SelfEmployedClient;
}

const SelfEmployedClientDataView = ({ client }: Props) => (
  <table className="data-table-view">
    <tbody>
    <tr>
      <td>{t("client.attrs.companyNameFOP")}:</td>
      <td colSpan={3}>{client.aggregatedName}</td>
    </tr>
    <tr>
      <td>{t("client.attrs.aggregatedNaturalName")}:</td>
      <td colSpan={3}>{client.aggregatedNaturalName}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("client.attrs.personalIdentificationNumberLong")}:</td>
      <td><CopyToClipboardView content={client.personalIdentificationNumber} /></td>
      <td>{t("client.attrs.birthDate")}:</td>
      <td>{formatLocaleDate(client.birthDate)}</td>
    </tr>
    <tr>
      <td>{t("client.attrs.identityCardNumber")}:</td>
      <td><CopyToClipboardView content={client.identityCardNumber} /></td>
      <td>{t("client.attrs.nationality")}:</td>
      <td>{client.nationality}</td>
    </tr>
    <tr>
      <td>{t("client.attrs.bankAccountNumber")}:</td>
      <td><CopyToClipboardView content={formatIban(client.bankAccountNumber)} /></td>
      <td>{t("client.attrs.webPage")}:</td>
      <td>{client.webPage
        ? <a href={createLinkHref(client.webPage)} target="_blank" rel="noopener noreferrer">{client.webPage}</a>
        : null}
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("client.attrs.companyRegistrationNumber")}:</td>
      <td><CopyToClipboardView content={client.companyRegistrationNumber} /></td>
      <td>{t("client.attrs.districtOffice")}:</td>
      <td>{client.districtOffice}</td>
    </tr>
    <tr>
      <td>{t("client.attrs.taxId")}:</td>
      <td><CopyToClipboardView content={client.taxId} /></td>
      <td>{t("client.attrs.tradeRegisterNumber")}:</td>
      <td>{client.tradeRegisterNumber}</td>
    </tr>
    <tr>
      <td>{t("client.attrs.vatId")}:</td>
      <td><CopyToClipboardView content={client.vatId} /></td>
      <td colSpan={2} />
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("client.attrs.note")}:</td>
      <td colSpan={3}>{client.note}</td>
    </tr>
    </tbody>
  </table>
);

export default SelfEmployedClientDataView;
