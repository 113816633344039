import React from "react";
import groupBy from "lodash/groupBy";
import { Col, Divider, Row } from "antd";
import { VehicleCalcResultData, VehicleCalcResults, VehicleCalcType } from "../../types";
import { CalcResult } from "../../../types";
import { CalcType } from "../../../../enums";
import { InstitutionEnum } from "../../../../../admin/institution/enums";
import { filterApplicableResults, filterApplicableSuccessResults } from "../../../utils";

import VehicleCalcResultsSection from "./VehicleCalcResultsSection";

export interface Props {
  calcType: VehicleCalcType;
  calcResults: VehicleCalcResults;
  selectedResult?: CalcResult<VehicleCalcResultData>;
  threeColumnsLayout?: boolean;
  onGenerateContractClick(result: CalcResult<VehicleCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<VehicleCalcResultData>): void;
}

const VehicleCalcResultsColumn = ({ calcType, calcResults, selectedResult, threeColumnsLayout, ...props }: Props) => {

  const resultsRows = ((calcType === CalcType.MTPL ? calcResults.mtpl : calcResults.crash) as CalcResult<VehicleCalcResultData>[][])
    .filter(resultsRow => filterApplicableResults(resultsRow).length > 0)
    .map(resultsRow => {
      if ( resultsRow.length > 0 && resultsRow[0].calcType === CalcType.CRASH &&
        resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ ) {
        const successResults = Object.values(groupBy(
          filterApplicableSuccessResults(resultsRow), (result => result.coverage.substr(0, result.coverage.indexOf("_")))));
        const applicableResults = Object.values(groupBy(
          filterApplicableResults(resultsRow), (result => result.coverage.substr(0, result.coverage.indexOf("_")))));
        return applicableResults.map((applicableRow, index) => ({
          ...(successResults.length > 0 && successResults[index].length > 0
            ? successResults[index].find(successRow => applicableRow[0].coverage === successRow.coverage)
            : applicableRow[0]),
          coverage: applicableRow[0].coverage.substr(0, applicableRow[0].coverage.indexOf("_"))
        }));
      }
      return resultsRow;
    });

  let gapResultsRows = [];
  let pasResultsRows = [];
  if ( calcType === CalcType.CRASH ) {
    gapResultsRows = calcResults.gap.filter(resultsRow => filterApplicableResults(resultsRow).length > 0);
    pasResultsRows = calcResults.pas.filter(resultsRow => filterApplicableResults(resultsRow).length > 0)
  }

  return (
    <>
      <VehicleCalcResultsSection
        calcType={calcType}
        resultsRows={resultsRows}
        allResults={calcType === CalcType.CRASH ? calcResults.crash : null}
        selectedResult={selectedResult}
        threeColumnsLayout={threeColumnsLayout}
        onGenerateContractClick={props.onGenerateContractClick}
        onShowErrorsClick={props.onShowErrorsClick} />

      {gapResultsRows.length > 0 && (
        <>
          <Row>
            <Col span={24}><Divider /></Col>
          </Row>
          <VehicleCalcResultsSection
            calcType={CalcType.GAP}
            resultsRows={gapResultsRows}
            selectedResult={selectedResult}
            onGenerateContractClick={props.onGenerateContractClick}
            onShowErrorsClick={props.onShowErrorsClick} />
        </>
      )}

      {pasResultsRows.length > 0 && (
        <>
          <Row>
            <Col span={24}><Divider /></Col>
          </Row>
          <VehicleCalcResultsSection
            calcType={CalcType.PAS}
            resultsRows={pasResultsRows}
            selectedResult={selectedResult}
            onGenerateContractClick={props.onGenerateContractClick}
            onShowErrorsClick={props.onShowErrorsClick} />
        </>
      )}
    </>
  )
};

export default VehicleCalcResultsColumn;
