import React from "react";
import groupBy from "lodash/groupBy";
import { Alert, Popover } from "antd";
import {
  CrashCalcResultData,
  GapCalcResultData,
  MtplCalcResultData,
  PasCalcResultData,
  VehicleCalcResultData
} from "../../types";
import { CalcResult } from "../../../types";
import { CalcType } from "../../../../enums";
import { InsuranceContractPaymentFrequency, InsuranceCoverageType } from "../../../../../contract/enums";
import { InstitutionEnum } from "../../../../../admin/institution/enums";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { HttpStatus } from "../../../../../../common/constants";
import { filterApplicableSuccessResults } from "../../../utils";
import { isDefinedValue, isNotEmptyArray } from "../../../../../../common/utils/utils";
import { formatCoverageLimits, formatLocaleCurrency } from "../../../../../../common/utils/formatUtils";
import t from "../../../../../../app/i18n";

import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";

export interface Props {
  result: CalcResult<VehicleCalcResultData>;
  disabled: boolean;
  allResults?: CalcResult<VehicleCalcResultData>[][];
  onGenerateContractClick(result: CalcResult<VehicleCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<VehicleCalcResultData>): void;
}

const VehicleCalcResultsItem = ({ result, disabled, allResults, ...props }: Props) => {

  let resultDescriptionTable = null;
  let resultCoveragesDescription = null;

  if ( result.data ) {
    if ( result.calcType === CalcType.GAP ) {
      const gapOptions = groupBy((result.data as GapCalcResultData).options, o => o.duration + "-" + o.complicityReinsurance.toString());
      resultDescriptionTable = (
        <table className="prices-table-view">
          <thead>
          <tr>
            <th />
            <th>{t("contract.enums.paymentFrequency.ONCE")}</th>
            <th>{t("contract.enums.paymentFrequency.ANNUALLY")} ({t("common.total")})</th>
          </tr>
          </thead>
          <tbody>
          {Object.keys(gapOptions).map(key => gapOptions[key]).map((options, index) => {
            const oncePaymentOption = options.find(option => option.paymentFrequency === InsuranceContractPaymentFrequency.ONCE);
            const annualPaymentOption = options.find(option => option.paymentFrequency === InsuranceContractPaymentFrequency.ANNUALLY);
            return oncePaymentOption && annualPaymentOption ? (
              <tr key={index}>
                <td>
                  {oncePaymentOption.complicityReinsurance
                    ? t("calc.vehicle.helpers.gapWithComplicityReinsurance")
                    : t("calc.vehicle.helpers.gapWithoutComplicityReinsurance")}&nbsp;
                  ({t("calc.vehicle.enums.gapDuration." + oncePaymentOption.duration)})
                </td>
                <td>{formatLocaleCurrency(oncePaymentOption.premium)}</td>
                <td>{formatLocaleCurrency(annualPaymentOption.premium)}</td>
              </tr>
            ) : null;
          })}
          </tbody>
        </table>
      )
    }
    else {
      resultDescriptionTable = (
        <table className="prices-table-view">
          <tbody>
          <tr>
            <td>{t("calc.vehicle.results.annualPremium")}</td>
            <td>{formatLocaleCurrency(result.data.annualPremium)}</td>
          </tr>
          {result.calcType === CalcType.MTPL && (
            <tr>
              <td>{t("calc.vehicle.results.coverageLimit")}</td>
              <td>
                {formatCoverageLimits((result.data as MtplCalcResultData).healthCoverageLimit, (result.data as MtplCalcResultData).propertyCoverageLimit)}
              </td>
            </tr>
          )}
          {result.calcType === CalcType.CRASH && (
            <>
              <tr>
                <td>{t("calc.vehicle.results.complicity")}</td>
                <td>{(result.data as CrashCalcResultData).complicity}</td>
              </tr>
              <tr>
                <td>{t("calc.vehicle.results.vehiclePrice")}</td>
                <td>{formatLocaleCurrency((result.data as CrashCalcResultData).vehiclePrice)}</td>
              </tr>
              <tr>
                <td>{t("calc.vehicle.results.totalDamage")}</td>
                <td>{(result.data as CrashCalcResultData).totalDamage}%</td>
              </tr>
            </>
          )}
          </tbody>
        </table>
      );
    }

    if ( result.calcType === CalcType.MTPL || result.calcType === CalcType.CRASH ) {
      resultCoveragesDescription = (
        <div className="sub-header-info">
          {result.data.appliedCoverages.map((coverage, index, array) => (
            <React.Fragment key={index}>
              {t("contract.enums.insuranceCoverageType." + coverage.type)}
              {isDefinedValue(coverage.annualPremium)
                ? <span> ({formatLocaleCurrency(coverage.annualPremium)})</span> : null}
              {index < array.length - 1 && ", "}
            </React.Fragment>
          ))}
        </div>
      );
    }

    if ( result.calcType === CalcType.PAS ) {
      resultCoveragesDescription = (
        <div className="sub-header-info">
          {(result.data as PasCalcResultData).coverages.map((coverage, index, array) => (
            <React.Fragment key={index}>
              {coverage.name} <span className="no-break">({coverage.limit})</span>
              {index < array.length - 1 && <>, <br /></>}
            </React.Fragment>
          ))}
        </div>
      )
    }
  }

  const resultInfo = result.calcType === CalcType.CRASH && result.insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ
    ? <Alert type="info" showIcon message={t("calc.vehicle.helpers.crashApplicableOnlyWithMtpl")} /> : null;

  let resultAlert = null;
  if ( result.data && result.data.warnings && result.data.warnings.length > 0 ) {
    resultAlert =
      <Alert type="warning" showIcon message={<MultiLineText showDivider tokens={result.data.warnings} />} />
  }
  else if ( result.error ) {
    if ( result.error.status === HttpStatus.UNPROCESSABLE_ENTITY ) {
      const violations = result.error.violations.filter(violation => !violation.fieldPath.startsWith("clientsData.clients"));
      if ( result.error.violations.some(violation => violation.fieldPath.startsWith("clientsData.clients")) ) {
        violations.unshift({
          fieldPath: "clientsData.holder",
          errors: [t("calc.validations.clientViolationError")],
          fieldValue: null
        });
      }
      resultAlert = <Alert type="error" showIcon message={result.error.message}
                           description={<FieldViolationsView violations={violations}
                                                             rootPath="calc.vehicle.attrs" />} />
    }
    else {
      resultAlert = <Alert type="error" showIcon message={result.error.message}
                           description={<MultiLineText tokens={result.error.errors} />} />
    }
  }

  let resultAction = null;
  if ( result.data ) {
    resultAction =
      <ComponentWithPermission
        permissions={result.calcType === CalcType.MTPL
          ? [Permission.MTPL_CALCULATOR_GENERATE_CONTRACT]
          : [Permission.CRASH_CALCULATOR_GENERATE_CONTRACT]}>
        <ActionTextIcon type="check" color="green" size="large" disabled={disabled}
                        text={t("calc.vehicle.actions.createContract")}
                        onClick={() => props.onGenerateContractClick(result)} />
      </ComponentWithPermission>
  }
  else if ( props.onShowErrorsClick && result.error && result.error.status === HttpStatus.UNPROCESSABLE_ENTITY ) {
    resultAction = <ActionTextIcon type="eye" color="red" size="large" text={t("calc.vehicle.actions.showErrors")}
                                   onClick={() => props.onShowErrorsClick(result)} />
  }

  const resultContent = (
    <div>
      {result.calcType !== CalcType.CRASH && result.coverage && <h4>{result.coverage}</h4>}
      {resultDescriptionTable}
      {resultCoveragesDescription}
      {resultInfo && <div className="margin-top-tiny">{resultInfo}</div>}
      {resultAlert && <div className="margin-top-tiny">{resultAlert}</div>}
      {resultAction && <div className="margin-top-tiny">{resultAction}</div>}
    </div>
  );

  const mtplInfoResults = isNotEmptyArray(allResults) && result.calcType === CalcType.CRASH
  && result.insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ
    ? filterApplicableSuccessResults(allResults.find(resultsRow =>
      resultsRow.length > 0 && resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ) as CalcResult<CrashCalcResultData>[])
      .filter(r => r.coverage.startsWith(result.coverage))
    : null;

  return result.data && (result.calcType === CalcType.MTPL || result.calcType === CalcType.CRASH) ? (
    <Popover
      placement="left"
      title={t("calc.vehicle.results.additionalInfo")}
      content={
        <div className="result-additional-info">
          {result.calcType === CalcType.CRASH && (
            <p>
              <b>{t("calc.vehicle.enums.territorialValidity._label")}:</b>&nbsp;
              {t("calc.vehicle.enums.territorialValidity." + (result.data as CrashCalcResultData).territorialValidity)}
            </p>
          )}

          {mtplInfoResults && (
            <table className="prices-table-view bottom-border margin-bottom-large margin-top-medium">
              <thead>
              <tr>
                <th>{t("calc.vehicle.helpers.mtplAnnualPremium")}</th>
                {mtplInfoResults.map((result, index) => <th key={index}>{result.coverage}</th>)}
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{t("calc.vehicle.results.coverageLimit")}</td>
                {mtplInfoResults.map((result, index) => (
                  <td
                    key={index}>{formatCoverageLimits(result.data.healthCoverageLimit, result.data.propertyCoverageLimit)}</td>
                ))}
              </tr>
              <tr>
                <td>{t("calc.vehicle.results.annualPremium")}</td>
                {mtplInfoResults.map((result, index) => (
                  <td key={index}>
                    {formatLocaleCurrency(result.data.mtplInsuranceAnnualPremium)} <br />
                    {t("common.total")}: {formatLocaleCurrency(result.data.mtplInsuranceAnnualPremium + result.data.annualPremium)}
                  </td>
                ))}
              </tr>
              </tbody>
            </table>
          )}

          {result.data.appliedCoverages.length > 0 ? (
            <table className="prices-table-view bottom-border">
              <thead>
              <tr>
                <th>{t("contract.enums.insuranceCoverageType._label")}</th>
                <th>{t("calc.vehicle.results.insuranceAmount")}</th>
                <th>{t("calc.vehicle.results.complicity")}</th>
                {result.calcType === CalcType.MTPL && (<th>{t("calc.vehicle.enums.territorialValidity._label")}</th>)}
                <th>{t("calc.vehicle.results.reinsuranceOrInCoverage")}</th>
              </tr>
              </thead>
              <tbody>
              {result.data.appliedCoverages.map((coverage, index) => (
                <tr key={index}>
                  <td>{t("contract.enums.insuranceCoverageType." + coverage.type)}</td>
                  <td>
                    {coverage.injuryLongTermEffectsInsuranceAmount && coverage.injuryDeathInsuranceAmount ? (
                      <>
                        <span className="no-break">
                          {t("calc.vehicle.results.injuryLongTermEffectsInsuranceAmount")}: {formatLocaleCurrency(coverage.injuryLongTermEffectsInsuranceAmount)}
                        </span><br />
                        <span className="no-break">
                          {t("calc.vehicle.results.injuryDeathInsuranceAmount")}: {formatLocaleCurrency(coverage.injuryDeathInsuranceAmount)}
                        </span>
                      </>
                    ) : coverage.gapDuration
                      ? <span className="no-break">{t("calc.vehicle.enums.gapDuration." + coverage.gapDuration)}</span>
                      : coverage.replacementVehicleLimit
                        ? <span className={coverage.replacementVehicleLimit?.length < 25 ? "no-break" : ""}>{coverage.replacementVehicleLimit}</span>
                        : <>{formatLocaleCurrency(coverage.insuranceAmount)}</>}
                  </td>
                  <td>
                    {coverage.type === InsuranceCoverageType.GAP
                      ? coverage.gapComplicityReinsurance
                        ? t("calc.vehicle.helpers.gapWithComplicityReinsurance")
                        : t("calc.vehicle.helpers.gapWithoutComplicityReinsurance")
                      : coverage.complicity}
                  </td>
                  {result.calcType === CalcType.MTPL && (
                    <td>{coverage.territorialValidity ?
                      <span className="no-break">
                        {t("calc.vehicle.enums.territorialValidity." + coverage.territorialValidity)}
                      </span> : null}
                    </td>
                  )}
                  <td>{t("calc.vehicle.results." + (coverage.reinsured ? "reinsurance" : "inCoverage"))}</td>
                </tr>
              ))}
              </tbody>
            </table>
          ) : (
            <p className="sub-header-info dashed">{t("calc.vehicle.results.noExtendedCoverages")}</p>
          )}

          {result.data && result.data.additionalWarnings && result.data.additionalWarnings.length > 0 ? (
            <div className="margin-top-tiny">
              <Alert type="warning" showIcon
                     message={<MultiLineText showDivider tokens={result.data.additionalWarnings} />} />
            </div>
          ) : null}
        </div>
      }>
      {resultContent}
    </Popover>
  ) : resultContent
};

export default VehicleCalcResultsItem;
