import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { Col, Divider, Row, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { DataSourceItemObject } from "antd/lib/auto-complete";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { AuthorizedClientFormInputs, Client } from "../types";
import { FieldConstraintViolation } from "../../../common/types";
import { ClientFormStage, ClientFormType } from "../enums";
import { AuthorizedClientCompanyFunction } from "../../contract/enums";
import { contains } from "../../../common/utils/utils";
import { selectStandardProps } from "../../../common/utils/formUtils";
import validations from "../../../common/utils/validationUtils";
import { rowGutter } from "../../../common/constants";
import t from "../../../app/i18n";

import ClientAutocompleteInput from "./ClientAutocompleteInput_Deprecated";
import DeleteIcon from "../../../common/components/icons/DeleteIcon";
import AddDeleteButton from "../../../common/components/buttons/AddDeleteButton";

export interface Props {
  visible: boolean;
  clientsCount: number;
  client1Props: AuthorizedClientProps;
  client2Props: AuthorizedClientProps;
  autocompleteProps: ClientAutocompleteProps;
  onValueChange(value: AuthorizedClientFormInputs, formType: ClientFormType): void;
  onClientAdd(): void;
  onClientDelete(): void;
  onClientChange(client: Client, type: ClientFormType, omitValidation?: boolean, callback?: () => void): void;
  onClientViolationErrorsChange(violations: FieldConstraintViolation[], type: ClientFormType): void;
  onClientDuplicateErrorChange(isDuplicate: boolean, type: ClientFormType): void;
  onClientFormStageChange(stage: ClientFormStage, type: ClientFormType): void;
}

export interface AuthorizedClientProps {
  formStage: ClientFormStage;
  formValue: AuthorizedClientFormInputs;
  client: Client;
}

export interface ClientAutocompleteProps {
  form: WrappedFormUtils;
  processedClientFormType: ClientFormType;
  autocompleteInProgress: boolean;
  options: DataSourceItemObject[];
  clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  clientsDuplicateErrors: ClientFormType[];
  inputColSpan?: number;
  clientNameColSpan?: number;
  onClientActionCreateClick(formType: ClientFormType): void;
  onClientActionUpdateClick(formType: ClientFormType): void;
  onClientActionDeselectClick(formType: ClientFormType): void;
  onClientsAutocompleteFocus(formType: ClientFormType): void;
  onClientsAutocompleteSearch(value: string, formType: ClientFormType): void;
  onClientsAutocompleteSelect(value: SelectValue, formType: ClientFormType): void;
  onClientsAutocompleteChange(value: SelectValue, formType: ClientFormType): void;
}

const DEFAULT_COL_SPAN = 8;

const AuthorizedClientsSection = ({ client1Props, client2Props, autocompleteProps, ...props }: Props) => {

  const handleFunctionChange = (value: string, formType: ClientFormType): void => {
    props.onValueChange({
      identifier: formType === ClientFormType.AUTHORIZED_1 ? client1Props.formValue.identifier : client2Props.formValue.identifier,
      function: AuthorizedClientCompanyFunction[value]
    }, formType);
  };

  const handleAutocompleteChange = (value: SelectValue, formType: ClientFormType): void => {
    props.onValueChange({
      identifier: value as string,
      function: formType === ClientFormType.AUTHORIZED_1 ? client1Props.formValue.function : client2Props.formValue.function
    }, formType);

    autocompleteProps.onClientsAutocompleteChange(value, formType);
  };

  const handleClientSwitch = (): void => {
    const client1 = cloneDeep(client1Props);
    const client2 = cloneDeep(client2Props);

    props.onClientChange(client2.client, ClientFormType.AUTHORIZED_1, true);
    props.onClientChange(client1.client, ClientFormType.AUTHORIZED_2, true);

    props.onClientFormStageChange(client2.formStage, ClientFormType.AUTHORIZED_1);
    props.onClientFormStageChange(client1.formStage, ClientFormType.AUTHORIZED_2);

    props.onValueChange(client2.formValue, ClientFormType.AUTHORIZED_1);
    props.onValueChange(client1.formValue, ClientFormType.AUTHORIZED_2);

    autocompleteProps.form.setFieldsValue({
      "clientsData.authorizedClient1Identifier": client2.formValue.identifier,
      "clientsData.authorizedClient1Function": client2.formValue.function,
      "clientsData.authorizedClient2Identifier": client1.formValue.identifier,
      "clientsData.authorizedClient2Function": client1.formValue.function
    });

    const duplicateErrors = [...autocompleteProps.clientsDuplicateErrors];
    props.onClientDuplicateErrorChange(contains(duplicateErrors, ClientFormType.AUTHORIZED_2), ClientFormType.AUTHORIZED_1);
    props.onClientDuplicateErrorChange(contains(duplicateErrors, ClientFormType.AUTHORIZED_1), ClientFormType.AUTHORIZED_2);

    const violationErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...autocompleteProps.clientsViolationErrors]);
    props.onClientViolationErrorsChange(violationErrors.get(ClientFormType.AUTHORIZED_2), ClientFormType.AUTHORIZED_1);
    props.onClientViolationErrorsChange(violationErrors.get(ClientFormType.AUTHORIZED_1), ClientFormType.AUTHORIZED_2);

    props.onClientDelete();
  };

  return props.visible ? (
    <>
      <Divider>{t("client.titles.authorizedClients")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={(autocompleteProps.inputColSpan || DEFAULT_COL_SPAN) - 2}>
          <Form.Item label={t("contract.enums.authorizedClientCompanyFunction._label")}>
            {autocompleteProps.form.getFieldDecorator("clientsData.authorizedClient1Function", {
              rules: [validations.notNull],
              initialValue: client1Props.formValue.function
            })(
              <Select
                {...selectStandardProps}
                options={Object.keys(AuthorizedClientCompanyFunction).map(companyFunction => ({
                  value: companyFunction,
                  label: t("contract.enums.authorizedClientCompanyFunction." + companyFunction)
                }))}
                onChange={value => handleFunctionChange(value, ClientFormType.AUTHORIZED_1)} />
            )}
          </Form.Item>
        </Col>

        <ClientAutocompleteInput
          formKey="clientsData.authorizedClient1Identifier"
          formType={ClientFormType.AUTHORIZED_1}
          formStage={client1Props.formStage}
          formInputOptions={{
            rules: [validations.notBlank, validations.pin],
            initialValue: client1Props.formValue.identifier
          }}
          label={t("client.helpers.authorizedClient1")}
          client={client1Props.client}
          {...autocompleteProps}
          onClientsAutocompleteChange={handleAutocompleteChange} />

        <Col span={1}>
          {props.clientsCount > 1 && <DeleteIcon onClick={handleClientSwitch} />}
        </Col>
      </Row>

      {props.clientsCount > 1 && (
        <Row gutter={rowGutter}>
          <Col span={(autocompleteProps.inputColSpan || DEFAULT_COL_SPAN) - 2}>
            <Form.Item label={t("contract.enums.authorizedClientCompanyFunction._label")}>
              {autocompleteProps.form.getFieldDecorator("clientsData.authorizedClient2Function", {
                rules: [validations.notNull],
                initialValue: client2Props.formValue.function
              })(
                <Select
                  {...selectStandardProps}
                  options={Object.keys(AuthorizedClientCompanyFunction).map(companyFunction => ({
                    value: companyFunction,
                    label: t("contract.enums.authorizedClientCompanyFunction." + companyFunction)
                  }))}
                  onChange={value => handleFunctionChange(value, ClientFormType.AUTHORIZED_2)} />
              )}
            </Form.Item>
          </Col>

          <ClientAutocompleteInput
            formKey="clientsData.authorizedClient2Identifier"
            formType={ClientFormType.AUTHORIZED_2}
            formStage={client2Props.formStage}
            formInputOptions={{
              rules: [validations.notBlank, validations.pin],
              initialValue: client2Props.formValue.identifier
            }}
            label={t("client.helpers.authorizedClient2")}
            client={client2Props.client}
            {...autocompleteProps}
            onClientsAutocompleteChange={handleAutocompleteChange} />

          <Col span={1}>
            {props.clientsCount > 1 && <DeleteIcon onClick={props.onClientDelete} />}
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        <Col span={24}>
          <AddDeleteButton
            type="add"
            label={t("client.actions.authorizedClientAdd")}
            disabled={props.clientsCount > 1}
            onClick={props.onClientAdd} />
        </Col>
      </Row>
    </>
  ) : null
};

export default AuthorizedClientsSection;
