import React from "react";
import { Button, Checkbox, Col, Divider, Popconfirm, Row, Space } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CloudUploadOutlined, QuestionCircleOutlined, ReloadOutlined, SwapOutlined } from "@ant-design/icons";
import { VehicleMappingType } from "../enums";
import { rowGutter } from "../../../../common/constants";
import t from "../../../../app/i18n";

export interface Props {
  selectedMappingTypes: VehicleMappingType[];
  showDownloadButton: boolean;
  onSelectedMappingTypesChange(checkedValues: CheckboxValueType[]): void;
  onDownloadMappingsClick(): void;
  onRemapMappingsClick(): void;
  onMapMappingsUpdatesClick(): void;
}

const VehicleSettingsHeader = (props: Props) => (
  <>
    <Divider orientation="left">{t("calc.vehicles.titles.selectedInstitutions")}</Divider>

    <Row gutter={rowGutter}>
      <Col span={12} style={{ maxWidth: "550px" }}>
        <Checkbox.Group onChange={props.onSelectedMappingTypesChange} defaultValue={props.selectedMappingTypes}>
          <Row gutter={rowGutter}>
            {Object.values(VehicleMappingType).map((type, index) => (
              <Col span={6} key={index}>
                <Checkbox value={type}>{t("calc.vehicles.enums.vehicleMappingType." + type)}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Col>

      <Col span={12} className="margin-top-tiny">
        <Space>
          <Popconfirm
            title={<p style={{ maxWidth: "350px" }}>{t("calc.vehicles.helpers.remapMappingsConfirm")}</p>}
            icon={<QuestionCircleOutlined />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="primary"
            onConfirm={props.onRemapMappingsClick}>
            <Button icon={<CloudUploadOutlined />} size="small">{t("calc.vehicles.actions.remapMappings")}</Button>
          </Popconfirm>

          <Popconfirm
            title={<p style={{ maxWidth: "350px" }}>{t("calc.vehicles.helpers.mapMappingsUpdatesConfirm")}</p>}
            icon={<QuestionCircleOutlined />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="primary"
            onConfirm={props.onMapMappingsUpdatesClick}>
            <Button icon={<SwapOutlined />} size="small">{t("calc.vehicles.actions.mapMappingsUpdates")}</Button>
          </Popconfirm>
        </Space>
      </Col>
    </Row>

    {props.showDownloadButton && (
      <Row gutter={rowGutter} className="margin-top-large">
        <Col span={24}>
          <Button type="primary" icon={<ReloadOutlined />} onClick={props.onDownloadMappingsClick}>
            {t("calc.vehicles.actions.downloadMappings")}
          </Button>
        </Col>
      </Row>
    )}
  </>
);

export default VehicleSettingsHeader;
