export enum CalcResultDataType {
  MTPL = 'MTPL',
  MTPL_ALLIANZ = 'MTPL_ALLIANZ',
  MTPL_UNIQA = 'MTPL_UNIQA',
  CRASH = 'CRASH',
  CRASH_ALLIANZ = 'CRASH_ALLIANZ',
  CRASH_UNIQA = 'CRASH_UNIQA',
  GAP = 'GAP',
  PAS = 'PAS',
  REALTY = 'REALTY',
  REALTY_ALLIANZ = 'REALTY_ALLIANZ',
  REALTY_UNIQA = 'REALTY_UNIQA',
  TRAVEL = 'TRAVEL'
}

export enum CalcStep {
  VALIDATE = 'VALIDATE',
  CALCULATE = 'CALCULATE'
}

export enum GenStep {
  VALIDATE = 'VALIDATE',
  RECALCULATE = 'RECALCULATE',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  SAVE_CLIENTS_AND_CONTRACT = 'SAVE_CLIENTS_AND_CONTRACT',
  DOWNLOAD_ATTACHMENTS = 'DOWNLOAD_ATTACHMENTS',
  GENERATE_ATTACHMENTS = 'GENERATE_ATTACHMENTS',
  SAVE_ATTACHMENTS = 'SAVE_ATTACHMENTS',
  SEND_EMAIL = 'SEND_EMAIL',
  TRANSFER_DRAFT_ATTACHMENTS_AND_DELETE_DRAFT = 'TRANSFER_DRAFT_ATTACHMENTS_AND_DELETE_DRAFT',
  SAVE_CLIENTS_AND_CONTRACT_TO_OLD_CRM = 'SAVE_CLIENTS_AND_CONTRACT_TO_OLD_CRM'
}
