import React from "react";
import { Col, Divider, Form, Input, Row } from "antd";
import { tryToExtractBankNameFromIban } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import { ibanNormalizeFunction } from "../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import AddDeleteButton from "../../../../../common/components/buttons/AddDeleteButton";
import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";

const CalcSettingsPaymentDetailsPart = () => (
  <>
    <Divider orientation="left">{t("calc.settings.attrs.paymentDetails._label")}</Divider>

    <Row gutter={rowGutter}>
      <Col span={5}>
        <Form.Item
          name={["paymentDetails", "constantSymbol"]}
          label={t("calc.settings.attrs.paymentDetails.constantSymbol")}
          rules={[validations.numeric, validations.length(4)]}>
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <Form.List name={["paymentDetails", "bankAccounts"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(field => (
            <Row gutter={rowGutter} key={field.key}>
              <Col span={6}>
                <Form.Item
                  {...field}
                  name={field.name}
                  label={t("calc.settings.attrs.paymentDetails.bankAccount")}
                  rules={[validations.notBlank, validations.iban]}
                  normalize={ibanNormalizeFunction}>
                  <Input />
                </Form.Item>
              </Col>

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  prev.paymentDetails?.bankAccounts?.[field.name] !== next.paymentDetails?.bankAccounts?.[field.name]}>
                {({ getFieldValue }) => (
                  <Col span={9} className="control-item-without-label">
                    {tryToExtractBankNameFromIban(getFieldValue(["paymentDetails", "bankAccounts", field.name]))}
                  </Col>
                )}
              </Form.Item>

              {fields.length > 1 && (
                <Col span={1}>
                  <DeleteIcon onClick={() => remove(field.name)} />
                </Col>
              )}
            </Row>
          ))}

          <AddDeleteButton type="add" label={t("calc.settings.actions.addBankAccount")} onClick={add} />
        </>
      )}
    </Form.List>
  </>
);

export default CalcSettingsPaymentDetailsPart;
