import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SearchPageResult, UUID } from "../../../../../common/types";
import { InstitutionList } from "../../types";
import { paginationStandardProps } from "../../../../../common/utils/formUtils";
import t from "../../../../../app/i18n";

import InstitutionTypeTag from "../InstitutionTypeTag";
import ActionLinkIcon from "../../../../../common/components/icons/ActionLinkIcon";

export interface Props {
  institutionsPage: SearchPageResult<InstitutionList>;
  onEditClick(institutionId: UUID): void;
  onPageChange(pageNumber: number): void;
}

const InstitutionListTableView = (props: Props) => {

  const columns: ColumnsType<InstitutionList> = [
    {
      key: "name",
      title: t("admin.institution.attrs.name"),
      dataIndex: "name"
    },
    {
      key: "fullName",
      title: t("admin.institution.attrs.fullName"),
      dataIndex: "fullName"
    },
    {
      key: "type",
      title: t("admin.institution.enums.institutionType._label"),
      render: (text, record) => <InstitutionTypeTag type={record.type} />
    },
    {
      key: "actions",
      className: "right-align display-on-hover",
      render: (text, record) =>
        <ActionLinkIcon path={`/admin/institutions/${record.id}`} type="edit" color="blue" text={t("common.edit")} />
    }
  ];

  return (
    <Table<InstitutionList>
      size="small"
      className="table-row-stripped"
      rowClassName={record => record.deactivated ? "deactivated" : undefined}
      rowKey="id"
      columns={columns}
      dataSource={props.institutionsPage.pageData}
      pagination={{
        ...paginationStandardProps,
        current: props.institutionsPage.pageIndex + 1,
        pageSize: props.institutionsPage.pageSize,
        total: props.institutionsPage.totalElementsCount,
        onChange: props.onPageChange
      }}
      onRow={record => ({
        onDoubleClick: () => props.onEditClick(record.id)
      })}
    />
  )
};

export default InstitutionListTableView;
