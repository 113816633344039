import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { Col, Form, Input, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "rc-field-form/lib/interface";
import { Country } from "../enums";
import { BuildingState } from "../../../modules/calculator/calcs/realty/enums";
import { regexPatterns, validations } from "../../utils/validationUtils";
import { selectStandardProps } from "../../utils/formUtils";
import { rowGutter } from "../../constants";
import t from "../../../app/i18n";

export interface Props {
  form: FormInstance;
  rootKey: NamePath;
  label: string;
  buildingState: BuildingState;
}

const PlaceOfInsuranceForm = ({ form, rootKey, label, buildingState }: Props) => {

  const [streetFilled, setStreetFilled] = useState<boolean>();

  useEffect(() => {
    form.validateFields([[...rootKey, (streetFilled ? "descriptiveNumber" : "orientationNumber")]]);
  }, [streetFilled, form, rootKey]);

  const largeColSpan = 4;
  const middleColSpan = 3;
  const smallColSpan = 2;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={24} className="bold-text">{label}</Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={largeColSpan}>
          <Form.Item
            name={[...rootKey, "street"]}
            label={t("address.attrs.street")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.streetRegex)]}>
            <Input onChange={e => setStreetFilled(!!e.target.value)} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, rootKey)?.street !== get(next, rootKey)?.street}>
          {({ getFieldValue }) => {
            const hasStreet = !!getFieldValue([...rootKey, "street"]);
            return (
              <>
                <Col span={middleColSpan}>
                  <Form.Item
                    name={[...rootKey, "descriptiveNumber"]}
                    label={t("address.attrs.descriptiveNumber")}
                    dependencies={[[...rootKey, "parcelNumber1"]]}
                    rules={[
                      buildingState === BuildingState.FINISHED && !hasStreet ? validations.notBlank : validations.none,
                      buildingState === BuildingState.UNDER_CONSTRUCTION && !hasStreet
                        ? validations.notNullIfOtherNull(form, [...rootKey, "parcelNumber1"], t("address.attrs.parcelNumber1"))
                        : validations.none,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={middleColSpan}>
                  <Form.Item
                    name={[...rootKey, "orientationNumber"]}
                    label={t("address.attrs.orientationNumber")}
                    dependencies={[[...rootKey, "parcelNumber1"]]}
                    rules={[
                      buildingState === BuildingState.FINISHED && hasStreet ? validations.notBlank : validations.none,
                      buildingState === BuildingState.UNDER_CONSTRUCTION && hasStreet
                        ? validations.notNullIfOtherNull(form, [...rootKey, "parcelNumber1"], t("address.attrs.parcelNumber1"))
                        : validations.none,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>

                {buildingState === BuildingState.UNDER_CONSTRUCTION && (
                  <>
                    <Col span={middleColSpan}>
                      <Form.Item
                        name={[...rootKey, "parcelNumber1"]}
                        label={t("address.attrs.parcelNumber1")}
                        dependencies={[[...rootKey, "descriptiveNumber"], [...rootKey, "orientationNumber"]]}
                        rules={[
                          hasStreet
                            ? validations.notNullIfOtherNull(form, [...rootKey, "orientationNumber"], t("address.attrs.orientationNumber"))
                            : validations.notNullIfOtherNull(form, [...rootKey, "descriptiveNumber"], t("address.attrs.descriptiveNumber")),
                          validations.size(1, 64),
                          validations.pattern(regexPatterns.parcelNumberRegex)
                        ]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={middleColSpan}>
                      <Form.Item
                        name={[...rootKey, "parcelNumber2"]}
                        label={t("address.attrs.parcelNumber2")}
                        rules={[validations.size(1, 64), validations.pattern(regexPatterns.parcelNumberRegex)]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )
          }}
        </Form.Item>

        <Col span={largeColSpan}>
          <Form.Item
            name={[...rootKey, "city"]}
            label={t("address.attrs.city")}
            rules={[validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootKey, "zipCode"]}
            label={t("address.attrs.zipCode")}
            rules={[validations.notBlank, validations.length(5), validations.numeric]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootKey, "country"]}
            label={t("address.attrs.country")}
            rules={[validations.notNull]}
            initialValue={Country.SVK}>
            <Select
              {...selectStandardProps}
              options={Object.keys(Country).map(country => ({ value: country, label: country }))} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default PlaceOfInsuranceForm;
