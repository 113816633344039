import { useMemo } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  Client,
  ClientSearchProps,
  ClientSearchResult,
  ClientsValidationProps,
  ClientValidationProps,
  CreateUpdateContractClient,
  LegalClient,
  NaturalClient,
  SelfEmployedClient
} from "./types";
import { ValidationErrorResponse } from "../types";
import { RootState } from "../../common/types";
import { ClientType } from "./enums";
import {
  deleteStateClientSearchResultAction,
  searchClientActions,
  selectClientSearchResult,
  validateClientActions,
  validateClientsActions
} from "./ducks";
import {
  deleteStateValidationErrorResponseAction,
  selectIsRequestInProgress,
  selectValidationErrorResponse
} from "../ducks";
import { requests } from "./api";


export const useClientSearch = (): ClientSearchProps => {
  const result = useSelector<RootState, ClientSearchResult>(selectClientSearchResult);
  const inProgress = useSelector<RootState, boolean>(state => selectIsRequestInProgress(state, requests.SEARCH_CLIENT));

  const dispatch = useDispatch();
  const actions = useMemo(() => bindActionCreators({
    onSearch: searchClientActions.request,
    onResultDelete: deleteStateClientSearchResultAction
  }, dispatch), [dispatch]);

  return { result, inProgress, onSearch: actions.onSearch, onResultDelete: actions.onResultDelete };
}

export const useClientValidation = (): ClientValidationProps => {
  const errorResponse = useSelector<RootState, ValidationErrorResponse>(state => selectValidationErrorResponse(state, requests.VALIDATE_CLIENT));

  const dispatch = useDispatch();
  const actions = useMemo(() => bindActionCreators({
    onValidate: validateClientActions.request,
    onErrorResponseDelete: deleteStateValidationErrorResponseAction
  }, dispatch), [dispatch]);

  return { errorResponse, onValidate: actions.onValidate, onErrorResponseDelete: actions.onErrorResponseDelete };
}

export const useClientsValidation = (): ClientsValidationProps => {
  const errorResponse = useSelector<RootState, ValidationErrorResponse>(state => selectValidationErrorResponse(state, requests.VALIDATE_CLIENTS));

  const dispatch = useDispatch();
  const actions = useMemo(() => bindActionCreators({
    onValidate: validateClientsActions.request,
    onErrorResponseDelete: deleteStateValidationErrorResponseAction
  }, dispatch), [dispatch]);

  return { errorResponse, onValidate: actions.onValidate, onErrorResponseDelete: actions.onErrorResponseDelete };
}

export const createClientAggregatedName = (client: Client): string => {
  switch ( client.type ) {
    case ClientType.NATURAL:
      return createClientAggregatedNaturalName(client);
    case ClientType.SELF_EMPLOYED:
      return (client as SelfEmployedClient).companyName;
    case ClientType.LEGAL:
      return (client as LegalClient).companyName;
  }
};

export const createClientAggregatedNaturalName = (client: Client): string => {
  if ( client.type === ClientType.LEGAL ) {
    return null;
  }

  const naturalClient = client as NaturalClient;
  const nameParts = [];

  if ( naturalClient.academicDegree ) {
    nameParts.push(naturalClient.academicDegree);
  }
  if ( naturalClient.firstName ) {
    nameParts.push(naturalClient.firstName);
  }
  if ( naturalClient.lastName ) {
    nameParts.push(naturalClient.lastName);
  }
  if ( naturalClient.academicDegreeAfter ) {
    nameParts.push(naturalClient.academicDegreeAfter);
  }

  return nameParts.join(" ");
};

export const resolveClientIdentifier = (client: Client): string => {
  switch ( client.type ) {
    case ClientType.NATURAL:
      return (client as NaturalClient).personalIdentificationNumber;
    case ClientType.SELF_EMPLOYED:
      return (client as SelfEmployedClient).companyRegistrationNumber;
    case ClientType.LEGAL:
      return (client as LegalClient).companyRegistrationNumber;
  }
};

export const clientToCreateUpdateContractClient = (client: Client): CreateUpdateContractClient => {
  if ( client ) {
    const clientData = { ...client };
    delete clientData.id;
    delete clientData.aggregatedName;
    delete (clientData as SelfEmployedClient).aggregatedNaturalName;
    delete clientData.identifier;
    delete clientData.createdAt;
    delete clientData.updatedAt;
    delete clientData.createdBy;
    delete clientData.historyRecords;

    return { id: client.id, client: clientData };
  }

  return null;
};

export const createUpdateContractClientToClient = (createUpdateClient: CreateUpdateContractClient): Client => {
  if ( createUpdateClient ) {
    const client: Client = Object.assign({}, createUpdateClient.client, { id: createUpdateClient.id }) as Client;
    client.identifier = resolveClientIdentifier(client);
    client.aggregatedName = createClientAggregatedName(client);

    if ( client.type === ClientType.SELF_EMPLOYED ) {
      (client as SelfEmployedClient).aggregatedNaturalName = createClientAggregatedNaturalName(client);
    }

    return client;
  }

  return null;
};
