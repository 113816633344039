import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Store } from "redux";
import { ErrorResponse, FieldConstraintViolation, NormalizedError, RequestMethod } from "../types";
import { HttpStatus } from "../constants";
import { finishRequestAction, startRequestAction } from "../../modules/ducks";
import { logoutAction, selectIsUserAuthenticated, selectToken } from "../../modules/auth/ducks";
import { getApiBaseUrl } from "../utils/utils";
import messageUtils from "../utils/messageUtils";
import { showErrorResponseNotification } from "../utils/apiUtils";
import t, { currentLanguage } from "../../app/i18n";

import { ApiRequestAdapter } from "./ApiRequestAdapter";

const apiService = axios.create({
  baseURL: getApiBaseUrl(),
  headers: { 'Accept-Language': currentLanguage() }
});

export default apiService;

export const createRequestInterceptor = (store: Store): void => {
  apiService.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    if ( selectIsUserAuthenticated(store.getState()) ) {
      config.headers.common = config.headers.common || {};
      config.headers.common['Authorization'] = `Bearer ${selectToken(store.getState())}`;
    }

    store.dispatch(
      startRequestAction({ requestIdentifier: parseRequestIdentifier(config) })
    );

    return config;
  });
};

export const createResponseInterceptor = (store: Store): void => {
  apiService.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
      store.dispatch(
        finishRequestAction({ requestIdentifier: parseRequestIdentifier(response.config) })
      );

      return response;
    },
    (error: AxiosError): Promise<NormalizedError> => {
      if ( error.response ) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const errorResponse: ErrorResponse = error.response.data;
        showErrorResponseNotification(errorResponse);

        if ( errorResponse.exceptionMessage ) {
          console.error("DEBUG: server response exception message -> " + errorResponse.exceptionMessage);
        }

        store.dispatch(
          finishRequestAction({
            requestIdentifier: parseRequestIdentifier(error.config),
            status: errorResponse.status,
            violations: errorResponse.violations
          })
        );

        if ( errorResponse.status === HttpStatus.UNAUTHORIZED ) {
          store.dispatch(logoutAction());
        }

        return Promise.reject(normalizeError(errorResponse.status, errorResponse.message, errorResponse.violations));
      }

      store.dispatch(
        finishRequestAction({ requestIdentifier: parseRequestIdentifier(error.config) })
      );

      if ( error.request ) {
        // The request was made but no response was received
        messageUtils.errorNotification(t("error.serverUnreachable.title"), t("error.serverUnreachable.text"));
      }
      else {
        // Some other error occurred
        messageUtils.errorNotification(t("error.unexpected.title"), t("error.unexpected.text"));
      }

      return Promise.reject(normalizeError(null));
    });
};

const parseRequestIdentifier = (config: AxiosRequestConfig): string => {
  return ApiRequestAdapter.createIdentifier(config.url.replace(config.baseURL, ""), config.method as RequestMethod);
};

const normalizeError = (status: number, message: string = null, violations: FieldConstraintViolation[] = null): NormalizedError => ({
  serverResponded: !!status,
  status,
  message,
  violations
});
