import React from "react";
import { Alert, Col, Input, Modal, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { UpdateMyIdentityAccount } from "../types";
import { Contact } from "../../../common/modules/types";
import { Account } from "../../account/types";
import { ValidationErrorResponse } from "../../types";
import { ContactType } from "../../../common/modules/enums";
import { updateMyIdentityActions } from "../ducks";
import { deleteStateValidationErrorResponseAction } from "../../ducks";
import { selectStandardProps, setErrorsToForm_deprecated } from "../../../common/utils/formUtils";
import validations, { regexPatterns } from "../../../common/utils/validationUtils";
import { rowGutter } from "../../../common/constants";
import t from "../../../app/i18n";

import HiddenInputDeprecated from "../../../common/components/form/components/HiddenInput_Deprecated";

export interface Props extends FormComponentProps<UpdateMyIdentityAccount> {
  updateAccountFormVisible: boolean;
  changePasswordFormVisible: boolean;
  account: Account;
  contacts: Contact[];
  errorResponse: ValidationErrorResponse;
  inProgress: boolean;
  locationKey: string;
  onFormSubmit: typeof updateMyIdentityActions.request;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
  onFormCancel(): void;
}

class MyIdentityUpdateAccountForm extends React.Component<Props> {

  handleFormSubmit = (): void => {
    this.props.form.validateFields((errors, values) => {
      if ( !errors ) {
        this.props.onFormSubmit({ account: values });
      }
    })
  };

  handleFormCancel = (): void => {
    this.props.onFormCancel();
    this.props.form.resetFields();
  };

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return nextProps.updateAccountFormVisible || this.props.updateAccountFormVisible
      || nextProps.changePasswordFormVisible || this.props.changePasswordFormVisible;
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( this.props.errorResponse ) {
      setErrorsToForm_deprecated(this.props.form, this.props.errorResponse.violations, "account.attrs");
      this.props.onErrorResponseDelete();
    }
    else if ( (!prevProps.updateAccountFormVisible && this.props.updateAccountFormVisible)
      || (!prevProps.changePasswordFormVisible && this.props.changePasswordFormVisible) ) {
      const { account } = this.props;
      this.props.form.setFieldsValue({
        optimisticLockVersion: account.optimisticLockVersion,
        username: account.username,
        email: account.email
      });
    }
    else if ( (this.props.updateAccountFormVisible || this.props.changePasswordFormVisible)
      && prevProps.locationKey !== this.props.locationKey ) {
      this.handleFormCancel();
    }
  }

  render(): React.ReactNode {
    const { form, updateAccountFormVisible, changePasswordFormVisible, contacts } = this.props;
    const { getFieldDecorator } = form;
    const colSpan = 12;

    return (
      <Modal
        width={700}
        visible={updateAccountFormVisible || changePasswordFormVisible}
        title={updateAccountFormVisible ? t("myIdentity.titles.updateAccount") : t("myIdentity.titles.changePassword")}
        okText={t("common.save")}
        cancelText={t("common.cancel")}
        confirmLoading={this.props.inProgress}
        forceRender
        onOk={this.handleFormSubmit}
        onCancel={this.handleFormCancel}>

        <Form layout="vertical">

          <HiddenInputDeprecated form={form} formKey="optimisticLockVersion" />

          {updateAccountFormVisible && (
            <Row gutter={rowGutter}>
              <Col span={colSpan}>
                <Form.Item label={t("account.attrs.username")}>
                  {getFieldDecorator("username", {
                    rules: [validations.notBlank, validations.size(1, 70), validations.pattern(regexPatterns.usernameRegex)]
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item label={t("account.attrs.email")}>
                  {getFieldDecorator("email", { rules: [validations.notNull] })(
                    <Select
                      {...selectStandardProps}
                      options={contacts
                        .filter(contact => contact.type === ContactType.EMAIL || contact.type === ContactType.BROKER_EMAIL)
                        .map(contact => ({ value: contact.value, label: contact.value }))} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}

          {changePasswordFormVisible && (
            <>
              <HiddenInputDeprecated form={form} formKey="username" />
              <HiddenInputDeprecated form={form} formKey="email" />

              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item label={t("account.attrs.previousPassword")}>
                    {getFieldDecorator("previousPassword", { rules: validations.sufficientPassword })(
                      <Input.Password />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item label={t("account.attrs.newPassword")}>
                    {getFieldDecorator("newPassword", { rules: validations.sufficientPassword })(
                      <Input.Password />
                    )}
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item label={t("account.attrs.newPasswordRepeat")}>
                    {getFieldDecorator("newPasswordRepeat", {
                      rules: [...validations.sufficientPassword, validations.repeatedPassword(form, "newPassword")]
                    })(
                      <Input.Password />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Alert type="info" showIcon message={t("myIdentity.helpers.changePasswordInfo")} />
            </>
          )}

        </Form>

      </Modal>
    );
  }
}

export default Form.create<Props>()(MyIdentityUpdateAccountForm);
