import t from "../../../app/i18n";

export enum CommissionsBatchStep {
  IMPORT = 'IMPORT',
  CALCULATION = 'CALCULATION',
  REVIEW = 'REVIEW',
  FINISH = 'FINISH'
}

export enum CommissionsToInclude {
  CURRENT_BATCH_ONLY = 'CURRENT_BATCH_ONLY',
  PREVIOUS_BATCHES_ONLY = 'PREVIOUS_BATCHES_ONLY',
  ALL = 'ALL'
}

export enum CommissionType {
  INITIAL = 'INITIAL',
  SUBSEQUENT = 'SUBSEQUENT'
}

export enum PostponementReason {
  NOT_FOUND = 'NOT_FOUND',
  NOT_VERIFIED = 'NOT_VERIFIED',
  BATCH_DELETED = 'BATCH_DELETED',
  IMPORT_ERROR = 'IMPORT_ERROR',
  MANUAL = 'MANUAL'
}

export enum CommissionsBatchStepDirection {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS'
}

export const commissionTypeTMap = new Map<CommissionType, string>(
  Object.values(CommissionType).map(type => [type, t("commissions.batch.enums.commissionType." + type)])
);

export const postponementReasonTMap = new Map<PostponementReason, string>(
  Object.values(PostponementReason).map(reason => [reason, t("commissions.batch.enums.postponementReason." + reason)])
);
