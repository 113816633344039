import "./client-search-input.less";
import React from "react";
import { Col, Form, Input } from "antd";
import { FormItemProps } from "antd/lib/form";
import { Client } from "../types";
import { FieldConstraintViolation } from "../../../common/types";
import { ClientFormStage, ClientFormType, ClientSearchActionType } from "../enums";
import t from "../../../app/i18n";

import ClientActionsFormLabel from "./ClientActionsFormLabel";
import ClientTypeTagWithName from "./ClientTypeTagWithName";

export interface Props<Type = ClientFormType> {
  formItemProps: FormItemProps;
  formStage: ClientFormStage;
  formType: Type;
  processedType: Type;
  client: Client;
  violationErrors: Map<Type, FieldConstraintViolation[]>;
  inProgress: boolean;
  disabled?: boolean;
  optional?: boolean;
  inputColSpan?: number;
  clientNameColSpan?: number;
  onActionClick(formType: Type, actionType: ClientSearchActionType): void;
  onFocus?(formType: Type): void;
  onSearch(value: string, formType: Type): void;
  onChange(value: string, formType: Type): void;
}

const DEFAULT_COL_SPAN = 8;

const ClientSearchInput = <Type, >(props: Props<Type>) => {

  let errorStatus = undefined;
  if ( props.violationErrors.has(props.formType) ) {
    errorStatus = { help: t("client.helpers.search.clientsDataError"), validateStatus: "error" };
  }
  else if ( props.formStage === ClientFormStage.NEW ) {
    errorStatus = { help: t("client.helpers.search.clientsDataMissingError"), validateStatus: "warning" };
  }

  return (
    <>
      <Col span={props.inputColSpan || DEFAULT_COL_SPAN}>
        <Form.Item
          {...errorStatus}
          className="client-search-input"
          name={props.formItemProps.name}
          required={false}
          rules={props.formItemProps.rules}
          initialValue={props.formItemProps.initialValue}
          label={
            <ClientActionsFormLabel
              label={props.formItemProps.label as string}
              stage={props.formStage}
              optional={props.optional}
              onCreateClick={() => props.onActionClick(props.formType, ClientSearchActionType.CREATE)}
              onUpdateClick={() => props.onActionClick(props.formType, ClientSearchActionType.UPDATE)}
              onDeleteClick={!props.disabled ? () => props.onActionClick(props.formType, ClientSearchActionType.DELETE) : undefined} />
          }>
          <Input.Search
            disabled={props.disabled || props.formStage === ClientFormStage.SELECTED}
            loading={props.inProgress && props.processedType === props.formType}
            onFocus={() => props.onFocus?.(props.formType)}
            onSearch={value => props.onSearch(value, props.formType)}
            onChange={event => props.onChange(event.target.value, props.formType)} />
        </Form.Item>
      </Col>
      <Col span={props.clientNameColSpan || DEFAULT_COL_SPAN} className="client-search-view sub-header-info">
        <ClientTypeTagWithName client={props.client} />
      </Col>
    </>
  )
};

export default ClientSearchInput;
