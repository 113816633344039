import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Tag } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Key, SorterResult, SortOrder, TableCurrentDataSource } from "antd/lib/table/interface";
import { ContractFilterPageResult, ContractList } from "../../../types";
import { UUID } from "../../../../../common/types";
import { ContractOrderByColumn, ContractView } from "../../../enums";
import { formatLocaleCurrency, formatLocaleDate, formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { orderDirectionToSortOrder, paginationStandardProps, toMoment } from "../../../../../common/utils/formUtils";
import { tInterval } from "../../../../../common/utils/translationUtils";
import t from "../../../../../app/i18n";

import ContractStatusTag from "../../ContractStatusTag";
import ContractReactionStatusTag from "../../ContractReactionStatusTag";
import ClientTypeTagWithName from "../../../../client/components/ClientTypeTagWithName";

export interface Props {
  contractsPage: ContractFilterPageResult<ContractList>;
  onRowDoubleClick(contractId: UUID): void;
  onTableChange(pagination: TablePaginationConfig,
                filters: Record<string, Key[] | null>,
                sorter: SorterResult<ContractList> | SorterResult<ContractList>[],
                extra: TableCurrentDataSource<ContractList>): void;
}

const ContractListTableView = (props: Props) => {

  const getSortOrder = (column: ContractOrderByColumn): SortOrder => {
    return orderDirectionToSortOrder(props.contractsPage.orderDirections[props.contractsPage.orderBy.indexOf(column)]);
  };

  const createColumns = (): ColumnsType<ContractList> => {
    const items: ColumnsType<ContractList> = [];

    if ( props.contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT ) {
      items.push(
        {
          key: "createdAt",
          title: t("contract.attrs.createdAt"),
          dataIndex: ContractOrderByColumn.CREATED_AT,
          sorter: true,
          defaultSortOrder: getSortOrder(ContractOrderByColumn.CREATED_AT),
          render: (text, record) => formatLocaleDateTime(record.createdAt)
        }
      );
    }
    if ( props.contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT || props.contractsPage.report === ContractView.INTERNAL_UNPAID_REPORT ) {
      items.push(
        {
          key: "effectiveBeginningOrSignDate",
          title: <>{t("contract.attrs.effectiveBeginningDate")}<br />{t("contract.attrs.signDate")}</>,
          dataIndex: ContractOrderByColumn.EFFECTIVE_BEGINNING_OR_SIGN_DATE,
          sorter: true,
          defaultSortOrder: getSortOrder(ContractOrderByColumn.EFFECTIVE_BEGINNING_OR_SIGN_DATE),
          render: (text, record) => formatLocaleDate(record.effectiveBeginningDate || record.signDate)
        }
      );
    }
    if ( props.contractsPage.report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT ) {
      items.push(
        {
          key: "lastContractCancellationOrContactClientDate",
          title: <>{t("contract.attrs.lastContractCancellationDate")}<br />{t("contract.attrs.contactClientDate")}</>,
          dataIndex: ContractOrderByColumn.LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE,
          sorter: true,
          defaultSortOrder: getSortOrder(ContractOrderByColumn.LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE),
          render: (text, record) => {
            const date = record.lastContractCancellationDate || record.contactClientDate;
            const days = date && toMoment(date).startOf("day").diff(moment().startOf("day"), "days");
            return <>
              <Tag color={days <= 0 ? "red" : "geekblue"}>
                {days < 0 ? (<>-</>) : null}{tInterval("contract.filter.helpers.daysUntil_interval", Math.abs(days))}
              </Tag>
              {formatLocaleDate(date)}
            </>;
          }
        }
      );
    }
    items.push(
      {
        key: "contractAndProposalOrLoanNumber",
        title: <>{t("contract.attrs.contractNumber")}<br />{t("contract.attrs.proposalNumber")}<br />{t("contract.attrs.loanNumber")}</>,
        render: (text, record) =>
          <Link to={`/contracts/${record.id}`}>
            {record.contractNumber && <>{record.contractNumber}<br /></>}
            {record.proposalNumber && record.proposalNumber}
            {record.loanNumber && record.loanNumber}
          </Link>
      },
      {
        key: "client",
        title: t("contract.attrs.client"),
        render: (text, record) => <ClientTypeTagWithName client={record.client} showLink />
      },
      {
        key: "institution",
        title: t("contract.attrs.institution"),
        render: (text, record) => record.institution.name
      },
      {
        key: "product",
        title: t("contract.attrs.product"),
        render: (text, record) => record.productGroup ? record.productGroup.name : record.product.name
      },
      {
        key: "gainer1OrGainer2",
        title: <>{t("contract.attrs.gainer1")}<br />{t("contract.attrs.gainer2")}</>,
        render: (text, record) =>
          <>
            {record.gainer1.aggregatedName}
            {record.gainer2 && <><br />{record.gainer2.aggregatedName}</>}
          </>
      }
    );
    if ( props.contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT || props.contractsPage.report === ContractView.INTERNAL_UNPAID_REPORT ) {
      items.push(
        {
          key: "annualPremiumOrApprovedAmount",
          title: <>{t("contract.attrs.annualPremium")}<br />{t("contract.attrs.approvedAmount")}</>,
          className: "table-column-right-align",
          dataIndex: ContractOrderByColumn.ANNUAL_PREMIUM_OR_APPROVED_AMOUNT,
          sorter: true,
          defaultSortOrder: getSortOrder(ContractOrderByColumn.ANNUAL_PREMIUM_OR_APPROVED_AMOUNT),
          render: (text, record) => formatLocaleCurrency(record.annualPremium || record.approvedAmount)
        }
      );
    }
    if ( props.contractsPage.report === ContractView.INTERNAL_UNPAID_REPORT ) {
      items.push(
        {
          key: "paidUntilDate",
          title: t("contract.attrs.paidUntilDate"),
          sorter: true,
          dataIndex: ContractOrderByColumn.PAID_UNTIL_DATE,
          defaultSortOrder: getSortOrder(ContractOrderByColumn.PAID_UNTIL_DATE),
          render: (text, record) => formatLocaleDate(record.paidUntilDate)
        },
        {
          key: "amountOwed",
          title: t("contract.attrs.amountOwed"),
          className: "table-column-right-align",
          dataIndex: ContractOrderByColumn.AMOUNT_OWED,
          sorter: true,
          defaultSortOrder: getSortOrder(ContractOrderByColumn.AMOUNT_OWED),
          render: (text, record) => formatLocaleCurrency(record.amountOwed)
        }
      );
    }
    if ( props.contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT || props.contractsPage.report === ContractView.INTERNAL_UNPAID_REPORT ) {
      items.push(
        {
          key: "status",
          title: t("contract.enums.status._label"),
          render: (text, record) => <ContractStatusTag status={record.status} />
        }
      );
    }
    if ( props.contractsPage.report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT ) {
      items.push(
        {
          key: "insurancePeriodEndOrFixationAnniversaryDate",
          title: <>{t("contract.attrs.insurancePeriodEndDate")}<br />{t("contract.attrs.fixationAnniversaryDate")}</>,
          dataIndex: ContractOrderByColumn.INSURANCE_PERIOD_END_OR_FIXATION_ANNIVERSARY_DATE,
          sorter: true,
          defaultSortOrder: getSortOrder(ContractOrderByColumn.INSURANCE_PERIOD_END_OR_FIXATION_ANNIVERSARY_DATE),
          render: (text, record) => formatLocaleDate(record.insurancePeriodEndDate || record.fixationAnniversaryDate)
        },
        {
          key: "reactionStatus",
          title: t("contract.enums.reactionStatus._label"),
          render: (text, record) => <ContractReactionStatusTag reactionStatus={record.reactionStatus} />
        }
      );
    }

    return items;
  };

  return (
    <Table<ContractList>
      size="small"
      className="table-row-stripped"
      rowKey="id"
      columns={createColumns()}
      dataSource={props.contractsPage.pageData}
      pagination={{
        ...paginationStandardProps,
        current: props.contractsPage.pageIndex + 1,
        pageSize: props.contractsPage.pageSize,
        total: props.contractsPage.totalElementsCount
      }}
      onChange={props.onTableChange}
      onRow={record => ({
        onDoubleClick: () => props.onRowDoubleClick(record.id)
      })} />
  )
};

export default ContractListTableView;
