import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { Col, Form, Input, Row, Select, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "rc-field-form/lib/interface";
import { Country } from "../enums";
import { regexPatterns, validations } from "../../utils/validationUtils";
import { selectStandardProps } from "../../utils/formUtils";
import { rowGutter } from "../../constants";
import t from "../../../app/i18n";

export interface Props {
  form: FormInstance;
  rootKey: NamePath;
  label: string;
  required?: boolean;
  switchProps?: AddressSwitchProps;
}

export interface AddressSwitchProps {
  enabled: boolean;
  onEnabledChange(enabled: boolean): void;
}

const AddressForm = ({ form, rootKey, label, required, switchProps }: Props) => {

  const [streetFilled, setStreetFilled] = useState<boolean>();

  useEffect(() => {
    form.validateFields([[...rootKey, (streetFilled ? "descriptiveNumber" : "orientationNumber")]]);
  }, [streetFilled, form, rootKey]);

  const handleEnabledSwitchChange = (checked: boolean): void => {
    if ( !checked ) {
      form.resetFields([[...rootKey, "street"], [...rootKey, "descriptiveNumber"], [...rootKey, "orientationNumber"],
        [...rootKey, "city"], [...rootKey, "zipCode"], [...rootKey, "country"]]);
    }
    switchProps.onEnabledChange(checked);
  };

  const colSpan = 4;
  const smallColSpan = 3;

  const switchButton = switchProps ? (
    <span className="margin-left-tiny">
      <Switch size="small" checked={switchProps.enabled} onChange={handleEnabledSwitchChange} />
    </span>
  ) : null;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={24}
             className={["bold-text", "margin-bottom-tiny", ...(required ? ["form-item-required"] : [])].join(" ")}>
          {label} {switchButton}
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...rootKey, "street"]}
            label={t("address.attrs.street")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.streetRegex)]}>
            <Input
              disabled={switchProps ? !switchProps.enabled : false}
              onChange={e => setStreetFilled(!!e.target.value)} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, rootKey)?.street !== get(next, rootKey)?.street}>
          {({ getFieldValue }) => {
            const hasStreet = !!getFieldValue([...rootKey, "street"]);
            return (
              <>
                <Col span={smallColSpan}>
                  <Form.Item
                    name={[...rootKey, "descriptiveNumber"]}
                    label={t("address.attrs.descriptiveNumber")}
                    rules={[
                      hasStreet ? validations.none : validations.notBlank,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}>
                    <Input disabled={switchProps ? !switchProps.enabled : false} />
                  </Form.Item>
                </Col>

                <Col span={smallColSpan}>
                  <Form.Item
                    name={[...rootKey, "orientationNumber"]}
                    label={t("address.attrs.orientationNumber")}
                    rules={[
                      hasStreet ? validations.notBlank : validations.none,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}>
                    <Input disabled={switchProps ? !switchProps.enabled : false} />
                  </Form.Item>
                </Col>
              </>
            )
          }}
        </Form.Item>

        <Col span={colSpan}>
          <Form.Item
            name={[...rootKey, "city"]}
            label={t("address.attrs.city")}
            rules={[validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]}>
            <Input disabled={switchProps ? !switchProps.enabled : false} />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootKey, "zipCode"]}
            label={t("address.attrs.zipCode")}
            rules={[validations.notBlank, validations.length(5), validations.numeric]}>
            <Input disabled={switchProps ? !switchProps.enabled : false} />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootKey, "country"]}
            label={t("address.attrs.country")}
            rules={[validations.notNull]}
            initialValue={Country.SVK}>
            <Select
              {...selectStandardProps}
              disabled={switchProps ? !switchProps.enabled : false}
              options={Object.keys(Country).map(country => ({ value: country, label: country }))} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default AddressForm;
