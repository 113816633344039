import React from "react";
import { connect } from "react-redux";
import { Redirect, RedirectProps } from "react-router";
import { RootState } from "../../types";
import { Permission } from "./enums";
import { selectPermissions } from "../../../modules/auth/ducks";
import { hasPermission } from "../../utils/utils";

import NotFound from "../../pages/NotFound/NotFound";

export interface Props {
  permissionRedirects: PermissionRedirectProps[];
}

export interface PermissionRedirectProps extends RedirectProps {
  permission: Permission;
}

interface StateProps {
  accountPermissions: Permission[];
}

const RedirectWithPermission = ({ permissionRedirects, accountPermissions }: Props & StateProps) => {
  const permissionRedirect = permissionRedirects ? permissionRedirects.find(redirect => hasPermission(accountPermissions, redirect.permission)) : null;
  if ( permissionRedirect ) {
    const { permission, ...redirectProps } = permissionRedirect;
    return <Redirect {...redirectProps} />;
  }
  return <NotFound />;
};

const mapStateToProps = (state: RootState): StateProps => ({
  accountPermissions: selectPermissions(state)
});

export default connect<StateProps, {}, Props, RootState>(mapStateToProps)(RedirectWithPermission);
