import "./vehicle-calc-results.less";
import React from "react";
import { Affix, Button, Col, Drawer, Row } from "antd";
import { DoubleRightOutlined, FileTextOutlined } from "@ant-design/icons";
import { VehicleCalcResultData, VehicleCalcResults, VehicleCalcType, VehicleOfferCalcType } from "../../types";
import { CalcResult } from "../../../types";
import { CalcType } from "../../../../enums";
import t from "../../../../../../app/i18n";

import VehicleCalcResultsColumn from "./VehicleCalcResultsColumn";

export interface Props {
  visible: boolean;
  calcType: VehicleCalcType;
  calcResults: VehicleCalcResults;
  selectedResult?: CalcResult<VehicleCalcResultData>;
  onClose(): void;
  onGenerateOfferClick(type: VehicleOfferCalcType): void;
  onGenerateContractClick(result: CalcResult<VehicleCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<VehicleCalcResultData>): void;
}

const VehicleCalcResultsView = ({ visible, calcType, calcResults, selectedResult, ...props }: Props) => (
  <Drawer
    title={t("calc.vehicle.sections.results")}
    width={calcType === CalcType.MTPL_CRASH ? 1300 : calcType === CalcType.MTPL ? 975 : 650}
    visible={visible}
    closable
    onClose={props.onClose}>

    <div className="vehicle-calc-results-container">
      {calcType === CalcType.MTPL_CRASH ? (
        <Row className="two-columns-view">
          <Col span={12} className="left-result-column">
            <VehicleCalcResultsColumn
              calcType={CalcType.MTPL}
              calcResults={calcResults}
              selectedResult={selectedResult}
              onGenerateContractClick={props.onGenerateContractClick}
              onShowErrorsClick={props.onShowErrorsClick} />
          </Col>

          <Col span={12} className="right-result-column">
            <VehicleCalcResultsColumn
              calcType={CalcType.CRASH}
              calcResults={calcResults}
              selectedResult={selectedResult}
              onGenerateContractClick={props.onGenerateContractClick}
              onShowErrorsClick={props.onShowErrorsClick} />
          </Col>
        </Row>
      ) : (
        <VehicleCalcResultsColumn
          calcType={calcType}
          calcResults={calcResults}
          selectedResult={selectedResult}
          threeColumnsLayout={calcType === CalcType.MTPL}
          onGenerateContractClick={props.onGenerateContractClick}
          onShowErrorsClick={props.onShowErrorsClick} />
      )}
    </div>

    <Affix offsetBottom={0} className="vehicle-calc-results-navigation">
      <>
        <div className="actions-center">
          {calcType === CalcType.MTPL || calcType === CalcType.MTPL_CRASH ? (
            <Button type="primary" icon={<FileTextOutlined />}
                    onClick={() => props.onGenerateOfferClick(CalcType.MTPL)}>
              {t("calc.vehicle.actions.generateMtplOffer")}
            </Button>
          ) : null}

          {calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH ? (
            <Button type="primary" icon={<FileTextOutlined />} className="margin-left-tiny"
                    onClick={() => props.onGenerateOfferClick(CalcType.CRASH)}>
              {t("calc.vehicle.actions.generateCrashOffer")}
            </Button>
          ) : null}
        </div>

        <div className="actions-right">
          <Button icon={<DoubleRightOutlined />} onClick={props.onClose}>{t("common.close")}</Button>
        </div>
      </>
    </Affix>
  </Drawer>
);

export default VehicleCalcResultsView;
