import { combineReducers } from "redux";
import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  CreateUpdateLifeInsuranceTariff,
  LifeInsuranceTariff,
  LifeInsuranceTariffFilterPageRequest,
  LifeInsuranceTariffFilterPageResult,
  LifeInsuranceTariffReducerState
} from "./types";
import { ActionCreator, EntityIdRequest, EntityObjectRequest, RootState } from "../../../common/types";
import { changeLocationKeyAction } from "../../ducks";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { initSearchPageResult } from "../../../common/utils/apiUtils";
import { replaceInArray } from "../../../common/utils/utils";
import api from "./api";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'life-insurance-tariff/FILTER',
  CREATE = 'life-insurance-tariff/CREATE',
  UPDATE = 'life-insurance-tariff/UPDATE',
  DELETE = 'life-insurance-tariff/DELETE',
  SET_STATE_LIST = 'life-insurance-tariff/SET_STATE_LIST',
  DELETE_STATE_PAGE = 'life-insurance-tariff/DELETE_STATE_PAGE'
}

/**
 * ACTIONS
 */
export const filterLifeInsuranceTariffsActions = createApiActionCreators<LifeInsuranceTariffFilterPageRequest, LifeInsuranceTariffFilterPageResult>(actionType.FILTER);
export const createLifeInsuranceTariffActions = createApiActionCreators<CreateUpdateLifeInsuranceTariff, LifeInsuranceTariff>(actionType.CREATE);
export const updateLifeInsuranceTariffActions = createApiActionCreators<EntityObjectRequest<CreateUpdateLifeInsuranceTariff>, LifeInsuranceTariff>(actionType.UPDATE);
export const deleteLifeInsuranceTariffActions = createApiActionCreators<EntityIdRequest, void>(actionType.DELETE);

export const setStateLifeInsuranceTariffsPageAction = createActionCreator<LifeInsuranceTariffFilterPageResult>(actionType.SET_STATE_LIST);
export const deleteStateLifeInsuranceTariffsPageAction = createActionCreator<void>(actionType.DELETE_STATE_PAGE);

/**
 * REDUCERS
 */
const initialState: LifeInsuranceTariffReducerState = {
  currentPage: {
    ...initSearchPageResult<LifeInsuranceTariff>(),
    productIds: []
  },
};

const currentPageReducer = createReducer<LifeInsuranceTariffFilterPageResult>(initialState.currentPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentPage
  },
  [actionType.SET_STATE_LIST]: (state, payload) => payload,
  [actionType.DELETE_STATE_PAGE]: () => initialState.currentPage
});

export default combineReducers({ currentPage: currentPageReducer });

/**
 * SELECTORS
 */
const selectLifeInsuranceTariff = (state: RootState): LifeInsuranceTariffReducerState => state.admin.lifeInsuranceTariff;

export const selectLifeInsuranceTariffsCurrentPage = (state: RootState): LifeInsuranceTariffFilterPageResult => selectLifeInsuranceTariff(state).currentPage;

/**
 * SAGAS
 */
function* filterLifeInsuranceTariffs({ payload }: ActionCreator<LifeInsuranceTariffFilterPageRequest>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariffFilterPageResult> = yield call(api.filterLifeInsuranceTariffs, payload);
    yield put(filterLifeInsuranceTariffsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterLifeInsuranceTariffsActions.failure(error));
  }
}

function* createLifeInsuranceTariff({ payload }: ActionCreator<CreateUpdateLifeInsuranceTariff>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariff> = yield call(api.createLifeInsuranceTariff, payload);
    yield put(createLifeInsuranceTariffActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();

    const currentPage: LifeInsuranceTariffFilterPageResult = yield { ...select(selectLifeInsuranceTariffsCurrentPage) };
    yield put(filterLifeInsuranceTariffsActions.request({
      pageIndex: currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      keyword: currentPage.keyword,
      productIds: currentPage.productIds
    }));
  }
  catch ( error ) {
    yield put(createLifeInsuranceTariffActions.failure(error));
  }
}

function* updateLifeInsuranceTariff({ payload }: ActionCreator<EntityObjectRequest<CreateUpdateLifeInsuranceTariff>>) {
  try {
    const response: AxiosResponse<LifeInsuranceTariff> = yield call(api.updateLifeInsuranceTariff, payload);
    yield put(updateLifeInsuranceTariffActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();

    const currentPage: LifeInsuranceTariffFilterPageResult = yield { ...select(selectLifeInsuranceTariffsCurrentPage) };
    yield put(setStateLifeInsuranceTariffsPageAction({
      ...currentPage,
      pageData: replaceInArray(currentPage.pageData, item => item.id === payload.id, response.data)
    }));
  }
  catch ( error ) {
    yield put(updateLifeInsuranceTariffActions.failure(error));
  }
}

function* deleteLifeInsuranceTariff({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteLifeInsuranceTariff, payload);
    yield put(deleteLifeInsuranceTariffActions.success());

    const currentPage: LifeInsuranceTariffFilterPageResult = yield { ...select(selectLifeInsuranceTariffsCurrentPage) };
    yield put(filterLifeInsuranceTariffsActions.request({
      pageIndex: currentPage.pageElementsCount === 1 ? Math.max(currentPage.pageIndex - 1, 0) : currentPage.pageIndex,
      pageSize: currentPage.pageSize,
      keyword: currentPage.keyword,
      productIds: currentPage.productIds
    }));
  }
  catch ( error ) {
    yield put(deleteLifeInsuranceTariffActions.failure(error));
  }
}

export function* lifeInsuranceTariffSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterLifeInsuranceTariffs);
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createLifeInsuranceTariff);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateLifeInsuranceTariff);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deleteLifeInsuranceTariff);
}
