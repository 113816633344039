import React from "react";
import { Alert, Button, Drawer } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Client, CreateUpdateClient, SelfEmployedClient } from "../../types";
import { FieldConstraintViolation } from "../../../../common/types";
import { ClientFormType, ClientType } from "../../enums";
import { createClientAggregatedName, createClientAggregatedNaturalName } from "../../utils";
import { setErrorsToForm_deprecated } from "../../../../common/utils/formUtils";
import t from "../../../../app/i18n";

import ClientUpdateForm, { ClientUpdateFormComponent } from "../forms/ClientUpdateForm";
import ClientTypeTag from "../ClientTypeTag";

export interface Props<Type = ClientFormType> {
  visible: boolean;
  client: Client;
  formType: Type;
  violationErrors: Map<Type, FieldConstraintViolation[]>;
  placement?: "calc" | "contract";
  onFormSubmit(client: Client, clientFormType: Type): void;
}

const ClientDrawerUpdateForm = <Type, >({ visible, client, formType, violationErrors, ...props }: Props<Type>) => {

  const formRef = React.createRef<ClientUpdateFormComponent>();

  const handleFormSubmit = (clientData: CreateUpdateClient): void => {
    const updatedClient = Object.assign({ ...client }, clientData);

    updatedClient.aggregatedName = createClientAggregatedName(updatedClient);
    if ( updatedClient.type === ClientType.SELF_EMPLOYED ) {
      (updatedClient as SelfEmployedClient).aggregatedNaturalName = createClientAggregatedNaturalName(updatedClient);
    }

    props.onFormSubmit(updatedClient, formType);
  };

  const handleDrawerClose = (): void => {
    if ( formRef.current ) {
      formRef.current.handleFormSubmit();
    }
  };

  const handleDrawerVisibleChange = (visible: boolean): void => {
    if ( visible && formRef.current ) {
      const violations = violationErrors.get(formType);
      if ( violations && violations.length > 0 ) {
        setErrorsToForm_deprecated(formRef.current.props.form, violations, "client.attrs");
      }
    }
  };

  return (
    <Drawer
      title={
        <>
          <span>{t("client.titles.update")}</span>
          <span>{client && <>: {client.aggregatedName} <ClientTypeTag type={client.type} /> </>}</span>
          <div className="margin-top-tiny">
            <Alert
              showIcon
              message={props.placement === "contract"
                ? t("client.helpers.clientDataUpdateInfoContract") : t("client.helpers.clientDataUpdateInfoCalc")} />
          </div>
        </>
      }
      width={950}
      visible={visible}
      closable
      destroyOnClose
      afterVisibleChange={handleDrawerVisibleChange}
      onClose={handleDrawerClose}>

      <>
        {client && (
          <ClientUpdateForm
            wrappedComponentRef={formRef}
            client={client}
            hideActionButtons
            disablePinInput={client.type === ClientType.NATURAL}
            disableCrnInput={client.type === ClientType.SELF_EMPLOYED || client.type === ClientType.LEGAL}
            disableFormSubmitValidation
            onFormSubmit={handleFormSubmit} />
        )}

        <div className="margin-top-large">
          <Button type="primary" icon={<CheckOutlined />} onClick={handleDrawerClose}>{t("common.submit")}</Button>
        </div>
      </>

    </Drawer>
  );
};

export default ClientDrawerUpdateForm;
