import React, { useState } from "react";
import { FormInstance } from "antd/lib/form";
import { Contact } from "../types";
import { ContactType } from "../enums";
import { contains } from "../../utils/utils";
import t from "../../../app/i18n";

import AddDeleteButton from "../../components/buttons/AddDeleteButton";
import ContactRowFormItem from "./ContactRowFormItem";

export interface Props {
  form: FormInstance;
  initialContacts?: Contact[];
  useBrokerContactTypes?: boolean;
}

const ContactsForm = (props: Props) => {
  const [selectedContactTypes, setSelectedContactTypes] = useState<ContactType[]>(
    props.initialContacts ? props.initialContacts.map(contact => contact.type) : []);

  const handleContactTypeChange = (index: number, contactType: ContactType): void => {
    const updatedSelectedContactTypes = [...selectedContactTypes];
    updatedSelectedContactTypes[index] = contactType;
    setSelectedContactTypes(updatedSelectedContactTypes);

    if ( props.useBrokerContactTypes ) {
      const contacts = [...props.form.getFieldValue("contacts")] as Contact[];
      contacts[index] = {
        ...contacts[index],
        useForNotifications: contactType === ContactType.BROKER_EMAIL || contactType === ContactType.BROKER_PHONE_NUMBER
      }
      props.form.setFieldsValue({ contacts });
    }
  };

  const handleContactDelete = (index: number): void => {
    const contacts: Contact[] = [...props.form.getFieldValue("contacts")];
    contacts.splice(index, 1);

    props.form.setFieldsValue({ contacts });
    setSelectedContactTypes(contacts.map(contact => contact.type));
  };

  const handleContactAdd = (): void => {
    setSelectedContactTypes([...selectedContactTypes, undefined]);
  };

  const contactTypeOptions: ContactType[] = props.useBrokerContactTypes
    ? Object.values(ContactType)
    : Object.values(ContactType).filter(type => type !== ContactType.BROKER_EMAIL && type !== ContactType.BROKER_PHONE_NUMBER);

  const disabledContactTypeOptions: ContactType[] = [];
  if ( contains(selectedContactTypes, ContactType.BROKER_EMAIL) ) {
    disabledContactTypeOptions.push(ContactType.BROKER_EMAIL);
  }
  if ( contains(selectedContactTypes, ContactType.BROKER_PHONE_NUMBER) ) {
    disabledContactTypeOptions.push(ContactType.BROKER_PHONE_NUMBER);
  }

  const contactFormItems = [];
  for ( let index = 0; index < selectedContactTypes.length; index++ ) {
    contactFormItems.push(
      <ContactRowFormItem
        key={index} index={index}
        selectedContactType={selectedContactTypes[index]}
        contactTypeOptions={contactTypeOptions}
        disabledContactTypeOptions={disabledContactTypeOptions}
        showUseForNotificationsItem={props.useBrokerContactTypes}
        onContactTypeChange={handleContactTypeChange}
        onDelete={handleContactDelete} />
    );
  }

  return (
    <>
      {contactFormItems}
      <AddDeleteButton type="add" label={t("contact.actions.add")} onClick={handleContactAdd} />
    </>
  )
}

export default ContactsForm;
