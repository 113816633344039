import React from "react"
import { Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ContactType } from "../enums";
import t from "../../../app/i18n";

const ContactTypePopover = () => (
  <Popover
    title={t("contact.enums.type._label")}
    content={
      <table className="contacts-table-view">
        <tbody>
        {Object.keys(ContactType).map((type, index) => (
          <tr key={index}>
            <td>{t("contact.enums.type." + type)}</td>
            <td>{t("contact.helpers.contactType." + type)}</td>
          </tr>
        ))}
        </tbody>
      </table>
    }>
    <QuestionCircleOutlined className="cursor-help" />
  </Popover>
);

export default ContactTypePopover;
