import React from "react";
import { Button, Col, Modal, PageHeader, Popconfirm, Row, Statistic, Tag } from "antd";
import { DeleteOutlined, EditOutlined, FileDoneOutlined } from "@ant-design/icons";
import {
  GenericInsurance,
  InsuranceContract,
  LifeInsurance,
  RealtyInsurance,
  VehicleInsurance
} from "../../../../../types";
import { AuthorizedClientCompanyFunction, InsuranceType } from "../../../../../enums";
import { ClientFormType } from "../../../../../../client/enums";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import { deleteContractActions, verifyContractActions } from "../../../../../ducks";
import { contains, isDefinedValue } from "../../../../../../../common/utils/utils";
import { threeSpacedStringNormalizeFunction } from "../../../../../../../common/utils/formUtils";
import { formatInstitutionName } from "../../../../../../../common/utils/formatUtils";
import t from "../../../../../../../app/i18n";

import ClientsListDrawerView, { ClientDataProps } from "../../../../../../client/components/drawers/ClientsListDrawerView";
import ItemCreatedUpdatedInfoView from "../../../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import CopyToClipboardView from "../../../../../../../common/components/views/CopyToClipboardView";
import ComponentWithPermission from "../../../../../../../common/security/authorization/ComponentWithPermission";
import ContractStatusTag from "../../../../ContractStatusTag";
import PopconfirmDeleteIcon from "../../../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  contract: InsuranceContract;
  onVerify: typeof verifyContractActions.request;
  onDelete: typeof deleteContractActions.request;
  onUpdateClick(): void;
}

const InsuranceContractHeaderSection = ({ contract, ...props }: Props) => {

  const handleVerifyContractClick = (): void => {
    Modal.confirm({
      title: t("contract.helpers.verifyContractTitle"),
      content: t("contract.helpers.verifyContractDesc"),
      okText: t("common.confirm"),
      cancelText: t("common.back"),
      onOk: () => {
        props.onVerify({ id: contract.id });
      }
    });
  };

  const addClientDataToMap = (index: number,
                              type: ClientFormType,
                              clientsData: Map<number, ClientDataProps>,
                              companyFunction?: AuthorizedClientCompanyFunction): void => {
    if ( isDefinedValue(index) ) {
      if ( clientsData.has(index) ) {
        const data = clientsData.get(index);
        clientsData.set(index, {
          ...data,
          clientFormTypes: contains(data.clientFormTypes, type) ? data.clientFormTypes : [...data.clientFormTypes, type]
        })
      }
      else {
        clientsData.set(index, { client: contract.clients[index], clientFormTypes: [type], companyFunction });
      }
    }
  };

  const clientsData = new Map<number, ClientDataProps>();
  addClientDataToMap(contract.insurerIndex, ClientFormType.INSURER, clientsData);

  if ( isDefinedValue(contract.authorizedClient1Index) ) {
    addClientDataToMap(contract.authorizedClient1Index, ClientFormType.AUTHORIZED_1, clientsData, contract.authorizedClient1Function);
  }
  if ( isDefinedValue(contract.authorizedClient2Index) ) {
    addClientDataToMap(contract.authorizedClient2Index, ClientFormType.AUTHORIZED_2, clientsData, contract.authorizedClient2Function);
  }

  contract.insurances.forEach(insurance => {
    switch ( insurance.type ) {
      case InsuranceType.MTPL:
      case InsuranceType.CRASH:
      case InsuranceType.GAP:
      case InsuranceType.PAS:
        addClientDataToMap((insurance as VehicleInsurance).vehicleHolderIndex, ClientFormType.HOLDER, clientsData);
        addClientDataToMap((insurance as VehicleInsurance).vehicleOwnerIndex, ClientFormType.OWNER, clientsData);
        break;
      case InsuranceType.REALTY:
        addClientDataToMap((insurance as RealtyInsurance).insuredClientIndex, ClientFormType.INSURED, clientsData);
        addClientDataToMap((insurance as RealtyInsurance).vinculationClientIndex, ClientFormType.VINCULATION, clientsData);
        break;
      case InsuranceType.TRAVEL:
        break;
      case InsuranceType.LIFE:
        addClientDataToMap((insurance as LifeInsurance).insuredClientIndex, ClientFormType.INSURED, clientsData);
        break;
      case InsuranceType.GENERIC:
        addClientDataToMap((insurance as GenericInsurance).insuredClientIndex, ClientFormType.INSURED, clientsData);
        break;
    }
  })

  return (
    <PageHeader
      className="page-header-box"
      title={t("contract.sections.mainData")}
      tags={[
        <ContractStatusTag key={1} status={contract.status} />,
        !contract.verified ? <Tag key={2} color="red">{t("contract.helpers.contractNotVerified")}</Tag> : null
      ]}
      extra={
        <>
          <ComponentWithPermission permissions={[Permission.INSURANCE_UPDATE]}>
            <Button type="primary" icon={<EditOutlined />} onClick={props.onUpdateClick}>
              {t("contract.actions.updateContract")}
            </Button>
          </ComponentWithPermission>

          {!contract.verified && (
            <ComponentWithPermission permissions={[Permission.INSURANCE_VERIFY]}>
              <Button className="blue-button" icon={<FileDoneOutlined />} onClick={handleVerifyContractClick}>
                {t("contract.actions.verifyContract")}
              </Button>
            </ComponentWithPermission>
          )}

          <ComponentWithPermission permissions={[Permission.INSURANCE_DELETE]}>
            <Popconfirm
              title={t("contract.helpers.deleteContractConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={() => props.onDelete({ id: contract.id })}>
              <Button danger icon={<DeleteOutlined />}>{t("contract.actions.deleteContract")}</Button>
            </Popconfirm>
          </ComponentWithPermission>
        </>
      }>

      <ItemCreatedUpdatedInfoView item={contract} style={{ marginTop: "-15px" }} className="margin-bottom-small" />

      <Row>
        <Statistic
          className="statistic-small"
          title={t("contract.attrs.contractNumber")}
          value={contract.contractNumber}
          formatter={value => <CopyToClipboardView content={threeSpacedStringNormalizeFunction(value as string)}
                                                   contentToCopy={value as string} />} />

        <Statistic
          className="statistic-small margin-left-medium"
          title={t("contract.attrs.proposalNumber")}
          value={contract.proposalNumber}
          formatter={value => <CopyToClipboardView content={threeSpacedStringNormalizeFunction(value as string)}
                                                   contentToCopy={value as string} />} />

        <Statistic
          className="statistic-small margin-left-medium"
          title={t("contract.attrs.insuranceInstitution")}
          value={formatInstitutionName(contract.insuranceInstitution)} />

        <Statistic
          className="statistic-small margin-left-medium"
          title={t("contract.attrs.productGroup")}
          value={contract.productGroup.name} />
      </Row>

      <Row>
        <Col span={24}>
          <h3 className="margin-top-medium">{t("contract.sections.clients")}</h3>
          <ClientsListDrawerView clientsData={Array.from(clientsData.values())} />
        </Col>
      </Row>
    </PageHeader>
  )
};

export default React.memo(InsuranceContractHeaderSection);
