import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { ActionProps, RootState } from "../../../../common/types";
import { createInstitutionActions } from "../ducks";

import InstitutionCreateForm from "../components/forms/InstitutionCreateForm";

interface ActionsMap {
  createInstitution: typeof createInstitutionActions.request;
}

const InstitutionCreateContainer = (props: ActionProps<ActionsMap>) => {
  return <InstitutionCreateForm onFormSubmit={props.actions.createInstitution} />
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    createInstitution: createInstitutionActions.request
  }, dispatch)
});

export default connect<{}, ActionProps<ActionsMap>, {}, RootState>(null, mapDispatchToProps)(InstitutionCreateContainer);
