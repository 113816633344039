export enum TravelInsuranceType {
  SHORT_TERM = 'SHORT_TERM',
  YEAR = 'YEAR',
  CANCELLATION = 'CANCELLATION'
}

export enum TravelRiskGroup {
  TOURIST = 'TOURIST',
  SPORT = 'SPORT',
  MANUAL_WORK = 'MANUAL_WORK',
  BUSINESS_TRIP = 'BUSINESS_TRIP'
}

export enum TravelTerritorialValidity {
  EUROPE = 'EUROPE',
  WORLD = 'WORLD',
  WORLD_WITHOUT_USA_AND_CANADA = 'WORLD_WITHOUT_USA_AND_CANADA',
  CZ = 'CZ'
}

export enum CancellationInsuranceCoverage {
  BASIC = 'BASIC',
  EXTENDED = 'EXTENDED'
}

export enum CancellationInsuranceServiceType {
  PLANE = 'PLANE',
  BUS = 'BUS',
  TRAIN = 'TRAIN',
  SHIP = 'SHIP',
  ACCOMMODATION = 'ACCOMMODATION',
  TRIP = 'TRIP',
  STUDY = 'STUDY'
}

export enum LuggageReinsuranceCoverage {
  ONE_THOUSAND = 'ONE_THOUSAND',
  THREE_THOUSAND = 'THREE_THOUSAND'
}

export enum TravelClientDiscountType {
  ISIC_CARD = 'ISIC_CARD',
  EURO26_CARD = 'EURO26_CARD',
  ITIC_CARD = 'ITIC_CARD',
  UNION_HEALTH_INSURANCE_CLIENT = 'UNION_HEALTH_INSURANCE_CLIENT'
}

export enum VehicleAssistanceReinsuranceType {
  CAR = 'CAR',
  MOTORCYCLE = 'MOTORCYCLE'
}

export enum AbandonedHouseholdReinsuranceType {
  HOUSE = 'HOUSE',
  FLAT = 'FLAT'
}

export enum PetReinsuranceType {
  DOG = 'DOG',
  CAT = 'CAT',
  RABBIT = 'RABBIT',
  BIRD = 'BIRD'
}
