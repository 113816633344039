import React from "react";
import { NaturalPerson } from "../../../types";
import { formatIban, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { createLinkHref } from "../../../../../common/utils/utils";
import t from "../../../../../app/i18n";

import CopyToClipboardView from "../../../../../common/components/views/CopyToClipboardView";

export interface Props {
  person: NaturalPerson;
}

const NaturalPersonDataView = ({ person }: Props) => (
  <>
    <tr>
      <td>{t("person.attrs.aggregatedName")}:</td>
      <td colSpan={3}>{person.aggregatedName}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("person.attrs.personalIdentificationNumberLong")}:</td>
      <td><CopyToClipboardView content={person.personalIdentificationNumber} /></td>
      <td>{t("person.attrs.birthDate")}:</td>
      <td>{formatLocaleDate(person.birthDate)}</td>
    </tr>
    <tr>
      <td>{t("person.attrs.identityCardNumber")}:</td>
      <td colSpan={3}><CopyToClipboardView content={person.identityCardNumber} /></td>
    </tr>
    <tr>
      <td>{t("person.attrs.bankAccountNumber")}:</td>
      <td><CopyToClipboardView content={formatIban(person.bankAccountNumber)} /></td>
      <td>{t("person.attrs.webPage")}:</td>
      <td>{person.webPage
        ? <a href={createLinkHref(person.webPage)} target="_blank" rel="noopener noreferrer">{person.webPage}</a>
        : null}
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("person.attrs.note")}:</td>
      <td colSpan={3}>{person.note}</td>
    </tr>
  </>
);

export default NaturalPersonDataView;
