import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { FormItemProps } from "antd/lib/form";
import { FilterFunction, InstitutionWithProducts } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { ProductGroupBase } from "../../../admin/product/types";
import { selectProductsClassificationEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: ProductGroupOptionsProps;
}

export interface ProductGroupOptionsProps {
  institutionId?: UUID;
  hideAll?: boolean;
  filter?: FilterFunction<ProductGroupBase>;
}

const ProductGroupsEnumFormItemSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {

  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnumerations);

  const resolveProductGroupSelectOptions = (): ProductGroupBase[] => {
    if ( optionsProps?.hideAll ) {
      return [];
    }

    const institutions = optionsProps?.institutionId
      ? productsClassification.filter(institution => institution.id === optionsProps.institutionId)
      : productsClassification;

    let groups = institutions.flatMap(institution => institution.productGroups);
    if ( optionsProps?.filter ) {
      groups = groups.filter(optionsProps.filter);
    }

    const distinctGroups = [];
    groups.forEach(group => {
      if ( distinctGroups.every(distinctGroup => distinctGroup.id !== group.id) ) {
        distinctGroups.push(group)
      }
    });

    return distinctGroups.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={resolveProductGroupSelectOptions().map(g => ({ value: g.id, label: g.name }))} />
    </Form.Item>
  );
}

export default ProductGroupsEnumFormItemSelect;
