import React from "react";
import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CloseCircleOutlined } from "@ant-design/icons";
import { RealtyCalcDraftList } from "../../types";
import { CalcDraftFilterPageResult } from "../../../../drafts/types";
import {
  deleteCalcDraftActions,
  deleteCalcDraftAttachmentActions,
  getCalcDraftActions,
  uploadCalcDraftAttachmentsActions
} from "../../../../drafts/ducks";
import {
  formatLocaleCurrency,
  formatLocaleDateTime,
  formatPlaceOfInsurance
} from "../../../../../../common/utils/formatUtils";
import t from "../../../../../../app/i18n";

import CalcDraftAttachmentsTableView from "../../../../drafts/components/CalcDraftAttachmentsTableView";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";

export interface Props {
  calcDraftsPage: CalcDraftFilterPageResult;
  onShowInCalculator: typeof getCalcDraftActions.request;
  onDelete: typeof deleteCalcDraftActions.request;
  onUploadCalcDraftAttachments: typeof uploadCalcDraftAttachmentsActions.request;
  onDeleteCalcDraftAttachment: typeof deleteCalcDraftAttachmentActions.request;
  onPageChange(pageNumber: number): void;
}

const RealtyCalcDraftTableView = (props: Props) => {

  const columns: ColumnsType<RealtyCalcDraftList> = [
    {
      key: "createdAt",
      title: t("calc.draft.attrs.createdAt"),
      render: (text, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "updatedAt",
      title: t("calc.draft.attrs.updatedAt"),
      render: (text, record) => formatLocaleDateTime(record.updatedAt)
    },
    {
      key: "clientAggregatedName",
      title: t("calc.draft.attrs.clientAggregatedName"),
      dataIndex: "clientAggregatedName"
    },
    {
      key: "buildingType",
      title: t("calc.realty.enums.buildingType._label"),
      render: (text, record) => t("calc.realty.enums.buildingType." + record.buildingType)
    },
    {
      key: "insurances",
      title: t("calc.draft.attrs.insurances"),
      children: [
        {
          key: "realty",
          title: t("calc.realty.sections.realtyInsurance"),
          className: "table-column-right-align",
          render: (text, record) => record.realtyInsuranceAmount
            ? formatLocaleCurrency(record.realtyInsuranceAmount)
            : <div className="center-align"><CloseCircleOutlined /></div>
        },
        {
          key: "household",
          title: t("calc.realty.sections.householdInsurance"),
          className: "table-column-right-align",
          render: (text, record) => record.householdInsuranceAmount
            ? formatLocaleCurrency(record.householdInsuranceAmount)
            : <div className="center-align"><CloseCircleOutlined /></div>
        }
      ]
    },
    {
      key: "placeOfInsurance",
      title: t("calc.draft.attrs.placeOfInsurance"),
      render: (text, record) => formatPlaceOfInsurance(record.placeOfInsurance)
    },
    {
      key: "attachmentsCount",
      title: t("calc.draft.attrs.attachmentsCount"),
      render: (text, record) => record.attachments ? record.attachments.length : 0
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (text, record) => (
        <>
          <ActionTextIcon type="import" color="blue" text={t("calc.draft.actions.setToCalcForm")}
                          onClick={() => props.onShowInCalculator({ id: record.id })} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("calc.draft.titles.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}>
            <span><ActionTextIcon type="delete" color="red" text={t("common.delete")} /></span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table<RealtyCalcDraftList>
      size="small"
      className="table-row-stripped"
      rowKey="id"
      columns={columns}
      dataSource={props.calcDraftsPage.pageData as RealtyCalcDraftList[]}
      expandedRowRender={record =>
        <CalcDraftAttachmentsTableView calcDraft={record}
                                       onUpload={props.onUploadCalcDraftAttachments}
                                       onDelete={props.onDeleteCalcDraftAttachment} />}
      pagination={{
        current: props.calcDraftsPage.pageIndex + 1,
        pageSize: props.calcDraftsPage.pageSize,
        total: props.calcDraftsPage.totalElementsCount,
        hideOnSinglePage: true,
        onChange: props.onPageChange,
        showQuickJumper: true
      }} />
  )
};

export default RealtyCalcDraftTableView;
