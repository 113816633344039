import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import api from "./api";
import {
  CalcRecord,
  CalcRecordFilterPageRequest,
  CalcRecordFilterPageResult,
  CalcRecordList,
  CalcRecordReducerState
} from "./types";
import { ActionCreator, EntityIdRequest, RootState } from "../../../common/types";
import { VehicleCalc, VehicleCalcResultData, VehicleGen } from "../calcs/vehicle/types";
import { TravelCalc, TravelCalcResultData, TravelGen } from "../calcs/travel/types";
import { RealtyCalc, RealtyCalcResultData, RealtyGen } from "../calcs/realty/types";
import { CalcResponse } from "../calcs/types";
import { CalcType } from "../enums";
import { OperationType } from "./enums";
import {
  setVehicleCalcResultsAction,
  setVehicleInitialCalcDataAction,
  setVehicleInitialGenDataAction
} from "../calcs/vehicle/ducks";
import {
  setTravelCalcResultsAction,
  setTravelInitialCalcDataAction,
  setTravelInitialGenDataAction
} from "../calcs/travel/ducks";
import {
  setRealtyCalcResultsAction,
  setRealtyInitialCalcDataAction,
  setRealtyInitialGenDataAction
} from "../calcs/realty/ducks";
import { sortAndGroupCalcResults } from "../calcs/utils";
import { sortAndGroupVehicleCalcResults } from "../calcs/vehicle/utils";
import { initPageResult } from "../../../common/utils/apiUtils";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'calc-record/FILTER',
  GET_REQUEST_RESPONSE = 'calc-record/GET_REQUEST_RESPONSE',
  GET_WEBSERVICES_LOGS = 'calc-record/GET_WEBSERVICES_LOGS',
  GET_STACK_TRACES_LOGS = 'calc-record/GET_STACK_TRACES_LOGS',
  GET_AND_REDIRECT = 'calc-record/GET_AND_REDIRECT',
  DELETE_STATE_LIST = 'calc-record/DELETE_STATE_LIST',
  DELETE_STATE_DETAIL = 'calc-record/DELETE_STATE_DETAIL'
}

/**
 * ACTIONS
 */
export const filterCalcRecordsActions = createApiActionCreators<CalcRecordFilterPageRequest, CalcRecordFilterPageResult>(actionType.FILTER);
export const getCalcRecordRequestResponseActions = createApiActionCreators<EntityIdRequest, CalcRecord>(actionType.GET_REQUEST_RESPONSE);
export const getCalcRecordWebservicesLogsActions = createApiActionCreators<EntityIdRequest, CalcRecord>(actionType.GET_WEBSERVICES_LOGS);
export const getCalcRecordStackTracesLogsActions = createApiActionCreators<EntityIdRequest, CalcRecord>(actionType.GET_STACK_TRACES_LOGS);
export const getCalcRecordAndRedirectToCalculatorActions = createApiActionCreators<EntityIdRequest, void>(actionType.GET_AND_REDIRECT);

export const deleteStateCalcRecordsPageAction = createActionCreator<void>(actionType.DELETE_STATE_LIST);
export const deleteStateCalcRecordDetailAction = createActionCreator<void>(actionType.DELETE_STATE_DETAIL);

/**
 * REDUCERS
 */
const initialState: CalcRecordReducerState = {
  currentPage: {
    ...initPageResult<CalcRecordList>(),
    operationType: null,
    calcType: null,
    personId: null
  },
  calcRecordDetail: null
};

const currentPageReducer = createReducer<CalcRecordFilterPageResult>(initialState.currentPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentPage
  },
  [actionType.DELETE_STATE_LIST]: () => initialState.currentPage
});

const calcRecordDetailReducer = createReducer<CalcRecord>(initialState.calcRecordDetail, {
  [actionType.GET_REQUEST_RESPONSE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.calcRecordDetail
  },
  [actionType.GET_WEBSERVICES_LOGS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.calcRecordDetail
  },
  [actionType.GET_STACK_TRACES_LOGS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.calcRecordDetail
  },
  [actionType.DELETE_STATE_DETAIL]: () => initialState.calcRecordDetail
});

export default combineReducers({ currentPage: currentPageReducer, calcRecordDetail: calcRecordDetailReducer });

/**
 * SELECTORS
 */
const selectCalcRecords = (state: RootState): CalcRecordReducerState => state.calculator.records;

export const selectCalcRecordsCurrentPage = (state: RootState): CalcRecordFilterPageResult => selectCalcRecords(state).currentPage;
export const selectCalcRecordDetail = (state: RootState): CalcRecord => selectCalcRecords(state).calcRecordDetail;

/**
 * SAGAS
 */
function* filterCalcRecords({ payload }: ActionCreator<CalcRecordFilterPageRequest>) {
  try {
    const response: AxiosResponse<CalcRecordFilterPageResult> = yield call(api.filterCalcRecords, payload);
    yield put(filterCalcRecordsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterCalcRecordsActions.failure(error));
  }
}

function* getCalcRecordRequestResponse({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordRequestResponse, payload);
    yield put(getCalcRecordRequestResponseActions.success(response.data));
  }
  catch ( error ) {
    yield put(getCalcRecordRequestResponseActions.failure(error));
  }
}

function* getCalcRecordWebservicesLogs({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordWebservicesLogs, payload);
    yield put(getCalcRecordWebservicesLogsActions.success(response.data));
  }
  catch ( error ) {
    yield put(getCalcRecordWebservicesLogsActions.failure(error));
  }
}

function* getCalcRecordStackTracesLogs({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordStackTracesLogs, payload);
    yield put(getCalcRecordStackTracesLogsActions.success(response.data));
  }
  catch ( error ) {
    yield put(getCalcRecordStackTracesLogsActions.failure(error));
  }
}

function* getCalcRecordAndRedirectToCalculator({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<CalcRecord> = yield call(api.getCalcRecordRequestResponse, payload);
    yield put(getCalcRecordAndRedirectToCalculatorActions.success());

    switch ( response.data.calcType ) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        if ( response.data.operationType === OperationType.CALCULATE ) {
          yield put(setVehicleInitialCalcDataAction(response.data.request as VehicleCalc));
          yield put(setVehicleCalcResultsAction(sortAndGroupVehicleCalcResults(response.data.response as CalcResponse<VehicleCalcResultData>)));
        }
        else {
          yield put(setVehicleInitialGenDataAction(response.data.request as VehicleGen));
        }
        yield put(push("/calc/vehicle"));
        break;
      case CalcType.TRAVEL:
        if ( response.data.operationType === OperationType.CALCULATE ) {
          yield put(setTravelInitialCalcDataAction(response.data.request as TravelCalc));
          yield put(setTravelCalcResultsAction(sortAndGroupCalcResults<TravelCalcResultData>(response.data.response as CalcResponse<TravelCalcResultData>)));
        }
        else {
          yield put(setTravelInitialGenDataAction(response.data.request as TravelGen));
        }
        yield put(push("/calc/travel"));
        break;
      case CalcType.REALTY:
        if ( response.data.operationType === OperationType.CALCULATE ) {
          yield put(setRealtyInitialCalcDataAction(response.data.request as RealtyCalc));
          yield put(setRealtyCalcResultsAction(sortAndGroupCalcResults<RealtyCalcResultData>(response.data.response as CalcResponse<RealtyCalcResultData>)));
        }
        else {
          yield put(setRealtyInitialGenDataAction(response.data.request as RealtyGen));
        }
        yield put(push("/calc/realty"));
        break;
    }
  }
  catch ( error ) {
    yield put(getCalcRecordAndRedirectToCalculatorActions.failure(error));
  }
}

export function* calcRecordsSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterCalcRecords);
  yield takeLatest(createActionType(actionType.GET_REQUEST_RESPONSE, apiOperation.REQUEST), getCalcRecordRequestResponse);
  yield takeLatest(createActionType(actionType.GET_WEBSERVICES_LOGS, apiOperation.REQUEST), getCalcRecordWebservicesLogs);
  yield takeLatest(createActionType(actionType.GET_STACK_TRACES_LOGS, apiOperation.REQUEST), getCalcRecordStackTracesLogs);
  yield takeLatest(createActionType(actionType.GET_AND_REDIRECT, apiOperation.REQUEST), getCalcRecordAndRedirectToCalculator);
}
