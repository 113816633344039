import React from "react";
import { Route, Switch } from "react-router";
import RouteWithPermission from "../../../common/security/authorization/RouteWithPermission";
import { regexPatterns } from "../../../common/utils/validationUtils";
import { Permission } from "../../../common/security/authorization/enums";

import InstitutionListContainer from "./containers/InstitutionListContainer";
import InstitutionCreateContainer from "./containers/InstitutionCreateContainer";
import InstitutionDetailContainer from "./containers/InstitutionDetailContainer";
import NotFound from "../../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <RouteWithPermission exact path="/admin/institutions"
                         component={InstitutionListContainer}
                         permissions={[Permission.ADMIN_ENUMERATIONS]} />

    <RouteWithPermission exact path="/admin/institutions/new"
                         component={InstitutionCreateContainer}
                         permissions={[Permission.ADMIN_ENUMERATIONS]} />

    <RouteWithPermission exact path={`/admin/institutions/:id(${regexPatterns.uuidRegex.source})`}
                         component={InstitutionDetailContainer}
                         permissions={[Permission.ADMIN_ENUMERATIONS]} />

    <Route component={NotFound} />
  </Switch>
);
