import React from "react";
import { Tag } from "antd";
import t from "../../../app/i18n";
import { ContractReactionStatus } from "../enums";

export interface Props {
  reactionStatus: ContractReactionStatus;
}

const ContractReactionStatusTag = ({ reactionStatus }: Props) => {
  let color: string;

  switch ( reactionStatus ) {
    case ContractReactionStatus.CONTACT_CLIENT:
      color = "cyan";
      break;
    case ContractReactionStatus.CONTRACT_IS_CONTINUED:
      color = "green";
      break;
    case ContractReactionStatus.SIGN_NEW_CONTRACT:
      color = "red";
      break;
    case ContractReactionStatus.SIGNED_CONTRACT:
      color = "geekblue";
      break;
    case ContractReactionStatus.CONTRACT_IS_NOT_CONTINUED:
      color = "orange";
      break;
    case ContractReactionStatus.CANCEL_CONTRACT:
      color = "purple";
      break
    default:
      return null;
  }

  return <Tag color={color}>{t("contract.enums.reactionStatus." + reactionStatus)}</Tag>;
};

export default ContractReactionStatusTag;