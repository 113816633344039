import { useEffect } from "react";
import { useSelector } from "react-redux";
import { LocationKey } from "history";
import { ApiRequest, RootState } from "../types";
import { selectIsRequestInProgress, selectRouterLocationKey } from "../../modules/ducks";

export const useRequestFinishedCallback = (request: ApiRequest, callback: () => void): boolean => {

  const locationKey = useSelector<RootState, LocationKey>(selectRouterLocationKey);
  const inProgress = useSelector<RootState, boolean>(state => selectIsRequestInProgress(state, request));

  useEffect(() => callback(), [locationKey]);  // eslint-disable-line react-hooks/exhaustive-deps

  return inProgress;
};
