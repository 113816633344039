import React from "react";
import { Checkbox, Col, Divider, Input, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { BuildingState } from "../../../enums";
import { InstitutionEnum } from "../../../../../../admin/institution/enums";
import { ClientExperience } from "../../../../../../contract/enums";
import { ClientType } from "../../../../../../client/enums";
import validations, { regexPatterns } from "../../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

export interface Props {
  form: WrappedFormUtils;
  initialAnotherInsuranceCompanyName: string;
  buildingState: BuildingState;
  insurerType: ClientType;
  selectedInstitutionEnum: InstitutionEnum;
}

interface State {
  readonly anotherInsuranceCompanyNameEnabled: boolean;
}

class RealtyGenAdditionalDataSection extends React.Component<Props, State> {
  readonly state: State = {
    anotherInsuranceCompanyNameEnabled: !!this.props.initialAnotherInsuranceCompanyName && this.props.selectedInstitutionEnum === InstitutionEnum.ALLIANZ
  };

  handleAnotherInsuranceCompanyNameEnabledChange = (event: CheckboxChangeEvent): void => {
    this.setState({ anotherInsuranceCompanyNameEnabled: event.target.checked });
  };

  render(): React.ReactNode {
    const { anotherInsuranceCompanyNameEnabled } = this.state;
    const { buildingState, selectedInstitutionEnum, insurerType } = this.props;
    const { getFieldDecorator } = this.props.form;

    const colSpan = 8;
    const fourColSpan = 6;

    const axaSelected = selectedInstitutionEnum === InstitutionEnum.AXA_NON_LIFE;
    const allianzSelected = selectedInstitutionEnum === InstitutionEnum.ALLIANZ;
    const generaliSelected = selectedInstitutionEnum === InstitutionEnum.GENERALI;

    return (
      <>
        {axaSelected || allianzSelected || generaliSelected ? (
          <>
            <Divider>{t("calc.realty.sections.additionalData")}</Divider>

            <Row gutter={rowGutter}>
              {axaSelected || generaliSelected ? (
                <Col span={colSpan}>
                  <Form.Item label={t("calc.realty.attrs.generalInsuranceData.signCity")}>
                    {getFieldDecorator("generalInsuranceData.signCity", {
                      rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
                    })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              ) : null}

              {axaSelected && buildingState === BuildingState.UNDER_CONSTRUCTION && (
                <Col span={colSpan}>
                  <Form.Item label={t("calc.realty.attrs.generalBuildingData.buildingPermitNumber")}>
                    {getFieldDecorator("generalBuildingData.buildingPermitNumber", { rules: [validations.notBlank] })(
                      <Input />
                    )}
                  </Form.Item>
                </Col>
              )}

              {allianzSelected && (
                <>
                  <Col span={colSpan}>
                    <Form.Item className="form-item-without-label">
                      <Checkbox
                        checked={anotherInsuranceCompanyNameEnabled}
                        onChange={this.handleAnotherInsuranceCompanyNameEnabledChange}>
                        {t("calc.realty.attrs.generalInsuranceData.anotherInsuranceCompanyNameLabel")}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {anotherInsuranceCompanyNameEnabled && (
                    <Col span={colSpan}>
                      <Form.Item label={t("calc.realty.attrs.generalInsuranceData.anotherInsuranceCompanyName")}>
                        {getFieldDecorator("generalInsuranceData.anotherInsuranceCompanyName", { rules: [validations.notBlank] })(
                          <Input />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                </>
              )}
            </Row>
          </>
        ) : null}

        <Row gutter={rowGutter}>
          <Divider>{t("calc.realty.sections.informationObligation")}</Divider>

          <Col span={fourColSpan}>
            <Form.Item label={t("contract.enums.clientExperience._label")}>
              {getFieldDecorator("informationObligationData.clientExperience", {
                rules: [validations.notNull],
                initialValue: insurerType === ClientType.SELF_EMPLOYED || insurerType === ClientType.LEGAL
                  ? ClientExperience.SUFFICIENT : undefined
              })(
                <Select
                  {...selectStandardProps}
                  disabled={insurerType === ClientType.SELF_EMPLOYED || insurerType === ClientType.LEGAL}
                  options={Object.keys(ClientExperience).map(experience => ({
                    value: experience,
                    label: t("contract.enums.clientExperience." + experience)
                  }))} />
              )}
            </Form.Item>
          </Col>

          <Col span={fourColSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("informationObligationData.ownedByClient", {
                rules: [validations.none], valuePropName: "checked", initialValue: false
              })(
                <Checkbox>{t("calc.realty.attrs.informationObligationData.ownedByClient")}</Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col span={fourColSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("informationObligationData.rented", {
                rules: [validations.none], valuePropName: "checked", initialValue: false
              })(
                <Checkbox>{t("calc.realty.attrs.informationObligationData.rented")}</Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col span={fourColSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("informationObligationData.currentlyUnderReconstruction", {
                rules: [validations.none], valuePropName: "checked", initialValue: false
              })(
                <Checkbox>{t("calc.realty.attrs.informationObligationData.currentlyUnderReconstruction")}</Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={fourColSpan} className="form-item-without-label">
            {t("calc.realty.sections.informationObligationBusiness")}
          </Col>

          <Col span={fourColSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("informationObligationData.underFiftyPercentBusiness", {
                rules: [validations.none], valuePropName: "checked", initialValue: false
              })(
                <Checkbox>{t("calc.realty.attrs.informationObligationData.underFiftyPercentBusiness")}</Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col span={fourColSpan * 2}>
            <Form.Item label={t("calc.realty.attrs.informationObligationData.businessDesc")}>
              {getFieldDecorator("informationObligationData.businessDesc", { rules: [validations.none] })(
                <Input />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.informationObligationData.otherRequirements")}>
              {getFieldDecorator("informationObligationData.otherRequirements", { rules: [validations.none] })(
                <Input />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.informationObligationData.otherRecommendationReasons")}>
              {getFieldDecorator("informationObligationData.otherRecommendationReasons", { rules: [validations.none] })(
                <Input />
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.informationObligationData.recommendedInsuranceRejectionReason")}>
              {getFieldDecorator("informationObligationData.recommendedInsuranceRejectionReason", { rules: [validations.none] })(
                <Input />
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
}

export default RealtyGenAdditionalDataSection;
