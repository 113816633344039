import React from "react";
import { Button, Col, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { EditOutlined, KeyOutlined } from "@ant-design/icons";
import { Account } from "../../account/types";
import { Contact } from "../../../common/modules/types";
import { ValidationErrorResponse } from "../../types";
import { updateMyIdentityActions } from "../ducks";
import { deleteStateValidationErrorResponseAction } from "../../ducks";
import t from "../../../app/i18n";

import MyIdentityUpdateAccountForm from "./MyIdentityUpdateAccountForm";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";

export interface Props {
  account: Account;
  userContacts: Contact[];
  updateAccountErrorResponse: ValidationErrorResponse;
  updateAccountRequestInProgress: boolean;
  locationKey: string;
  onUpdateAccountFormSubmit: typeof updateMyIdentityActions.request;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
}

interface State {
  readonly updateAccountFormVisible: boolean;
  readonly changePasswordFormVisible: boolean;
}

class MyIdentityAccountView extends React.Component<Props, State> {
  readonly state: State = {
    updateAccountFormVisible: false,
    changePasswordFormVisible: false
  };

  handleUpdateAccountClick = (): void => {
    this.setState({ updateAccountFormVisible: true });
  };

  handleChangePasswordClick = (): void => {
    this.setState({ changePasswordFormVisible: true });
  };

  handleUpdateFormCancelClick = (): void => {
    this.setState({ updateAccountFormVisible: false, changePasswordFormVisible: false });
  };

  render(): React.ReactNode {
    const { account } = this.props;

    const layout: ColProps = { span: 24, style: { maxWidth: "900px" } };

    return (
      <>
        <ItemCreatedUpdatedInfoView item={account} className="margin-bottom-medium" />

        <Row>
          <Col {...layout}>
            <table className="data-table-view">
              <tbody>
              <tr>
                <td>{t("account.attrs.username")}</td>
                <td>{account.username}</td>
              </tr>

              <tr>
                <td>{t("account.attrs.name")}</td>
                <td>{account.name}</td>
              </tr>

              <tr>
                <td>{t("account.attrs.email")}</td>
                <td>{account.email}</td>
              </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <div className="margin-top-large">
          <Button type="primary" icon={<EditOutlined />} className="margin-right-tiny"
                  onClick={this.handleUpdateAccountClick}>
            {t("myIdentity.actions.updateAccount")}
          </Button>
          <Button icon={<KeyOutlined />} onClick={this.handleChangePasswordClick} className="blue-button">
            {t("myIdentity.actions.changePassword")}
          </Button>
        </div>

        <MyIdentityUpdateAccountForm
          updateAccountFormVisible={this.state.updateAccountFormVisible}
          changePasswordFormVisible={this.state.changePasswordFormVisible}
          account={account}
          contacts={this.props.userContacts}
          errorResponse={this.props.updateAccountErrorResponse}
          inProgress={this.props.updateAccountRequestInProgress}
          locationKey={this.props.locationKey}
          onFormSubmit={this.props.onUpdateAccountFormSubmit}
          onErrorResponseDelete={this.props.onErrorResponseDelete}
          onFormCancel={this.handleUpdateFormCancelClick} />
      </>
    );
  }
}

export default MyIdentityAccountView;
