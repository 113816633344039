import React from "react";
import { Checkbox } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import validations from "../../../utils/validationUtils";

export interface Props {
  form: WrappedFormUtils;
  formKey: string;
  initialValue: boolean;
}

const HiddenCheckbox_Deprecated = ({ form, formKey, initialValue }: Props) => (
  <Form.Item className="display-none">
    {form.getFieldDecorator(formKey, {
      rules: [validations.none], valuePropName: "checked", initialValue
    })(
      <Checkbox />
    )}
  </Form.Item>
);

export default HiddenCheckbox_Deprecated;
