import React from "react";
import { Tag } from "antd";
import { JobState } from "../enums";
import t from "../../../../app/i18n";

export interface Props {
  state: JobState;
}

const JobStateTag = ({ state }: Props) => {
  let color: string;

  switch ( state ) {
    case JobState.MANUALLY_SCHEDULED:
      color = "blue";
      break;
    case JobState.IN_PROGRESS:
      color = "purple";
      break;
    case JobState.COMPLETED:
      color = "green";
      break;
    case JobState.CANCELLED:
      color = "orange";
      break;
    case JobState.ABORTED:
      color = "volcano";
      break;
    case JobState.TERMINATED_BY_ERROR:
      color = "red";
      break;
    default:
      return null;
  }

  return <Tag color={color}>{t("admin.job.enums.jobState." + state)}</Tag>
};

export default JobStateTag;