import { AxiosPromise } from "axios";
import apiService from "../../common/api/apiService";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { EntityIdRequest, EntityObjectRequest, TwoLevelEntityObjectRequest } from "../../common/types";
import {
  Account,
  AdminCreatePersonAccount,
  AdminPersonAccount,
  AdminUpdatePersonAccount,
  ConfirmAccount,
  PasswordReset,
  RequestPasswordReset
} from "./types";

const GET_PERSON_ACCOUNTS = ApiRequestAdapter.create("/persons/{0}/accounts", "GET");
const CREATE_PERSON_ACCOUNT = ApiRequestAdapter.create("/persons/{0}/accounts", "POST");
const UPDATE_PERSON_ACCOUNT = ApiRequestAdapter.create("/persons/{0}/accounts/{1}", "PUT");
const CONFIRM_ACCOUNT = ApiRequestAdapter.create("/accounts/{0}/confirm", "POST");
const REQUEST_PASSWORD_RESET = ApiRequestAdapter.create("/accounts/request-password-reset", "POST");
const RESET_PASSWORD = ApiRequestAdapter.create("/accounts/{0}/password-reset", "POST");

export const requests = {
  GET_PERSON_ACCOUNTS,
  CREATE_PERSON_ACCOUNT,
  UPDATE_PERSON_ACCOUNT,
  CONFIRM_ACCOUNT,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD
};

export default {
  getPersonAccounts: (request: EntityIdRequest): AxiosPromise<AdminPersonAccount[]> => {
    return apiService.get<AdminPersonAccount[]>(GET_PERSON_ACCOUNTS.getParametrizedUrl(request.id));
  },
  createPersonAccount: (request: EntityObjectRequest<AdminCreatePersonAccount>): AxiosPromise<AdminPersonAccount> => {
    return apiService.post<AdminPersonAccount>(CREATE_PERSON_ACCOUNT.getParametrizedUrl(request.id), request.object);
  },
  updatePersonAccount: (request: TwoLevelEntityObjectRequest<AdminUpdatePersonAccount>): AxiosPromise<AdminPersonAccount> => {
    return apiService.put<AdminPersonAccount>(UPDATE_PERSON_ACCOUNT.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  confirmAccount: (request: EntityObjectRequest<ConfirmAccount>): AxiosPromise<Account> => {
    return apiService.post<Account>(CONFIRM_ACCOUNT.getParametrizedUrl(request.id), request.object);
  },
  requestPasswordReset: (request: RequestPasswordReset): AxiosPromise<void> => {
    return apiService.post<void>(REQUEST_PASSWORD_RESET.url, request);
  },
  resetPassword: (request: EntityObjectRequest<PasswordReset>): AxiosPromise<void> => {
    return apiService.post<void>(RESET_PASSWORD.getParametrizedUrl(request.id), request.object);
  }
}
