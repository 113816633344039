import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { InstitutionWithComplicities } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { selectComplicitiesClassificationEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  institutionId: UUID;
}

const ComplicityFormItemSelect = ({ formItemProps, selectProps, institutionId }: Props) => {

  const institutionsWithComplicities = useSelector<RootState, InstitutionWithComplicities[]>(selectComplicitiesClassificationEnumerations);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={(institutionsWithComplicities
          .find(institution => institution.id === institutionId)?.complicities || [])
          .map(complicity => ({ value: complicity, label: complicity }))} />
    </Form.Item>
  )
}

export default ComplicityFormItemSelect;
