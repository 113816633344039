import qs from "qs";
import { AutocompleteResult, ErrorResponse, PageResult, SearchPageResult } from "../types";
import { isNotEmptyArray } from "./utils";
import messageUtils from "./messageUtils";
import t from "../../app/i18n";

export const serializeParams = (params: any): string => {
  return qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
};

export const initPageResult = <T = any>(): PageResult<T> => ({
  pageData: [],
  pageIndex: 0,
  pageSize: 0,
  totalElementsCount: 0,
  pageElementsCount: 0,
  pagesCount: 0,
  isFirst: true,
  isLast: true
});

export const initNonEmptyPageResult = <T = any>(pageData: T[], pageSize: number): PageResult<T> => {
  if ( !isNotEmptyArray(pageData) ) {
    return initPageResult<T>();
  }

  // @ts-ignore
  let pagesCount = parseInt(pageData.length / pageSize);
  pagesCount = pageData.length % pageSize > 0 ? pagesCount + 1 : pagesCount;

  return {
    pageData,
    pageIndex: 0,
    pageSize,
    totalElementsCount: pageData.length,
    pageElementsCount: Math.min(pageData.length, pageSize),
    pagesCount,
    isFirst: true,
    isLast: pagesCount === 1
  };
};

export const initSearchPageResult = <T = any>(): SearchPageResult<T> => ({
  ...initPageResult<T>(),
  keyword: null
});

export const initAutocompleteResult = <T = any>(): AutocompleteResult<T> => ({
  data: [],
  maxResultSize: 0,
  actualResultSize: 0,
  keyword: null
});

export const showErrorResponseNotification = (errorResponse: ErrorResponse): void => {
  if ( errorResponse.errors && errorResponse.errors.length > 0 ) {
    messageUtils.errorNotification(errorResponse.message, errorResponse.errors.join(" "));
  }
  else {
    messageUtils.errorNotification(t("common.error"), errorResponse.message);
  }
};
