import React from "react";
import { Space } from "antd";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import t from "../../../../../../../../app/i18n";

import AddDeleteButton from "../../../../../../../../common/components/buttons/AddDeleteButton";
import RealtyCalcFloorAreasSectionItem from "./RealtyCalcFloorAreasSectionItem";

export interface Props {
  form: WrappedFormUtils;
  initialFloorAreasSize: number;
  buildArea: number;
}

interface State {
  readonly areaItemsSize: number;
}

const ITEMS_IN_ROW = 3;

class RealtyCalcFloorAreasSection extends React.Component<Props, State> {
  readonly state: State = {
    areaItemsSize: this.props.initialFloorAreasSize || 1
  };

  handleAreaItemAdd = (): void => {
    this.setState(previousState => ({ areaItemsSize: previousState.areaItemsSize + 1 }));
  };

  handleAreaItemDelete = (): void => {
    this.setState(previousState => ({ areaItemsSize: previousState.areaItemsSize - 1 }));
  };

  render(): React.ReactNode {
    const rowsCount = Math.floor(this.state.areaItemsSize / ITEMS_IN_ROW);
    const lastRowItemsCount = this.state.areaItemsSize % ITEMS_IN_ROW;

    const areaItems: React.ReactNode[] = [];
    for ( let rowIndex = 0; rowIndex < rowsCount; rowIndex++ ) {
      areaItems.push(
        <RealtyCalcFloorAreasSectionItem
          key={rowIndex}
          form={this.props.form}
          buildArea={this.props.buildArea}
          itemsInRow={ITEMS_IN_ROW}
          startIndex={rowIndex * ITEMS_IN_ROW} />)
    }

    if ( lastRowItemsCount ) {
      areaItems.push(
        <RealtyCalcFloorAreasSectionItem
          key={rowsCount}
          form={this.props.form}
          buildArea={this.props.buildArea}
          itemsInRow={lastRowItemsCount}
          startIndex={rowsCount * ITEMS_IN_ROW} />)
    }

    return (
      <>
        {areaItems}

        <div className="margin-bottom-medium">
          <Space>

            <AddDeleteButton type="add" label={t("calc.realty.actions.overgroundAreaAdd")}
                             disabled={this.state.areaItemsSize >= 3}
                             onClick={this.handleAreaItemAdd} />

            <AddDeleteButton type="delete" label={t("calc.realty.actions.overgroundAreaDelete")}
                             disabled={this.state.areaItemsSize <= 1}
                             onClick={this.handleAreaItemDelete} />

          </Space>
        </div>
      </>
    )
  }
}

export default RealtyCalcFloorAreasSection;
