import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import api from "./api";
import {
  CommissionSourceTemplate,
  Enumerations,
  EnumerationsReducerState,
  InstitutionWithComplicities,
  InstitutionWithCoverageLimits,
  InstitutionWithProducts,
  ProductGroupWithProducts,
  ProductWithLifeInsuranceTariffs,
  VehicleBrandWithModels
} from "./types";
import { RootState } from "../../common/types";
import { PersonBase } from "../person/types";
import { CertificateVehicleColor } from "../contract/types";
import { apiOperation, createActionType, createApiActionCreators, createReducer } from "../../common/utils/reduxUtils";

export const VEHICLE_OTHER_MAPPING_NAME = "Ostatné";

/**
 * ACTION TYPES
 */
export enum actionType {
  GET = 'enumerations/GET'
}

/**
 * ACTIONS
 */
export const getEnumerationsActions = createApiActionCreators<void, Enumerations>(actionType.GET);

/**
 * REDUCERS
 */
const initialState: EnumerationsReducerState = {
  common: {
    initialized: false,
    persons: [],
    productGroups: [],
    productsClassification: [],
    vehicleBrands: [],
    vehicleColors: [],
    coverageLimitsClassification: [],
    complicitiesClassification: [],
    tariffsClassification: [],
    commissionSourceTemplates: []
  }
};

const commonEnumerationsReducer = createReducer<Enumerations>(initialState.common, {
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => ({ initialized: true, ...initialState.common })
  }
});

export default combineReducers({ common: commonEnumerationsReducer });

/**
 * SELECTORS
 */
const selectStateEnumerations = (state: RootState): EnumerationsReducerState => state.enumerations;

export const selectEnumerations = (state: RootState): Enumerations => selectStateEnumerations(state).common;
export const selectEnumerationsInitialized = (state: RootState): boolean => selectEnumerations(state).initialized;
export const selectPersonsEnumerations = (state: RootState): PersonBase[] => selectEnumerations(state).persons;
export const selectProductGroups = (state: RootState): ProductGroupWithProducts[] => selectEnumerations(state).productGroups;
export const selectProductsClassificationEnumerations = (state: RootState): InstitutionWithProducts[] => selectEnumerations(state).productsClassification;
export const selectVehicleBrandsEnumerations = (state: RootState): VehicleBrandWithModels[] => selectEnumerations(state).vehicleBrands;
export const selectVehicleColorsEnumerations = (state: RootState): CertificateVehicleColor[] => selectEnumerations(state).vehicleColors;
export const selectCoverageLimitsClassificationEnumerations = (state: RootState): InstitutionWithCoverageLimits[] => selectEnumerations(state).coverageLimitsClassification;
export const selectComplicitiesClassificationEnumerations = (state: RootState): InstitutionWithComplicities[] => selectEnumerations(state).complicitiesClassification;
export const selectTariffsClassificationEnumerations = (state: RootState): ProductWithLifeInsuranceTariffs[] => selectEnumerations(state).tariffsClassification;
export const selectCommissionSourceTemplates = (state: RootState): CommissionSourceTemplate[] => selectEnumerations(state).commissionSourceTemplates;

export const selectVehicleOtherBrandsIdsEnumerations = createSelector<RootState, VehicleBrandWithModels[], string[]>(
  selectVehicleBrandsEnumerations,
  brands => brands.filter(brand => brand.name === VEHICLE_OTHER_MAPPING_NAME)
    .flatMap(brand => brand.id));

export const selectVehicleOtherModelsIdsEnumerations = createSelector<RootState, VehicleBrandWithModels[], string[]>(
  selectVehicleBrandsEnumerations,
  brands => brands.flatMap(brand => brand.models)
    .filter(model => model.name === VEHICLE_OTHER_MAPPING_NAME)
    .flatMap(model => model.id));

/**
 * SAGAS
 */
function* getEnumerations() {
  try {
    const response: AxiosResponse<Enumerations> = yield call(api.getEnumerations);
    yield put(getEnumerationsActions.success({ initialized: true, ...response.data }));
  }
  catch ( error ) {
    yield put(getEnumerationsActions.failure(error));
  }
}

export function* enumerationsSaga() {
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getEnumerations);
}
