import React from "react";
import isEqual from "lodash/isEqual";
import { Moment } from "moment";
import { Button, Col, Modal, Radio, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CaretRightOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import {
  VehicleCalc,
  VehicleCalcClientsData,
  VehicleCalcForm,
  VehicleCalcFormClientsData,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleCalcType,
  VehicleOfferCalcType
} from "../../types";
import { ValidationErrorResponse } from "../../../../../types";
import { FieldConstraintViolation, UUID } from "../../../../../../common/types";
import { Client, ClientsAutocompleteProps } from "../../../../../client/types";
import { CalcDataSource, CalcResult } from "../../../types";
import { VehicleAutocompleteProps, VehiclePriceRequestProps } from "../../../../vehicles/types";
import { VehicleInsurerRelation, VehicleOwnerRelation } from "../../enums";
import { CalcType } from "../../../../enums";
import { ClientFormType, ClientType } from "../../../../../client/enums";
import { VehicleCategory } from "../../../../../contract/enums";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { deleteStateVehicleCalcResultsAction } from "../../ducks";
import { validateClientActions } from "../../../../../client/ducks";
import { deleteStateValidationErrorResponseAction } from "../../../../../ducks";
import { downloadCardReaderActions } from "../../../ducks";
import { processClientsDataViolations } from "../../../utils";
import { contains, containsAll, getOldCrmBaseUrl, removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import validations from "../../../../../../common/utils/validationUtils";
import { hasAnyVehicleResult, M1_N1, REINSURANCES_MAP, SPECS_MAP } from "../../utils";
import {
  clientToCreateUpdateContractClient,
  createUpdateContractClientToClient,
  resolveClientIdentifier
} from "../../../../../client/utils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { setErrorsToForm_deprecated } from "../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import VehicleCalcVehicleDataSection from "./sections/VehicleCalcVehicleDataSection";
import VehicleCalcClientsDataSection from "./sections/VehicleCalcClientsDataSection";
import VehicleCalcGeneralAndReinsurancesDataSection from "./sections/VehicleCalcGeneralAndReinsurancesDataSection";
import VehicleCalcNavigation from "./navigation/VehicleCalcNavigation";
import VehicleCalcResultsView from "../result/VehicleCalcResults";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";

export interface Props extends FormComponentProps<VehicleCalcForm> {
  calcData: VehicleCalc;
  calcDataSource: CalcDataSource;
  calcResults: VehicleCalcResults;
  holder: Client;
  vehicleAutocomplete: VehicleAutocompleteProps;
  vehiclePriceRequest: VehiclePriceRequestProps;
  clientsAutocomplete: ClientsAutocompleteProps;
  otherVehicleBrandsIds: UUID[];
  otherVehicleModelsIds: UUID[];
  vehicleCalcPermissions: Permission[];
  validateClientErrorResponse: ValidationErrorResponse;
  onValidateClient: typeof validateClientActions.request;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
  onCalcResultsDelete: typeof deleteStateVehicleCalcResultsAction;
  onDownloadCardReader: typeof downloadCardReaderActions.request;
  onCalculationFormSubmit(calcData: VehicleCalc): void;
  onGenerateContractClick(calcData: VehicleCalc, selectedResult: CalcResult<VehicleCalcResultData>): void;
  onGenerateOfferClick(type: VehicleOfferCalcType, calcData: VehicleCalc): void;
  onClientChange(client: Client, type: ClientFormType, callback?: () => void);
  onResetCalculatorClick(): void;
  onCalcDataSourceReset(): void;
}

interface State {
  readonly calcType: VehicleCalcType;
  readonly firstRegistrationDate: Moment;
  readonly category: VehicleCategory;
  readonly glass: boolean;
  readonly gap: boolean;
  readonly replacementVehicle: boolean;
  readonly resultsVisible: boolean;
  readonly clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
}

const HOLDER_INDEX_MAP = new Map<ClientFormType, number>([[ClientFormType.HOLDER, 0]]);

class VehicleCalcWrapper extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const stateObject = {
      calcType: (contains(props.vehicleCalcPermissions, Permission.MTPL_CALCULATOR) ? CalcType.MTPL : CalcType.CRASH) as VehicleCalcType,
      firstRegistrationDate: null,
      category: null,
      glass: false,
      gap: false,
      replacementVehicle: false,
      resultsVisible: false,
      clientsViolationErrors: new Map<ClientFormType, FieldConstraintViolation[]>()
    };

    if ( props.calcData ) {
      stateObject.calcType = props.calcData.type as VehicleCalcType;
      stateObject.firstRegistrationDate = props.calcData.vehicleData ? props.calcData.vehicleData.firstRegistrationDate : null;
      stateObject.category = props.calcData.vehicleData ? props.calcData.vehicleData.category : null;
      stateObject.glass = !!(props.calcData.reinsurancesData && props.calcData.reinsurancesData.glass);
      stateObject.gap = !!(props.calcData.reinsurancesData && props.calcData.reinsurancesData.gap);
      stateObject.replacementVehicle = !!(props.calcData.reinsurancesData && props.calcData.reinsurancesData.replacementVehicle);
    }

    this.state = stateObject;
  }

  handleReturnToOlcCalculatorClick = (): void => {
    window.open(getOldCrmBaseUrl() + "/assurecar", "_self");
  }

  handleInsuranceTypeChange = (e: RadioChangeEvent): void => {
    this.setState(previousState => ({
      calcType: e.target.value as VehicleCalcType,
      gap: e.target.value === CalcType.MTPL ? false : previousState.gap,
      replacementVehicle: e.target.value === CalcType.MTPL ? false : previousState.replacementVehicle
    }), () => {
      const { vehicleData } = this.props.form.getFieldsValue(["vehicleData"]) as VehicleCalcForm;
      if ( e.target.value as VehicleCalcType === CalcType.MTPL ) {
        this.props.form.setFieldsValue({
          "vehicleData.odometer": undefined,
          "vehicleData.repairLimit": undefined,
          "vehicleData.usedNotInsured": false,
          "vehicleData.price": vehicleData.newVehicle && contains(M1_N1, vehicleData.category) ? vehicleData.price : undefined,
          "vehicleData.timePrice": undefined,
          "vehicleData.buyingPrice": undefined,
          "clientsData.holderHasNeverBeenCrashInsured": false,
          "clientsData.holderYoungRiderComplicityDiscount": false,
          "reinsurancesData.gap": false,
          "reinsurancesData.replacementVehicle": false,
          "reinsurancesData.generaliAbroadVehicleRepair": false
        });
      }
      if ( e.target.value as VehicleCalcType === CalcType.CRASH ) {
        this.props.form.setFieldsValue({
          "generalData.crossSelling.uniqaRealtyContract": false,
          "reinsurancesData.animal": false,
          "reinsurancesData.element": false,
          "reinsurancesData.theftAndVandalism": false,
          "reinsurancesData.injury": false
        });
      }
    });
  };

  handleFirstRegistrationDateChange = (firstRegistrationDate: Moment): void => {
    this.setState({ firstRegistrationDate });
  };

  handleCategoryChange = (category: VehicleCategory): void => {
    this.setState(previousState => ({
      category,
      gap: contains(REINSURANCES_MAP.get("gap"), category) ? previousState.gap : false,
      replacementVehicle: contains(REINSURANCES_MAP.get("replacementVehicle"), category) ? previousState.replacementVehicle : false,
      glass: contains(REINSURANCES_MAP.get("glass"), category) ? previousState.glass : false,
    }), () => {
      const { type, vehicleData, reinsurancesData } = this.props.form.getFieldsValue(["type", "vehicleData", "reinsurancesData"]) as VehicleCalcForm;
      this.props.form.setFieldsValue({
        "vehicleData.fuelType": contains(SPECS_MAP.get("fuelType"), category) ? vehicleData.fuelType : undefined,
        "vehicleData.transmission": contains(SPECS_MAP.get("transmission"), category) ? vehicleData.transmission : undefined,
        "vehicleData.bodywork": contains(SPECS_MAP.get("bodywork"), category) ? vehicleData.bodywork : undefined,
        "vehicleData.seatsNumber": contains(SPECS_MAP.get("seatsNumber"), category) ? vehicleData.seatsNumber : undefined,
        "vehicleData.doorsNumber": contains(SPECS_MAP.get("doorsNumber"), category) ? vehicleData.doorsNumber : undefined,
        "vehicleData.engineDisplacement": contains(SPECS_MAP.get("engineDisplacement"), category) ? vehicleData.engineDisplacement : undefined,
        "vehicleData.enginePower": contains(SPECS_MAP.get("enginePower"), category) ? vehicleData.enginePower : undefined,
        "vehicleData.odometer": contains(SPECS_MAP.get("odometer"), category) ? vehicleData.odometer : undefined,
        "vehicleData.steeringWheelOnTheRight": contains(SPECS_MAP.get("steeringWheelOnTheRight"), category) ? vehicleData.steeringWheelOnTheRight : false,
        "vehicleData.price": type === CalcType.MTPL && !contains(M1_N1, category) ? undefined : vehicleData.price,
        "reinsurancesData.extendedAssistance": contains(REINSURANCES_MAP.get("extendedAssistance"), category) ? reinsurancesData.extendedAssistance : false,
        "reinsurancesData.glass": contains(REINSURANCES_MAP.get("glass"), category) ? reinsurancesData.glass : false,
        "reinsurancesData.animal": contains(REINSURANCES_MAP.get("animal"), category) ? reinsurancesData.animal : false,
        "reinsurancesData.element": contains(REINSURANCES_MAP.get("element"), category) ? reinsurancesData.element : false,
        "reinsurancesData.theftAndVandalism": contains(REINSURANCES_MAP.get("theftAndVandalism"), category) ? reinsurancesData.theftAndVandalism : false,
        "reinsurancesData.injury": contains(REINSURANCES_MAP.get("injury"), category) ? reinsurancesData.injury : false,
        "reinsurancesData.gap": contains(REINSURANCES_MAP.get("gap"), category) ? reinsurancesData.gap : false,
        "reinsurancesData.replacementVehicle": contains(REINSURANCES_MAP.get("replacementVehicle"), category) ? reinsurancesData.replacementVehicle : false,
        "reinsurancesData.generaliAbroadVehicleRepair": contains(REINSURANCES_MAP.get("generaliAbroadVehicleRepair"), category) ? reinsurancesData.generaliAbroadVehicleRepair : false
      });
    });
  };

  handleGlassChange = (event: CheckboxChangeEvent): void => {
    this.setState({ glass: event.target.checked });
  };

  handleGapChange = (event: CheckboxChangeEvent): void => {
    this.setState({ gap: event.target.checked });
  };

  handleReplacementVehicleChange = (event: CheckboxChangeEvent): void => {
    this.setState({ replacementVehicle: event.target.checked });
  };

  handleCalculationFormSubmit = (): void => {
    this.props.form.validateFieldsAndScroll((errors, values) => {
      if ( errors || this.checkHasClientsError() ) {
        messageUtils.errorNotification(t("common.error"), t("calc.vehicle.validations.formError"));
      }
      else {
        this.props.onCalculationFormSubmit(this.processAndGetCalcFormData(values));

        if ( this.props.calcDataSource !== "init" ) {
          this.props.onCalcDataSourceReset();
        }
      }
    })
  };

  handleResultsShow = (): void => {
    this.setState({ resultsVisible: true });
  };

  handleResultsClose = (): void => {
    this.setState({ resultsVisible: false });
  };

  handleHolderChange = (client: Client): void => {
    if ( hasAnyVehicleResult(this.props.calcResults) && !isEqual(this.props.holder, client) ) {
      this.props.onCalcResultsDelete();
    }

    this.props.onClientChange(client, ClientFormType.HOLDER, () => {
      if ( client ) {
        this.props.onValidateClient({
          prefix: `clientsData.clients[0]`,
          client: clientToCreateUpdateContractClient(client)
        });

        if ( client.type !== ClientType.NATURAL ) {
          const { clientsData, generalData } = this.props.form.getFieldsValue(["clientsData", "generalData"]) as VehicleCalcForm;

          if ( clientsData.holderChildrenUnder15Years || clientsData.holderIsDisabledPerson
            || generalData.crossSelling.unionHealthContract || generalData.crossSelling.uniqaRealtyContract ) {
            this.props.form.setFieldsValue({
              "clientsData.holderChildrenUnder15Years": false,
              "clientsData.holderIsDisabledPerson": false,
              "generalData.crossSelling.unionHealthContract": false,
              "generalData.crossSelling.uniqaRealtyContract": false
            });
          }

          if ( !clientsData.leasing && (clientsData.insurerRelation !== VehicleInsurerRelation.SAME_AS_HOLDER
            || clientsData.ownerRelation !== VehicleOwnerRelation.SAME_AS_HOLDER) ) {
            this.props.form.setFieldsValue({
              "clientsData.insurerRelation": VehicleInsurerRelation.SAME_AS_HOLDER,
              "clientsData.ownerRelation": VehicleOwnerRelation.SAME_AS_HOLDER
            })
          }
        }
      }
    })
  };

  handleHolderViolationErrorsDelete = (): void => {
    this.setState({ clientsViolationErrors: new Map<ClientFormType, FieldConstraintViolation[]>() })
  };

  handleResultsGenerateOfferClick = (type: VehicleOfferCalcType): void => {
    this.setState({ resultsVisible: false });
    if ( this.checkAreCalcFormDataUnchanged() ) {
      this.props.onGenerateOfferClick(type, this.processAndGetCalcFormData());
    }
  };

  handleResultsGenerateContractClick = (result: CalcResult<VehicleCalcResultData>): void => {
    this.setState({ resultsVisible: false });
    if ( this.checkAreCalcFormDataUnchanged() ) {
      const calcFormData = this.processAndGetCalcFormData();
      if ( !calcFormData.vehicleData.vin ) {
        messageUtils.errorNotification(
          t("error.api.status_422"),
          <FieldViolationsView violations={[{
            fieldPath: "vehicleData.vin",
            fieldValue: calcFormData.vehicleData.vin,
            errors: [t("validation.notNull")]
          }]} rootPath="calc.vehicle.attrs" />, 10);
      }
      else {
        this.props.onGenerateContractClick(calcFormData, result);
      }
    }
  };

  handleResultsShowErrorsClick = (result: CalcResult<VehicleCalcResultData>): void => {
    if ( result.error ) {
      setErrorsToForm_deprecated(this.props.form, result.error.violations, "calc.vehicle.attrs");
      this.setState({
        resultsVisible: false,
        clientsViolationErrors: processClientsDataViolations(result.error.violations, HOLDER_INDEX_MAP)
      });
    }
  };

  processClientsViolationErrorsStateUpdate = (errorResponse: ValidationErrorResponse): void => {
    this.setState({
      clientsViolationErrors: processClientsDataViolations(errorResponse.violations, HOLDER_INDEX_MAP)
    }, () => this.props.onErrorResponseDelete());
  };

  processAndGetCalcFormData = (calcData?: VehicleCalcForm): VehicleCalc => {
    const formValues = calcData ? { ...calcData } : this.props.form.getFieldsValue() as VehicleCalcForm;

    const clientsData: VehicleCalcClientsData & VehicleCalcFormClientsData = {
      ...formValues.clientsData,
      holderIndex: 0,
      clients: [clientToCreateUpdateContractClient(this.props.holder)]
    };
    delete clientsData.holderIdentifier;

    return {
      ...formValues,
      vehicleData: {
        ...formValues.vehicleData,
        licensePlate: removeStringWhiteSpaces(formValues.vehicleData.licensePlate)
      },
      clientsData: clientsData as VehicleCalcClientsData
    };
  };

  checkAreCalcFormDataUnchanged = (): boolean => {
    const calcDataUnchanged = isEqual(this.props.calcData, this.processAndGetCalcFormData());

    if ( !calcDataUnchanged ) {
      Modal.warning({
        title: t("common.warning"),
        content: t("calc.vehicle.validations.calcDataChanged"),
        okText: t("calc.vehicle.actions.recalculationSubmit"),
        maskClosable: true,
        onOk: this.handleCalculationFormSubmit,
        onCancel: this.handleCalculationFormSubmit
      });
    }

    if ( this.props.calcDataSource !== "init" ) {
      this.props.onCalcDataSourceReset();
    }

    return calcDataUnchanged
  };

  checkHasClientsError = (): boolean => {
    return !this.props.holder || this.state.clientsViolationErrors.has(ClientFormType.HOLDER);
  };

  resolvePageTitle = (): string => {
    return this.state.calcType
      ? t("calc.vehicle.titles.calculation." + this.state.calcType)
      : t("calc.vehicle.titles.noSelectedCalcType");
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( this.props.validateClientErrorResponse ) {
      this.processClientsViolationErrorsStateUpdate(this.props.validateClientErrorResponse);
    }
    if ( !hasAnyVehicleResult(prevProps.calcResults) && hasAnyVehicleResult(this.props.calcResults) ) {
      this.setState({ resultsVisible: true });
    }
  }

  componentDidMount(): void {
    const { calcData, form } = this.props;
    if ( calcData ) {
      const holder = createUpdateContractClientToClient(calcData.clientsData.clients[calcData.clientsData.holderIndex]);
      form.setFieldsValue({
        ...calcData,
        clientsData: {
          ...calcData.clientsData,
          clients: undefined,
          holderIndex: undefined,
          holderIdentifier: holder ? resolveClientIdentifier(holder) : undefined
        }
      } as VehicleCalc & VehicleCalcForm);
      this.props.onCalcDataSourceReset();
    }
  }

  render(): React.ReactNode {
    const { calcType, firstRegistrationDate, category } = this.state;
    const { form, calcData, calcResults, vehicleCalcPermissions, holder } = this.props;

    const allowedCalcTypes = containsAll(vehicleCalcPermissions, Permission.MTPL_CALCULATOR, Permission.CRASH_CALCULATOR)
      ? [CalcType.MTPL, CalcType.CRASH, CalcType.MTPL_CRASH]
      : [contains(vehicleCalcPermissions, Permission.MTPL_CALCULATOR) ? CalcType.MTPL : CalcType.CRASH];

    const formLayout: ColProps = { span: 24, style: { maxWidth: "1150px" } };

    return (
      <>
        <Row>
          <Col span={16}>
            <h2>{this.resolvePageTitle()}</h2>
          </Col>

          <Col span={8} className="right-align">
            <Button type="dashed" onClick={this.handleReturnToOlcCalculatorClick}>
              {t("calc.vehicle.actions.returnToOlcCalculator")} <CaretRightOutlined />
            </Button>
          </Col>
        </Row>

        <Row gutter={64} className="margin-top-small" justify="center">
          <Col {...formLayout}>
            <Form layout="vertical">

              <Row gutter={rowGutter} justify="center">
                <Col span={24} className="center-align">
                  <Form.Item>
                    {form.getFieldDecorator("type", {
                      rules: [validations.notNull], initialValue: calcType
                    })(
                      <Radio.Group size="large" buttonStyle="solid" onChange={this.handleInsuranceTypeChange}>
                        {allowedCalcTypes.map(type =>
                          <Radio.Button value={type} key={type}>{t("calc.enums.calcType." + type)}</Radio.Button>
                        )}
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <VehicleCalcVehicleDataSection
                form={form}
                initialVehicleData={calcData ? calcData.vehicleData : null}
                calcType={calcType}
                firstRegistrationDate={firstRegistrationDate}
                category={category}
                vehicleAutocomplete={this.props.vehicleAutocomplete}
                vehiclePriceRequest={this.props.vehiclePriceRequest}
                otherVehicleBrandsIds={this.props.otherVehicleBrandsIds}
                otherVehicleModelsIds={this.props.otherVehicleModelsIds}
                onFirstRegistrationDateChange={this.handleFirstRegistrationDateChange}
                onCategoryChange={this.handleCategoryChange}
                onDownloadCardReader={this.props.onDownloadCardReader} />

              <VehicleCalcClientsDataSection
                form={form}
                initialClientsData={calcData ? calcData.clientsData : null}
                calcType={calcType}
                holder={holder}
                clientsAutocomplete={this.props.clientsAutocomplete}
                clientsViolationErrors={this.state.clientsViolationErrors}
                onHolderChange={this.handleHolderChange}
                onHolderViolationErrorsDelete={this.handleHolderViolationErrorsDelete} />

              <VehicleCalcGeneralAndReinsurancesDataSection
                form={form}
                initialData={calcData}
                calcType={calcType}
                firstRegistrationDate={firstRegistrationDate}
                category={category}
                glass={this.state.glass}
                gap={this.state.gap}
                replacementVehicle={this.state.replacementVehicle}
                holder={holder}
                onGlassChange={this.handleGlassChange}
                onGapChange={this.handleGapChange}
                onReplacementVehicleChange={this.handleReplacementVehicleChange} />

            </Form>
          </Col>
        </Row>

        <VehicleCalcResultsView
          visible={this.state.resultsVisible}
          calcType={calcType}
          calcResults={calcResults}
          onClose={this.handleResultsClose}
          onGenerateOfferClick={this.handleResultsGenerateOfferClick}
          onGenerateContractClick={this.handleResultsGenerateContractClick}
          onShowErrorsClick={this.handleResultsShowErrorsClick} />

        <VehicleCalcNavigation
          hasResults={hasAnyVehicleResult(calcResults)}
          onFormSubmit={this.handleCalculationFormSubmit}
          onShowResultsClick={this.handleResultsShow}
          onResetCalculatorClick={this.props.onResetCalculatorClick} />
      </>
    );
  }
}

export default Form.create<Props>({
  onValuesChange: props => {
    if ( hasAnyVehicleResult(props.calcResults) && props.calcDataSource === "init" ) {
      props.onCalcResultsDelete();
    }
  }
})(VehicleCalcWrapper);
