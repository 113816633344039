import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "connected-react-router";
import api from "./api";
import { changeLocationKeyAction } from "../ducks";
import {
  Client,
  ClientAutocompleteRequest,
  ClientAutocompleteResult,
  ClientAutocompleteResultDto,
  ClientList,
  ClientReducerState,
  ClientSearchRequest,
  ClientSearchResult,
  CreateUpdateClient,
  ValidateContractClientRequest,
  ValidateContractClientsRequest
} from "./types";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  SearchPageRequest,
  SearchPageResult
} from "../../common/types";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../common/utils/reduxUtils";
import { initAutocompleteResult, initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  FILTER = 'client/FILTER',
  AUTOCOMPLETE = 'client/AUTOCOMPLETE',
  SEARCH = 'client/SEARCH',
  VALIDATE = 'client/VALIDATE',
  VALIDATE_ALL = 'client/VALIDATE_ALL',
  GET = 'client/GET',
  UPDATE = 'client/UPDATE',
  CREATE = 'client/CREATE',
  DELETE = 'client/DELETE',
  DELETE_STATE_LIST = 'client/DELETE_STATE_LIST',
  DELETE_STATE_AUTOCOMPLETE_RESULT = 'client/DELETE_STATE_AUTOCOMPLETE_RESULT',
  DELETE_STATE_SEARCH_RESULT = 'client/DELETE_STATE_SEARCH_RESULT',
  DELETE_STATE_DETAIL = 'client/DELETE_STATE_DETAIL'
}

/**
 * ACTIONS
 */
export const filterClientsActions = createApiActionCreators<SearchPageRequest, SearchPageResult<ClientList>>(actionType.FILTER);
export const autocompleteClientsActions = createApiActionCreators<ClientAutocompleteRequest, ClientAutocompleteResultDto>(actionType.AUTOCOMPLETE);
export const searchClientActions = createApiActionCreators<ClientSearchRequest, ClientSearchResult>(actionType.SEARCH);
export const validateClientActions = createApiActionCreators<ValidateContractClientRequest, void>(actionType.VALIDATE);
export const validateClientsActions = createApiActionCreators<ValidateContractClientsRequest, void>(actionType.VALIDATE_ALL);
export const getClientActions = createApiActionCreators<EntityIdRequest, Client>(actionType.GET);
export const updateClientActions = createApiActionCreators<EntityObjectRequest<CreateUpdateClient>, Client>(actionType.UPDATE);
export const createClientActions = createApiActionCreators<CreateUpdateClient, Client>(actionType.CREATE);
export const deleteClientActions = createApiActionCreators<EntityIdRequest, void>(actionType.DELETE);

export const deleteStateClientsPageAction = createActionCreator<void>(actionType.DELETE_STATE_LIST);
export const deleteStateClientsAutocompleteResultAction = createActionCreator<void>(actionType.DELETE_STATE_AUTOCOMPLETE_RESULT);
export const deleteStateClientSearchResultAction = createActionCreator<void>(actionType.DELETE_STATE_SEARCH_RESULT);
export const deleteStateClientDetailAction = createActionCreator<void>(actionType.DELETE_STATE_DETAIL);

/**
 * REDUCERS
 */
const initialState: ClientReducerState = {
  currentPage: initSearchPageResult<ClientList>(),
  autocompleteResult: {
    id: null,
    clientType: null,
    ...initAutocompleteResult<Client>()
  },
  searchResult: {
    keyword: null,
    clientType: null,
    data: null
  },
  clientDetail: null
};

const currentPageReducer = createReducer<SearchPageResult<ClientList>>(initialState.currentPage, {
  [actionType.FILTER]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.currentPage
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.currentPage
  },
  [actionType.DELETE_STATE_LIST]: () => initialState.currentPage
});

const autocompleteResultReducer = createReducer<ClientAutocompleteResult, ClientAutocompleteResultDto>(initialState.autocompleteResult, {
  [actionType.AUTOCOMPLETE]: {
    [apiOperation.SUCCESS]: (state, payload) => ({
      id: new Date().getTime(),
      ...payload
    }),
    [apiOperation.FAILURE]: () => initialState.autocompleteResult
  },
  [actionType.DELETE_STATE_AUTOCOMPLETE_RESULT]: () => initialState.autocompleteResult
});

const searchResultReducer = createReducer<ClientSearchRequest, ClientSearchResult>(initialState.searchResult, {
  [actionType.SEARCH]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.searchResult
  },
  [actionType.DELETE_STATE_SEARCH_RESULT]: () => initialState.searchResult
});

const clientDetailReducer = createReducer<Client>(initialState.clientDetail, {
  [actionType.GET]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.clientDetail
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.CREATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE]: {
    [apiOperation.SUCCESS]: () => initialState.clientDetail
  },
  [actionType.DELETE_STATE_DETAIL]: () => initialState.clientDetail
});

export default combineReducers({
  currentPage: currentPageReducer,
  autocompleteResult: autocompleteResultReducer,
  searchResult: searchResultReducer,
  clientDetail: clientDetailReducer
});

/**
 * SELECTORS
 */
const selectClient = (state: RootState): ClientReducerState => state.client;

export const selectClientsCurrentPage = (state: RootState): SearchPageResult<ClientList> => selectClient(state).currentPage;
export const selectClientsAutocompleteResult = (state: RootState): ClientAutocompleteResult => selectClient(state).autocompleteResult;
export const selectClientSearchResult = (state: RootState): ClientSearchResult => selectClient(state).searchResult;
export const selectClientDetail = (state: RootState): Client => selectClient(state).clientDetail;

/**
 * SAGAS
 */
function* filterClients({ payload }: ActionCreator<SearchPageRequest>) {
  try {
    const response: AxiosResponse<SearchPageResult<ClientList>> = yield call(api.filterClients, payload);
    yield put(filterClientsActions.success(response.data));
  }
  catch ( error ) {
    yield put(filterClientsActions.failure(error));
  }
}

function* autocompleteClients({ payload }: ActionCreator<ClientAutocompleteRequest>) {
  try {
    const response: AxiosResponse<ClientAutocompleteResultDto> = yield call(api.autocompleteClients, payload);
    yield put(autocompleteClientsActions.success(response.data));
  }
  catch ( error ) {
    yield put(autocompleteClientsActions.failure(error));
  }
}

function* searchClient({ payload }: ActionCreator<ClientSearchRequest>) {
  try {
    const response: AxiosResponse<ClientSearchResult> = yield call(api.searchClient, payload);
    yield put(searchClientActions.success(response.data));
  }
  catch ( error ) {
    yield put(searchClientActions.failure(error));
  }
}

function* validateClient({ payload }: ActionCreator<ValidateContractClientRequest>) {
  try {
    yield call(api.validateClient, payload);
    yield put(validateClientActions.success());
  }
  catch ( error ) {
    yield put(validateClientActions.failure(error));
  }
}

function* validateClients({ payload }: ActionCreator<ValidateContractClientsRequest>) {
  try {
    yield call(api.validateClients, payload);
    yield put(validateClientsActions.success());
  }
  catch ( error ) {
    yield put(validateClientsActions.failure(error));
  }
}

function* getClientDetail({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<Client> = yield call(api.getClient, payload);
    yield put(getClientActions.success(response.data));
  }
  catch ( error ) {
    yield put(getClientActions.failure(error));
  }
}

function* updateClient({ payload }: ActionCreator<EntityObjectRequest<CreateUpdateClient>>) {
  try {
    const response: AxiosResponse<Client> = yield call(api.updateClient, payload);
    yield put(updateClientActions.success(response.data));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateClientActions.failure(error));
  }
}

function* createClient({ payload }: ActionCreator<CreateUpdateClient>) {
  try {
    const response: AxiosResponse<Client> = yield call(api.createClient, payload);
    yield put(createClientActions.success(response.data));
    yield put(replace("/clients/" + response.data.id));
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createClientActions.failure(error));
  }
}

function* deleteClient({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    yield call(api.deleteClient, payload);
    yield put(deleteClientActions.success());
    yield put(replace("/clients"));
  }
  catch ( error ) {
    yield put(deleteClientActions.failure(error));
  }
}

export function* clientSaga() {
  yield takeLatest(createActionType(actionType.FILTER, apiOperation.REQUEST), filterClients);
  yield takeLatest(createActionType(actionType.AUTOCOMPLETE, apiOperation.REQUEST), autocompleteClients);
  yield takeLatest(createActionType(actionType.SEARCH, apiOperation.REQUEST), searchClient);
  yield takeLatest(createActionType(actionType.VALIDATE, apiOperation.REQUEST), validateClient);
  yield takeLatest(createActionType(actionType.VALIDATE_ALL, apiOperation.REQUEST), validateClients);
  yield takeLatest(createActionType(actionType.GET, apiOperation.REQUEST), getClientDetail);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateClient);
  yield takeLatest(createActionType(actionType.CREATE, apiOperation.REQUEST), createClient);
  yield takeLatest(createActionType(actionType.DELETE, apiOperation.REQUEST), deleteClient);
}
