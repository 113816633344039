import "./travel-calc-results.less";
import React from "react";
import { Affix, Button, Col, Divider, Drawer, Row } from "antd";
import { DoubleRightOutlined, FileTextOutlined } from "@ant-design/icons";
import { CalcResult } from "../../../types";
import { TravelCalcResultData } from "../../types";
import { InstitutionEnum } from "../../../../../admin/institution/enums";
import { TravelInsuranceType } from "../../enums";
import { filterApplicableResults } from "../../../utils";
import t from "../../../../../../app/i18n";

import TravelCalcResultItem from "./TravelCalcResultItem";

export interface Props {
  visible: boolean;
  insuranceType: TravelInsuranceType;
  calcResults: CalcResult<TravelCalcResultData>[][];
  selectedResult?: CalcResult<TravelCalcResultData>;
  onClose(): void;
  onGenerateOfferClick(): void;
  onGenerateContractClick(result: CalcResult<TravelCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<TravelCalcResultData>): void;
}

const TravelCalcResults = (props: Props) => (
  <Drawer
    title={t("calc.travel.sections.results")}
    width={800}
    visible={props.visible}
    closable
    onClose={props.onClose}>

    <div className="travel-calc-results-container">
      {props.calcResults
        .filter(resultsRow => filterApplicableResults(resultsRow).length > 0)
        .map((resultsRow, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <Row className="result-title">
              <Col span={24}>
                <h3>{resultsRow[0].insuranceInstitution.name}</h3>
              </Col>
            </Row>

            <Row>
              {filterApplicableResults(resultsRow).map((result, index) => {
                const resultSelected = props.selectedResult &&
                  props.selectedResult.insuranceInstitution.id === result.insuranceInstitution.id &&
                  props.selectedResult.coverage === result.coverage;

                return (
                  <Col span={12} key={index}
                       className={resultSelected ? "result-data result-selected" : "result-data"}>
                    <TravelCalcResultItem
                      result={result}
                      disabled={resultSelected
                      || (props.insuranceType === TravelInsuranceType.YEAR && result.insuranceInstitution.institutionEnum === InstitutionEnum.UNION)
                      || (props.insuranceType === TravelInsuranceType.CANCELLATION && result.insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ)}
                      onGenerateContractClick={props.onGenerateContractClick}
                      onShowErrorsClick={props.onShowErrorsClick} />
                  </Col>
                )
              })}
            </Row>

            {rowIndex < props.calcResults.length - 1 && (
              <Row>
                <Col span={24}><Divider /></Col>
              </Row>
            )}
          </React.Fragment>
        ))}
    </div>

    <Affix offsetBottom={0} className="travel-calc-results-navigation">
      <>
        <div className="actions-center">
          <Button type="primary" icon={<FileTextOutlined />} onClick={props.onGenerateOfferClick}>
            {t("calc.travel.actions.generateOffer")}
          </Button>
        </div>

        <div className="actions-right">
          <Button icon={<DoubleRightOutlined />} onClick={props.onClose}>{t("common.close")}</Button>
        </div>
      </>
    </Affix>

  </Drawer>
);

export default TravelCalcResults;
