import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { FilterFunction } from "../../types";
import { RootState } from "../../../../common/types";
import { InstitutionBase } from "../../../admin/institution/types";
import { InstitutionType } from "../../../admin/institution/enums";
import { selectProductsClassificationEnumerations } from "../../ducks";
import { contains } from "../../../../common/utils/utils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { formatInstitutionName } from "../../../../common/utils/formatUtils";
import t from "../../../../app/i18n";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: InstitutionOptionsProps;
}

export interface InstitutionOptionsProps {
  includeDeactivated?: boolean;
  selected?: InstitutionBase[];
  filterType?: InstitutionType;
  optGroups?: InstitutionOptGroupProps[];
  groupByType?: boolean;
  filter?: FilterFunction<InstitutionBase>;
}

export interface InstitutionOptGroupProps {
  label: string;
  filter: FilterFunction<InstitutionBase>;
}

const InstitutionsEnumFormItemSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {

  const institutionsEnumerations = useSelector<RootState, InstitutionBase[]>(selectProductsClassificationEnumerations);

  const resolveInstitutionSelectOptions = (types: InstitutionType[]): InstitutionBase[] => {
    let options = [...institutionsEnumerations].filter(institution => contains(types, institution.type));

    if ( optionsProps ) {
      if ( !optionsProps.includeDeactivated ) {
        options = options.filter(institution => !institution.deactivated);
      }
      if ( optionsProps.filter ) {
        options = options.filter(optionsProps.filter);
      }
      if ( optionsProps.selected ) {
        optionsProps.selected
          .filter(selectedInstitution => contains(types, selectedInstitution.type))
          .forEach(selectedInstitution => {
            if ( !options.some(option => option.id === selectedInstitution.id) ) {
              options.unshift(selectedInstitution);
            }
          });
      }
    }

    return options.sort((a, b) => a.name.localeCompare(b.name));
  };

  const resolveInstitutionSelectGroupOptions = (filterOptions: FilterFunction<InstitutionBase>): InstitutionBase[] => {
    let options = [...institutionsEnumerations];
    if ( !optionsProps?.includeDeactivated ) {
      options = options.filter(institution => !institution.deactivated);
    }
    return options.filter(filterOptions).sort((a, b) => a.name.localeCompare(b.name));
  };

  if ( optionsProps?.optGroups ) {
    return (
      <Form.Item {...formItemProps}>
        <Select {...selectStandardProps} {...selectProps}>
          {optionsProps.optGroups.map((group, index) =>
            <Select.OptGroup key={index} label={group.label}>
              {resolveInstitutionSelectGroupOptions(group.filter).map(i =>
                <Select.Option key={i.id} value={i.id}>{formatInstitutionName(i)}</Select.Option>)}
            </Select.OptGroup>
          )}
        </Select>
      </Form.Item>
    )
  }
  else if ( optionsProps?.groupByType ) {
    return (
      <Form.Item {...formItemProps}>
        <Select {...selectStandardProps} {...selectProps}>
          <Select.OptGroup label={t("admin.institution.helpers.insuranceInstitutions")}>
            {resolveInstitutionSelectOptions([InstitutionType.INSURANCE_COMPANY]).map(i =>
              <Select.Option key={i.id} value={i.id}>{formatInstitutionName(i)}</Select.Option>)}
          </Select.OptGroup>

          <Select.OptGroup label={t("admin.institution.helpers.bankInstitutions")}>
            {resolveInstitutionSelectOptions([InstitutionType.BANK]).map(i =>
              <Select.Option key={i.id} value={i.id}>{formatInstitutionName(i)}</Select.Option>)}
          </Select.OptGroup>
        </Select>
      </Form.Item>
    )
  }
  else {
    const institutionTypes = optionsProps?.filterType
      ? [optionsProps.filterType]
      : [InstitutionType.INSURANCE_COMPANY, InstitutionType.BANK];

    return (
      <Form.Item {...formItemProps}>
        <Select {...selectStandardProps} {...selectProps}>
          {resolveInstitutionSelectOptions(institutionTypes).map(i =>
            <Select.Option key={i.id} value={i.id}>{formatInstitutionName(i)}</Select.Option>)}
        </Select>
      </Form.Item>
    )
  }
}

export default InstitutionsEnumFormItemSelect;
