import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { ProductWithLifeInsuranceTariffs } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { selectTariffsClassificationEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { formatLifeInsuranceTariff } from "../../../../common/utils/formatUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  productId: UUID;
}

const LifeInsuranceTariffFormItemSelect = ({ formItemProps, selectProps, productId }: Props) => {

  const productsWithTariffs = useSelector<RootState, ProductWithLifeInsuranceTariffs[]>(selectTariffsClassificationEnumerations);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={(productsWithTariffs
          .find(product => product.id === productId)?.tariffs || [])
          .map(tariff => ({ value: tariff.id, label: formatLifeInsuranceTariff(tariff) }))} />
    </Form.Item>
  )
}

export default LifeInsuranceTariffFormItemSelect;
