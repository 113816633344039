import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { Modal } from "antd";
import {
  ActionCreator,
  EntityIdRequest,
  EntityObjectRequest,
  RootState,
  TwoLevelEntityObjectRequest
} from "../../common/types";
import {
  Account,
  AccountReducerState,
  AdminCreatePersonAccount,
  AdminPersonAccount,
  AdminUpdatePersonAccount,
  ConfirmAccount,
  PasswordReset,
  RequestPasswordReset
} from "./types";
import { changeLocationKeyAction } from "../ducks";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../common/utils/reduxUtils";
import { replaceInArray } from "../../common/utils/utils";
import messageUtils from "../../common/utils/messageUtils";
import api from "./api";
import t from "../../app/i18n";

/**
 * ACTION TYPES
 */
export enum actionType {
  GET_PERSON = 'account/GET_PERSON',
  CREATE_PERSON = 'account/CREATE_PERSON',
  UPDATE_PERSON = 'account/UPDATE_PERSON',
  CONFIRM = 'account/CONFIRM',
  REQUEST_PASSWORD_RESET = 'account/REQUEST_PASSWORD_RESET',
  RESET_PASSWORD = 'account/RESET_PASSWORD',
  DELETE_STATE_PERSON = 'account/DELETE_STATE_PERSON'
}

/**
 * ACTIONS
 */
export const getPersonAccountsActions = createApiActionCreators<EntityIdRequest, AdminPersonAccount[]>(actionType.GET_PERSON);
export const createPersonAccountActions = createApiActionCreators<EntityObjectRequest<AdminCreatePersonAccount>, AdminPersonAccount[]>(actionType.CREATE_PERSON);
export const updatePersonAccountActions = createApiActionCreators<TwoLevelEntityObjectRequest<AdminUpdatePersonAccount>, AdminPersonAccount[]>(actionType.UPDATE_PERSON);
export const confirmAccountActions = createApiActionCreators<EntityObjectRequest<ConfirmAccount>, Account>(actionType.CONFIRM);
export const requestPasswordResetActions = createApiActionCreators<RequestPasswordReset, void>(actionType.REQUEST_PASSWORD_RESET);
export const resetPasswordActions = createApiActionCreators<EntityObjectRequest<PasswordReset>, void>(actionType.RESET_PASSWORD);

export const deleteStatePersonAccountsAction = createActionCreator<void>(actionType.DELETE_STATE_PERSON);

/**
 * REDUCERS
 */
const initialState: AccountReducerState = {
  personAccounts: []
};

const personAccountsReducer = createReducer<AdminPersonAccount[]>(initialState.personAccounts, {
  [actionType.GET_PERSON]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.personAccounts
  },
  [actionType.CREATE_PERSON]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.UPDATE_PERSON]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_PERSON]: () => initialState.personAccounts
});

export default combineReducers({ personAccounts: personAccountsReducer });

/**
 * SELECTORS
 */
const selectAccount = (state: RootState): AccountReducerState => state.account;

export const selectPersonAccounts = (state: RootState): AdminPersonAccount[] => selectAccount(state).personAccounts;

/**
 * SAGAS
 */
function* getPersonAccounts({ payload }: ActionCreator<EntityIdRequest>) {
  try {
    const response: AxiosResponse<AdminPersonAccount[]> = yield call(api.getPersonAccounts, payload);
    yield put(getPersonAccountsActions.success(response.data));
  }
  catch ( error ) {
    yield put(getPersonAccountsActions.failure(error));
  }
}

function* createPersonAccount({ payload }: ActionCreator<EntityObjectRequest<AdminCreatePersonAccount>>) {
  try {
    const response: AxiosResponse<AdminPersonAccount> = yield call(api.createPersonAccount, payload);
    const accounts: AdminPersonAccount[] = [...yield select(selectPersonAccounts)];
    accounts.push(response.data);
    yield put(createPersonAccountActions.success(accounts));
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();
  }
  catch ( error ) {
    yield put(createPersonAccountActions.failure(error));
  }
}

function* updatePersonAccount({ payload }: ActionCreator<TwoLevelEntityObjectRequest<AdminUpdatePersonAccount>>) {
  try {
    const response: AxiosResponse<AdminPersonAccount> = yield call(api.updatePersonAccount, payload);
    let accounts: AdminPersonAccount[] = [...yield select(selectPersonAccounts)];
    accounts = replaceInArray(accounts, item => item.id === response.data.id, response.data);
    yield put(updatePersonAccountActions.success(accounts));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updatePersonAccountActions.failure(error));
  }
}

function* confirmAccount({ payload }: ActionCreator<EntityObjectRequest<ConfirmAccount>>) {
  try {
    const response: AxiosResponse<Account> = yield call(api.confirmAccount, payload);
    yield put(confirmAccountActions.success(response.data));
    yield new Promise(resolve =>
      Modal.success({
        title: t("common.operationSuccess"),
        content: t("account.helpers.accountConfirmationSuccess"),
        okText: t("account.actions.continueToLogin"),
        onOk: () => resolve()
      }));
    yield put(replace("/auth"));
  }
  catch ( error ) {
    yield put(confirmAccountActions.failure(error));
  }
}

function* requestPasswordReset({ payload }: ActionCreator<RequestPasswordReset>) {
  try {
    yield call(api.requestPasswordReset, payload);
    yield put(requestPasswordResetActions.success());
    yield new Promise(resolve =>
      Modal.info({
        title: t("common.operationSuccess"),
        content: t("account.helpers.requestPasswordResetSuccess"),
        okText: t("account.actions.closeWindow"),
        onOk: () => resolve()
      })
    );
  }
  catch ( error ) {
    yield put(requestPasswordResetActions.failure(error));
  }
}

function* resetPassword({ payload }: ActionCreator<EntityObjectRequest<PasswordReset>>) {
  try {
    yield call(api.resetPassword, payload);
    yield put(resetPasswordActions.success());
    yield new Promise(resolve =>
      Modal.success({
        title: t("common.operationSuccess"),
        content: t("account.helpers.resetPasswordSuccess"),
        okText: t("account.actions.continueToLogin"),
        onOk: () => resolve()
      })
    );
    yield put(replace("/auth"));
  }
  catch ( error ) {
    yield put(resetPasswordActions.failure(error));
  }
}

export function* accountSaga() {
  yield takeLatest(createActionType(actionType.GET_PERSON, apiOperation.REQUEST), getPersonAccounts);
  yield takeLatest(createActionType(actionType.CREATE_PERSON, apiOperation.REQUEST), createPersonAccount);
  yield takeLatest(createActionType(actionType.UPDATE_PERSON, apiOperation.REQUEST), updatePersonAccount);
  yield takeLatest(createActionType(actionType.CONFIRM, apiOperation.REQUEST), confirmAccount);
  yield takeLatest(createActionType(actionType.REQUEST_PASSWORD_RESET, apiOperation.REQUEST), requestPasswordReset);
  yield takeLatest(createActionType(actionType.RESET_PASSWORD, apiOperation.REQUEST), resetPassword);
}
