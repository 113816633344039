import React from "react";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { Col, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CalcResult, GenResponse } from "../../../types";
import {
  TravelCalc,
  TravelCalcResultData,
  TravelFormClients,
  TravelGenForm,
  TravelGenFormData,
  TravelGenFormInsuredClient
} from "../../types";
import {
  Client,
  ClientsAutocompleteProps,
  CreateUpdateContractClient,
  NaturalClient
} from "../../../../../client/types";
import { ValidationErrorResponse } from "../../../../../types";
import { FieldConstraintViolation } from "../../../../../../common/types";
import { TravelInsuranceType } from "../../enums";
import { CalcType } from "../../../../enums";
import { ClientFormType, ClientType } from "../../../../../client/enums";
import { InstitutionEnum } from "../../../../../admin/institution/enums";
import { ClientExperience } from "../../../../../contract/enums";
import { deleteStateTravelGenResultAction, generateTravelActions } from "../../ducks";
import { deleteStateValidationErrorResponseAction } from "../../../../../ducks";
import { validateClientActions, validateClientsActions } from "../../../../../client/ducks";
import { processClientsDataViolations, processGenResultError } from "../../../utils";
import { contains } from "../../../../../../common/utils/utils";
import { clientToCreateUpdateContractClient } from "../../../../../client/utils";
import { toMoment } from "../../../../../../common/utils/formUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import t from "../../../../../../app/i18n";

import TravelGenInsurerSection from "./sections/TravelGenInsurerSection";
import TravelGenInsuredClientsSection from "./sections/TravelGenInsuredClientsSection";
import TravelGenReinsurancesSection from "./sections/TravelGenReinsurancesSection";
import TravelGenInformationObligationSection from "./sections/TravelGenInformationObligationSection";
import TravelGenNavigation from "./navigation/TravelGenNavigation";
import TravelCalcResults from "../result/TravelCalcResults";
import CalcGenResultModal from "../../../components/CalcGenResultModal";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";

export interface Props extends FormComponentProps<TravelGenForm> {
  initialData: TravelGenFormData;
  genResult: GenResponse;
  calcData: TravelCalc;
  clients: TravelFormClients;
  calcResults: CalcResult<TravelCalcResultData>[][];
  selectedResult: CalcResult<TravelCalcResultData>;
  clientsAutocomplete: ClientsAutocompleteProps;
  validateClientErrorResponse: ValidationErrorResponse;
  validateClientsErrorResponse: ValidationErrorResponse;
  onGenerateFormSubmit: typeof generateTravelActions.request;
  onValidateClient: typeof validateClientActions.request;
  onValidateClients: typeof validateClientsActions.request;
  onGenResultDelete: typeof deleteStateTravelGenResultAction;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
  onClientChange(client: Client, type: ClientFormType, callback?: () => void);
  onGenerateOfferClick(): void;
  onSelectedResultChange(selectedResult: CalcResult<TravelCalcResultData>, callback?: () => void): void;
  onReturnToCalculationClick(genFormData: TravelGenFormData): void;
}

interface State {
  readonly resultsVisible: boolean;
  readonly insurerIsAlsoInsured: boolean;
  readonly authorizedClientsCount: number;
  readonly clientsDuplicateErrors: ClientFormType[];
  readonly clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
}

class TravelGenWrapper extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    let stateObject = {
      resultsVisible: false,
      insurerIsAlsoInsured: false,
      authorizedClientsCount: 1,
      clientsDuplicateErrors: [],
      clientsViolationErrors: new Map<ClientFormType, FieldConstraintViolation[]>()
    };

    if ( props.initialData ) {
      const { formData, clientsData } = props.initialData;

      stateObject.insurerIsAlsoInsured = formData.clientsData.insurerIsAlsoInsured;
      stateObject = { ...stateObject, ...clientsData };
    }

    this.state = stateObject;
  }

  handleGenerateFormSubmit = (): void => {
    this.props.form.validateFieldsAndScroll(errors => {
      const hasClientsError = this.checkHasClientsError();
      const hasClientsDuplicatePinsError = this.checkHasClientsDuplicatePinsError();
      if ( errors || hasClientsError || hasClientsDuplicatePinsError ) {
        messageUtils.errorNotification(t("common.error"), t("calc.travel.validations.formError"));
      }
      else {
        const { calcData } = this.props;
        const values = this.props.form.getFieldsValue() as TravelGenForm;

        this.props.onGenResultDelete();
        this.props.onGenerateFormSubmit({
          type: CalcType.TRAVEL,
          calcResult: this.props.selectedResult.data,
          coverage: this.props.selectedResult.coverage,
          calcResponse: { results: this.props.calcResults.flat() },
          insuranceInstitutionId: this.props.selectedResult.insuranceInstitution.id,
          ...calcData,
          clientsData: {
            ...calcData.clientsData,
            clients: this.createContractClientsArray(),
            insurerEmail: values.clientsData.insurerEmail,
            insurerPhone: values.clientsData.insurerPhone,
            insurerIsAlsoInsured: values.clientsData.insurerIsAlsoInsured,
            authorizedClient1Function: values.clientsData.authorizedClient1Function,
            authorizedClient2Function: values.clientsData.authorizedClient2Function,
            insuredClients: values.clientsData.insuredClients.map((formClient, index) => ({
              ...(calcData.clientsData.insuredClients ? calcData.clientsData.insuredClients[index] : {}),
              ...formClient
            }))
          },
          reinsurancesData: calcData.generalData.insuranceType === TravelInsuranceType.SHORT_TERM
            ? merge({}, calcData.reinsurancesData, values.reinsurancesData) : null,
          informationObligationData: values.informationObligationData
        });
      }
    })
  };

  handleReturnToCalculationClick = (): void => {
    this.props.onReturnToCalculationClick({
      formData: this.props.form.getFieldsValue() as TravelGenForm,
      clientsData: {
        authorizedClientsCount: this.state.authorizedClientsCount,
        clientsDuplicateErrors: this.state.clientsDuplicateErrors
      }
    });
  };

  handleResultsShow = (): void => {
    this.setState({ resultsVisible: true });
  };

  handleResultsClose = (): void => {
    this.setState({ resultsVisible: false });
  };

  handleResultsGenerateOfferClick = (): void => {
    this.setState({ resultsVisible: false });
    this.props.onGenerateOfferClick();
  };

  handleResultsGenerateContractClick = (result: CalcResult<TravelCalcResultData>): void => {
    this.props.onSelectedResultChange(result, () => {
      if ( this.state.insurerIsAlsoInsured ) {
        this.setFirstInsuredClientData(this.props.clients.insurer as NaturalClient);
      }
    });
    this.setState({ resultsVisible: false });
  };

  handleInsurerIsAlsoInsuredChange = (event: CheckboxChangeEvent): void => {
    this.setState({ insurerIsAlsoInsured: event.target.checked });
    this.setFirstInsuredClientData(event.target.checked ? this.props.clients.insurer as NaturalClient : null);
  };

  handleAuthorizedClientAdd = (): void => {
    this.setState(previousState => ({ authorizedClientsCount: previousState.authorizedClientsCount + 1 }));
  };

  handleAuthorizedClientDelete = (): void => {
    this.setState(previousState => ({ authorizedClientsCount: previousState.authorizedClientsCount - 1 }));
  };

  handleClientChange = (client: Client, type: ClientFormType, omitValidation?: boolean, callback?: () => void): void => {
    if ( type === ClientFormType.INSURER && this.state.insurerIsAlsoInsured ) {
      if ( client && client.type !== ClientType.NATURAL ) {
        this.setState({ insurerIsAlsoInsured: false });
        this.setFirstInsuredClientData(null);
        this.props.form.setFieldsValue({ "clientsData.insurerIsAlsoInsured": false });
      }
      else {
        this.setFirstInsuredClientData(client as NaturalClient);
      }
    }

    this.props.onClientChange(client, type, () => {
      if ( client && !omitValidation ) {
        this.props.onValidateClient({
          prefix: `clientsData.clients[${this.createClientsIndicesMap().get(type)}]`,
          client: clientToCreateUpdateContractClient(client)
        });
      }

      if ( client && type === ClientFormType.INSURER && (client.type === ClientType.SELF_EMPLOYED || client.type === ClientType.LEGAL) ) {
        this.props.form.setFieldsValue({ "informationObligationData.clientExperience": ClientExperience.SUFFICIENT });
      }

      if ( callback ) {
        callback();
      }
    });
  };

  handleClientViolationErrorsChange = (violations: FieldConstraintViolation[], type: ClientFormType): void => {
    this.setState(previousState => {
      const clientsViolationErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...previousState.clientsViolationErrors]);
      if ( violations && violations.length > 0 ) {
        clientsViolationErrors.set(type, violations);
      }
      else {
        clientsViolationErrors.delete(type);
      }
      return { clientsViolationErrors };
    });
  };

  handleClientDuplicateErrorChange = (isDuplicate: boolean, type: ClientFormType): void => {
    if ( isDuplicate ) {
      this.setState(previousState =>
        contains(previousState.clientsDuplicateErrors, type)
          ? { clientsDuplicateErrors: previousState.clientsDuplicateErrors }
          : { clientsDuplicateErrors: [...previousState.clientsDuplicateErrors, type] });
    }
    else {
      this.setState(previousState => ({ clientsDuplicateErrors: previousState.clientsDuplicateErrors.filter(error => error !== type) }));
    }
  };

  processClientsViolationErrorsStateUpdate = (errorResponse: ValidationErrorResponse): void => {
    this.setState(previousState => ({
      clientsViolationErrors: new Map([
        ...previousState.clientsViolationErrors,
        ...processClientsDataViolations(errorResponse.violations, this.createClientsIndicesMap())
      ])
    }), () => this.props.onErrorResponseDelete());
  };

  setFirstInsuredClientData = (client: NaturalClient): void => {
    if ( client ) {
      const { institutionEnum } = this.props.selectedResult.insuranceInstitution;
      this.props.form.setFieldsValue({
        "clientsData.insuredClients[0].firstName": client.firstName,
        "clientsData.insuredClients[0].lastName": client.lastName,
        "clientsData.insuredClients[0].academicDegree":
          institutionEnum !== InstitutionEnum.UNION ? client.academicDegree : undefined,
        "clientsData.insuredClients[0].academicDegreeAfter":
          institutionEnum !== InstitutionEnum.UNION ? client.academicDegreeAfter : undefined,
        "clientsData.insuredClients[0].personalIdentificationNumber":
          institutionEnum === InstitutionEnum.GENERALI ? client.personalIdentificationNumber : undefined,
        "clientsData.insuredClients[0].birthDate":
          institutionEnum !== InstitutionEnum.GENERALI ? toMoment(client.birthDate) : undefined,
        "clientsData.insuredClients[0].discountIdentifier": undefined
      });
    }
    else {
      this.props.form.setFieldsValue({
        "clientsData.insuredClients[0].firstName": undefined,
        "clientsData.insuredClients[0].lastName": undefined,
        "clientsData.insuredClients[0].academicDegree": undefined,
        "clientsData.insuredClients[0].academicDegreeAfter": undefined,
        "clientsData.insuredClients[0].personalIdentificationNumber": undefined,
        "clientsData.insuredClients[0].birthDate": undefined,
        "clientsData.insuredClients[0].discountIdentifier": undefined
      });
    }
  };

  checkHasClientsError = (): boolean => {
    const { insurer, authorized1, authorized2 } = this.props.clients;
    const { authorizedClientsCount, clientsViolationErrors, clientsDuplicateErrors } = this.state;

    if ( !insurer || clientsViolationErrors.has(ClientFormType.INSURER) || contains(clientsDuplicateErrors, ClientFormType.INSURER) ) {
      return true;
    }

    if ( insurer.type === ClientType.LEGAL ) {
      if ( !authorized1 || clientsViolationErrors.has(ClientFormType.AUTHORIZED_1) || contains(clientsDuplicateErrors, ClientFormType.AUTHORIZED_1) ) {
        return true;
      }
      if ( authorizedClientsCount > 1 &&
        (!authorized2 || clientsViolationErrors.has(ClientFormType.AUTHORIZED_2) || contains(clientsDuplicateErrors, ClientFormType.AUTHORIZED_2)) ) {
        return true;
      }
    }

    return false;
  };

  checkHasClientsDuplicatePinsError = (): boolean => {
    if ( this.props.selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.GENERALI ) {
      const insuredClientsPins = [];
      const errorFields = {};

      (this.props.form.getFieldsValue() as TravelGenForm).clientsData.insuredClients
        .forEach((client, index) => {
          if ( client.personalIdentificationNumber ) {
            if ( contains(insuredClientsPins, client.personalIdentificationNumber) ) {
              errorFields[`clientsData.insuredClients[${index}].personalIdentificationNumber`] = {
                value: client.personalIdentificationNumber,
                errors: [new Error(t("calc.travel.validations.duplicatePin"))]
              };
            }
            insuredClientsPins.push(client.personalIdentificationNumber);
          }
        });

      if ( !isEmpty(errorFields) ) {
        this.props.form.setFields(errorFields);
        return true;
      }
    }

    return false;
  };

  createClientsIndicesMap = (): Map<ClientFormType, number> => {
    const { insurer, authorized1, authorized2 } = this.props.clients;
    const indices = new Map<ClientFormType, number>();
    let index = 0;

    if ( insurer ) {
      indices.set(ClientFormType.INSURER, index++);

      if ( insurer.type === ClientType.LEGAL ) {
        if ( authorized1 ) {
          indices.set(ClientFormType.AUTHORIZED_1, index++);
        }

        if ( authorized2 && this.state.authorizedClientsCount > 1 ) {
          indices.set(ClientFormType.AUTHORIZED_2, index);
        }
      }
    }

    return indices;
  };

  createContractClientsArray = (): CreateUpdateContractClient[] => {
    const { insurer, authorized1, authorized2 } = this.props.clients;
    const createUpdateClients: CreateUpdateContractClient[] = [];

    if ( insurer ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(insurer));

      if ( insurer.type === ClientType.LEGAL ) {
        if ( authorized1 ) {
          createUpdateClients.push(clientToCreateUpdateContractClient(authorized1));
        }
        if ( this.state.authorizedClientsCount > 1 && authorized2 ) {
          createUpdateClients.push(clientToCreateUpdateContractClient(authorized2));
        }
      }
    }

    return createUpdateClients;
  };

  resolvePageTitle = (): string => {
    return `${t("calc.travel.titles.contractGeneration")} - 
    ${t("calc.travel.titles.insuranceType." + this.props.calcData.generalData.insuranceType)}, 
    ${this.props.selectedResult.insuranceInstitution.name}, 
    ${t("calc.travel.sections.coverage")} ${this.props.selectedResult.coverage}`;
  };

  componentDidMount(): void {
    if ( this.props.initialData ) {
      const { calcData } = this.props;
      const { insurer } = this.props.clients;
      const { institutionEnum } = this.props.selectedResult.insuranceInstitution;
      const formData = cloneDeep(this.props.initialData.formData);

      const insurerType = insurer ? insurer.type : null;
      formData.informationObligationData.clientExperience = insurerType === ClientType.SELF_EMPLOYED || insurerType === ClientType.LEGAL
        ? ClientExperience.SUFFICIENT : formData.informationObligationData.clientExperience;

      formData.clientsData.insuredClients = formData.clientsData.insuredClients.map<TravelGenFormInsuredClient>((insuredClient, index) => {
        let client: TravelGenFormInsuredClient;
        if ( index === 0 && formData.clientsData.insurerIsAlsoInsured && this.props.clients.insurer ) {
          const insurer = this.props.clients.insurer as NaturalClient;
          client = {
            academicDegree: insurer.academicDegree,
            academicDegreeAfter: insurer.academicDegreeAfter,
            firstName: insurer.firstName,
            lastName: insurer.lastName,
            personalIdentificationNumber: insurer.personalIdentificationNumber,
            birthDate: toMoment(insurer.birthDate),
            discountIdentifier: insuredClient.discountIdentifier
          }
        }
        else {
          client = insuredClient;
        }

        return {
          ...client,
          academicDegree: institutionEnum === InstitutionEnum.UNION ? undefined : client.academicDegree,
          academicDegreeAfter: institutionEnum === InstitutionEnum.UNION ? undefined : client.academicDegreeAfter,
          personalIdentificationNumber: institutionEnum === InstitutionEnum.GENERALI ? client.personalIdentificationNumber : undefined,
          birthDate: institutionEnum === InstitutionEnum.GENERALI ? undefined : client.birthDate,
          discountIdentifier: institutionEnum === InstitutionEnum.UNION ? client.discountIdentifier : undefined
        } as TravelGenFormInsuredClient;
      });

      if ( calcData.generalData.insuranceType !== TravelInsuranceType.SHORT_TERM ) {
        delete formData.reinsurancesData;
      }
      else if ( formData.reinsurancesData ) {
        if ( !calcData.reinsurancesData.cancellationReinsurance || institutionEnum !== InstitutionEnum.UNION ) {
          delete formData.reinsurancesData.cancellationReinsurance;
        }

        if ( !calcData.reinsurancesData.abandonedHouseholdReinsurance ) {
          delete formData.reinsurancesData.abandonedHouseholdReinsuranceData;
        }
        else if ( formData.reinsurancesData.abandonedHouseholdReinsuranceData ) {
          if ( institutionEnum === InstitutionEnum.ALLIANZ ) {
            delete formData.reinsurancesData.abandonedHouseholdReinsuranceData;
          }
        }

        if ( !calcData.reinsurancesData.vehicleAssistanceReinsurance ) {
          delete formData.reinsurancesData.vehicleAssistanceReinsuranceData;
        }
        else if ( formData.reinsurancesData.vehicleAssistanceReinsuranceData ) {
          if ( institutionEnum === InstitutionEnum.ALLIANZ ) {
            delete formData.reinsurancesData.vehicleAssistanceReinsuranceData;
          }
          else if ( institutionEnum === InstitutionEnum.GENERALI ) {
            delete formData.reinsurancesData.vehicleAssistanceReinsuranceData.type;
          }
        }

        if ( !calcData.reinsurancesData.petReinsurance || institutionEnum !== InstitutionEnum.UNION ) {
          delete formData.reinsurancesData.petReinsuranceData;
        }
      }

      this.props.form.setFieldsValue(formData);

      this.props.onValidateClients({
        prefix: "clientsData.clients",
        clients: this.createContractClientsArray()
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( !prevProps.genResult && this.props.genResult && this.props.genResult.error ) {
      const violations = processGenResultError(this.props.genResult.error, this.createClientsIndicesMap(),
        this.props.form, "calc.travel.attrs");

      if ( violations ) {
        messageUtils.errorNotification(
          this.props.genResult.error.message,
          <FieldViolationsView violations={violations.notificationViolations} rootPath="calc.travel.attrs" />, 10);
        this.setState({ clientsViolationErrors: violations.clientsViolations });
      }
    }

    if ( this.props.validateClientErrorResponse ) {
      this.processClientsViolationErrorsStateUpdate(this.props.validateClientErrorResponse);
    }
    else if ( this.props.validateClientsErrorResponse ) {
      this.processClientsViolationErrorsStateUpdate(this.props.validateClientsErrorResponse);
    }
  }

  render(): React.ReactNode {
    const { insurerIsAlsoInsured } = this.state;
    const { initialData, form, calcData, selectedResult } = this.props;
    const { insurer } = this.props.clients;
    const formLayout: ColProps = { span: 24, style: { maxWidth: "950px" } };

    return (
      <>
        <h2>{this.resolvePageTitle()}</h2>

        <Row gutter={64} className="margin-top-medium" justify="center">
          <Col {...formLayout}>
            <Form layout="vertical">
              <TravelGenInsurerSection
                form={form}
                initialData={initialData ? initialData.formData : null}
                insurerIsAlsoInsured={insurerIsAlsoInsured}
                authorizedClientsCount={this.state.authorizedClientsCount}
                clients={this.props.clients}
                clientsAutocomplete={this.props.clientsAutocomplete}
                clientsDuplicateErrors={this.state.clientsDuplicateErrors}
                clientsViolationErrors={this.state.clientsViolationErrors}
                onInsurerIsAlsoInsuredChange={this.handleInsurerIsAlsoInsuredChange}
                onAuthorizedClientAdd={this.handleAuthorizedClientAdd}
                onAuthorizedClientDelete={this.handleAuthorizedClientDelete}
                onClientChange={this.handleClientChange}
                onClientViolationErrorsChange={this.handleClientViolationErrorsChange}
                onClientDuplicateErrorChange={this.handleClientDuplicateErrorChange} />

              <TravelGenInsuredClientsSection
                form={form}
                calcData={calcData}
                selectedInstitutionEnum={selectedResult.insuranceInstitution.institutionEnum}
                insurerIsAlsoInsured={insurerIsAlsoInsured} />

              {calcData.generalData.insuranceType === TravelInsuranceType.SHORT_TERM && (
                <TravelGenReinsurancesSection
                  form={form}
                  initialAbandonedHouseholdData={initialData && initialData.formData && initialData.formData.reinsurancesData
                    ? initialData.formData.reinsurancesData.abandonedHouseholdReinsuranceData : null}
                  selectedInstitutionEnum={selectedResult.insuranceInstitution.institutionEnum}
                  reinsurancesData={calcData.reinsurancesData} />
              )}

              <TravelGenInformationObligationSection
                form={form}
                insurerType={insurer ? insurer.type : null} />
            </Form>
          </Col>
        </Row>

        <TravelCalcResults
          visible={this.state.resultsVisible}
          insuranceType={calcData.generalData.insuranceType}
          calcResults={this.props.calcResults}
          selectedResult={this.props.selectedResult}
          onClose={this.handleResultsClose}
          onGenerateOfferClick={this.handleResultsGenerateOfferClick}
          onGenerateContractClick={this.handleResultsGenerateContractClick} />

        <TravelGenNavigation
          onFormSubmit={this.handleGenerateFormSubmit}
          onShowResultsClick={this.handleResultsShow}
          onReturnToCalculationClick={this.handleReturnToCalculationClick} />

        <CalcGenResultModal
          result={this.props.genResult}
          onCancel={this.props.onGenResultDelete} />
      </>
    );
  }
}

export default Form.create<Props>()(TravelGenWrapper);
