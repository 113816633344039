import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ActionProps, RootState } from "../../../../common/types";
import { Institution } from "../types";
import {
  deleteInstitutionActions,
  deleteStateInstitutionDetailAction,
  getInstitutionActions,
  selectInstitutionDetail,
  updateInstitutionActions
} from "../ducks";
import { requests } from "../api";

import InstitutionUpdateForm from "../components/forms/InstitutionUpdateForm";
import DisplayWrapper from "../../../../common/modules/wrappers/DisplayWrapper";

interface StateProps {
  institution: Institution;
}

interface ActionsMap {
  getInstitution: typeof getInstitutionActions.request;
  updateInstitution: typeof updateInstitutionActions.request;
  deleteInstitution: typeof deleteInstitutionActions.request;
  deleteStateInstitutionDetail: typeof deleteStateInstitutionDetailAction;
}

const InstitutionDetailContainer = (props: StateProps & ActionProps<ActionsMap> & RouteComponentProps) => {

  useEffect(() => {
    if ( !props.institution ) {
      props.actions.getInstitution({ id: props.match.params["id"] })
    }
    return () => props.actions.deleteStateInstitutionDetail();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DisplayWrapper itemLoaded={!!props.institution} notFoundCheckRequest={requests.GET_INSTITUTION}>
      <InstitutionUpdateForm
        institution={props.institution}
        onUpdateSubmit={props.actions.updateInstitution}
        onDeleteClick={props.actions.deleteInstitution} />
    </DisplayWrapper>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  institution: selectInstitutionDetail(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    getInstitution: getInstitutionActions.request,
    updateInstitution: updateInstitutionActions.request,
    deleteInstitution: deleteInstitutionActions.request,
    deleteStateInstitutionDetail: deleteStateInstitutionDetailAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(InstitutionDetailContainer);
