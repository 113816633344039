import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import api from "./api";
import { JobRecord, JobReducerState, JobSettings, ScheduleJobRequest, UpdateJobSettings } from "./types";
import {
  ActionCreator,
  EntityObjectRequest,
  PageRequest,
  PageResult,
  RootState,
  TwoLevelEntityIdRequest
} from "../../../common/types";
import { changeLocationKeyAction } from "../../ducks";
import { replaceInArray } from "../../../common/utils/utils";
import {
  apiOperation,
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer
} from "../../../common/utils/reduxUtils";
import messageUtils from "../../../common/utils/messageUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  GET_ALL = 'job/GET_ALL',
  UPDATE = 'job/UPDATE',
  SCHEDULE = 'job/SCHEDULE',
  FILTER_JOB_RECORDS = 'job/FILTER_JOB_RECORDS',
  CANCEL = 'job/CANCEL',
  ABORT = 'job/ABORT',
  DELETE_STATE_ITEMS = 'job/DELETE_STATE_ITEMS'
}

/**
 * ACTIONS
 */
export const getAllJobsActions = createApiActionCreators<void, JobSettings[]>(actionType.GET_ALL);
export const updateJobActions = createApiActionCreators<EntityObjectRequest<UpdateJobSettings>, JobSettings[]>(actionType.UPDATE);
export const scheduleJobActions = createApiActionCreators<EntityObjectRequest<ScheduleJobRequest>, void>(actionType.SCHEDULE);
export const filterJobRecordsActions = createApiActionCreators<EntityObjectRequest<PageRequest>, JobSettings[]>(actionType.FILTER_JOB_RECORDS);
export const cancelJobActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.CANCEL);
export const abortJobActions = createApiActionCreators<TwoLevelEntityIdRequest, void>(actionType.ABORT);

export const deleteStateJobItemsAction = createActionCreator<void>(actionType.DELETE_STATE_ITEMS);

/**
 * REDUCERS
 */
const initialState: JobReducerState = {
  items: []
}

const jobsReducer = createReducer<JobSettings[]>(initialState.items, {
  [actionType.GET_ALL]: {
    [apiOperation.SUCCESS]: (state, payload) => payload,
    [apiOperation.FAILURE]: () => initialState.items
  },
  [actionType.UPDATE]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.FILTER_JOB_RECORDS]: {
    [apiOperation.SUCCESS]: (state, payload) => payload
  },
  [actionType.DELETE_STATE_ITEMS]: () => initialState.items
});

export default combineReducers({ items: jobsReducer });

/**
 * SELECTORS
 */
const selectJob = (state: RootState): JobReducerState => state.admin.job;

export const selectJobs = (state: RootState): JobSettings[] => selectJob(state).items;

/**
 * SAGAS
 */
function* getAllJobs() {
  try {
    const response: AxiosResponse<JobSettings[]> = yield call(api.getJobs);
    yield put(getAllJobsActions.success(response.data));
  }
  catch ( error ) {
    yield put(getAllJobsActions.failure(error));
  }
}

function* updateJob({ payload }: ActionCreator<EntityObjectRequest<UpdateJobSettings>>) {
  try {
    const response: AxiosResponse<JobSettings> = yield call(api.updateJob, payload);
    let items: JobSettings[] = [...yield select(selectJobs)];
    items = replaceInArray(items, item => item.id === response.data.id, response.data);
    yield put(updateJobActions.success(items));
    yield put(changeLocationKeyAction());
    messageUtils.itemUpdatedNotification();
  }
  catch ( error ) {
    yield put(updateJobActions.failure(error));
  }
}

function* scheduleJob({ payload }: ActionCreator<EntityObjectRequest<ScheduleJobRequest>>) {
  try {
    yield call(api.scheduleJob, payload);
    yield put(scheduleJobActions.success());
    yield put(changeLocationKeyAction());
    messageUtils.itemCreatedNotification();
    yield delay(2500);
    yield put(getAllJobsActions.request());
  }
  catch ( error ) {
    yield put(scheduleJobActions.failure(error));
  }
}

function* filterJobRecords({ payload }: ActionCreator<EntityObjectRequest<PageRequest>>) {
  try {
    const response: AxiosResponse<PageResult<JobRecord>> = yield call(api.filterJobRecords, payload);
    let items: JobSettings[] = [...yield select(selectJobs)];
    const job = items.find(item => item.id === payload.id);
    items = replaceInArray(items, item => item.id === payload.id, { ...job, records: response.data });
    yield put(filterJobRecordsActions.success(items));
  }
  catch ( error ) {
    yield put(filterJobRecordsActions.failure(error));
  }
}

function* cancelJob({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.cancelJob, payload);
    yield put(cancelJobActions.success());
    yield* refreshJobRecords((yield select(selectJobs)).find(job => job.id === payload.id1));
  }
  catch ( error ) {
    yield put(cancelJobActions.failure(error));
  }
}

function* abortJob({ payload }: ActionCreator<TwoLevelEntityIdRequest>) {
  try {
    yield call(api.abortJob, payload);
    yield put(abortJobActions.success());
    yield* refreshJobRecords((yield select(selectJobs)).find(job => job.id === payload.id1));
  }
  catch ( error ) {
    yield put(abortJobActions.failure(error));
  }
}

function* refreshJobRecords(job: JobSettings) {
  yield delay(2000);
  if ( job.records ) {
    yield put(filterJobRecordsActions.request({
      id: job.id,
      object: { pageIndex: job.records.pageIndex, pageSize: job.records.pageSize }
    }));
  }
  else {
    yield put(getAllJobsActions.request());
  }
}

export function* jobSaga() {
  yield takeLatest(createActionType(actionType.GET_ALL, apiOperation.REQUEST), getAllJobs);
  yield takeLatest(createActionType(actionType.UPDATE, apiOperation.REQUEST), updateJob);
  yield takeLatest(createActionType(actionType.SCHEDULE, apiOperation.REQUEST), scheduleJob);
  yield takeLatest(createActionType(actionType.FILTER_JOB_RECORDS, apiOperation.REQUEST), filterJobRecords);
  yield takeLatest(createActionType(actionType.CANCEL, apiOperation.REQUEST), cancelJob);
  yield takeLatest(createActionType(actionType.ABORT, apiOperation.REQUEST), abortJob);
}
