import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { Col, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import {
  CrashCalcResultData,
  VehicleCalc,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleCalcType,
  VehicleFormClients,
  VehicleGenForm,
  VehicleGenFormData,
  VehicleOfferCalcType
} from "../../types";
import { CalcResult, GenResponse } from "../../../types";
import { FieldConstraintViolation } from "../../../../../../common/types";
import { Client, ClientsAutocompleteProps, CreateUpdateContractClient } from "../../../../../client/types";
import { ValidationErrorResponse } from "../../../../../types";
import { VehicleInsurerRelation, VehicleOwnerRelation, VehiclePriceSource, VehiclePurpose } from "../../enums";
import { CalcType } from "../../../../enums";
import { ClientFormType, ClientType } from "../../../../../client/enums";
import { ClientExperience, PaymentMethod } from "../../../../../contract/enums";
import { InstitutionEnum } from "../../../../../admin/institution/enums";
import { deleteStateVehicleGenResultAction, generateVehicleActions } from "../../ducks";
import { validateClientActions, validateClientsActions } from "../../../../../client/ducks";
import { deleteStateValidationErrorResponseAction } from "../../../../../ducks";
import { M1_N1, resolveVehicleInsurerType, SPECS_MAP } from "../../utils";
import { filterApplicableSuccessResults, processClientsDataViolations, processGenResultError } from "../../../utils";
import { contains } from "../../../../../../common/utils/utils";
import { clientToCreateUpdateContractClient } from "../../../../../client/utils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import t from "../../../../../../app/i18n";

import VehicleGenVehicleDataSection from "./sections/VehicleGenVehicleDataSection";
import VehicleGenClientsDataSection from "./sections/VehicleGenClientsDataSection";
import VehicleGenOtherDataSection from "./sections/VehicleGenOtherDataSection";
import VehicleGenNavigation from "./navigation/VehicleGenNavigation";
import VehicleCalcResultsView from "../result/VehicleCalcResults";
import CalcGenResultModal from "../../../components/CalcGenResultModal";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";

export interface Props extends FormComponentProps<VehicleGenForm> {
  initialData: VehicleGenFormData;
  genResult: GenResponse;
  calcData: VehicleCalc;
  clients: VehicleFormClients;
  calcResults: VehicleCalcResults;
  selectedResult: CalcResult<VehicleCalcResultData>;
  clientsAutocomplete: ClientsAutocompleteProps;
  validateClientErrorResponse: ValidationErrorResponse;
  validateClientsErrorResponse: ValidationErrorResponse;
  onGenerateFormSubmit: typeof generateVehicleActions.request;
  onValidateClient: typeof validateClientActions.request;
  onValidateClients: typeof validateClientsActions.request;
  onGenResultDelete: typeof deleteStateVehicleGenResultAction;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
  onGenerateOfferClick(type: VehicleOfferCalcType): void;
  onSelectedResultChange(selectedResult: CalcResult<VehicleCalcResultData>, callback?: () => void): void;
  onReturnToCalculationClick(genFormData: VehicleGenFormData, contractCreated?: boolean): void;
  onClientChange(client: Client, type: ClientFormType, callback?: () => void);
}

interface State {
  readonly resultsVisible: boolean;
  readonly authorizedClientsCount: number;
  readonly recommendedResultConfirmationVisible: boolean;
  readonly clientsDuplicateErrors: ClientFormType[];
  readonly clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
}

class VehicleGenWrapper extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const stateObject = {
      resultsVisible: false,
      authorizedClientsCount: 1,
      recommendedResultConfirmationVisible: false,
      clientsDuplicateErrors: [],
      clientsViolationErrors: new Map<ClientFormType, FieldConstraintViolation[]>()
    };

    if ( props.initialData ) {
      stateObject.authorizedClientsCount = props.initialData.authorizedClientsCount;

      const { insurerRelation, ownerRelation } = props.calcData.clientsData;
      const { holder, insurer, owner, authorized1, authorized2 } = props.clients;
      const usedIdentifiers = [holder.identifier];

      if ( insurer && insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER ) {
        if ( contains(usedIdentifiers, insurer.identifier) ) {
          stateObject.clientsDuplicateErrors.push(ClientFormType.INSURER);
        }
        else {
          usedIdentifiers.push(insurer.identifier);
        }
      }

      if ( owner && ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER ) {
        if ( contains(usedIdentifiers, owner.identifier) ) {
          stateObject.clientsDuplicateErrors.push(ClientFormType.OWNER);
        }
        else {
          usedIdentifiers.push(owner.identifier);
        }
      }

      if ( resolveVehicleInsurerType(props.clients, insurerRelation) === ClientType.LEGAL ) {
        if ( authorized1 ) {
          if ( contains(usedIdentifiers, authorized1.identifier) ) {
            stateObject.clientsDuplicateErrors.push(ClientFormType.AUTHORIZED_1);
          }
          else {
            usedIdentifiers.push(authorized1.identifier);
          }
        }
        if ( stateObject.authorizedClientsCount > 1 && authorized2 && contains(usedIdentifiers, authorized2.identifier) ) {
          stateObject.clientsDuplicateErrors.push(ClientFormType.AUTHORIZED_2);
        }
      }
    }

    this.state = stateObject;
  }

  handleGenerateFormSubmit = (): void => {
    this.props.form.validateFieldsAndScroll((errors, values) => {
      const clientsError = this.checkHasClientsError();
      const recommendedResultError = this.checkHasRecommendedResultError(values);
      if ( errors || clientsError || recommendedResultError ) {
        messageUtils.errorNotification(t("common.error"), t("calc.vehicle.validations.formError"));
      }
      else {
        const { calcData, selectedResult, calcResults } = this.props;
        const indicesMap = this.createClientsIndicesMap();

        let recommendedResult = null;
        if ( values.informationObligationData.recommendedResult ) {
          const recommendedResultData = values.informationObligationData.recommendedResult as string[];
          const institutionResults = ((selectedResult.calcType === CalcType.MTPL ? calcResults.mtpl : calcResults.crash) as CalcResult<VehicleCalcResultData>[][])
            .find(resultsRow => resultsRow.length > 0 && resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum[recommendedResultData[0]]);

          recommendedResult = recommendedResultData.length > 1
            ? institutionResults.find(result => result.coverage === recommendedResultData[1])
            : institutionResults[0];

          delete values.informationObligationData.recommendedResultConfirmation;
        }

        const allianzCrashCoverage = selectedResult.calcType === CalcType.CRASH && selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ
          ? values.additionalData.allianzMtplCoverage : null;

        this.props.onGenResultDelete();
        this.props.onGenerateFormSubmit({
          type: selectedResult.calcType,
          calcResult: allianzCrashCoverage
            ? calcResults.crash
              .find(resultsRow => resultsRow.length > 0 && resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ)
              .find(result => result.coverage === allianzCrashCoverage).data
            : selectedResult.data,
          coverage: allianzCrashCoverage || selectedResult.coverage,
          calcResponse: { results: [...calcResults.mtpl.flat(), ...calcResults.crash.flat()] },
          insuranceInstitutionId: selectedResult.insuranceInstitution.id,
          vehicleData: { ...calcData.vehicleData, ...values.vehicleData },
          clientsData: {
            ...calcData.clientsData,
            clients: this.createContractClientsArray(),
            holderIndex: indicesMap.get(ClientFormType.HOLDER),
            insurerIndex: indicesMap.get(ClientFormType.INSURER),
            ownerIndex: indicesMap.get(ClientFormType.OWNER),
            authorizedClient1Index: indicesMap.get(ClientFormType.AUTHORIZED_1),
            authorizedClient2Index: indicesMap.get(ClientFormType.AUTHORIZED_2),
            insurerEmail: values.clientsData.insurerEmail,
            insurerPhone: values.clientsData.insurerPhone,
            authorizedClient1Function: values.clientsData.authorizedClient1Function,
            authorizedClient2Function: values.clientsData.authorizedClient2Function,
            holderChildrenUnder15YearsName: values.clientsData.holderChildrenUnder15YearsName,
            holderChildrenUnder15YearsBirthDate: values.clientsData.holderChildrenUnder15YearsBirthDate,
            insurerPolicePenaltyInLast3Years: values.clientsData.insurerPolicePenaltyInLast3Years,
            insurerContractCancelledInLast3Years: values.clientsData.insurerContractCancelledInLast3Years
          },
          generalData: {
            ...calcData.generalData,
            ...values.generalData,
            crossSelling: { ...calcData.generalData.crossSelling, ...(values.generalData.crossSelling || {}) }
          },
          reinsurancesData: selectedResult.calcType === CalcType.MTPL
            ? {
              ...calcData.reinsurancesData,
              gap: false,
              gapComplicityReinsurance: false,
              gapDuration: null,
              replacementVehicle: false,
              replacementVehicleCategory: null,
              generaliAbroadVehicleRepair: false
            } : {
              ...calcData.reinsurancesData,
              animal: false,
              element: false,
              theftAndVandalism: false,
              injury: false
            },
          informationObligationData: { ...values.informationObligationData, recommendedResult }
        });
      }
    })
  };

  handleReturnToCalculationClick = (): void => {
    this.props.onReturnToCalculationClick({
      formData: this.props.form.getFieldsValue() as VehicleGenForm,
      authorizedClientsCount: this.state.authorizedClientsCount
    });
  };

  handleGenResultReturnToCalculationClick = (): void => {
    this.props.onReturnToCalculationClick({
      formData: this.props.form.getFieldsValue() as VehicleGenForm,
      authorizedClientsCount: this.state.authorizedClientsCount
    }, true);
  };

  handleResultsShow = (): void => {
    this.setState({ resultsVisible: true });
  };

  handleResultsClose = (): void => {
    this.setState({ resultsVisible: false });
  };

  handleResultsGenerateOfferClick = (type: VehicleOfferCalcType): void => {
    this.setState({ resultsVisible: false });
    this.props.onGenerateOfferClick(type);
  };

  handleResultsGenerateContractClick = (result: CalcResult<VehicleCalcResultData>): void => {
    this.setState({ resultsVisible: false });
    if ( result.insuranceInstitution.institutionEnum === InstitutionEnum.ALLIANZ ||
      result.insuranceInstitution.institutionEnum === InstitutionEnum.WUSTENROT ) {
      this.props.form.setFieldsValue({ generalData: { paymentMethod: PaymentMethod.BANK_TRANSFER } });
    }
    this.props.onSelectedResultChange(result, () => this.resolveInformationObligationRecommendedResult());
  };

  handleAuthorizedClientAdd = (): void => {
    this.setState(previousState => ({ authorizedClientsCount: previousState.authorizedClientsCount + 1 }));
  };

  handleAuthorizedClientDelete = (): void => {
    this.setState(previousState => ({ authorizedClientsCount: previousState.authorizedClientsCount - 1 }));
  };

  handleClientChange = (client: Client, type: ClientFormType, omitValidation?: boolean, callback?: () => void): void => {
    this.props.onClientChange(client, type, () => {
      if ( client && !omitValidation ) {
        this.props.onValidateClient({
          prefix: `clientsData.clients[${this.createClientsIndicesMap().get(type)}]`,
          client: clientToCreateUpdateContractClient(client)
        });
      }

      if ( client && type === ClientFormType.INSURER && (client.type === ClientType.SELF_EMPLOYED || client.type === ClientType.LEGAL) ) {
        this.props.form.setFieldsValue({ "informationObligationData.clientExperience": ClientExperience.SUFFICIENT });
      }

      if ( callback ) {
        callback();
      }
    });
  };

  handleClientViolationErrorsChange = (violations: FieldConstraintViolation[], type: ClientFormType): void => {
    this.setState(previousState => {
      const clientsViolationErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...previousState.clientsViolationErrors]);
      if ( violations && violations.length > 0 ) {
        clientsViolationErrors.set(type, violations);
      }
      else {
        clientsViolationErrors.delete(type);
      }
      return { clientsViolationErrors };
    });
  };

  handleClientDuplicateErrorChange = (isDuplicate: boolean, type: ClientFormType): void => {
    if ( isDuplicate ) {
      this.setState(previousState =>
        contains(previousState.clientsDuplicateErrors, type)
          ? { clientsDuplicateErrors: previousState.clientsDuplicateErrors }
          : { clientsDuplicateErrors: [...previousState.clientsDuplicateErrors, type] });
    }
    else {
      this.setState(previousState => ({ clientsDuplicateErrors: previousState.clientsDuplicateErrors.filter(error => error !== type) }));
    }
  };

  handleRecommendedResultChange = (): void => {
    if ( this.state.recommendedResultConfirmationVisible ) {
      this.setState({ recommendedResultConfirmationVisible: false });
    }
  };

  processClientsViolationErrorsStateUpdate = (errorResponse: ValidationErrorResponse): void => {
    this.setState(previousState => ({
      clientsViolationErrors: new Map([
        ...previousState.clientsViolationErrors,
        ...processClientsDataViolations(errorResponse.violations, this.createClientsIndicesMap())
      ])
    }), () => this.props.onErrorResponseDelete());
  };

  createClientsIndicesMap = (): Map<ClientFormType, number> => {
    const { insurerRelation, ownerRelation } = this.props.calcData.clientsData;
    const { insurer, owner, authorized1, authorized2 } = this.props.clients;
    const indices = new Map<ClientFormType, number>();
    let index = 0;

    indices.set(ClientFormType.HOLDER, index++);

    if ( insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER ) {
      if ( insurer ) {
        indices.set(ClientFormType.INSURER, index++);
      }
    }
    else {
      indices.set(ClientFormType.INSURER, indices.get(ClientFormType.HOLDER));
    }

    switch ( ownerRelation ) {
      case VehicleOwnerRelation.SAME_AS_INSURER:
        indices.set(ClientFormType.OWNER, indices.has(ClientFormType.INSURER) ? indices.get(ClientFormType.INSURER) : index++);
        break;
      case VehicleOwnerRelation.SAME_AS_HOLDER:
        indices.set(ClientFormType.OWNER, indices.get(ClientFormType.HOLDER));
        break;
      case VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER:
        if ( owner ) {
          indices.set(ClientFormType.OWNER, index++);
        }
        break;
    }

    if ( resolveVehicleInsurerType(this.props.clients, insurerRelation) === ClientType.LEGAL ) {
      if ( authorized1 ) {
        indices.set(ClientFormType.AUTHORIZED_1, index++);
      }

      if ( authorized2 && this.state.authorizedClientsCount > 1 ) {
        indices.set(ClientFormType.AUTHORIZED_2, index);
      }
    }

    return indices;
  };

  createContractClientsArray = (): CreateUpdateContractClient[] => {
    const { insurerRelation, ownerRelation } = this.props.calcData.clientsData;
    const { holder, insurer, owner, authorized1, authorized2 } = this.props.clients;
    const createUpdateClients: CreateUpdateContractClient[] = [];

    createUpdateClients.push(clientToCreateUpdateContractClient(holder));

    if ( insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER && insurer ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(insurer));
    }

    if ( ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER && owner ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(owner));
    }

    if ( resolveVehicleInsurerType(this.props.clients, insurerRelation) === ClientType.LEGAL ) {
      if ( authorized1 ) {
        createUpdateClients.push(clientToCreateUpdateContractClient(authorized1));
      }
      if ( this.state.authorizedClientsCount > 1 && authorized2 ) {
        createUpdateClients.push(clientToCreateUpdateContractClient(authorized2));
      }
    }

    return createUpdateClients;
  };

  checkHasClientsError = (): boolean => {
    const { authorizedClientsCount, clientsViolationErrors, clientsDuplicateErrors } = this.state;
    const { insurer, owner, authorized1, authorized2 } = this.props.clients;
    const { insurerRelation, ownerRelation } = this.props.calcData.clientsData;

    if ( insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER &&
      (!insurer || clientsViolationErrors.has(ClientFormType.INSURER) || contains(clientsDuplicateErrors, ClientFormType.INSURER)) ) {
      return true;
    }

    if ( ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER &&
      (!owner || clientsViolationErrors.has(ClientFormType.OWNER) || contains(clientsDuplicateErrors, ClientFormType.OWNER)) ) {
      return true;
    }

    if ( resolveVehicleInsurerType(this.props.clients, insurerRelation) === ClientType.LEGAL ) {
      if ( !authorized1 || clientsViolationErrors.has(ClientFormType.AUTHORIZED_1) || contains(clientsDuplicateErrors, ClientFormType.AUTHORIZED_1) ) {
        return true;
      }
      if ( authorizedClientsCount > 1 &&
        (!authorized2 || clientsViolationErrors.has(ClientFormType.AUTHORIZED_2) || contains(clientsDuplicateErrors, ClientFormType.AUTHORIZED_2)) ) {
        return true;
      }
    }

    return false;
  };

  checkHasRecommendedResultError = (data: VehicleGenForm): boolean => {
    if ( this.state.recommendedResultConfirmationVisible && !data.informationObligationData.recommendedResultConfirmation ) {
      this.props.form.setFields({
        "informationObligationData.recommendedResultConfirmation": {
          value: data.informationObligationData.recommendedResultConfirmation,
          errors: [new Error(t("calc.vehicle.validations.recommendedResultConfirmation"))]
        }
      });
      return true;
    }
    return false;
  };

  resolveInformationObligationRecommendedResult = (): void => {
    const { selectedResult, calcResults } = this.props;
    const { vehicleData } = this.props.calcData;

    if ( selectedResult.calcType === CalcType.MTPL ) {
      let initialValue: string[] = null;

      if ( contains(M1_N1, vehicleData.category) && vehicleData.purpose === VehiclePurpose.NORMAL ) {
        const institutionResults = filterApplicableSuccessResults(calcResults.mtpl.find(resultsRow => resultsRow.length > 0
          && resultsRow[0].insuranceInstitution.institutionEnum === selectedResult.insuranceInstitution.institutionEnum));

        if ( institutionResults.length > 1 ) {
          const highestCoverage = institutionResults[institutionResults.length - 1];
          initialValue = [highestCoverage.insuranceInstitution.institutionEnum, highestCoverage.coverage];
        }
        else {
          const generaliResults = calcResults.mtpl.find(resultsRow => resultsRow.length > 0
            && resultsRow[0].insuranceInstitution.institutionEnum === InstitutionEnum.GENERALI);
          if ( generaliResults ) {
            const highestCoverage = generaliResults.find(generaliResult => generaliResult.coverage === "XL" && generaliResult.data);
            if ( highestCoverage ) {
              initialValue = [highestCoverage.insuranceInstitution.institutionEnum, highestCoverage.coverage];
            }
          }
        }
      }

      this.props.form.setFieldsValue({ "informationObligationData.recommendedResult": initialValue });
      if ( initialValue ) {
        this.setState({ recommendedResultConfirmationVisible: true }, () =>
          this.props.form.setFieldsValue({ "informationObligationData.recommendedResultConfirmation": false }));
      }
      else {
        this.setState({ recommendedResultConfirmationVisible: false });
      }
    }
    else if ( this.state.recommendedResultConfirmationVisible ) {
      this.setState({ recommendedResultConfirmationVisible: false });
    }
  };

  resolvePageTitle = (): string => {
    const { calcType, coverage, insuranceInstitution } = this.props.selectedResult;
    return `${t("calc.vehicle.titles.generation." + calcType)} - ${insuranceInstitution.name}`
      + (calcType !== CalcType.CRASH && coverage ? ` (${t("calc.vehicle.sections.coverage")} ${coverage})` : "")
  };

  componentDidMount(): void {
    if ( this.props.initialData ) {
      const { vehicleData, clientsData, generalData } = this.props.calcData;
      const { calcType, data } = this.props.selectedResult;
      const { institutionEnum } = this.props.selectedResult.insuranceInstitution;
      const formData = cloneDeep(this.props.initialData.formData);

      formData.vehicleData.damaged = formData.vehicleData.damaged || false;
      if ( !formData.vehicleData.damaged ) {
        delete formData.vehicleData.damageDescription;
      }

      if ( institutionEnum !== InstitutionEnum.UNIQA || calcType !== CalcType.CRASH ) {
        delete formData.vehicleData.standardKeys;
        delete formData.vehicleData.standardWheels;
        delete formData.vehicleData.nonStandardWheelsDesc;
        delete formData.vehicleData.priceSource;
      }
      else {
        formData.vehicleData.standardKeys = formData.vehicleData.standardKeys || false;
        formData.vehicleData.standardWheels = formData.vehicleData.standardWheels || false;
        formData.vehicleData.priceSource = (data as CrashCalcResultData).vehiclePriceCalculatedByUniqa
          ? VehiclePriceSource.INSURANCE_COMPANY_PRICE_LIST : formData.vehicleData.priceSource;

        if ( formData.vehicleData.standardWheels ) {
          delete formData.vehicleData.nonStandardWheelsDesc;
        }
      }

      if ( formData.vehicleData.key ) {
        if ( institutionEnum !== InstitutionEnum.ALLIANZ
          && !(institutionEnum === InstitutionEnum.GENERALI && calcType === CalcType.CRASH)
          && institutionEnum !== InstitutionEnum.UNIQA ) {
          delete formData.vehicleData.key.numberOfPieces;
        }
        if ( !(institutionEnum === InstitutionEnum.GENERALI && calcType === CalcType.CRASH) ) {
          delete formData.vehicleData.key.numberOfCopies;
        }
      }

      if ( institutionEnum !== InstitutionEnum.UNION || calcType !== CalcType.CRASH || !contains(M1_N1, vehicleData.category) ) {
        delete formData.vehicleData.equipment;
      }

      if ( institutionEnum !== InstitutionEnum.UNIQA ) {
        delete formData.vehicleData.security;
      }

      if ( (institutionEnum !== InstitutionEnum.ALLIANZ && institutionEnum !== InstitutionEnum.AXA_NON_LIFE)
        || this.props.calcData.type !== CalcType.MTPL || !contains(SPECS_MAP.get("odometer"), vehicleData.category) ) {
        delete formData.vehicleData.odometer;
      }

      if ( institutionEnum !== InstitutionEnum.COLONNADE || calcType !== CalcType.GAP ) {
        delete formData.vehicleData.purchaseDate;
      }

      if ( clientsData.insurerRelation !== VehicleInsurerRelation.DIFFERENT_FROM_HOLDER ) {
        delete formData.clientsData.insurerIdentifier;
      }

      if ( clientsData.ownerRelation !== VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER ) {
        delete formData.clientsData.ownerIdentifier;
      }

      const insurerType = resolveVehicleInsurerType(this.props.clients, clientsData.insurerRelation);
      if ( insurerType !== ClientType.LEGAL ) {
        delete formData.clientsData.authorizedClient1Identifier;
        delete formData.clientsData.authorizedClient1Function;
        delete formData.clientsData.authorizedClient2Identifier;
        delete formData.clientsData.authorizedClient2Function;
      }
      else if ( this.props.initialData.authorizedClientsCount === 1 ) {
        delete formData.clientsData.authorizedClient2Identifier;
        delete formData.clientsData.authorizedClient2Function;
      }

      if ( institutionEnum !== InstitutionEnum.GROUPAMA || !clientsData.holderChildrenUnder15Years ) {
        delete formData.clientsData.holderChildrenUnder15YearsName;
        delete formData.clientsData.holderChildrenUnder15YearsBirthDate;
      }

      if ( institutionEnum !== InstitutionEnum.CSOB ) {
        delete formData.clientsData.insurerPolicePenaltyInLast3Years;
        delete formData.clientsData.insurerContractCancelledInLast3Years;
      }
      else {
        formData.clientsData.insurerPolicePenaltyInLast3Years = formData.clientsData.insurerPolicePenaltyInLast3Years || false;
        formData.clientsData.insurerContractCancelledInLast3Years = formData.clientsData.insurerContractCancelledInLast3Years || false;
      }

      if ( institutionEnum === InstitutionEnum.ALLIANZ
        || institutionEnum === InstitutionEnum.CSOB
        || institutionEnum === InstitutionEnum.WUSTENROT ) {
        formData.generalData.paymentMethod = PaymentMethod.BANK_TRANSFER;
      }

      if ( institutionEnum !== InstitutionEnum.ALLIANZ
        && institutionEnum !== InstitutionEnum.AXA_NON_LIFE
        && institutionEnum !== InstitutionEnum.GENERALI
        && institutionEnum !== InstitutionEnum.KOMUNALNA
        && institutionEnum !== InstitutionEnum.KOOPERATIVA ) {
        delete formData.generalData.signCity;
      }

      if ( institutionEnum !== InstitutionEnum.ALLIANZ || calcType !== CalcType.CRASH ) {
        delete formData.generalData.mtplInsuranceEffectiveBeginningDate;
        delete formData.additionalData;
      }

      if ( institutionEnum !== InstitutionEnum.GROUPAMA ) {
        delete formData.generalData.otherMtplInsurance;
      }
      else {
        formData.generalData.otherMtplInsurance = formData.generalData.otherMtplInsurance || false;
      }

      if ( institutionEnum !== InstitutionEnum.COLONNADE || calcType !== CalcType.GAP ) {
        delete formData.generalData.gapComplicityReinsurance;
        delete formData.generalData.gapDuration;
        delete formData.generalData.gapPaymentFrequency;
      }
      else {
        formData.generalData.gapComplicityReinsurance = formData.generalData.gapComplicityReinsurance || false;
      }

      if ( formData.generalData.crossSelling ) {
        if ( institutionEnum !== InstitutionEnum.GROUPAMA || !generalData.crossSelling.groupamaContract ) {
          delete formData.generalData.crossSelling.groupamaContractNumber;
        }
        if ( institutionEnum !== InstitutionEnum.UNION || !generalData.crossSelling.unionHealthContract ) {
          delete formData.generalData.crossSelling.unionHealthContractNumber;
        }
        if ( institutionEnum !== InstitutionEnum.WUSTENROT || !generalData.crossSelling.wustenrotContractsCount ) {
          delete formData.generalData.crossSelling.wustenrotContractNumbers;
        }
      }

      formData.informationObligationData.clientExperience = insurerType === ClientType.SELF_EMPLOYED || insurerType === ClientType.LEGAL
        ? ClientExperience.SUFFICIENT : formData.informationObligationData.clientExperience;
      delete formData.informationObligationData.recommendedResult;
      delete formData.informationObligationData.recommendedResultConfirmation;
      delete formData.informationObligationData.recommendationReason;
      if ( calcType === CalcType.CRASH ) {
        delete formData.informationObligationData.otherRecommendationReasons;
      }

      this.props.form.setFieldsValue(formData);

      const clients = this.createContractClientsArray();
      if ( clients.length > 1 ) {
        this.props.onValidateClients({ prefix: "clientsData.clients", clients });
      }
    }

    this.resolveInformationObligationRecommendedResult();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( !prevProps.genResult && this.props.genResult && this.props.genResult.error ) {
      const violations = processGenResultError(this.props.genResult.error, this.createClientsIndicesMap(),
        this.props.form, "calc.vehicle.attrs");

      if ( violations ) {
        messageUtils.errorNotification(
          this.props.genResult.error.message,
          <FieldViolationsView violations={violations.notificationViolations} rootPath="calc.vehicle.attrs" />, 10);
        this.setState({ clientsViolationErrors: violations.clientsViolations });
      }
    }

    if ( this.props.validateClientErrorResponse ) {
      this.processClientsViolationErrorsStateUpdate(this.props.validateClientErrorResponse)
    }
    else if ( this.props.validateClientsErrorResponse ) {
      this.processClientsViolationErrorsStateUpdate(this.props.validateClientsErrorResponse);
    }
  }

  render(): React.ReactNode {
    const { initialData, form, calcData, clients, calcResults, selectedResult } = this.props;

    const formLayout: ColProps = { span: 24, style: { maxWidth: "1150px" } };

    return (
      <>
        <h2>{this.resolvePageTitle()}</h2>

        <Row gutter={64} className="margin-top-small" justify="center">
          <Col {...formLayout}>
            <Form layout="vertical">

              <VehicleGenVehicleDataSection
                form={form}
                initialVehicleData={initialData ? initialData.formData.vehicleData : null}
                calcData={calcData}
                selectedResult={selectedResult} />

              <VehicleGenClientsDataSection
                form={form}
                initialClientsData={initialData ? initialData.formData.clientsData : null}
                clientsData={calcData.clientsData}
                selectedResult={selectedResult}
                clients={clients}
                authorizedClientsCount={this.state.authorizedClientsCount}
                clientsAutocomplete={this.props.clientsAutocomplete}
                clientsDuplicateErrors={this.state.clientsDuplicateErrors}
                clientsViolationErrors={this.state.clientsViolationErrors}
                onAuthorizedClientAdd={this.handleAuthorizedClientAdd}
                onAuthorizedClientDelete={this.handleAuthorizedClientDelete}
                onClientChange={this.handleClientChange}
                onClientViolationErrorsChange={this.handleClientViolationErrorsChange}
                onClientDuplicateErrorChange={this.handleClientDuplicateErrorChange} />

              <VehicleGenOtherDataSection
                form={form}
                initialGeneralData={initialData ? initialData.formData.generalData : null}
                insurerType={resolveVehicleInsurerType(clients, calcData.clientsData.insurerRelation)}
                calcData={calcData}
                selectedResult={selectedResult}
                calcResults={calcResults}
                recommendedResultConfirmationVisible={this.state.recommendedResultConfirmationVisible}
                onRecommendedResultChange={this.handleRecommendedResultChange} />

            </Form>
          </Col>
        </Row>

        <VehicleCalcResultsView
          visible={this.state.resultsVisible}
          calcType={calcData.type as VehicleCalcType}
          calcResults={calcResults}
          selectedResult={selectedResult}
          onClose={this.handleResultsClose}
          onGenerateOfferClick={this.handleResultsGenerateOfferClick}
          onGenerateContractClick={this.handleResultsGenerateContractClick} />

        <VehicleGenNavigation
          calcType={selectedResult.calcType}
          onFormSubmit={this.handleGenerateFormSubmit}
          onShowResultsClick={this.handleResultsShow}
          onReturnToCalculationClick={this.handleReturnToCalculationClick} />

        <CalcGenResultModal
          result={this.props.genResult}
          showReturnToCalculationButton={calcData.type === CalcType.CRASH || calcData.type === CalcType.MTPL_CRASH}
          onCancel={this.props.onGenResultDelete}
          onReturnToCalculationClick={this.handleGenResultReturnToCalculationClick} />
      </>
    )
  }
}

export default Form.create<Props>()(VehicleGenWrapper);
