import React, { ReactText } from "react";
import { Checkbox, Col, Divider, Input, InputNumber, Row } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import validations from "../../../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import InputNumberWithAddon from "../../../../../../../common/components/form/components/InputNumberWithAddon";
import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";

export interface Props {
  form: WrappedFormUtils;
  initialFloorFrom: number;
}

interface State {
  readonly floorFrom: number;
  readonly topFloorCheckboxDisabled: boolean;
}

interface FloorPopoverProps {
  label: string;
}

const FloorPopoverLabel = ({ label }: FloorPopoverProps) => (
  <LabelWithPopover
    label={label}
    popoverTitle={t("calc.realty.helpers.apartmentFloor")}
    popoverContent={
      <table className="data-table-view only-first-bold-col">
        <thead>
        <tr>
          <th>{t("calc.realty.helpers.floor.valueHeader")}</th>
          <th>{t("calc.realty.helpers.floor.floorHeader")}</th>
          <th>{t("calc.realty.helpers.floor.overgroundFloorHeader")}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>{t("calc.realty.helpers.floor.groundFloor")}</td>
          <td>{t("calc.realty.helpers.floor.overgroundFloor", { value: 1 })}</td>
        </tr>
        {[2, 3, 4, 5].map(value =>
          <tr key={value}>
            <td>{value}</td>
            <td>{t("calc.realty.helpers.floor.floor", { value: value - 1 })}</td>
            <td>{t("calc.realty.helpers.floor.overgroundFloor", { value: value })}</td>
          </tr>)}
        <tr>
          <td>...</td>
          <td>...</td>
          <td>...</td>
        </tr>
        </tbody>
      </table>
    }
  />
);

class RealtyCalcApartmentStep extends React.Component<Props, State> {
  readonly state: State = {
    floorFrom: this.props.initialFloorFrom,
    topFloorCheckboxDisabled: this.props.initialFloorFrom === 1
  };

  handleFloorFromChange = (floorFrom: ReactText): void => {
    const topFloorCheckboxDisabled = floorFrom === 1;
    if ( topFloorCheckboxDisabled ) {
      this.props.form.setFieldsValue({ "apartmentData.topFloor": false });
    }
    this.setState({ floorFrom: floorFrom as number, topFloorCheckboxDisabled });
  };

  render(): React.ReactNode {
    const { floorFrom, topFloorCheckboxDisabled } = this.state;
    const { getFieldDecorator } = this.props.form;
    const colSpan = 8;

    return (
      <>
        <Row gutter={rowGutter}>
          <Divider>{t("calc.realty.sections.apartment")}</Divider>

          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.apartmentData.apartmentNumber")}>
              {getFieldDecorator("apartmentData.apartmentNumber", { rules: [validations.none] })(
                <Input />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item label={<FloorPopoverLabel label={t("calc.realty.attrs.apartmentData.floorFrom")} />}>
              {getFieldDecorator("apartmentData.floorFrom", {
                rules: [validations.notNull], initialValue: floorFrom
              })(
                <InputNumber min={1} onChange={this.handleFloorFromChange} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={<FloorPopoverLabel label={t("calc.realty.attrs.apartmentData.floorTo")} />}>
              {getFieldDecorator("apartmentData.floorTo", {
                rules: [validations.notNull, validations.minNumber(floorFrom, t("calc.realty.attrs.apartmentData.floorFrom"))]
              })(
                <InputNumber min={floorFrom || 1} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item className="form-item-without-label">
              {getFieldDecorator("apartmentData.topFloor", {
                rules: [validations.none], valuePropName: "checked", initialValue: topFloorCheckboxDisabled
              })(
                <Checkbox disabled={this.state.topFloorCheckboxDisabled}>
                  {t("calc.realty.attrs.apartmentData.topFloor")}
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Divider className="divider-subheader">{t("calc.realty.sections.areas")}</Divider>

          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.apartmentData.floorArea")}>
              {getFieldDecorator("apartmentData.floorArea", { rules: [validations.notNull] })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={1} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.apartmentData.balconyArea")}>
              {getFieldDecorator("apartmentData.balconyArea", { rules: [validations.none] })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={0} />
              )}
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item label={t("calc.realty.attrs.apartmentData.cellarAndGarageArea")}>
              {getFieldDecorator("apartmentData.cellarAndGarageArea", { rules: [validations.none] })(
                <InputNumberWithAddon addonType="area" formatStyle="integer" min={0} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
}

export default RealtyCalcApartmentStep;
