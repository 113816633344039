import "./input-number-with-addon.less";
import React from "react";
import { Button, Input, InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import { inputNumberDecimalFormatter, inputNumberIntegerFormatter } from "../../../utils/formUtils";
import t from "../../../../app/i18n";

export interface Props extends InputNumberProps {
  addonType: "euro" | "piece" | "area" | "weight" | "distance" | "enginePower" | "engineDisplacement" | "percentage";
  formatStyle?: "integer" | "decimal"
}

class InputNumberWithAddon extends React.Component<Props> {

  render(): React.ReactNode {
    let formatProps = undefined;
    switch ( this.props.formatStyle ) {
      case "integer":
        formatProps = inputNumberIntegerFormatter;
        break;
      case "decimal":
        formatProps = { ...inputNumberDecimalFormatter, precision: 2, decimalSeparator: "," };
        break;
    }

    let addonComponent;
    switch ( this.props.addonType ) {
      case "area":
        addonComponent = <Button className="addon-component" disabled>m<sup>2</sup></Button>;
        break;
      case "engineDisplacement":
        addonComponent = <Button className="addon-component" disabled>cm<sup>3</sup></Button>;
        break;
      case "percentage":
        addonComponent = <Input className="addon-component" disabled value={"%"} />
        break;
      default:
        addonComponent = <Input className="addon-component" disabled
                                value={(t("common.sign." + this.props.addonType) as string)} />;
        break;
    }

    return (
      <Input.Group className="input-number-with-addon" compact>
        <InputNumber className="main-component" {...this.props} {...formatProps} />
        {addonComponent}
      </Input.Group>
    );
  }
}

export default InputNumberWithAddon;
