import React from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { CreateUpdateInstitution } from "../../types";
import { InstitutionEnum, InstitutionType } from "../../enums";
import { createInstitutionActions } from "../../ducks";
import { selectStandardProps, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../common/constants";
import { requests } from "../../api";
import t from "../../../../../app/i18n";

import AddressForm from "../../../../../common/modules/address/AddressForm";

export interface Props {
  onFormSubmit: typeof createInstitutionActions.request;
}

const InstitutionCreateForm = ({ onFormSubmit }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "admin.institution.attrs", requests.CREATE_INSTITUTION);

  const handleFormFinish = (values: CreateUpdateInstitution): void => {
    onFormSubmit({ ...values, deactivated: false });
  };

  const colSpan = 6;

  return (
    <>
      <h2 className="margin-bottom-large">{t("admin.institution.titles.new")}</h2>

      <Form form={form} layout="vertical" className="standard-container" name="institutionCreateForm"
            onFinish={handleFormFinish}>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="type"
              label={t("admin.institution.enums.institutionType._label")}
              rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(InstitutionType).map(type => ({
                  value: type,
                  label: t("admin.institution.enums.institutionType." + type)
                }))} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="institutionEnum"
              label={t("admin.institution.enums.institutionEnum._label")}
              rules={[validations.none]}>
              <Select
                {...selectStandardProps} allowClear
                options={Object.keys(InstitutionEnum).map(institutionEnum => ({
                  value: institutionEnum,
                  label: t("admin.institution.enums.institutionEnum." + institutionEnum)
                }))} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("admin.institution.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 64)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan * 2}>
            <Form.Item
              name="fullName"
              label={t("admin.institution.attrs.fullName")}
              rules={[validations.notBlank, validations.size(1, 1024)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item
              name="companyRegistrationNumber"
              label={t("admin.institution.attrs.companyRegistrationNumber")}
              rules={[validations.notBlank, validations.crn]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <AddressForm form={form} rootKey={["address"]} label={t("admin.institution.attrs.address")} />
          </Col>
        </Row>

        <Button className="margin-top-medium" type="primary" htmlType="submit" icon={<SaveOutlined />}>
          {t("common.save")}
        </Button>

      </Form>
    </>
  );
}

export default InstitutionCreateForm;
