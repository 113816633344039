import { ReactNode } from "react";
import { message, notification } from "antd";
import { MessageType } from "antd/lib/message";
import { NotificationPlacement } from "antd/lib/notification";
import t from "../../app/i18n";

const infoMessage = (content: string, duration: number = 5): MessageType => message.info(content, duration);
const loadingMessage = (content: string, duration: number = 5): MessageType => message.loading(content, duration);
const successMessage = (content: string, duration: number = 5): MessageType => message.success(content, duration);
const warnMessage = (content: string, duration: number = 5): MessageType => message.warn(content, duration);
const errorMessage = (content: string, duration: number = 5): MessageType => message.error(content, duration);

const infoNotification = (message: string, description: string | ReactNode, duration: number = 5, placement: NotificationPlacement = "topRight"): void =>
  notification.info({ message, description, duration, placement });

const successNotification = (message: string, description: string | ReactNode, duration: number = 5, placement: NotificationPlacement = "topRight"): void =>
  notification.success({ message, description, duration, placement });

const warnNotification = (message: string, description: string | ReactNode, duration: number = 5, placement: NotificationPlacement = "topRight"): void =>
  notification.warn({ message, description, duration, placement });

const errorNotification = (message: string, description: string | ReactNode, duration: number = 5, placement: NotificationPlacement = "topRight"): void =>
  notification.error({ message, description, duration, placement });

const itemCreatedNotification = (): void => successNotification(t("common.operationSuccess"), t("common.createOperationSuccess"));

const itemUpdatedNotification = (): void => successNotification(t("common.operationSuccess"), t("common.updateOperationSuccess"));

export default {
  infoMessage,
  loadingMessage,
  successMessage,
  warnMessage,
  errorMessage,
  infoNotification,
  successNotification,
  warnNotification,
  errorNotification,
  itemCreatedNotification,
  itemUpdatedNotification
};
