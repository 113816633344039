import React from "react";
import { FormInstance } from "antd/lib/form";
import { PersonType } from "../../../enums";
import t from "../../../../../app/i18n";

import AddressForm from "../../../../../common/modules/address/AddressForm";

export interface Props {
  form: FormInstance;
  personType: PersonType;
  serviceAddressEnabled: boolean;
  correspondenceAddressEnabled: boolean;
  onServiceAddressEnabledChange(enabled: boolean): void;
  onCorrespondenceAddressEnabledChange(enabled: boolean): void;
}

const PersonAddressesFormPart = (props: Props) => (
  <>
    <AddressForm
      form={props.form}
      rootKey={["address"]}
      required
      label={props.personType === PersonType.LEGAL ? t("person.attrs.addressLongPO") : t("person.attrs.addressLong")} />

    <AddressForm
      form={props.form}
      rootKey={["serviceAddress"]}
      label={t("person.attrs.serviceAddress")}
      switchProps={{
        enabled: props.serviceAddressEnabled,
        onEnabledChange: props.onServiceAddressEnabledChange
      }} />

    <AddressForm
      form={props.form}
      rootKey={["correspondenceAddress"]}
      label={t("person.attrs.correspondenceAddress")}
      switchProps={{
        enabled: props.correspondenceAddressEnabled,
        onEnabledChange: props.onCorrespondenceAddressEnabledChange
      }} />
  </>
);

export default PersonAddressesFormPart;
