export enum AutocompleteVehiclesBy {
  LICENSE_PLATE = 'LICENSE_PLATE',
  VIN = 'VIN'
}

export enum VehicleMappingType {
  ALLIANZ = 'ALLIANZ',
  AXA = 'AXA',
  COLONNADE = 'COLONNADE',
  CSOB = 'CSOB',
  GENERALI = 'GENERALI',
  GROUPAMA = 'GROUPAMA',
  KOMUNALNA = 'KOMUNALNA',
  KOOPERATIVA = 'KOOPERATIVA',
  UNION_MTPL = 'UNION_MTPL',
  UNION_CRASH = 'UNION_CRASH',
  UNIQA = 'UNIQA'
}
