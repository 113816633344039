import React from "react";
import { Card, Divider, Popconfirm, Table, Upload } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Contract, ContractAttachment } from "../../../types";
import { deleteContractAttachmentActions, uploadContractAttachmentsActions } from "../../../ducks";
import { formatFileSize, formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import t from "../../../../../app/i18n";

import AddDeleteButton from "../../../../../common/components/buttons/AddDeleteButton";
import FileTypeIcon from "../../../../../common/components/icons/FileTypeIcon";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import ActionLinkIcon from "../../../../../common/components/icons/ActionLinkIcon";

export interface Props {
  contract: Contract;
  onUpload: typeof uploadContractAttachmentsActions.request;
  onDelete: typeof deleteContractAttachmentActions.request;
}

const ContractAttachmentsSection = ({ contract, ...props }: Props) => {

  const handleAttachmentsUpload = (file: RcFile, fileList: RcFile[]): boolean => {
    if ( fileList.length > 0 ) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      props.onUpload({ id: contract.id, object: formData });
    }

    return false;
  };

  const columns: ColumnsType<ContractAttachment> = [
    {
      key: "createdAt",
      title: t("contract.sections.attachments.createdAt"),
      render: (value, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "filename",
      title: t("contract.sections.attachments.filename"),
      render: (value, record) => <><FileTypeIcon contentType={record.contentType} /> {record.filename}</>
    },
    {
      key: "size",
      title: t("contract.sections.attachments.size"),
      render: (value, record) => formatFileSize(record.size)
    },
    {
      key: "sentToClient",
      title: t("contract.sections.attachments.sentToClient"),
      render: (value, record) => record.sentToClient ? <CheckOutlined /> : <CloseOutlined />
    },
    {
      key: "actions",
      className: "table-column-right-align",
      render: (text, record) => (
        <>
          <ActionLinkIcon path={`/contracts/${contract.id}/attachments/${record.id}`}
                          target="_blank" type="open" color="blue" text={t("common.open")} />

          {record.isDeletable && (
            <>
              <Divider type="vertical" />

              <Popconfirm
                title={t("contract.helpers.deleteAttachmentConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => props.onDelete({ id1: contract.id, id2: record.id })}>
                <span><ActionTextIcon type="delete" color="red" text={t("common.delete")} /></span>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <Card size="small" type="inner" className="card-box margin-top-medium"
          title={t("contract.sections.attachments.label")}>
      <Table<ContractAttachment>
        size="small"
        className="table-row-stripped"
        rowKey="id"
        columns={columns}
        dataSource={contract.attachments}
        pagination={false} />

      <div className="margin-top-small margin-bottom-small">
        <Upload
          multiple
          showUploadList={false}
          beforeUpload={handleAttachmentsUpload}>
          <AddDeleteButton type="add" label={t("contract.actions.addAttachment")} />
        </Upload>
      </div>
    </Card>
  )
};

export default ContractAttachmentsSection;
