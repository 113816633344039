import React from "react";
import { Col, Input, Modal, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CreateVehicleMapping, CreateVehicleMappingFormItem, VehicleMappingData } from "../../types";
import { ValidationErrorResponse } from "../../../../types";
import { VehicleMappingType } from "../../enums";
import { createVehicleBrandMappingActions } from "../../ducks";
import { deleteStateValidationErrorResponseAction } from "../../../../ducks";
import { parseVehicleMappingsFromIdentifiers, VEHICLE_IDENTIFIER_DELIMITER, VEHICLE_TYPES_PATH_MAP } from "../../utils";
import { contains } from "../../../../../common/utils/utils";
import validations from "../../../../../common/utils/validationUtils";
import { selectStandardProps, setErrorsToForm_deprecated } from "../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

export interface Props extends FormComponentProps<CreateVehicleMappingFormItem> {
  visible: boolean;
  selectedMappingTypes: VehicleMappingType[];
  mappingData: VehicleMappingData[];
  errorResponse: ValidationErrorResponse;
  inProgress: boolean;
  locationKey: string;
  onFormSubmit: typeof createVehicleBrandMappingActions.request;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
  onFormCancel(): void;
}

class VehicleBrandCreateMappingModal extends React.Component<Props> {

  handleFormSubmit = (): void => {
    this.props.form.validateFields((errors, values) => {
      if ( !errors ) {
        this.props.onFormSubmit(parseVehicleMappingsFromIdentifiers(values) as CreateVehicleMapping);
      }
    });
  };

  handleFormCancel = (): void => {
    this.props.form.resetFields();
    this.props.onFormCancel();
  };

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return nextProps.visible || this.props.visible;
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( this.props.errorResponse ) {
      setErrorsToForm_deprecated(this.props.form, this.props.errorResponse.violations, "calc.vehicles.attrs");
      this.props.onErrorResponseDelete();
    }
    else if ( this.props.visible && prevProps.locationKey !== this.props.locationKey ) {
      this.handleFormCancel();
    }
  }

  render(): React.ReactNode {
    const { getFieldDecorator } = this.props.form;
    const colSpan = 6;

    return (
      <Modal
        width={900}
        visible={this.props.visible}
        maskClosable={false}
        confirmLoading={this.props.inProgress}
        title={t("calc.vehicles.titles.brandCreate")}
        okText={t("common.save")}
        cancelText={t("common.cancel")}
        onOk={this.handleFormSubmit}
        onCancel={this.handleFormCancel}>

        <Form layout="vertical">
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item label={t("calc.vehicles.attrs.name")}>
                {getFieldDecorator("name", { rules: [validations.notBlank] })(
                  <Input size="small" />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            {Object.values(VehicleMappingType).map((type, index) => {
              const typePath = VEHICLE_TYPES_PATH_MAP.get(type);
              const mappings = this.props.mappingData.find(mapping => mapping.type === type);

              return contains(this.props.selectedMappingTypes, type) ? (
                <Col span={colSpan} key={index}>
                  <Form.Item label={t(`calc.vehicles.attrs.${typePath}Identifier`)}>
                    {getFieldDecorator(`${typePath}Identifier`, { rules: [validations.none] })(
                      <Select
                        {...selectStandardProps} allowClear size="small"
                        options={(mappings && mappings.brands ? mappings.brands : []).map(item => ({
                          value: item.id + VEHICLE_IDENTIFIER_DELIMITER + item.name,
                          label: `${item.name} (${item.id})`
                        }))} />
                    )}
                  </Form.Item>
                </Col>
              ) : null;
            })}
          </Row>
        </Form>

      </Modal>
    );
  }
}

export default Form.create<Props>()(VehicleBrandCreateMappingModal);
