import t from "../../../app/i18n";

export enum OperationType {
  CALCULATE = 'CALCULATE',
  RECALCULATE = 'RECALCULATE',
  GENERATE = 'GENERATE'
}

export const operationTypeTMap = new Map<OperationType, string>(
  Object.values(OperationType).map(type => [type, t("calc.records.enums.operationType." + type)])
);
