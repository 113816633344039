import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <Route exact path="/reports" render={() => <Redirect to="/reports/statistics" />} />
    <Route exact path="/reports/statistics" render={() => <h2>Reports / statistics</h2>} />

    <Route component={NotFound} />
  </Switch>
);
