import React from "react";
import { Col, DatePicker, Form, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { regexPatterns, validations } from "../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerPresentAndFuture,
  fillBirthDateFromPinChangeEvent,
  ibanNormalizeFunction,
  upperCaseStringNormalizeFunction
} from "../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

export interface Props {
  form: FormInstance;
}

const SelfEmployedPersonDataFormPart = ({ form }: Props) => {
  const colSpan = 6;

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="companyName"
            label={t("person.attrs.companyNameFOP")}
            rules={[validations.notBlank, validations.size(1, 255)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="academicDegree"
            label={t("person.attrs.academicDegree")}
            rules={[validations.size(1, 64), validations.academicDegree]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="firstName"
            label={t("person.attrs.firstName")}
            rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="lastName"
            label={t("person.attrs.lastName")}
            rules={[validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="academicDegreeAfter"
            label={t("person.attrs.academicDegreeAfter")}
            rules={[validations.size(1, 64), validations.academicDegreeAfter]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="personalIdentificationNumber"
            label={t("person.attrs.personalIdentificationNumberLong")}
            rules={[validations.notBlank, validations.pin]}>
            <Input onChange={event => fillBirthDateFromPinChangeEvent(event, form)} />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="birthDate"
            label={t("person.attrs.birthDate")}
            rules={[validations.notNull, validations.notPresentAndFuture]}>
            <DatePicker {...datePickerStandardProps} showToday={false}
                        disabledDate={disableDatePickerPresentAndFuture} />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="identityCardNumber"
            label={t("person.attrs.identityCardNumber")}
            rules={[validations.size(6, 10), validations.pattern(regexPatterns.idCardRegex)]}
            normalize={upperCaseStringNormalizeFunction}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="bankAccountNumber"
            label={t("person.attrs.bankAccountNumber")}
            rules={[validations.size(1, 34), validations.iban]}
            normalize={ibanNormalizeFunction}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="webPage"
            label={t("person.attrs.webPage")}
            rules={[validations.size(1, 2048), validations.pattern(regexPatterns.webPageRegex)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="companyRegistrationNumber"
            label={t("person.attrs.companyRegistrationNumber")}
            rules={[validations.notBlank, validations.crn]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="districtOffice"
            label={t("person.attrs.districtOffice")}
            rules={[validations.size(1, 255)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="taxId"
            label={t("person.attrs.taxId")}
            rules={[validations.length(10), validations.numeric]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item
            name="tradeRegisterNumber"
            label={t("person.attrs.tradeRegisterNumber")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.tradeRegNumRegex)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="vatId"
            label={t("person.attrs.vatId")}
            rules={[validations.length(12), validations.pattern(regexPatterns.vatIdRegex)]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan * 2}>
          <Form.Item
            name="note"
            label={t("person.attrs.note")}
            rules={[validations.size(1, 8192)]}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default SelfEmployedPersonDataFormPart;
