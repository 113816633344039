import React from "react";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { NearbyBuildingReinsurance } from "../../../../types";
import t from "../../../../../../../../app/i18n";

import AddDeleteButton from "../../../../../../../../common/components/buttons/AddDeleteButton";
import RealtyCalcReinsurancesSectionItem from "./RealtyCalcReinsurancesSectionItem";

export interface Props {
  form: WrappedFormUtils;
  initialReinsurances: NearbyBuildingReinsurance[];
}

interface State {
  readonly reinsuranceItemsSize: number;
}

class RealtyCalcReinsurancesSection extends React.Component<Props, State> {
  readonly state: State = {
    reinsuranceItemsSize: this.props.initialReinsurances ? this.props.initialReinsurances.length : 0
  };

  handleReinsuranceItemDelete = (index: number): void => {
    const reinsuranceItems: NearbyBuildingReinsurance[] = this.props.form.getFieldValue("realtyInsuranceData.nearbyBuildingReinsurances");
    reinsuranceItems.splice(index, 1);

    this.setState(previousState => ({ reinsuranceItemsSize: previousState.reinsuranceItemsSize - 1 }));
    this.props.form.setFieldsValue({ "realtyInsuranceData.nearbyBuildingReinsurances": reinsuranceItems });
  };

  handleReinsuranceItemAdd = (): void => {
    this.setState(previousState => ({ reinsuranceItemsSize: previousState.reinsuranceItemsSize + 1 }));
  };

  render(): React.ReactNode {
    const { initialReinsurances } = this.props;

    const buildingItems = [];
    for ( let index = 0; index < this.state.reinsuranceItemsSize; index++ ) {
      buildingItems.push(
        <RealtyCalcReinsurancesSectionItem
          key={index}
          form={this.props.form}
          index={index}
          initialBuildingType={initialReinsurances && initialReinsurances[index] ? initialReinsurances[index].type : undefined}
          onDelete={this.handleReinsuranceItemDelete} />
      )
    }

    return (
      <>
        {buildingItems}
        <AddDeleteButton type="add" label={t("calc.realty.actions.nearbyBuildingAdd")}
                         onClick={this.handleReinsuranceItemAdd} />
      </>
    );
  }
}

export default RealtyCalcReinsurancesSection;
