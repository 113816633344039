import React, { FormEvent } from "react";
import { Moment } from "moment";
import { Button, Col, Divider, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form/Form";
import { Client, CreateUpdateClient, CreateUpdateNaturalClient, NaturalClient, SelfEmployedClient } from "../../types";
import { ClientType } from "../../enums";
import { ContactType } from "../../../../common/modules/enums";
import { removeStringWhiteSpaces } from "../../../../common/utils/utils";
import {
  fillBirthDateFromPin_deprecated,
  getAllRootFieldsNames_deprecated,
  momentToDateString,
  toMoment
} from "../../../../common/utils/formUtils";
import t from "../../../../app/i18n";

import NaturalClientDataFormPart from "./parts/NaturalClientDataFormPart";
import SelfEmployedClientDataFormPart from "./parts/SelfEmployedClientDataFormPart";
import LegalClientDataFormPart from "./parts/LegalClientDataFormPart";
import ClientAddressesFormPart from "./parts/ClientAddressesFormPart";
import ContactsFormDeprecated from "../../../../common/modules/contact/ContactsForm_Deprecated";
import HiddenInputDeprecated from "../../../../common/components/form/components/HiddenInput_Deprecated";

export interface ClientUpdateFormComponent extends React.Component<Props, State> {
  handleFormSubmit(event?: FormEvent): void;
  handleCorrespondenceAddressEnabledChange(enabled: boolean): void;
  validateFormFields(): void;
}

export interface Props extends FormComponentProps<CreateUpdateClient> {
  client: Client;
  hideActionButtons?: boolean;
  disablePinInput?: boolean;
  disableCrnInput?: boolean;
  disableFormSubmitValidation?: boolean;
  onFormSubmit(clientData: CreateUpdateClient, errors?: any): void;
  onCancelClick?(): void;
}

interface State {
  readonly correspondenceAddressEnabled: boolean;
}

class ClientUpdateForm extends React.Component<Props, State> implements ClientUpdateFormComponent {
  readonly state: State = {
    correspondenceAddressEnabled: !!this.props.client.correspondenceAddress
  };

  handleFormSubmit = (event?: FormEvent): void => {
    if ( event ) {
      event.preventDefault();
    }

    if ( this.props.disableFormSubmitValidation ) {
      this.props.onFormSubmit(this.processAndGetFormValues());
    }
    else {
      this.props.form.validateFieldsAndScroll(this.getFieldsForValidation(), (errors, values) => {
        this.props.onFormSubmit(this.processAndGetFormValues(values), errors);
      });
    }
  };

  handleCorrespondenceAddressEnabledChange = (enabled: boolean): void => {
    this.setState({ correspondenceAddressEnabled: enabled });
  };

  validateFormFields = (): void => {
    this.props.form.validateFields(this.getFieldsForValidation());
  };

  processAndGetFormValues = (formValues?: CreateUpdateClient): CreateUpdateClient => {
    const processedValues = formValues ? { ...formValues } : this.props.form.getFieldsValue() as CreateUpdateClient;
    processedValues.bankAccountNumber = removeStringWhiteSpaces(processedValues.bankAccountNumber);
    processedValues.correspondenceAddress = this.state.correspondenceAddressEnabled ? processedValues.correspondenceAddress : null;
    processedValues.contacts = (processedValues.contacts ? processedValues.contacts : [])
      .map(c => ({ ...c, value: c.type === ContactType.PHONE_NUMBER ? removeStringWhiteSpaces(c.value) : c.value }));

    if ( processedValues.type === ClientType.NATURAL || processedValues.type === ClientType.SELF_EMPLOYED ) {
      (processedValues as CreateUpdateNaturalClient).birthDate = momentToDateString((processedValues as CreateUpdateNaturalClient).birthDate as Moment);
    }

    return processedValues;
  };

  getFieldsForValidation = (): string[] => {
    const fields = getAllRootFieldsNames_deprecated(this.props.form);
    return this.state.correspondenceAddressEnabled ? fields : fields.filter(field => field !== "correspondenceAddress");
  };

  componentDidMount(): void {
    const { form, client } = this.props;
    const formData = { ...client };

    delete formData.id;
    delete formData.aggregatedName;
    delete formData.identifier;
    delete formData.createdAt;
    delete formData.updatedAt;
    delete formData.createdBy;
    delete formData.historyRecords;

    switch ( client.type ) {
      case ClientType.NATURAL:
        form.setFieldsValue({ ...formData, birthDate: toMoment((formData as NaturalClient).birthDate) });
        break;
      case ClientType.SELF_EMPLOYED:
        form.setFieldsValue({
          ...formData,
          aggregatedNaturalName: undefined,
          birthDate: toMoment((formData as SelfEmployedClient).birthDate)
        });
        break;
      case ClientType.LEGAL:
        form.setFieldsValue(formData);
        break;
    }

    if ( this.props.disablePinInput && (client.type === ClientType.NATURAL || client.type === ClientType.SELF_EMPLOYED) ) {
      fillBirthDateFromPin_deprecated((formData as NaturalClient).personalIdentificationNumber, this.props.form);
    }
  }

  render(): React.ReactNode {
    const { client, form } = this.props;
    const formLayout: ColProps = { span: 24, style: { maxWidth: "900px" } };

    let dataFormSection;
    switch ( client.type ) {
      case ClientType.NATURAL:
        dataFormSection = <NaturalClientDataFormPart form={form} disablePinInput={this.props.disablePinInput} />;
        break;
      case ClientType.SELF_EMPLOYED:
        dataFormSection = <SelfEmployedClientDataFormPart form={form} disableCrnInput={this.props.disableCrnInput} />;
        break;
      case ClientType.LEGAL:
        dataFormSection = <LegalClientDataFormPart form={form} disableCrnInput={this.props.disableCrnInput} />;
        break;
    }

    return (
      <Form layout="vertical" onSubmit={this.handleFormSubmit}>

        <HiddenInputDeprecated form={form} formKey="type" />
        <HiddenInputDeprecated form={form} formKey="optimisticLockVersion" />
        <HiddenInputDeprecated form={form} formKey="oldCrmId" />
        <HiddenInputDeprecated form={form} formKey="oldCrmOptimisticLockVersion" />

        <Divider orientation="left">{t("client.titles.basicData")}</Divider>

        <Row>
          <Col {...formLayout}>
            {dataFormSection}
          </Col>
        </Row>

        <Divider orientation="left">{t("client.titles.addresses")}</Divider>

        <Row>
          <Col {...formLayout}>
            <ClientAddressesFormPart
              form={form}
              initialClient={client}
              clientType={client.type}
              correspondenceAddressEnabled={this.state.correspondenceAddressEnabled}
              onCorrespondenceAddressEnabledChange={this.handleCorrespondenceAddressEnabledChange} />
          </Col>
        </Row>

        <Divider orientation="left">{t("client.titles.contacts")}</Divider>

        <Row>
          <Col {...formLayout}>
            <ContactsFormDeprecated form={form} initialContacts={client.contacts} />
          </Col>
        </Row>

        {!this.props.hideActionButtons && (
          <div className="margin-top-large">
            <Button type="primary" htmlType="submit" className="margin-right-tiny" icon={<SaveOutlined />}>
              {t("common.save")}
            </Button>
            {this.props.onCancelClick && (
              <Button type="default" onClick={this.props.onCancelClick} icon={<CloseOutlined />}>
                {t("common.cancel")}
              </Button>
            )}
          </div>
        )}

      </Form>
    );
  }
}

export default Form.create<Props>()(ClientUpdateForm);
