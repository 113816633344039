import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { InstitutionWithCoverageLimits } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { selectCoverageLimitsClassificationEnumerations } from "../../ducks";
import { coverageLimitsToFormValue } from "../utils";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { formatCoverageLimits } from "../../../../common/utils/formatUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  institutionId: UUID;
}

const CoverageLimitFormItemSelect = ({ formItemProps, selectProps, institutionId }: Props) => {

  const institutionsWithLimits = useSelector<RootState, InstitutionWithCoverageLimits[]>(selectCoverageLimitsClassificationEnumerations);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={(institutionsWithLimits
          .find(institution => institution.id === institutionId)?.coverageLimits || [])
          .map(limit => ({
            value: coverageLimitsToFormValue(limit.health, limit.property),
            label: formatCoverageLimits(limit.health, limit.property)
          }))} />
    </Form.Item>
  )
}

export default CoverageLimitFormItemSelect;
