import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { CalcSettings, CalcSettingsRequest, CreateCalcSettings, UpdateCalcSettings } from "./types";
import { EntityIdRequest, EntityObjectRequest, TwoLevelEntityIdRequest } from "../../../common/types";

const CREATE_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings", "POST");
const GET_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings", "GET");
const UPDATE_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings/{0}", "PUT");
const DELETE_CALC_SETTINGS = ApiRequestAdapter.create("/calculators/settings/{0}", "DELETE");
const UPLOAD_ATTACHMENT_CONFS = ApiRequestAdapter.create("/calculators/settings/{0}/attachment-confs", "POST");
const DOWNLOAD_ATTACHMENT_CONF = ApiRequestAdapter.create("/calculators/settings/{0}/attachment-confs/{1}", "GET");
const DELETE_ATTACHMENT_CONF = ApiRequestAdapter.create("/calculators/settings/{0}/attachment-confs/{1}", "DELETE");

export const requests = {
  CREATE_CALC_SETTINGS,
  GET_CALC_SETTINGS,
  UPDATE_CALC_SETTINGS,
  DELETE_CALC_SETTINGS,
  UPLOAD_ATTACHMENT_CONFS,
  DOWNLOAD_ATTACHMENT_CONF,
  DELETE_ATTACHMENT_CONF
};

export default {
  createCalcSettings: (request: CreateCalcSettings): AxiosPromise<CalcSettings> => {
    return apiService.post<CalcSettings>(CREATE_CALC_SETTINGS.url, request);
  },
  getCalcSettings: (request: CalcSettingsRequest): AxiosPromise<CalcSettings[]> => {
    return apiService.get<CalcSettings[]>(GET_CALC_SETTINGS.url, { params: request });
  },
  updateCalcSettings: (request: EntityObjectRequest<UpdateCalcSettings>): AxiosPromise<CalcSettings> => {
    return apiService.put<CalcSettings>(UPDATE_CALC_SETTINGS.getParametrizedUrl(request.id), request.object)
  },
  deleteCalcSettings: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_CALC_SETTINGS.getParametrizedUrl(request.id));
  },
  uploadAttachmentConfs: (request: EntityObjectRequest<FormData>): AxiosPromise => {
    return apiService.post(
      UPLOAD_ATTACHMENT_CONFS.getParametrizedUrl(request.id),
      request.object,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  },
  downloadAttachmentConf: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.get(DOWNLOAD_ATTACHMENT_CONF.getParametrizedUrl(request.id1, request.id2), { responseType: "blob" });
  },
  deleteAttachmentConf: (request: TwoLevelEntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_ATTACHMENT_CONF.getParametrizedUrl(request.id1, request.id2));
  }
}
