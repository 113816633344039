import React from "react";
import { Link } from "react-router-dom";
import { Client } from "../types";
import { resolveClientIdentifier } from "../utils";
import { formatAddress } from "../../../common/utils/formatUtils";

import ClientTypeTag from "./ClientTypeTag";

export interface Props {
  client: Client;
  showLink?: boolean;
}

const ClientTypeTagWithDescription = ({ client, showLink }: Props) => {
  const clientDescription =
    <span>
      <b>{client.aggregatedName}</b> {`(${resolveClientIdentifier(client)}), ${formatAddress(client.address)}`}
    </span>

  return (
    client ?
      <>
        <ClientTypeTag type={client.type} style={{ fontWeight: "normal" }} />
        {showLink && client.id ? <Link to={`/clients/${client.id}`}>{clientDescription}</Link> : clientDescription}
      </>
      : null
  );
};

export default ClientTypeTagWithDescription;
