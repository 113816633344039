import React from "react";
import { Checkbox, Col, Form, Input, Popconfirm, Row, Select } from "antd";
import { Product } from "../../types";
import { UUID } from "../../../../../common/types";
import { ProductType } from "../../enums";
import { InstitutionType } from "../../../institution/enums";
import { InsuranceType } from "../../../../contract/enums";
import { deleteProductActions } from "../../ducks";
import { validations } from "../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import InstitutionsEnumFormItemSelect from "../../../../enumerations/components/form/InstitutionsEnumFormItemSelect";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../../common/components/views/ItemCreatedUpdatedInfoView";
import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  index: number;
  productGroupId: UUID;
  product: Product;
  onDeleteClick: typeof deleteProductActions.request;
}

const ProductRowForm = ({ index, product, productGroupId, onDeleteClick }: Props) => {
  const colSpan = 4;
  const colSpanBig = 11;

  return (
    <>
      <HiddenInput name={["products", index, "id"]} />
      <HiddenInput name={["products", index, "type"]} />
      <HiddenInput name={["products", index, "optimisticLockVersion"]} />

      <Row gutter={rowGutter}>
        <Col span={24}>
          <ItemCreatedUpdatedInfoView item={product} />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["products", index, "name"]}
            label={t("admin.product.attrs.productName")}
            rules={[validations.notBlank, validations.size(1, 1024)]}>
            <Input />
          </Form.Item>
        </Col>

        {product.type === ProductType.INSURANCE_PRODUCT ? (
          <Col span={colSpanBig}>
            <InstitutionsEnumFormItemSelect
              formItemProps={{
                name: ["products", index, "insuranceInstitutionIds"],
                label: t("admin.product.attrs.insuranceInstitutionIds"),
                rules: [validations.notNull]
              }}
              selectProps={{ mode: "multiple" }}
              optionsProps={{
                filterType: InstitutionType.INSURANCE_COMPANY,
                selected: product.insuranceInstitutions
              }} />
          </Col>
        ) : (
          <Col span={colSpanBig}>
            <InstitutionsEnumFormItemSelect
              formItemProps={{
                name: ["products", index, "bankInstitutionIds"],
                label: t("admin.product.attrs.bankInstitutionIds"),
                rules: [validations.notNull]
              }}
              selectProps={{ mode: "multiple" }}
              optionsProps={{
                filterType: InstitutionType.BANK,
                selected: product.bankInstitutions
              }} />
          </Col>
        )}

        <Col span={colSpan}>
          {product.type === ProductType.INSURANCE_PRODUCT && (
            <Form.Item
              name={["products", index, "insuranceType"]}
              label={t("contract.enums.insuranceType._label")}
              rules={[validations.notNull]}>
              <Select
                {...selectStandardProps}
                options={Object.keys(InsuranceType).map(type => ({
                  value: type,
                  label: t("contract.enums.insuranceType." + type)
                }))} />
            </Form.Item>
          )}
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={["products", index, "deactivated"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
            className="form-item-without-label">
            <Checkbox>
              <LabelWithTooltip label={t("admin.product.attrs.deactivated")}
                                tooltip={t("admin.product.helpers.deactivatedDesc")} />
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={1}>
          <Popconfirm
            title={t("admin.product.titles.deleteProductConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => onDeleteClick({ id1: productGroupId, id2: product.id })}>
            <span><DeleteIcon /></span>
          </Popconfirm>
        </Col>
      </Row>
    </>
  );
};

export default ProductRowForm;
