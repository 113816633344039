import React from "react";
import { Col, DatePicker, Divider, Input, Row } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { TravelCalc } from "../../../types";
import { TravelInsuranceType } from "../../../enums";
import { InstitutionEnum } from "../../../../../../admin/institution/enums";
import { insuredClientAgeFromPinValidationRule, insuredClientAgeValidationRule } from "../../../utils"
import validations, { regexPatterns } from "../../../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerPresentAndFuture
} from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";
import t from "../../../../../../../app/i18n";

import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";

export interface Props {
  form: WrappedFormUtils;
  calcData: TravelCalc;
  selectedInstitutionEnum: InstitutionEnum;
  insurerIsAlsoInsured: boolean;
}

const TravelGenInsuredClientsSection = (props: Props) => {

  const colSpan = 6;
  const colSpanSmall = 3;

  const { insuranceType, effectiveBeginningDate } = props.calcData.generalData;
  const { insuredClientsCount, insuredClients } = props.calcData.clientsData;
  const { getFieldDecorator } = props.form;

  return (
    <>
      <Divider>{t("calc.travel.sections.insuredClients")}</Divider>

      {[...Array(insuredClientsCount)].map((value, index) => (
        <React.Fragment key={index}>
          <Row gutter={rowGutter}>
            <Col className="bold-text margin-bottom-tiny">
              {insuredClients
                ? t("calc.travel.sections.insuredClientWithAge", { number: index + 1, age: insuredClients[index].age })
                : t("calc.travel.sections.insuredClient", { number: index + 1 })}
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            {props.selectedInstitutionEnum !== InstitutionEnum.UNION && (
              <Col span={colSpanSmall}>
                <Form.Item label={t("calc.travel.attrs.clientsData.insuredClients.academicDegree")}>
                  {getFieldDecorator(`clientsData.insuredClients[${index}].academicDegree`, {
                    rules: [validations.size(1, 64), validations.academicDegree]
                  })(
                    <Input disabled={index === 0 && props.insurerIsAlsoInsured} />
                  )}
                </Form.Item>
              </Col>
            )}

            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.attrs.clientsData.insuredClients.firstName")}>
                {getFieldDecorator(`clientsData.insuredClients[${index}].firstName`, {
                  rules: [validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]
                })(
                  <Input disabled={index === 0 && props.insurerIsAlsoInsured} />
                )}
              </Form.Item>
            </Col>

            <Col span={colSpan}>
              <Form.Item label={t("calc.travel.attrs.clientsData.insuredClients.lastName")}>
                {getFieldDecorator(`clientsData.insuredClients[${index}].lastName`, {
                  rules: [validations.notBlank, validations.size(1, 255), validations.pattern(regexPatterns.wordRegex)]
                })(
                  <Input disabled={index === 0 && props.insurerIsAlsoInsured} />
                )}
              </Form.Item>
            </Col>

            {props.selectedInstitutionEnum !== InstitutionEnum.UNION && (
              <Col span={colSpanSmall}>
                <Form.Item label={t("calc.travel.attrs.clientsData.insuredClients.academicDegreeAfter")}>
                  {getFieldDecorator(`clientsData.insuredClients[${index}].academicDegreeAfter`, {
                    rules: [validations.size(1, 64), validations.academicDegreeAfter]
                  })(
                    <Input disabled={index === 0 && props.insurerIsAlsoInsured} />
                  )}
                </Form.Item>
              </Col>
            )}

            {props.selectedInstitutionEnum === InstitutionEnum.GENERALI ? (
              <Col span={colSpan}>
                <Form.Item label={t("calc.travel.attrs.clientsData.insuredClients.personalIdentificationNumber")}>
                  {getFieldDecorator(`clientsData.insuredClients[${index}].personalIdentificationNumber`, {
                    rules: [
                      validations.notBlank,
                      validations.pin,
                      insuredClientAgeFromPinValidationRule(effectiveBeginningDate, insuredClients ? insuredClients[index].age : null)
                    ]
                  })(
                    <Input disabled={index === 0 && props.insurerIsAlsoInsured} />
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col span={colSpan}>
                <Form.Item label={t("client.attrs.birthDate")}>
                  {getFieldDecorator(`clientsData.insuredClients[${index}].birthDate`, {
                    rules: [
                      validations.notNull,
                      validations.notPresentAndFuture,
                      insuredClientAgeValidationRule(effectiveBeginningDate, insuredClients ? insuredClients[index].age : null)
                    ]
                  })(
                    <DatePicker
                      {...datePickerStandardProps}
                      showToday={false}
                      disabled={index === 0 && props.insurerIsAlsoInsured}
                      disabledDate={disableDatePickerPresentAndFuture} />
                  )}
                </Form.Item>
              </Col>
            )}

            {props.selectedInstitutionEnum === InstitutionEnum.UNION
            && insuranceType === TravelInsuranceType.SHORT_TERM
            && insuredClients[index].discountType && (
              <Col span={colSpan}>
                <Form.Item label={
                  <LabelWithPopover
                    label={t("calc.travel.attrs.clientsData.insuredClients.discountIdentifier")}
                    popoverTitle={t("calc.travel.helpers.discount.title", {
                      type: t("calc.travel.enums.travelClientDiscountType." + insuredClients[index].discountType)
                    })}
                    popoverContent={
                      <p style={{ maxWidth: "300px" }}>
                        {t("calc.travel.helpers.discount.type." + insuredClients[index].discountType)}
                      </p>
                    } />
                }>
                  {getFieldDecorator(`clientsData.insuredClients[${index}].discountIdentifier`, {
                    rules: [validations.notBlank, validations.size(1, 255)]
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        </React.Fragment>
      ))}
    </>
  )
};

export default TravelGenInsuredClientsSection;
