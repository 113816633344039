import React, { useEffect } from "react";
import { Col, Form, Input, Modal, Row } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { CreateProductGroup } from "../../types";
import { createProductGroupActions } from "../../ducks";
import { validations } from "../../../../../common/utils/validationUtils";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { requests } from "../../api";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

export interface Props {
  visible: boolean;
  onFormSubmit: typeof createProductGroupActions.request;
  onFormCancel(): void;
}

const ProductGroupCreateForm = ({ visible, onFormSubmit, onFormCancel }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "admin.product.attrs", requests.CREATE_PRODUCT_GROUP);

  const inProgress = useRequestFinishedCallback(requests.CREATE_PRODUCT_GROUP, onFormCancel);

  useEffect(() => {
    if ( visible ) {
      form.resetFields();
    }
  }, [visible, form]);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: CreateProductGroup | Store) => {
        onFormSubmit(values as CreateProductGroup);
      })
      .catch(resolveFormValidationError);
  };

  const colSpan = 24;

  return (
    <Modal
      width={350}
      visible={visible}
      title={t("admin.product.titles.newProductGroup")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}>

      <Form form={form} layout="vertical" name="productGroupCreateForm">

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("admin.product.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 64)]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </Modal>
  );
}

export default React.memo(ProductGroupCreateForm);
