import React, { useEffect, useState } from "react";
import Big from "big.js";
import cloneDeep from "lodash/cloneDeep";
import { Button, Col, Form, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import {
  CrashInsuranceData,
  CreateUpdateInsurance,
  CreateUpdateInsuranceContract,
  CreateUpdateLifeInsurance,
  CreateUpdateRealtyInsurance,
  CreateUpdateTravelInsurance,
  CreateUpdateVehicleInsurance,
  InsuranceContract,
  LifeInsurance,
  MtplInsuranceData,
  VehicleInsurance
} from "../../../types";
import { FieldConstraintViolation } from "../../../../../common/types";
import { Client } from "../../../../client/types";
import { ContractType, InsuranceType } from "../../../enums";
import { createContractActions, updateContractActions } from "../../../ducks";
import { calculatePartialPremium } from "../../../utils";
import { clientToCreateUpdateContractClient, useClientValidation } from "../../../../client/utils";
import { coverageLimitsToFormValue, formValueToCoverageLimits } from "../../../../enumerations/components/utils";
import { distinctArray, isNotEmptyArray, removeStringWhiteSpaces } from "../../../../../common/utils/utils";
import {
  licensePlateNormalizeFunction,
  resolveFormValidationError,
  toMoment,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { requests } from "../../../api";
import t from "../../../../../app/i18n";

import InsuranceContractFormHeaderSection from "./sections/InsuranceContractFormHeaderSection";
import InsuranceContractFormInsurancesSection from "./sections/InsuranceContractFormInsurancesSection";
import InsuranceContractFormDataSection from "./sections/InsuranceContractFormDataSection";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";

export interface Props {
  initialContract?: InsuranceContract;
  onCreateFormSubmit?: typeof createContractActions.request;
  onUpdateFormSubmit?: typeof updateContractActions.request;
  onCancelClick?(): void;
}

const InsuranceContractForm = (props: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "contract.attrs", props.initialContract ? requests.UPDATE_CONTRACT : requests.CREATE_CONTRACT);

  const clientValidation = useClientValidation();

  const [clients, setClients] = useState<Client[]>(props.initialContract?.clients || []);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<number, FieldConstraintViolation[]>>(new Map());
  const [insurancesCount, setInsurancesCount] = useState<number>(props.initialContract?.insurances.length || 1);

  useEffect(() => {
    if ( props.initialContract ) {
      const initialContract = cloneDeep(props.initialContract);
      const formData = {
        ...initialContract,
        clients: null,
        insuranceInstitution: null,
        productGroup: null,
        signer: null,
        gainer1: null,
        gainer2: null,
        manager: null,
        attachments: null,
        insuranceInstitutionId: initialContract.insuranceInstitution.id,
        productGroupId: initialContract.productGroup.id,
        clientIdentifiers: initialContract.clients.map(client => client.identifier),
        authorizedClient1Identifier: initialContract.clients[initialContract.authorizedClient1Index]?.identifier,
        authorizedClient2Identifier: initialContract.clients[initialContract.authorizedClient2Index]?.identifier,
        signerId: initialContract.signer.id,
        gainer1Id: initialContract.gainer1.id,
        gainer2Id: initialContract.gainer2?.id,
        managerId: initialContract.manager.id,
        effectiveBeginningDate: toMoment(initialContract.effectiveBeginningDate),
        effectiveEndDate: toMoment(initialContract.effectiveEndDate),
        cancellationDate: toMoment(initialContract.cancellationDate),
        transferredToOtherBrokerDate: toMoment(initialContract.transferredToOtherBrokerDate),
        insurancePeriodEndDate: toMoment(initialContract.insurancePeriodEndDate),
        lastContractCancellationDate: toMoment(initialContract.lastContractCancellationDate),
        insurances: initialContract.insurances.map(initialInsurance => {
          let insurance = {
            ...initialInsurance,
            productId: initialInsurance.product.id,
            contractEntryDate: toMoment(initialInsurance.contractEntryDate),
            contractWithdrawalDate: toMoment(initialInsurance.contractWithdrawalDate),
            calcData: null,
            product: null
          } as CreateUpdateInsurance;

          insurance["insuredClientIdentifier"] = initialContract.clients[initialInsurance["insuredClientIndex"]]?.identifier;
          insurance["vehicleHolderIdentifier"] = initialContract.clients[initialInsurance["vehicleHolderIndex"]]?.identifier;
          insurance["vehicleOwnerIdentifier"] = initialContract.clients[initialInsurance["vehicleOwnerIndex"]]?.identifier;
          insurance["vinculationClientIdentifier"] = initialContract.clients[initialInsurance["vinculationClientIndex"]]?.identifier;

          switch ( insurance.type ) {
            case InsuranceType.MTPL:
            case InsuranceType.CRASH:
            case InsuranceType.GAP:
            case InsuranceType.PAS:
              const vehicleInsurance = insurance as CreateUpdateVehicleInsurance;
              vehicleInsurance.licensePlate = licensePlateNormalizeFunction(vehicleInsurance.licensePlate);

              const initialVehicle = (initialInsurance as VehicleInsurance).vehicle;
              vehicleInsurance.vehicle = {
                ...vehicleInsurance.vehicle,
                firstRegistrationDate: toMoment(initialVehicle.firstRegistrationDate),
                colorId: initialVehicle.color.id,
                brandId: initialVehicle.model.brand.id,
                modelId: initialVehicle.model.id
              }

              delete vehicleInsurance.vehicle["model"];
              delete vehicleInsurance.vehicle["color"];
              delete vehicleInsurance.vehicle["certificateCategory"];

              if ( vehicleInsurance.type === InsuranceType.MTPL ) {
                const mtplInsuranceData = vehicleInsurance.insuranceData as MtplInsuranceData;
                mtplInsuranceData.coverageLimits =
                  coverageLimitsToFormValue(mtplInsuranceData.healthCoverageLimit, mtplInsuranceData.propertyCoverageLimit);
              }
              if ( vehicleInsurance.type === InsuranceType.CRASH ) {
                const crashInsuranceData = vehicleInsurance.insuranceData as CrashInsuranceData;
                if ( crashInsuranceData.mtpl ) {
                  crashInsuranceData.mtpl.coverageLimits =
                    coverageLimitsToFormValue(crashInsuranceData.mtpl.healthCoverageLimit, crashInsuranceData.mtpl.propertyCoverageLimit)
                  crashInsuranceData.mtpl.effectiveBeginningDate = toMoment(crashInsuranceData.mtpl.effectiveBeginningDate);
                }
              }

              return vehicleInsurance;
            case InsuranceType.REALTY:
              const realtyInsurance = insurance as CreateUpdateRealtyInsurance;
              realtyInsurance.insuranceData.realtyEnabled = !!realtyInsurance.insuranceData.realty;
              realtyInsurance.insuranceData.householdEnabled = !!realtyInsurance.insuranceData.household;
              return realtyInsurance;
            case InsuranceType.TRAVEL:
              const travelInsurance = insurance as CreateUpdateTravelInsurance;
              travelInsurance.insuranceData.insuredClients = travelInsurance.insuranceData.insuredClients.map(client => ({
                ...client, birthDate: toMoment(client.birthDate)
              }));
              return travelInsurance;
            case InsuranceType.LIFE:
              const lifeInsurance = insurance as CreateUpdateLifeInsurance;
              lifeInsurance.tariffId = (initialInsurance as LifeInsurance).tariff.id
              delete lifeInsurance["tariff"];
              return lifeInsurance;
            default:
              return insurance;
          }
        })
      } as CreateUpdateInsuranceContract;

      if ( formData.insurances.length === 1 ) {
        formData.insurances[0] = {
          ...formData.insurances[0],
          contractEntryDate: null,
          contractWithdrawalDate: null,
          annualPremium: null,
          partialPremium: null
        }
      }

      form.setFieldsValue(formData);
    }
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ( clientValidation.errorResponse?.violations?.length > 0 ) {
      const violations = clientValidation.errorResponse.violations;
      const index = parseInt(violations[0].fieldPath.charAt(8));
      const updatedViolationsErrors = new Map<number, FieldConstraintViolation[]>([...clientsViolationErrors]);
      // TODO fieldpath - po update formulara klientov na antd v4 pouzit fieldPathToNamePath + dynamicky vyriesit index 18
      updatedViolationsErrors.set(index, violations.map(v => ({ ...v, fieldPath: v.fieldPath.substring(18) })));
      setClientsViolationErrors(updatedViolationsErrors);
    }
  }, [clientValidation.errorResponse]);   // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormFinish = (): void => {
    form.validateFields()
      .then((values: CreateUpdateInsuranceContract | Store) => {
        if ( clientsViolationErrors.size > 0 ) {
          messageUtils.errorNotification(t("common.error"), t("contract.validations.formError"));
        }
        else {
          const processedValues = { ...values } as CreateUpdateInsuranceContract;

          processedValues.insurances = processedValues.insurances.map(insuranceData => {
            const insurance = { ...insuranceData };

            insurance["insuredClientIndex"] = findClientIndex(insurance["insuredClientIdentifier"]);
            insurance["vehicleHolderIndex"] = findClientIndex(insurance["vehicleHolderIdentifier"]);
            insurance["vehicleOwnerIndex"] = findClientIndex(insurance["vehicleOwnerIdentifier"]);
            insurance["vinculationClientIndex"] = findClientIndex(insurance["vinculationClientIdentifier"]);

            delete insurance["insuredClientIdentifier"];
            delete insurance["vehicleHolderIdentifier"];
            delete insurance["vehicleOwnerIdentifier"];
            delete insurance["vinculationClientIdentifier"];

            switch ( insurance.type ) {
              case InsuranceType.MTPL:
              case InsuranceType.CRASH:
              case InsuranceType.GAP:
              case InsuranceType.PAS:
                const vehicleInsurance = insurance as CreateUpdateVehicleInsurance;
                vehicleInsurance.licensePlate = removeStringWhiteSpaces(vehicleInsurance.licensePlate);
                vehicleInsurance.vehicle.licensePlate = vehicleInsurance.licensePlate;
                vehicleInsurance.vehicle.registrationCertificateNumber = vehicleInsurance.insuranceData.registrationCertificateNumber;
                delete vehicleInsurance.vehicle.brandId;

                if ( vehicleInsurance.type === InsuranceType.MTPL ) {
                  const mtplInsuranceData = vehicleInsurance.insuranceData as MtplInsuranceData;
                  const limits = formValueToCoverageLimits(mtplInsuranceData.coverageLimits);
                  mtplInsuranceData.healthCoverageLimit = limits[0];
                  mtplInsuranceData.propertyCoverageLimit = limits[1];
                  delete mtplInsuranceData.coverageLimits;
                }
                if ( vehicleInsurance.type === InsuranceType.CRASH ) {
                  const crashInsuranceData = vehicleInsurance.insuranceData as CrashInsuranceData;
                  if ( crashInsuranceData.mtpl ) {
                    const limits = formValueToCoverageLimits(crashInsuranceData.mtpl.coverageLimits);
                    crashInsuranceData.mtpl.healthCoverageLimit = limits[0];
                    crashInsuranceData.mtpl.propertyCoverageLimit = limits[1];
                    delete crashInsuranceData.mtpl.coverageLimits;
                  }
                }

                return vehicleInsurance;
              case InsuranceType.REALTY:
                const realtyInsurance = insurance as CreateUpdateRealtyInsurance;
                delete realtyInsurance.insuranceData.realtyEnabled;
                delete realtyInsurance.insuranceData.householdEnabled;
                return realtyInsurance;
              default:
                return insurance;
            }
          });

          if ( processedValues.insurances.length === 1 ) {
            processedValues.insurances[0] = {
              ...processedValues.insurances[0],
              annualPremium: processedValues.annualPremium,
              partialPremium: processedValues.partialPremium,
              contractEntryDate: processedValues.effectiveBeginningDate
            }
          }

          processedValues.clients = clients.map(client => clientToCreateUpdateContractClient(client));
          processedValues.insurerIndex = 0;
          processedValues.authorizedClient1Index = findClientIndex(processedValues.authorizedClient1Identifier);
          processedValues.authorizedClient2Index = findClientIndex(processedValues.authorizedClient2Identifier);

          delete processedValues.clientIdentifiers;
          delete processedValues.authorizedClient1Identifier;
          delete processedValues.authorizedClient2Identifier;

          if ( props.initialContract ) {
            props.onUpdateFormSubmit?.({
              id: props.initialContract.id,
              object: {
                ...processedValues,
                paidUntilDate: props.initialContract.paidUntilDate,
                amountOwed: props.initialContract.amountOwed
              }
            });
          }
          else {
            props.onCreateFormSubmit?.(processedValues);
          }
        }
      })
      .catch((errors: ValidateErrorEntity) => {
        const errorInsurancesNumbers = distinctArray(errors.errorFields
          .filter(field => field.name[0] === "insurances" && typeof field.name[1] === "number")
          .map(field => (field.name[1] as number) + 1));

        if ( isNotEmptyArray(errorInsurancesNumbers) ) {
          messageUtils.errorNotification(t("common.error"), t("contract.validations.formErrorWithInsurances", {
            insurancesNumbers: errorInsurancesNumbers.join(", ")
          }));
        }
        else {
          messageUtils.errorNotification(t("common.error"), t("contract.validations.formError"));
        }

        resolveFormValidationError(errors);
      });
  }

  const handleClientChange = (client: Client, index: number): void => {
    const updatedClients = [...clients];
    updatedClients[index] = client;
    setClients(updatedClients);

    if ( client ) {
      clientValidation.onValidate({ prefix: `clients[${index}]`, client: clientToCreateUpdateContractClient(client) });
    }
  };

  const handleClientDelete = (index: number): void => {
    const updatedClients = [...clients];
    updatedClients.splice(index, 1);
    setClients(updatedClients);
  };

  const handleInsurancesCountChange = (count: number): void => {
    if ( insurancesCount > count && count > 1 ) {
      const contract = form.getFieldsValue([["insurances"], ["paymentFrequency"]]) as CreateUpdateInsuranceContract;

      let totalAnnualPremium = new Big(0);
      (contract.insurances || []).forEach(insurance => totalAnnualPremium = totalAnnualPremium.plus(insurance?.annualPremium || 0));
      const totalAnnualPremiumValue = parseFloat(totalAnnualPremium.round(2, 1).valueOf());

      form.setFieldsValue({
        annualPremium: totalAnnualPremiumValue,
        partialPremium: calculatePartialPremium(totalAnnualPremiumValue, contract.paymentFrequency)
      });
    }
    setInsurancesCount(count);
  };

  const findClientIndex = (identifier: string): number => {
    if ( identifier ) {
      const index = clients.findIndex(client => client.identifier === identifier);
      return index === -1 ? undefined : index;
    }
    return undefined;
  }

  const formLayout: ColProps = { span: 24, style: { maxWidth: "1100px" } };

  return (
    <>
      <h2>{t("contract.titles.createInsuranceContract")}</h2>

      <Form form={form} layout="vertical" name="insuranceContractForm">

        <HiddenInput name="type" initialValue={ContractType.INSURANCE_CONTRACT} />
        <HiddenInput name="optimisticLockVersion" />
        <HiddenInput name="status" />

        <Row justify="center">
          <Col {...formLayout}>

            <InsuranceContractFormHeaderSection
              initialContract={props.initialContract}
              form={form}
              clients={clients}
              clientsViolationErrors={clientsViolationErrors}
              onClientChange={handleClientChange}
              onClientDelete={handleClientDelete}
              onClientViolationErrorsChange={setClientsViolationErrors} />

            <InsuranceContractFormInsurancesSection
              initialContract={props.initialContract}
              form={form}
              clients={clients}
              insurancesCount={insurancesCount}
              onInsurancesCountChange={handleInsurancesCountChange} />

            <InsuranceContractFormDataSection
              form={form}
              insurancesCount={insurancesCount} />

            <div className="margin-top-small">
              <Button type="primary" icon={<SaveOutlined />} onClick={handleFormFinish}>
                {t("common.save")}
              </Button>

              {props.onCancelClick && (
                <Button className="margin-left-tiny" onClick={props.onCancelClick} icon={<CloseOutlined />}>
                  {t("common.cancel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>

      </Form>
    </>
  )
};

export default InsuranceContractForm;
