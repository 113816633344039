import React from "react";
import { Link } from "react-router-dom";
import { Location, LocationDescriptor } from "history";
import { Layout, Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import {
  AlertOutlined,
  ApartmentOutlined,
  AreaChartOutlined,
  BankOutlined,
  CalculatorOutlined,
  CarOutlined,
  CloudDownloadOutlined,
  DatabaseOutlined,
  EuroOutlined,
  FileOutlined,
  FileSearchOutlined,
  FormOutlined,
  GlobalOutlined,
  HomeOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PlusCircleOutlined,
  ReconciliationOutlined,
  RobotOutlined,
  TeamOutlined,
  ToolOutlined,
  UserAddOutlined,
  ContainerOutlined
} from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { Permission } from "../../common/security/authorization/enums";
import { hasAnyPermission, hasPermission, parseFirstPartOfPathname } from "../../common/utils/utils";
import t from "../i18n";

export interface Props {
  location: Location;
  permissions: Permission[];
}

interface State {
  readonly currentSelectedKey: string;
}

export interface MenuItemLinkProps extends MenuItemProps {
  icon: React.ReactNode;
  to: LocationDescriptor;
  label: string;
}

const MenuItemLink = ({ icon, to, label, ...menuItemProps }: MenuItemLinkProps) => (
  <Menu.Item {...menuItemProps}>
    <Link to={to}>{icon} {label}</Link>
  </Menu.Item>
);

class SideMenu extends React.Component<Props, State> {
  readonly state: State = {
    currentSelectedKey: this.props.location.pathname
  };

  handleMenuItemClick = (clickInfo: MenuInfo): void => {
    if ( clickInfo.key !== this.props.location.pathname ) {
      this.setState({ currentSelectedKey: clickInfo.key as string });
    }
  };

  createMenuItems = (): React.ReactNode => {
    const { permissions } = this.props;
    const menuItems = [];

    switch ( parseFirstPartOfPathname(this.props.location.pathname) ) {
      case "/calc":
        if ( hasAnyPermission(permissions, [Permission.MTPL_CALCULATOR, Permission.CRASH_CALCULATOR]) ) {
          menuItems.push(
            <MenuItemLink key="/calc/vehicle" icon={<CarOutlined />}
                          to={this.resolveLocationDescriptor("/calc/vehicle")}
                          label={t("navigation.sideMenu.calc.car")} />
          );
        }

        if ( hasPermission(permissions, Permission.TRAVEL_CALCULATOR) ) {
          menuItems.push(
            <MenuItemLink key="/calc/travel" icon={<GlobalOutlined />}
                          to={this.resolveLocationDescriptor("/calc/travel")}
                          label={t("navigation.sideMenu.calc.travel")} />
          );
        }

        if ( hasPermission(permissions, Permission.REALTY_CALCULATOR) ) {
          menuItems.push(
            <Menu.SubMenu
              key="calc-submenu-1"
              title={<span><HomeOutlined /><span>{t("navigation.sideMenu.calc.realty.title")}</span></span>}>
              <MenuItemLink key="/calc/realty" icon={<CalculatorOutlined />}
                            to={this.resolveLocationDescriptor("/calc/realty")}
                            label={t("navigation.sideMenu.calc.realty.calculator")} />
              <MenuItemLink key="/calc/realty/drafts" icon={<FormOutlined />}
                            to={this.resolveLocationDescriptor("/calc/realty/drafts")}
                            label={t("navigation.sideMenu.calc.realty.drafts")} />
            </Menu.SubMenu>
          );
        }

        if ( hasAnyPermission(permissions, [Permission.MTPL_CALCULATOR_ADMIN, Permission.CRASH_CALCULATOR_ADMIN,
          Permission.REALTY_CALCULATOR_ADMIN, Permission.TRAVEL_CALCULATOR_ADMIN]) ) {
          menuItems.push(
            <Menu.Divider key="calc-divider-1" />,
            <MenuItemLink key="/calc/records" icon={<FileSearchOutlined />}
                          to={this.resolveLocationDescriptor("/calc/records")}
                          label={t("navigation.sideMenu.calc.records")} />,
            <Menu.Divider key="calc-divider-2" />
          );

          const settingsItems = [];
          if ( hasPermission(permissions, Permission.MTPL_CALCULATOR_ADMIN) ) {
            settingsItems.push(
              <MenuItemLink key="/calc/settings/mtpl" icon={<CarOutlined />}
                            to={this.resolveLocationDescriptor("/calc/settings/mtpl")}
                            label={t("navigation.sideMenu.calc.mtpl")} />
            );
          }

          if ( hasPermission(permissions, Permission.CRASH_CALCULATOR_ADMIN) ) {
            settingsItems.push(
              <MenuItemLink key="/calc/settings/crash" icon={<CarOutlined />}
                            to={this.resolveLocationDescriptor("/calc/settings/crash")}
                            label={t("navigation.sideMenu.calc.crash")} />,
              <MenuItemLink key="/calc/settings/gap" icon={<CarOutlined />}
                            to={this.resolveLocationDescriptor("/calc/settings/gap")}
                            label={t("navigation.sideMenu.calc.gap")} />,
              <MenuItemLink key="/calc/settings/pas" icon={<CarOutlined />}
                            to={this.resolveLocationDescriptor("/calc/settings/pas")}
                            label={t("navigation.sideMenu.calc.pas")} />
            );
          }

          if ( hasPermission(permissions, Permission.TRAVEL_CALCULATOR_ADMIN) ) {
            settingsItems.push(
              <MenuItemLink key="/calc/settings/travel" icon={<GlobalOutlined />}
                            to={this.resolveLocationDescriptor("/calc/settings/travel")}
                            label={t("navigation.sideMenu.calc.travel")} />
            );
          }

          if ( hasPermission(permissions, Permission.REALTY_CALCULATOR_ADMIN) ) {
            settingsItems.push(
              <MenuItemLink key="/calc/settings/realty" icon={<HomeOutlined />}
                            to={this.resolveLocationDescriptor("/calc/settings/realty")}
                            label={t("navigation.sideMenu.calc.realty.title")} />
            );
          }

          if ( hasAnyPermission(permissions, [Permission.MTPL_CALCULATOR_ADMIN, Permission.CRASH_CALCULATOR_ADMIN]) ) {
            settingsItems.push(
              <MenuItemLink key="/calc/settings/vehicle-brands" icon={<CarOutlined />}
                            to={this.resolveLocationDescriptor("/calc/settings/vehicle-brands")}
                            label={t("navigation.sideMenu.calc.vehicleBrands")} />
            );
          }

          menuItems.push(
            <Menu.SubMenu
              key="calc-submenu-2"
              title={<span><ToolOutlined /><span>{t("navigation.sideMenu.calc.settings")}</span></span>}>
              {settingsItems}
            </Menu.SubMenu>
          );
        }
        break;
      case "/clients":
        if ( hasPermission(permissions, Permission.CLIENT) ) {
          menuItems.push(
            <MenuItemLink key="/clients" icon={<TeamOutlined />} to={this.resolveLocationDescriptor("/clients")}
                          label={t("navigation.sideMenu.clients.list")} />
          );
        }

        if ( hasPermission(permissions, Permission.CLIENT_CREATE) ) {
          menuItems.push(
            <Menu.Divider key="clients-divider-1" />,
            <MenuItemLink key="/clients/new" icon={<UserAddOutlined />}
                          to={this.resolveLocationDescriptor("/clients/new")}
                          label={t("navigation.sideMenu.clients.new")} />
          );
        }
        break;
      case "/contracts":
        if ( hasAnyPermission(permissions, [Permission.INSURANCE, Permission.LOAN]) ) {
          menuItems.push(
            <MenuItemLink key="/contracts" icon={<DatabaseOutlined />} to={this.resolveLocationDescriptor("/contracts")}
                          label={t("navigation.sideMenu.contracts.list")} />
          );
        }

        if ( hasAnyPermission(permissions, [Permission.INSURANCE_CREATE, Permission.LOAN_CREATE]) ) {
          menuItems.push(<Menu.Divider key="contracts-divider-1" />);
        }
        if ( hasPermission(permissions, Permission.INSURANCE_CREATE) ) {
          menuItems.push(
            <MenuItemLink key="/contracts/new-insurance" icon={<PlusCircleOutlined />}
                          to={this.resolveLocationDescriptor("/contracts/new-insurance")}
                          label={t("navigation.sideMenu.contracts.newInsurance")} />);
        }
        if ( hasPermission(permissions, Permission.LOAN_CREATE) ) {
          menuItems.push(
            <MenuItemLink key="/contracts/new-loan" icon={<PlusCircleOutlined />}
                          to={this.resolveLocationDescriptor("/contracts/new-loan")}
                          label={t("navigation.sideMenu.contracts.newLoan")} />);
        }

        if ( hasAnyPermission(permissions, [Permission.INSURANCE, Permission.LOAN]) ) {
          menuItems.push(
            <Menu.Divider key="contracts-divider-2" />,
            <Menu.SubMenu
              key="contracts-submenu-1"
              title={
                <span><OrderedListOutlined /><span>{t("navigation.sideMenu.contracts.reports")}</span></span>
              }>
              <MenuItemLink key="/contracts/anniversary-date" icon={<AlertOutlined />}
                            to={this.resolveLocationDescriptor("/contracts/anniversary-date")}
                            label={t("navigation.sideMenu.contracts.anniversaryDate")} />

              <MenuItemLink key="/contracts/unpaid" icon={<EuroOutlined />}
                            to={this.resolveLocationDescriptor("/contracts/unpaid")}
                            label={t("navigation.sideMenu.contracts.unpaid")} />
            </Menu.SubMenu>
          );
        }
        break;
      case "/reports":
        if ( hasPermission(permissions, Permission.ADMIN) ) {
          menuItems.push(
            <MenuItemLink key="/reports/statistics" icon={<AreaChartOutlined />}
                          to={this.resolveLocationDescriptor("/reports/statistics")}
                          label={t("navigation.sideMenu.reports.statistics")} />,
            <MenuItemLink key="/reports/reminders" icon={<NotificationOutlined />}
                          to={this.resolveLocationDescriptor("/reports/reminders")}
                          label={t("navigation.sideMenu.reports.reminders")} />,
            <MenuItemLink key="/reports/documents" icon={<FileOutlined />}
                          to={this.resolveLocationDescriptor("/reports/documents")}
                          label={t("navigation.sideMenu.reports.documents")} />,
            <MenuItemLink key="/reports/forms" icon={<FormOutlined />}
                          to={this.resolveLocationDescriptor("/reports/forms")}
                          label={t("navigation.sideMenu.reports.forms")} />
          );
        }
        break;
      case "/persons":
        if ( hasPermission(permissions, Permission.PERSON) ) {
          menuItems.push(
            <MenuItemLink key="/persons" icon={<TeamOutlined />} to={this.resolveLocationDescriptor("/persons")}
                          label={t("navigation.sideMenu.persons.list")} />,
            <MenuItemLink key="/persons/tree" icon={<ApartmentOutlined />}
                          to={this.resolveLocationDescriptor("/persons/tree")}
                          label={t("navigation.sideMenu.persons.tree")} />
          );
        }

        if ( hasPermission(permissions, Permission.PERSON_CREATE) ) {
          menuItems.push(
            <Menu.Divider key="persons-divider-1" />,
            <MenuItemLink key="/persons/new" icon={<UserAddOutlined />}
                          to={this.resolveLocationDescriptor("/persons/new")}
                          label={t("navigation.sideMenu.persons.new")} />
          );
        }
        break;
      case "/commissions":
        if ( hasPermission(permissions, Permission.COMMISSIONS) ) {
          menuItems.push(
            <MenuItemLink key="/commissions/batches" icon={<EuroOutlined />}
                          to={this.resolveLocationDescriptor("/commissions/batches")}
                          label={t("navigation.sideMenu.commissions.batchesList")} />);
        }
        break;
      case "/admin":
        if ( hasPermission(permissions, Permission.ADMIN_ENUMERATIONS) ) {
          menuItems.push(
            <MenuItemLink key="/admin/institutions" icon={<BankOutlined />}
                          to={this.resolveLocationDescriptor("/admin/institutions")}
                          label={t("navigation.sideMenu.admin.institutions")} />,
            <MenuItemLink key="/admin/products" icon={<ReconciliationOutlined />}
                          to={this.resolveLocationDescriptor("/admin/products")}
                          label={t("navigation.sideMenu.admin.products")} />,
            <MenuItemLink key="/admin/life-insurance-tariffs" icon={<ContainerOutlined />}
                          to={this.resolveLocationDescriptor("/admin/life-insurance-tariffs")}
                          label={t("navigation.sideMenu.admin.lifeInsuranceTariffs")} />
          );
        }

        if ( hasPermission(permissions, Permission.ADMIN_JOBS) ) {
          menuItems.push(
            <MenuItemLink key="/admin/jobs" icon={<RobotOutlined />}
                          to={this.resolveLocationDescriptor("/admin/jobs")}
                          label={t("navigation.sideMenu.admin.jobs")} />
          );
        }

        if ( hasPermission(permissions, Permission.ADMIN_CONTRACT_UPDATES) ) {
          menuItems.push(
            <MenuItemLink key="/admin/contract-updates" icon={<CloudDownloadOutlined />}
                          to={this.resolveLocationDescriptor("/admin/contract-updates")}
                          label={t("navigation.sideMenu.admin.contractUpdates")} />
          );
        }
        break;
      default:
        return null;
    }

    return menuItems.length === 0 ? null : menuItems;
  };

  resolveLocationDescriptor = (pathname: string): LocationDescriptor => ({
    pathname,
    search: this.props.location.pathname === pathname ? this.props.location.search : undefined
  });

  componentDidUpdate(): void {
    if ( this.state.currentSelectedKey !== this.props.location.pathname ) {
      this.setState((previousState, previousProps) => ({ currentSelectedKey: previousProps.location.pathname }));
    }
  }

  render(): React.ReactNode {
    const menuItems = this.createMenuItems();
    return menuItems ? (
      <Layout.Sider>
        <Menu
          mode="inline"
          onClick={this.handleMenuItemClick}
          selectedKeys={[this.state.currentSelectedKey]}
          defaultOpenKeys={["contracts-submenu-1", "calc-submenu-1", "calc-submenu-2"]}>
          {menuItems}
        </Menu>
      </Layout.Sider>
    ) : null;
  }
}

export default SideMenu;
