import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "./api";
import { ActionCreator } from "../../../common/types";
import { CalcRequest, CalcResponse, CalcResultData, GenRequest, GenResponse, Offer } from "./types";
import { VehicleCalcResultData } from "./vehicle/types";
import { RealtyCalcResultData } from "./realty/types";
import { TravelCalcResultData } from "./travel/types";
import { CalcType } from "../enums";
import vehicleReducer, {
  actionType as vehicleActionType,
  calculateVehicleActions,
  generateVehicleActions,
  generateVehicleOfferActions
} from "./vehicle/ducks";
import realtyReducer, {
  actionType as realtyActionType,
  calculateRealtyActions,
  generateRealtyActions,
  generateRealtyOfferActions
} from "./realty/ducks";
import travelReducer, {
  actionType as travelActionType,
  calculateTravelActions,
  generateTravelActions,
  generateTravelOfferActions
} from "./travel/ducks";
import { sortAndGroupCalcResults } from "./utils";
import { sortAndGroupVehicleCalcResults } from "./vehicle/utils";
import { openBlobFile } from "../../../common/utils/utils";
import { apiOperation, createActionType, createApiActionCreators } from "../../../common/utils/reduxUtils";

/**
 * ACTION TYPES
 */
export enum actionType {
  DOWNLOAD_CARD_READER = 'calc/DOWNLOAD_CARD_READER'
}

/**
 * ACTIONS
 */
export const downloadCardReaderActions = createApiActionCreators<void, void>(actionType.DOWNLOAD_CARD_READER);

/**
 * REDUCERS
 */
export default combineReducers({
  vehicle: vehicleReducer,
  realty: realtyReducer,
  travel: travelReducer
});

/**
 * SAGAS
 */
function* calculate({ payload }: ActionCreator<CalcRequest>) {
  try {
    const response: AxiosResponse<CalcResponse<CalcResultData>> = yield call(api.calculate, payload);
    switch ( payload.type ) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(calculateVehicleActions.success(sortAndGroupVehicleCalcResults(response.data as CalcResponse<VehicleCalcResultData>)));
        break;
      case CalcType.TRAVEL:
        yield put(calculateTravelActions.success(sortAndGroupCalcResults<TravelCalcResultData>(response.data as CalcResponse<TravelCalcResultData>)));
        break;
      case CalcType.REALTY:
        yield put(calculateRealtyActions.success(sortAndGroupCalcResults<RealtyCalcResultData>(response.data as CalcResponse<RealtyCalcResultData>)));
        break;
    }
  }
  catch ( error ) {
    switch ( payload.type ) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(calculateVehicleActions.failure(error));
        break;
      case CalcType.TRAVEL:
        yield put(calculateTravelActions.failure(error));
        break;
      case CalcType.REALTY:
        yield put(calculateRealtyActions.failure(error));
        break;
    }
  }
}

function* generate({ payload }: ActionCreator<GenRequest<CalcResultData>>) {
  try {
    const response: AxiosResponse<GenResponse> = yield call(api.generate, payload);
    switch ( payload.type ) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleActions.success(response.data));
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelActions.success(response.data));
        break;
      case CalcType.REALTY:
        yield put(generateRealtyActions.success(response.data));
        break;
    }
  }
  catch ( error ) {
    switch ( payload.type ) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleActions.failure(error));
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelActions.failure(error));
        break;
      case CalcType.REALTY:
        yield put(generateRealtyActions.failure(error));
        break;
    }
  }
}

function* generateOffer({ payload }: ActionCreator<Offer<CalcRequest, CalcResultData>>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.generateOffer, payload);
    openBlobFile(response, true);
    switch ( payload.type ) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleOfferActions.success());
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelOfferActions.success());
        break;
      case CalcType.REALTY:
        yield put(generateRealtyOfferActions.success());
        break;
    }
  }
  catch ( error ) {
    switch ( payload.type ) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleOfferActions.failure(error));
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelOfferActions.failure(error));
        break;
      case CalcType.REALTY:
        yield put(generateRealtyOfferActions.failure(error));
        break;
    }
  }
}

function* downloadCardReader() {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCardReader);
    openBlobFile(response, true);
    yield put(downloadCardReaderActions.success());
  }
  catch ( error ) {
    yield put(downloadCardReaderActions.failure(error));
  }
}

export function* calcsSaga() {
  yield takeLatest(createActionType(vehicleActionType.CALCULATE, apiOperation.REQUEST), calculate);
  yield takeLatest(createActionType(vehicleActionType.GENERATE, apiOperation.REQUEST), generate);
  yield takeLatest(createActionType(vehicleActionType.GENERATE_OFFER, apiOperation.REQUEST), generateOffer);

  yield takeLatest(createActionType(travelActionType.CALCULATE, apiOperation.REQUEST), calculate);
  yield takeLatest(createActionType(travelActionType.GENERATE, apiOperation.REQUEST), generate);
  yield takeLatest(createActionType(travelActionType.GENERATE_OFFER, apiOperation.REQUEST), generateOffer);

  yield takeLatest(createActionType(realtyActionType.CALCULATE, apiOperation.REQUEST), calculate);
  yield takeLatest(createActionType(realtyActionType.GENERATE, apiOperation.REQUEST), generate);
  yield takeLatest(createActionType(realtyActionType.GENERATE_OFFER, apiOperation.REQUEST), generateOffer);

  yield takeLatest(createActionType(actionType.DOWNLOAD_CARD_READER, apiOperation.REQUEST), downloadCardReader);
}
