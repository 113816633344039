import React from "react";
import { useSelector } from "react-redux";
import { RootState, UUID } from "../../../../common/types";
import { PersonBase } from "../../../person/types";
import { selectPersonsEnumerations } from "../../ducks";
import { formatPersonName } from "../../../../common/utils/formatUtils";
import t from "../../../../app/i18n";

export interface Props {
  id: UUID;
}

const PersonEnumName = ({ id }: Props) => {
  const personsEnumerations = useSelector<RootState, PersonBase[]>(selectPersonsEnumerations);

  const person = personsEnumerations.find(person => person.id === id);
  return person ? <span>{formatPersonName(person)}</span> : <span>{t("person.helpers.unknownPerson")}</span>
};

export default PersonEnumName;
