import React from "react";
import groupBy from "lodash/groupBy";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { FormItemProps } from "antd/lib/form";
import { FilterFunction, InstitutionWithProducts, ProductGroupWithProducts } from "../../types";
import { RootState, UUID } from "../../../../common/types";
import { ProductBase } from "../../../admin/product/types";
import { ProductType } from "../../../admin/product/enums";
import { selectProductGroups, selectProductsClassificationEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { formatProductName } from "../../../../common/utils/formatUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: ProductOptionsProps;
}

export interface ProductOptionsProps {
  includeDeactivated?: boolean;
  selected?: ProductBase;
  institutionId?: UUID;
  productGroupId?: UUID;
  filterType?: ProductType;
  groupByProductGroup?: boolean;
  hideAll?: boolean;
  filter?: FilterFunction<ProductBase>;
  onChange?(product: ProductBase): void;
}

const ProductsEnumFormItemSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {

  const productsClassification = useSelector<RootState, InstitutionWithProducts[]>(selectProductsClassificationEnumerations);
  const productGroupsClassification = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroups);

  const resolveProductSelectOptions = (): ProductBase[] => {
    if ( optionsProps?.hideAll ) {
      return [];
    }

    const institutions = optionsProps?.institutionId
      ? productsClassification.filter(institution => institution.id === optionsProps.institutionId)
      : productsClassification;

    let groups = institutions.flatMap(institution => institution.productGroups);
    if ( optionsProps?.productGroupId ) {
      groups = groups.filter(group => group.id === optionsProps.productGroupId);
    }

    let products = groups.flatMap(group => group.products);
    if ( !optionsProps?.includeDeactivated ) {
      products = products.filter(product => !product.deactivated);
    }
    if ( optionsProps?.filterType ) {
      products = products.filter(product => product.type === optionsProps.filterType);
    }
    if ( optionsProps?.filter ) {
      products = products.filter(optionsProps.filter);
    }
    if ( optionsProps?.selected
      && !products.some(product => product.id === optionsProps.selected.id)
      && groups.some(group => group.products.some(product => product.id === optionsProps.selected.id)) ) {
      products.unshift(optionsProps.selected);
    }

    const distinctProducts = [];
    products.forEach(product => {
      if ( distinctProducts.every(distinctProduct => distinctProduct.id !== product.id) ) {
        distinctProducts.push(product);
      }
    });

    return distinctProducts.sort((a, b) => a.name.localeCompare(b.name));
  };

  const onChange = (productId: UUID, option: any): void => {
    selectProps?.onChange?.(productId, option);
    optionsProps?.onChange?.(
      productGroupsClassification.flatMap(group => group.products).find(product => product.id === productId));
  };

  if ( optionsProps?.groupByProductGroup ) {
    const groupsMap = groupBy(resolveProductSelectOptions(),
      product => productGroupsClassification.find(group => group.products.some(p => p.id === product.id)).id);
    const groups = Object.keys(groupsMap)
      .map<ProductGroupWithProducts>(groupId => ({
        ...productGroupsClassification.find(group => group.id === groupId),
        products: groupsMap[groupId]
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <Form.Item {...formItemProps}>
        <Select {...selectStandardProps} {...selectProps} onChange={onChange}>
          {groups.map((group, index) =>
            <Select.OptGroup key={index} label={group.name}>
              {group.products.map(p => <Select.Option key={p.id} value={p.id}>{formatProductName(p)}</Select.Option>)}
            </Select.OptGroup>
          )}
        </Select>
      </Form.Item>
    )
  }

  return (
    <Form.Item {...formItemProps}>
      <Select {...selectStandardProps} {...selectProps} onChange={onChange}>
        {resolveProductSelectOptions().map(p =>
          <Select.Option key={p.id} value={p.id}>{formatProductName(p)}</Select.Option>)}
      </Select>
    </Form.Item>
  );
}

export default ProductsEnumFormItemSelect;

