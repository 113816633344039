import React from "react";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { Col, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { Form } from "@ant-design/compatible";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CalcResult, GenResponse } from "../../../types";
import {
  RealtyCalc,
  RealtyCalcResultData,
  RealtyFormClients,
  RealtyGen,
  RealtyGenClientsData,
  RealtyGenForm,
  RealtyGenFormData
} from "../../types";
import { Client, ClientsAutocompleteProps, CreateUpdateContractClient } from "../../../../../client/types";
import { FieldConstraintViolation, UUID } from "../../../../../../common/types";
import { ValidationErrorResponse } from "../../../../../types";
import { CalcType } from "../../../../enums";
import { BuildingState } from "../../enums";
import { ClientFormType, ClientType } from "../../../../../client/enums";
import { InstitutionEnum } from "../../../../../admin/institution/enums";
import { ClientExperience } from "../../../../../contract/enums";
import { deleteStateRealtyGenResultAction, generateRealtyActions } from "../../ducks";
import { validateClientActions, validateClientsActions } from "../../../../../client/ducks";
import { deleteStateValidationErrorResponseAction } from "../../../../../ducks";
import { processClientsDataViolations, processGenResultError } from "../../../utils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { contains } from "../../../../../../common/utils/utils";
import { clientToCreateUpdateContractClient } from "../../../../../client/utils";
import t from "../../../../../../app/i18n";

import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import RealtyGenClientsDataSection from "./sections/RealtyGenClientsDataSection";
import RealtyGenAdditionalDataSection from "./sections/RealtyGenAdditionalDataSection";
import RealtyGenNavigation from "./navigation/RealtyGenNavigation";
import RealtyCalcResults from "../result/RealtyCalcResults";
import CalcGenResultModal from "../../../components/CalcGenResultModal";

export interface Props extends FormComponentProps<RealtyGenForm> {
  initialData: RealtyGenFormData;
  genResult: GenResponse;
  calcData: RealtyCalc;
  clients: RealtyFormClients;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  selectedResult: CalcResult<RealtyCalcResultData>;
  draftId: UUID;
  clientsAutocomplete: ClientsAutocompleteProps;
  validateClientErrorResponse: ValidationErrorResponse;
  validateClientsErrorResponse: ValidationErrorResponse;
  onGenerateFormSubmit: typeof generateRealtyActions.request;
  onValidateClient: typeof validateClientActions.request;
  onValidateClients: typeof validateClientsActions.request;
  onGenResultDelete: typeof deleteStateRealtyGenResultAction;
  onErrorResponseDelete: typeof deleteStateValidationErrorResponseAction;
  onClientChange(client: Client, type: ClientFormType, callback?: () => void);
  onGenerateOfferClick(): void;
  onSelectedResultChange(selectedResult: CalcResult<RealtyCalcResultData>, callback?: () => void): void;
  onSaveDraftClick(genData: RealtyGen): void;
  onReturnToCalculationClick(genFormData: RealtyGenFormData): void;
}

interface State {
  readonly resultsVisible: boolean;
  readonly clientsDuplicateErrors: ClientFormType[];
  readonly clientsViolationErrors: Map<ClientFormType, FieldConstraintViolation[]>;
  readonly insurerInputEnabled: boolean;
  readonly authorizedClientsCount: number;
}

class RealtyGenWrapper extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    let stateObject = {
      resultsVisible: false,
      clientsDuplicateErrors: [],
      clientsViolationErrors: new Map<ClientFormType, FieldConstraintViolation[]>(),
      insurerInputEnabled: false,
      authorizedClientsCount: 1
    };

    if ( props.initialData ) {
      stateObject = { ...stateObject, ...props.initialData.clientsData }
    }

    this.state = stateObject;
  }

  handleInsurerInputEnabledChange = (enabled: boolean): void => {
    this.setState({ insurerInputEnabled: enabled });
  };

  handleAuthorizedClientAdd = (): void => {
    this.setState(previousState => ({ authorizedClientsCount: previousState.authorizedClientsCount + 1 }));
  };

  handleAuthorizedClientDelete = (): void => {
    this.setState(previousState => ({ authorizedClientsCount: previousState.authorizedClientsCount - 1 }));
  };

  handleClientChange = (client: Client, type: ClientFormType, omitValidation?: boolean, callback?: () => void): void => {
    this.props.onClientChange(client, type, () => {
      if ( client && !omitValidation ) {
        this.props.onValidateClient({
          prefix: `clientsData.clients[${this.createClientsIndicesMap().get(type)}]`,
          client: clientToCreateUpdateContractClient(client)
        });
      }

      if ( client && type === ClientFormType.INSURER && (client.type === ClientType.SELF_EMPLOYED || client.type === ClientType.LEGAL) ) {
        this.props.form.setFieldsValue({ "informationObligationData.clientExperience": ClientExperience.SUFFICIENT });
      }

      if ( callback ) {
        callback();
      }
    });
  };

  handleClientViolationErrorsChange = (violations: FieldConstraintViolation[], type: ClientFormType): void => {
    this.setState(previousState => {
      const clientsViolationErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...previousState.clientsViolationErrors]);
      if ( violations && violations.length > 0 ) {
        clientsViolationErrors.set(type, violations);
      }
      else {
        clientsViolationErrors.delete(type);
      }
      return { clientsViolationErrors };
    });
  };

  handleClientDuplicateErrorChange = (isDuplicate: boolean, type: ClientFormType): void => {
    if ( isDuplicate ) {
      this.setState(previousState =>
        contains(previousState.clientsDuplicateErrors, type)
          ? { clientsDuplicateErrors: previousState.clientsDuplicateErrors }
          : { clientsDuplicateErrors: [...previousState.clientsDuplicateErrors, type] });
    }
    else {
      this.setState(previousState => ({ clientsDuplicateErrors: previousState.clientsDuplicateErrors.filter(error => error !== type) }));
    }
  };

  handleGenerateFormSubmit = (): void => {
    this.props.form.validateFieldsAndScroll(errors => {
      const hasClientsError = this.checkHasClientsErrors();
      if ( errors || hasClientsError ) {
        messageUtils.errorNotification(t("common.error"), t("calc.realty.validations.formError"));
      }
      else {
        this.props.onGenResultDelete();
        this.props.onGenerateFormSubmit(this.processAndGetGenFormData());
      }
    });
  };

  handleResultsShow = (): void => {
    this.setState({ resultsVisible: true });
  };

  handleResultsClose = (): void => {
    this.setState({ resultsVisible: false });
  };

  handleResultsSaveDraftClick = (): void => {
    this.setState({ resultsVisible: false });
    this.props.onSaveDraftClick(this.processAndGetGenFormData());
  };

  handleResultsGenerateOfferClick = (): void => {
    this.setState({ resultsVisible: false });
    this.props.onGenerateOfferClick();
  };

  handleResultsGenerateContractClick = (result: CalcResult<RealtyCalcResultData>): void => {
    this.props.onSelectedResultChange(result,
      () => this.props.form.validateFields(["clientsData"], { force: true }));
    this.setState({ resultsVisible: false });
  };

  handleReturnToCalculationClick = (): void => {
    this.props.onReturnToCalculationClick({
      formData: this.props.form.getFieldsValue() as RealtyGenForm,
      clientsData: {
        clientsDuplicateErrors: this.state.clientsDuplicateErrors,
        insurerInputEnabled: this.state.insurerInputEnabled,
        authorizedClientsCount: this.state.authorizedClientsCount
      }
    });
  };

  checkHasClientsErrors = (): boolean => {
    const { insured, insurer, authorized1, authorized2, vinculation } = this.props.clients;
    const { clientsViolationErrors, clientsDuplicateErrors, insurerInputEnabled, authorizedClientsCount } = this.state;

    if ( !insured || clientsViolationErrors.has(ClientFormType.INSURED) || contains(clientsDuplicateErrors, ClientFormType.INSURED) ) {
      return true;
    }

    if ( insurerInputEnabled
      && (!insurer || clientsViolationErrors.has(ClientFormType.INSURER) || contains(clientsDuplicateErrors, ClientFormType.INSURER)) ) {
      return true;
    }

    const insurerType = insurerInputEnabled ? insurer.type : this.props.calcData.clientsData.insuredClientType;
    if ( insurerType === ClientType.LEGAL ) {
      if ( !authorized1 || clientsViolationErrors.has(ClientFormType.AUTHORIZED_1) || contains(clientsDuplicateErrors, ClientFormType.AUTHORIZED_1) ) {
        return true;
      }

      if ( authorizedClientsCount > 1
        && (!authorized2 || clientsViolationErrors.has(ClientFormType.AUTHORIZED_2) || contains(clientsDuplicateErrors, ClientFormType.AUTHORIZED_2)) ) {
        return true;
      }
    }

    // noinspection RedundantIfStatementJS
    if ( this.props.calcData.generalInsuranceData.vinculation
      && (!vinculation || clientsViolationErrors.has(ClientFormType.VINCULATION) || contains(clientsDuplicateErrors, ClientFormType.VINCULATION)) ) {
      return true;
    }

    return false;
  };

  processAndGetGenFormData = (): RealtyGen => {
    const clientsIndices = this.createClientsIndicesMap();
    const values = this.props.form.getFieldsValue() as RealtyGenForm;

    const clientsData: RealtyGenClientsData = {
      ...this.props.calcData.clientsData,
      clients: this.createContractClientsArray(),
      insuredClientIndex: clientsIndices.get(ClientFormType.INSURED),
      insurerIndex: clientsIndices.get(ClientFormType.INSURER),
      authorizedClient1Index: clientsIndices.get(ClientFormType.AUTHORIZED_1),
      authorizedClient1Function: values.clientsData.authorizedClient1Function,
      authorizedClient2Index: clientsIndices.get(ClientFormType.AUTHORIZED_2),
      authorizedClient2Function: values.clientsData.authorizedClient2Function,
      vinculationClientIndex: clientsIndices.get(ClientFormType.VINCULATION),
      insurerEmail: values.clientsData.insurerEmail,
      insurerPhone: values.clientsData.insurerPhone
    };

    const valuesCopy = cloneDeep(values);
    delete valuesCopy.clientsData;

    return {
      type: CalcType.REALTY,
      calcResult: this.props.selectedResult.data,
      calcResponse: { results: this.props.calcResults.flat() },
      coverage: this.props.selectedResult.coverage,
      insuranceInstitutionId: this.props.selectedResult.insuranceInstitution.id,
      ...merge({}, this.props.calcData, valuesCopy, { clientsData }),
      draftId: this.props.draftId
    };
  };

  processClientsViolationErrorsStateUpdate = (errorResponse: ValidationErrorResponse): void => {
    this.setState(previousState => ({
      clientsViolationErrors: new Map([
        ...previousState.clientsViolationErrors,
        ...processClientsDataViolations(errorResponse.violations, this.createClientsIndicesMap())
      ])
    }), () => this.props.onErrorResponseDelete());
  };

  createClientsIndicesMap = (): Map<ClientFormType, number> => {
    const { insured, insurer, authorized1, authorized2, vinculation } = this.props.clients;
    const { insurerInputEnabled } = this.state;
    const indices = new Map<ClientFormType, number>();
    let index = 0;

    if ( insured ) {
      indices.set(ClientFormType.INSURED, index++);

      if ( !insurerInputEnabled ) {
        indices.set(ClientFormType.INSURER, index - 1);
      }
    }

    if ( insurer && insurerInputEnabled ) {
      indices.set(ClientFormType.INSURER, index++);
    }

    const insurerType = insurerInputEnabled
      ? insurer ? insurer.type : null
      : this.props.calcData.clientsData.insuredClientType;

    if ( insurerType === ClientType.LEGAL ) {
      if ( authorized1 ) {
        indices.set(ClientFormType.AUTHORIZED_1, index++);
      }

      if ( authorized2 && this.state.authorizedClientsCount > 1 ) {
        indices.set(ClientFormType.AUTHORIZED_2, index++);
      }
    }

    if ( vinculation && this.props.calcData.generalInsuranceData.vinculation ) {
      indices.set(ClientFormType.VINCULATION, index);
    }

    return indices;
  };

  createContractClientsArray = (): CreateUpdateContractClient[] => {
    const { insured, insurer, authorized1, authorized2, vinculation } = this.props.clients;
    const { insurerInputEnabled } = this.state;
    const createUpdateClients: CreateUpdateContractClient[] = [];

    if ( insured ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(insured));
    }

    if ( insurer && insurerInputEnabled ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(insurer));
    }

    const insurerType = insurerInputEnabled
      ? insurer ? insurer.type : null
      : this.props.calcData.clientsData.insuredClientType;

    if ( insurerType === ClientType.LEGAL ) {
      if ( authorized1 ) {
        createUpdateClients.push(clientToCreateUpdateContractClient(authorized1));
      }

      if ( authorized2 && this.state.authorizedClientsCount > 1 ) {
        createUpdateClients.push(clientToCreateUpdateContractClient(authorized2));
      }
    }

    if ( vinculation && this.props.calcData.generalInsuranceData.vinculation ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(vinculation));
    }

    return createUpdateClients;
  };

  resolvePageTitle = (): string => {
    const { selectedResult, calcData } = this.props;

    let pageTitle = `${t("calc.realty.titles.contractGeneration")} ${selectedResult.insuranceInstitution.name}`;
    pageTitle += selectedResult.coverage ? `, ${t("calc.realty.sections.coverage")} ${selectedResult.coverage}` : "";

    const insurancesTranslations = [];
    if ( calcData.realtyInsuranceData ) {
      insurancesTranslations.push(t("calc.realty.sections.realtyInsurance"));
    }
    if ( calcData.householdInsuranceData ) {
      insurancesTranslations.push(t("calc.realty.sections.householdInsurance"));
    }
    pageTitle += ` (${t("calc.realty.enums.buildingType." + calcData.generalBuildingData.type)} - ${insurancesTranslations.join(", ")})`;

    return pageTitle;
  };

  componentDidMount(): void {
    if ( this.props.initialData ) {
      const { insurerInputEnabled } = this.state;
      const { insured, insurer } = this.props.clients;
      const { institutionEnum } = this.props.selectedResult.insuranceInstitution;
      const { clientsData, generalBuildingData } = this.props.calcData;

      const formData = cloneDeep(this.props.initialData.formData);
      formData.clientsData.insuredClientIdentifier = clientsData.insuredClientType === ClientType.NATURAL ? clientsData.insuredClientPin : clientsData.insuredClientCrn;

      const insurerType = insurerInputEnabled ? (insurer ? insurer.type : null) : (insured ? insured.type : null);
      formData.informationObligationData.clientExperience = insurerType === ClientType.SELF_EMPLOYED || insurerType === ClientType.LEGAL
        ? ClientExperience.SUFFICIENT : formData.informationObligationData.clientExperience;

      if ( institutionEnum !== InstitutionEnum.AXA_NON_LIFE || generalBuildingData.state !== BuildingState.UNDER_CONSTRUCTION ) {
        delete formData.generalBuildingData;
      }

      if ( institutionEnum !== InstitutionEnum.AXA_NON_LIFE && institutionEnum !== InstitutionEnum.GENERALI && formData.generalInsuranceData ) {
        delete formData.generalInsuranceData.signCity;
      }

      if ( institutionEnum !== InstitutionEnum.ALLIANZ && formData.generalInsuranceData ) {
        delete formData.generalInsuranceData.anotherInsuranceCompanyName;
      }

      this.props.form.setFieldsValue(formData);

      this.props.onValidateClients({
        prefix: "clientsData.clients",
        clients: this.createContractClientsArray()
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ( !prevProps.genResult && this.props.genResult && this.props.genResult.error ) {
      const violations = processGenResultError(this.props.genResult.error, this.createClientsIndicesMap(),
        this.props.form, "calc.realty.attrs");

      if ( violations ) {
        messageUtils.errorNotification(
          this.props.genResult.error.message,
          <FieldViolationsView violations={violations.notificationViolations} rootPath="calc.realty.attrs" />, 10);
        this.setState({ clientsViolationErrors: violations.clientsViolations });
      }
    }

    if ( this.props.validateClientErrorResponse ) {
      this.processClientsViolationErrorsStateUpdate(this.props.validateClientErrorResponse);
    }
    else if ( this.props.validateClientsErrorResponse ) {
      this.processClientsViolationErrorsStateUpdate(this.props.validateClientsErrorResponse);
    }
  }

  render(): React.ReactNode {
    const { insurerInputEnabled } = this.state;
    const { form, selectedResult, calcData, initialData } = this.props;
    const { insured, insurer } = this.props.clients;

    const formLayout: ColProps = { span: 24, style: { maxWidth: "950px" } };

    return (
      <>
        <h2>{this.resolvePageTitle()}</h2>

        <Row gutter={64} className="margin-top-large" justify="center">
          <Col {...formLayout}>
            <Form layout="vertical">

              <RealtyGenClientsDataSection
                form={form}
                initialData={initialData ? initialData.formData : null}
                calcClientsData={calcData.clientsData}
                vinculation={calcData.generalInsuranceData.vinculation}
                selectedInstitutionEnum={selectedResult.insuranceInstitution.institutionEnum}
                insurerInputEnabled={insurerInputEnabled}
                authorizedClientsCount={this.state.authorizedClientsCount}
                clients={this.props.clients}
                clientsAutocomplete={this.props.clientsAutocomplete}
                clientsDuplicateErrors={this.state.clientsDuplicateErrors}
                clientsViolationErrors={this.state.clientsViolationErrors}
                onInsurerInputEnabledChange={this.handleInsurerInputEnabledChange}
                onAuthorizedClientAdd={this.handleAuthorizedClientAdd}
                onAuthorizedClientDelete={this.handleAuthorizedClientDelete}
                onClientChange={this.handleClientChange}
                onClientViolationErrorsChange={this.handleClientViolationErrorsChange}
                onClientDuplicateErrorChange={this.handleClientDuplicateErrorChange} />

              <RealtyGenAdditionalDataSection
                form={form}
                initialAnotherInsuranceCompanyName={initialData && initialData.formData && initialData.formData.generalInsuranceData
                  ? initialData.formData.generalInsuranceData.anotherInsuranceCompanyName : null}
                buildingState={calcData.generalBuildingData.state}
                insurerType={insurerInputEnabled ? (insurer ? insurer.type : null) : (insured ? insured.type : null)}
                selectedInstitutionEnum={selectedResult.insuranceInstitution.institutionEnum} />

            </Form>
          </Col>
        </Row>

        <RealtyCalcResults
          visible={this.state.resultsVisible}
          calcResults={this.props.calcResults}
          selectedResult={this.props.selectedResult}
          onClose={this.handleResultsClose}
          onSaveDraftClick={this.handleResultsSaveDraftClick}
          onGenerateOfferClick={this.handleResultsGenerateOfferClick}
          onGenerateContractClick={this.handleResultsGenerateContractClick} />

        <RealtyGenNavigation
          onFormSubmit={this.handleGenerateFormSubmit}
          onShowResultsClick={this.handleResultsShow}
          onReturnToCalculationClick={this.handleReturnToCalculationClick} />

        <CalcGenResultModal
          result={this.props.genResult}
          showDraftStep={!!this.props.draftId}
          onCancel={this.props.onGenResultDelete} />
      </>
    );
  }
}

export default Form.create<Props>()(RealtyGenWrapper);
