import React from "react";
import { Checkbox, Col, DatePicker, Divider, Input, InputNumber, Row, Select } from "antd";
import { Form } from "@ant-design/compatible";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import t from "../../../../../../../app/i18n";
import { InsuranceContractPaymentFrequency } from "../../../../../../contract/enums";
import validations from "../../../../../../../common/utils/validationUtils";
import {
  datePickerStandardProps,
  disableDatePickerPresentAndPast,
  selectStandardProps
} from "../../../../../../../common/utils/formUtils";
import { rowGutter } from "../../../../../../../common/constants";

export interface Props {
  form: WrappedFormUtils;
}

const RealtyCalcInsuranceStep = (props: Props) => {
  const { getFieldDecorator } = props.form;

  const colSpan = 8;

  return (
    <>
      <Row gutter={rowGutter}>
        <Divider>{t("calc.realty.sections.generalInsuranceData")}</Divider>

        <Col span={colSpan}>
          <Form.Item label={t("calc.realty.attrs.generalInsuranceData.effectiveBeginningDate")}>
            {getFieldDecorator("generalInsuranceData.effectiveBeginningDate", {
              rules: [validations.notNull, validations.notPresentAndPast]
            })(
              <DatePicker
                {...datePickerStandardProps} showToday={false}
                disabledDate={disableDatePickerPresentAndPast} />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("contract.enums.paymentFrequency._label")}>
            {getFieldDecorator("generalInsuranceData.paymentFrequency", { rules: [validations.notNull] })(
              <Select
                {...selectStandardProps}
                options={[InsuranceContractPaymentFrequency.ANNUALLY, InsuranceContractPaymentFrequency.SEMI_ANNUALLY, InsuranceContractPaymentFrequency.QUARTERLY]
                  .map(frequency => ({
                    value: frequency,
                    label: t("contract.enums.paymentFrequency." + frequency)
                  }))} />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item className="form-item-without-label">
            {getFieldDecorator("generalInsuranceData.complicity", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.generalInsuranceData.complicity")}</Checkbox>
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item className="form-item-without-label">
            {getFieldDecorator("generalInsuranceData.vinculation", {
              rules: [validations.none], valuePropName: "checked", initialValue: false
            })(
              <Checkbox>{t("calc.realty.attrs.generalInsuranceData.vinculation")}</Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Divider>{t("calc.realty.sections.crossSelling")}</Divider>

        <Col span={colSpan}>
          <Form.Item label={t("calc.realty.attrs.generalInsuranceData.crossSelling.uniqaContracts")}>
            {getFieldDecorator("generalInsuranceData.crossSelling.uniqaContracts", { rules: [validations.none] })(
              <InputNumber min={0} />
            )}
          </Form.Item>
        </Col>
        <Col span={colSpan}>
          <Form.Item label={t("calc.realty.attrs.generalInsuranceData.crossSelling.axaContractNumber")}>
            {getFieldDecorator("generalInsuranceData.crossSelling.axaContractNumber", { rules: [validations.none] })(
              <Input />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default RealtyCalcInsuranceStep;
