import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import { ClientList } from "../types";
import { ActionProps, RootState, SearchPageResult, UUID } from "../../../common/types";
import { deleteStateClientsPageAction, filterClientsActions, selectClientsCurrentPage } from "../ducks";
import { selectRouterLocationSearchParam } from "../../ducks";
import { appendSearchParamsToCurrentPathname } from "../../../common/utils/utils";
import { serializeParams } from "../../../common/utils/apiUtils";

import ClientListTableView from "../components/views/list/ClientListTableView";
import ClientListSearchHeaderView from "../components/views/list/ClientListSearchHeaderView";

interface StateProps {
  clientsCurrentPage: SearchPageResult<ClientList>;
  initialUrlSearchKeyword: string;
}

interface ActionsMap {
  filterClients: typeof filterClientsActions.request;
  deleteStateClientsPage: typeof deleteStateClientsPageAction;
  pushNavigation: typeof push;
  replaceNavigation: typeof replace;
}

class ClientListContainer extends React.Component<StateProps & ActionProps<ActionsMap>> {
  private static readonly PAGE_SIZE = 50;

  handleTableRowDoubleClick = (clientId: UUID): void => {
    this.props.actions.pushNavigation("/clients/" + clientId);
  };

  handleTablePageChange = (pageNumber: number): void => {
    this.props.actions.filterClients({
      keyword: this.props.clientsCurrentPage.keyword,
      pageIndex: pageNumber - 1,
      pageSize: ClientListContainer.PAGE_SIZE
    });
  };

  handleSearchSubmit = (keyword: string): void => {
    this.props.actions.replaceNavigation(appendSearchParamsToCurrentPathname(
      serializeParams({ keyword: keyword !== "" ? keyword : null })));

    this.props.actions.filterClients({
      keyword,
      pageIndex: 0,
      pageSize: ClientListContainer.PAGE_SIZE
    });
  };

  componentWillUnmount(): void {
    this.props.actions.deleteStateClientsPage();
  }

  componentDidMount(): void {
    this.props.actions.filterClients({
      keyword: this.props.initialUrlSearchKeyword,
      pageIndex: 0,
      pageSize: ClientListContainer.PAGE_SIZE
    });
  }

  render(): React.ReactNode {
    return (
      <>
        <ClientListSearchHeaderView
          initialSearchKeyword={this.props.initialUrlSearchKeyword}
          onSearchSubmit={this.handleSearchSubmit} />

        <ClientListTableView
          clientsPage={this.props.clientsCurrentPage}
          onRowDoubleClick={this.handleTableRowDoubleClick}
          onPageChange={this.handleTablePageChange} />
      </>
    )
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  clientsCurrentPage: selectClientsCurrentPage(state),
  initialUrlSearchKeyword: selectRouterLocationSearchParam(state, "keyword")
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    filterClients: filterClientsActions.request,
    deleteStateClientsPage: deleteStateClientsPageAction,
    pushNavigation: push,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(ClientListContainer);
