import React from "react";
import { Col, Row } from "antd";
import { ColProps } from "antd/lib/grid";
import { LoanContract } from "../../../../types";
import {
  deleteContractActions,
  deleteContractAttachmentActions,
  uploadContractAttachmentsActions,
  verifyContractActions
} from "../../../../ducks";

import LoanContractHeaderSection from "./sections/LoanContractHeaderSection";
import LoanContractDataSection from "./sections/LoanContractDataSection";
import ContractAttachmentsSection from "../ContractAttachmentsSection";

export interface Props {
  contract: LoanContract;
  onVerify: typeof verifyContractActions.request;
  onDelete: typeof deleteContractActions.request;
  onAttachmentsUpload: typeof uploadContractAttachmentsActions.request;
  onAttachmentDelete: typeof deleteContractAttachmentActions.request;
  onUpdateClick(): void;
}

const LoanContractDetailView = ({ contract, ...props }: Props) => {

  const layout: ColProps = { span: 24, style: { maxWidth: "1100px" } };

  return (
    <Row gutter={64} className="margin-top-small" justify="center">
      <Col {...layout}>

        <LoanContractHeaderSection
          contract={contract}
          onVerify={props.onVerify}
          onDelete={props.onDelete}
          onUpdateClick={props.onUpdateClick} />

        <LoanContractDataSection contract={contract} />

        <ContractAttachmentsSection
          contract={contract}
          onUpload={props.onAttachmentsUpload}
          onDelete={props.onAttachmentDelete} />

      </Col>
    </Row>
  )
};

export default LoanContractDetailView;
