import React from "react";
import get from "lodash/get";
import { Col, Form, InputNumber, Row, Select } from "antd";
import { Client } from "../../../../../client/types";
import { validations } from "../../../../../../common/utils/validationUtils";
import { selectStandardProps } from "../../../../../../common/utils/formUtils";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import LifeInsuranceTariffFormItemSelect
  from "../../../../../enumerations/components/form/LifeInsuranceTariffFormItemSelect";
import InputNumberWithAddon from "../../../../../../common/components/form/components/InputNumberWithAddon";

export interface Props {
  index: number;
  clients: Client[];
}

const LifeInsuranceFormPart = ({ index, clients }: Props) => {

  const colSpan = 4;
  const colSpanBig = 6;
  const formNamePrefix = ["insurances", index];
  const formNameDataPrefix = [...formNamePrefix, "insuranceData"];

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => get(prev, formNamePrefix)?.productId !== get(next, formNamePrefix)?.productId}>
            {({ getFieldValue }) => (
              <LifeInsuranceTariffFormItemSelect
                formItemProps={{
                  name: [...formNamePrefix, "tariffId"],
                  label: t("contract.attrs.insurances.tariffId"),
                  rules: [validations.notNull]
                }}
                productId={getFieldValue([...formNamePrefix, "productId"])} />
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "insurancePeriod"]}
            label={t("contract.attrs.insurances.insuranceData.insurancePeriod")}
            rules={[validations.notNull, validations.minNumber(1)]}>
            <InputNumber min={1} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "insuranceAmount"]}
            label={t("contract.attrs.insurances.insuranceData.insuranceAmount")}
            rules={[validations.notNull, validations.minNumber(0)]}>
            <InputNumberWithAddon addonType="euro" formatStyle="decimal" min={0.01} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpanBig}>
          <Form.Item
            name={[...formNamePrefix, "insuredClientIdentifier"]}
            label={t("contract.attrs.insurances.insuredClientIdentifier")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={clients.filter(c => !!c).map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default LifeInsuranceFormPart;
