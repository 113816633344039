import { ChangeEvent, FocusEvent, ReactText, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment, { Moment } from "moment";
import latinise from "voca/latinise";
import has from "lodash/has";
import at from "lodash/at";
import isEmpty from "lodash/isEmpty";
import { AsYouType } from "libphonenumber-js";
import { flatten } from "flat";
import { SelectProps } from "antd/lib/select";
import { InputNumberProps } from "antd/lib/input-number";
import { ShowSearchType } from "antd/lib/cascader";
import { FormInstance } from "antd/lib/form";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { FieldData, NamePath, ValidateErrorEntity } from "rc-field-form/lib/interface"
import { SortOrder } from "antd/lib/table/interface";
import { TablePaginationConfig } from "antd/lib/table";
import { ApiRequest, FieldConstraintViolation, RootState } from "../types";
import { ValidationErrorResponse } from "../../modules/types";
import { OrderDirection } from "../enums";
import { deleteStateValidationErrorResponseAction, selectValidationErrorResponse } from "../../modules/ducks";
import { ALL_WHITE_SPACES_PATTERN, insertToString, isLocalhostDevMode, isNotEmptyArray, valueToString } from "./utils";
import { tValidationMessage } from "./translationUtils";
import { regexPatterns, validationFunctions } from "./validationUtils";
import t from "../../app/i18n";

const MOMENT_DATE_FORMAT = "YYYY-MM-DD";
const MOMENT_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS";
const DATE_TIME_PICKER_FORMAT = "D.M.YYYY, hh:mm:ss";

const NON_DIGIT_CHARACTERS_REGEX = new RegExp("[^\\d]", "g");
const NON_DECIMAL_DIGIT_CHARACTERS_REGEX = new RegExp("[^\\d.]", "g");
const EVERY_FOURTH_CHAR_PATTERN = new RegExp(/(.{4})/, "g");
const EVERY_THIRD_CHAR_PATTERN = new RegExp(/(.{3})/, "g");
const BEGINNING_END_WHITE_SPACES_PATTER = new RegExp(/(^\s+|\s+$)/, "g");

export type FormatType = "lowerCase" | "upperCase" | "threeSpaces" | "fourSpaces" | "iban" | "phone" | "licensePlate";

export const useFormErrorHandler = (form: FormInstance, translationRootPath: string, request: ApiRequest): void => {
  const dispatch = useDispatch();
  const { onErrorResponseDelete } = useMemo(() => bindActionCreators({
    onErrorResponseDelete: deleteStateValidationErrorResponseAction
  }, dispatch), [dispatch]);

  const errorResponse = useSelector<RootState, ValidationErrorResponse>(state => selectValidationErrorResponse(state, request));

  useEffect(() => {
    if ( errorResponse ) {
      setErrorsToForm(form, translationRootPath, errorResponse.violations);
      onErrorResponseDelete();
    }
  }, [form, translationRootPath, errorResponse, onErrorResponseDelete]);
}

export const setErrorsToForm = (form: FormInstance,
                                translationRootPath: string,
                                violations: FieldConstraintViolation[]): void => {
  if ( isNotEmptyArray(violations) ) {
    const fieldsValues = form.getFieldsValue();
    const errorFields: FieldData[] = [];

    violations.forEach((violation: FieldConstraintViolation): void => {
      const fieldValue = at(fieldsValues, violation.fieldPath)[0];
      if ( has(fieldsValues, violation.fieldPath) && (fieldValue instanceof moment || typeof fieldValue !== "object" || fieldValue === null) ) {
        errorFields.push({
          name: fieldPathToNamePath(violation.fieldPath),
          errors: violation.errors.map<string>(errorMessage => tValidationMessage(translationRootPath, errorMessage))
        })
      }
    });

    if ( isNotEmptyArray(errorFields) ) {
      form.setFields(errorFields);
    }
  }
}

export const setErrorsToForm_deprecated = (form: WrappedFormUtils,
                                           violations: FieldConstraintViolation[],
                                           translationRootPath: string): void => {
  if ( violations ) {
    const fieldsValues = form.getFieldsValue();
    const errorFields = {};

    violations.forEach((violation: FieldConstraintViolation): void => {
      const fieldValue = at(fieldsValues, violation.fieldPath)[0];
      if ( has(fieldsValues, violation.fieldPath) && (fieldValue instanceof moment || typeof fieldValue !== "object" || fieldValue === null) ) {
        errorFields[violation.fieldPath] = {
          value: form.getFieldValue(violation.fieldPath),
          errors: violation.errors.map<Error>(errorMessage => new Error(tValidationMessage(translationRootPath, errorMessage)))
        }
      }
    });

    if ( !isEmpty(errorFields) ) {
      form.setFieldsValue(fieldsValues);
      form.setFields(errorFields);
    }
  }
};

export const fieldPathToNamePath = (fieldPath: string): NamePath => {
  return fieldPath
    .replace("[", ".")
    .replace("]", "")
    .split(".")
    .map(token => regexPatterns.numberRegex.test(token) ? parseInt(token) : token);
}

export const getAllRootFieldsNames_deprecated = (form: WrappedFormUtils): string[] => {
  return Object.keys(form.getFieldsValue());
};

export const getAllFieldsNames = (form: FormInstance): ReactText[][] => {
  return Object.keys(flatten(
    JSON.parse(JSON.stringify(form.getFieldsValue(), (key: string, value: any): any => typeof value === "undefined" ? null : value))
  )).map(key => key.split(".").map(token => regexPatterns.numberRegex.test(token) ? parseInt(token) : token));
};

export const resolveFormValidationError = (errorInfo: ValidateErrorEntity): void => {
  if ( isLocalhostDevMode() ) {
    console.warn("Form validation error caught.", errorInfo);
  }
}

export const toMoment = (input: string | Moment): Moment | null => {
  if ( !input ) {
    return null;
  }
  const date = moment(input);
  return date.isValid() ? date : null;
};

export const toMomentArray = (inputs: string[]): Moment[] | null => {
  if ( !inputs || inputs.length === 0 || inputs.filter(input => !!input).length === 0 ) {
    return null;
  }
  return inputs.map(input => toMoment(input));
};

export const momentToDateString = (date: Moment): string => {
  return date ? moment(date).format(MOMENT_DATE_FORMAT) : null;
}

export const momentToDateTimeString = (date: Moment): string => {
  return date ? date.format(MOMENT_DATE_TIME_FORMAT) : null;
}

export const getDatePickerFormat = (): string => moment.localeData().longDateFormat("l");

export const datePickerStandardProps: PickerDateProps<Moment> = {
  format: getDatePickerFormat(),
  placeholder: null,
  allowClear: false
};

export const datePickerClearableProps: PickerDateProps<Moment> = {
  ...datePickerStandardProps,
  allowClear: true
};

export const dateTimePickerStandardProps: PickerDateProps<Moment> = {
  format: DATE_TIME_PICKER_FORMAT,
  placeholder: null,
  allowClear: false
};

export const dateTimePickerClearableProps: PickerDateProps<Moment> = {
  ...dateTimePickerStandardProps,
  allowClear: true
};

export const disableDatePickerPresentAndFuture = (checked: Moment): boolean => {
  return checked && checked.isSameOrAfter(moment(), "day");
};

export const disableDatePickerFuture = (checked: Moment): boolean => {
  return checked && checked.isAfter(moment(), "day");
};

export const disableDatePickerPresentAndPast = (checked: Moment): boolean => {
  return checked && checked.isSameOrBefore(moment(), "day");
};

export const disableDatePickerPast = (checked: Moment): boolean => {
  return checked && checked.isBefore(moment(), "day");
};

export const disableDatePickerPresent = (checked: Moment): boolean => {
  return checked && checked.isSame(moment(), "day");
};

export const disableDatePickerOutOfInterval = (checked: Moment, min: Moment, max: Moment): boolean => {
  return checked && (checked.isBefore(min, "day") || checked.isAfter(max, "day"));
};

export const disableDatePickerOutOfMinDate = (checked: Moment, min: Moment): boolean => {
  return checked && checked.isBefore(min, "day");
};

export const disableDatePickerOutOfMinDateIncluded = (checked: Moment, min: Moment): boolean => {
  return checked && checked.isSameOrBefore(min, "day");
};

export const disableDatePickerOutOfMaxDate = (checked: Moment, max: Moment): boolean => {
  return checked && checked.isAfter(max, "day");
};

export const disableDatePickerOutOfMaxDateIncluded = (checked: Moment, max: Moment): boolean => {
  return checked && checked.isSameOrAfter(max, "day");
};

export const selectFilterFunction = (input: string, option?: object): boolean => {
  return option && latinise((option["label"] || option["children"].toString()).toLowerCase()).indexOf(latinise(input.toLowerCase())) !== -1;
};

export const selectTagsFilterFunction = (input: string, option: object, translationsMap: Map<string, string>): boolean => {
  return option && latinise((translationsMap.get(option["value"]) || "").toLowerCase()).indexOf(latinise(input.toLowerCase())) !== -1;
};

export const selectStandardProps: SelectProps<any> = {
  showSearch: true,
  filterOption: selectFilterFunction,
  dropdownMatchSelectWidth: false
};

export const selectTagsStandardProps = (translationsMap: Map<string, string>): SelectProps<any> => ({
  showSearch: true,
  filterOption: (inputValue, option) => selectTagsFilterFunction(inputValue, option, translationsMap),
  dropdownMatchSelectWidth: false
});

export const treeNodeFilterFunction = (input: string, treeNode?: object): boolean => {
  return treeNode && latinise(treeNode["title"].toLowerCase()).indexOf(latinise(input.toLowerCase())) !== -1;
};

export const cascaderSearchConfigObject: ShowSearchType = {
  filter: (inputValue, path) => path.some(option =>
    latinise(option.label.toString().toLowerCase()).indexOf(latinise(inputValue.toLowerCase())) !== -1)
};

export const inputNumberIntegerFormatter: InputNumberProps = {
  formatter: value => {
    // @ts-ignore
    return value || value === 0 ? Intl.NumberFormat("SK").format(value) : "";
  },
  parser: displayValue => displayValue ? displayValue.replace(NON_DIGIT_CHARACTERS_REGEX, "") : ""
};

export const inputNumberDecimalFormatter: InputNumberProps = {
  formatter: value => {
    if ( !value || value === 0 ) {
      return "";
    }
    const tokens = valueToString(value).split(".");
    // @ts-ignore
    return Intl.NumberFormat("SK").format(tokens[0]) + (tokens.length > 1 ? ("." + tokens[1]) : "");
  },
  parser: displayValue => displayValue ? displayValue.replace(NON_DECIMAL_DIGIT_CHARACTERS_REGEX, "") : ""
};

export const threeSpacedStringNormalizeFunction = (input: string): string => {
  return input
    ? input.replace(ALL_WHITE_SPACES_PATTERN, "").replace(EVERY_THIRD_CHAR_PATTERN, "$1 ").replace(BEGINNING_END_WHITE_SPACES_PATTER, "")
    : input;
};

export const fourSpacedStringNormalizeFunction = (input: string): string => {
  return input
    ? input.replace(ALL_WHITE_SPACES_PATTERN, "").replace(EVERY_FOURTH_CHAR_PATTERN, "$1 ").replace(BEGINNING_END_WHITE_SPACES_PATTER, "")
    : input;
};

export const lowerCaseStringNormalizeFunction = (input: string): string => input ? input.toLowerCase() : input;

export const upperCaseStringNormalizeFunction = (input: string): string => input ? input.toUpperCase() : input;

export const licensePlateNormalizeFunction = (input: string): string => {
  if ( input ) {
    input = input.replace(ALL_WHITE_SPACES_PATTERN, "");
    input = input.length > 2 ? insertToString(input, " ", 2) : input;
    input = input.length > 6 ? insertToString(input, " ", 6) : input;
    return input.toUpperCase();
  }
  return input;
};

export const phoneNumberNormalizeFunction = (value: string): string => value ? new AsYouType("SK").input(value) : value;

export const ibanNormalizeFunction = (input: string): string => input ? fourSpacedStringNormalizeFunction(input).toUpperCase() : input;

export const normalizeOnBlur = (event: FocusEvent<HTMLInputElement>,
                                form: WrappedFormUtils,
                                type: FormatType,
                                eventCallback?: (value: string) => void): void => {
  const normalizedValue = normalizeValue(event.target.value, type);
  if ( event.target.value !== normalizedValue ) {
    form.setFieldsValue({ [event.target.id]: normalizedValue });
  }
  if ( eventCallback ) {
    eventCallback(normalizedValue);
  }
}

export const normalizeValue = (value: string, type: FormatType): string => {
  switch ( type ) {
    case "lowerCase":
      return lowerCaseStringNormalizeFunction(value);
    case "upperCase":
      return upperCaseStringNormalizeFunction(value);
    case "threeSpaces":
      return threeSpacedStringNormalizeFunction(value);
    case "fourSpaces":
      return fourSpacedStringNormalizeFunction(value);
    case "iban":
      return ibanNormalizeFunction(value);
    case "phone":
      return phoneNumberNormalizeFunction(value);
    case "licensePlate":
      return licensePlateNormalizeFunction(value);
    default:
      return value;
  }
}

export const fillBirthDateFromPinChangeEvent_deprecated = (event: ChangeEvent<HTMLInputElement>, form: WrappedFormUtils, birthDateFormKey: string = "birthDate"): void => {
  fillBirthDateFromPin_deprecated(event.target.value, form, birthDateFormKey);
};

export const fillBirthDateFromPin_deprecated = (pin: string, form: WrappedFormUtils, birthDateFormKey: string = "birthDate"): void => {
  if ( !pin || !validationFunctions.validatePin(pin) ) {
    form.setFieldsValue({ [birthDateFormKey]: undefined });
  }
  else {
    const yearString = pin.substring(0, 2);
    const month = parseInt(pin.substring(2, 4));
    const birthDate = moment()
      .year(pin.length === 9
        ? parseInt("19" + yearString)
        : parseInt((moment().get("year") - parseInt(yearString) < 2000 ? "19" : "20") + yearString))
      .month(month > 50 ? month - 51 : month - 1)
      .date(parseInt(pin.substring(4, 6)));

    form.setFieldsValue({ [birthDateFormKey]: birthDate });
  }
};

export const fillBirthDateFromPinChangeEvent = (event: ChangeEvent<HTMLInputElement>, form: FormInstance, birthDateFormKey: string = "birthDate"): void => {
  fillBirthDateFromPin(event.target.value, form, birthDateFormKey);
};

export const fillBirthDateFromPin = (pin: string, form: FormInstance, birthDateFormKey: string = "birthDate"): void => {
  if ( !pin || !validationFunctions.validatePin(pin) ) {
    form.setFieldsValue({ [birthDateFormKey]: undefined });
  }
  else {
    const yearString = pin.substring(0, 2);
    const month = parseInt(pin.substring(2, 4));
    const birthDate = moment()
      .year(pin.length === 9
        ? parseInt("19" + yearString)
        : parseInt((moment().get("year") - parseInt(yearString) < 2000 ? "19" : "20") + yearString))
      .month(month > 50 ? month - 51 : month - 1)
      .date(parseInt(pin.substring(4, 6)));

    form.setFieldsValue({ [birthDateFormKey]: birthDate });
  }
};

export const sortOrderToOrderDirection = (sortOrder: SortOrder): OrderDirection => {
  if ( !sortOrder ) {
    return null;
  }
  switch ( sortOrder ) {
    case "ascend":
      return OrderDirection.ASC;
    case "descend":
      return OrderDirection.DESC;
    default:
      return null;
  }
};

export const orderDirectionToSortOrder = (orderDirection: OrderDirection): SortOrder => {
  if ( !orderDirection ) {
    return null;
  }
  switch ( orderDirection ) {
    case OrderDirection.ASC:
      return "ascend";
    case OrderDirection.DESC:
      return "descend"
    default:
      return null;
  }
};

export const paginationStandardProps: TablePaginationConfig = {
  position: ["topRight", "bottomRight"],
  showTotal: (total, [from, to]) => t("common.pagination", { from, to, total }),
  showQuickJumper: true,
  showSizeChanger: false
};

export const quillEditorStandardProps: object = {
  theme: "snow",
  modules: {
    toolbar: [
      [{ "size": ["small", false, "large"] }],
      ["bold", "italic", "underline", "strike", { "color": [] }],
      [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
      ["link", "clean"]
    ]
  },
  formats: [
    "header", "size", "code", "code-block", "blockquote", "bold", "italic", "underline", "strike", "color", "list", "bullet", "indent", "link"
  ]
};
