import React, { useEffect } from "react";
import { Col, Form, Input, Modal, Row } from "antd";
import { Store } from "rc-field-form/lib/interface";
import { JobSettings, UpdateJobSettings } from "../../types";
import { updateJobActions } from "../../ducks";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import { requests } from "../../api";
import { formatCron } from "../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import HiddenCheckbox from "../../../../../common/components/form/components/HiddenCheckbox";

export interface Props {
  job: JobSettings;
  visible: boolean;
  onUpdate: typeof updateJobActions.request;
  onFormCancel(): void;
}

const UpdateJobForm = ({ job, visible, onUpdate, onFormCancel }: Props) => {

  const [form] = Form.useForm();
  useFormErrorHandler(form, "admin.job.attrs", requests.UPDATE_JOB);

  useEffect(() => {
    if ( visible && job ) {
      form.setFieldsValue(job);
    }
  }, [visible, job, form]);

  const handleFormCancel = (): void => {
    onFormCancel();
    form.resetFields();
  };

  const inProgress = useRequestFinishedCallback(requests.UPDATE_JOB, handleFormCancel);

  const handleFormSubmit = (): void => {
    form.validateFields()
      .then((values: UpdateJobSettings | Store) => onUpdate({
        id: job.id,
        object: { ...values as UpdateJobSettings, preferences: job.preferences }
      }))
      .catch(resolveFormValidationError);
  };

  const colSpan = 12;

  return (
    <Modal
      width={700}
      visible={visible}
      title={t("admin.job.titles.updateJob")}
      okText={t("common.save")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      forceRender
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={handleFormCancel}>

      <Form form={form} layout="vertical" name="updateJobForm">
        <HiddenInput name="optimisticLockVersion" />
        <HiddenCheckbox name="enabled" />

        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="name"
              label={t("admin.job.attrs.name")}
              rules={[validations.notBlank, validations.size(1, 128)]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.cron !== next.cron}>
              {({ getFieldValue }) => {
                const cronValue = formatCron(getFieldValue("cron"))
                return (
                  <Form.Item
                    name="cron"
                    label={cronValue ? <>{t("admin.job.attrs.cron")} - {cronValue}</> : <>{t("admin.job.attrs.cron")}</>}
                    rules={[validations.size(1, 255)]}>
                    <Input />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
}

export default UpdateJobForm;
