import React from "react";
import { Tag } from "antd";
import { ContractStatus } from "../enums";
import t from "../../../app/i18n";

export interface Props {
  status: ContractStatus;
  closable?: boolean;
  onClose?(event?: React.MouseEvent<HTMLElement, MouseEvent>): void;
}

const ContractStatusTag = ({ status, closable, onClose }: Props) => {
  let color: string;

  switch ( status ) {
    case ContractStatus.UNSTARTED:
      color = "cyan";
      break;
    case ContractStatus.ACTIVE:
      color = "green";
      break;
    case ContractStatus.CANCELED:
      color = "purple";
      break;
    case ContractStatus.FINISHED:
      color = "geekblue";
      break;
    case ContractStatus.TRANSFERRED_TO_BROKER:
      color = "orange";
      break;
    default:
      return null;
  }

  return <Tag color={color} closable={closable} onClose={onClose}>{t("contract.enums.status." + status)}</Tag>;
};

export default ContractStatusTag;
