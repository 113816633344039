import React from "react";
import { Button, Modal, Result, Timeline } from "antd";
import { DoubleLeftOutlined, FileDoneOutlined } from "@ant-design/icons";
import { GenResponse } from "../types";
import { GenStep } from "../enums";
import { getOldCrmBaseUrl, isLocalhostDevMode } from "../../../../common/utils/utils";
import { checkIfStepsAreSuccessful } from "../utils";
import t from "../../../../app/i18n";

import TimelineDotIcon from "../../../../common/components/icons/TimelineDotIcon";

export interface Props {
  result: GenResponse;
  showDraftStep?: boolean;
  showReturnToCalculationButton?: boolean;
  onCancel?(): void;
  onReturnToCalculationClick?(): void;
}

const CalcGenResultModal = ({ result, showDraftStep, showReturnToCalculationButton, ...props }: Props) => {

  const contractCreated = result && result.steps && checkIfStepsAreSuccessful(result.steps, GenStep.CREATE_CONTRACT);
  let contractSaved = false;
  let attachmentsProcessed = false;
  let emailSent = false;
  let draftDeleted = false;
  let contractSavedToOldCrm = false;
  let allStepsSuccessful = false;

  if ( contractCreated ) {
    contractSaved = checkIfStepsAreSuccessful(result.steps, GenStep.SAVE_CLIENTS_AND_CONTRACT);
    attachmentsProcessed = checkIfStepsAreSuccessful(result.steps, GenStep.DOWNLOAD_ATTACHMENTS, GenStep.GENERATE_ATTACHMENTS, GenStep.SAVE_ATTACHMENTS);
    emailSent = checkIfStepsAreSuccessful(result.steps, GenStep.SEND_EMAIL);
    draftDeleted = checkIfStepsAreSuccessful(result.steps, GenStep.TRANSFER_DRAFT_ATTACHMENTS_AND_DELETE_DRAFT);
    contractSavedToOldCrm = checkIfStepsAreSuccessful(result.steps, GenStep.SAVE_CLIENTS_AND_CONTRACT_TO_OLD_CRM);
    allStepsSuccessful = contractSaved && attachmentsProcessed && emailSent && draftDeleted && contractSavedToOldCrm;
  }

  return (
    <Modal
      width={650}
      title={t("calc.genResult.title")}
      visible={contractCreated}
      maskClosable={false}
      closable={isLocalhostDevMode()}
      cancelButtonProps={null}
      footer={
        <>
          {showReturnToCalculationButton && props.onReturnToCalculationClick && (
            <Button type="dashed" icon={<DoubleLeftOutlined />} onClick={props.onReturnToCalculationClick}>
              {t("calc.genResult.returnToCalculation")}
            </Button>
          )}
          <Button type="primary" icon={<FileDoneOutlined />} onClick={() => window.open(
            contractSavedToOldCrm ? getOldCrmBaseUrl() + "/contracts/" + result.contract.oldCrmId : getOldCrmBaseUrl() + "/contracts",
            "_self")}>
            {contractSavedToOldCrm ? t("calc.genResult.continueToContract") : t("calc.genResult.continueToContractsList")}
          </Button>
        </>
      }
      onCancel={props.onCancel}>

      <Result
        style={{ padding: "0 32px" }}
        status={allStepsSuccessful ? "success" : "warning"}
        title={allStepsSuccessful ? t("calc.genResult.successDesc") : t("calc.genResult.errorDesc")}
        extra={
          <>
            {!allStepsSuccessful && (
              <div className="margin-bottom-large center-align">
                <div className="highlighted-text-big margin-bottom-small">
                  {t("calc.genResult.errorInfoDesc1")}
                </div>
                <div className="sub-header-info">{t("calc.genResult.errorInfoDesc2")}</div>
              </div>
            )}

            <div style={{ maxWidth: "350px", margin: "0 auto" }}>
              <Timeline>
                <Timeline.Item dot={<TimelineDotIcon status="success" />} color="green">
                  {t("calc.genResult.steps.createContract")}
                </Timeline.Item>

                <Timeline.Item dot={<TimelineDotIcon status={contractSaved ? "success" : "error"} />}
                               color={contractSaved ? "green" : "red"}>
                  {t("calc.genResult.steps.saveContract")}
                </Timeline.Item>

                <Timeline.Item dot={<TimelineDotIcon status={attachmentsProcessed ? "success" : "error"} />}
                               color={attachmentsProcessed ? "green" : "red"}>
                  {t("calc.genResult.steps.processAttachments")}
                </Timeline.Item>

                <Timeline.Item dot={<TimelineDotIcon status={emailSent ? "success" : "error"} />}
                               color={emailSent ? "green" : "red"}>
                  {t("calc.genResult.steps.sendEmail")}
                </Timeline.Item>

                {showDraftStep && (
                  <Timeline.Item dot={<TimelineDotIcon status={draftDeleted ? "success" : "error"} />}
                                 color={draftDeleted ? "green" : "red"}>
                    {t("calc.genResult.steps.deleteDraft")}
                  </Timeline.Item>
                )}

                <Timeline.Item dot={<TimelineDotIcon status={contractSavedToOldCrm ? "success" : "error"} />}
                               color={contractSavedToOldCrm ? "green" : "red"}>
                  {t("calc.genResult.steps.saveContractToOldCrm")}
                </Timeline.Item>
              </Timeline>
            </div>
          </>
        } />

    </Modal>
  );
};

export default React.memo(CalcGenResultModal);
