import React from "react";
import moment from "moment";
import get from "lodash/get";
import { Checkbox, Col, Form, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { CreateUpdateRealtyInsurance } from "../../../../types";
import { Client } from "../../../../../client/types";
import {
  BuildingState,
  BuildingType,
  ConstructionMaterial,
  RoofType
} from "../../../../../calculator/calcs/realty/enums";
import { ClientType } from "../../../../../client/enums";
import { selectStandardProps } from "../../../../../../common/utils/formUtils";
import { formatClientName } from "../../../../../../common/utils/formatUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { rowGutter } from "../../../../../../common/constants";
import t from "../../../../../../app/i18n";

import PlaceOfInsuranceForm from "../../../../../../common/modules/address/PlaceOfInsuranceForm";
import InputNumberWithAddon from "../../../../../../common/components/form/components/InputNumberWithAddon";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";

export interface Props {
  index: number;
  clients: Client[];
  form: FormInstance;
}

const RealtyInsuranceFormPart = ({ index, clients, form }: Props) => {

  const handleBuildingStateChange = (state: BuildingState): void => {
    if ( state === BuildingState.UNDER_CONSTRUCTION ) {
      const insurances = [...(form.getFieldValue(["insurances"]) || [])] as CreateUpdateRealtyInsurance[];
      insurances[index] = {
        ...insurances[index],
        insuranceData: {
          ...insurances[index].insuranceData,
          constructionYear: null,
          householdEnabled: false,
          household: null
        }
      }
      form.setFieldsValue({ insurances });
    }
  };

  const colSpan = 4;
  const colSpanBig = 6;
  const formNamePrefix = ["insurances", index];
  const formNameDataPrefix = [...formNamePrefix, "insuranceData"];

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "buildingType"]}
            label={t("calc.realty.enums.buildingType._label")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(BuildingType).map(type => ({
                value: type,
                label: t("calc.realty.enums.buildingType." + type)
              }))} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "buildingState"]}
            label={t("calc.realty.enums.buildingState._label")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(BuildingState).map(state => ({
                value: state,
                label: t("calc.realty.enums.buildingState." + state)
              }))}
              onChange={handleBuildingStateChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.buildingState !== get(next, formNameDataPrefix)?.buildingState}>
            {({ getFieldValue }) => {
              const state = getFieldValue([...formNameDataPrefix, "buildingState"]) as BuildingState;
              return (
                <Form.Item
                  name={[...formNameDataPrefix, "constructionYear"]}
                  label={t("contract.attrs.insurances.insuranceData.constructionYear")}
                  rules={[
                    state === BuildingState.FINISHED ? validations.notNull : validations.none,
                    validations.minNumber(1900), validations.maxNumber(moment().year())
                  ]}>
                  <InputNumber min={1900} max={moment().year()} disabled={state === BuildingState.UNDER_CONSTRUCTION} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "permanentlyOccupied"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
            className="form-item-without-label">
            <Checkbox>{t("contract.attrs.insurances.insuranceData.permanentlyOccupied")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "complicity"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
            className="form-item-without-label">
            <Checkbox>{t("contract.attrs.insurances.insuranceData.complicity")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "vinculation"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
            className="form-item-without-label">
            <Checkbox>{t("contract.attrs.insurances.insuranceData.vinculation")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.buildingState !== get(next, formNameDataPrefix)?.buildingState}>
        {({ getFieldValue }) => (
          <PlaceOfInsuranceForm
            form={form}
            rootKey={[...formNameDataPrefix, "placeOfInsurance"]}
            label={t("contract.attrs.insurances.insuranceData.placeOfInsurance._label")}
            buildingState={getFieldValue([...formNameDataPrefix, "buildingState"])} />
        )}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.buildingType !== get(next, formNameDataPrefix)?.buildingType}>
        {({ getFieldValue }) => {
          const buildingType = getFieldValue([...formNameDataPrefix, "buildingType"]) as BuildingType;
          switch ( buildingType ) {
            case BuildingType.HOUSE:
            case BuildingType.RECREATIONAL_BUILDING:
              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={24}><b>{t("calc.realty.enums.buildingType." + buildingType)}</b></Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "houseOrRecreationalBuilding", "constructionMaterial"]}
                        label={t("calc.realty.enums.constructionMaterial._label")}
                        rules={[validations.notNull]}>
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(ConstructionMaterial).map(material => ({
                            value: material,
                            label: t("calc.realty.enums.constructionMaterial." + material)
                          }))} />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "houseOrRecreationalBuilding", "roofType"]}
                        label={t("calc.realty.enums.roofType._label")}
                        rules={[validations.notNull]}>
                        <Select
                          {...selectStandardProps}
                          options={Object.keys(RoofType).map(type => ({
                            value: type,
                            label: t("calc.realty.enums.roofType." + type)
                          }))} />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "houseOrRecreationalBuilding", "buildArea"]}
                        label={t("contract.attrs.insurances.insuranceData.houseOrRecreationalBuilding.buildArea")}
                        rules={[validations.notNull, validations.minNumber(1)]}>
                        <InputNumberWithAddon addonType="area" formatStyle="integer" min={1} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            case BuildingType.APARTMENT_IN_APARTMENT_BUILDING:
            case BuildingType.APARTMENT_IN_FAMILY_HOUSE:
              return (
                <>
                  <Row gutter={rowGutter}>
                    <Col span={24}><b>{t("calc.realty.enums.buildingType." + buildingType)}</b></Col>
                  </Row>

                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "apartment", "floor"]}
                        label={t("contract.attrs.insurances.insuranceData.apartment.floor")}
                        rules={[validations.notNull, validations.minNumber(1)]}>
                        <InputNumber min={1} />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={[...formNameDataPrefix, "apartment", "floorArea"]}
                        label={t("contract.attrs.insurances.insuranceData.apartment.floorArea")}
                        rules={[validations.notNull, validations.minNumber(1)]}>
                        <InputNumberWithAddon addonType="area" formatStyle="integer" min={1} />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            default:
              return null;
          }
        }}
      </Form.Item>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "realtyEnabled"]}
            valuePropName="checked"
            dependencies={[[...formNameDataPrefix, "householdEnabled"]]}
            rules={[
              validations.notFalseIfOtherFalse(
                form, [...formNameDataPrefix, "householdEnabled"],
                t("contract.attrs.insurances.insuranceData.household._label"))
            ]}
            initialValue={false}
            className="form-item-without-label">
            <Checkbox>{t("contract.attrs.insurances.insuranceData.realty._label")}</Checkbox>
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.realtyEnabled !== get(next, formNameDataPrefix)?.realtyEnabled}>
          {({ getFieldValue }) => getFieldValue([...formNameDataPrefix, "realtyEnabled"]) && (
            <>
              <Col span={colSpan}>
                <Form.Item
                  name={[...formNameDataPrefix, "realty", "insuranceAmount"]}
                  label={t("contract.attrs.insurances.insuranceData.realty.insuranceAmount")}
                  rules={[validations.notNull, validations.minNumber(1)]}>
                  <InputNumberWithAddon addonType="euro" formatStyle="integer" min={1} />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name={[...formNameDataPrefix, "realty", "liabilityInsuranceAmount"]}
                  label={t("contract.attrs.insurances.insuranceData.realty.liabilityInsuranceAmount")}
                  rules={[validations.notNull, validations.minNumber(1)]}>
                  <InputNumberWithAddon addonType="euro" formatStyle="integer" min={1} />
                </Form.Item>
              </Col>
            </>
          )}
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.buildingState !== get(next, formNameDataPrefix)?.buildingState}>
            {({ getFieldValue }) => {
              const state = getFieldValue([...formNameDataPrefix, "buildingState"]) as BuildingState;
              return (
                <Form.Item
                  name={[...formNameDataPrefix, "householdEnabled"]}
                  valuePropName="checked"
                  dependencies={[[...formNameDataPrefix, "realtyEnabled"]]}
                  rules={[state === BuildingState.FINISHED
                    ? validations.notFalseIfOtherFalse(
                      form, [...formNameDataPrefix, "realtyEnabled"],
                      t("contract.attrs.insurances.insuranceData.realty._label"))
                    : validations.none
                  ]}
                  initialValue={false}
                  className="form-item-without-label">
                  <Checkbox disabled={state !== BuildingState.FINISHED}>
                    <LabelWithTooltip label={t("contract.attrs.insurances.insuranceData.household._label")}
                                      tooltip={t("contract.helpers.householdDisabled")} />
                  </Checkbox>
                </Form.Item>
              )
            }}
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.householdEnabled !== get(next, formNameDataPrefix)?.householdEnabled}>
          {({ getFieldValue }) => getFieldValue([...formNameDataPrefix, "householdEnabled"]) && (
            <>
              <Col span={colSpan}>
                <Form.Item
                  name={[...formNameDataPrefix, "household", "insuranceAmount"]}
                  label={t("contract.attrs.insurances.insuranceData.household.insuranceAmount")}
                  rules={[validations.notNull, validations.minNumber(1)]}>
                  <InputNumberWithAddon addonType="euro" formatStyle="integer" min={1} />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name={[...formNameDataPrefix, "household", "liabilityInsuranceAmount"]}
                  label={t("contract.attrs.insurances.insuranceData.household.liabilityInsuranceAmount")}
                  rules={[validations.notNull, validations.minNumber(1)]}>
                  <InputNumberWithAddon addonType="euro" formatStyle="integer" min={1} />
                </Form.Item>
              </Col>

              <Col span={colSpan}>
                <Form.Item
                  name={[...formNameDataPrefix, "household", "structuralComponentsReinsurance"]}
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                  className="form-item-without-label">
                  <Checkbox>{t("contract.attrs.insurances.insuranceData.household.structuralComponentsReinsurance")}</Checkbox>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.Item>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}><b>{t("contract.attrs.insurances.insuranceData.reinsurances._label")}</b></Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "civilLiability"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}>
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.civilLiability")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "cyberSecurity"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}>
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.cyberSecurity")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "earthquake"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}>
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.earthquake")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "reinsurances", "glass"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}>
            <Checkbox>{t("contract.attrs.insurances.insuranceData.reinsurances.glass")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpanBig}>
          <Form.Item
            name={[...formNamePrefix, "insuredClientIdentifier"]}
            label={t("contract.attrs.insurances.insuredClientIdentifier")}
            rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={clients.filter(c => !!c).map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
          </Form.Item>
        </Col>

        <Col span={colSpanBig}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => get(prev, formNameDataPrefix)?.vinculation !== get(next, formNameDataPrefix)?.vinculation}>
            {({ getFieldValue }) => getFieldValue([...formNameDataPrefix, "vinculation"]) && (
              <Form.Item
                name={[...formNamePrefix, "vinculationClientIdentifier"]}
                label={<LabelWithTooltip label={t("contract.attrs.insurances.vinculationClientIdentifier")}
                                         tooltip={t("contract.helpers.vinculationClient")} />}
                rules={[validations.notNull]}>
                <Select
                  {...selectStandardProps}
                  options={clients
                    .filter((c, i) => c?.type === ClientType.LEGAL && i > 0)
                    .map(c => ({ value: c.identifier, label: formatClientName(c) }))} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default RealtyInsuranceFormPart;
