import React from "react";
import { Button } from "antd";
import { CalculatorOutlined, DoubleLeftOutlined, ReloadOutlined } from "@ant-design/icons";
import t from "../../../../../../../app/i18n";

export interface Props {
  hasResults: boolean;
  onFormSubmit(): void;
  onShowResultsClick(): void;
  onResetCalculatorClick(): void;
}

const VehicleCalcNavigation = (props: Props) => (
  <div className="bottom-fixed-navigation">

    <div className="navigation-center">
      <Button icon={<CalculatorOutlined />} type="primary" onClick={props.onFormSubmit}>{t("common.calculate")}</Button>
    </div>

    <div className="navigation-right">
      <Button className="blue-button" icon={<DoubleLeftOutlined />} onClick={props.onShowResultsClick}
              disabled={!props.hasResults}>
        {t("common.showResults")}
      </Button>
    </div>

    <div className="navigation-left">
      <Button icon={<ReloadOutlined />} onClick={props.onResetCalculatorClick}>{t("common.resetCalculator")}</Button>
    </div>
  </div>
);

export default VehicleCalcNavigation;
