import React, { useEffect } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DoubleLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { CommissionsFilterPageRequest, CommissionsFilterPageResult } from "../../types";
import { PostponementReason, postponementReasonTMap } from "../../enums";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { selectTagsStandardProps } from "../../../../../common/utils/formUtils";
import { formatLocaleCurrencyWithNullAsZero } from "../../../../../common/utils/formatUtils";
import { rowGutter } from "../../../../../common/constants";
import t from "../../../../../app/i18n";

import PostponementReasonTag from "../PostponementReasonTag";

export interface Props {
  filter: CommissionsFilterPageResult;
  onFilterSubmit(filter: CommissionsFilterPageRequest);
  onBackToBatchClick(): void;
}

const ImportedCommissionFilterHeaderView = ({ filter, onFilterSubmit, onBackToBatchClick }: Props) => {

  const [form] = Form.useForm<CommissionsFilterPageRequest>();

  const colSpan = 5;
  const colSpanBig = 9;

  useEffect(() => {
    form.setFieldsValue({
      keyword: filter.keyword,
      onlyPostponed: filter.onlyPostponed,
      postponementReasons: filter.postponementReasons || []
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnlyPostponedChange = (event: CheckboxChangeEvent): void => {
    if ( !event.target.checked ) {
      form.setFieldsValue({ postponementReasons: [] });
    }
  };

  return (
    <Card
      title={t("commissions.batch.filter.title")}
      size="small"
      className="card-filter"
      style={{ maxWidth: "1100px" }}
      extra={
        <Button size="small" icon={<DoubleLeftOutlined />} className="blue-button" onClick={onBackToBatchClick}>
          {t("commissions.batch.actions.backToBatch")}
        </Button>
      }>

      <Form form={form} layout="vertical" onFinish={onFilterSubmit} name="commissionsAttachmentFilterForm">
        <Row gutter={rowGutter}>

          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(validationConstants.SEARCH_KEYWORD_MIN_LENGTH, validationConstants.SEARCH_KEYWORD_MAX_LENGTH)
              ]}>
              <Input allowClear />
            </Form.Item>
          </Col>

          <Col span={colSpan} className="center-align">
            <Form.Item
              name="onlyPostponed"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}>
              <Checkbox onChange={handleOnlyPostponedChange}>{t("commissions.batch.filter.onlyPostponed")}</Checkbox>
            </Form.Item>
          </Col>

          <Col span={colSpanBig}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.onlyPostponed !== next.onlyPostponed}>
              {({ getFieldValue }) => (
                <Form.Item
                  name="postponementReasons"
                  label={t("commissions.batch.enums.postponementReason._label")}
                  rules={[validations.none]}>
                  <Select
                    {...selectTagsStandardProps(postponementReasonTMap)}
                    allowClear
                    mode="multiple"
                    disabled={!getFieldValue("onlyPostponed")}
                    tagRender={props => <PostponementReasonTag reason={PostponementReason[props.value as string]}
                                                               closable={props.closable} onClose={props.onClose} />}
                    options={Object.keys(PostponementReason)
                      .filter(reason => reason !== PostponementReason.BATCH_DELETED)
                      .map(reason => ({
                        value: reason,
                        label: <PostponementReasonTag reason={PostponementReason[reason]} />
                      }))} />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col span={colSpan} className="center-align">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>{t("common.filter")}</Button>
            </Form.Item>
          </Col>

        </Row>
      </Form>

      <div className="margin-bottom-small">
        <b>{t("commissions.batch.helpers.filteredCommissionsSum")}: </b>
        {formatLocaleCurrencyWithNullAsZero(filter.commissionAmountsSum)}
      </div>

    </Card>
  )
};

export default ImportedCommissionFilterHeaderView;
