import { AxiosPromise } from "axios";
import apiService from "../../../common/api/apiService";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import {
  CreateVehicleMapping,
  UpdateVehicleMappingList,
  VehicleAutocompleteRequest,
  VehicleAutocompleteResultDto,
  VehicleBrandMappingFilterPageResultDto,
  VehicleMapping,
  VehicleMappingData,
  VehicleMappingFilterPageRequest,
  VehicleMappingTypesRequest,
  VehicleModelMappingFilterPageResultDto,
  VehiclePriceRequest
} from "./types";
import { EntityObjectRequest } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";

const AUTOCOMPLETE_VEHICLES = ApiRequestAdapter.create("/vehicles/autocomplete", "GET");
const GET_VEHICLE_PRICE = ApiRequestAdapter.create("/vehicles/price", "GET");
const GET_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings", "GET");
const REMAP_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/remap", "POST");
const MAP_VEHICLE_MAPPINGS_UPDATES = ApiRequestAdapter.create("/vehicles/mappings/map-updates", "POST");
const UNMAP_OBSOLETE_VEHICLE_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/unmap-obsolete", "POST");
const FILTER_VEHICLE_BRANDS = ApiRequestAdapter.create("/vehicles/mappings/brands", "GET");
const CREATE_VEHICLE_BRAND_MAPPING = ApiRequestAdapter.create("/vehicles/mappings/brands", "POST");
const UPDATE_VEHICLE_BRAND_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/brands", "PUT");
const FILTER_VEHICLE_MODELS = ApiRequestAdapter.create("/vehicles/mappings/brands/{0}/models", "GET");
const CREATE_VEHICLE_MODEL_MAPPING = ApiRequestAdapter.create("/vehicles/mappings/brands/{0}/models", "POST");
const UPDATE_VEHICLE_MODEL_MAPPINGS = ApiRequestAdapter.create("/vehicles/mappings/brands/{0}/models", "PUT");

export const requests = {
  AUTOCOMPLETE_VEHICLES,
  GET_VEHICLE_PRICE,
  GET_VEHICLE_MAPPINGS,
  REMAP_VEHICLE_MAPPINGS,
  MAP_VEHICLE_MAPPINGS_UPDATES,
  UNMAP_OBSOLETE_VEHICLE_MAPPINGS,
  FILTER_VEHICLE_BRANDS,
  CREATE_VEHICLE_BRAND_MAPPING,
  UPDATE_VEHICLE_BRAND_MAPPINGS,
  FILTER_VEHICLE_MODELS,
  CREATE_VEHICLE_MODEL_MAPPING,
  UPDATE_VEHICLE_MODEL_MAPPINGS
};

export default {
  autocompleteVehicles: (request: VehicleAutocompleteRequest): AxiosPromise<VehicleAutocompleteResultDto> => {
    return apiService.get<VehicleAutocompleteResultDto>(AUTOCOMPLETE_VEHICLES.url, { params: request });
  },
  getVehiclePrice: (request: VehiclePriceRequest): AxiosPromise<number> => {
    return apiService.get<number>(GET_VEHICLE_PRICE.url, { params: request });
  },
  getVehicleMappings: (request: VehicleMappingTypesRequest): AxiosPromise<VehicleMappingData[]> => {
    return apiService.get<VehicleMappingData[]>(GET_VEHICLE_MAPPINGS.url, {
      params: request,
      paramsSerializer: serializeParams
    });
  },
  remapVehicleMappings: (request: VehicleMappingTypesRequest): AxiosPromise<void> => {
    return apiService.post<void>(REMAP_VEHICLE_MAPPINGS.url, null, {
      params: request,
      paramsSerializer: serializeParams
    });
  },
  mapVehicleMappingsUpdates: (request: VehicleMappingTypesRequest): AxiosPromise<void> => {
    return apiService.post<void>(MAP_VEHICLE_MAPPINGS_UPDATES.url, null, {
      params: request,
      paramsSerializer: serializeParams
    });
  },
  unmapObsoleteVehicleMappings: (request: VehicleMappingTypesRequest): AxiosPromise<void> => {
    return apiService.post<void>(UNMAP_OBSOLETE_VEHICLE_MAPPINGS.url, null, {
      params: request,
      paramsSerializer: serializeParams
    });
  },
  filterVehicleBrands: (request: VehicleMappingFilterPageRequest): AxiosPromise<VehicleBrandMappingFilterPageResultDto> => {
    return apiService.get<VehicleBrandMappingFilterPageResultDto>(FILTER_VEHICLE_BRANDS.url, {
      params: request,
      paramsSerializer: serializeParams
    });
  },
  createVehicleBrandMapping: (request: CreateVehicleMapping): AxiosPromise<VehicleMapping> => {
    return apiService.post<VehicleMapping>(CREATE_VEHICLE_BRAND_MAPPING.url, request);
  },
  updateVehicleBrandMappings: (request: UpdateVehicleMappingList): AxiosPromise<VehicleMapping[]> => {
    return apiService.put<VehicleMapping[]>(UPDATE_VEHICLE_BRAND_MAPPINGS.url, request);
  },
  filterVehicleModels: (request: EntityObjectRequest<VehicleMappingFilterPageRequest>): AxiosPromise<VehicleModelMappingFilterPageResultDto> => {
    return apiService.get<VehicleModelMappingFilterPageResultDto>(FILTER_VEHICLE_MODELS.getParametrizedUrl(request.id), {
      params: request.object,
      paramsSerializer: serializeParams
    });
  },
  createVehicleModelMapping: (request: EntityObjectRequest<CreateVehicleMapping>): AxiosPromise<VehicleMapping> => {
    return apiService.post<VehicleMapping>(CREATE_VEHICLE_MODEL_MAPPING.getParametrizedUrl(request.id), request.object);
  },
  updateVehicleModelMappings: (request: EntityObjectRequest<UpdateVehicleMappingList>): AxiosPromise<VehicleMapping[]> => {
    return apiService.put<VehicleMapping[]>(UPDATE_VEHICLE_MODEL_MAPPINGS.getParametrizedUrl(request.id), request.object);
  }
}
