import React from "react";
import {
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined
} from "@ant-design/icons";
import { ContentType } from "../../constants";

export interface Props {
  contentType: string;
  size?: "small" | "default" | "large" | "huge";
}

const FileTypeIcon = ({ contentType, size }: Props) => {

  const className = "file-type-icon " + (size || "");

  let icon;
  switch ( contentType ) {
    case ContentType.RAR:
    case ContentType.ZIP:
      icon = <FileZipOutlined className={className} />;
      break;
    case ContentType.PDF:
      icon = <FilePdfOutlined className={className} />;
      break;
    case ContentType.DOCX:
      icon = <FileWordOutlined className={className} />;
      break;
    case ContentType.XLSX:
      icon = <FileExcelOutlined className={className} />;
      break;
    case ContentType.PPTX:
      icon = <FilePptOutlined className={className} />;
      break;
    default:
      if ( !contentType ) {
        icon = <FileUnknownOutlined className={className} />;
      }
      else if ( contentType.startsWith("image") || contentType.startsWith("video") ) {
        icon = <FileImageOutlined className={className} />;
      }
      else if ( contentType.startsWith("text") ) {
        icon = <FileTextOutlined className={className} />;
      }
      else {
        icon = <FileOutlined className={className} />;
      }
      break;
  }

  return icon;
};

export default FileTypeIcon;
