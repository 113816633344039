import React from "react";
import { Card } from "antd";
import { InsuranceContract } from "../../../../../types";
import {
  formatLocaleCurrency,
  formatLocaleDate,
  formatPersonName
} from "../../../../../../../common/utils/formatUtils";
import t from "../../../../../../../app/i18n";

import HtmlView from "../../../../../../../common/components/views/HtmlView";

export interface Props {
  contract: InsuranceContract;
}

const InsuranceContractDataSection = ({ contract }: Props) => (
  <Card size="small" type="inner" className="card-box margin-top-medium" title={t("contract.sections.contractData")}>
    <table className="data-table-view">
      <tbody>
      <tr>
        <td>{t("contract.attrs.signer")}:</td>
        <td>{formatPersonName(contract.signer)}</td>
        <td>
          {t("contract.attrs.gainer1")}:
          {contract.gainer2 ? <><br />{t("contract.attrs.gainer2")}:</> : null}
        </td>
        <td>
          {formatPersonName(contract.gainer1)}
          {contract.gainer2 ? <><br />{formatPersonName(contract.gainer2)}</> : null}
        </td>
        <td>{t("contract.attrs.manager")}:</td>
        <td>{formatPersonName(contract.manager)}</td>
      </tr>
      <tr>
        <td colSpan={6} />
      </tr>
      <tr>
        <td>{t("contract.attrs.effectiveBeginningDate")}:</td>
        <td>{formatLocaleDate(contract.effectiveBeginningDate)}</td>
        <td>{t("contract.enums.paymentFrequency._label")}:</td>
        <td className="right-align">{t("contract.enums.paymentFrequency." + contract.paymentFrequency)}</td>
        <td>{t("contract.enums.insurancePeriod._label")}:</td>
        <td>{t("contract.enums.insurancePeriod." + contract.insurancePeriod)}</td>
      </tr>
      <tr>
        <td>{t("contract.attrs.effectiveEndDate")}:</td>
        <td>{formatLocaleDate(contract.effectiveEndDate)}</td>
        <td>{t("contract.attrs.annualPremium")}:</td>
        <td className="right-align">{formatLocaleCurrency(contract.annualPremium)}</td>
        <td>{t("contract.enums.periodType._label")}:</td>
        <td>{t("contract.enums.periodType." + contract.periodType)}</td>
      </tr>
      <tr>
        <td>{t("contract.attrs.cancellationDate")}:</td>
        <td>{formatLocaleDate(contract.cancellationDate)}</td>
        <td>{t("contract.attrs.partialPremium")}:</td>
        <td className="right-align">{formatLocaleCurrency(contract.partialPremium)}</td>
        <td>{t("contract.attrs.transferredToOtherBrokerDate")}:</td>
        <td>{formatLocaleDate(contract.transferredToOtherBrokerDate)}</td>
      </tr>
      <tr>
        <td>{t("contract.attrs.insurancePeriodEndDate")}:</td>
        <td>{formatLocaleDate(contract.insurancePeriodEndDate)}</td>
        <td>{t("contract.attrs.amountOwed")}:</td>
        <td className="highlighted-text right-align">{formatLocaleCurrency(contract.amountOwed)}</td>
      </tr>
      <tr>
        <td>{t("contract.attrs.lastContractCancellationDate")}:</td>
        <td>{formatLocaleDate(contract.lastContractCancellationDate)}</td>
        <td>{t("contract.attrs.paidUntilDate")}:</td>
        <td className="highlighted-text right-align">{formatLocaleDate(contract.paidUntilDate)}</td>
        <td>{t("contract.enums.reactionStatus._label")}:</td>
        <td>{t("contract.enums.reactionStatus." + contract.reactionStatus)}</td>
      </tr>
      <tr>
        <td colSpan={6} />
      </tr>
      <tr>
        <td colSpan={6}>{t("contract.attrs.note")}:</td>
      </tr>
      <tr>
        <td colSpan={6}><HtmlView value={contract.note} /></td>
      </tr>
      </tbody>
    </table>
  </Card>
)

export default React.memo(InsuranceContractDataSection);
