import React from "react";
import { Alert, Popover } from "antd";
import { CalcResult } from "../../../types";
import { RealtyCalcResultData } from "../../types";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { HttpStatus } from "../../../../../../common/constants";
import { formatLocaleCurrency, formatLocaleCurrencyWithNullAsZero } from "../../../../../../common/utils/formatUtils";
import t from "../../../../../../app/i18n";

import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";

export interface Props {
  result: CalcResult<RealtyCalcResultData>;
  selected: boolean;
  onGenerateContractClick(result: CalcResult<RealtyCalcResultData>): void;
  onShowErrorsClick?(result: CalcResult<RealtyCalcResultData>): void;
}

const RealtyCalcResultItem = ({ result, selected, ...props }: Props) => {

  const resultPricesTable = result.data ? (
    <table className="prices-table-view">
      <tbody>
      <tr>
        <td>{t("calc.realty.results.annualPremium")}:</td>
        <td>{formatLocaleCurrency(result.data.annualPremium)}</td>
      </tr>
      {result.data.realtyInsuranceAmount && (
        <tr>
          <td>{t("calc.realty.results.realtyInsuranceAmount")}:</td>
          <td>{formatLocaleCurrency(result.data.realtyInsuranceAmount)}</td>
        </tr>
      )}
      {result.data.householdInsuranceAmount && (
        <tr>
          <td>{t("calc.realty.results.householdInsuranceAmount")}:</td>
          <td>{formatLocaleCurrency(result.data.householdInsuranceAmount)}</td>
        </tr>
      )}
      </tbody>
    </table>
  ) : null;

  let resultAlert = null;
  if ( result.data && result.data.warnings && result.data.warnings.length > 0 ) {
    resultAlert =
      <Alert type="warning" showIcon message={<MultiLineText showDivider tokens={result.data.warnings} />} />
  }
  else if ( result.error ) {
    if ( result.error.status === HttpStatus.UNPROCESSABLE_ENTITY ) {
      resultAlert = <Alert type="error" showIcon message={result.error.message}
                           description={<FieldViolationsView violations={result.error.violations}
                                                             rootPath="calc.realty.attrs" />} />
    }
    else {
      resultAlert = <Alert type="error" showIcon message={result.error.message}
                           description={<MultiLineText tokens={result.error.errors} />} />
    }
  }

  let resultAction = null;
  if ( result.data ) {
    resultAction = <ComponentWithPermission permissions={[Permission.REALTY_CALCULATOR_GENERATE_CONTRACT]}>
      <ActionTextIcon type="check" color="green" size="large"
                      disabled={selected || result.data.allowGenerateContract === false}
                      text={t("calc.realty.results.createContract")}
                      onClick={() => props.onGenerateContractClick(result)} />
    </ComponentWithPermission>
  }
  else if ( props.onShowErrorsClick && result.error && result.error.status === HttpStatus.UNPROCESSABLE_ENTITY ) {
    resultAction = <ActionTextIcon type="eye" color="red" size="large"
                                   text={t("calc.realty.results.showErrors")}
                                   onClick={() => props.onShowErrorsClick(result)} />
  }

  const resultContent = (
    <div>
      {result.coverage && <h4>{result.coverage}</h4>}
      {resultPricesTable}
      {resultAlert}
      {resultAction && <div className="margin-top-tiny">{resultAction}</div>}
    </div>
  );

  return result.error ? resultContent : (
    <Popover
      placement="left"
      title={t("calc.realty.results.additionalInfo")}
      content={
        <div className="result-additional-info">
          <table className="prices-table-view">
            <thead>
            <tr>
              <th />
              <th>{t("calc.realty.results.annualPremium")}</th>
              <th>{t("calc.realty.results.insuranceAmount")}</th>
            </tr>
            </thead>
            <tbody>
            {(result.data.realtyAnnualPremium || result.data.realtyInsuranceAmount) && (
              <tr>
                <td>{t("calc.realty.sections.realtyInsurance")}</td>
                <td>{formatLocaleCurrency(result.data.realtyAnnualPremium)}</td>
                <td>{formatLocaleCurrency(result.data.realtyInsuranceAmount)}</td>
              </tr>
            )}
            {(result.data.householdAnnualPremium || result.data.householdInsuranceAmount) && (
              <tr>
                <td>{t("calc.realty.sections.householdInsurance")}</td>
                <td>{formatLocaleCurrency(result.data.householdAnnualPremium)}</td>
                <td>{formatLocaleCurrency(result.data.householdInsuranceAmount)}</td>
              </tr>
            )}
            {(result.data.realtyLiabilityAnnualPremium || result.data.realtyLiabilityInsuranceAmount) && (
              <tr>
                <td>{t("calc.realty.results.realtyLiability")}</td>
                <td>{formatLocaleCurrency(result.data.realtyLiabilityAnnualPremium)}</td>
                <td>{formatLocaleCurrency(result.data.realtyLiabilityInsuranceAmount)}</td>
              </tr>
            )}
            {(result.data.householdLiabilityAnnualPremium || result.data.householdLiabilityInsuranceAmount) && (
              <tr>
                <td>{t("calc.realty.results.householdLiability")}</td>
                <td>{formatLocaleCurrency(result.data.householdLiabilityAnnualPremium)}</td>
                <td>{formatLocaleCurrency(result.data.householdLiabilityInsuranceAmount)}</td>
              </tr>
            )}
            {(result.data.glassAnnualPremium || result.data.glassInsuranceAmount) && (
              <tr>
                <td>{t("calc.realty.results.glass")}</td>
                <td>{formatLocaleCurrency(result.data.glassAnnualPremium)}</td>
                <td>{formatLocaleCurrency(result.data.glassInsuranceAmount)}</td>
              </tr>
            )}
            {(result.data.structuralComponentsReinsuranceAnnualPremium || result.data.structuralComponentsReinsuranceInsuranceAmount) && (
              <tr>
                <td>{t("calc.realty.results.structuralComponentsReinsurance")}</td>
                <td>{formatLocaleCurrency(result.data.structuralComponentsReinsuranceAnnualPremium)}</td>
                <td>{formatLocaleCurrency(result.data.structuralComponentsReinsuranceInsuranceAmount)}</td>
              </tr>
            )}
            {(result.data.civilLiabilityReinsuranceAnnualPremium || result.data.civilLiabilityReinsuranceInsuranceAmount) && (
              <tr>
                <td>{t("calc.realty.results.civilLiabilityReinsurance")}</td>
                <td>{formatLocaleCurrency(result.data.civilLiabilityReinsuranceAnnualPremium)}</td>
                <td>{formatLocaleCurrency(result.data.civilLiabilityReinsuranceInsuranceAmount)}</td>
              </tr>
            )}
            {result.data.earthquakeReinsuranceAnnualPremium && (
              <tr>
                <td>{t("calc.realty.results.earthquakeReinsurance")}</td>
                <td>{formatLocaleCurrency(result.data.earthquakeReinsuranceAnnualPremium)}</td>
                <td>-</td>
              </tr>
            )}
            {result.data.cyberneticReinsuranceAnnualPremium && (
              <tr>
                <td>{t("calc.realty.results.cyberneticReinsurance")}</td>
                <td>{formatLocaleCurrency(result.data.cyberneticReinsuranceAnnualPremium)}</td>
                <td>-</td>
              </tr>
            )}
            </tbody>
          </table>

          <table className="prices-table-view margin-top-small">
            <thead>
            <tr>
              <th />
              <th>{t("calc.realty.sections.realtyInsurance")}</th>
              <th>{t("calc.realty.sections.householdInsurance")}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{t("calc.realty.results.participation")}</td>
              <td>{formatLocaleCurrencyWithNullAsZero(result.data.realtyParticipation)}</td>
              <td>{formatLocaleCurrencyWithNullAsZero(result.data.householdParticipation)}</td>
            </tr>
            </tbody>
          </table>
        </div>
      }>
      {resultContent}
    </Popover>
  );
};

export default RealtyCalcResultItem;
