export const OrganizationPathDelimiter = "|";

export const rowGutter = 16;

export const HttpStatus = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  MULTI_STATUS: 207,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  UNSUPPORTED_MEDIA_TYPE: 415,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500
};

export const ContentType = {
  RAR: "application/octet-stream",
  ZIP: "application/x-zip-compressed",
  PDF: "application/pdf",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
};

export const LicensePlateRegionPrefixes = [
  "BB", "BC", "BK",         // Banská Bystrica
  "BS",                     // Banská Štiavnica
  "BJ",                     // Bardejov
  "BN",                     // Bánovce nad Bebravou
  "BR",                     // Brezno
  "BA", "BD", "BE", "BI", "BL", "BT",   // Bratislava
  "BY",                     // Bytča
  "CA",                     // Čadca
  "DT",                     // Detva
  "DK",                     // Dolný Kubín
  "DS",                     // Dunajská Streda
  "GA",                     // Galanta
  "GL",                     // Gelnica
  "HC",                     // Hlohovec
  "HE",                     // Humenné
  "IL",                     // Ilava
  "KK",                     // Kežmarok
  "KN",                     // Komárno
  "KE", "KC", "KI",         // Košice
  "KS",                     // Košice-okolie
  "KA",                     // Krupina
  "KM",                     // Kysucké Nové Mesto
  "LV",                     // Levice
  "LE",                     // Levoča
  "LM",                     // Liptovský Mikuláš
  "LC",                     // Lučenec
  "MA",                     // Malacky
  "MT",                     // Martin
  "ML",                     // Medzilaborce
  "MI",                     // Michalovce
  "MY",                     // Myjava
  "NO",                     // Námestovo
  "NR", "NI", "NT",         // Nitra
  "NM",                     // Nové Mesto nad Váhom
  "NZ",                     // Nové Zámky
  "PE",                     // Partizánske
  "PK",                     // Pezinok
  "PN",                     // Piešťany
  "PT",                     // Poltár
  "PP",                     // Poprad
  "PB",                     // Považská Bystrica
  "PO", "PV", "PS",         // Prešov
  "PD",                     // Prievidza
  "PU",                     // Púchov
  "RA",                     // Revúca
  "RS",                     // Rimavská Sobota
  "RV",                     // Rožňava
  "RK",                     // Ružomberok
  "SB",                     // Sabinov
  "SC",                     // Senec
  "SE",                     // Senica
  "SI",                     // Skalica
  "SV",                     // Snina
  "SO",                     // Sobrance
  "SN",                     // Spišská Nová Ves
  "SL",                     // Stará Ľubovňa
  "SP",                     // Stropkov
  "SK",                     // Svidník
  "SA",                     // Šaľa
  "TO",                     // Topoľčany
  "TV",                     // Trebišov
  "TN", "TC", "TE",         // Trenčín
  "TT", "TA", "TB",         // Trnava
  "TR",                     // Turčianske Teplice
  "TS",                     // Tvrdošín
  "VK",                     // Veľký Krtíš
  "VT",                     // Vranov nad Topľou
  "ZM",                     // Zlaté Moravce
  "ZV",                     // Zvolen
  "ZC",                     // Žarnovica
  "ZH",                     // Žiar nad Hronom
  "ZA", "ZI", "ZL"          // Žilina
];

export const academicDegrees = [
  "Bc.", "Mgr.", "Ing.", "JUDr.", "MVDr.", "MUDr.", "PaedDr.", "PhDr.", "PhMr.", "RNDr.", "ThDr.", "MBA", "Ing. arch.",
  "RSDr.", "akad. arch.", "akad. mal.", "akad. soch.", "arch.", "PharmDr.", "ThLic.", "prom. biol.", "prom. logop.",
  "prom. ek.", "prom. filol.", "prom. fil.", "prom. fyz.", "prom. geol.", "prom. hist.", "prom. mat.", "prom. nov.",
  "prom. ped.", "prom. psych.", "prom. práv.", "prom. zub.", "prom. pharm.", "prom. chem.", "prom. vet.", "prom. knih.",
  "Dr.", "Mgr. art.", "MDDr.", "abs. v. š.", "akad.", "BcA.", "dipl.", "dipl. tech.", "doc.", "doc. Dr.", "doc. Dr. Ing.",
  "doc. Ing.", "doc. Ing. arch.", "doc. Ing. PhDr.", "doc. JUDr.", "doc. Mgr.", "doc. Mgr. art.", "doc. MUDr.",
  "doc. MVDr.", "doc. PaedDr.", "doc. PhDr.", "doc. PhDr. Ing.", "doc. PhMr.", "doc. RNDr.", "doc. RSDr.", "doc. ThDr.",
  "doc. ThDr. PaedDr.", "Dr. Ing.", "Dr. JUDr.", "Dr. Mgr.", "Dr. MUDr.", "Dr. PhDr.", "Dr. PhMr.", "Dr. RNDr.",
  "ICDr.", "Ing. akad. arch.", "Ing. arch. Mgr.", "Ing. JUDr.", "Ing. Mgr.", "Ing. MUDr.", "Ing. MVDr.", "Ing. PaedDr.",
  "Ing. PhDr.", "Ing. RNDr.", "Ing. ThDr.", "JUDr. Ing.", "JUDr. Mgr.", "JUDr. MUDr.", "JUDr. MVDr.", "JUDr. PaedDr.",
  "JUDr. PhDr.", "JUDr. RNDr.", "MgA.", "Mgr. Ing.", "Mgr. JUDr.", "Mgr. PaedDr.", "Mgr. PhDr.", "Mgr. RSDr.",
  "MUDr. Ing.", "MUDr. MVDr.", "MUDr. PhDr.", "MVDr. Ing.", "MVDr. MUDr.", "MVDr. PhDr.", "PaedDr. Ing.", "PaedDr. JUDr.",
  "PaedDr. Mgr.", "PaedDr. Mgr. art.", "PaedDr. MUDr.", "PaedDr. PhDr.", "PharmDr. RNDr.", "PhDr. Ing.", "PhDr. JUDr.",
  "PhDr. Mgr.", "PhDr. MUDr.", "PhDr. PaedDr.", "PhDr. RNDr.", "PhMr. Ing.", "PhMr. Mgr.", "PhMr. MVDr.", "prof.",
  "prof. doc.", "prof. doc. Ing.", "prof. doc. Mgr.", "prof. Dr.", "prof. Dr. Ing.", "prof. Ing.", "prof. Ing. PhDr.",
  "prof. Ing. RNDr.", "prof. JUDr.", "prof. Mgr.", "prof. MUDr.", "prof. MVDr.", "prof. MVDr. JUDr.", "prof. PaedDr.",
  "prof. PhDr.", "prof. RNDr.", "prof. RNDr. PhDr.", "prof. ThDr.", "prom. d.", "prom. geog.", "RCDr.", "RNDr. Ing.",
  "RNDr. Mgr.", "RNDr. PhDr.", "RNDr. PhMr.", "RSDr. Ing.", "RSDr. Mgr.", "ThDr. ThLic.", "ThMgr.", "doc. akad. mal.",
  "JCDr.", "MUDr. Mgr.", "Bc. Mgr."
];

export const academicDegreesAfter = [
  "CSc.", "PhD.", "ArtD.", "DrSc.", "DiS.", "DiS.art", "MBA", "BA", "BArch", "BBA", "BBS", "BSc", "Dr.h.c.",
  "Dr.theol.", "LL.M", "MA", "MSc", "M.Mus.", "M.Phil.", "Th.D.", "PhD. CSc.", "MPH", "PhD. MPH.", "FCCA"
];

export const commercialRegisterSections = ["Pš", "Sa", "Sro", "Sr", "Firm", "Dr", "Po", "Pšn"];

export const commercialRegisterDistrictOffices = [
  "Banská Bystrica", "Bratislava I", "Košice I", "Nitra", "Prešov", "Trenčín", "Trnava", "Žilina"
];

// https://www.vlada.gov.sk//statne-sviatky/
export const slovakHolidays = [
  { d: 1, m: 1 },     // 1. 1.   - Deň vzniku Slovenskej republiky
  { d: 6, m: 1 },     // 6. 1.   - Zjavenie Pána (Traja králi a vianočný sviatok pravoslávnych kresťanov)
  { d: 1, m: 5 },     // 1. 5.   - Sviatok práce
  { d: 8, m: 5 },     // 8. 5.   - Deň víťazstva nad fašizmom
  { d: 5, m: 7 },     // 5. 7.   - Sviatok sv. Cyrila a Metoda
  { d: 29, m: 8 },    // 29. 8.  - Výročie Slovenského národného povstania
  { d: 1, m: 9 },     // 1. 9.   - Deň ústavy SR
  { d: 15, m: 9 },    // 15. 9.  - Sedembolestná Panna Mária
  { d: 1, m: 11 },    // 1. 11.  - Sviatok všetkých svätých
  { d: 17, m: 11 },   // 17. 11. - Deň boja za slobodu a demokraciu
  { d: 24, m: 12 },   // 24. 12. - Štedrý deň
  { d: 25, m: 12 },   // 25. 12. - Prvý sviatok vianočný
  { d: 26, m: 12 }    // 26. 12. - Druhý sviatok vianočný
];
