import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginWrapper from "../../common/security/authentication/LoginWrapper";
import AuthenticatedUserWrapper from "../../common/security/authentication/AuthenticatedUserWrapper";

import LoginContainer from "./containers/LoginContainer";
import NotFound from "../../common/pages/NotFound/NotFound";

const wrappedLoginContainer = LoginWrapper(LoginContainer);
const wrappedNotFoundComponent = AuthenticatedUserWrapper(NotFound);

export default () => (
  <Switch>
    <Route exact path="/auth" component={wrappedLoginContainer} />
    <Route component={wrappedNotFoundComponent} />
  </Switch>
);
