import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Modal } from "antd";
import {
  VehicleCalc,
  VehicleCalcResultData,
  VehicleCalcResults,
  VehicleFormClients,
  VehicleGen,
  VehicleGenFormData,
  VehicleOfferCalcType
} from "../types";
import { CalcDataSource, CalcResult, GenResponse } from "../../types";
import { ValidationErrorResponse } from "../../../../types";
import { ActionProps, RootState, UUID } from "../../../../../common/types";
import { VehicleAutocompleteResult } from "../../../vehicles/types";
import { Client, ClientAutocompleteResult } from "../../../../client/types";
import { VehicleInsurance } from "../../../../contract/types";
import { VehicleInsurerRelation, VehicleOwnerRelation } from "../enums";
import { CalcType } from "../../../enums";
import { ClientFormType } from "../../../../client/enums";
import { Permission } from "../../../../../common/security/authorization/enums";
import {
  calculateVehicleActions,
  deleteStateVehicleCalcResultsAction,
  deleteStateVehicleGenResultAction,
  deleteStateVehicleInitialCalcGenDataAction,
  generateVehicleActions,
  generateVehicleOfferActions,
  selectVehicleCalcResults,
  selectVehicleGenResult,
  selectVehicleInitialCalcData,
  selectVehicleInitialGenData
} from "../ducks";
import {
  autocompleteClientsActions,
  deleteStateClientsAutocompleteResultAction,
  selectClientsAutocompleteResult,
  validateClientActions,
  validateClientsActions
} from "../../../../client/ducks";
import {
  deleteStateValidationErrorResponseAction,
  selectIsRequestInProgress,
  selectValidationErrorResponse
} from "../../../../ducks";
import {
  autocompleteVehiclesActions,
  deleteStateVehicleAutocompleteResultAction,
  deleteStateVehiclePriceResultAction,
  getVehiclePriceActions,
  selectCalcVehicleAutocompleteResult,
  selectCalcVehiclePriceResult
} from "../../../vehicles/ducks";
import {
  selectVehicleOtherBrandsIdsEnumerations,
  selectVehicleOtherModelsIdsEnumerations
} from "../../../../enumerations/ducks";
import { downloadCardReaderActions } from "../../ducks";
import { selectHasPermissions } from "../../../../auth/ducks";
import { requests as calcRequests } from "../../api";
import { requests as clientRequests } from "../../../../client/api";
import { requests as vehicleRequests } from "../../../vehicles/api";
import {
  createVehicleCalcObjectFromCalcData,
  createVehicleCalcObjectFromGenData,
  createVehicleFormClientsObjectFromCalcData,
  createVehicleFormClientsObjectFromGenData,
  createVehicleGenFormDataObject
} from "../utils";
import messageUtils from "../../../../../common/utils/messageUtils";
import t from "../../../../../app/i18n";

import VehicleCalcWrapper from "../components/calc/VehicleCalcWrapper";
import VehicleGenWrapper from "../components/gen/VehicleGenWrapper";
import CalcLoadingModal from "../../components/CalcLoadingModal";

interface StateProps {
  initialCalcData: VehicleCalc;
  initialGenData: VehicleGen;
  calcResults: VehicleCalcResults;
  genResult: GenResponse;
  vehicleAutocompleteResult: VehicleAutocompleteResult;
  vehicleAutocompleteInProgress: boolean;
  vehiclePriceResult: number;
  vehiclePriceRequestInProgress: boolean;
  clientsAutocompleteResult: ClientAutocompleteResult;
  clientsAutocompleteInProgress: boolean;
  calcRequestInProgress: boolean;
  genRequestInProgress: boolean;
  otherVehicleBrandsIds: UUID[];
  otherVehicleModelsIds: UUID[];
  vehicleCalcPermissions: Permission[];
  validateClientErrorResponse: ValidationErrorResponse;
  validateClientsErrorResponse: ValidationErrorResponse;
}

interface ActionsMap {
  calculateVehicle: typeof calculateVehicleActions.request;
  generateVehicle: typeof generateVehicleActions.request;
  generateVehicleOffer: typeof generateVehicleOfferActions.request;
  autocompleteVehicles: typeof autocompleteVehiclesActions.request;
  getVehiclePrice: typeof getVehiclePriceActions.request;
  autocompleteClients: typeof autocompleteClientsActions.request;
  validateClient: typeof validateClientActions.request;
  validateClients: typeof validateClientsActions.request;
  downloadCardReader: typeof downloadCardReaderActions.request;
  deleteStateVehicleCalcResults: typeof deleteStateVehicleCalcResultsAction;
  deleteStateVehicleGenResult: typeof deleteStateVehicleGenResultAction;
  deleteStateVehicleInitialCalcGenData: typeof deleteStateVehicleInitialCalcGenDataAction;
  deleteStateVehicleAutocompleteResult: typeof deleteStateVehicleAutocompleteResultAction;
  deleteStateVehiclePriceResult: typeof deleteStateVehiclePriceResultAction;
  deleteStateClientsAutocompleteResult: typeof deleteStateClientsAutocompleteResultAction;
  deleteStateValidationErrorResponse: typeof deleteStateValidationErrorResponseAction;
}

interface State {
  readonly resetKey: number;
  readonly genStepActive: boolean;
  readonly clients: VehicleFormClients;
  readonly calcData: VehicleCalc;
  readonly calcDataSource: CalcDataSource;
  readonly genFormData: VehicleGenFormData;
  readonly selectedResult: CalcResult<VehicleCalcResultData>;
}

class VehicleCalcContainer extends React.Component<StateProps & ActionProps<ActionsMap>, State> {

  constructor(props: StateProps & ActionProps<ActionsMap>) {
    super(props);

    const stateObject = {
      resetKey: 0,
      genStepActive: false,
      clients: { holder: null, insurer: null, owner: null, authorized1: null, authorized2: null },
      calcData: null,
      calcDataSource: "init" as CalcDataSource,
      genFormData: null,
      selectedResult: null
    };

    if ( props.initialCalcData ) {
      stateObject.calcData = createVehicleCalcObjectFromCalcData(props.initialCalcData);
      stateObject.clients = createVehicleFormClientsObjectFromCalcData(props.initialCalcData);
      stateObject.calcDataSource = "calcData";
    }
    else if ( props.initialGenData ) {
      stateObject.clients = createVehicleFormClientsObjectFromGenData(props.initialGenData);
      stateObject.calcData = createVehicleCalcObjectFromGenData(props.initialGenData);
      stateObject.calcDataSource = "genData";
      stateObject.genFormData = createVehicleGenFormDataObject(props.initialGenData, stateObject.clients);
    }

    this.state = stateObject;
  }

  handleClientChange = (client: Client, type: ClientFormType, callback?: () => void): void => {
    switch ( type ) {
      case ClientFormType.HOLDER:
        this.setState(previousState => ({ clients: { ...previousState.clients, holder: client } }), callback);
        break;
      case ClientFormType.INSURER:
        this.setState(previousState => ({ clients: { ...previousState.clients, insurer: client } }), callback);
        break;
      case ClientFormType.OWNER:
        this.setState(previousState => ({ clients: { ...previousState.clients, owner: client } }), callback);
        break;
      case ClientFormType.AUTHORIZED_1:
        this.setState(previousState => ({ clients: { ...previousState.clients, authorized1: client } }), callback);
        break;
      case ClientFormType.AUTHORIZED_2:
        this.setState(previousState => ({ clients: { ...previousState.clients, authorized2: client } }), callback);
        break;
    }
  };

  handleResetCalculatorClick = (): void => {
    Modal.confirm({
      title: t("calc.helpers.resetCalculatorSubmit"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        this.setState(previousState => ({
          resetKey: previousState.resetKey + 1,
          clients: { holder: null, insurer: null, owner: null, authorized1: null, authorized2: null },
          calcData: null,
          calcDataSource: "init" as CalcDataSource,
          genFormData: null,
          selectedResult: null
        }));
        this.props.actions.deleteStateVehicleCalcResults();
        this.props.actions.deleteStateVehicleAutocompleteResult();
        this.props.actions.deleteStateClientsAutocompleteResult();
      }
    });
  };

  handleCalcDataSourceReset = (): void => {
    this.setState({ calcDataSource: "init" });
  };

  handleCalculationFormSubmit = (calcData: VehicleCalc): void => {
    this.setState({ calcData });
    this.props.actions.deleteStateVehicleCalcResults();
    this.props.actions.calculateVehicle(calcData);
  };

  handleGenerateContractClick = (calcData: VehicleCalc, selectedResult: CalcResult<VehicleCalcResultData>): void => {
    this.setState({ genStepActive: true, calcData, selectedResult });
  };

  handleGenerateOfferClick = (type: VehicleOfferCalcType, calcData?: VehicleCalc): void => {
    const { mtpl, crash, gap, pas } = this.props.calcResults;
    this.props.actions.generateVehicleOffer({
      type,
      calcRequest: calcData ? calcData : this.state.calcData,
      calcResponse: {
        results: type === CalcType.MTPL ? mtpl.flat() : [...crash.flat(), ...gap.flat(), ...pas.flat()]
      }
    });
  };

  handleSelectedResultChange = (selectedResult: CalcResult<VehicleCalcResultData>, callback?: () => void): void => {
    this.setState({ selectedResult }, callback);
  };

  handleReturnToCalculationClick = (genFormData: VehicleGenFormData, contractCreated?: boolean): void => {
    if ( contractCreated ) {
      const { contract } = this.props.genResult;
      if ( contract ) {
        const insurance = contract.insurances[0] as VehicleInsurance;
        const { insurerRelation, ownerRelation } = this.state.calcData.clientsData;

        this.setState({
          genStepActive: false,
          calcDataSource: "init",
          genFormData,
          clients: {
            holder: contract.clients[insurance.vehicleHolderIndex],
            insurer: insurerRelation === VehicleInsurerRelation.DIFFERENT_FROM_HOLDER ? contract.clients[contract.insurerIndex] : null,
            owner: ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_HOLDER_AND_INSURER ? contract.clients[insurance.vehicleOwnerIndex] : null,
            authorized1: contract.clients[contract.authorizedClient1Index],
            authorized2: contract.clients[contract.authorizedClient2Index]
          }
        });
      }
      else {
        this.setState(previousState => ({
          genStepActive: false,
          calcDataSource: "init",
          genFormData,
          clients: { holder: null, insurer: null, owner: null, authorized1: null, authorized2: null },
          calcData: {
            ...previousState.calcData,
            clientsData: { ...previousState.calcData.clientsData, clients: [], holderIndex: null }
          }
        }));
      }

      this.props.actions.deleteStateVehicleGenResult();
    }
    else {
      this.setState({ genStepActive: false, calcDataSource: "calcData", genFormData });
    }
  };

  componentWillUnmount(): void {
    this.props.actions.deleteStateVehicleCalcResults();
    this.props.actions.deleteStateVehicleGenResult();
  }

  componentDidMount(): void {
    if ( this.props.initialCalcData || this.props.initialGenData ) {
      this.props.actions.deleteStateVehicleInitialCalcGenData();
      messageUtils.infoNotification(t("calc.records.helpers.calcDataInitTitle"), t("calc.records.helpers.calcDataInit"), 10);
    }
  }

  render(): React.ReactNode {
    return (
      <>
        {this.state.genStepActive ? (
          <VehicleGenWrapper
            initialData={this.state.genFormData}
            genResult={this.props.genResult}
            calcData={this.state.calcData}
            clients={this.state.clients}
            calcResults={this.props.calcResults}
            selectedResult={this.state.selectedResult}
            clientsAutocomplete={{
              result: this.props.clientsAutocompleteResult,
              inProgress: this.props.clientsAutocompleteInProgress,
              onSearch: this.props.actions.autocompleteClients,
              onResultDelete: this.props.actions.deleteStateClientsAutocompleteResult
            }}
            validateClientErrorResponse={this.props.validateClientErrorResponse}
            validateClientsErrorResponse={this.props.validateClientsErrorResponse}
            onGenerateFormSubmit={this.props.actions.generateVehicle}
            onValidateClient={this.props.actions.validateClient}
            onValidateClients={this.props.actions.validateClients}
            onGenResultDelete={this.props.actions.deleteStateVehicleGenResult}
            onErrorResponseDelete={this.props.actions.deleteStateValidationErrorResponse}
            onGenerateOfferClick={this.handleGenerateOfferClick}
            onSelectedResultChange={this.handleSelectedResultChange}
            onReturnToCalculationClick={this.handleReturnToCalculationClick}
            onClientChange={this.handleClientChange} />
        ) : (
          <VehicleCalcWrapper
            key={this.state.resetKey}
            calcData={this.state.calcData}
            calcDataSource={this.state.calcDataSource}
            calcResults={this.props.calcResults}
            holder={this.state.clients.holder}
            vehicleAutocomplete={{
              result: this.props.vehicleAutocompleteResult,
              inProgress: this.props.vehicleAutocompleteInProgress,
              onSearch: this.props.actions.autocompleteVehicles,
              onResultDelete: this.props.actions.deleteStateVehicleAutocompleteResult
            }}
            vehiclePriceRequest={{
              price: this.props.vehiclePriceResult,
              inProgress: this.props.vehiclePriceRequestInProgress,
              onGetPrice: this.props.actions.getVehiclePrice,
              onResultDelete: this.props.actions.deleteStateVehiclePriceResult
            }}
            clientsAutocomplete={{
              result: this.props.clientsAutocompleteResult,
              inProgress: this.props.clientsAutocompleteInProgress,
              onSearch: this.props.actions.autocompleteClients,
              onResultDelete: this.props.actions.deleteStateClientsAutocompleteResult
            }}
            otherVehicleBrandsIds={this.props.otherVehicleBrandsIds}
            otherVehicleModelsIds={this.props.otherVehicleModelsIds}
            vehicleCalcPermissions={this.props.vehicleCalcPermissions}
            validateClientErrorResponse={this.props.validateClientErrorResponse}
            onValidateClient={this.props.actions.validateClient}
            onErrorResponseDelete={this.props.actions.deleteStateValidationErrorResponse}
            onCalcResultsDelete={this.props.actions.deleteStateVehicleCalcResults}
            onCalculationFormSubmit={this.handleCalculationFormSubmit}
            onGenerateContractClick={this.handleGenerateContractClick}
            onGenerateOfferClick={this.handleGenerateOfferClick}
            onClientChange={this.handleClientChange}
            onResetCalculatorClick={this.handleResetCalculatorClick}
            onCalcDataSourceReset={this.handleCalcDataSourceReset}
            onDownloadCardReader={this.props.actions.downloadCardReader} />
        )}

        {this.props.calcRequestInProgress || this.props.genRequestInProgress ? (
          <CalcLoadingModal
            calcRequestInProgress={this.props.calcRequestInProgress}
            genRequestInProgress={this.props.genRequestInProgress} />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  initialCalcData: selectVehicleInitialCalcData(state),
  initialGenData: selectVehicleInitialGenData(state),
  calcResults: selectVehicleCalcResults(state),
  genResult: selectVehicleGenResult(state),
  vehicleAutocompleteResult: selectCalcVehicleAutocompleteResult(state),
  vehicleAutocompleteInProgress: selectIsRequestInProgress(state, vehicleRequests.AUTOCOMPLETE_VEHICLES),
  vehiclePriceResult: selectCalcVehiclePriceResult(state),
  vehiclePriceRequestInProgress: selectIsRequestInProgress(state, vehicleRequests.GET_VEHICLE_PRICE),
  clientsAutocompleteResult: selectClientsAutocompleteResult(state),
  clientsAutocompleteInProgress: selectIsRequestInProgress(state, clientRequests.AUTOCOMPLETE_CLIENTS),
  calcRequestInProgress: selectIsRequestInProgress(state, calcRequests.CALCULATE),
  genRequestInProgress: selectIsRequestInProgress(state, calcRequests.GENERATE),
  otherVehicleBrandsIds: selectVehicleOtherBrandsIdsEnumerations(state),
  otherVehicleModelsIds: selectVehicleOtherModelsIdsEnumerations(state),
  vehicleCalcPermissions: selectHasPermissions(Permission.MTPL_CALCULATOR, Permission.CRASH_CALCULATOR)(state),
  validateClientErrorResponse: selectValidationErrorResponse(state, clientRequests.VALIDATE_CLIENT),
  validateClientsErrorResponse: selectValidationErrorResponse(state, clientRequests.VALIDATE_CLIENTS)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    calculateVehicle: calculateVehicleActions.request,
    generateVehicle: generateVehicleActions.request,
    generateVehicleOffer: generateVehicleOfferActions.request,
    autocompleteVehicles: autocompleteVehiclesActions.request,
    getVehiclePrice: getVehiclePriceActions.request,
    autocompleteClients: autocompleteClientsActions.request,
    validateClient: validateClientActions.request,
    validateClients: validateClientsActions.request,
    downloadCardReader: downloadCardReaderActions.request,
    deleteStateVehicleCalcResults: deleteStateVehicleCalcResultsAction,
    deleteStateVehicleGenResult: deleteStateVehicleGenResultAction,
    deleteStateVehicleInitialCalcGenData: deleteStateVehicleInitialCalcGenDataAction,
    deleteStateVehicleAutocompleteResult: deleteStateVehicleAutocompleteResultAction,
    deleteStateVehiclePriceResult: deleteStateVehiclePriceResultAction,
    deleteStateClientsAutocompleteResult: deleteStateClientsAutocompleteResultAction,
    deleteStateValidationErrorResponse: deleteStateValidationErrorResponseAction
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(VehicleCalcContainer);
