import React from "react";
import { WrappedFormUtils } from "@ant-design/compatible/lib/form/Form";
import { Client } from "../../../types";
import { ClientType } from "../../../enums";
import t from "../../../../../app/i18n";

import AddressFormDeprecated from "../../../../../common/modules/address/AddressForm_Deprecated";

export interface Props {
  form: WrappedFormUtils;
  initialClient?: Client;
  clientType: ClientType;
  correspondenceAddressEnabled: boolean;
  onCorrespondenceAddressEnabledChange(enabled: boolean): void;
}

const ClientAddressesFormPart = (props: Props) => (
  <>
    <AddressFormDeprecated
      form={props.form}
      initialAddress={props.initialClient ? props.initialClient.address : null}
      rootKey="address"
      required
      label={props.clientType === ClientType.LEGAL ? t("client.attrs.addressLongPO") : t("client.attrs.addressLong")} />

    <AddressFormDeprecated
      form={props.form}
      initialAddress={props.initialClient ? props.initialClient.correspondenceAddress : null}
      rootKey="correspondenceAddress"
      switchProps={{
        enabled: props.correspondenceAddressEnabled,
        onEnabledChange: props.onCorrespondenceAddressEnabledChange
      }}
      label={t("client.attrs.correspondenceAddress")} />
  </>
);

export default ClientAddressesFormPart;
