import { AxiosPromise } from "axios";
import apiService from "../../common/api/apiService";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { EntityIdRequest, EntityObjectRequest, SearchPageRequest, SearchPageResult } from "../../common/types";
import { CreateUpdatePerson, Person, PersonList, PersonsTreeRequest, PersonWithSubordinates } from "./types";

const FILTER_PERSONS = ApiRequestAdapter.create("/persons", "GET");
const GET_PERSONS_TREE = ApiRequestAdapter.create("/persons/tree", "GET");
const GET_PERSON = ApiRequestAdapter.create("/persons/{0}", "GET");
const CREATE_PERSON = ApiRequestAdapter.create("/persons", "POST");
const UPDATE_PERSON = ApiRequestAdapter.create("/persons/{0}", "PUT");
const DELETE_PERSON = ApiRequestAdapter.create("/persons/{0}", "DELETE");

export const requests = {
  FILTER_PERSONS,
  GET_PERSONS_TREE,
  GET_PERSON,
  CREATE_PERSON,
  UPDATE_PERSON,
  DELETE_PERSON
};

export default {
  filterPersons: (request: SearchPageRequest): AxiosPromise<SearchPageResult<PersonList>> => {
    return apiService.get<SearchPageResult<PersonList>>(FILTER_PERSONS.url, { params: request });
  },
  getPersonsTree: (request: PersonsTreeRequest): AxiosPromise<PersonWithSubordinates[]> => {
    return apiService.get<PersonWithSubordinates[]>(GET_PERSONS_TREE.url, { params: request });
  },
  getPerson: (request: EntityIdRequest): AxiosPromise<Person> => {
    return apiService.get<Person>(GET_PERSON.getParametrizedUrl(request.id));
  },
  createPerson: (request: CreateUpdatePerson): AxiosPromise<Person> => {
    return apiService.post<Person>(CREATE_PERSON.url, request);
  },
  updatePerson: (request: EntityObjectRequest<CreateUpdatePerson>): AxiosPromise<Person> => {
    return apiService.put<Person>(UPDATE_PERSON.getParametrizedUrl(request.id), request.object);
  },
  deletePerson: (request: EntityIdRequest): AxiosPromise => {
    return apiService.delete(DELETE_PERSON.getParametrizedUrl(request.id));
  }
}
