import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { FilterFunction } from "../../types";
import { RootState } from "../../../../common/types";
import { PersonBase } from "../../../person/types";
import { selectPersonsEnumerations } from "../../ducks";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { formatPersonName } from "../../../../common/utils/formatUtils";

export interface Props {
  formItemProps: FormItemProps;
  selectProps?: SelectProps<string>;
  optionsProps?: PersonOptionsProps;
}

export interface PersonOptionsProps {
  includeDeactivated?: boolean;
  selected?: PersonBase;
  filterOrganizationPath?: string;
  filter?: FilterFunction<PersonBase>;
}

const PersonsEnumFormItemSelect = ({ formItemProps, selectProps, optionsProps }: Props) => {

  const personsEnumerations = useSelector<RootState, PersonBase[]>(selectPersonsEnumerations);

  const resolvePersonSelectOptions = useCallback((): PersonBase[] => {
    let options = [...personsEnumerations];

    if ( optionsProps ) {
      if ( !optionsProps.includeDeactivated ) {
        options = options.filter(person => !person.deactivated);
      }
      if ( optionsProps.filterOrganizationPath ) {
        options = options.filter(person => !person.organizationPath.startsWith(optionsProps.filterOrganizationPath));
      }
      if ( optionsProps.filter ) {
        options = options.filter(optionsProps.filter);
      }
      if ( optionsProps.selected && !options.some(person => person.id === optionsProps.selected.id) ) {
        options.unshift(optionsProps.selected);
      }
    }
    else {
      options = options.filter(person => !person.deactivated);
    }

    return options.sort((a, b) => a.aggregatedName.localeCompare(b.aggregatedName));
  }, [personsEnumerations, optionsProps]);

  return (
    <Form.Item {...formItemProps}>
      <Select
        {...selectStandardProps} {...selectProps}
        options={resolvePersonSelectOptions().map(person => ({
          value: person.id,
          label: formatPersonName(person)
        }))} />
    </Form.Item>
  )
}

export default PersonsEnumFormItemSelect;
