import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps } from "react-router";
import { replace } from "connected-react-router";
import { ActionProps, RootState } from "../../../common/types";
import { ConfirmPersonAccount } from "../types";
import { Role } from "../../../common/security/authorization/enums";
import { selectRouterLocationSearchParam } from "../../ducks";
import { confirmAccountActions } from "../ducks";

import ConfirmPersonAccountForm from "../components/forms/ConfirmAccountForm";

interface StateProps {
  type: Role;
  optimisticLockVersion: number;
  token: string;
}

interface ActionsMap {
  confirmAccount: typeof confirmAccountActions.request;
  replaceNavigation: typeof replace;
}

type Props = StateProps & ActionProps<ActionsMap> & RouteComponentProps;

const ConfirmAccountContainer = (props: Props) => {

  useEffect(() => {
    const { type, optimisticLockVersion, token } = props;
    if ( !type || isNaN(optimisticLockVersion) || !token ) {
      props.actions.replaceNavigation("/");
    }
    else if ( type === Role.CLIENT ) {
      props.actions.confirmAccount({
        id: props.match.params["accountId"],
        object: { type, optimisticLockVersion, token }
      })
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handlePersonAccountFormSubmit = (personAccount: ConfirmPersonAccount): void => {
    props.actions.confirmAccount({ id: props.match.params["accountId"], object: personAccount });
  };

  return props.type === Role.PERSON
    ? <ConfirmPersonAccountForm
      confirmationDataParams={{
        type: props.type,
        optimisticLockVersion: props.optimisticLockVersion,
        token: props.token
      }}
      onFormSubmit={handlePersonAccountFormSubmit} />
    : null;
}

const mapStateToProps = (state: RootState): StateProps => ({
  type: Role[selectRouterLocationSearchParam(state, "type")],
  optimisticLockVersion: parseInt(selectRouterLocationSearchParam(state, "optimisticLockVersion")),
  token: selectRouterLocationSearchParam(state, "token")
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    confirmAccount: confirmAccountActions.request,
    replaceNavigation: replace
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, RouteComponentProps, RootState>(mapStateToProps, mapDispatchToProps)(ConfirmAccountContainer);

