import React from "react";
import { CommissionsBatch } from "../../../../types";

export interface Props {
  batch: CommissionsBatch;
}

const CommissionsBatchReviewStep = ({ batch }: Props) => {
  return <span>STEP REVIEW</span>
};

export default CommissionsBatchReviewStep;
