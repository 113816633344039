import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { routerActions } from "connected-react-router";

import { RootState } from "../../types";
import { selectIsUserAuthenticated } from "../../../modules/auth/ducks";
import { selectIsRequestInProgress } from "../../../modules/ducks";
import requests from "../../../modules/auth/apiRequests";

const locationHelper = locationHelperBuilder({});

/**
 * HOC should be applied to the anonymous login components which support redirect query param.
 * <b>Config params:</b>
 * -> <i>redirectPath</i> - the url to redirect user to if conditions fails
 * -> <i>allowRedirectBack</i> - prevents from adding the query parameter when the user is sent away from the login page
 * -> <i>authenticatedSelector</i> - if selector is true, wrapper will not redirect
 * -> <i>authenticatingSelector</i> - returns true if the user auth state is loading
 * -> <i>redirectAction</i> - redux action creator
 * -> <i>AuthenticatingComponent</i> - rendered component while the authenticatingSelector returns true
 * -> <i>wrapperDisplayName</i> - a descriptive display name for this check
 */
export default connectedRouterRedirect({
  redirectPath: (state: RootState, props: any) => locationHelper.getRedirectQueryParam(props) || '/',
  allowRedirectBack: false,
  authenticatedSelector: (state: RootState) => !selectIsUserAuthenticated(state),
  authenticatingSelector: (state: RootState) => selectIsRequestInProgress(state, requests.LOGIN),
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'LoginWrapper'
});
