import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Button, Result } from "antd";
import { Path, Search } from "history";
import { HomeOutlined } from "@ant-design/icons";
import { ActionProps, RootState } from "../../types";
import { selectRouterLocationPathname, selectRouterLocationSearch } from "../../../modules/ducks";
import { selectIsUserAuthenticated } from "../../../modules/auth/ducks";
import { createFrontendErrorLogActions } from "../../../modules/event/ducks";
import t from "../../../app/i18n";

import history from "../../../app/store/rootHistory";

export interface Props {
  children: React.ReactNode;
}

interface StateProps {
  userAuthenticated: boolean;
  locationPathname: Path;
  locationSearch: Search;
}

interface ActionsMap {
  createFrontendErrorLog: typeof createFrontendErrorLogActions.request;
}

interface State {
  readonly hasError: boolean;
}

class ErrorBoundary extends React.Component<Props & StateProps & ActionProps<ActionsMap>, State> {

  readonly state: State = {
    hasError: false
  }

  private historyListenerUnregisterCallback;

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if ( this.props.userAuthenticated ) {
      this.props.actions.createFrontendErrorLog({
        name: error.name || "Unknown error",
        message: error.message || "Unknown error occurred.",
        stack: error.stack,
        componentStack: errorInfo.componentStack,
        locationPath: this.props.locationPathname + this.props.locationSearch
      });
    }
  }

  componentDidMount() {
    this.historyListenerUnregisterCallback = history.listen(() => {
      if ( this.state.hasError ) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.historyListenerUnregisterCallback();
  }

  render() {
    return this.state.hasError ? (
      <Result
        className="result-page margin-top-large"
        status="500"
        title={t("navigation.error.title")}
        subTitle={t("navigation.error.text")}
        extra={
          <Button onClick={() => window.location.replace("/")}>
            {t("navigation.error.homeAction")}<HomeOutlined />
          </Button>
        } />
    ) : this.props.children;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  userAuthenticated: selectIsUserAuthenticated(state),
  locationPathname: selectRouterLocationPathname(state),
  locationSearch: selectRouterLocationSearch(state)
})

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators({
    createFrontendErrorLog: createFrontendErrorLogActions.request
  }, dispatch)
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
