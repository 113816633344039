import React from "react";
import { Button } from "antd";
import { InteractionOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";

export interface Props {
  type: "add" | "delete" | "manage";
  label: string;
  index?: number;
  disabled?: boolean;
  className?: string;
  onClick?(index?: number): void;
}

const AddDeleteButton = ({ type, label, index, disabled, className, onClick }: Props) => {
  let icon = null;
  switch ( type ) {
    case "add":
      icon = <PlusOutlined />;
      break;
    case "delete":
      icon = <MinusOutlined />;
      break;
    case "manage":
      icon = <InteractionOutlined />;
      break;
  }

  return (
    <Button size="small" type="dashed" disabled={disabled} icon={icon} className={className}
            onClick={() => onClick?.(index)}>
      {label}
    </Button>
  );
};

export default AddDeleteButton;
