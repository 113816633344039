import React from "react";
import { Route, Switch } from "react-router-dom";
import { Permission } from "../../common/security/authorization/enums";
import { regexPatterns } from "../../common/utils/validationUtils";
import RouteWithPermission from "../../common/security/authorization/RouteWithPermission";
import RedirectWithPermission from "../../common/security/authorization/RedirectWithPermission";

import VehicleCalcContainer from "./calcs/vehicle/containers/VehicleCalcContainer";
import TravelCalcContainer from "./calcs/travel/containers/TravelCalcContainer";
import RealtyCalcContainer from "./calcs/realty/containers/RealtyCalcContainer";
import RealtyCalcDraftContainer from "./calcs/realty/containers/RealtyCalcDraftContainer";
import CalcRecordsContainer from "./records/containers/CalcRecordsContainer";
import CalcSettingsContainer from "./settings/containers/CalcSettingsContainer";
import VehicleSettingsContainer from "./vehicles/containers/VehicleSettingsContainer";

import NotFound from "../../common/pages/NotFound/NotFound";

export default () => (
  <Switch>
    <Route
      exact path="/calc"
      render={() => <RedirectWithPermission permissionRedirects={[
        { permission: Permission.MTPL_CALCULATOR, exact: true, from: "/calc", to: "/calc/vehicle" },
        { permission: Permission.CRASH_CALCULATOR, exact: true, from: "/calc", to: "/calc/vehicle" },
        { permission: Permission.REALTY_CALCULATOR, exact: true, from: "/calc", to: "/calc/realty" },
        { permission: Permission.TRAVEL_CALCULATOR, exact: true, from: "/calc", to: "/calc/travel" }
      ]} />} />

    <RouteWithPermission exact path="/calc/vehicle"
                         component={VehicleCalcContainer}
                         anyPermission
                         permissions={[Permission.MTPL_CALCULATOR, Permission.CRASH_CALCULATOR]} />

    <RouteWithPermission exact path="/calc/travel"
                         component={TravelCalcContainer}
                         permissions={[Permission.TRAVEL_CALCULATOR]} />

    <RouteWithPermission exact path="/calc/realty"
                         component={RealtyCalcContainer}
                         permissions={[Permission.REALTY_CALCULATOR]} />

    <RouteWithPermission exact path="/calc/realty/drafts"
                         component={RealtyCalcDraftContainer}
                         permissions={[Permission.REALTY_CALCULATOR]} />

    <RouteWithPermission exact path="/calc/records"
                         component={CalcRecordsContainer}
                         anyPermission
                         permissions={[Permission.MTPL_CALCULATOR_ADMIN, Permission.CRASH_CALCULATOR_ADMIN,
                           Permission.REALTY_CALCULATOR_ADMIN, Permission.TRAVEL_CALCULATOR_ADMIN]} />

    <RouteWithPermission exact path="/calc/settings/mtpl"
                         key="calc-settings-mtpl"
                         component={CalcSettingsContainer}
                         permissions={[Permission.MTPL_CALCULATOR_ADMIN]} />

    <RouteWithPermission exact path="/calc/settings/crash"
                         key="calc-settings-crash"
                         component={CalcSettingsContainer}
                         permissions={[Permission.CRASH_CALCULATOR_ADMIN]} />

    <RouteWithPermission exact path="/calc/settings/gap"
                         key="calc-settings-gap"
                         component={CalcSettingsContainer}
                         permissions={[Permission.CRASH_CALCULATOR_ADMIN]} />

    <RouteWithPermission exact path="/calc/settings/pas"
                         key="calc-settings-pas"
                         component={CalcSettingsContainer}
                         permissions={[Permission.CRASH_CALCULATOR_ADMIN]} />

    <RouteWithPermission exact
                         path={["/calc/settings/vehicle-brands", `/calc/settings/vehicle-brands/:id(${regexPatterns.uuidRegex.source})/vehicle-models`]}
                         component={VehicleSettingsContainer}
                         anyPermission
                         permissions={[Permission.MTPL_CALCULATOR_ADMIN, Permission.CRASH_CALCULATOR_ADMIN]} />

    <RouteWithPermission exact path="/calc/settings/travel"
                         key="calc-settings-travel"
                         component={CalcSettingsContainer}
                         permissions={[Permission.TRAVEL_CALCULATOR_ADMIN]} />

    <RouteWithPermission exact path="/calc/settings/realty"
                         key="calc-settings-realty"
                         component={CalcSettingsContainer}
                         permissions={[Permission.REALTY_CALCULATOR_ADMIN]} />

    <Route component={NotFound} />
  </Switch>
);
